


<template>
  <div v-if="r" id="report">

    <section class="">
      <table class="titlebox absolute text-white" style="font-size: 1vw; left: 11em; width:28em; margin-top: 11em;">
        <tr><td class="font-bold" style="font-size: 2.5em; padding-bottom: 0.5em;" data-cell-style="TitlePageTitleCell">FINDINGS REPORT</td></tr>
        <tr><td class="font-bold leading-none" style="font-size: 2em; padding-bottom: 1em;" data-cell-style="TitlePageSubtitleCell">Project Management Review</td></tr>
        <tr><td class="leading-snug" style="font-size: 1.2em;" data-cell-style="TitlePageCell">Confidential: For the exclusive use of Ricoh and <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span>.  This document cannot be shared with any other parties without written permission from all of the parties referenced here.</td>
        </tr>
        <tr><td style="padding-bottom: 2em;">  </td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell">V1.0</td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell"><span>{{ r.userProfile.firstname }} {{ r.userProfile.lastname }}</span></td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell"><span>{{ r.userProfile.title }}</span></td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell"><span>{{ currentDate }}</span></td></tr>
      </table>

      <img class="w-full -mx-8 block" data-float="true" data-ratio="1.2941" src="/reports/title-bkg.png" />



      <p></p>



    </section>

    <section>
      <p class="font-bold no-mb">Prepared for:</p>
      <p class="font-bold no-mb "><span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> - <span  @click="onSpanClick">{{ r.customerProfile.customerContact }}</span></p>
      <p class="no-mb"><span  @click="onSpanClick">{{ r.customerProfile.address }}</span></p>
      <p class="no-mb"><span  @click="onSpanClick">{{ r.customerProfile.city }}</span>, <span  @click="onSpanClick">{{ r.customerProfile.state }}</span> <span  @click="onSpanClick">{{ r.customerProfile.postalcode }}</span></p>
      <p class="mt-8 no-mb font-bold">Prepared by:</p>
      <p class="no-mb"><span>{{ r.userProfile.firstname }}</span> <span>{{ r.userProfile.lastname }}</span></p>
      <p class="no-mb" v-if="r.userProfile.organization"><span>{{ r.userProfile.organization }}</span></p>
      <p class="no-mb"><span>{{ r.userProfile.title }}</span></p>
      <p class="no-mb mt-8"><span>{{ currentDate }}</span></p>
    </section>
    <section>
      <p class="toc-title">TABLE OF CONTENTS</p>
      <p data-toc="true" data-alias="Table of Contents" data-levels="1-3">Table of Contents here.</p>
    </section>
    <section v-if="true">
      <h1>Overview</h1>
      <h2>Background</h2>

      <p>Many marketing teams can elevate their productivity and efficiency through streamlined processes for creating, reviewing, and producing marketing assets such as campaign materials, brochures, sales collateral, external communications, and digital assets. With Ricoh’s PMP Assessment, marketing teams can benefit in several key areas:</p>
      <ul class="list-disc">
        <li><b>Accelerated Time to Market:</b> Utilize automated processes to reduce delays and meet deadlines, with automatic reminders and escalations.</li>
        <li><b>Optimal Resource Allocation:</b> Ensure that internal designers, content authors, SMEs, and external agencies are effectively utilized based on project priority, strategic value, and effort.</li>
        <li><b>Clear Ownership and Accountability:</b> Actively track project owners, collaborators, and approvers against deadlines and committed SLAs, providing data for productivity and performance analysis.</li>
        <li><b>Streamlined Approval Processes:</b> Implement systems and tracking for legally sensitive or regulated content, ensuring accountability and executive oversight.</li>
        <li><b>Minimized Duplicate Efforts:</b> Avoid replication of processes and content by improving the accessibility of official versions and processes.</li>
        <li><b>Increased Productivity and Morale:</b> Reduce manual and repetitive work, enhancing team member satisfaction and overall team goals.</li>


      </ul>
      <p>Ricoh’s PMP Assessment benchmarks your current processes against industry best practices, providing recommendations and scalable solutions to automate and simplify your marketing processes. This leads to more efficient project management, better control of timelines, and optimal use of resources necessary for complex marketing campaigns and projects. This PMP Assessment includes a full survey of {{ r.customerProfile.organizationName }}’s creative and content processes, a scorecard of critical category ratings (by leadership owner/sponsor and cross functional team participants) and observations gathered by our marketing consultants during interviews and conversations with key marketing team members.  This finding documents the current state of {{ r.customerProfile.organizationName }}’s marketing project management process against industry best practices and provides recommendations to streamline operations and optimize and improve productivity.</p>

      <h2>Methodology</h2>
      <p><span>{{ r.userProfile.firstname }}</span> <span>{{ r.userProfile.lastname }}</span>, Ricoh’s <span>{{ r.userProfile.title }}</span> visited with <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> on <span  @click="onSpanClick">{{ r.customerProfile.assessmentDate }}</span> to review the organization’s Project Management processes. The goal was to review all processes and business requirements from marketing, product teams and contributors, legal review and executive approvers involved in the entire marketing content creation process.  Project Management processes were evaluated against industry standards and best practice benchmarks.</p>
      <p>Processes reviewed:</p>
      <ul class="list-disc">
        <li>Business Goals and Objective</li>
        <li>Project Management Platform</li>
        <li>Integration and Compatibility</li>
        <li>Adoption and usage: Internal and External Teams</li>
        <li>Process, Operations and Workflow</li>
        <li>Resource and Cost Management</li>
        <li>Scalability and Customization</li>
        <li>Audit Tracking and Analytics</li>
        <li>Team Satisfaction with PMP</li>

      </ul>

      <h2>Site</h2>
      <p class="no-mb"><span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> - <span  @click="onSpanClick">{{ r.customerProfile.customerContact }}</span></p>
      <p class="no-mb"><span  @click="onSpanClick">{{ r.customerProfile.address }}</span></p>
      <p class="no-mb"><span  @click="onSpanClick">{{ r.customerProfile.city }}</span>, <span  @click="onSpanClick">{{ r.customerProfile.state }}</span> <span  @click="onSpanClick">{{ r.customerProfile.postalcode }}</span></p>

      <h2>Departments Reviewed</h2>
      <table>



        <tr>
          <th>No.</th><th class="text-left">Departments/Area</th>
        </tr>

        <tr v-for="(department, index) in departmentsReviewed" :key="index">
          <td>{{ index + 1 }}</td><td class="text-left">{{ department }}</td>
        </tr>

      </table>

    </section>
    <section v-if="true">
      <h1>Current State</h1>
      <h2>Organization</h2>
      <p><span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span>’s Marketing asset creation process produces a wide range of formats including campaign and advertising materials, brochures, sales collateral, external communications materials and digital assets. Various teams develop <span title="X107.final" @click="onSpanClick">{{ q.X107.answer.final | decimal(0) }}</span> new assets or update <span title="X108.final" @click="onSpanClick">{{ q.X108.answer.final | decimal(0) }}</span> existing assets annually. These assets generate approximately <span title="X109.final" @click="onSpanClick">{{ q.X109.answer.final | decimal(0) }}</span> print and <span title="X110.final" @click="onSpanClick">{{ q.X110.answer.final | decimal(0) }}</span> digital formats monthly. </p>

      <p>There are a total of <span title="X3225.final" @click="onSpanClick">{{ q.X3225.answer.final | decimal(1) }}</span> employees contributing to the creative and production process.</p>


      <h3 class="mt-8">Business Goals and Marketing Objectives</h3>
      <p class="">The team’s goals and objectives are <span title="X120.notes" @click="onSpanClick">{{ q.X120.notes }}</span> aligned with the organizations strategic and revenue goals:</p>
      <ul class="">
        <li class="ml-4">Marketing priorities and objectives: <span title="X121.notes" @click="onSpanClick">{{ q.X121.notes }}</span></li>
        <li class="ml-4">Sales targets: <span title="X122.notes" @click="onSpanClick">{{ q.X122.notes }}</span></li>
        <li class="ml-4">Key performance indicators (KPIs): <span title="X123.notes" @click="onSpanClick">{{ q.X123.notes }}</span></li>
      </ul>

      <h2>Software and Platforms</h2>
      <p>Software and Platforms to support creation, distribution and management of marketing assets and campaigns.</p>

      <ul>
        <li>
          <b>Creative Software:</b><br />
          <span title="X221.notes" @click="onSpanClick">{{ q.X221.notes }}</span>
        </li>
        <li>
          <b>Project or Work Management Platforms: </b><br />
          <span title="X222.notes" @click="onSpanClick">{{ q.X222.notes }}</span>
        </li>
        <li>
          <b>Marketing Automation Platforms:</b><br />
          <span title="X223.notes" @click="onSpanClick">{{ q.X223.notes }}</span>
        </li>
        <li>
          <b>Customer Relationship Management Platforms:</b><br />
          <span title="X224.notes" @click="onSpanClick">{{ q.X224.notes }}</span>
        </li>
        <li>
          <b>Digital Asset Management Platforms:</b><br />
          <span title="X225.notes" @click="onSpanClick">{{ q.X225.notes }}</span>
        </li>
        <li>
          <b>Sales Enablement or Marketing Asset Distribution Platforms:</b><br />
          <span title="X226.notes" @click="onSpanClick">{{ q.X226.notes }}</span>
        </li>
        <li>
          <b>Website Authoring and Content Management:</b><br />
          <span title="X227.notes" @click="onSpanClick">{{ q.X227.notes }}</span>
        </li>
      </ul>
















      <p class="bold-label">Integration: </p>
      <p class="">The project management platform is integrated with <span title="X228.notes" @click="onSpanClick">{{ q.X228.notes }}.</span></p>

      <p>The project management platform provides integration via  <span title="X229.notes" @click="onSpanClick">{{ q.X229.notes }}.</span></p>
      <p class="scoreframe mt-2">The <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> team Scorecard rating for Integration was <span title="S011" class="font-bold" @click="onSpanClick">{{ reportData.survey.questions.S011.answer.pre }}</span> <span title="S011" @click="onSpanClick" v-if="reportData.survey.questions.S011.answer.pre !== 'NA'">({{ scoreRating(reportData.survey.questions.S011.answer.pre) }})</span>. </p>

      <h2>Project Management Platform and Processes</h2>
      <h3>Project Management Platform</h3>
      <p>The existing project management platforms is <span title="X3100.notes" @click="onSpanClick">{{ listNotes(q.X3100) }}</span>.  This <span title="X3101.notes" @click="onSpanClick">{{ q.X3101.notes === 'Yes' ? 'is':'is not' }}</span> an enterprise deployed platform selected by <span title="X3102.notes" @click="onSpanClick">{{ listNotes(q.X3102) }}</span>.  Marketing <span title="X3103.notes" @click="onSpanClick">{{ q.X3103.notes === 'Yes' ? 'did':'did not' }}</span> participate in the evaluation and selection of the platform.  The platform <span title="X3104.notes" @click="onSpanClick">{{ q.X3104.notes === 'Yes' ? 'provides':'does not provide' }}</span> functionality that addresses the  marketing creative and collaborative process.  </p>
      <p class="scoreframe mt-2">The <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> team Scorecard rating for Project Management Platform was <span title="S001" class="font-bold" @click="onSpanClick">{{ reportData.survey.questions.S001.answer.pre }}</span> <span title="S001" @click="onSpanClick" v-if="reportData.survey.questions.S001.answer.pre !== 'NA'">({{ scoreRating(reportData.survey.questions.S001.answer.pre) }})</span>. </p>

      <p>Other platforms are in use are <span title="X3106.notes" @click="onSpanClick">{{ q.X3106.notes }}</span>.  They are used for <span title="X3107.notes" @click="onSpanClick">{{ listNotes(q.X3107) }}</span> by the <span title="X3108.notes" @click="onSpanClick">{{ listNotes(q.X3108) }}</span> teams.</p>
      <p><b>Annual Contract Cost for Primary PMP:</b> $<span title="X3109" @click="onSpanClick">{{ q.X3109.answer.final | decimal(0)  }}</span></p>
      <p><b>Platform Support provided by:</b> <span title="X3110.notes" @click="onSpanClick">{{ listNotes(q.X3110) }}</span>.</p>
      <p>Add details about the Marketing Ops and IT team and their support of the platform: </p>
      <p><b>Vendor specific support:</b> Marketing teams and leaders are <span title="X3111.notes" @click="onSpanClick">{{ q.X3111.notes }}</span> with the vendor provided support they receive to resolve issues, obtain guidance and best practices and forward-looking plans for the platform.  Leaders <span title="X3112.notes" @click="onSpanClick">{{ q.X3112.notes === 'Yes' ? 'would':'would not' }}</span> recommend their platform to other marketers.</p>
      <p class="scoreframe mt-2">The <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> team Scorecard rating for Vendor Support was <span title="S013" class="font-bold" @click="onSpanClick">{{ reportData.survey.questions.S013.answer.pre }}</span> <span title="S013" @click="onSpanClick" v-if="reportData.survey.questions.S013.answer.pre !== 'NA'">({{ scoreRating(reportData.survey.questions.S013.answer.pre) }})</span>. </p>

      <h3>Adoption and usage: Internal and External Teams</h3>
      <p>The existing project management platform is utilized by <span title="X3200.notes" @click="onSpanClick">{{ q.X3200.notes }}</span> marketing teams within the organization. Approximately <span title="X320.notes" @click="onSpanClick">{{ q.X3201.notes }}</span>% of marketing use the platform.   The existing platform is utilized by <span title="X3202.notes" @click="onSpanClick">{{ listNotes(q.X3202) }}</span>.</p>
      <p class="font-bold">Team members using the PMP platform: </p>
      <p>There are a total of <span title="X3225.final" @click="onSpanClick">{{ q.X3225.answer.final | decimal(2) }}</span> team members utilizing the platform.</p>

      <p>User counts by department: (Including all levels i.e. Coordinator, Manager, Director, Sr Director, VP): </p>
      <ul>

        <li>Project/Program: <span title="X3204.final" @click="onSpanClick">{{ q.X3204.answer.final | decimal(2) }}</span></li>
        <li>Traffic: <span title="X3205.final" @click="onSpanClick">{{ q.X3205.answer.final | decimal(2) }}</span></li>
        <li>Creative: <span title="X3206.final" @click="onSpanClick">{{ q.X3206.answer.final | decimal(2) }}</span></li>
        <li>Campaign: <span title="X3207.final" @click="onSpanClick">{{ q.X3207.answer.final | decimal(2) }}</span></li>
        <li>Product/Line of Business: <span title="X3216.final" @click="onSpanClick">{{ q.X3216.answer.final | decimal(2) }}</span></li>
        <li>SME/Product: <span title="X3208.final" @click="onSpanClick">{{ q.X3208.answer.final | decimal(2) }}</span></li>
        <li>Collaborator: <span title="X3209.final" @click="onSpanClick">{{ q.X3209.answer.final | decimal(2) }}</span></li>
        <li>Legal: <span title="X3219.final" @click="onSpanClick">{{ q.X3219.answer.final | decimal(2) }}</span></li>
        <li>Compliance: <span title="X3220.final" @click="onSpanClick">{{ q.X3220.answer.final | decimal(2) }}</span></li>
        <li>Approver: <span title="X3210.final" @click="onSpanClick">{{ q.X3210.answer.final | decimal(2) }}</span></li>
        <li>Sr Leader (VP, SVP, CMO): <span title="X3211.final" @click="onSpanClick">{{ q.X3211.answer.final | decimal(2) }}</span></li>
        <li>Marketing Ops: <span title="X3222.final" @click="onSpanClick">{{ q.X3222.answer.final | decimal(2) }}</span></li>
        <li>Sales Ops: <span title="X3218.final" @click="onSpanClick">{{ q.X3218.answer.final | decimal(2) }}</span></li>
        <li>Other: <span title="X3221.final" @click="onSpanClick">{{ q.X3221.answer.final | decimal(2) }}</span></li>
      </ul>

      <h3>Project or Campaign Types and Complexity </h3>
      <p>Number of projects per month/year: <span title="X3301.final" @click="onSpanClick">{{ q.X3301.answer.final | decimal(0) }}</span></p>
      <p>Types of projects that are tracked using PMP:</p>
      <bulleted-notes :onClick="onSpanClick" :questions="q" questionId="X3302" />

      <p>Projects managed within the project management platform are <span title="X3304.notes" @click="onSpanClick">{{ q.X3304.notes }}</span>.  Product or service launch projects with a wide variety of marketing deliverables and assets and schedule dependencies <span title="X3305.notes" @click="onSpanClick">{{ q.X3305.answer.final?'are':'are not' }}</span> managed using the platform.</p>
      <p class="scoreframe mt-2">The <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> team Scorecard rating for Project or Campaign Complexity was <span title="S006" class="font-bold" @click="onSpanClick">{{ reportData.survey.questions.S006.answer.pre }}</span> <span title="S006" @click="onSpanClick" v-if="reportData.survey.questions.S006.answer.pre !== 'NA'">({{ scoreRating(reportData.survey.questions.S006.answer.pre) }})</span>. </p>
      <p>Percentage of projects that are managed in PMP: <span title="X3307.final" @click="onSpanClick">{{ q.X3307.answer.final | decimal(0) }}</span>%</p>
      <p>Of the projects that are NOT managed in PMP, what are challenges or why not:</p>
      <bulleted-notes :onClick="onSpanClick" :questions="q" questionId="X3308" />

      <h3>Request and Intake</h3>
      <p>Marketing project requests are submitted by <span title="X3401.notes" @click="onSpanClick">{{ listNotes(q.X3401) }}</span> using <span title="X3402.notes" @click="onSpanClick">{{ listNotes(q.X3402) }}.</span>  Intake methods are utilized for <span title="X3403.notes" @click="onSpanClick">{{ listNotes(q.X3403) }}</span>.</p>
      <p class="scoreframe mt-2">The <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> team Scorecard rating for Request and Intake was <span title="S004" class="font-bold" @click="onSpanClick">{{ reportData.survey.questions.S004.answer.pre }}</span> <span title="S004" @click="onSpanClick" v-if="reportData.survey.questions.S004.answer.pre !== 'NA'">({{ scoreRating(reportData.survey.questions.S004.answer.pre) }})</span>. </p>
      <p>There <span title="X3405.notes" @click="onSpanClick">{{ q.X3405.notes === 'Yes' ? 'is':'is not' }}</span> a common form with options and selections to intake all marketing requests.</p>
      <p v-if="q.X3405.notes === 'Yes'">Approximately  <span title="X3406.final" @click="onSpanClick">{{ q.X3406.answer.final | decimal(0) }}</span>% of all marketing requests are supported by the form.</p>
      <p v-if="q.X3405.notes === 'No'">There are <span title="X3407.final" @click="onSpanClick">{{ q.X3407.answer.final | decimal(0) }}</span> intake forms for specific requests such as <span title="X3408.notes" @click="onSpanClick">{{ listNotes(q.X3408) }}.</span></p>
      <p v-if="q.X3405.notes === 'No'">There <span title="X3409.notes" @click="onSpanClick">{{ q.X3409.notes === 'Yes' ? 'are':'are not' }}</span> concerns with the intake process. <span v-if="q.X3409.notes === 'Yes'" title="X3410.notes" @click="onSpanClick">Concerns with the intake process are {{ q.X3410.notes }}.</span></p>

      <h3>Marketing Specific Workflow Support - Creative App Integration</h3>
      <p>The existing project management platform <span title="X3601.notes" @click="onSpanClick">{{ q.X3601.notes }}</span> for marketing specific workflows with a number of contributors, reviews and approvers.  Designers and graphic design teams <span title="X3602.final" @click="onSpanClick">{{ q.X3602.answer.final ? 'can':'can not' }}</span> work within the platform and access design tools (i.e. Adobe Creative Suite) from within the project management platform.  Video projects <span title="X3503.final" @click="onSpanClick">{{ q.X3503.answer.final ? 'can':'can not' }}</span> be developed, edited, commented or annotated within the platform.</p>
      Current workflow:
      <bulleted-notes :onClick="onSpanClick" :questions="q" questionId="X3503A" />

      <p>Workflows, steps in your process or creative work that are NOT supported by your platform include <span title="X3504.notes" @click="onSpanClick">{{ q.X3504.notes }}.</span></p>
      <p>Comments: <span title="X3505.notes" @click="onSpanClick">{{ q.X3505.notes }}</span></p>
      <p>Other Features: The current platform <span title="X3506.notes" @click="onSpanClick">{{ q.X3506.notes === 'Yes' ? 'provides':'does not provide' }}</span> all of the features that your team requires.</p>
      <p v-if="q.X3506.notes !== 'Yes'">The specific missing features are <span title="X3507.notes" @click="onSpanClick">{{ q.X3507.notes }}</span>.</p>
      <p class="scoreframe mt-2">The <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> team Scorecard rating for Marketing Specific Workflow Support was <span title="S007" class="font-bold" @click="onSpanClick">{{ reportData.survey.questions.S007.answer.pre }}</span> <span title="S007" @click="onSpanClick" v-if="reportData.survey.questions.S007.answer.pre !== 'NA'">({{ scoreRating(reportData.survey.questions.S007.answer.pre) }})</span>. </p>


      <h3>Requestor, Collaborator, Approver Enablement</h3>
      <p>The existing platform <span title="X3601.notes" @click="onSpanClick">{{ q.X3601.notes }}</span> to accommodate the organization’s requestors, collaborators and approvers.</p>
      <p>Senior executives <span title="X3602.final" @click="onSpanClick">{{ q.X3602.answer.final ? 'utilize':'do not utilize' }}</span> the platform to review and approve content they are accountable for. Legal or regulatory teams <span title="X3603.final" @click="onSpanClick">{{ q.X3603.answer.final ? 'utilize':'do not utilize' }}</span> the platform.</p>
      <p>SMEs for Product, Segment or Industry specific projects <span title="X3604.final" @click="onSpanClick">{{ q.X3604.answer.final ? 'utilize':'do not utilize' }}</span> the platform.</p>
      <p>Collaborators and approvers <span title="X3605.notes" @click="onSpanClick">{{ q.X3605.notes === 'Yes' ? 'work':'do not work' }}</span> within the platform to make edits, comments, approve or reject content.</p>
      <p v-if="q.X3605.notes !== 'Yes'">For collaborators and approvers responding outside of the system, email replies and responses <span title="X3506.notes" @click="onSpanClick">{{ q.X3506.notes === 'Yes' ? 'are':'are not'}}</span> incorporated back into the workflow.</p>

      <p class="scoreframe mt-2">The <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> team Scorecard rating for Requestor, Collaborator and Approver Enablement was <span title="S005" class="font-bold" @click="onSpanClick">{{ reportData.survey.questions.S005.answer.pre }}</span> <span title="S005" @click="onSpanClick" v-if="reportData.survey.questions.S005.answer.pre !== 'NA'">({{ scoreRating(reportData.survey.questions.S005.answer.pre) }})</span>. </p>


      <h3>Project Replication or Update</h3>
      <p>Existing projects <span title="X3701.final" @click="onSpanClick">{{ q.X3701.answer.final ? 'can':'can not' }}</span> be easily replicated or updated with the platform.</p>
      <p class="scoreframe mt-2">The <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> team Scorecard rating for Project Replication or Update was <span title="S008" class="font-bold" @click="onSpanClick">{{ reportData.survey.questions.S008.answer.pre }}</span> <span title="S008" @click="onSpanClick" v-if="reportData.survey.questions.S008.answer.pre !== 'NA'">({{ scoreRating(reportData.survey.questions.S008.answer.pre) }})</span>. </p>

      <h3>Marketing and Creative Resource Availability and Management </h3>
      <p>Marketing and creative team resource and time management is <span title="X3801.notes" @click="onSpanClick">{{ q.X3801.notes }}</span>.</p>
      <p>Resource management features are <span title="X3802.notes" @click="onSpanClick">{{ q.X3802.notes }}</span>.</p>
      <p>Project managers and project owners <span title="X3803.final" @click="onSpanClick">{{ q.X3803.answer.final ? 'can':'can not' }}</span> view resources across projects and calendars.</p>
      <p>Project managers and owners <span title="X3804.final" @click="onSpanClick">{{ q.X3804.answer.final ? 'can':'can not' }}</span> quickly determine where projects are stalled within the process and who is accountable for delays.</p>
      <p class="scoreframe mt-2">The <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> team Scorecard rating for Marketing and Creative Resource Availability and Management was <span title="S012" class="font-bold" @click="onSpanClick">{{ reportData.survey.questions.S012.answer.pre }}</span> <span title="S012" @click="onSpanClick" v-if="reportData.survey.questions.S012.answer.pre !== 'NA'">({{ scoreRating(reportData.survey.questions.S012.answer.pre) }})</span>. </p>

      <h3>Audit Tracking of Approvals by SMEs, Execs and for Regulatory Requirements</h3>
      <p>There <span title="X3901.final" @click="onSpanClick">{{ q.X3901.answer.final ? 'is':'is not' }}</span> an end-to-end tracking capability for audit and senior leadership review of the production process and the participants, contributors, reviewers and approvers of content.</p>
      <p>Reports <span title="X3902.final" @click="onSpanClick">{{ q.X3902.answer.final ? 'are available within the platform':'must be pulled manually' }}</span>.</p>

      <h3>Cost tracking for client or internal billing</h3>
      <p>Cost tracking and accounting of projects <span title="X31001.notes" @click="onSpanClick">{{ q.X31001.notes }}</span>.</p>
      <p class="scoreframe mt-2">The <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> team Scorecard rating for Cost tracking was <span title="S009" class="font-bold" @click="onSpanClick">{{ reportData.survey.questions.S009.answer.pre }}</span> <span title="S009" @click="onSpanClick" v-if="reportData.survey.questions.S009.answer.pre !== 'NA'">({{ scoreRating(reportData.survey.questions.S009.answer.pre) }})</span>. </p>


      <h3>Team Satisfaction with PMP</h3>
      <p>In general, the platform <span title="X31101.final" @click="onSpanClick">{{ q.X31101.answer.final ? 'has':'does not have' }}</span> the capabilities required to address the specific needs of the creative, content and production process.</p>
      <p class="scoreframe mt-2">The <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> team Scorecard rating for Team Satisfaction was <span title="S003" class="font-bold" @click="onSpanClick">{{ reportData.survey.questions.S003.answer.pre }}</span> <span title="S003" @click="onSpanClick" v-if="reportData.survey.questions.S003.answer.pre !== 'NA'">({{ scoreRating(reportData.survey.questions.S003.answer.pre) }})</span>. </p>
      <p>In the context of the Scorecard rating, users have these concerns with the existing platform:</p>
      <bulleted-notes :onClick="onSpanClick" :questions="q" questionId="X31103" />

    </section>

    <section v-if="true">
      <h1>Findings</h1>
      <h2>Team's PMP Assessment Scorecard Summary</h2>
      <p><span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span>’s PMP Assessment processes are rated as <span class="uppercase">{{ scorecardGrade }}</span> with a rating of {{ r.scorecard.final }} on a scale of 1 to 5. The overall rating would rise if some of the issues discussed in this document would be addressed. </p>
      <assessment-scorecard data-svg="true" data-ratio="0.8" title="Team Assessment Scorecard" answer-key="pre" :assessment="r" :questions="survey"></assessment-scorecard>

      <p class="mt-4">See Appendix 1 for a detailed scale of the PMP Best Practices Scorecard.</p>
      <p>The current team has made changes and improvements over time within the budget constraints and resources availability. However, substantial room for improvement exists with respect to PMP development efficiency, project management best practices and integration.</p>

      <h2>Strengths</h2>
      <p>The strengths of the operation include:</p>


      <div data-container="true" v-for="(strength, index) in strengthsWeakness.strength" :key="'s'+index">
        <h3>{{ strength.title }}</h3>
        <p><span v-html="strength.criteria" />  This is considered {{ strength.grade }}.</p>
        <p>Notes: {{ strength.notes }}</p>
      </div>


      <h2>Issues / Risks / Challenges</h2>
      <p> The opportunities for improvement include:</p>

      <div data-container="true" v-for="(weakness, index) in strengthsWeakness.weakness" :key="'w'+index">
        <h3>{{ weakness.title }}</h3>
        <p><span v-html="weakness.criteria" /> This is considered {{ weakness.grade }}.</p>
        <p>Notes: {{ weakness.notes }}</p>
      </div>
    </section>
    <section>
      <h1>Recommendations</h1>
      <p>Based on our assessment of your production operation, Ricoh recommends incorporating the following software solutions to streamline <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span>'s workflow.</p>

      <h2>Proposed Solutions</h2>
      <component data-container="true" v-for="(solution) in solutions.Other" :key="solution.id" :is="solution.component"></component>
    </section>

    <section>
      <h1>Appendix</h1>
      <h2>Appendix 1 - Project Management Review Scorecard Ratings</h2>
      <table class="text-xs" data-cell-style="AppendixCell">
        <tr>
          <th></th>
          <th>5</th>
          <th>4</th>
          <th>3</th>
          <th>2</th>
          <th>1</th>
          <th>N/A</th>
        </tr>
        <tr v-for="rating in survey" :key="rating.id">
          <td class="align-top">{{ rating.question }}</td>
          <td class="align-top">{{  stripHTML(rating.criteria5) }}</td>
          <td class="align-top">{{  stripHTML(rating.criteria4) }}</td>
          <td class="align-top">{{  stripHTML(rating.criteria3) }}</td>
          <td class="align-top">{{  stripHTML(rating.criteria2) }}</td>
          <td class="align-top">{{  stripHTML(rating.criteria1) }}</td>
          <td class="align-top">{{  stripHTML(rating.criteriaNA) }}</td>
        </tr>
      </table>
    </section>

  </div>


</template>

<script>
import workflow from "@/schema/marketingPmp";
import utils from "@/plugins/utils"
import BulletedNotes from "@/components/BulletedNotes";
import AssessmentScorecard from "@/components/AssessmentScorecard";
import CreativeServices from "@/components/Solutions/CreativeServices";
import MarcomCentral from "@/components/Solutions/MarcomCentral";
import Robohead from "@/components/Solutions/Robohead";
import RicohMarketingServices from "@/components/Solutions/RicohMarketingServices";
import AugmentedReality from "@/components/Solutions/AugmentedReality";
import MailAssessment from "@/components/Solutions/MailAssessment";
import DigitalAttribution from "@/components/Solutions/DigitalAttribution";
import DigitalMaturity from "@/components/Solutions/DigitalMaturity";
import ProjectManagementConsulting from "@/components/Solutions/ProjectManagementConsulting";
import MarketingAutomation from "@/components/Solutions/MarketingAutomation";


export default {
    name: "report-pmp",
  components: {
    MarketingAutomation,
    ProjectManagementConsulting,
    DigitalMaturity,
    DigitalAttribution,
    Robohead,
    MarcomCentral,
    CreativeServices,
      BulletedNotes,
    RicohMarketingServices,
    MailAssessment,
    AugmentedReality,
    AssessmentScorecard
  },
  mounted: function(){
    this.$emit("mounted");
  },
    props: {
      reportData: {
        required: true,
      }
    },
    data: function(){
        return {

        }
        },
    methods: {
      listNotes: function(val){
        return utils.listNotes(val)
      },

      onSpanClick: function(e){

        let questionData;



        if(e.target.title) {
          let qPath = e.target.title.split(".");
          if (qPath[0].startsWith("X") || qPath[0].startsWith("V")) {
            let question = this.iQ[qPath[0]];
            let answer = this.q[qPath[0]];

            if (question) {
              questionData = {
                title: "Interview Question",
                answer: answer,
                question: question
              }
              this.$emit("edit", questionData)
            }
          } else if (qPath[0].startsWith("S")) {
            let question = this.reportData.survey.questions[qPath[0]];
            let answer = this.reportData.survey.questions[qPath[0]];

            console.log('criteria'+answer.final, answer)

            if (question) {
              questionData = {
                title: this.survey[qPath[0]].question,
                answer: answer,
                question: {
                  id: qPath[0],
                  answer: question.answer,
                },
                criteria: this.survey[qPath[0]]['criteria'+answer.answer.final]
              }
              this.$emit("edit", questionData)
            }
          }
        }

      },

      onChange: function(e){

        this.$emit("change",e);

      },

      scoreRating: function(score){

        if(score === "NA") {
          return score;
        }
        let intValue = parseFloat(score);
        if(intValue > 4.5 ){
          return "Best-in-Class"
        }
        if(intValue > 3.5 ){
          return "Very Good"
        }
        if(intValue > 2.5 ){
          return "Good"
        }
        if(intValue > 1.5 ){
          return "Sufficient"
        }
        if(intValue > 0 ){
          return "Needs Improvement"
        }
      },

      stripHTML: function(html){
        let tmp = document.createElement("DIV")
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
      }
    },
    computed: {
       r: function(){
         return this.reportData;
       },

      q: function(){
        return this.r.interview.questions;
      },


      strengthsWeakness: function(){
        let _listing = {
          strength: [],
          weakness: []
        }

        if(this.r){
          Object.keys(this.survey).forEach((key) => {
            let question = this.survey[key];
            let answer = this.r.survey.questions[key];

            if(answer.answer.final && answer.answer.final !=="NA"){
              let intValue = parseInt(answer.answer.final);
              if(intValue > 3){
                _listing.strength.push({
                  title: question.question,
                  criteria: question['criteria'+intValue],
                  grade: (intValue === 4)?"Very Good":"Best-in-Class",
                  notes: answer.notes
                })
              }

              if(intValue < 3){
                _listing.weakness.push({
                  title: question.question,
                  criteria: question['criteria'+intValue],
                  grade: (intValue === 2)?"Sufficient":"Substandard",
                  notes: answer.notes
                })
              }
            }

          })
        }

        return _listing;


      },


      option2: function(){
        if(this.r){
          return (this.r.exportSettings.option === "2");
        }
        return false;
      },

      solutions: function(){


        if(this.r){
          let _solutions = {
            Other: this.r.exportSettings.solutions.Other.map((otherID)=>{
              return this.solutionList[otherID]
            })
          };

          return _solutions;

        }

        return {}

      },

      survey: function(){
        return workflow.survey;
      },

      iQ: function(){
        return workflow.questions;
      },

      currentDate: function(){
        return (new Date(Date.now())).toLocaleDateString()
      },

      scorecardGrade: function(){
        if(this.r && this.r.scorecard && this.r.scorecard.final){
          if(this.r.scorecard.final < 1.5){
            return "Sub-Standard"
          }else if(this.r.scorecard.final < 2.5){
            return "Sufficient"
          }else if(this.r.scorecard.final < 3.5){
            return "Good"
          }else if(this.r.scorecard.final < 4.5){
            return "Very Good"
          }else if(this.r.scorecard.final < 5.1){
            return "Best-in-Class"
          }


        }

        return "NA"
      },

      solutionList: function() {
         return {
            "AvantiSlingshot": {name: "Avanti Slingshot", component: "AvantiSlingshot", category: "MIS"},
            "EfiPace": {name: "EFI Pace", component: "EfiPace", category: "MIS"},
            "PrintSmithVision": {name: "PrintSmith Vision", component: "PrintSmithVision", category: "MIS"},
            "FusionPro": {name: "Fusion Pro", component: "FusionPro", category: "VDP"},
           "PrintShopMailConnect": {name: "Objectif Lune Print Shop Mail Connect", component: "PrintShopMailConnect", category: "VDP"},
            "EFIFieryJobMaster": {name: "EFI Fiery Job Master", component: "EFIFieryJobMaster", category: "VDP"},
           "EFIFieryJobFlow": {name: "EFI Fiery Job Flow", component: "EFIFieryJobFlow", category: "VDP"},
           "ReadyPrint": {name: "RSA ReadyPrint", component: "ReadyPrint", category: "VDP"},
           "EnfocusSwitch": {name: "Enfocus Switch", component: "EnfocusSwitch", category: "VDP"},
           "Robohead": {name: "Robohead", component: "Robohead", category: "Other"},
           "CreativeServices": {name: "Creative Services", component: "CreativeServices", category: "Other"},
            "MarcomCentral": {name: "Marcom Central", component: "MarcomCentral", category: "Other"},
            "WebCrd": {name: "Web CRD", component: "WebCrd", category: "Storefront"},
            "MarketDirect": {name: "Market Direct", component: "MarketDirect", category: "Storefront"},
            "PrintNet": {name: "PrintNet", component: "PrintNet", category: "Storefront"},
            "ColorGap": {name: "Color Gap Analysis", component: "ColorGap", category: "Other"},
           "RicohConsultingServices": {
             name: "Ricoh Consulting Services",
             component: "RicohConsultingServices",
             category: "Other"
           },
           "RicohMarketingServices": {
             name: "Marketing Services",
             component: "RicohMarketingServices",
             category: "Other"
           },
           "AugmentedReality": {
             name: "Augmented Reality",
             component: "AugmentedReality",
             category: "Other"
           },
           "MarketingAutomation": {
             name: "Marketing Automation",
             component: "MarketingAutomation",
             category: "Other"
           },
           "DigitalAttribution": {
             name: "Digital Attribution",
             component: "DigitalAttribution",
             category: "Other"
           },
           "ProjectManagementConsulting": {
             name: "Project Management Consulting",
             component: "ProjectManagementConsulting",
             category: "Other"
           },
           "DigitalMaturity": {
             name: "Digital Maturity Assessment",
             component: "DigitalMaturity",
             category: "Other"
           },
           "MailAssessment": {
             name: "Mail Assessment",
             component: "MailAssessment",
             category: "Other"
           },
            "ProductionWorkflow": {name: "Production Workflow", component: "ProductionWorkflow", category: "Other"},
          }
       },

      departmentsReviewed: function(){
        if(this.r){
          let departments = this.r.interview.questions.X106.notes;



          let list = departments.split(',')

          if(list[list.length - 1] === 'Other'){
            list[list.length - 1] = this.r.interview.questions.X106.answer.final
          }

          return list
        }

        return [];
      }
    }
}
</script>

<style scoped>


.report-production-workflow {

}

span[title]:empty:after {
  content: '\0000a0';
  padding: 0px 0.5em;
}

.scoreframe {
  border: 1px solid black;
  padding: 1em;
  background-color: #F8F8F8;
}

</style>