
<template>
<div class="configuration-details flex flex-col h-full relative">
  <div class="text-xs px-8 py-2 pt-4 bg-white">
    <router-link to="/">Home</router-link> /
    <router-link to="/printshop-config">Print Shop Configurations</router-link> /
    <span v-if="configuration">{{ configuration.customerProfile.organizationName }}</span>
  </div>
  <div class="px-8 pt-0 bg-white">
    <h1 class="text-3xl mb-6 leading-none flex"><span>{{ title }}</span><button v-if="configuration" class="button ml-2 text-xs py-1 px-2 -mb-1" @click="changeSettings"><span class="flex items-center"><img src="../assets/icons/settings-icon.svg" class="w-4 h-4 mr-2 opacity-75" /><span>Settings</span></span></button><button v-if="configuration" @click="saveConfiguration" class="button text-sm py-1 px-2 ml-4 -mb-1" :class="hasUnsavedChanges?'border-red-600 text-red-600 hover:bg-red-600 hover:text-white':''"><span v-if="hasUnsavedChanges">Save Changes</span><span v-else>Saved</span></button></h1>
  </div>
  <div class="config-content border-t-4 flex-grow flex px-8 py-4 bg-gray-50 overflow-y-scroll" v-if="configuration">
    <link-list class="w-64 text-sm pr-8 border-r" :links="sectionList" :selection="activeSection" @change="setSection"></link-list>
    <div class="pl-8 w-full" v-if="activeSectionId !== 'siteSurvey'">
      <header class="">
        <p v-if="activeSection.description" class="border rounded p-4 bg-gray-100 font-bold">{{ activeSection.description }}</p>
      </header>
      <div class="mt-4">
        <div class="w-full -mt-4 -mr-8" v-if="activeSectionId === 'configurationSummary'">
          <config-summary-export :doc="configuration" :api="api" @boilerplate="showBoilerplate"></config-summary-export>
        </div>
        <div v-else>
          <template v-for="question in activeQuestionList">
            <component :key="question.id" :is="question.component" :question="question" :answer="configuration.answers[question.id]" :configuration="configuration" @change="onAnswerChange" class="py-3 border-b" @detail-click="onShowDetails(question)" @modify-click="modifyRecommendation(question)" @auto-generate="autoRecommendation(question)">{{ question.component }}</component>
          </template>
        </div>
      </div>
    </div>
    <div class="w-full -mt-4 -mr-8" v-if="activeSectionId === 'siteSurvey'">
        <configurator-interview-panel :configuration="configuration" @tab="setSection"></configurator-interview-panel>
    </div>

  </div>

  <transition appear name="fade">
    <div id="menu-dimmer" class="config-content" v-if="pulloutActive || boilerplate"></div>
  </transition>
  <transition appear name="slide-left">
    <component :is="pulloutActive" class="config-content" v-if="pulloutActive" @hide="onHidePullout" :question="pulloutQuestion" :answers="pulloutQuestionAnswers" :context="configuration"></component>
  </transition>

  <transition appear name="slide-left">
    <pullout-modify-panel class="config-content" v-if="modifyPulloutActive" @hide="onHideModifyPullout" v-bind:question="pulloutQuestion" v-bind:answers="pulloutQuestionAnswers"></pullout-modify-panel>
  </transition>

  <transition appear name="slide-left">
    <side-panel-config-boilerplate class="max-w-2xl fixed right-0 top-0 h-screen" v-if="boilerplate" @hide="boilerplate = ''" :solution="boilerplate"></side-panel-config-boilerplate>
  </transition>

  <transition name="slide">
    <div class="fixed w-full h-full max-w-xl top-0 right-0 shadow-lg" v-if="showSettings">

      <side-panel-doc-settings class="side-panel absolute top-0 right-0 w-full h-screen bg-white border-l bg-gray-50 overflow-y-scroll"  :record="configuration" :api="api" :admin="isAdmin" @close="closeSidePanel"></side-panel-doc-settings>

    </div>
  </transition>



<!--  <transition appear name="slide-left">-->
<!--    <pullout-modify-panel id="modify-pullout-panel" v-if="modifyPulloutActive" @hide="onHideModifyPullout" v-bind:question="pulloutQuestion" v-bind:answers="pulloutQuestionAnswers"></pullout-modify-panel>-->
<!--  </transition>-->
</div>

</template>

<script>


//import ConfigurationSurveyPanel from "@/components/ConfigurationSurveyPanel";
//import ConfigurationSurveyPanel from "@/components/ConfigurationSurveyPanel";

import printconfig from "@/plugins/printconfig";
import printConfigStore from "@/store/print-config-store"
import LinkList from "@/components/LinkList";
import ConfigQuestionSelect from "@/components/PrintConfig/ConfigQuestionSelect";
import ConfigQuestionText from "@/components/PrintConfig/ConfigQuestionText";
import ConfigQuestionSwitchDetail from "@/components/PrintConfig/ConfigQuestionSwitchDetail";
import PulloutDetailsMenu from "@/components/PrintConfig/PulloutDetailsMenu";

import PulloutModifyPanel from "@/components/PrintConfig/PulloutModifyPanel";
import ConfigSummaryExport from "@/components/PrintConfig/ConfigSummaryExport";
import PulloutMailingMenu from "@/components/PrintConfig/PulloutMailingMenu";
import ConfiguratorInterviewPanel from "@/components/PrintConfig/ConfiguratorInterviewPanel";
import SidePanelConfigBoilerplate from "@/components/PrintConfig/SidePanelConfigBoilerplate";
import SidePanelDocSettings from "../components/SidePanelDocSettings";



export default {
  name: "print-shop-config-details",
  components: {
    SidePanelDocSettings,
    SidePanelConfigBoilerplate,
    ConfiguratorInterviewPanel,
    PulloutMailingMenu,
    ConfigSummaryExport,
    PulloutModifyPanel,
    PulloutDetailsMenu,
    ConfigQuestionSwitchDetail,
    ConfigQuestionText,
    ConfigQuestionSelect,
    LinkList
},
  created: function(){
    if(!this.$store.state.printconfig){
      this.$store.registerModule("printconfig", printConfigStore);
    }
  },

  mounted: function(){
      console.log("Load data");
      this.loadData();
      if(this.$route.query.section){
        this.$store.dispatch("setActiveSectionId",this.$route.query.section);
      }
  },

  watch: {
    $route: function(newVal){
      if(newVal.query.section){
        this.$store.dispatch("setActiveSectionId",newVal.query.section);
      }else{
        this.$store.dispatch("setActiveSectionId","facilityProfile");
      }
    }
  },

  props: {},
  data: function(){
      return {
        boilerplate: null,
        pulloutActive: false,
        modifyPulloutActive: false,
        pulloutQuestion: null,
        pulloutQuestionAnswers: null,
        currentTabComponent: "ConfigurationSurveyPanel",
        questionnairePanel: false,
        configuration: null,
        hasUnsavedChanges: false,
        api: printconfig,
        showSettings: false

      }
  },
    methods: {

      changeSettings: function(){
        this.showSettings = true;
      },

      showBoilerplate: function(solution){
        this.boilerplate = solution;
      },

      onShowPullout: function(question){
        //console.log("Pullout", question.id);
        this.pulloutQuestion = question;
        this.pulloutQuestionAnswers = this.configuration.answers[question.id].details;
        if(question.detailPanelComponent){
          this.pulloutActive = question.detailPanelComponent;
        }else{
          this.pulloutActive = "PulloutDetailsMenu";
        }

        document.querySelector('body').style.overflow = "hidden";
      },
      onHidePullout: function(){
        this.pulloutActive = false;
        document.querySelector('body').style.overflow = "auto";
      },

      showModifyPanel: function(question){
        //console.log("Pullout", question.id);
        this.pulloutQuestion = question;
        this.pulloutQuestionAnswers = this.configuration.answers[question.id].details;
        this.modifyPulloutActive = true;
        document.querySelector('body').style.overflow = "hidden";
      },

      onHideModifyPullout: function(){
        this.modifyPulloutActive = false;
        document.querySelector('body').style.overflow = "auto";
      },

      showQuestionnaire: function(){
        this.questionnairePanel = true;
      },

      closeSidePanel: function(){
        this.questionnairePanel = false;
        this.showSettings = false;
      },


      loadData: function(){
        //console.log("loading configuration", this.$route.params.configuration);
        if(this.$route.params.configuration){
          this.api.load(this.$route.params.configuration).then((configuration) => {

            if(!configuration.exportSettings || Array.isArray(configuration.exportSettings)) {
              configuration.exportSettings = {
                solutions: {
                  ordering: [],
                  mis: [],
                  workflow: [],
                  composition: [],
                  admin: [],
                  mail: [],
                  other: []
                }
              }
            }

            this.configuration = configuration
          }).catch((error) => {
            console.log("ERROR",error);
          })
        }
      },

      setSection: function(section){
        this.$router.push({
          name: this.$route.name,
          params: this.$route.params,
          query: {
            section: section.id
          }
        })
      },

      saveConfiguration: function(){
        if(this.configuration){
          this.api.save(this.configuration).then((result) => {
            console.log(result);
            this.hasUnsavedChanges = false;
            alert("Changes have been saved.");
          }).catch((error) => {
            console.log(error);
            alert("Something went wrong. Try again.")
          })
        }
      },

      onRecalculate: function(){
        if(this.configuration){
          this.api.calculate(this.configuration);
          this.hasUnsavedChanges = true;
        }

      },

      onAnswerChange: function(){
        console.log("change");
        this.hasUnsavedChanges = true;
      },

      onShowDetails: function(question){
        //this.$emit('show-details',question);
        this.onShowPullout(question);
      },

      modifyRecommendation: function(question){
        //this.$emit('show-modify-panel',question);
        this.hasUnsavedChanges = true;
        this.showModifyPanel(question);
      },

      autoRecommendation: function(question){
        console.log("revert to auto")
        this.hasUnsavedChanges = true;
        this.configuration.answers[question.id].details.customConfiguration = null
      }
    },
    computed: {

      isAdmin: function(){
        return this.$store.getters.isAdmin;
      },

      questions: function(){
        return this.$store.state.printconfig.questions;
      },

      status: function(){
        return this.$store.state.printconfig.status;
      },

      title: function(){
        if(this.configuration){
          return this.configuration.projectName;
        }else{
          return "Loading Record..."
        }
      },

      sectionList: function(){
        return this.$store.getters.sectionList;
      },
      activeSectionId: function(){
        return this.$store.state.printconfig.activeSectionId
      },
      activeSection: function(){
        return this.$store.getters.activeSection
      },
      activeQuestionList: function(){
        let qs = this.$store.getters.activeQuestionList;
        return qs;
      },
    }
}
</script>

<style>

  .configuration-details {
    --primary-color: #155e8f;
    --primary-color-tint-1: #8AAEC8;
    --secondary-color-1: #316F9A;
    --secondary-color-2: #DA1F49;
    --secondary-color-3: #158f48;

    --primary-color-25: rgba(21, 94, 143, 0.25);
    --primary-color-50: #9FABB4;
    --primary-color-75: rgba(21,94,143,0.75);


    --mid-gray: #C8D3DB;
    --dark-gray: #798A98;

    --near-white-1: #eeeeee;
    --near-white-2: #f8f8f8;

    --near-black-1: #495862;
    --near-black-2: #313d45;
  }



  .configuration-details button.reversed {

    border: 1px solid var(--primary-color);
    background-color: white;
    color: var(--primary-color);


  }

  .configuration-details button.reversed:hover {
    background: var(--primary-color);
    color: white;
  }

  .configuration-details button.warning {

    border: 1px solid var(--secondary-color-2);
    background-color: var(--secondary-color-2);
    color: white;

  }

  .configuration-details button.warning:hover {
    background: white;
    color: var(--secondary-color-2);
  }

  .configuration-details button.action {

    border: 1px solid var(--secondary-color-3);
    background-color: var(--secondary-color-3);
    color: white;


  }

  .configuration-details button.action:hover {
    background: white;
    color: var(--secondary-color-3);
  }

  .configuration-details .g-condensed-regular {
    font-family: "Helvetica Neue Condensed","Helvetica Neue","Helvetica","Arial",sans-serif;
    font-weight: normal;
    font-stretch: condensed;
  }

  .configuration-details .g-condensed-bold {
    font-family: "Helvetica Neue Condensed","Helvetica Neue","Helvetica","Arial",sans-serif;
    font-weight: bold;
    font-stretch: condensed;
  }

  .configuration-details .warning {
    color: red;
  }

  .configuration-details .smaller {
    font-size: 0.8em;
  }








</style>

<style scoped>


.configuration-details {

}

.pullout-details-menu {
  position:fixed;
  min-width: 500px;
  max-width: 640px;
  height: 100%;
  top: 0;
  right: 0;
}

.pullout-modify-panel {
  position:fixed;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
}

#menu-dimmer {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0,0,0,0.3);
}

</style>
