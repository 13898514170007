


<template>
  <div id="report">

    <section class="">
      <table class="titlebox absolute text-white" style="font-size: 1vw; left: 11em; width:28em; margin-top: 11em;">
        <tr><td class="font-bold" style="font-size: 2.5em; padding-bottom: 0.5em;" data-cell-style="TitlePageTitleCell">Summary Report</td></tr>
        <tr><td class="font-bold leading-none" style="font-size: 2em; padding-bottom: 1em;" data-cell-style="TitlePageSubtitleCell">Print and Mail Operations Configuration</td></tr>
        <tr><td class="leading-snug" style="font-size: 1.2em;" data-cell-style="TitlePageCell">Confidential: For the exclusive use of Ricoh and <span>{{ r.customerProfile.organizationName?r.customerProfile.organizationName:'XXXX' }}</span>.  This document cannot be shared with any other parties without written permission from all of the parties referenced here.</td>
        </tr>
        <tr><td style="padding-bottom: 2em;">  </td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell">V1.0</td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell"><span>{{ r.userProfile.firstname }} {{ r.userProfile.lastname }}</span></td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell"><span>{{ r.userProfile.title }}</span></td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell"><span>{{ currentDate }}</span></td></tr>
      </table>

      <img class="w-full -mx-8 block" data-float="true" data-ratio="1.2941" src="/reports/title-bkg.png" />



      <p></p>



    </section>

    <section>
      <p class="font-bold no-mb">Prepared for:</p>
      <p class="font-bold no-mb "><span >{{ r.customerProfile.organizationName }}</span> - <span >{{ q.facilityContact.value }}</span></p>
      <p class="no-mb"><span >{{ q.facilityAddress.value }}</span></p>
      <p class="no-mb"><span >{{ q.facilityCity.value }}</span>, <span >{{ q.facilityState.value }}</span> <span >{{ q.facilityPostal.value }}</span></p>
      <p class="mt-8 no-mb font-bold">Prepared by:</p>
      <p class="no-mb"><span>{{ r.userProfile.firstname }}</span> <span>{{ r.userProfile.lastname }}</span></p>
      <p class="no-mb" v-if="r.userProfile.organization"><span>{{ r.userProfile.organization }}</span></p>
      <p class="no-mb"><span>{{ r.userProfile.title }}</span></p>
      <p class="no-mb mt-8"><span>{{ currentDate }}</span></p>
    </section>

    <section>
      <p class="toc-title">TABLE OF CONTENTS</p>
      <p data-toc="true" data-alias="Summary" data-levels="1-3">Table of Contents here.</p>
    </section>
    <section>
      <h1>Recommendations</h1>
      <h2>Proposed Solutions — Operation's Profile</h2>
      <p>Based on the information that was gathered as a part of this study, we have configured an onsite operation to include the following components:</p>
      <summary-report :configuration="reportData"></summary-report>
      <p></p>
    </section>



  </div>
</template>

<script>

import schema from "@/schema/siteConfig"


import SummaryReport from "@/components/PrintConfig/SummaryReport";




export default {
    name: "report-site-config-full",
  components: {
    SummaryReport,

  },
  mounted: function(){
    this.$emit("mounted");
  },
    props: {
      reportData: {
        required: true,
      }
    },
    data: function(){
        return {
          schema: schema
        }
        },
    methods: {




      dateString: function(ts){
        let date = new Date(ts);
        return (date.getMonth()+1) + "/"+date.getDate() + "/"+date.getFullYear();
      },

      editOrg: function(){
        alert("Exit preview and go to the Facility Profile section to change organization information.")
      },

      editSettings: function(){
        console.log("edit Settings");
      },

      onChange: function(e){

        this.$emit("change",e);

      },

      onSpanClick: function(e){
        console.log(e.target.title);
      },
      listValue: function(answer){

        let list = answer.notes;

        if(!list){
          return "XXXX"
        }




        let selections = list.split(",");
        if(selections.length > 2){
          selections[selections.length -1] = 'and '+selections[selections.length -1];
        }

        let result;



        if(selections.length < 3){
          result = selections.join(' and ');
        }else{
          result = selections.join(', ');
        }

        if(answer.answer.final){

          let otherText = "Other";

          let question = this.iQ[answer.questionID];


          if(question.choices){
            otherText = question.choices[question.choices.length - 1];
          }

          result = result.replace(otherText, answer.answer.final)

        }

        return result;
      },

      bulletValue: function(answer){

        let list = answer.notes;






        if(answer.answer.final){

          let otherText = "Other";

          let question = this.iQ[answer.questionID];


          if(question.choices){
            otherText = question.choices[question.choices.length - 1];
          }

          list = list.replace(otherText, answer.answer.final)


        }

        let selections = list.split(",");

        return selections;
      },
    },
    computed: {

       r: function(){
         return this.reportData;
       },

      q: function(){
        return this.r.answers;
      },

      iQ: function(){
        return this.r.interview.questions;
      },

      currentDate: function(){
        return (new Date(Date.now())).toLocaleDateString()
      },

      softwareItems: function(){
        return this.bulletValue(this.iQ.X120);
      },

      platformItems: function(){
        return this.bulletValue(this.iQ.X121);
      },

      resourceItems: function(){
        return this.bulletValue(this.iQ.X207);
      },

      solutions: function(){


        if(this.r && this.r.exportSettings){

          return this.r.exportSettings.solutions;
        }

        return {}

      },
    }
}
</script>

<style scoped>


.report-production-workflow {

}

p[data-bullet="true"]{
  display: list-item;
  margin-left: 1em;
  margin-bottom: 0;
}

p[data-bullet="true"]:last-child {
  margin-bottom: 1em;
}

</style>
