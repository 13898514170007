


<template>
<div class="login overflow-auto">
  <div class="max-w-md mx-auto p-8 border mt-12 bg-white rounded text-center shadow-lg">
        <p class="text-3xl font-bold border-b pb-4 mb-2">Ricoh Consulting Services</p>
        <h1 class="text-lg font-bold">Password Reset</h1>

        <p></p>
        <form class="mx-auto mt-6 text-left" @submit.prevent="sendReset" v-if="!resetSuccess">
          <label class="text-sm text-red-500 block mb-1" v-if="emailError">{{ emailError }}</label>
          <input class="rounded block w-full p-2 border my-2" type="text" name="username" placeholder="Username" v-model="username" @change="onEmailChange" required/>

          <label class="text-sm mt-8 block mb-1" :data-valid="isValid">New Password <span v-if="!validPassword">(Must be 12 characters or more)</span><span v-if="validPassword && !validMatch">(Passwords below must match)</span></label>
          <input class="rounded block w-full p-2 border mb-2" type="password" name="newpassword" placeholder="New Password" v-model="newpassword" autocomplete="new-password" :data-valid="isValid" required/>

          <input class="rounded block w-full p-2 border mb-2" type="password" name="confirmpassword" placeholder="Confirm Password" v-model="confirmpassword" autocomplete="new-password" :data-valid="isValid" required/>
          <p class="text-red-500 my-2" v-if="error">
            {{ error }}
          </p>
          <input class="button button-blue mt-8 block w-full p-2 cursor-pointer" type="submit" value="RESET PASSWORD" />
          <p class="text-center mt-2">
          <router-link class="text-sm mt-4 text-center underline opacity-50 hover:opacity-100 cursor-pointer" to="/login">Return to login.</router-link>
          </p>
        </form>

        <div class="mx-auto mt-6"  v-if="resetSuccess">
          <p class="pb-4">A verification code has been sent to:<br> <b>{{ username }}</b> <br>Please enter that code here to continue:</p>


          <p class="text-red-500 my-2 text-red-500 py-2" v-if="error">
            {{ error }}
          </p>
          <button @click="returnToLogin" class="button button-blue mt-8 block w-full p-2 cursor-pointer">RETURN TO LOGIN</button>

        </div>

  </div>
  <p class="text-center mt-4 opacity-75 text-sm">© 2020 Ricoh USA, Inc. All Rights Reserved.</p>
</div>
</template>

<script>
export default {
    name: "reset",
    props: {},
    data: function(){
        return {
          username: "",
          resetemail: "",
          newpassword: "",
          confirmpassword: "",
          error: "",
          resetCode: "",
          resetSuccess: false,
          emailError: ""
        }
        },
    mounted() {

      //this.$store.commit("logout");
      if(this.$route.query.r){
        this.loadData();
      }


    },
  methods: {
    sendReset: function(){
        if(!this.isValid){
          if(!this.validResetEmail){
            this.emailError = "This email does not match the reset request."
          }else if(!this.validEmail){
            this.emailError = "Enter a valid email"
          }

          alert("Something isn't right. Please re-enter your reset password and try again");
        }else{
          console.log("sending new password")

          this.$store.dispatch("setReset", {
            email: this.resetemail,
            password: this.newpassword,
            resetCode: this.$route.query.r
          })
          .then((response) => {
            if(response && response.data){
              alert("Your password has been reset.");
            }else{
              alert("Your password could not be reset at this time. Request a new reset from the login screen, or contact your Ricoh representative");
            }
            this.$router.push("/login");
          })
          .catch((error) => {
            console.error(error);
            alert("Your password could not be reset at this time. Request a new reset from the login screen, or contact your Ricoh representative");
            this.$router.push("/login");
          })

        }
    },

    returnToLogin: function(){
      this.$store.commit("logout");
    },

    onEmailChange: function(){
      if(this.username === this.resetemail){
        this.emailError = "";
      }else{
        this.emailError = "This email does not match the reset request."
      }
    },

    loadData: function(){
       this.$store.dispatch("getReset", this.$route.query.r)
      .then((response) => {
        console.log(response)
        if(response.data && response.data.email){
          this.username = response.data.email;
          this.resetemail = response.data.email;
        }else{
          this.error = "This reset is no longer valid."
        }
      })
      .catch((error) => {
        console.error(error)
      })
    }

    },
    computed: {
      validResetEmail: function(){
        return (this.resetemail === this.username);
      },

      validEmail: function() {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.username)
      },

      validPassword: function(){
        return (this.newpassword.length >= 12)
      },

      validMatch: function(){
        return (this.newpassword === this.confirmpassword)
      },

      isValid: function(){
        return (this.validResetEmail && this.validEmail && this.validPassword && this.validMatch);
      },
    }
}
</script>

<style scoped>


.login {

}

input[data-valid="true"]{
  @apply border-green-500 bg-green-50;
}

label[data-valid="true"]:after{
  content: "\01f44d";
}

</style>