


<template>
<div class="">
  <h4>Ricoh Marketing Services</h4>
  <hr>
  <p>Ricoh Marketing Services offers a range of features and services to help businesses execute integrated marketing campaigns effectively.  Overall, Ricoh Marketing Services aims to empower businesses with comprehensive marketing solutions, leveraging data-driven strategies, creative design, and advanced printing technologies to enhance customer engagement and drive business growth.</p>
  <p class="bold-label mt-4">Collaboration and Distribution:</p>
  <ul>
   <li>Project Collaboration: Empowers marketing teams to plan projects, track feedback and revisions, hit deadlines, and provide reporting and surveys.</li>
   <li>Sales Enablement: Optimizes the sales process through distribution, personalization, lead tracking, and analytics.</li>
   <li>Digital Asset Management: Provides a centralized platform to manage and distribute digital assets across multiple platforms.</li>
   <li>Multi-platform Integration: Ensures seamless integration of marketing infrastructure and safeguarding of brand consistency.</li>
  </ul>

  <p class="bold-label mt-4">Campaign Management:</p>
  <ul>
   <li>Omni-Channel Campaigns: Enables the creation and deployment of personalized marketing messages across various communication channels, including email, web, print, mobile, and social media.</li>
   <li>Paid Digital Media Buys: Facilitates strategic media buying for digital advertising campaigns.</li>
   <li>Marketing Automation Services: Offers automation solutions to streamline marketing processes and improve efficiency.</li>
   <li>Marketing Attribution: Provides a dashboard for tracking and analyzing marketing performance and lead scoring for B2B sales attribution.</li>
  </ul>

  <p class="bold-label mt-4">Audience Engagement and Personalization:</p>
  <ul>
   <li>Augmented Reality: Utilizes immersive AR experiences to enhance marketing strategies and engage customers.</li>
   <li>CCM/CXM Hyper Personalization: Delivers personalized communications and experiences through data-driven insights.</li>
   <li>Variable Data Printing: Enables the creation of customized marketing materials with variable content.</li>
   <li>Creative Design: Provides design consultation, video production and editing, copywriting, and brand consistency services.</li>
  </ul>

  <p class="bold-label mt-4">Assessments and Consulting:</p>
  <ul>
   <li>Planning and Consultative Support: Offers support in project planning, ideation, audience definition, and storytelling.</li>
   <li>Creation: Provides services such as copywriting, web development, graphic design, and videography.</li>
   <li>Analytics: Offers real-time analytics dashboards and post-campaign analysis for data-driven decision-making.</li>
   <li>Audience Engagement: Utilizes strategies like augmented reality, transpromo, and hyper-personalization to engage the target audience.</li>
  </ul>

  <p class="bold-label mt-4">Attribution and Lead Scoring/Process Improvement:</p>
  <ul>
   <li>Digital Advertising Attribution: Tracks the user journey across platforms and provides insights for optimizing marketing campaigns.</li>
   <li>B2B Lead Scoring and Martech Assessments: Assesses marketing automation tools and provides recommendations for maximizing utilization and future growth.</li>
  </ul>

  <p class="bold-label mt-4">Creative Design:</p>
  <ul>
   <li>Graphic Design: Offers quick design services, copywriting, and editing to support creative and innovative marketing messages.</li>
   <li>Video Production: Provides on-site and remote video production services, including editing, graphics, effects, and voice-over recording.</li>
  </ul>

</div>
</template>

<script>
export default {
    name: "marketing-automation",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


</style>
