<template>

  <div class="home flex flex-col h-full relative">
    <div class="text-xs px-8 py-2 pt-4 bg-white">
      <router-link to="/">Home</router-link> /
      <router-link to="/printshop-config">Print Shop Configurations</router-link>

    </div>
    <div class="bg-white">

      <div class="flex justify-between w-full pt-0  pb-4 px-8">
        <h1 class="text-3xl leading-none">My Configurations</h1>
        <div class="flex -mt-2">

          <button v-if="$store.getters.isAdmin" class="button mr-2 py-2 px-4" @click="exportSchema"><span class="flex items-center"><span>Export Questions</span></span></button>

          <button class="button button-blue" @click="showNewConfiguration"><img class="inline-block mr-2 h-3" src="../assets/icons/new-doc-icon.svg" />New Configuration</button>
        </div>
      </div>


    </div>

    <div class="border-t-4 px-8 py-4 overflow-y-scroll">
      <table class="w-full">
        <thead>
        <tr class="text-left">
          <th class="md:w-1/4 pr-2 pl-4 hover:underline cursor-pointer" @click="setSort(3)">Organization</th>
          <th class="md:w-1/4 pr-2 hover:underline cursor-pointer" @click="setSort(4)">Configuration</th>
          <th class="px-2 hover:underline cursor-pointer" @click="setSort(0)">Last Updated</th>
          <th class="px-2 hover:underline cursor-pointer" @click="setSort(1)">User</th>
          <th class=""></th>

        </tr>
        </thead>
        <tbody>
        <tr class="hover:bg-blue-50" v-for="configuration in sortedConfigurations" :key="configuration[2]">
          <td class="md:w-1/4 pr-2 pl-4"><router-link :to="getConfigurationUrl(configuration)" class="hover:underline">{{ configuration[3] }}</router-link></td>
          <td class=" pr-2"><span>{{ configuration[4] }}</span></td>
          <td class="px-2"><span>{{ configuration[0] | formatTS }}</span></td>
          <td class="px-2"><span v-if="configuration[4]">{{ configuration[1] }}</span></td>
          <td class=""><img @click="deleteRecord(configuration)" class="h-4 opacity-50 hover:opacity-100 cursor-pointer" src="../assets/icons/delete-icon.svg" /></td>

        </tr>
        </tbody>

      </table>
      <p class="pt-4 ml-4" v-if="loading">Loading records...</p>
      <p class="pt-4 ml-4" v-if="configurations && !configurations.length">No records found.</p>
    </div>

    <div class="fixed w-screen h-screen top-0 right-0 bg-black bg-opacity-50" v-if="sidePanel"></div>

    <transition name="slide">
      <div class="fixed w-full h-full max-w-xl top-0 right-0 shadow-lg" v-if="sidePanel">
        <side-panel-new-configuration class="side-panel absolute top-0 right-0 w-full h-screen bg-white border-l bg-gray-50 overflow-y-scroll" @close="closeSidePanel" @created="onConfigurationCreated" :api="api"></side-panel-new-configuration>
      </div>
    </transition>


  </div>


</template>

<script>
// @ is an alias to /src

import printconfig from "@/plugins/printconfig";
import SidePanelNewConfiguration from "@/components/SidePanelNewConfiguration";
import {utils, writeFileXLSX} from "xlsx";
export default {
  name: 'PrintShopConfig',
  components: {
    SidePanelNewConfiguration

  },
  data: function(){
    return {
      sidePanel: false,
      loading: false,
      sortKey: 0,
      secondarySortKey: 0,
      sortDirection: 1,
      api: printconfig
    }
  },
  mounted: function(){


    //if(!this.$store.getters.configurations.length ){
    this.loadData();
    //}


  },
  methods: {

    exportSchema: function(){



      let schema = this.api.schema

      let questions = Object.keys(schema.questions).map((id)=>{
        let q = schema.questions[id];
        return [
          q.id,
          q.question,
          q.type,
          q.hint,
          q.caption,
          q.dataFormat
        ]
      })


      questions.unshift([
        "ID",
        "Question",
        "Type",
        "Hint",
        "Caption",
        "Format"
      ])




      let workbook = utils.book_new();
      if(!workbook.Props) workbook.Props = {};
      workbook.Props.Title = "Workflow Assessment Question List";




      console.log('data',questions);
      let listing = utils.aoa_to_sheet(questions)

      utils.book_append_sheet(workbook,listing,"Configurator Questions")

      console.log("writing file...")

      writeFileXLSX(workbook, 'Print Shop Configurator Questions.xlsx');

      console.log("export complete")
    },

    setSort(key){

      if(key === this.sortKey){
        this.sortDirection = -this.sortDirection;
        return;
      }

      this.sortDirection = 1;
      this.sortKey = key;
    },

    fullname: function(profile){

      if(profile.lastname){
        if(profile.firstname){
          return (profile.firstname.slice(0,1) + " " + profile.lastname);
        }else{
          return profile.lastname
        }
      }

      return "Unknown"
    },

    deleteRecord: function(record){
      if(confirm("Delete this record permanently?")){
        printconfig.delete(record[2]).then((response)=> {
          console.log(response);
          this.loadData();
        })
      }
    },

    getConfigurationUrl: function(configuration){
      if(configuration){
        return "/printshop-config/"+configuration[2];
      }

      return "#";

    },


    loadData: function(){
      this.loading = true;
      let user = this.$store.getters.getUserProfile;
      return printconfig.getAll({ user})
          .then((response) => {
            //console.log(response);
            this.loading = false;
            this.$store.commit("setConfigurations", response);
          })
          .catch((error) => {
            console.error(error);
            this.loading = false;
            alert("There was a problem loading your documents.")
          })
    },

    closeSidePanel: function(){
      this.sidePanel = false;
    },

    showNewConfiguration: function(){
      this.sidePanel = true;
    },

    onConfigurationCreated: function(){
      this.loadData().then(() => {
        this.closeSidePanel();
      })
    }


  },

  computed: {
    configurations: function(){
      return this.$store.getters.configurations.filter((configuration) => {
        return (configuration[4]);
      });
    },

    sortedConfigurations: function(){
      if(this.configurations && this.configurations.length){
        return [...this.configurations].sort((a,b) => {
          if(a[this.sortKey] < b[this.sortKey]){
            return (this.sortDirection);
          }else if(a[this.sortKey] > b[this.sortKey]){
            return -(this.sortDirection)
          }else if(this.sortKey !== this.secondarySortKey){


            if(a[this.secondarySortKey] < b[this.secondarySortKey]){
              return 1;
            }else if(a[this.secondarySortKey] > b[this.secondarySortKey]){
              return -1;
            }
          }

          return 0
        })
      }
      return [];
    }
  }
}
</script>
