


<template>
<div class="survey flex flex-col h-full relative">

  <div class="flex-grow relative flex overflow-hidden">
    <div class="text-sm w-32 mt-8 ml-8">
      <link-list class="" :links="sections" :selection="currentSection" @change="setSection"></link-list>
    </div>
    <div class="flex-grow ml-8 border-l px-8 h-full overflow-y-scroll relative" ref="tables">
      <div>
        <component class="my-8" :is="currentSection.component" @summary="goSummary" :instructions="currentSection.instructions" :options="currentSection" :analysis="analysis" :api="api" @change="$emit('change')" @note="editNote"></component>
      </div>
    </div>
  </div>
  <div>


  </div>


</div>

</template>

<script>
import LinkList from "@/components/LinkList";
import NeedsSummaryExport from "@/components/NeedsAnalysis/NeedsSummaryExport";
import NeedsSummaryNotes from "@/components/NeedsAnalysis/NeedsSummaryNotes";





export default {
    name: "needs-summary-panel",
  components: {NeedsSummaryNotes, NeedsSummaryExport, LinkList },
  props: {
      analysis: {
        type: Object,
        required: true
      },
    api: {
      type: Object,
      required: true
    }
  },
  mounted: function(){

  },
    data: function(){
        return {
          sections:[
            { label: "Export", id: "reportexport", component: "NeedsSummaryExport",
              instructions: "Use the fields below to add additional analysis and export a summary document.",
            },
            { label: "Background Notes", id: "reportbackground", component: "NeedsSummaryNotes",
              instructions: "Enter any additional team notes and analysis for this report.",
              meta: { key: "background", description: "Team comments and notes that will appear in the \"Background\" section of the report.", next:{
                  name: "NeedsAnalysisDetails", tab: "summary", section:"reportexport", label: "Report Export Options"
                }, back:{
                  name: "NeedsAnalysisDetails", tab: "summary", section:"reportsummary", label: "Summary Notes"
                }  }
            },
            { label: "Summary Notes", id: "reportsummary", component: "NeedsSummaryNotes",
              instructions: "Enter any additional team notes and analysis for this report.",
              meta: { key: "summary", description: "Team comments and notes that will appear in the \"Summary\" section of the report.", next:{
                  name: "NeedsAnalysisDetails", tab: "summary", section:"reportexport", label: "Report Export Options"
                }, back:{
                  name: "NeedsAnalysisDetails", tab: "summary", section:"reportbackground", label: "Background Notes"
                }  }
            },

          ]
        }
        },
    methods: {

      editNote: function(e){
        console.log("note",e);
        this.$emit("note",e)
      },



      setSection: function(section){

        console.log("goto", section.id);
        this.$router.push({
          name: this.$route.name,
          params: this.$route.params,
          query: {
            tab: this.$route.query.tab,
            section: section.id
          }
        })

        this.$refs.tables.scrollTo(0,0);
      },

      goToSection: function(sectionId){
        this.setSection({ id: sectionId });
      },

      goNext: function(){
        let currentIndex = this.sections.indexOf(this.currentSection);


        let nextSection = this.sections[currentIndex + 1];


        if((currentIndex + 1) < this.sections.length){
          this.setSection(nextSection);
        }
      },

      goSummary: function(){

      }
    },
    computed: {
      currentSection: function(){
        //console.log("current section?");

        //console.log(this.sections);
        var section = this.sections.find((item) => {
          return item.id == this.$route.query.section;
        })

        if(!section){
          section = this.sections[0];
        }

        return section;
      }
    }
}
</script>

<style scoped>


.survey {

}

</style>
