


<template>


            <span class="relative rounded-full justify-center items-center border border-gray-400 text-gray-400 hover:text-brand-blue hover:border-brand-blue leading-none inline-flex" :class="circleClass">
              <svg class="absolute w-full text-center top-1/2 transform -translate-y-1/2 stroke-current" :class="plusClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <g transform="translate(-6 -6)">
                  <path id="Path_1" data-name="Path 1" d="M18,7.5v21" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                  <path id="Path_2" data-name="Path 2" d="M7.5,18h21" fill="none"  stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
                </g>
              </svg>
            </span>


</template>

<script>
export default {
    name: "plus-icon",
    props: {
      size: {
        type: String,
        default: "8"
      }
    },
    data: function(){
        return {}
        },
    methods: {},
    computed: {
      plusSize: function(){
        return parseInt(this.size / 2);
      },
      plusClass: function(){
        return `w-${this.plusSize} h-${this.plusSize}`
      },
      circleClass: function(){
        return `w-${this.size} h-${this.size}`
      }
    }
}
</script>

<style scoped>


.plus-icon {

}

</style>
