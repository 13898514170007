export default {
    requirements: {
        low: {
            id: 'low',
            name: 'Low',
            totalFootage: 72,
            staffing: 0,
            staffingCode: "da",
            equipment: [],
            software: [],
            otherItems: []
        },

        medium: {
            id: 'medium',
            name: 'Medium',
            totalFootage: 324,
            staffing:0,
            staffingCode: "da",
            equipment: [],
            software: [],
            otherItems: []
        },

        high: {
            id: 'high',
            name: 'High',
            totalFootage: 684,
            staffing: 0,
            staffingCode: "da",
            equipment: [],
            software: [],
            otherItems: []
        },
    },

    ratingDetails: [
        {
            id: 'level',
            type: 'enum',
            required: false,
            component: 'PulloutQuestionMultiSwitchList',
            default: 0,
            label: "Level of Need:",
            options: [
                {
                    id: "none",
                    label: "None",
                    value: 0
                },

                {
                    id: "low",
                    label: "Small",
                    description: "Small fulfillment storage (50 11 x 17 bins)",
                    value: 1
                },
                {
                    id: "medium",
                    label: "Medium",
                    description: "Medium fulfillment storage (100 11 x 17 bins, 3 pallets)",
                    value: 2
                },
                {
                    id: "high",
                    label: "High",
                    description: "Large fulfillment storage (150 11 x 17 bins, 9 pallets)",
                    value: 3
                }
            ]
        }

    ]
}
