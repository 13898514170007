


<template>
<div class="efi-pace">
  <h4>ePS Pace MIS/ERP</h4>
  <hr>
  <p>ePS Pace is a proven best-in-class browser-based MIS/ERP solution exclusively designed for the print industry. Improve efficiencies, cut costs, and maximize profit potential with this powerful solution.</p>
  <p>With a modular architecture, ePS Pace gives you the tools you need today and the flexibility you’ll require tomorrow. It allows you to grow the solution as your business requirements change and evolve, easily adapting to new processes without interrupting your business operations. Pace uses modern open-source technologies and is supported by ePS’s continuous investment in innovation, providing a long life cycle that translates into years of productivity and a significant return on investment.</p>
  <p>ePS Pace provides advanced integrations to ePS’s leading solutions in eCommerce, marketing, shipping, warehouse and fulfillment management, and planning and imposition. As part of ePS’s Midmarket Print Suite, Pace offers smart software solutions in an integrated and certified workflow, allowing for an end-to-end approach to process improvement.</p>
  <p>As information flows seamlessly, processes become automated and your company benefits from increased operation efficiencies:</p>
  <ul>
    <li>Eliminate human errors by sharing information automatically</li>
    <li>Reduce touchpoints by streamlining operations</li>
    <li>Cut costs by maximizing resources</li>
  </ul>
  <p class="bold-label">Base Features</p>
  <ul>
    <li>Estimating</li>
    <li>Job control</li>
    <li>Accounts receivable</li>
    <li>Job billing</li>
    <li>Job history</li>
    <li>Pace Station</li>
    <li>Inventory</li>
    <li>Purchase orders</li>
    <li>Change orders</li>
    <li>Advanced accounting</li>
    <li>Accounts payable</li>
    <li>Business intelligence tool</li>
    <li>General ledger</li>
    <li>Fixed assets</li>
    <li>Payroll</li>
    <li>Item templates</li>
    <li>Questionnaire</li>
    <li>Custom reporting</li>
    <li>CRM</li>
    <li>Charts and graphs</li>
    <li>Credit card</li>
    <li>Price list quoting</li>
    <li>Data collection</li>
    <li>Job costing</li>
    <li>Mailing</li>
    <li>Multi-currency</li>
    <li>Tax distribution</li>
    <li>LDAP</li>
    <li>Universal importer</li>
    <li>Paper certification authority</li>
    <li>Pace scheduling</li>
    <li>Customization toolkit</li>
    <li>XML Data Object Importer</li>
    <li>User defined alerts, translations, defaults, fields/lists, objects</li>
    <li>API</li>
  </ul>

</div>
</template>

<script>
export default {
    name: "efi-pace",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


.efi-pace {

}

</style>