


<template>
<div class="report-generator">
  <div class="px-8 py-4 border-b w-full sticky top-0 bg-white z-30 flex items-center justify-end">

    <button class="button mr-4" @click="saveEdits" v-if="hasUnsavedChanges">Save Edits</button>
    <button class="button button-blue" @click="exportDoc">Export Doc</button>
    <div class="w-8 h-8 p-2 border-gray-300 border-2 rounded-full flex justify-center items-center mt-1 ml-3 opacity-50 hover:opacity-100 cursor-pointer" @click="exitPreview">
      <img  class="h-3 inline-block " src="../assets/icons/close-icon.svg" />
    </div>
  </div>

  <component @mounted="onTemplateMount" :is="reportTemplate.component" class="m-8 mt-16 report" ref="report" :report-data="reportData" v-if="reportTemplate && reportData" @edit="onEditField" @change="onChange"></component>


<!--  <report-production-workflow class="m-8 mt-16 report" ref="report" :report-data="reportData" v-if="reportData" @edit="onEditField" @change="onChange"></report-production-workflow>-->


  <transition name="slide">
    <div class="fixed w-full h-full top-0 right-0 shadow-lg z-30 bg-black bg-opacity-50" v-if="sidePanel">
      <div v-if="sourceQuestion" class="side-panel absolute w-full max-w-2xl top-0 right-0 w-full h-screen bg-white border-l bg-gray-50 overflow-y-scroll">
        <div class="flex justify-between items-center px-8 py-4 border-b bg-white">
          <p class="text-xl leading-none">{{ sourceQuestion.question.id }}</p>
          <div class="w-8 h-8 border-gray-300 border-2 rounded-full flex justify-center items-center ml-3 opacity-50 hover:opacity-100 cursor-pointer" @click="closeSidePanel">
            <img  class="h-3 inline-block " src="../assets/icons/close-icon.svg" />
          </div>
        </div>
        <div class="py-8" v-if="sourceQuestion.question.id.startsWith('S')">

          <p class="px-8">Scorecard Rating:</p>
          <p class="font-bold text-xl px-8 my-2">{{ sourceQuestion.title }}</p>

          <div class="my-4 px-8"><span class="border p-4 font-bold text-3xl inline-flex w-16  items-center justify-center aspect-square">{{ sourceQuestion.answer.answer.pre }}</span></div>
          <div class="px-8 my-4" v-html=" sourceQuestion.criteria"></div>
          <p class="px-8 py-4 underline text-brand-blue" @click="onEditScorecard">Edit Scorecard</p>




        </div>
        <div v-else class="py-8">

          <component :is="questionTypes[sourceQuestion.question.type]"  :question="sourceQuestion.question" :answer="sourceQuestion.answer" class="question flex items-center pb-4 border-b bg-gray-50 mx-8"  @change="onChange">
          </component>

          <p class="px-8 py-4 text-red-600">Warning:<br>Any edits you make here changes this value everywhere in this assessment. Any calculations relying on this value will also be recalculated.</p>
          <p class="px-8 py-4 text-red-600">This document will need to be saved before you close this preview if you wish to keep these changes outside of preview mode.</p>

        </div>

      </div>
    </div>
  </transition>

</div>
</template>

<script>
/* eslint-disable no-unused-vars */

import { AlignmentType, PageSize, UnderlineType, BorderStyle, ShadingType, WidthType, Document, HeadingLevel, Media, Packer, Paragraph, TableOfContents, Table, TableRow, TableCell, TabStopPosition, TabStopType, TextRun } from "docx";
import { saveAs } from "file-saver"
import workflow from "@/schema/workflow";
import AssessmentScorecard from "@/components/AssessmentScorecard";
import SavingsSummaryTable from "@/components/SavingsSummaryTable";

import RoiTableVdp from "@/components/ROITableVDP";
import RoiTableVdpDetails from "@/components/ROITableVDPDetails";
import RoiTableMis from "@/components/ROITableMIS";
import RoiTableStorefront from "@/components/ROITableStorefront";

import AvantiSlingshot from "@/components/Solutions/AvantiSlingshot";
import EfiPace from "@/components/Solutions/EfiPace";
import PrintSmithVision from "@/components/Solutions/PrintSmithVision";

import FusionPro from "@/components/Solutions/FusionPro";
import PrintShopMail from "@/components/Solutions/PrintShopMail";

import MarcomCentral from "@/components/Solutions/MarcomCentral";
import WebCrd from "@/components/Solutions/WebCrd";
import MarketDirect from "@/components/Solutions/MarketDirect";
import PrintNet from "@/components/Solutions/PrintNet";

import ColorGap from "@/components/Solutions/ColorGap";
import RicohConsultingServices from "@/components/Solutions/RicohConsultingServices";
import ProductionWorkflow from "@/components/Solutions/ProductionWorkflow";
import RoiChartMisPotential from "@/components/ROIChartMISPotential";
import RoiChartMisCurrentFuture from "@/components/ROIChartMISCurrentFuture";
import RoiChartStorefrontComparison from "@/components/ROIChartStorefrontComparison";
import RoiChartVdpComparison from "@/components/ROIChartVDPComparison";

import AssessmentInterviewQuestionBoolean from "@/components/AssessmentInterviewQuestionBoolean";
import AssessmentInterviewQuestionMultiChoice from "@/components/AssessmentInterviewQuestionMultiChoice";
import AssessmentInterviewQuestionMultiCol from "@/components/AssessmentInterviewQuestionMultiCol";
import AssessmentInterviewQuestionSelect from "@/components/AssessmentInterviewQuestionSelect";
import AssessmentInterviewQuestionSingleCol from "@/components/AssessmentInterviewQuestionSingleCol";
import AssessmentInterviewQuestionTextField from "@/components/AssessmentInterviewQuestionTextField";
//import ReportProductionWorkflow from "@/views/ReportProductionWorkflow";

export default {
    name: "report-generator",
  components: {

    AssessmentInterviewQuestionTextField,
    AssessmentInterviewQuestionSingleCol,
    AssessmentInterviewQuestionSelect,
    AssessmentInterviewQuestionMultiCol,
    AssessmentInterviewQuestionMultiChoice,
    AssessmentInterviewQuestionBoolean,
    },
  props: {},
    mounted: function(){
      if(this.$route.query.r){
        this.prepareReport(this.$route.query.r);
      }
    },
    data: function(){
        return {
          reportTemplate: undefined,
          hasUnsavedChanges: false,
          solutionIds: "000000000000",
          disabled: true,
          r: undefined,
          sidePanel: false,
          sourceQuestion: undefined,

        }
    },
    methods: {

      onEditScorecard: function(){



        this.$router.push(`/assessments/${this.$route.query.r}?tab=pre&section=selfassessment`)
      },

      onTemplateMount: function(){
        this.numberHeadings();
      },

      onChange: function(){
        //console.log("changes");
        this.$sdm.calculate(this.r);
        this.hasUnsavedChanges = true;
      },

      closeSidePanel: function(){
        this.sidePanel = false;
      },

      exitPreview: function(){
        if(this.hasUnsavedChanges){
          if(!confirm("You have unsaved edits. If you leave now, you will lose those changes.")){
            return;
          }
        }
        this.$router.back();
      },

      saveEdits: function(){

        if(confirm("This will save any edits you make in preview mode. Are you sure?")){
          this.$sdm.updateAssessment(this.r, this.r.id).then(() => {
            alert("Your edits have been saved.");
            this.hasUnsavedChanges = false;
          }).catch((error) => {
            console.error(error);
            alert("There was a problem with this operation. Try again.")
          })
        }

      },


      onEditField: function(fieldData){
          if(fieldData){
            this.sourceQuestion = fieldData;
            this.sidePanel = true;
          }
      },

      loadReportTemplate: function(){
        if(this.r){

          let componentName = '';

          if(this.$route.query.t){

            switch (this.$route.query.t){
              case "mail":
                this.reportTemplate = {
                  component: () => import(/* webpackChunkName: "about" */ '@/views/ReportMailWorkflow.vue')
                }
                break;
              case "pw":
                    this.reportTemplate = {
                      component: () => import(/* webpackChunkName: "about" */ '@/views/ReportProductionWorkflow.vue')
                    }
                    break;

              case "pws":
                this.reportTemplate = {
                  component: () => import(/* webpackChunkName: "about" */ '@/views/ReportProductionWorkflowSnapshot.vue')
                }

                break;

              case "ccm":
                this.reportTemplate = {
                  component: () => import(/* webpackChunkName: "about" */ '@/views/ReportCCMSnapshot.vue')
                }
                break;

              case "map":
                this.reportTemplate = {
                  component: () => import(/* webpackChunkName: "about" */ '@/views/ReportMAP.vue')
                }
                break;

              case "pmp":
                this.reportTemplate = {
                  component: () => import(/* webpackChunkName: "about" */ '@/views/ReportPMP.vue')
                }
                break;
            }

          }


        }
      },

      prepareReport: function(id){
        this.$sdm.loadAssessment(id).then((response) => {
          this.$sdm.calculate(response);
          this.r = response;
          this.loadReportTemplate();
        })

      },

      numberHeadings: function(){

        let counters = {
          H1: 0,
          H2: 0,
          H3: 0
        }

        //console.log("reportRef", this.$refs.report);

        this.$refs.report.$el.querySelectorAll('section').forEach((section) => {
              section.children.forEach((node) => {
                if(node.nodeName === "H1"){
                  counters.H1++;
                  counters.H2 = 0;
                  counters.H3 = 0;
                  node.dataset.num = counters.H1+".";
                }else if(node.nodeName === "H2"){
                  counters.H2++;
                  counters.H3 = 0;
                  node.dataset.num = counters.H1 +"."+counters.H2
                }else if(node.nodeName === "H3"){
                  counters.H3++;
                  node.dataset.num = counters.H1 +"."+counters.H2+"."+counters.H3;
                }else if(node.dataset.container){
                  node.children.forEach((subnode) => {
                    if(subnode.nodeName === "H1"){
                      counters.H1++;
                      counters.H2 = 0;
                      counters.H3 = 0;
                      subnode.dataset.num = counters.H1+".";
                    }else if(subnode.nodeName === "H2"){
                      counters.H2++;
                      counters.H3 = 0;
                      subnode.dataset.num = counters.H1 +"."+counters.H2
                    }else if(subnode.nodeName === "H3"){
                      counters.H3++;
                      subnode.dataset.num = counters.H1 +"."+counters.H2+"."+counters.H3;
                    }
                  })
                }
              });
        })
      },

      exportDoc: async function(){
        await this.$docx.generateFile(this.$refs.report.$el, {
          title: "Findings Report",
          description: "Production Workflow Assessment",
          numberHeadings: { H1:true, H2: true, H3: true }
        })
      },



    },
    computed: {

      reportData: function(){
        return this.r;
      },

      questionTypes: function(){
        return this.$store.getters.questionTypes;
      },



    }
}
</script>

<style>


.report-generator {

}

.report section {
  @apply mb-16 pb-16 border-b-2 border-red-600;
}

.report section h1 {
  @apply text-2xl font-bold mb-4;
}

.report section h2 {
  @apply text-lg font-bold mt-8 mb-2 text-red-700;
}

.report section h3 {
  @apply font-bold;
}

.report section h4 {
  @apply font-bold text-lg mt-4;
}

.report hr {
  @apply mb-4;
}

.report .container + .container {
  @apply border-t;
}

.report section h1 + h2 {
  @apply mt-4;
}

.report section p {
  @apply mb-4;
}

.report section p.no-mb {
  @apply mb-0;
}


.report section ul {
  @apply ml-4 mb-4;
}

.report section ol {
  @apply ml-4 mb-4;
}

.report section span[title] {
  @apply bg-yellow-100 cursor-pointer;
}

.report section .span[title] {
  @apply bg-yellow-100 cursor-pointer;
}

.report section span:hover {
  @apply bg-yellow-200
}

.report section span.no-hover:hover {
  @apply bg-transparent
}


.report section th {
  @apply bg-blue-100 border border-black p-2;
}

.report section td {
  @apply border border-black p-2;
}

.report section h1:before {
  content: attr(data-num);
  @apply mr-2;
}

.report section h2:before {
  content: attr(data-num);
  @apply mr-2;
}

.report section h3:before {
  content: attr(data-num);
  @apply mr-2;
}

.report section .title {
  @apply text-2xl font-bold mb-0;
}

.report section .subtitle {
  @apply text-lg font-bold mb-4;
}

.report section table.titlebox tr {
  @apply border-none;
}

.report section .titlebox th {
  @apply bg-blue-100 border-none p-0;
}

.report section .titlebox td {
  @apply border-none p-0;
}

.report section .titlebox span {
  background-color: transparent;
}

.report section .bold-label {
  @apply font-bold mb-0;
}

.report section ul {
  @apply list-disc;
}


</style>
