<template>
  <div class="production-workflow">
    <h4>Production Workflow Automation Services</h4>
    <hr>
    <p>To ensure your solution is implemented in a timely fashion while reducing resources required from Customer, Ricoh is proposing full implementation services. Ricoh is the only vendor currently offering full implementation services. </p>
    <p>Implementation services differ in that Ricoh would perform all configuration responsibilities, allowing Customer to focus on supporting their core business functions. Additionally, the labor typically required during the highly active initial platform setup would be handled by Ricoh services, minimizing impact to the current staff’s workload.  </p>
    <p>Once the solution has been fully implemented, Ricoh assists Customer on the transition to self-sufficiency.</p>
    <p>Implementation services accelerate the implementation timeframe, allowing Customer to receive the return on their investment in a shorter timeframe. Ricoh’s customers, on average, achieved a typical reduction of 40-60% in their timetable when utilizing Ricoh implementation services to complete standing up the solution.  A typical Web to Print implementation of 3-6 months without additional services can be shortened to as little as 1-2 months, depending on the full scope of products to be included.</p>
    <p>While these implementation services do not replace any training that you will receive, the Ricoh team will review the system with you after training is complete to ensure you understand how to use what you’ve learned for your specific environment.</p>
    <p class="bold-label">How Implementation Services Differ from Vendor Training Services</p>
    <p>While most solutions provide “training” services, Ricoh is a unique vendor in regard to providing implementation services. </p>
    <p>Training services typically consist of a vendor training the customer on how to configure specific aspects of their solution. For example, the vendor could train a customer on configuring their paper catalog, creating users, adding pricing, creating products, and much more. The time to implement a solution could be anywhere from 6-24 months, depending on the customer’s expertise and labor bandwidth.</p>
    <p></p>
  </div>
</template>

<script>
export default {
  name: "production-workflow",
  props: {},
  data: function () {
    return {}
  },
  methods: {},
  computed: {}
}
</script>

<style scoped>


.production-workflow {

}

</style>