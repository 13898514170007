


<template>
<div class="summary-export max-w-3xl">
  <div class="mb-8">
    <p class="py-4 px-6 bg-gray-100 border rounded font-bold" v-if="options.instructions">{{ options.instructions }}</p>
  </div>




  <div class="py-4">
    <div class=" pb-2 border-b-2">
      <h2 class="font-bold text-xl pb-1">{{ options.label }}</h2>
      <p class="mb-2">{{ options.meta.description }}</p>
    </div>
    <div>

      <div class="max-w-2xl inline-flex items-center group relative cursor-pointer my-4"  @click="newResponse(options.meta.key)">
        <plus-icon size="8"></plus-icon>
        <span class="text-xs text-center inline-block p-1 px-2 bg-brand-blue text-white rounded ml-2" >Add Note</span>
      </div>
      <div class="max-w-2xl mb-4" v-for="response in filteredNotes" :key="response.id">

        <needs-comment-bubble :schema="api.schema" :comment="response" :analysis="analysis" @click.native="editResponse(response)"></needs-comment-bubble>
      </div>

    </div>




  </div>

  <div class="pt-8 mt-4 border-t">
    <div class="pb-4 flex justify-between">
      <div class="pr-4">
        <button v-if="options.meta.back" class="button button-blue button-next" @click="showTab('back')">{{ options.meta.back.label }}</button>
      </div>
      <div class="pl-4">
        <button v-if="options.meta.next" class="button button-blue button-next" @click="showTab('next')">{{ options.meta.next.label }}</button>
      </div>

    </div>
  </div>




</div>
</template>

<script>

import PlusIcon from "@/components/PlusIcon";
import NeedsCommentBubble from "@/components/NeedsAnalysis/NeedsCommentBubble";
export default {
    name: "needs-summary-notes",
  components: {NeedsCommentBubble, PlusIcon},
  props: {
      analysis: {
        type: Object,
        required: true
      },
      api: {
        type: Object,
        required: true
      },
      options: {
        default: function(){
          return {
            label: "Solution Costs",
            costsKey: "",
            instructions: "Costs instructions"
          }
        }
      }
    },
    mounted() {

    },
  data: function(){
        return {
          hasUnsavedChanges: false
        }
        },
    methods: {


      onChange: function(){
          this.$emit("change");
      },

      newResponse: function(noteType){
        let noteInfo = {
          questionId: noteType,
          category: "notes"
        }
        this.$emit("note",noteInfo)
      },

      editResponse: function(response){
        let noteInfo = {
          questionId: response.question,
          category: "notes",
          responseId: response.id
        }
        this.$emit("note", noteInfo)
      },

      showTab: function(key){
        this.$router.push({
          name: this.options.meta[key].name,
          params: this.$route.params,
          query: {
            tab: this.options.meta[key].tab,
            section: this.options.meta[key].section
          }
        })
      }
    },
    computed: {

      notes: function(){
        if(this.analysis && this.analysis.notes){
          return Object.keys(this.analysis.notes).map((id) => {
            return this.analysis.notes[id];
          })
        }

        return []
      },

      filteredNotes: function(){

        return this.notes.filter((note) => {
          return note.question === this.options.meta.key
        }).sort((a,b) => {
          return (a.ts - b.ts);
        })

      },


    }
}
</script>

<style scoped>


.summary-export {

}

</style>
