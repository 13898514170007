<template>
  <div class="efifiery-job-master">
      <h4>EFI Fiery Job Master</h4>
    <hr>
    <p>Fiery® JobMaster™, a key product of the Fiery Workflow Suite, provides advanced PDF-based job preparation functions. Print operators will be more productive when performing make-ready tasks with complex documents. Intuitive document assembly includes fully visual tab insertion and design, media assignment, advanced page numbering, finishing, scanning, and powerful late-stage editing features.</p>
    <p>JobMaster launches from Command Workstation® providing users the flexibility to run on either a PC or Mac, the familiar Fiery user interface and easily maintain and access without installing on a separate server.</p>
    <p class="bold-label">Highlights:</p>
    <ul>
      <li>Previews jobs in page and sheet views, displaying media attributes at a glance for quick job navigation.</li>
      <li>Provides state-of-the-art tab creation tools with a WYSIWYG preview.</li>
      <li>Integrates and cleans up hard-copy pages.</li>
      <li>Provides PDF-based make-ready functions, including flexible document assembly that allows drag-and-drop merging of pages.</li>
      <li>Includes Enfocus Pitstop Edit and Adobe® Acrobat® for quick and easy last-minute text, spot color or image changes in PDF document.</li>
      <li>Allows users to make the correct finishing decision and see in detail the impact of various finishing options.</li>
      <li>Provides easy configuration of page ranges or subsets within jobs.</li>
      <li>Allows users to clean up hard copy pages inside the Fiery workflow.</li>
    </ul>
    <p class="bold-label">Summary:</p>
    <ul>
      <li>Produce error-free, higher-margin jobs such as catalogs, manuals, proposals, reports, and books.</li>
      <li>Speed up job preparation using a flexible and integrated solution.</li>
      <li>Reduce waste and save time with interactive and fully visual job previews.</li>
    </ul>

  </div>
</template>

<script>
export default {
  name: "efi-fiery-job-master",
  props: {},
  data: function () {
    return {}
  },
  methods: {},
  computed: {}
}
</script>

<style scoped>


.eficross-media {

}

</style>