


<template>
  <div v-if="r" id="report">

    <section class="">
      <table class="titlebox absolute text-white" style="font-size: 1vw; left: 11em; width:28em; margin-top: 11em;">
        <tr><td class="font-bold" style="font-size: 2.5em; padding-bottom: 0.5em;" data-cell-style="TitlePageTitleCell">FINDINGS REPORT</td></tr>
        <tr><td class="font-bold leading-none" style="font-size: 2em; padding-bottom: 1em;" data-cell-style="TitlePageSubtitleCell">Mail Workflow Assessment</td></tr>
        <tr><td class="leading-snug" style="font-size: 1.2em;" data-cell-style="TitlePageCell">Confidential: For the exclusive use of Ricoh and <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span>.  This document cannot be shared with any other parties without written permission from all of the parties referenced here.</td>
        </tr>
        <tr><td style="padding-bottom: 2em;">  </td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell">V1.0</td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell"><span>{{ r.userProfile.firstname }} {{ r.userProfile.lastname }}</span></td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell"><span>{{ r.userProfile.title }}</span></td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell"><span>{{ currentDate }}</span></td></tr>
      </table>

      <img class="w-full -mx-8 block" data-float="true" data-ratio="1.2941" src="/reports/title-bkg.png" />



      <p></p>



    </section>
    <section>
      <p class="font-bold no-mb">Prepared for:</p>
      <p class="font-bold no-mb "><span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> - <span  @click="onSpanClick">{{ r.customerProfile.customerContact }}</span></p>
      <p class="no-mb"><span  @click="onSpanClick">{{ r.customerProfile.address }}</span></p>
      <p class="no-mb"><span  @click="onSpanClick">{{ r.customerProfile.city }}</span>, <span  @click="onSpanClick">{{ r.customerProfile.state }}</span> <span  @click="onSpanClick">{{ r.customerProfile.postalcode }}</span></p>
      <p class="mt-8 no-mb font-bold">Prepared by:</p>
      <p class="no-mb"><span>{{ r.userProfile.firstname }}</span> <span>{{ r.userProfile.lastname }}</span></p>
      <p class="no-mb" v-if="r.userProfile.organization"><span>{{ r.userProfile.organization }}</span></p>
      <p class="no-mb"><span>{{ r.userProfile.title }}</span></p>
      <p class="no-mb mt-8"><span>{{ currentDate }}</span></p>
    </section>



    <section>
      <p class="toc-title">TABLE OF CONTENTS</p>
      <p data-toc="true" data-alias="Table of Contents" data-levels="1-3">Table of Contents here.</p>
    </section>

    <section>
      <h1>Overview</h1>
      <h2>Background</h2>

      <p>Mail operations face challenges in today’s business environment. To thrive, organizations must be innovative, adaptable, and central to the ongoing mission of the customers they serve. They are looking for ways to control and reduce mailing costs. Understanding the customer, improving efficiency, ensuring compliance, providing added value and complementary services, and focusing on continuous improvement are all critical components of a successful operation.</p>
      <p>To ensure and maintain success, it is important to take into account the following components:</p>
      <ul class="list-disc">
        <li>Configuration of equipment, software and facilities </li>
        <li>Order intake and production workflow</li>
        <li>Postage costs</li>
        <li>Database integrity</li>
      </ul>
      <p>The cost associated with the fulfillment and distribution of an organization’s overall document expense is estimated at 49% based on data provided by ALL Associates Group. (ALL Associates Group is a document-centric analyst firm that develops and maintains robust statistical modeling tools and techniques for the document solutions industry.)</p>
      <img class="w-full" src="/reports/predicitive-analytics.png" data-ratio="0.5923" />
      <p>Having insight and strategies to reduce the cost of fulfillment and distribution have a significant impact on the profitability and marketplace competitiveness of an organization. Facilitating an assessment of current state and developing strategies to reduce cost and increase efficiency is the cornerstone for future success.</p>


      <h2>Methodology</h2>
      <p><span>{{ r.userProfile.firstname }}</span> <span>{{ r.userProfile.lastname }}</span>, Ricoh’s <span>{{ r.userProfile.title }}</span> visited <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> on <span  @click="onSpanClick">{{ r.customerProfile.assessmentDate }}</span> to review the organization’s print and mail operation. The goal was to review all processes and customer requirements and develop strategies to impact the cost and efficiency of the operations.</p>
      <p>Components reviewed:</p>
      <ul class="list-disc">
        <li>Order intake and production workflow</li>
        <li>Production planning</li>
        <li>Production monitoring</li>
        <li>Inventory management</li>
        <li>Postage costs</li>
        <li>Database quality</li>
        <li>Financial and production reporting</li>
        <li>Customer interaction</li>
        <li>Systems integration</li>
        <li>Operation configuration (equipment, personnel, facilities)</li>
        <li>Facility space utilization and floor plans</li>
      </ul>

      <h2>Site</h2>
      <p class="no-mb"><span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> - <span  @click="onSpanClick">{{ r.customerProfile.customerContact }}</span></p>
      <p class="no-mb"><span  @click="onSpanClick">{{ r.customerProfile.address }}</span></p>
      <p class="no-mb"><span  @click="onSpanClick">{{ r.customerProfile.city }}</span>, <span  @click="onSpanClick">{{ r.customerProfile.state }}</span> <span  @click="onSpanClick">{{ r.customerProfile.postalcode }}</span></p>

      <h2>Departments Reviewed</h2>
      <table>
        <tr>
          <th>No.</th><th class="text-left">Departments/Area</th>
        </tr>
        <tr>
          <td>1</td><td>Administration</td>
        </tr>
        <tr>
          <td>2</td><td>Data Processing and Workflow </td>
        </tr>
        <tr>
          <td>3</td><td>Production-Inkjet/Inserting</td>
        </tr>
        <tr>
          <td>4</td><td>Delivery and Distribution</td>
        </tr>
      </table>

    </section>
    <section>
      <h1>Current State</h1>
      <h2>Organization</h2>
      <p><span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> is a mail service provider that processes <span title="I1003.final" @click="onSpanClick">{{ q.I1003.answer.final | decimal(0) }}</span> mail pieces per year with yearly sales of $<span title="I1001.final" @click="onSpanClick">{{ q.I1001.answer.final | decimal(0) }}</span>. They have a total of <span title="I1010.final" @click="onSpanClick">{{ q.I1010.answer.final | decimal(0) }}</span> employees with the following number of employees in the following functions:</p>
      <table>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Management:</td><td><span title="I1010A.final" @click="onSpanClick">{{ q.I1010A.answer.final | decimal(2) }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Sales:</td><td><span title="I1014.final" @click="onSpanClick">{{ q.I1014.answer.final | decimal(2) }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Administration:</td><td><span title="I1010B.final" @click="onSpanClick">{{ q.I1010B.answer.final | decimal(2) }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Mail Piece Design & Development:</td><td><span title="X3001D.final" @click="onSpanClick">{{ q.X3001D.answer.final | decimal(2) }}</span></td>
        </tr>

        <tr data-height="0.25in" data-height-rule="exact">
          <td>Prep (Mail List Processing, etc.):</td><td><span title="X3001E.final" @click="onSpanClick">{{ q.X3001E.answer.final | decimal(2) }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Insertion:</td><td><span title="X3001F.final" @click="onSpanClick">{{ q.X3001F.answer.final | decimal(2) }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Inventory Management:</td><td><span title="X3001G.final" @click="onSpanClick">{{ q.X3001G.answer.final | decimal(2) }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Shipping/Receiving:</td><td><span title="I1010H.final" @click="onSpanClick">{{ q.I1010H.answer.final | decimal(2) }}</span></td>
        </tr>
      </table>
      <p class="mt-8">The hours of operation are <span title="X3000A.notes" @click="onSpanClick">{{ q.X3000A.notes?q.X3000A.notes:"X3000A" }}</span>, <span title="X3000B.notes" @click="onSpanClick">{{ q.X3000B.notes?q.X3000B.notes:"X3000B" }}</span></p>

      <h2>Products and Services</h2>
      <p><span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> offers the following products and services: <span title="X3002.notes" @click="onSpanClick">{{ q.X3002.notes }}</span>.</p>
      <p><span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> is utilizing the following USPS postal promotions: <span title="X3003.notes" @click="onSpanClick">{{ q.X3003.notes }}</span></p>
      <p>In addition to mailing services, <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> prints materials for their customers with b/w 8.5 x 11 page volumes of <span title="X3002B.final" @click="onSpanClick">{{ q.X3002B.answer.final | decimal(0) }}</span> per year and color 8.5 x 11 page volumes of <span title="X3002C.final" @click="onSpanClick">{{ q.X3002C.answer.final | decimal(0) }}</span> per year.</p>


      <h2>Software and Equipment</h2>
      <p>Software and platforms used by the organization includes:</p>
      <p class="ml-8 no-mb font-bold">Software:</p>
      <p class="ml-8 font-bold"><span title="X3004.notes" @click="onSpanClick">{{ q.X3004.notes?q.X3004.notes:"X3004" }}</span></p>
      <p class="ml-8 no-mb font-bold">Equipment:</p>
      <p class="ml-8 font-bold"><span title="X3005.notes" @click="onSpanClick">{{ q.X3005.notes?q.X3005.notes:"X3005" }}</span></p>

      <h2>Customer Base</h2>
      <p>The main customers of the mail operation include <span title="X3006.notes" @click="onSpanClick">{{ q.X3006.notes?q.X3006.notes:"X3006" }}</span></p>

      <h2>Workflow</h2>
      <h3>Mail Administration</h3>
      <p>There is a total of <span title="X3001K.final" @click="onSpanClick">{{ q.X3001K.answer.final | decimal(2) }}</span> employees in administrative roles within the operation including customer service, management and sales.</p>
      <p><span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> routes mailings to the USPS <span title="X3007.final" @click="onSpanClick">{{ q.X3007.answer.final | decimal(0) }}</span> times per day. They <span title="X3008.notes" @click="onSpanClick">{{ (q.X3008.notes === 'Yes')?'have':'do not have' }}</span> a Mailer ID and include the following mailing address(es): <span title="X3009.notes" @click="onSpanClick">{{ q.X3009.notes }}</span>.</p>
      <p>The total value of postage processed by the organization is estimated at $<span title="X3010.final" @click="onSpanClick">{{ q.X3010.answer.final | decimal(0) }}</span>.</p>
      <p>The classifications of mail that are processed include:  <span title="X3011.notes" @click="onSpanClick">{{ listNotes(q.X3011) }}</span>. First class mail <span title="X3012.notes" @click="onSpanClick">{{ (q.X3012.notes === "Yes")?"is":"is not" }}</span> pre-sorted. Postage is applied through <span title="X3013.notes" @click="onSpanClick">{{ listNotes(q.X3013) }}</span>. The average cost for Certified mail is $<span title="X3014A.final" @click="onSpanClick">{{ q.X3014A.answer.final | decimal(2) }}</span>, Priority mail is $<span title="X3014B.final" @click="onSpanClick">{{ q.X3014B.answer.final | decimal(2) }}</span>, First-Class mail is $<span title="X3014C.final" @click="onSpanClick">{{ q.X3014C.answer.final | decimal(2) }}</span>, Periodicals is $<span title="X3014D.final" @click="onSpanClick">{{ q.X3014D.answer.final | decimal(2) }}</span>, Marketing Mail is $<span title="X3014E.final" @click="onSpanClick">{{ q.X3014E.answer.final | decimal(2) }}</span> Packages is $<span title="X3014F.final" @click="onSpanClick">{{ q.X3014F.answer.final | decimal(2) }}</span>, and Non-Profit is $<span title="X3014G.final" @click="onSpanClick">{{ q.X3014G.answer.final | decimal(2) }}</span>.</p>
      <p>Access to postal reports <span title="X3015.notes" @click="onSpanClick">{{ (q.X3015.notes === "Yes")?"is":"is not" }}</span> accessed through a Postal1 Account.</p>


      <h3>Order Intake</h3>
      <p>Orders are submitted through <span title="X3016.notes" @click="onSpanClick">{{ q.X3016.notes }}</span>. Job tickets are developed by entering the information received in <span title="X3017.notes" @click="onSpanClick">{{ q.X3017.notes }}</span>.</p>

      <h3>Mail Piece Design</h3>
      <p>The following types of mail pieces are processed: <span title="X3018.notes" @click="onSpanClick">{{ listNotes(q.X3018) }}</span>.</p>
      <p>The weight of mail pieces range from <span title="X3019A.notes" @click="onSpanClick">{{ q.X3019A.notes }}</span> to <span title="X3019B.notes" @click="onSpanClick">{{ q.X3019B.notes }}</span> and thicknesses ranges from <span title="X3020A.notes" @click="onSpanClick">{{ q.X3020A.notes }}</span> to <span title="X3020B.notes" @click="onSpanClick">{{ q.X3020B.notes }}</span>.</p>
      <p>The design of the mail piece is completed by <span title="X3021.notes" @click="onSpanClick">{{ (q.X3021.notes === "In-house")?r.customerProfile.organizationName:q.X3021.notes }}</span>.</p>
      <p>Incorporating personalization/variable data and programming of the conditional logic (text, images, tables, etc.) is processed by <span title="X3022.notes" @click="onSpanClick">{{ (q.X3022.notes === "In-house")?r.customerProfile.organizationName:q.X3022.notes }}</span>.</p>
      <p v-if="q.X3022A.notes === 'Yes'"><span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> <span title="X3022A.notes" @click="onSpanClick">has at least one staff member certified by the USPS as a mail design professional (experienced with mail piece design and address accuracy standards)</span>.</p>

      <h3>Data Processing</h3>
      <p>There are <span title="X3001L.final" @click="onSpanClick">{{ q.X3001L.answer.final | decimal(2) }}</span> employees who process files and prepare mailing lists for production processing. Processes include <span title="X3023.notes" @click="onSpanClick">{{ listNotes(q.X3023) }}</span>.</p>
      <p>The following mailing list services are provided: <span title="X3024.notes" @click="onSpanClick">{{ listNotes(q.X3024) }}</span></p>
      <p>Address resolution services <span title="X3025.notes" @click="onSpanClick">{{ (q.X3025.notes === "Yes")?"are":"are not" }}</span> provided.  Address resolution is provided <span title="X3026.notes" @click="onSpanClick">{{ q.X3026.notes }}</span>.</p>
      <p>The following software is used to provide address resolution: <span title="X3027.notes" @click="onSpanClick">{{ listNotes(q.X3027) }}</span>.</p>
      <p>The file types that are processed include: <span title="X3028.notes" @click="onSpanClick">{{ listNotes(q.X3028) }}</span>.</p>
      <p>The origin of the mailing list file is: <span title="X3029.notes" @click="onSpanClick">{{ listNotes(q.X3029) }}</span>.</p>
      <p><span title="X3030.final" @click="onSpanClick">{{ q.X3030.answer.final | decimal(0) }}</span> files containing approximately <span title="X3031.final" @click="onSpanClick">{{ q.X3031.answer.final | decimal(0) }}</span> records/images/pages are processed monthly.</p>
      <p>The highest volume of records/images/pages that is processed in one day is <span title="X3032.final" @click="onSpanClick">{{ q.X3032.answer.final | decimal(0) }}</span>.</p>
      <p>Turnaround time/SLAs are generally <span title="X3033.final" @click="onSpanClick">{{ q.X3033.answer.final | decimal(0) }}</span> day(s).</p>
      <p>If days of operation fall on a holiday, the expectations for turnaround times are: <span title="X3034.notes" @click="onSpanClick">{{ q.X3034.notes }}</span>.</p>
      <p>Peak periods for processing include: <span title="X3035.notes" @click="onSpanClick">{{ q.X3035.notes }}</span>.</p>
      <p>In addition to USPS mailing, other methods of communication that are processed by <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> include: <span title="X3036.notes" @click="onSpanClick">{{ listNotes(q.X3036) }}</span>.</p>


      <h3>Preference Management Workflow</h3>
      <p>Customer communication and/or billing preferences are managed by <span title="X3037.notes" @click="onSpanClick">{{ (q.X3037.notes === "In-house")?r.customerProfile.organizationName:q.X3037.notes }}</span>.</p>
      <p>The system in place for customers to indicate their preference of communications includes <span title="X3038.notes" @click="onSpanClick">{{ q.X3038.notes }}</span>.</p>
      <p>The following methods of communication can be selected by customers: <span title="X3039.notes" @click="onSpanClick">{{ listNotes(q.X3039) }}</span>.</p>
      <p>Communication content that customers can opt in for include: <span title="X3040.notes" @click="onSpanClick">{{ listNotes(q.X3040) }}</span>.</p>

      <h3>Approvals</h3>
      <p>Print projects <span title="X3041.notes" @click="onSpanClick">{{ (q.X3041.notes === "Yes")?"require":"do not require" }}</span> approvals. <span v-if="q.X3041.notes === 'Yes'" title="X3041A.notes" @click="onSpanClick">{{ q.X3041A.notes }}.</span></p>

      <h3>Security & Compliance Controls</h3>
      <p>Compliance controls are required to be in place for the following: <span title="X3042.notes" @click="onSpanClick">{{ listNotes(q.X3042) }}</span>.</p>
      <p>Please explain the requirements to ensure compliance <span title="X3043.notes" @click="onSpanClick">{{ listNotes(q.X3043) }}</span>.</p>
      <p><span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> <span title="X3044.notes" @click="onSpanClick">{{ (q.X3044.notes === "Yes")?"requires":"does not require" }}</span> closed loop reporting data to be ingested into another system. <span v-if="q.X3044.notes === 'Yes'">Mail data is imported into <span title="X3044B.notes" @click="onSpanClick">{{ q.X3044B.notes }}</span>.</span></p>
      <p v-if="q.X3045.notes === 'Yes'"><span title="X3045.notes" @click="onSpanClick">Data is required to be deleted</span> from our system in <span title="X3045B.final" @click="onSpanClick">{{ q.X3045B.answer.final | decimal(0) }}</span> days.  The following data needs to be deleted: <span title="X3045A.notes" @click="onSpanClick">{{ q.X3045A.notes }}</span>.</p>

      <h3>Postage Payment Methods - Permit Management and Postal Classification</h3>
      <p>The following types of postage meters are used: <span title="X3046.notes" @click="onSpanClick">{{ listNotes(q.X3046) }}</span>.</p>
      <p>The following classes of mail are processed: <span title="X3047.notes" @click="onSpanClick">{{ listNotes(q.X3047) }}</span>.</p>
      <p>Additional services required by customers include: <span title="X3048.notes" @click="onSpanClick">{{ listNotes(q.X3048) }}</span>.</p>
      <p>The types of mail that are processed include: <span title="X3049.notes" @click="onSpanClick">{{ listNotes(q.X3049) }}</span>.</p>
      <p><span title="X3050.final" @click="onSpanClick">{{ q.X3050.answer.final | decimal(0) }}</span> business/commercial mailing permits are managed by the organization. The mailing permits are classified as: <span title="X3051.notes" @click="onSpanClick">{{ listNotes(q.X3051) }}</span>.</p>
      <p>Mail is processed in the following classifications: <span title="X3052.notes" @click="onSpanClick">{{ listNotes(q.X3052) }}</span>.</p>
      <p>The types of barcodes used include: <span title="X3053.notes" @click="onSpanClick">{{ listNotes(q.X3053) }}</span>.</p>
      <p>Bar codes are <span title="X3054.notes" @click="onSpanClick">{{ q.X3054.notes }}</span> onto mailing pieces.</p>
      <p>The Presorted First Class mailings are processed with the following presort zip code classifications in the following percentages: “X3055”</p>
      <p>(******Insert a screenshot of a mailers scorecard.)</p>
      <p><span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> <span title="X3012A.notes" @click="onSpanClick">{{ (q.X3012A.notes === "Yes")?"does":"does not" }}</span> utilize a presort bureau.</p>
      <p>The following mailing services are utilized: <span title="X3057.notes" @click="onSpanClick">{{ listNotes(q.X3057) }}</span>.</p>
      <p><span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> <span title="X3058.notes" @click="onSpanClick">{{ (q.X3058.notes === "Yes")?"uses":"does not use" }}</span> an electronic Manifest when presenting mail to the USPS.</p>


      <h3>Billing</h3>
      <p>The process for billing includes <span title="X3059.notes" @click="onSpanClick">{{ q.X3059.notes }}</span>.</p>
      <p><span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> <span title="X3060.notes" @click="onSpanClick">{{ (q.X3060.notes === "Yes")?"does":"does not" }}</span> bill back to account codes. <span title="X3060A.notes" @click="onSpanClick" v-if="(q.X3060.notes === 'Yes')">Account codes are referenced when billing and the format of the account codes that are referenced is {{ q.X3060A.notes }}.</span></p>
      <p>Postage costs that are processed on meters is billed back to customers by <span title="X3061.notes" @click="onSpanClick">{{ q.X3061.notes }}</span>.</p>
      <p>{{ r.customerProfile.organizationName }} <span title="X3062.notes" @click="onSpanClick">{{ (q.X3062.notes === 'Yes')?'bills':'does not bill' }}</span> back actual postage costs to the customer. <span title="X3062A.notes" @click="onSpanClick" v-if="(q.X3062.notes !== 'Yes')">The barrier to billing back actual postage costs is {{ q.X3062A.notes }}.</span></p>

      <h2>Production Operations</h2>
      <h3>Production Volume</h3>
      <p>The average volume of mail processed daily is <span title="X3633.final" @click="onSpanClick">{{ q.X3063.answer.final | decimal(0) }}</span>. The maximum volume that is processed daily is <span title="X3064.final" @click="onSpanClick">{{ q.X3064.answer.final | decimal(0) }}</span>.

      <p>The turnaround time/SLAs for projects is generally <span title="X3033.final" @click="onSpanClick">{{ q.X3033.answer.final | decimal(0) }}</span> day(s).</p>
      <p>The total volume of annual mailing pieces and the average postage for each is estimated at:</p>
      <p>Envelopes:	<span title="X3065A.final" @click="onSpanClick">{{ q.X3065A.answer.final | decimal(0) }}</span> pieces @ $<span title="X3065B.final" @click="onSpanClick">{{ q.X3065B.answer.final | decimal(2) }}</span> per piece</p>
      <p>Flats:	<span title="X3065C.final" @click="onSpanClick">{{ q.X3065C.answer.final | decimal(0) }}</span> pieces @ $<span title="X3065D.final" @click="onSpanClick">{{ q.X3065D.answer.final | decimal(2) }}</span> per piece</p>
      <p>Cards:	<span title="X3065E.final" @click="onSpanClick">{{ q.X3065E.answer.final | decimal(0) }}</span> pieces @ $<span title="X3065F.final" @click="onSpanClick">{{ q.X3065F.answer.final | decimal(2) }}</span> per piece</p>
      <p>Parcels/Packages: <span title="X3065G.final" @click="onSpanClick">{{ q.X3065G.answer.final | decimal(0) }}</span> pieces @ $<span title="X3065H.final" @click="onSpanClick">{{ q.X3065H.answer.final | decimal(2) }}</span> per piece</p>
      <p>There are <span title="X3066.final" @click="onSpanClick">{{ q.X3066.answer.final | decimal(0) }}</span>  types of outer envelopes used. The sizes are <span title="X3067.notes" @click="onSpanClick">{{ q.X3067.notes }}</span>.</p>
      <p>In order to reduce the number of outer envelopes the following would be needed: <span title="X3068.notes" @click="onSpanClick">{{ q.X3068.notes }}</span>.</p>

      <p>The average number of pieces in a mailing is <span title="X3069.final" @click="onSpanClick">{{ q.X3069.answer.final | decimal(0) }}</span>.</p>
      <p v-if="q.X3002A.notes === 'Yes'"><span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> <span title="X3002A.notes" @click="onSpanClick">prints</span> <span title="X3002B.final" @click="onSpanClick">{{ q.X3002B.answer.final | decimal(0) }}</span> b/w 8.5 x 11 pages and <span title="X3002C.final" @click="onSpanClick">{{ q.X3002C.answer.final | decimal(0) }}</span> color 8.5 x 11 pages per year.</p>
      <p>The substrates that are printed on include <span title="X3070.notes" @click="onSpanClick">{{ listNotes(q.X3070) }}</span>.</p>
      <p v-if="q.X3071.notes === 'Yes'"><span title="X3071.notes" @click="onSpanClick">Mailings are commingled.</span></p>

      <p v-if="q.X3072.notes === 'Yes'"><span title="X3072.notes" @click="onSpanClick">Householding is incorporated into mail processes in an effort to reduce postage and associated processing costs.</span></p>

      <p v-if="q.X3073.notes === 'Yes'"><span title="X3073.notes" @click="onSpanClick">Mailings include</span> <span title="X3066.final" @click="onSpanClick">{{ q.X3066.answer.final | decimal(0) }}</span> inserts <span title="X3075.notes" @click="onSpanClick">{{ q.X3075.notes === 'Yes' ? 'that can be selective for each envelope’s contents':'that are the same for each job‘s contents' }}</span>.</p>
      <p v-if="q.X3076.notes === 'Yes'"><span title="X3076.notes" @click="onSpanClick">There are requirements</span> to pull records from the data and process those records separately from the mailing.</p>

      <p>The process for handling the pulling of records within a mailing includes <span title="X3077.notes" @click="onSpanClick">{{ q.X3077.notes }}</span>.</p>


      <p><span title="X3078.final" @click="onSpanClick">{{ q.X3078.answer.final | decimal(0) }}</span>% of work is hand inserted. Hand inserting vs. machine insertion is required due to <span title="X3079.notes" @click="onSpanClick">{{ q.X3079.notes }}</span>.</p>

      <h3>Capacity and Utilization</h3>
      <p>****Include a pie chart showing the utilization of equipment here.</p>
      <p v-if="q.X3080.notes === 'Yes'"><span>{{ r.customerProfile.organizationName }}</span> <span title="X3080.notes" @click="onSpanClick">is</span> outsourcing <span title="X3081.final" @click="onSpanClick">{{ q.X3081.answer.final | decimal(0) }}</span>% of its work due to capacity constraints.</p>
      <p v-if="q.X3081A.notes === 'Yes'"><span title="X3081A.notes" @click="onSpanClick">The productivity of employees is tracked</span>, and their percentage of productive hours is <span title="X3081B.final" @click="onSpanClick">{{ q.X3081B.answer.final | decimal(0) }}</span>%.</p>

      <h3>Standard Operating Procedures</h3>
      <p>Areas where there are documented Standard Operating Procedures for the operation include <span title="X3082.notes" @click="onSpanClick">{{ listNotes(q.X3082) }}</span></p>

      <h3>Workflow Overview</h3>
      <p>Job workflow and the software used in processing includes <span title="X3083.notes" @click="onSpanClick">{{ q.X3083.notes }}</span>.</p>

      <h3>Reprints</h3>
      <p>The process for reprinting mail pieces that were damaged in the production process includes <span title="X3084.notes" @click="onSpanClick">{{ q.X3084.notes }}</span>.</p>
      <p v-if="q.X3012A.notes === 'Yes'">The process for managing reprints for <span title="X3012A.notes" @click="onSpanClick">mailings processed by a presort bureau</span> includes <span title="X3085.notes" @click="onSpanClick">{{ q.X3085.notes }}</span>.</p>

      <h3>Returned Mail</h3>
      <p>Returned Mail accounts for <span title="X3086.final" @click="onSpanClick">{{ q.X3086.answer.final | decimal(0) }}</span>% of <span title="X3065.final" @click="onSpanClick">{{ q.X3065.answer.final | decimal(0) }}</span> yearly mailing pieces. The total postage value of returned mail is estimated at $<span title="X3088.final" @click="onSpanClick">{{ q.X3088.answer.final | decimal(2) }}</span> per month. The total print/material cost value of returned mail is estimated at $<span title="X3089.final" @click="onSpanClick">{{ q.X3089.answer.final | decimal(2) }}</span>. The cost to process returned mail is estimated at $<span title="X3090.final" @click="onSpanClick">{{ q.X3090.answer.final | decimal(2) }}</span> per month.</p>
      <p>Returned mail is processed within the operation by <span title="X3091.notes" @click="onSpanClick">{{ q.X3091.notes?listNotes(q.X3091):'X3091' }}</span>.</p>

      <h3>Raw Materials/Supplies/Inventory</h3>

      <p>The following software is used to manage inventory: <span title="X3092.notes" @click="onSpanClick">{{ q.X3092.notes?q.X3092.notes:'X3092' }}</span>.</p>
      <p>The following types, sizes and estimated qty. of envelopes are held in inventory: <span title="X3093.notes" @click="onSpanClick">{{ q.X3093.notes?q.X3093.notes:'X3093' }}</span>.</p>
      <p v-if="q.X3094.notes === 'Yes'"><span title="X3094.final" @click="onSpanClick">There are automated notifications</span> for triggers or alerts for stocking levels, reorder requirements or low inventory.</p>

      <h3>Quality Control Procedures</h3>
      <p>Quality control procedures for monitoring mail processing include <span title="X3095.notes" @click="onSpanClick">{{ q.X3095.notes?q.X3095.notes:'X3095' }}</span>.</p>
      <p>Compliance requirements for tracking mail include <span title="X3096.notes" @click="onSpanClick">{{ q.X3096.notes?q.X3096.notes:'X3096' }}</span>.</p>

      <h3>Scheduling</h3>
      <p>Software and methods for scheduling jobs include <span title="X3097.notes" @click="onSpanClick">{{ q.X3097.notes?q.X3097.notes:'X3097' }}</span>.</p>
      <p>Production status reports are provided by <span title="X3098.notes" @click="onSpanClick">{{ q.X3098.notes?q.X3098.notes:'X3098' }}</span>.</p>
      <p v-if="q.X3099.notes === 'Yes'"><span title="X3099.notes" @click="onSpanClick">Dynamic scheduling is provided</span> through the software solution managing production processes.</p>

      <h3>Delivery and Distribution</h3>
      <p>Customers submit jobs in the following methods: <span title="X3100.notes" @click="onSpanClick">{{ q.X3100.notes?listNotes(q.X3100):'X3100' }}</span>.</p>

      <h3>Reports</h3>
      <p>The following reports are used to manage the production and financial aspects of the operation: <span title="X3101.notes" @click="onSpanClick">{{ q.X3101.notes?q.X3101.notes:'X3101' }}</span>.</p>
      <p>Other reports that would be beneficial in or to monitor  production and report required data back to customers include <span title="X3102.notes" @click="onSpanClick">{{ q.X3102.notes?q.X3102.notes:'X3102' }}</span>.</p>

      <h3>Maintenance and Troubleshooting</h3>
      <p>The following preventative maintenance is performed by <span>{{ r.customerProfile.organizationName }}</span> employees: <span title="X3103.notes" @click="onSpanClick">{{ q.X3103.notes?q.X3103.notes:'X3103' }}</span>.</p>
      <p>The process for vendor provided maintenance includes <span title="X3104.notes" @click="onSpanClick">{{ q.X3104.notes?q.X3104.notes:'X3104' }}</span>.</p>

      <h3>Training</h3>
      <p>The process for onboarding new customers includes <span title="X3105.notes" @click="onSpanClick">{{ q.X3105.notes?q.X3105.notes:'X3105' }}</span>.</p>
      <p>The process for onboarding new employees includes <span title="X3106.notes" @click="onSpanClick">{{ q.X3106.notes?q.X3106.notes:'X3106' }}</span>.</p>
      <p>The following training and cross-training procedures are provided to employees: <span title="X3107.notes" @click="onSpanClick">{{ q.X3107.notes?q.X3107.notes:'X3107' }}</span>.</p>

      <h3>Disaster Recovery</h3>
      <p>The organization has the following disaster recovery program in place for documents processed by the print and/or mail operation: <span title="X3108.notes" @click="onSpanClick">{{ q.X3108.notes?q.X3108.notes:'X3108' }}</span>.</p>


    </section>
    <section>
      <h1>Findings</h1>
      <h2>Mail Workflow Scorecard Summary</h2>
      <p><span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span>’s Mail Assessment processes are rated as <span class="uppercase">{{ scorecardGrade }}</span> with a rating of {{ r.scorecard.final }} on a scale of 1 to 5.  The overall rating would rise by incorporating the recommendation solutions outlined in this document. </p>
      <assessment-scorecard data-svg="true" data-ratio="0.8" title="Mail Workflow Scorecard" answer-key="final" :assessment="r" :questions="survey"></assessment-scorecard>

      <p class="mt-4">See Appendix 1 for a detailed Grading Scale of the Mail Best Practices Scorecard.</p>

    </section>

    <section>
      <h1>Recommendations</h1>
      <p>Based on our assessment of your production operation, Ricoh recommends incorporating the following software solutions to streamline <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span>'s workflow.</p>


        <h2>Mail Software</h2>
        <component data-container="true" v-for="(solution) in solutions.MailSoftware" :key="solution.id" :is="solution.component"></component>

        <h2>Services</h2>
        <component data-container="true" v-for="(solution) in solutions.Other" :key="solution.id" :is="solution.component"></component>

      <br style="page-break-before: always; clear: both" />
      <h2>Benefits</h2>
      <p>The proposed solution will align with organizational goals and objectives and provide solutions for administrative efficiency and improved customer experience.</p>
      <p>Details for the opportunities for cost savings include: </p>
      <savings-details-table-mail :assessment="r"></savings-details-table-mail>
      <p class="mt-8">Streamlining and simplifying the process of mail administration and processing along with utilizing USPS postage discounts provides the following benefits:</p>

      <p class="font-bold">Reduced Cost</p>
      <ul>
        <li>Increased efficiencies within data processing, personalization and addressing</li>
        <li>Less administrative time to manage production workflow by streamlining processing and integration with print and mail processing applications</li>
        <li>Workflow optimization that reduces production processing time</li>
        <li>Postal discounts and promotions</li>
      </ul>

      <p class="font-bold">More Efficient Processes and Greater Control</p>
      <ul>
        <li>Use of barcodes to create better document security and more efficient operations</li>
        <li>Create centralized, enterprise-wide process automation and task management, and eliminate manual and redundant steps</li>
        <li>Automate on-demand requests and deliver communications quickly to customer’s preferred channel</li>
        <li>Scale production servers to create unparalleled production capacity</li>

      </ul>

      <p class="font-bold">Regulatory Compliance</p>
      <p>Empower the business to control communications</p>
      <ul>
        <li>Give your experts the control to make fast document and regulatory changes over the web</li>
        <li>Rapidly develop, modify and manage files/documents</li>
        <li>Manage, track, audit, and approve changes easily</li>
        <li>Increase control over defining the approval processes</li>
        <li>Control of ad-hoc requests</li>
        <li>Meet customer demands and improve turnaround times</li>
        <li>Online proofing drastically cut approval and implementation time</li>
      </ul>

      <p class="font-bold">Better Customer Experience</p>
      <ul>
        <li>Provide omni-channel experience</li>
        <li>Personalization engages customers</li>
        <li>Quality control influences customer satisfaction</li>
      </ul>
    </section>


    <section>
      <h1>Appendix</h1>
      <h2>Appendix 1 - Workflow Assessment Scorecard Ratings</h2>
      <table class="text-xs" data-cell-style="AppendixCell">
        <tr>
          <th></th>
          <th>5</th>
          <th>4</th>
          <th>3</th>
          <th>2</th>
          <th>1</th>
          <th>N/A</th>
        </tr>
        <tr v-for="rating in survey" :key="rating.id">
          <td>{{ rating.question }}</td>
          <td>{{  rating.criteria5 }}</td>
          <td>{{  rating.criteria4 }}</td>
          <td>{{  rating.criteria3 }}</td>
          <td>{{  rating.criteria2 }}</td>
          <td>{{  rating.criteria1 }}</td>
          <td>{{  rating.criteriaNA }}</td>
        </tr>
      </table>
    </section>
  </div>

</template>

<script>
import mailing from "@/schema/mailing";
import RoiChartVdpComparison from "@/components/ROIChartVDPComparison";
import RoiChartStorefrontComparison from "@/components/ROIChartStorefrontComparison";
import RoiChartMisCurrentFuture from "@/components/ROIChartMISCurrentFuture";
import RoiChartMisPotential from "@/components/ROIChartMISPotential";
import ProductionWorkflow from "@/components/Solutions/ProductionWorkflow";
import RicohConsultingServices from "@/components/Solutions/RicohConsultingServices";

import RoiTableStorefront from "@/components/ROITableStorefront";
import RoiTableMis from "@/components/ROITableMIS";
import RoiTableVdpDetails from "@/components/ROITableVDPDetails";
import RoiTableVdp from "@/components/ROITableVDP";

import AssessmentScorecard from "@/components/AssessmentScorecard";
import BccMailManagerEtAl from "@/components/Solutions/BccMailManagerEtAl";
import Lorton from "@/components/Solutions/Lorton";
import ProcessDirector from "@/components/Solutions/ProcessDirector";
import PlanetPressConnect from "@/components/Solutions/PlanetPressConnect";
import PresConnect from "@/components/Solutions/PresConnect";
import QuadientAims from "@/components/Solutions/QuadientAims";
import QuadientBulkMailer from "@/components/Solutions/QuadientBulkMailer";
import ObjectifConnectSend from "@/components/Solutions/ObjectifConnectSend";
import RicohHybridMail from "@/components/Solutions/RicohHybridMail";
import RicohReturnMail from "@/components/Solutions/RicohReturnMail";
import RicohMarketingServices from "@/components/Solutions/RicohMarketingServices";
import SavingsDetailsTableMail from "@/components/SavingsDetailsTableMail";


export default {
    name: "report-mail-workflow",
  components: {
    SavingsDetailsTableMail,
    RicohMarketingServices,
    RicohReturnMail,
    RicohHybridMail,
    ObjectifConnectSend,
    QuadientBulkMailer,
    QuadientAims,
    PresConnect,
    PlanetPressConnect,
    ProcessDirector,
    Lorton,
    BccMailManagerEtAl,

    RoiChartVdpComparison,
    RoiChartStorefrontComparison,
    RoiChartMisCurrentFuture,
    RoiChartMisPotential,
    ProductionWorkflow,
    RicohConsultingServices,

    RoiTableStorefront,
    RoiTableMis,
    RoiTableVdpDetails,
    RoiTableVdp, AssessmentScorecard
  },
  mounted: function(){
    this.$emit("mounted");
  },
    props: {
      reportData: {
        required: true,
      }
    },
    data: function(){
        return {

        }
        },
    methods: {
      onSpanClick: function(e){

        let questionData;

        if(e.target.title){
          let qPath = e.target.title.split(".");
          if(qPath[0].startsWith("I") || qPath[0].startsWith("V") || qPath[0].startsWith("X")){
            let question = this.iQ[qPath[0]];
            let answer = this.q[qPath[0]];

            if(question){
              questionData = {
                title: "Interview Question",
                answer: answer,
                question: question
              }
              this.$emit("edit",questionData)
            }
          }
        }

      },

      listNotes: function(val){

        console.log(val)
        let items = val.notes.split(",")

        if(items[items.length - 1] === "Other"){
          if(val.answer?.final){
            items[items.length - 1] = val.answer.final
          }
        }

        if(items.length > 1){
          items[items.length - 1] = "and "+items[items.length - 1]
        }

        if(items.length === 2){
          return items.join(" ")
        }
        return items.join(", ")
      },

      onChange: function(e){

        this.$emit("change",e);

      }
    },
    computed: {
       r: function(){
         return this.reportData;
       },

      q: function(){
        return this.r.interview.questions;
      },


      strengthsWeakness: function(){
        let _listing = {
          strength: [],
          weakness: []
        }

        if(this.r){
          Object.keys(this.survey).forEach((key) => {
            let question = this.survey[key];
            let answer = this.r.survey.questions[key];

            if(answer.answer.final && answer.answer.final !=="NA"){
              let intValue = parseInt(answer.answer.final);
              if(intValue > 3){
                _listing.strength.push({
                  title: question.question,
                  criteria: question['criteria'+intValue],
                  grade: (intValue === 4)?"Very Good":"Best-in-Class",
                  notes: answer.notes
                })
              }

              if(intValue < 3){
                _listing.weakness.push({
                  title: question.question,
                  criteria: question['criteria'+intValue],
                  grade: (intValue === 2)?"Sufficient":"Substandard",
                  notes: answer.notes
                })
              }
            }

          })
        }

        return _listing;


      },


      option2: function(){
        if(this.r){
          return (this.r.exportSettings.option === "2");
        }
        return false;
      },

      solutions: function(){


        if(this.r){
          let _solutions = {
            Other: this.r.exportSettings.solutions.Other.map((otherID)=>{
              return this.solutionList[otherID]
            }),
            MailSoftware: this.r.exportSettings.solutions.MailSoftware.map((otherID)=>{
              return this.solutionList[otherID]
            })
          };

          if(this.r.exportSettings.solutions.MIS){
            _solutions.MIS = this.solutionList[this.r.exportSettings.solutions.MIS]
          }

          if(this.r.exportSettings.solutions.Storefront){
            _solutions.Storefront = this.solutionList[this.r.exportSettings.solutions.Storefront]
          }

          if(this.r.exportSettings.solutions.VDP){
            _solutions.VDP = this.solutionList[this.r.exportSettings.solutions.VDP]
          }

          return _solutions;

        }

        return {}

      },

      survey: function(){
        return mailing.survey;
      },

      iQ: function(){
        return mailing.questions;
      },

      currentDate: function(){
        return (new Date(Date.now())).toLocaleDateString()
      },

      scorecardGrade: function(){
        if(this.r && this.r.scorecard && this.r.scorecard.final){
          if(this.r.scorecard.final < 1.5){
            return "Sub-Standard"
          }else if(this.r.scorecard.final < 2.5){
            return "Sufficient"
          }else if(this.r.scorecard.final < 3.5){
            return "Good"
          }else if(this.r.scorecard.final < 4.5){
            return "Very Good"
          }else if(this.r.scorecard.final < 5.1){
            return "Best-in-Class"
          }


        }

        return "NA"
      },

      solutionList: function() {
         return {
            "BccMailManagerEtAl": {name: "BCC Mail Manager / cQuencer/ ZIPFOURce / Datasolve / Ignite", component: "BccMailManagerEtAl", category: "Mail Software"},
            "Lorton": {name: "Lorton", component: "Lorton", category: "MailSoftware"},
            "ProcessDirector": {name: "Ricoh Process Director", component: "ProcessDirector", category: "MailSoftware"},
            "PlanetPressConnect": {name: "ObjectifLune PlanetPress", component: "PlanetPressConnect", category: "MailSoftware"},
           "PresConnect": {name: "ObjectifLune PresConnect", component: "PresConnect", category: "MailSoftware"},
            "QuadientAims": {name: "Quadient AIMS", component: "QuadientAims", category: "MailSoftware"},
           "QuadientBulkMailer": {name: "Quadient Bulk Mailer", component: "QuadientBulkMailer", category: "MailSoftware"},
           "ObjectifConnectSend": {name: "ObjectifLune Connect Send", component: "ObjectifConnectSend", category: "MailSoftware"},



            "RicohReturnMail": {name: "Ricoh Return Mail Services", component: "RicohReturnMail", category: "Other"},
           "RicohHybridMail": {name: "Ricoh Hybrid Mail Services", component: "RicohHybridMail", category: "Other"},
            "RicohConsultingServices": {
              name: "Ricoh Business Consulting",
              component: "RicohConsultingServices",
              category: "Other"
            },
           "RicohMarketingServices": {
             name: "Ricoh Marketing Services",
             component: "RicohMarketingServices",
             category: "Other"
           },

          }
       }
    }
}
</script>

<style scoped>


.report-production-workflow {

}

</style>