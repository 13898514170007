<template>

    <div class="pullout-details-menu" ref="mailingPanel">


        <div class="" v-if="!detailPanel">
          <div class="menu-header">
            <icon-x class="close-button" @click.native="hide"></icon-x>
            <h3 class="header-label">Mailing Requirements</h3>
            <button class="button text-xs clear-button warning" @click="clearDetails">CLEAR</button>

          </div>
          <div class="pullout-question">
            <component :is="detailMap.env10mailings.component" :detail="detailMap.env10mailings" :answers="answers"  @change="onDetailChange" trigger-button="Details" @trigger="showDetails('env10')"></component>
            <div class="mt-1 ml-1 border-l-2 border-brand-blue p-2" v-if="answers.env10mailings">
              <p class="text-sm font-bold mb-1">{{ detailSummary?detailSummary.volumes.env10:0 | decimal(0) }} #10 mailings per month ( {{ answers.env10mailings}} jobs x {{ answers.env10runQuantity }} items per run)</p>
              <p v-for="(req, index) in requirements.env10" :key="'env10_'+index">+ {{ req }}</p>
            </div>
          </div>

          <div class="pullout-question">
            <component :is="detailMap.env6x9mailings.component" :detail="detailMap.env6x9mailings" :answers="answers"  @change="onDetailChange" trigger-button="Details" @trigger="showDetails('env6x9')"></component>
            <div class="mt-1 ml-1 border-l-2 border-brand-blue p-2" v-if="answers.env6x9mailings">
              <p class="text-sm font-bold mb-1">{{ detailSummary?detailSummary.volumes.env6x9:0 | decimal(0) }} 6x9 mailings per month ( {{ answers.env6x9mailings}} jobs x {{ answers.env6x9runQuantity }} items per run)</p>
              <p v-for="(req, index) in requirements.env6x9" :key="'env6x9_'+index">+ {{ req }}</p>
            </div>
          </div>

          <div class="pullout-question">
            <component :is="detailMap.flatMailings.component" :detail="detailMap.flatMailings" :answers="answers"  @change="onDetailChange" trigger-button="Details" @trigger="showDetails('flat')"></component>
            <div class="mt-1 ml-1 border-l-2 border-brand-blue p-2" v-if="answers.flatMailings">
              <p class="text-sm font-bold mb-1">{{ detailSummary?detailSummary.volumes.flat:0 | decimal(0) }} flat mailings per month ( {{ answers.flatMailings}} jobs x {{ answers.envFlatRunQuantity }} items per run)</p>
              <p v-for="(req, index) in requirements.flat" :key="'flat_'+index">+ {{ req }}</p>
            </div>
          </div>


          <component class="pullout-question" :is="detailMap.additionalEquipment.component" :detail="detailMap.additionalEquipment" :answers="answers"  @change="onDetailChange"></component>

          <footer class="pullout-footer">
            <button class="button save-button" @click="hide">SAVE MAILING INFO</button>
          </footer>
        </div>




        <div class="" v-if="detailPanel">
          <div v-if="detailPanel==='env10'">
            <div class="menu-header">
              <img src="../../assets/icons/next-arrow-icon-black.svg" class="w-4 transform rotate-180 cursor-pointer" @click="hideDetailPanel" />
              <h3 class="w-full text-center">#10 Envelope Mailing Details</h3>
            </div>
            <div class="">
              <component class="pullout-question" :is="detailMap.env10runQuantity.component" :detail="detailMap.env10runQuantity" :answers="answers"  @change="onDetailChange"></component>
              <component class="pullout-question" :is="detailMap.env10inserts.component" :detail="detailMap.env10inserts" :answers="answers"  @change="onDetailChange"></component>
              <component class="pullout-question" :is="detailMap.env10insertSize.component" :detail="detailMap.env10insertSize" :answers="answers"  @change="onDetailChange"></component>
              <component class="pullout-question" :is="detailMap.env10lbs.component" :detail="detailMap.env10lbs" :answers="answers"  @change="onDetailChange"></component>
              <component class="pullout-question" :is="detailMap.env10insertsColorPct.component" :detail="detailMap.env10insertsColorPct" :answers="answers"  @change="onDetailChange"></component>
              <component class="pullout-question" :is="detailMap.env10postage.component" :detail="detailMap.env10postage" :answers="answers"  @change="onDetailChange"></component>
            </div>

          </div>

          <div v-if="detailPanel==='env6x9'">
            <div class="menu-header">
              <img src="../../assets/icons/next-arrow-icon-black.svg" class="w-4 transform rotate-180 cursor-pointer" @click="hideDetailPanel" />
              <h3 class="header-label">6x9 Envelope Mailing Details</h3>
            </div>
            <div class="">
              <component class="pullout-question" :is="detailMap.env6x9runQuantity.component" :detail="detailMap.env6x9runQuantity" :answers="answers"  @change="onDetailChange"></component>
              <component class="pullout-question" :is="detailMap.env6x9inserts.component" :detail="detailMap.env6x9inserts" :answers="answers"  @change="onDetailChange"></component>
              <component class="pullout-question" :is="detailMap.env6x9insertSize.component" :detail="detailMap.env6x9insertSize" :answers="answers"  @change="onDetailChange"></component>
              <component class="pullout-question" :is="detailMap.env6x9lbs.component" :detail="detailMap.env6x9lbs" :answers="answers"  @change="onDetailChange"></component>
              <component class="pullout-question" :is="detailMap.env6x9insertsColorPct.component" :detail="detailMap.env6x9insertsColorPct" :answers="answers"  @change="onDetailChange"></component>

              <component class="pullout-question" :is="detailMap.env6x9postage.component" :detail="detailMap.env6x9postage" :answers="answers"  @change="onDetailChange"></component>
            </div>

          </div>

          <div v-if="detailPanel==='flat'">
            <div class="menu-header">
              <img src="../../assets/icons/next-arrow-icon-black.svg" class="w-4 transform rotate-180 cursor-pointer" @click="hideDetailPanel" />
              <h3 class="header-label">Flat Mailing Details</h3>
            </div>
            <div class="">
              <component class="pullout-question" :is="detailMap.envFlatRunQuantity.component" :detail="detailMap.envFlatRunQuantity" :answers="answers"  @change="onDetailChange"></component>
              <component class="pullout-question" :is="detailMap.envFlatInserts.component" :detail="detailMap.envFlatInserts" :answers="answers"  @change="onDetailChange"></component>
              <component class="pullout-question" :is="detailMap.envFlatLbs.component" :detail="detailMap.envFlatLbs" :answers="answers"  @change="onDetailChange"></component>
              <component class="pullout-question" :is="detailMap.envFlatInsertsColorPct.component" :detail="detailMap.envFlatInsertsColorPct" :answers="answers"  @change="onDetailChange"></component>
              <component class="pullout-question" :is="detailMap.envFlatStiched.component" :detail="detailMap.envFlatStiched" :answers="answers"  @change="onDetailChange"></component>

              <component class="pullout-question" :is="detailMap.envFlatPostage.component" :detail="detailMap.envFlatPostage" :answers="answers"  @change="onDetailChange"></component>
            </div>

          </div>

          <footer class="pullout-footer">
            <button class="button button-blue" @click="hideDetailPanel">BACK</button>
          </footer>

        </div>

    </div>



</template>

<script>

    import IconX from './IconX.vue';
    import PulloutQuestionNumber from './PulloutQuestionNumber.vue';
    import PulloutQuestionSwitch from './PulloutQuestionSwitch.vue';
    import PulloutQuestionMultiSwitch from './PulloutQuestionMultiSwitch.vue'
    import PulloutQuestionMultiChoice from './PulloutQuestionMultiChoice.vue'
    import PulloutQuestionMultiSwitchList from './PulloutQuestionMultiSwitchList.vue'
    import PulloutQuestionRatedList from './PulloutQuestionRatedList.vue'
    import printconfig from "@/plugins/printconfig";


    export default {
        components: {
            IconX,
            PulloutQuestionNumber,
            PulloutQuestionSwitch,
            PulloutQuestionMultiSwitch,
            PulloutQuestionMultiChoice,
            PulloutQuestionMultiSwitchList,
            PulloutQuestionRatedList
        },
        name: "pullout-mailing-menu",
        props: {
            question: {
                type: Object,
                required: true
            },
            answers: {
                type: Object,
                required: true
            }

        },

        data: function(){
          return {
            currentFocus: undefined,
            detailPanel: ""
          }
        },

        computed: {
            details: function(){
                if(this.question.configurator){
                    return this.question.configurator.detailList;
                }else{
                    return this.question.details;
                }



            },

            detailMap: function(){
              let _map = {};
              this.details.forEach((detail) => {
                _map[detail.id] = detail
              })
              return _map;
            },

            detailSummary: function(){
                if(this.question.configurator){
                    return this.question.configurator.summary(this.answers);
                }

                return null;

            },

            requirements: function(){

              let reqs = {
                env10: [],
                env6x9: [],
                flat: [],
                additional: []
              }
              if(this.detailSummary){
                this.detailSummary.requirements.forEach((req) => {
                  if(req.id.startsWith('env10')){
                    if(req.type === 'number'){
                      reqs.env10.push(this.$options.filters.decimal(this.answers[req.id],0) + req.summaryLabel)
                    }else{
                      reqs.env10.push(req.summaryLabel?req.summaryLabel:req.label);
                    }

                  }else if(req.id.startsWith('env6x9')){
                    if(req.type === 'number'){
                      reqs.env6x9.push(this.$options.filters.decimal(this.answers[req.id],0) + req.summaryLabel)
                    }else{
                      reqs.env6x9.push(req.summaryLabel?req.summaryLabel:req.label);
                    }

                  }else if(req.id.startsWith('envFlat')){
                    if(req.type === 'number'){
                      reqs.flat.push(this.$options.filters.decimal(this.answers[req.id],0) + req.summaryLabel)
                    }else{
                      reqs.flat.push(req.summaryLabel?req.summaryLabel:req.label);
                    }
                  }
                })
              }

              return reqs
            },

            mailingTotal: function(){
              let env10 = parseInt(this.answers.env10mailings)
              let env6x9 = parseInt(this.answers.env6x9mailings)
              let envFlat = parseInt(this.answers.flatMailings)

              return (env10 + env6x9 + envFlat);
            },

            totalWarning: function(){
              if(this.mailingTotal !== parseInt(this.answers.mailingsPerMonth)){
                return "Sum of #10, 6x9, and Flat mailings per month should equal Total Monthly Mailings amount."
              }

              return ""
            }
        },

        methods: {

            showDetails: function(panelId){
              this.detailPanel = panelId;
            },

            hideDetailPanel: function (){
              this.detailPanel = ""
              this.$refs.mailingPanel.scrollTo(0,0);
            },

            hide: function(){
                this.$emit('hide');
            },

            onDetailChange: function(detail){




                var update = {
                    questionId: this.question.id,
                    detail: detail
                }
                this.$store.dispatch('updateDetail',update);
                this.$emit("change");
            },

            clearDetails: function(){

              if(!window.confirm("This will clear your custom configuration for "+ this.question.label+".")){
                return;
              }

              let defaultAnswer = printconfig.defaultAnswer(this.question);

              this.details.forEach((detail) => {
                this.answers[detail.id] = defaultAnswer.details[detail.id];
              })

            }
        }
    }
</script>

<style scoped>

    .pullout-details-menu {
        overflow-y: scroll;
        background-color: var(--near-white-2);
      box-shadow: 0 3px 16px rgba(64,64,64,0.1);
    }

    .menu-header {
        background-color: white;
      box-shadow: 0 3px 16px rgba(64,64,64,0.1);
        height: 3em;
        box-sizing: border-box;
        padding: 0.5em 1em;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .header-label {
        padding-left: 1em;
        padding-right: 1em;
    }

    .close-button {
        fill: var(--mid-gray);
        height: 1em;
        cursor: pointer;

    }

    .close-button:hover {
        fill: var(--near-black-2);
    }

    .pullout-question {
        padding: 1em;
        border-bottom: 1px solid var(--mid-gray);
        font-size: 0.8em;
        margin: 0 1em;
    }

    .model-choices {
        position: fixed;
        left: 3em;
        top: 3em;
        padding: 3em;
        border: 1px solid var(--mid-gray);
        background-color: white;
    }


    .ineligible {
        text-decoration: line-through;
    }

    .pullout-footer {
        padding: 1em;
        text-align: center;

    }

    .save-button {
        background-color: var(--primary-color);
        color: white;
        border-color: transparent;
        font-size: 1.0em;
    }

    .save-button:hover {
        background-color: white;
        color: var(--primary-color);
        border-color: var(--primary-color);
        font-size: 1.0em;
    }





</style>
