


<template>
  <div id="report">

    <section class="">
      <table class="titlebox absolute text-white" style="font-size: 1vw; left: 11em; width:28em; margin-top: 11em;">
        <tr><td class="font-bold" style="font-size: 2.5em; padding-bottom: 0.5em;" data-cell-style="TitlePageTitleCell">Findings Report</td></tr>
        <tr><td class="font-bold leading-none" style="font-size: 2em; padding-bottom: 1em;" data-cell-style="TitlePageSubtitleCell">Print and Mail Operations Configuration (Transactional)</td></tr>
        <tr><td style="padding-bottom: 1em;">  </td></tr>
        <tr><td class="leading-snug" style="font-size: 1.2em;" data-cell-style="TitlePageCell">Confidential: For the exclusive use of Ricoh and <span>{{ r.customerProfile.organizationName?r.customerProfile.organizationName:'XXXX' }}</span>.  This document cannot be shared with any other parties without written permission from all of the parties referenced here.</td>
        </tr>
        <tr><td style="padding-bottom: 2em;">  </td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell">V1.0</td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell"><span>{{ r.userProfile.firstname }} {{ r.userProfile.lastname }}</span></td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell"><span>{{ r.userProfile.title }}</span></td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell"><span>{{ currentDate }}</span></td></tr>
      </table>

      <img class="w-full -mx-8 block" data-float="true" data-ratio="1.2941" src="/reports/title-bkg.png" />



      <p></p>



    </section>

    <section>
      <p class="font-bold no-mb">Prepared for:</p>
      <p class="font-bold no-mb "><span>{{ r.customerProfile.organizationName?r.customerProfile.organizationName:'XXXX' }}</span> - <span @click="onSpanClick" title="facilityContact">{{q.facilityContact.value?q.facilityContact.value:'XXXX'}}</span></p>
      <p class="no-mb"><span @click="onSpanClick" title="facilityAddress">{{q.facilityAddress.value?q.facilityAddress.value:'XXXX'}}</span></p>
      <p class="no-mb"><span @click="onSpanClick" title="facilityCity">{{q.facilityCity.value?q.facilityCity.value:'XXXX'}}</span>, <span @click="onSpanClick" title="facilityState">{{q.facilityState.value?q.facilityState.value:'XXXX'}}</span> <span @click="onSpanClick" title="facilityPostal">{{q.facilityPostal.value?q.facilityPostal.value:'XXXX'}}</span></p>
      <p class="mt-8 no-mb font-bold">Prepared by:</p>
      <p class="no-mb"><span>{{ r.userProfile.firstname }}</span> <span>{{ r.userProfile.lastname }}</span></p>
      <p class="no-mb" v-if="r.userProfile.organization"><span>{{ r.userProfile.organization }}</span></p>
      <p class="no-mb"><span>{{ r.userProfile.title }}</span></p>
      <p class="no-mb mt-8"><span>{{ currentDate }}</span></p>
    </section>

    <section>
      <p class="toc-title">TABLE OF CONTENTS</p>
      <p data-toc="true" data-alias="Summary" data-levels="1-3">Table of Contents here.</p>
    </section>

    <section>
        <h1>Overview</h1>
        <h2>Background</h2>
        <p>The most proficient and cost-effective Print and Mail Operations are right sized with an optimum configuration for equipment, software and personnel within a facility that can accommodate the operations for efficient production workflows. You must take into consideration the volumes and types of work, turnaround times, customer requirements and capacity of equipment in order to develop a right-sized operation.</p>
        <p>To assist organizations in the development and optimization of production operations, Ricoh has developed a program to assess a customer’s requirements and provide the information needed to configure an onsite operation. Ricoh solution consultants work collaboratively with customers to define workflow processes, software and equipment solutions as well as best practices for effective onsite production.</p>


      <h2>Methodology</h2>
      <p><span>{{ r.userProfile.firstname }}</span> <span>{{ r.userProfile.lastname }}</span> met with <span @click="onSpanClick" title="facilityContact">{{q.facilityContact.value?q.facilityContact.value:'XXXX'}}</span> to review the organization’s print and mail requirements. The goal was to review all workflows and business requirements for print and distribution of communications and configure an onsite operation to meet customer needs.</p>
      <p>Areas reviewed:</p>
      <ul class="list-disc">
        <li>Administration</li>
        <li>Order Intake</li>
        <li>Pre-Press Design and File Processing</li>
        <li>Production Workflow</li>
        <li>Software and Equipment</li>
        <li>Printed Materials</li>
        <li>Envelopes</li>
        <li>Mail Processing</li>
        <li>Job Status Tracking</li>
        <li>Reports</li>
        <li>Integration Requirements</li>
      </ul>

      <h2>Site</h2>
      <p class="no-mb"><span @click="onSpanClick" title="customerProfile.organizationName">{{ r.customerProfile.organizationName?r.customerProfile.organizationName:'XXXX' }}</span> - <span @click="onSpanClick" title="facilityContact">{{q.facilityContact.value?q.facilityContact.value:'XXXX'}}</span></p>
      <p class="no-mb"><span @click="onSpanClick" title="facilityAddress">{{q.facilityAddress.value?q.facilityAddress.value:'XXXX'}}</span></p>
      <p class="no-mb"><span @click="onSpanClick" title="facilityCity">{{q.facilityCity.value?q.facilityCity.value:'XXXX'}}</span>, <span @click="onSpanClick" title="facilityState" >{{q.facilityState.value?q.facilityState.value:'XXXX'}}</span> <span @click="onSpanClick" title="facilityPostal">{{q.facilityPostal.value?q.facilityPostal.value:'XXXX'}}</span></p>

      <h2>Operations Reviewed</h2>
      <table>
        <tr>
          <th>No.</th><th class="text-left">Departments/Area</th>
        </tr>
        <tr>
          <td>1</td><td>Pre-Production</td>
        </tr>
        <tr>
          <td>2</td><td>Production</td>

        </tr>
        <tr>
          <td>3</td><td>Post-Production</td>
        </tr>

      </table>
    </section>

    <section>
      <h1>Current State</h1>
      <h2>Organization</h2>
      <p><span @click="onSpanClick" title="customerProfile.organizationName">{{ r.customerProfile.organizationName?r.customerProfile.organizationName:'XXXX' }}</span> is a <span title="X200.notes" @click="onSpanClick">{{  iQ.X200.notes }}</span> that processes print requirements for <span title="X201.notes" @click="onSpanClick">{{  iQ.X201.notes }}</span>.</p>
      <p>The types of printed materials include <span title="X202.final" @click="onSpanClick">{{  listValue(iQ.X202) }}</span>.</p>
      <p>Approximately <span title="I1003.final" @click="onSpanClick">{{  (iQ.I1003.answer.final/12) | decimal(0) }}</span> projects are processed monthly. The total monthly volume includes <span title="X118.final" @click="onSpanClick">{{  (iQ.X118.answer.final/12) | decimal(0) }}</span> color, <span title="X119.final" @click="onSpanClick">{{  (iQ.X119.answer.final/12) | decimal(0) }}</span> monochrome and <span title="X203.final" @click="onSpanClick">{{  (iQ.X203.answer.final/12) | decimal(0) }}</span> MICR. The number of envelopes processed monthly is <span title="X204.final" @click="onSpanClick">{{  (iQ.X204.answer.final/12) | decimal(0) }}</span>. Inserts range from <span title="X205.notes" @click="onSpanClick">{{  iQ.X205.notes }}</span> to <span title="X206.notes" @click="onSpanClick">{{  iQ.X206.notes }}</span> per envelope.</p>

      <h2>Software and Platforms</h2>
      <p>Software and Platforms to process materials include:</p>
      <p class="bold-label"><span title="X120.notes" @click="onSpanClick">Software</span></p>
      <ul class="list-disc">
        <li v-for="(software, index) in softwareItems" :key="'software'+index">{{ software }}</li>
      </ul>
      <p class="bold-label"><span title="X207.notes" @click="onSpanClick">Print Resources</span></p>
      <ul class="list-disc">
        <li v-for="(software, index) in resourceItems" :key="'software'+index">{{ software }}</li>
      </ul>
      <p class="bold-label"><span title="X121.notes" @click="onSpanClick">Platforms</span></p>
      <ul class="list-disc">
        <li v-for="(software, index) in platformItems" :key="'software'+index">{{ software }}</li>
      </ul>

      <h2>Processes</h2>
      <h3>Scanning</h3>
      <p>Scanning and digitizing documents provides efficiency in the distribution and archival of important correspondence. Hardware and software scanning solutions facilitate creating actionable information that can be delivered to your lines of business applications, workflows and repositories.</p>
      <p v-if="iQ.X208.notes === 'Yes'"><span title="customerProfile.organizationName" @click="onSpanClick">{{  r.customerProfile.organizationName?r.customerProfile.organizationName:'XXXX' }}</span> currently scans documents utilizing <span title="X209.notes" @click="onSpanClick">{{  listValue(iQ.X209) }}</span> equipment and <span title="X210.notes" @click="onSpanClick">{{  listValue(iQ.X210) }}</span> software. The types of documents that are scanned include <span title="X211.notes" @click="onSpanClick">{{  listValue(iQ.X211) }}</span> and the total yearly volume is estimated at <span title="X212.final" @click="onSpanClick">{{  iQ.X212.answer.final | decimal(0) }}</span> pages.</p>
      <p v-else><span title="customerProfile.organizationName" @click="onSpanClick">{{  r.customerProfile.organizationName?r.customerProfile.organizationName:'XXXX' }}</span> does not scan documents for distribution or archival. <span title="customerProfile.organizationName" @click="onSpanClick">{{  r.customerProfile.organizationName?r.customerProfile.organizationName:'XXXX' }}</span> receives <span title="X213.final" @click="onSpanClick">{{  iQ.X213.answer.final | decimal(0) }}</span> pages over the course of a year that could potentially be scanned and digitized for distribution and archival.</p>
      <h3>Preference Management</h3>
      <p>Customers have preferences for communications that range from print to SMS text messaging and email communications. It is important to provide a customer experience in their preferred method of communications to ensure you achieve the highest level of interaction and response with customers.</p>
      <p v-if="iQ.X154.notes === 'Yes'">Preference management data <span title="X154.notes" @click="onSpanClick">is</span> available.  The preference management data is managed via <span title="X155.notes" @click="onSpanClick">{{  iQ.X155.notes?iQ.X155.notes:'XXXX' }}</span>.</p>
      <p v-else>Preference management data <span title="X154.notes" @click="onSpanClick">is not</span> available.  Preference management <span title="X156.notes" @click="onSpanClick">{{  iQ.X156.notes==='Yes'?'is':'is not' }}</span> planning to be implemented in the next 12 months.</p>
      <p v-if="iQ.X157.notes"><span title="X157.notes" @click="onSpanClick">{{ iQ.X157.notes }}</span></p>

      <h3>Campaign Management</h3>
      <p>An initiative that involves a series of intentional messaging around a product launch or event. The initiative is typically targeted to return certain responses. Campaigns can be sent using mail, email, social media, surveys, etc. Tracking and recording response results are customarily part of a campaign.</p>
      <p>Campaigns are tracked and triggered <span title="X158.notes" @click="onSpanClick">{{  listValue(iQ.X158) }}</span>. There <span title="X159.notes" @click="onSpanClick">{{  iQ.X159.notes === 'Yes'?'is':'is no' }}</span> capability to produce reports or dashboards based on campaign data.</p>

      <h3>Content Management</h3>
      <p>Management of resources; that can include, approved verbiage, inserts, brand standards, colors, logos, signatures, etc. Content Management is the process that provides controls and governance of these resources. It is typically combined with software that supports the collection of resources used to publish output via print, email, social media, etc. Steps within Content Management include retrieval, governance, and overall management of information. </p>
      <p>Content is stored on a <span title="X132.notes" @click="onSpanClick">{{  iQ.X132.notes?iQ.X132.notes:'XXXX' }}</span>. <span title="X133.notes" @click="onSpanClick" v-if="iQ.X132.notes === 'ECM System'">The ECM Software Platform is {{  iQ.X133.notes?iQ.X133.notes:'XXXX' }}.</span> Content <span title="X134.notes" @click="onSpanClick">{{  iQ.X134.notes==='Yes'?'is':'is not' }}</span> categorized, tagged, and organized via a consistent methodology. Data is stored in <span title="X135.notes" @click="onSpanClick">{{  iQ.X135.notes?iQ.X135.notes:'XXXX' }}</span>. Data is stored using <span title="X136.notes" @click="onSpanClick">{{  listValue(iQ.X136) }}</span>.</p>

      <h3>Document Composition</h3>
      <p>The process of creating communications using composition software to distribute information to customers. This process typically involves using specific business rules in combination with variable data (invoices, letters, statements, etc.) to publish output via print, email, social media, etc.</p>
      <p>Data used to create communications is in <span title="X214.notes" @click="onSpanClick">{{  listValue(iQ.X214) }}</span> format. Data is imported into <span title="X215.notes" @click="onSpanClick">{{  listValue(iQ.X215) }}</span> to create communications. Fully composed output is created in <span title="X216.notes" @click="onSpanClick">{{  iQ.X216.notes?iQ.X216.notes:'XXXX' }}</span>. Output is passed to <span title="X217.notes" @click="onSpanClick">{{  listValue(iQ.X217) }}</span>.</p>

      <h3>Document Workflow</h3>
      <p>A document’s workflow begins with the data input, normalizing that data and processing through production and distribution while ensuring document security, integrity and compliance</p>
      <p>The current workflow process includes <span title="X220.notes" @click="onSpanClick">{{  iQ.X220.notes?iQ.X220.notes:'XXXX' }}</span> to <span title="X221.notes" @click="onSpanClick">{{  listValue(iQ.X221) }}</span>.</p>

      <h3>Document Archival</h3>
      <p>Archiving data and documents are required to meet regulations. It is important to take into consideration the server capacity, security and retention policies when architecting an archival system. Metadata is incorporated into archived documents to ensure that users can easily access and retrieve the archived documents.</p>
      <p>The current archival process is <span title="X222.notes" @click="onSpanClick">{{  iQ.X222.notes?iQ.X222.notes:'XXXX' }}</span> and the requirements are <span title="X223.notes" @click="onSpanClick">{{  iQ.X223.notes?iQ.X223.notes:'XXXX' }}</span>. The following correspondence types are archived: <span title="X224.notes" @click="onSpanClick">{{  listValue(iQ.X224) }}</span>. The volume of archived documents is <span title="X225.final" @click="onSpanClick">{{  iQ.X225.answer.final | decimal(0) }}</span> documents requiring approximately <span title="X226.final" @click="onSpanClick">{{  iQ.X226.answer.final | decimal(0) }}</span> gigabytes of server space. The retention policy includes <span title="X227.notes" @click="onSpanClick">{{  iQ.X227.notes?iQ.X227.notes:'XXXX' }}</span>.</p>

      <h3>Communication/Correspondence Methods</h3>
      <p>Effective messaging is critical when defining the call-to-action to the recipient. Selecting an effective communication/correspondence method will increase response rates. Communication/correspondence can be in the form of digital and physical/printed media.</p>
      <p><span title="customerProfile.organizationName" @click="onSpanClick">{{  r.customerProfile.organizationName?r.customerProfile.organizationName:'XXXX' }}</span> currently provides communications in the following methods: <span title="X143.notes" @click="onSpanClick">{{  listValue(iQ.X143) }}</span></p>

      <h3>Production Resources/Sourcing</h3>
      <p>Effectively managing purchasing and sourcing processes ensures cost efficiency, materials and services availability and organizational throughput. It is important to take into consideration integration of systems, scheduling, delivery timeframes and supplier performance criteria when establishing an effective procurement system.</p>
      <p><span title="customerProfile.organizationName" @click="onSpanClick">{{  r.customerProfile.organizationName?r.customerProfile.organizationName:'XXXX' }}</span> has a base of <span title="X228.final" @click="onSpanClick">{{  iQ.X228.answer.final | decimal(0) }}</span> suppliers. The current purchasing process includes <span title="X229.notes" @click="onSpanClick">{{  iQ.X229.notes?iQ.X229.notes:'XXXX' }}</span></p>

      <h3>Distribution/Mailing Procedures</h3>
      <p>Outbound distribution includes first class mail, pre-sorted letters and flats, and can require different ancillary service through third party carriers. Based on the requirements of the service providers, procedures will follow the documentation required to effectively process shipments through their systems.</p>
      <p>Proof of delivery <span title="X230.notes" @click="onSpanClick">{{  iQ.X230.notes === 'Yes'?'is':'is not' }}</span> required. USPS Informed Visibility for piece level tracking <span title="X231.notes" @click="onSpanClick">{{  iQ.X231.notes === 'Yes'?'is':'is not' }}</span> required. Critical mail <span title="X232.notes" @click="onSpanClick">{{  iQ.X232.notes === 'Yes'?'is':'is not' }}</span> regulated.</p>

      <h3>Inventory Management</h3>
      <p>It is critical to maintain and monitor inventory levels to avoid production stoppage. A Print and Mail operation will need to hold inventory of raw materials (paper, envelopes, etc.), production materials, fulfillment items/inserts and maintenance supplies.</p>
      <p>Current inventory is managed using <span title="X233.notes" @click="onSpanClick">{{  iQ.X233.notes?iQ.X233.notes:'XXXX' }}</span> software. There are <span title="X234.notes" @click="onSpanClick">{{  iQ.X234.notes?iQ.X234.notes:'XXXX' }}</span> different paper types (paper weight, paper size, letterhead, etc.). There are <span title="X235.notes" @click="onSpanClick">{{  iQ.X235.notes?iQ.X235.notes:'XXXX' }}</span> different envelope types (envelope size, color, windows, etc.). There are <span title="X236.notes" @click="onSpanClick">{{  iQ.X236.notes?iQ.X236.notes:'XXXX' }}</span> number of inserts.</p>

      <h3>SLAs</h3>
      <p>Service level agreements (SLAs)/turnaround time requirements can be defined by government agencies, organizations, customers, etc. SLAs dictate when specific processes should be completed. An example of this includes a print file upon receipt should be mailed within a specified period of time (ex. 24hrs).</p>
      <p>Current SLA requirements include <span title="X237.final" @click="onSpanClick">{{  iQ.X237.answer.final | decimal(0) }}</span> days to mail documents. Current datafiles must be processed within <span title="X238.final" @click="onSpanClick">{{  iQ.X238.answer.final | decimal(0) }}</span> hours to meet SLA requirements. SLAs are tracked through <span title="X239.notes" @click="onSpanClick">{{  iQ.X239.notes?iQ.X239.notes:'XXXX' }}</span>.</p>

      <h3>Reports</h3>
      <p>Reports providing details on organizational statistics help to effectively manage the business. Monitoring production, volume and financial aspects of a business is attained through reports that can be generated in enterprise software solutions. These reports can include job status, labor/equipment productivity, volume of work and revenue.</p>
      <p>Reports currently monitor <span title="X240.notes" @click="onSpanClick">{{  listValue(iQ.X240) }}</span>. Reports are generated <span title="X241.notes" @click="onSpanClick">{{  listValue(iQ.X241) }}</span>. The amount of time spent producing reports on a monthly basis is estimated at <span title="X242.final" @click="onSpanClick">{{  iQ.X242.answer.final | decimal(0) }}</span> hours.</p>

      <h3>Tracking/Auditing/Compliance</h3>
      <p>Monitoring and tracking of data supports reporting requirements for compliance.</p>
      <p>There <span title="X147.notes" @click="onSpanClick">{{  iQ.X147.notes === 'Yes'?'is':'is not' }}</span> an end-to-end tracking system in place. <span title="X147.final" @click="onSpanClick" v-if="iQ.X147.notes === 'Yes'">The tracking system includes</span> <span v-if="iQ.X147.notes === 'Yes'" title="X148.notes" @click="onSpanClick">{{  listValue(iQ.X148) }}.</span> <span v-if="iQ.X147.notes !== 'Yes'">The system used for tracking is </span><span v-if="iQ.X147.notes !== 'Yes'" title="X149.notes" @click="onSpanClick">{{  listValue(iQ.X149) }}.</span></p>

      <h3>System Integration</h3>
      <p>Systems that are integrated rather than siloed provide efficiency that yields faster turnaround, accuracy and throughput for an operation.</p>
      <p>Systems that currently share data across the organization include: <span title="X243.notes" @click="onSpanClick">{{  iQ.X243.notes?iQ.X243.notes:'XXXX' }}</span>. Systems that are siloed where information must be re-entered into other systems or manually processed include: <span title="X244.notes" @click="onSpanClick">{{  iQ.X244.notes?iQ.X244.notes:'XXXX' }}</span>.</p>

      <h3>Security Conformance</h3>
      <p>Security conformance initiatives safeguard an organization for malware attacks, provide access controls and develop plans to reduce incidents. In addition, having procedures to manage PII are essential to safeguard customer data.</p>
      <p><span title="customerProfile.organizationName" @click="onSpanClick">{{  r.customerProfile.organizationName?r.customerProfile.organizationName:'XXXX' }}</span> <span title="X245.notes" @click="onSpanClick">{{  iQ.X245.notes === 'Yes'?'has':'does not have' }}</span> a security framework in place to manage PII. <span title="customerProfile.organizationName" @click="onSpanClick">{{  r.customerProfile.organizationName?r.customerProfile.organizationName:'XXXX' }}</span> <span title="X246.notes" @click="onSpanClick">{{  iQ.X246.notes === 'Yes'?'has':'does not have' }}</span> a security framework to assess, monitor, and mitigate cybersecurity risk.</p>

      <h3>Disaster Recovery</h3>
      <p>The current Disaster Recovery Plan that ensures that your organization can continue to distribute printed materials and other communications in the event of a disaster includes <span title="X165.notes" @click="onSpanClick">{{  iQ.X165.notes?iQ.X165.notes:'XXXX' }}</span>. The Disaster Recovery production site is located <span title="X166.final" @click="onSpanClick">{{  iQ.X166.answer.final | decimal(0) }}</span> miles from your current operation.</p>
      <p>SLA's (Service Level Agreements)/required turnaround times and types of materials that need to be distributed in the event of a disaster include <span title="X167.notes" @click="onSpanClick">{{  iQ.X167.notes?iQ.X167.notes:'XXXX' }}</span>.</p>
      <p>SLAs are tracked through <span title="X168.notes" @click="onSpanClick">{{  iQ.X168.notes?iQ.X168.notes:'XXXX' }}</span>.</p>
      <p>Critical mail <span title="X169.notes" @click="onSpanClick">{{  iQ.X169.notes === 'Yes'?'is':'is not' }}</span> regulated.</p>
      <p>Requirements to track and report outbound mail from production completion through delivery include <span title="X170.notes" @click="onSpanClick">{{  iQ.X170.notes?iQ.X170.notes:'XXXX' }}</span>.</p>
    </section>
    <section>
      <h1>Recommendations</h1>
      <h2>Proposed Solutions — Operation's Profile</h2>
      <p>Based on the information that was gathered as a part of this study, we have configured an onsite operation to include the following components:</p>
      <summary-report :configuration="reportData"></summary-report>
      <p></p>
      <h2>Workflow</h2>
      <p>A best-in-class print production workflow includes the following components. Our recommendations for the solutions that would drive this workflow are included after the diagram.</p>
      <img class="w-full mb-8" src="/reports/config-workflow.png" data-ratio="0.5886" />
<!--      <component data-container="true" v-for="(solution) in solutions.Other" :key="solution.id" :is="solution.component"></component>-->

      <template v-if="solutions.workflow">
        <h2>Workflow Solutions</h2>
        <component data-container="true" v-for="(solution) in solutions.workflow" :key="solution" :is="solution"></component>
        <p></p>
      </template>
      <template v-if="solutions.composition">
        <h2>Composition Solutions</h2>
        <component data-container="true" v-for="(solution) in solutions.composition" :key="solution" :is="solution"></component>
        <p></p>
      </template>
      <template v-if="solutions.admin">
        <h2>Administration</h2>
        <component data-container="true" v-for="(solution) in solutions.admin" :key="solution" :is="solution"></component>
        <p></p>
      </template>
      <template v-if="solutions.mail">
        <h2>Mailing Solutions</h2>
        <component data-container="true" v-for="(solution) in solutions.mail" :key="solution" :is="solution"></component>
        <p></p>
      </template>
      <template v-if="solutions.other">
        <h2>Other Recommended Solutions</h2>
        <component data-container="true" v-for="(solution) in solutions.other" :key="solution" :is="solution"></component>
        <p></p>
      </template>

      <h2>Benefits</h2>
      <p>The proposed solution will align with organizational goals and objectives, provide compliance along with administrative efficiency and improved customer experience. Onsite production has the potential to provide the following benefits:</p>
      <p class="bold-label">1.  Time Savings</p>
      <p>Fast turnaround time with Piece level tracking. This tracking can be turned on during piece production and while in the USPS mail stream. With the piece level tracking there is internal accountability. </p>
      <p class="bold-label">2.	Cost Savings</p>
      <p>With the addition of Presort software, discounts on postage can be taken by the organization, thus reducing postage costs.</p>
      <p class="bold-label">3.	Regulatory Compliance</p>
      <p>Meet requirements that prohibits debt collectors from communicating information about consumer debtors to mail vendors in connection with the collection of any consumer debt. </p>
      <p class="bold-label">4.	Output Integrity and Ability to Satisfy Reporting Requirements </p>
      <p>Compliance penalties are a serious source of concern for businesses dealing with critical communications delivery. RICOH ProcessDirector tracks documents to help achieve 100% integrity from receipt to delivery. </p>
      <ul>
        <li>Easily generate a piece level audit trail of production history.</li>
        <li>Verify that every piece is produced accurately using either network-capable inserters or barcode scanners and automate reprinting of damaged or missing pieces.</li>
        <li>Collect and store job, device and document properties throughout the workflow.</li>
        <li>Use RICOH ProcessDirector to store production history data alongside the input and output print files for long-term storage, retrieval, reprinting and online viewing.</li>
        <li>Use data from RICOH ProcessDirector to create reports using commercially available business intelligence tools for capacity planning, production optimization and problem determination.</li>
        <li>Research the production history of individual documents to respond to queries or audit requests.</li>
        <li>Configure user access rights to control system usage.</li>

      </ul>
      <p class="bold-label">5.	Control</p>
      <ul>
        <li>Empower the business to control communications</li>
        <li>Drive more control over correspondence and communications</li>
        <li>Meet requirements to improve time to market with communications</li>
        <li>Enable version control, validity of dates, approval status and full approval tracking</li>
        <li>Document security and more efficient operations</li>
        <li>Create centralized, enterprise-wide process automation and task management, and eliminate manual and/or redundant steps</li>
      </ul>
      <p class="bold-label">6.	Integration/Interoperability</p>
      <ul>
        <li>Integrate directly with existing core systems or print and mail processing applications</li>
        <li>Enable production of print, mobile and web communications through single platform and design</li>
      </ul>
      <p class="bold-label">7.	Workflow Efficiency</p>
      <p>Automated tasks can reduce costs and eliminate human error. RICOH ProcessDirector helps teams manage deadlines by automating workflow processes and providing visibility into every job from receipt to delivery. </p>
      <ul>
        <li>Easily build workflows using a drag-and-drop interface.</li>
        <li>Specify finishing, media and duplex instructions to sheet-fed or continuous feed printers when work is scheduled or change them on the fly.</li>
        <li>Comply with last-minute pull requests.</li>
        <li>Create a full audit trail, including manually deleted jobs.</li>
        <li>Merge electronic forms with variable data in one printing pass, eliminating storage costs and manual workflows of preprinted forms.</li>
        <li>Integrate with other systems via web services.</li>
        <li>Automatically reprint mail pieces damaged during insertion.</li>

      </ul>
      <p class="bold-label">8.	Better Customer Experience:</p>
      <p>Drive Omni-Channel Customer Experience</p>
      <ul>
        <li>A single solution for print and digital saves up to 70% of design time</li>
        <li>Provides a powerful customer experience</li>
        <li>Personalized up-sell and cross-sell promotions with digital and print output</li>

      </ul>


    </section>



  </div>
</template>

<script>

import schema from "@/schema/siteConfig"

import AvantiSlingshot from '@/components/Solutions/AvantiSlingshot'
    import MarketDirect from '@/components/Solutions/MarketDirect'
    import MarcomCentral from '@/components/Solutions/MarcomCentral'
    import WebCrd from '@/components/Solutions/WebCrd'
    import EfiPace from '@/components/Solutions/EfiPace'
    import PrintSmithVision from '@/components/Solutions/PrintSmithVision'
    import EfiQuickPrint from '@/components/Solutions/EfiQuickPrint'
    import FusionPro from '@/components/Solutions/FusionPro'
    import ProductionWorkflowAssessment from '@/components/Solutions/ProductionWorkflowAssessment'
    import EFICrossMedia from '@/components/Solutions/EFICrossMedia'
    import EFICrossMediaCampaigns from '@/components/Solutions/EFICrossMediaCampaigns'
    import ProductionWorkflow from '@/components/Solutions/ProductionWorkflow'
    import RsaQdirect from '@/components/Solutions/RsaQdirect'

    import CcmAssessment from '@/components/Solutions/CcmAssessment'
    import CampaignServices from '@/components/Solutions/CampaignServices'
    import PlanetPressConnect from '@/components/Solutions/PlanetPressConnect'
    import PresConnect from '@/components/Solutions/PresConnect'
    import PrintShopMailConnect from '@/components/Solutions/PrintShopMailConnect'
    import QuadientImpress from '@/components/Solutions/QuadientImpress'
    import QuadientInspire from '@/components/Solutions/QuadientInspire'
    import UluroSolutions from '@/components/Solutions/UluroSolutions'
    import CCMDesign from '@/components/Solutions/CCMDesign'
    import PrintShopMail from '@/components/Solutions/PrintShopMail'

    import FacilityBestPractices from '@/components/Solutions/FacilityBestPractices'
    import PurchasingBestPractices from '@/components/Solutions/PurchasingBestPractices'
    import InventoryBestPractices from '@/components/Solutions/InventoryBestPractices'
    import ContentManagement from '@/components/Solutions/ContentManagement'

    import MailOptimization from '@/components/Solutions/MailOptimization'
    import BccMailManager from '@/components/Solutions/BccMailManager'
    import QuadientBulkMailer from '@/components/Solutions/QuadientBulkMailer'
    import QuadientAims from '@/components/Solutions/QuadientAims'
    import ProcessDirector from '@/components/Solutions/ProcessDirector'
    import ElectronicBillPayment from '@/components/Solutions/ElectronicBillPayment'
    import StandardOperatingProcedures from '@/components/Solutions/StandardOperatingProcedures'
    import SupportTraining from '@/components/Solutions/SupportTraining'
    import OpsChecklist from '@/components/Solutions/OpsChecklist'
    import RicohConsultingServices from '@/components/Solutions/RicohConsultingServices'
    import SecurityConformance from '@/components/Solutions/SecurityConformance'
    import DisasterRecovery from '@/components/Solutions/DisasterRecovery'
import SummaryReport from "@/components/PrintConfig/SummaryReport";




export default {
    name: "report-site-config-full",
  components: {
    SummaryReport,
      AvantiSlingshot,
      MarketDirect,
      MarcomCentral,
      WebCrd,
      EfiPace,
      PrintSmithVision,
      EfiQuickPrint,
      FusionPro,
      ProductionWorkflowAssessment,
      EFICrossMedia,
      EFICrossMediaCampaigns,
      ProductionWorkflow,
      RsaQdirect,

      CcmAssessment,
      CampaignServices,
      PlanetPressConnect,
      PresConnect,
      PrintShopMailConnect,
      QuadientImpress,
      QuadientInspire,
      UluroSolutions,
      CCMDesign,
      PrintShopMail,

      FacilityBestPractices,
      PurchasingBestPractices,
      InventoryBestPractices,
      ContentManagement,

      MailOptimization,
      BccMailManager,
      QuadientBulkMailer,
      QuadientAims,
      ProcessDirector,
      ElectronicBillPayment,
      StandardOperatingProcedures,
      SupportTraining,
      OpsChecklist,
      RicohConsultingServices,
      SecurityConformance,
      DisasterRecovery,
  },
  mounted: function(){
    this.$emit("mounted");
  },
    props: {
      reportData: {
        required: true,
      },
      api: {
        required: true
      }
    },
    data: function(){
        return {
          schema: schema
        }
        },
    methods: {




      dateString: function(ts){
        let date = new Date(ts);
        return (date.getMonth()+1) + "/"+date.getDate() + "/"+date.getFullYear();
      },

      editOrg: function(){
        alert("Exit preview and go to the Facility Profile section to change organization information.")
      },

      editSettings: function(){
        console.log("edit Settings");
      },

      onChange: function(e){

        this.$emit("change",e);

      },

      onSpanClick: function(e){
        console.log(e);
        if(e.target.title){
          let qPath = e.target.title.split(".");
          if(qPath.length){
            let editInfo;

            if(this.iQ[qPath[0]]){
              //interview question
              editInfo = {
                question: this.schema.questions[qPath[0]],
                answer: this.reportData.interview.questions[qPath[0]]
              }
            }else if(this.q[qPath[0]]){
              //interview question
              editInfo = {
                question: this.api.questions[qPath[0]],
                answer: this.reportData.answers[qPath[0]]
              }
            }

            if(editInfo){
              this.$emit('edit',editInfo);
            }
          }
        }
        
        //this.$emit('edit',e.dataset.title);
      },
      listValue: function(answer){

        let list = answer.notes;

        if(!list){
          return "XXXX"
        }




        let selections = list.split(",");
        if(selections.length > 2){
          selections[selections.length -1] = 'and '+selections[selections.length -1];
        }

        let result;



        if(selections.length < 3){
          result = selections.join(' and ');
        }else{
          result = selections.join(', ');
        }

        if(answer.answer.final){

          let otherText = "Other";

          let question = this.iQ[answer.questionID];


          if(question.choices){
            otherText = question.choices[question.choices.length - 1];
          }

          result = result.replace(otherText, answer.answer.final)

        }

        return result;
      },

      bulletValue: function(answer){

        let list = answer.notes;






        if(answer.answer.final){

          let otherText = "Other";

          let question = this.iQ[answer.questionID];


          if(question.choices){
            otherText = question.choices[question.choices.length - 1];
          }

          list = list.replace(otherText, answer.answer.final)


        }

        let selections = list.split(",");

        return selections;
      },
    },
    computed: {

       r: function(){
         return this.reportData;
       },

      q: function(){
        return this.r.answers;
      },

      iQ: function(){
        return this.r.interview.questions;
      },

      currentDate: function(){
        return (new Date(Date.now())).toLocaleDateString()
      },

      softwareItems: function(){
        return this.bulletValue(this.iQ.X120);
      },

      platformItems: function(){
        return this.bulletValue(this.iQ.X121);
      },

      resourceItems: function(){
        return this.bulletValue(this.iQ.X207);
      },

      solutions: function(){


        if(this.r && this.r.exportSettings){

          return this.r.exportSettings.solutions;
        }

        return {}

      },
    }
}
</script>

<style scoped>


.report-production-workflow {

}

p[data-bullet="true"]{
  display: list-item;
  margin-left: 1em;
  margin-bottom: 0;
}

p[data-bullet="true"]:last-child {
  margin-bottom: 1em;
}

</style>
