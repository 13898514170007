export default {
    requirements: {
        low: {
            id: 'low',
            name: 'Low',
            totalFootage: 64,
            staffing: 0.5,
            staffingCode: "sm",
            equipment: [],
            software: [],
            otherItems: []
        },

        medium: {
            id: 'medium',
            name: 'Medium',
            totalFootage: 64,
            staffing:1,
            staffingCode: "sm",
            equipment: [],
            software: [],
            otherItems: []
        },

        high: {
            id: 'high',
            name: 'High',
            totalFootage: 64,
            staffing: 1.5,
            staffingCode: "sm",
            equipment: [],
            software: [],
            otherItems: []
        },
    },

    ratingDetails: [
        {
            id: 'level',
            type: 'enum',
            required: false,
            component: 'PulloutQuestionMultiSwitchList',
            default: 0,
            label: "Level of Need:",
            options: [
                {
                    id: "none",
                    label: "None",
                    value: 0
                },

                {
                    id: "low",
                    label: "Low",
                    description: "up to 251 Devices",
                    value: 1
                },
                {
                    id: "medium",
                    label: "Medium",
                    description: "up to 500 Devices",
                    value: 2
                },
                {
                    id: "high",
                    label: "High",
                    description: "up to 700 Devices",
                    value: 3
                }
            ]
        }

    ]
}
