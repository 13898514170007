


<template>
  <div>
    <h4>ePS PrintSmith Vision</h4>
    <hr>
    <p>ePS PrintSmith™ Vision is an affordable print management software solution that understands and automates print operational processes in franchise shops, small print shops, print-on-demand and in-plant facilities, including print estimating, production, accounting, sales management and eCommerce.</p>
    <p>A flexible and affordable browser-based Management Information System (MIS). PrintSmith Vision automates key elements in your printing workflow, reducing touch points, streamlining operations and optimizing business growth. And, provides you full business reporting capabilities in real-time analytics that allow businesses to identify productivity and job costing areas.</p>
    <p class="bold-label">Key Features:</p>
    <ul class="list-disc">
      <li>Intuitive and easy-to-use browser-based print management solution</li>
      <li>Integration to EFI best-in-class eCommerce solutions</li>
      <li>Direct communication with EFI Fiery for touchless print production</li>
      <li>Tools to effectively manage key operational processes like estimating, point-of-sale, account management, production management, receivables and sales analysis</li>
      <li>Powerful Scheduling, Shop Floor Data Collection and Job Costing solutions</li>
    </ul>
    <p class="bold-label">Benefits:</p>
    <ul class="list-disc">
      <li>Streamlines printing workflow from job submission, to estimating, scheduling and production</li>
      <li>Improves visibility into your business performance allowing you to make informed decisions and maximize organization’s profitability</li>
      <li>Increases production accuracy, waste reduction and job turnaround times</li>
      <li>Provides secure, fast and easy access to your information anytime from anywhere</li>
    </ul>
    <p class="bold-label">Process Automation and Integration:</p>
    <p>With ePS PrintSmith Vision you cut costs, reduce job turnaround times and win more business. Certified end-to-end workflows and integrations with the leading solutions for eCommerce and direct communication with the EFI to Fiery Central and Command WorkStation provide out-of-the-box value in a 100% hosted solution that is designed to minimize headaches and get your print production optimized quickly.</p>
  </div>
</template>

<script>
export default {
    name: "print-smith-vision",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


.print-smith-vision {

}

</style>