


<template>
  <div v-if="r" id="report">

    <section class="">
      <table class="titlebox absolute text-white" style="font-size: 1vw; left: 11em; width:28em; margin-top: 11em;">
        <tr><td class="font-bold" style="font-size: 2.5em; padding-bottom: 0.5em;" data-cell-style="TitlePageTitleCell">FINDINGS REPORT</td></tr>
        <tr><td class="font-bold leading-none" style="font-size: 2em; padding-bottom: 1em;" data-cell-style="TitlePageSubtitleCell">Production Workflow Assessment</td></tr>
        <tr><td class="leading-snug" style="font-size: 1.2em;" data-cell-style="TitlePageCell">Confidential: For the exclusive use of Ricoh and <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span>.  This document cannot be shared with any other parties without written permission from all of the parties referenced here.</td>
        </tr>
        <tr><td style="padding-bottom: 2em;">  </td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell">V1.0</td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell"><span>{{ r.userProfile.firstname }} {{ r.userProfile.lastname }}</span></td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell"><span>{{ r.userProfile.title }}</span></td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell"><span>{{ currentDate }}</span></td></tr>
      </table>

      <img class="w-full -mx-8 block" data-float="true" data-ratio="1.2941" src="/reports/title-bkg.png" />



      <p></p>



    </section>
    <section>
      <p class="font-bold no-mb">Prepared for:</p>
      <p class="font-bold no-mb "><span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> - <span  @click="onSpanClick">{{ r.customerProfile.customerContact }}</span></p>
      <p class="no-mb"><span  @click="onSpanClick">{{ r.customerProfile.address }}</span></p>
      <p class="no-mb"><span  @click="onSpanClick">{{ r.customerProfile.city }}</span>, <span  @click="onSpanClick">{{ r.customerProfile.state }}</span> <span  @click="onSpanClick">{{ r.customerProfile.postalcode }}</span></p>
      <p class="mt-8 no-mb font-bold">Prepared by:</p>
      <p class="no-mb"><span>{{ r.userProfile.firstname }}</span> <span>{{ r.userProfile.lastname }}</span></p>
      <p class="no-mb" v-if="r.userProfile.organization"><span>{{ r.userProfile.organization }}</span></p>
      <p class="no-mb"><span>{{ r.userProfile.title }}</span></p>
      <p class="no-mb mt-8"><span>{{ currentDate }}</span></p>
    </section>



    <section>
      <p class="toc-title">TABLE OF CONTENTS</p>
      <p data-toc="true" data-alias="Table of Contents" data-levels="1-3">Table of Contents here.</p>
    </section>

    <section>
      <h1>Overview</h1>
      <h2>Background</h2>

      <p v-if="!option2"><span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> is seeking recommendations to improve profitability and allow room for growth by streamlining workflow for estimating, customer ordering, job ticket development, data collection, invoicing and reporting.</p>
      <p v-if="!option2">To support the efforts of the Print Production Team at <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> and to determine where Ricoh could help with overall improvements, Ricoh conducted a Production Workflow Assessment to benchmark <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> against industry best practices.  The assessment looked at the entire production operation with strong focus on removing manual processes and improving overall job flow.</p>
      <p v-if="option2" ><span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> is seeking recommendations to improve operational efficiency and customer ease of use by implementing the latest online print ordering technology. To support these efforts and to determine where Ricoh could help with overall improvements, Ricoh conducted a Web to Print Readiness Assessment to benchmark <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> against industry best practices and determine the appropriate solution to meet the stated goals.  The assessment looked at the overall operation from quote requests and estimating to invoicing and reporting.</p>

      <h2>Methodology</h2>
      <p><span>{{ r.userProfile.firstname }}</span> <span>{{ r.userProfile.lastname }}</span>, Ricoh’s <span>{{ r.userProfile.title }}</span> visited <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> on <span  @click="onSpanClick">{{ r.customerProfile.assessmentDate }}</span> to conduct a Production Workflow Assessment. The assessment goals were to review job production workflows to identify areas of possible improvement and potential issues of compliance.</p>
      <p>Processes reviewed:</p>
      <ul class="list-disc">
        <li>Estimating</li>
        <li>Order Processing</li>
        <li>Job Ticket Development</li>
        <li>Production Workflow</li>
        <li>Finishing</li>
        <li>Inventory Management</li>
        <li>Shipping</li>
        <li>Billing/Invoicing</li>
        <li>Report Development</li>
      </ul>

      <h2>Site</h2>
      <p class="no-mb"><span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> - <span  @click="onSpanClick">{{ r.customerProfile.customerContact }}</span></p>
      <p class="no-mb"><span  @click="onSpanClick">{{ r.customerProfile.address }}</span></p>
      <p class="no-mb"><span  @click="onSpanClick">{{ r.customerProfile.city }}</span>, <span  @click="onSpanClick">{{ r.customerProfile.state }}</span> <span  @click="onSpanClick">{{ r.customerProfile.postalcode }}</span></p>

      <h2>Departments Reviewed</h2>
      <table>
        <tr>
          <th>No.</th><th class="text-left">Departments/Area</th>
        </tr>
        <tr>
          <td>1</td><td>Management-Director, Manager, Supervisor, Sales</td>
        </tr>
        <tr>
          <td>2</td><td>Administration-Estimating, Job Ticketing, Customer Service</td>
        </tr>
        <tr>
          <td>3</td><td>Copyright Clearance</td>
        </tr>
        <tr>
          <td>4</td><td>Pre-Press</td>
        </tr>
        <tr>
          <td>5</td><td>Production Print</td>
        </tr>
        <tr>
          <td>6</td><td>Bindery/Finishing</td>
        </tr>
        <tr>
          <td>7</td><td>Fulfillment/Kitting</td>
        </tr>
        <tr>
          <td>8</td><td>Shipping/Receiving</td>
        </tr>
      </table>

    </section>
    <section>
      <h1>Current State</h1>
      <h2>Organization</h2>
      <p><span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span>’s production operations process <span title="I1003.final" @click="onSpanClick">{{ q.I1003.answer.final | decimal(0) }}</span> jobs per year with yearly sales of $<span title="I1001.final" @click="onSpanClick">{{ q.I1001.answer.final | decimal(0) }}</span>. They have a total of <span title="I1010.final" @click="onSpanClick">{{ q.I1010.answer.final | decimal(0) }}</span> employees with the following number of employees in different work groups:</p>
      <table>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Management:</td><td><span title="I1010A.final" @click="onSpanClick">{{ q.I1010A.answer.final | decimal(2) }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Estimators:</td><td><span title="I1011.final" @click="onSpanClick">{{ q.I1011.answer.final | decimal(2) }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Administrative/Job Ticket Devs/CSRs:</td><td><span title="I1011A.final" @click="onSpanClick">{{ q.I1011A.answer.final | decimal(2) }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Copyright Clearance:</td><td><span title="I1010C.final" @click="onSpanClick">{{ q.I1010C.answer.final | decimal(2) }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Pre-Press:</td><td><span title="I1010D.final" @click="onSpanClick">{{ q.I1010D.answer.final | decimal(2) }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Production Print:</td><td><span title="I1010E.final" @click="onSpanClick">{{ q.I1010E.answer.final | decimal(2) }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Bindery/Finishing:</td><td><span title="I1010F.final" @click="onSpanClick">{{ q.I1010F.answer.final | decimal(2) }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Fulfillment/Kitting:</td><td><span title="I1010G.final" @click="onSpanClick">{{ q.I1010G.answer.final | decimal(2) }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Shipping/Receiving:</td><td><span title="I1010H.final" @click="onSpanClick">{{ q.I1010H.answer.final | decimal(2) }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Sales:</td><td><span title="I1014.final" @click="onSpanClick">{{ q.I1014.answer.final | decimal(2) }}</span></td>
        </tr>
      </table>
      <p class="mt-4">The main products and services offered include <span title="I1024.notes" @click="onSpanClick">{{ q.I1024.notes }}</span>. Customers of the operation include <span title="I1025.notes" @click="onSpanClick">{{ q.I1025.notes }}</span>.</p>
      <p v-if="q.I1028.notes">Growth opportunities that are being considered include <span title="I1028.notes" @click="onSpanClick">{{ q.I1028.notes }}</span>.</p>
      <h2>Equipment and Software</h2>
      <p>Equipment includes:</p>
      <p class="ml-8 no-mb font-bold">Digital:</p>
      <p class="ml-8 font-bold"><span title="I1025A.notes" @click="onSpanClick">{{ q.I1025A.notes }}</span></p>
      <p class="ml-8 no-mb font-bold">Offset:</p>
      <p class="ml-8 font-bold"><span title="I1025B.notes" @click="onSpanClick">{{ q.I1025B.notes }}</span></p>
      <p class="ml-8 no-mb font-bold">Finishing:</p>
      <p class="ml-8 font-bold"><span title="I1025C.notes" @click="onSpanClick">{{ q.I1025C.notes }}</span></p>
      <p class="ml-8 no-mb font-bold">Mailing:</p>
      <p class="ml-8 font-bold"><span title="I1025D.notes" @click="onSpanClick">{{ q.I1025D.notes }}</span></p>
      <p class="ml-8 no-mb font-bold">Software:</p>
      <p class="ml-8 font-bold"><span title="I1025E.notes" @click="onSpanClick">{{ q.I1025E.notes }}</span></p>
      <p class="ml-8 font-bold">Plans for new equipment include <span title="I1025F.notes" @click="onSpanClick">{{ q.I1025F.notes }}</span></p>

      <h2>Workflow</h2>
      <h3>Estimating</h3>
      <p>Estimates are received via <span title="I1031.notes" @click="onSpanClick">{{ q.I1031.notes }}</span>. <span v-if="q.I1032.notes" title="I1032.notes" @click="onSpanClick">{{ q.I1032.notes }}.</span> <span title="I1041.0" @click="onSpanClick">{{ q.I1041.answer.Current | decimal(0)  }}</span> estimates are produced per month. There are <span title="I1011.0" @click="onSpanClick">{{ q.I1011.answer.final }}</span> estimators on staff who spend 100% of their time producing estimates. It takes approximately <span title="I1044A.0" @click="onSpanClick">{{ q.I1044A.answer.final | decimal(0)  }}</span> minutes to produce each estimate. Estimates are produced based on <span title="I1033.notes" @click="onSpanClick">{{ q.I1033.notes }}</span>. <span v-if="q.I1034.notes === 'Yes'">A standard pricing schedule is used to price repetitive work.   Tiered pricing is managed by <span title="I1035.notes" @click="onSpanClick">{{ q.I1035.notes }}</span>.   Outsourced/purchased work is estimated by <span title="I1036.notes" @click="onSpanClick">{{ q.I1036.notes }}</span> and managed by <span title="I1103.notes" @click="onSpanClick">{{ q.I1103.notes }}</span>.</span></p>
      <p>Challenges and opportunities for Estimating include <span title="I1037A.notes" @click="onSpanClick">{{ q.I1037A.notes }}</span>.</p>

      <h3>Order Entry</h3>
      <p>Orders are submitted via <span title="I1048B.notes" @click="onSpanClick">{{ q.I1048B.notes }}</span>.</p>

      <h3>Job Ticket Development</h3>
      <p><span title="I1059.0" @click="onSpanClick">{{ q.I1059.answer.Current | decimal(0) }}</span> job tickets are produced each year. They are developed by entering the information received into <span title="I1048.notes" @click="onSpanClick">{{ q.I1048.notes }}</span> by a team of <span title="I1011A.0" @click="onSpanClick">{{ q.I1011A.answer.final }}</span> customer service reps.</p>
      <p>Challenges and opportunities for Job Ticket Development include <span title="I1048A.notes" @click="onSpanClick">{{ q.I1048A.notes }}</span>.</p>

      <h3>Inventory</h3>
      <p><span title="I1068.0" @click="onSpanClick">{{ q.I1068.answer.Current | decimal(0) }}</span> inventory items are managed for print production and <span title="I1069.0" @click="onSpanClick">{{ q.I1069.answer.Current | decimal(0) }}</span> inventory items are managed that are finished goods/fulfillment items. <span title="I1070.0" @click="onSpanClick">{{ q.I1070.answer.Current | decimal(0) }}</span> minutes are spent each month managing all inventory items.</p>
      <p>Material inventory is managed by <span title="I1071.notes" @click="onSpanClick">{{ q.I1071.notes }}</span> Inventory is allocated <span title="I1079.notes" @click="onSpanClick">{{ q.I1079.notes }}</span>. POs are generated, managed and reconciled in the following way: <span title="I1072.notes" @click="onSpanClick">{{ q.I1072.notes }}</span>.</p>
      <p>The production operation <span title="I1075.0" @click="onSpanClick">{{ (q.I1075.notes === 'Yes')?"carries and manages":"does not carry or manage" }}</span> finished goods inventory. </p>
      <p>There <span title="I1074.0" @click="onSpanClick">{{ (q.I1074.notes === 'Yes')?"are":"are not" }}</span> automatic triggers to re-stock and re-order inventory items. It is managed by <span title="I1076.notes" @click="onSpanClick">{{ q.I1076.notes }}</span>. The pick and pack process is accomplished by <span title="I1077.notes" @click="onSpanClick">{{ q.I1077.notes }}</span>. Production inventory items <span title="I1078.0" @click="onSpanClick">{{ q.I1078.answer.final?"are":"are not" }}</span> verified to ensure there is enough on hand to meet deadlines. Inventory is allocated <span title="I1079.notes" @click="onSpanClick">{{ q.I1079.notes }}</span>. Pricing for inventory items is managed by <span title="I1081.notes" @click="onSpanClick">{{ q.I1081.notes }}</span>. The process for handling pricing updates is managed by <span title="I1082.notes" @click="onSpanClick">{{ q.I1082.notes }}</span>. If there are non-inventory specialty materials, they are ordered as follows: <span title="I1083.notes" @click="onSpanClick">{{ q.I1083.notes }}</span>.</p>
      <p>Challenges and opportunities for Inventory Management include <span title="I1083A.notes" @click="onSpanClick">{{ q.I1083A.notes }}</span>.</p>

      <h3>Purchasing / Accounts Payable</h3>
      <p><span title="I1084A.notes" @click="onSpanClick">{{ q.I1084A.notes }}</span> </p>
      <p>It takes <span title="I1084.0" @click="onSpanClick">{{ q.I1084.answer.Current }}</span> minutes to create a PO and <span title="I1085.0" @click="onSpanClick">{{ q.I1085.answer.Current }}</span> minutes to reconcile a PO. There are <span title="I1086.0" @click="onSpanClick">{{ q.I1086.answer.Current | decimal(0)  }}</span> POs processed per month. It is estimated that it costs an additional <span title="I1089.0" @click="onSpanClick">{{ q.I1089.answer.Current }}</span>% to purchase printed materials from external sources.</p>

      <h3>Job Tracking / Scheduling</h3>
      <p>The production scheduling process is <span title="I1090.notes" @click="onSpanClick">{{ q.I1090.notes }}</span>. There <span title="I1091.0" @click="onSpanClick">{{ q.I1091.answer.final?"is":"is not" }}</span> visibility to everyone in the department for the production schedule. The job’s status is visible to <span title="I1092.notes" @click="onSpanClick">{{ q.I1092.notes }}</span>. Job workflow is managed by <span title="I1093.notes" @click="onSpanClick">{{ q.I1093.notes }}</span>. </p>
      <p><span title="I1012.0" @click="onSpanClick">{{ q.I1012.answer.final }}</span> employees are involved in tracking jobs daily. Production meetings are scheduled for <span title="I1098.0" @click="onSpanClick">{{ q.I1098.answer.Current }}</span> minutes per day and are attended by <span title="I1099.0" @click="onSpanClick">{{ q.I1099.answer.Current | decimal(2)  }}</span> employees. Each administrative person spends <span title="I1096.0" @click="onSpanClick">{{ q.I1096.answer.Current }}</span> minutes per day tracking jobs and each management employee spends <span title="I1097.0" @click="onSpanClick">{{ q.I1097.answer.Current }}</span> minutes per day tracking jobs.</p>
      <p v-if="q.I1094.notes">Job change orders and author alterations are tracked by <span title="I1094.notes" @click="onSpanClick">{{ q.I1094.notes }}</span>.</p>

      <h3>Production - Pre-Press</h3>
      <p>The process for preflighting files and prepping for production includes <span title="I1100.notes" @click="onSpanClick">{{ q.I1100.notes }}</span>.</p>

      <h3>Variable Data/Personalization</h3>
      <p>The following types of jobs contain variable data/personalization: <span title="V1000.answer.final" @click="onSpanClick">{{ q.V1000.answer.final }}</span>. Approximately <span title="V1001.final" @click="onSpanClick">{{ q.V1001.answer.final | decimal(0)  }}</span> projects are processed per month containing variable data/personalization. Base template production of a variable data template takes about <span title="V1002.0" @click="onSpanClick">{{ q.V1002.answer.Current | decimal(0)  }}</span> minutes. It takes about <span title="V1004.0" @click="onSpanClick">{{ q.V1004.answer.Current | decimal(0)  }}</span> minutes to customize a document to incorporate variable data/personalization.</p>


      <h3>Production - Press / Copy / Bindery / Finishing</h3>
      <p class="">The volume of work produced each year includes the following:</p>
      <table>
        <tr data-height="0.25in" data-height-rule="exact">
          <td></td><td><b>Color</b></td><td><b>B/W</b></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>8.5 x 11 pages - Offset</td><td><span title="I1004.final" @click="onSpanClick">{{ q.I1004.answer.final | decimal(0) }}</span></td><td><span title="I1005.final" @click="onSpanClick">{{ q.I1005.answer.final | decimal(0) }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>8.5 x 11 pages - Digital Print</td><td><span title="I1006.final" @click="onSpanClick">{{ q.I1006.answer.final | decimal(0) }}</span></td><td><span title="I1007.final" @click="onSpanClick">{{ q.I1007.answer.final | decimal(0) }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Square Feet - Wide Format</td><td><span title="I1007A.final" @click="onSpanClick">{{ q.I1007A.answer.final | decimal(0) }}</span></td><td></td>
        </tr>

      </table>
      <p class="mt-4">Production employees receive information regarding new jobs tickets from <span title="I1101.notes" @click="onSpanClick">{{ q.I1101.notes }}</span>. The shop floor is notified of a project to plan their workload from <span title="I1102.notes" @click="onSpanClick">{{ q.I1102.notes }}</span>. Production employees’ labor is tracked by <span title="I1104B.notes" @click="onSpanClick">{{ q.I1104B.notes }}</span>.</p>
      <p>Challenges and opportunities for Production include: <span title="I1105A.notes" @click="onSpanClick">{{ q.I1105A.notes }}</span>.</p>

      <h3>Production - Kitting / Fulfillment</h3>
      <p>There are <span title="I1057.0" @click="onSpanClick">{{ q.I1057.answer.Current | decimal(0)  }}</span> jobs processed for kitting or fulfillment monthly.</p>

      <h3>Distribution - Mailing / Shipping / Delivery</h3>
      <p><span title="I1114.0" @click="onSpanClick">{{ q.I1114.answer.Current }}</span>% of jobs require UPS or FedEx tracking and it takes approximately <span title="I1113.0" @click="onSpanClick">{{ q.I1113.answer.Current }}</span> minutes each to track these jobs. <span title="I1115.0" @click="onSpanClick">{{ q.I1115.answer.Current | decimal(0)  }}</span> minutes are spent creating shipping documents for projects. Shipping costs are missed on approx. <span title="I1116.0" @click="onSpanClick">{{ q.I1116.answer.Current | decimal(0)  }}</span> jobs per month where their average cost is $<span title="I1117.0" @click="onSpanClick">{{ q.I1117.answer.Current | decimal(2)  }}</span>.</p>

      <h3>Billing / Accounts Receivable</h3>
      <p>The billing/invoicing process for projects includes <span title="I1118.notes" @click="onSpanClick">{{ q.I1118.notes }}</span>. Taxes are calculated by <span title="I1121.notes" @click="onSpanClick">{{ q.I1121.notes }}</span>. Jobs are invoiced <span title="I1119.notes" @click="onSpanClick">{{ q.I1119.notes }}</span> and the information <span title="I1120.0" @click="onSpanClick">{{ q.I1120.answer.final?"is":"is not" }}</span> entered into the parent organization’s accounting system. Payments are generally received from our customers in <span title="I1124.0" @click="onSpanClick">{{ q.I1124.answer.Current }}</span> days. The invoicing process generally takes <span title="I1125.0" @click="onSpanClick">{{ q.I1125.answer.Current }}</span> minutes per project. Receivables are managed in <span title="I1122.notes" @click="onSpanClick">{{ q.I1122.notes }}</span>.</p>

      <h3>Labor Tracking / Management Reports</h3>
      <p>Labor <span title="I1104.notes" @click="onSpanClick">{{ (q.I1104A.notes === "Yes")?('is tracked in '+q.I1104.notes+'.'):"is" }}</span> <span title="I1104A.notes" @click="onSpanClick">{{ (q.I1104A.notes === "Yes")?"Production labor is entered into the software system by":"not" }}</span> <span title="I1104B.notes" @click="onSpanClick">{{ (q.I1104A.notes === "Yes")?q.I1104B.notes:"tracked" }}</span>.</p>
      <p>Reports are generated by <span title="I1129.notes" @click="onSpanClick">{{ q.I1129.notes  }}</span>.</p>
      <p>Real time visibility for reports — <span title="I1130.notes" @click="onSpanClick">{{ q.I1130.notes  }}</span>.</p>
      <p>The profitability of jobs is evaluated by <span title="I1131.notes" @click="onSpanClick">{{ q.I1131.notes  }}</span>.</p>
      <p>Profitability of jobs <span title="I1132.notes" @click="onSpanClick">{{ (q.I1132.notes === 'Yes')?"can":"cannot" }} be segmented by job type.</span></p>
      <p>Custom reports can be created by  <span title="I1133.notes" @click="onSpanClick">{{ q.I1133.notes  }}</span>.</p>


      <br>
      <p class="font-bold">CURRENT WORKFLOW</p>
      <img class="w-full" src="/reports/current-workflow-2020.png" data-ratio="0.5326" />




    </section>
    <section>
      <h1>Findings</h1>
      <h2>Production Workflow Scorecard Summary</h2>
      <p><span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span>’s Production Workflow is rated as <span class="uppercase">{{ scorecardGrade }}</span> with a rating of {{ r.scorecard.final }} on a scale of 1 to 5.  The overall rating would rise if some of the issues discussed in this document could be rectified. </p>
      <assessment-scorecard data-svg="true" data-ratio="0.8" title="Production Workflow Scorecard" answer-key="final" :assessment="r" :questions="survey"></assessment-scorecard>

      <p class="mt-4">See Appendix 1 for a detailed Grading Scale of the Best Practices Scorecard.</p>
      <p>The current management team has made changes and improvements over time within the constraints of their budgets and resources availability. However, substantial room for improvement exists with respect to print cost tracking, profitability tracking, document design, inventory and other. </p>

      <h2>Strengths</h2>
      <p>The strengths of the operation include <span title="I1026.notes" @click="onSpanClick">{{ q.I1026.notes }}</span>.</p>


      <div data-container="true" v-for="(strength, index) in strengthsWeakness.strength" :key="'s'+index">
        <h3>{{ strength.title }}</h3>
        <p>{{ strength.criteria }} This is considered {{ strength.grade }}.</p>
        <p>Notes: {{ strength.notes }}</p>
      </div>


      <h2>Issues / Risks / Challenges</h2>
      <p> The opportunities for improvement include <span title="I1027.notes" @click="onSpanClick">{{ q.I1027.notes }}</span>.</p>

      <div data-container="true" v-for="(weakness, index) in strengthsWeakness.weakness" :key="'w'+index">
        <h3>{{ weakness.title }}</h3>
        <p>{{ weakness.criteria }} This is considered {{ weakness.grade }}.</p>
        <p v-if="weakness.title.startsWith('Change Orders')">Change orders are tracked by <span title="I1105.notes" @click="onSpanClick">{{ q.I1105.notes }}</span>.</p>
        <p>Notes: {{ weakness.notes }}</p>
      </div>



      <h2>Information Gaps</h2>
      <p>List any information that you were not able to uncover during your walkthrough. Pay special attention to data that is necessary to complete the ROI tool and ask for management’s assistance to gather that data.</p>

      <h3>Labor Costs</h3>
      <p>Unable to determine average hourly wage for shop floor employees. Necessary data for ROI/Payback Period calculations.</p>
      <h3>Change Orders</h3>
      <p>Varied information about % of monthly jobs that require change orders or alterations. Necessary data for ROI/Payback Period calculations.</p>
      <h3>Management Visibility</h3>
      <p>It is difficult to quantify the level of visibility into the daily shop operations because there is no frame of reference to compare against.</p>
    </section>

    <section>
      <h1>Recommendations</h1>
      <p>Based on our assessment of your production operation, Ricoh recommends incorporating the following software solutions to streamline <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span>'s workflow.</p>


      <h2 v-if="solutions.MIS">Proposed Solution (MIS)</h2>
      <component data-container="true" v-if="solutions.MIS" :is="solutions.MIS.component"></component>

      <h3 v-if="solutions.MIS">Benefits</h3>
      <p v-if="solutions.MIS">The proposed solution will provide a streamlined workflow whereby both administrative and production staff will benefit. Info on streamlined workflow. Build workflow in findings report powerpoint and then copy here.</p>

      <h3 v-if="solutions.MIS">Investment</h3>
      <p v-if="solutions.MIS">The investment for the proposed solution is estimated* at:</p>
      <ul v-if="solutions.MIS">
        <li>{{ solutions.MIS.name }} – Initial Investment: ${{ r.costs.mis.totals.initial | decimal(0) }}; Yearly Maintenance: ${{ r.costs.mis.maintenance.year1 | decimal(0) }}</li>
      </ul>
      <p v-if="solutions.MIS">*Estimate is based on standard configuration. A formal proposal will be provided at your request.</p>


      <h2 v-if="solutions.Storefront">Proposed Solution (Online Storefront)</h2>
      <component  data-container="true" v-if="solutions.Storefront"  :is="solutions.Storefront.component"></component>

      <h3 v-if="solutions.Storefront">Benefits</h3>
      <p v-if="solutions.Storefront">The proposed solution will provide a streamlined workflow whereby both administrative and production staff will benefit. Info on streamlined workflow. Build workflow in findings report powerpoint and then copy here.</p>

      <h3 v-if="solutions.Storefront">Investment</h3>
      <p v-if="solutions.Storefront">The investment for the proposed solution is estimated* at:</p>
      <ul v-if="solutions.Storefront">
        <li>{{ solutions.Storefront.name }} – Initial Investment: ${{ r.costs.storefront.totals.initial | decimal(0) }}; Yearly Maintenance: ${{ r.costs.storefront.maintenance.year1 | decimal(0) }}</li>
      </ul>
      <p v-if="solutions.Storefront">*Estimate is based on standard configuration. A formal proposal will be provided at your request.</p>

      <h2 v-if="solutions.FilePrep.length">Proposed Solution (File Prep/VDP)</h2>
      <component  data-container="true" :key="solution.name" v-for="(solution) in solutions.FilePrep"  :is="solution.component"></component>

      <h3 v-if="solutions.FilePrep.length">Benefits</h3>
      <p v-if="solutions.FilePrep.length">The proposed solution will provide a streamlined workflow whereby both administrative and production staff will benefit. Info on streamlined workflow. Build workflow in findings report powerpoint and then copy here.</p>

      <h3 v-if="solutions.FilePrep.length">Investment</h3>
      <p v-if="solutions.FilePrep.length">The investment for the proposed solution is estimated* at:</p>
      <ul v-if="solutions.FilePrep.length">
        <li>{{ solutions.FilePrep.map((solution)=>{return solution.name}).join(", ") }} – Initial Investment: ${{ r.costs.vdp.totals.initial | decimal(0) }}; Yearly Maintenance: ${{ r.costs.vdp.maintenance.year1 | decimal(0) }}</li>
      </ul>
      <p v-if="solutions.FilePrep.length">*Estimate is based on standard configuration. A formal proposal will be provided at your request.</p>



      <h2 class="font-bold">Future Workflow</h2>
      <img class="w-full mb-8" src="/reports/future-workflow-2020.png" data-ratio="0.5326" />

      <h2>Savings Detail/Revenue Summary</h2>
      <h3 v-if="solutions.MIS || solutions.Storefront">Print MIS and Digital Storefront Cost Savings and Additional Revenue Summary</h3>
      <p v-if="solutions.MIS || solutions.Storefront">Details for the targeted cost savings and potential increased revenue include:</p>
      <savings-summary-table v-if="solutions.MIS || solutions.Storefront" :assessment="r" :hideVDP="true"></savings-summary-table>
      <p class="mt-8"></p>
      <h3 v-if="solutions.FilePrep.length">File Prep/VDP Savings</h3>
      <p v-if="solutions.FilePrep.length">Details for the targeted cost savings and potential increased revenue include:</p>
      <roi-table-vdp-details v-if="solutions.FilePrep.length" :assessment="r"></roi-table-vdp-details>



      <h2>ROI</h2>
      <h3 v-if="solutions.MIS">MIS</h3>
      <p v-if="solutions.MIS">The proposed solution provides financial payback in {{ r.costs.mis.paybackPeriod | decimal(0) }} months which includes {{ r.savings.hoursMISSavingsAnnual | decimal(0) }} man hour savings valued at ${{ r.savings.costMISSavingsAnnual | decimal(0) }} and increased revenue potential of ${{ r.savings.revenue.total | decimal(0) }} per year.</p>
      <roi-table-mis v-if="solutions.MIS" :assessment="r"></roi-table-mis>
      <p></p>
      <roi-chart-mis-potential v-if="solutions.MIS" :assessment="r" class="max-w-3xl"></roi-chart-mis-potential>
      <roi-chart-mis-current-future v-if="solutions.MIS" :assessment="r" class="max-w-3xl"></roi-chart-mis-current-future>
      <p class="mt-8"></p>
      <h3 v-if="solutions.Storefront">Online Storefront</h3>
      <p v-if="solutions.Storefront">The proposed solution provides financial payback in {{ r.costs.storefront.paybackPeriod | decimal(0) }} months which includes {{ r.savings.hoursStorefrontSavingsAnnual | decimal(0) }} man hour savings valued at ${{ r.savings.costStorefrontSavingsAnnual | decimal(0) }} and increased revenue potential of ${{ r.savings.revenue.total | decimal(0) }} per year.</p>
      <roi-table-storefront v-if="solutions.Storefront" :assessment="r"></roi-table-storefront>
      <p></p>
      <roi-chart-storefront-comparison v-if="solutions.Storefront" :assessment="r" class="max-w-3xl"></roi-chart-storefront-comparison>
      <p class="mt-8"></p>
      <h3 v-if="solutions.FilePrep.length">File Prep/VDP</h3>
      <p v-if="solutions.FilePrep.length">The proposed solution provides financial payback in {{ r.costs.vdp.paybackPeriod | decimal(0) }} months which includes {{ r.costs.vdp.orderDetails.jobTicketOrder.hourSavingsAnnual | decimal(0) }} man hour savings valued at ${{ r.costs.vdp.orderDetails.jobTicketOrder.costSavingsAnnual | decimal(0) }} and reword savings of ${{ r.costs.vdp.orderDetails.rework.costSavingsAnnual | decimal(0) }} for a total savings of ${{ r.costs.vdp.orderDetails.totalSavingsAnnual | decimal(0) }} per year.</p>
      <roi-table-vdp v-if="solutions.FilePrep.length" :assessment="r"></roi-table-vdp>
      <p></p>
      <roi-chart-vdp-comparison v-if="solutions.FilePrep.length" :assessment="r" class="max-w-3xl"></roi-chart-vdp-comparison>

    </section>

    <section v-if="solutions.Other.length">
      <h1>Other Recommendations</h1>
      <component data-container="true" v-for="(solution) in solutions.Other" :key="solution.id" :is="solution.component"></component>
    </section>

    <section>
      <h1>Appendix</h1>
      <h2>Appendix 1 - Workflow Assessment Scorecard Ratings</h2>
      <table class="text-xs" data-cell-style="AppendixCell">
        <tr>
          <th></th>
          <th>5</th>
          <th>4</th>
          <th>3</th>
          <th>2</th>
          <th>1</th>
          <th>N/A</th>
        </tr>
        <tr v-for="rating in survey" :key="rating.id">
          <td>{{ rating.question }}</td>
          <td>{{  rating.criteria5 }}</td>
          <td>{{  rating.criteria4 }}</td>
          <td>{{  rating.criteria3 }}</td>
          <td>{{  rating.criteria2 }}</td>
          <td>{{  rating.criteria1 }}</td>
          <td>{{  rating.criteriaNA }}</td>
        </tr>
      </table>
    </section>
  </div>

</template>

<script>
import workflow from "@/schema/workflow";
import RoiChartVdpComparison from "@/components/ROIChartVDPComparison";
import RoiChartStorefrontComparison from "@/components/ROIChartStorefrontComparison";
import RoiChartMisCurrentFuture from "@/components/ROIChartMISCurrentFuture";
import RoiChartMisPotential from "@/components/ROIChartMISPotential";
import ProductionWorkflow from "@/components/Solutions/ProductionWorkflow";
import RicohConsultingServices from "@/components/Solutions/RicohConsultingServices";
import ColorGap from "@/components/Solutions/ColorGap";
import PrintNet from "@/components/Solutions/PrintNet";
import WebCrd from "@/components/Solutions/WebCrd";
import MarketDirect from "@/components/Solutions/MarketDirect";
import MarcomCentral from "@/components/Solutions/MarcomCentral";
import PrintShopMail from "@/components/Solutions/PrintShopMail";
import FusionPro from "@/components/Solutions/FusionPro";
import RoiTableStorefront from "@/components/ROITableStorefront";
import RoiTableMis from "@/components/ROITableMIS";
import RoiTableVdpDetails from "@/components/ROITableVDPDetails";
import RoiTableVdp from "@/components/ROITableVDP";
import EfiPace from "@/components/Solutions/EfiPace";
import AvantiSlingshot from "@/components/Solutions/AvantiSlingshot";
import PrintSmithVision from "@/components/Solutions/PrintSmithVision";
import SavingsSummaryTable from "@/components/SavingsSummaryTable";
import AssessmentScorecard from "@/components/AssessmentScorecard";
import EFIFieryJobMaster from "@/components/Solutions/EFIFieryJobMaster";
import EFIFieryJobFlow from "@/components/Solutions/EFIFieryJobFlow";
import ReadyPrint from "@/components/Solutions/ReadyPrint";
import EnfocusSwitch from "@/components/Solutions/EnfocusSwitch";
import PrintShopMailConnect from "@/components/Solutions/PrintShopMailConnect";
import MailAssessment from "@/components/Solutions/MailAssessment";

export default {
    name: "report-production-workflow",
  components: {
    MailAssessment,
    PrintShopMailConnect,
    EnfocusSwitch,
    ReadyPrint,
    EFIFieryJobFlow,
    EFIFieryJobMaster,
    RoiChartVdpComparison,
    RoiChartStorefrontComparison,
    RoiChartMisCurrentFuture,
    RoiChartMisPotential,
    ProductionWorkflow,
    RicohConsultingServices,
    ColorGap,
    PrintNet,
    WebCrd,
    MarketDirect,
    MarcomCentral,
    PrintShopMail,
    FusionPro,
    RoiTableStorefront,
    RoiTableMis,
    RoiTableVdpDetails,
    RoiTableVdp, EfiPace, AvantiSlingshot, PrintSmithVision, SavingsSummaryTable, AssessmentScorecard
  },
  mounted: function(){
    this.$emit("mounted");
  },
    props: {
      reportData: {
        required: true,
      }
    },
    data: function(){
        return {

        }
        },
    methods: {
      onSpanClick: function(e){

        let questionData;

        if(e.target.title){
          let qPath = e.target.title.split(".");
          if(qPath[0].startsWith("I") || qPath[0].startsWith("V")){
            let question = this.iQ[qPath[0]];
            let answer = this.q[qPath[0]];

            if(question){
              questionData = {
                title: "Interview Question",
                answer: answer,
                question: question
              }
              this.$emit("edit",questionData)
            }
          }
        }

      },

      onChange: function(e){

        this.$emit("change",e);

      }
    },
    computed: {
       r: function(){
         return this.reportData;
       },

      q: function(){
        return this.r.interview.questions;
      },


      strengthsWeakness: function(){
        let _listing = {
          strength: [],
          weakness: []
        }

        if(this.r){
          Object.keys(this.survey).forEach((key) => {
            let question = this.survey[key];
            let answer = this.r.survey.questions[key];

            if(answer.answer.final && answer.answer.final !=="NA"){
              let intValue = parseInt(answer.answer.final);
              if(intValue > 3){
                _listing.strength.push({
                  title: question.question,
                  criteria: question['criteria'+intValue],
                  grade: (intValue === 4)?"Very Good":"Best-in-Class",
                  notes: answer.notes
                })
              }

              if(intValue < 3){
                _listing.weakness.push({
                  title: question.question,
                  criteria: question['criteria'+intValue],
                  grade: (intValue === 2)?"Sufficient":"Substandard",
                  notes: answer.notes
                })
              }
            }

          })
        }

        return _listing;


      },


      option2: function(){
        if(this.r){
          return (this.r.exportSettings.option === "2");
        }
        return false;
      },

      solutions: function(){


        if(this.r){
          let _solutions = {
            Other: this.r.exportSettings.solutions.Other.map((otherID)=>{
              return this.solutionList[otherID]
            }),
            FilePrep: this.r.exportSettings.solutions.FilePrep.map((otherID)=>{
              return this.solutionList[otherID]
            })
          };

          if(this.r.exportSettings.solutions.MIS){
            _solutions.MIS = this.solutionList[this.r.exportSettings.solutions.MIS]
          }

          if(this.r.exportSettings.solutions.Storefront){
            _solutions.Storefront = this.solutionList[this.r.exportSettings.solutions.Storefront]
          }

          if(this.r.exportSettings.solutions.VDP){
            _solutions.VDP = this.solutionList[this.r.exportSettings.solutions.VDP]
          }

          return _solutions;

        }

        return {}

      },

      survey: function(){
        return workflow.survey;
      },

      iQ: function(){
        return workflow.questions;
      },

      currentDate: function(){
        return (new Date(Date.now())).toLocaleDateString()
      },

      scorecardGrade: function(){
        if(this.r && this.r.scorecard && this.r.scorecard.final){
          if(this.r.scorecard.final < 1.5){
            return "Sub-Standard"
          }else if(this.r.scorecard.final < 2.5){
            return "Sufficient"
          }else if(this.r.scorecard.final < 3.5){
            return "Good"
          }else if(this.r.scorecard.final < 4.5){
            return "Very Good"
          }else if(this.r.scorecard.final < 5.1){
            return "Best-in-Class"
          }


        }

        return "NA"
      },

      solutionList: function() {
         return {
            "AvantiSlingshot": {name: "Avanti Slingshot", component: "AvantiSlingshot", category: "MIS"},
            "EfiPace": {name: "EFI Pace", component: "EfiPace", category: "MIS"},
            "PrintSmithVision": {name: "PrintSmith Vision", component: "PrintSmithVision", category: "MIS"},
            "FusionPro": {name: "Fusion Pro", component: "FusionPro", category: "VDP"},
           "PrintShopMailConnect": {name: "Objectif Lune Print Shop Mail Connect", component: "PrintShopMailConnect", category: "VDP"},
            "EFIFieryJobMaster": {name: "EFI Fiery Job Master", component: "EFIFieryJobMaster", category: "VDP"},
           "EFIFieryJobFlow": {name: "EFI Fiery Job Flow", component: "EFIFieryJobFlow", category: "VDP"},
           "ReadyPrint": {name: "RSA ReadyPrint", component: "ReadyPrint", category: "VDP"},
           "EnfocusSwitch": {name: "Enfocus Switch", component: "EnfocusSwitch", category: "VDP"},

            "MarcomCentral": {name: "Marcom Central", component: "MarcomCentral", category: "Storefront"},
            "WebCrd": {name: "Web CRD", component: "WebCrd", category: "Storefront"},
            "MarketDirect": {name: "Market Direct", component: "MarketDirect", category: "Storefront"},
            "PrintNet": {name: "PrintNet", component: "PrintNet", category: "Storefront"},
            "ColorGap": {name: "Color Gap Analysis", component: "ColorGap", category: "Other"},
            "RicohConsultingServices": {
              name: "Ricoh Consulting Services",
              component: "RicohConsultingServices",
              category: "Other"
            },
            "ProductionWorkflow": {name: "Production Workflow", component: "ProductionWorkflow", category: "Other"},
           "MailAssessment": {name: "Mail Assessment", component: "MailAssessment", category: "Other"},
          }
       }
    }
}
</script>

<style scoped>


.report-production-workflow {

}

</style>