


<template>
<div class="">
  <h4>Support/Training - Best Practices</h4>
  <hr>
  <p>Cross-training your employees is essential to mitigating risk when staff is sick or on vacation. It will help develop your team’s capabilities and improve capacity, giving your operation the flexibility to respond to fluctuating workflows.</p>
  <p>Recommendations are to create and implement a cross-training program. Best practices are to track employees training with a cross-training matrix. This would indicate each function an employee is cross trained on, listing the employee's skill level for each of the functions and track when the employee last had refresh training.</p>

</div>
</template>

<script>
export default {
    name: "support-training",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


</style>
