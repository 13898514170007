


<template>
<div class="fusion-pro">
  <h4>RSA ReadyPrint</h4>
  <hr>
  <p>ReadyPrint™ gives you the functionality of industry-leading make ready software and basic job submission capabilities. ReadyPrint Pro adds expanded job assembly and automated prepress applications. Both options will output to the digital print devices in your print shop, regardless of the brand, integrating seamlessly with RSA’s WebCRD™ and QDirect™ solutions.</p>
  <p class="bold-label">Customer Benefits:</p>
  <ul>
    <li>Maximize your return on investment and provide operators with the ability to choose the best printer for the job with one application</li>
    <li>Streamline job submission, automatically apply file settings and send jobs directly to any printer</li>
    <li>Simplify training by using one prepress interface for your entire print shop</li>
    <li>Enhance WebCRD Web to Print with robust prepress capabilities</li>
    <li>Increase flexibility and accelerate turnaround by simplifying and automating workflows, even for complex multiple file jobs</li>
  </ul>
  <p class="bold-label">ReadyPrint Features:</p>
  <ul>
    <li>Import PDF, various native file types — even email — and hardcopy scans</li>
    <li>Simplify job ticketing by previewing settings and changes while you work and saving settings as part of the file for future reprints</li>
    <li>Easily reorder, renumber, delete and insert pages and tabs on complex jobs</li>
    <li>Improve image quality by adjusting brightness, despeckle and deskew, and cropping</li>
    <li>Add watermarks, stamps, page numbers, headers and footers</li>
    <li>Quickly perform page imposition with bleed and marks.</li>
  </ul>
  <p class="bold-label">Additional Features with ReadyPrint Pro:</p>
  <ul>
    <li>Automate workflows to perform repeated document enhancement operations</li>
    <li>Assemble groups of jobs into one document from multiple files in their native formats and print as a batch of single files or as one individual job</li>
    <li>ReadyPrint Pro adds ReadyPrint Automatica for prepress automation and ReadyPrint Binder for multiple document assembly to intelligently automate the production process from file receipt through production.</li>
  </ul>
</div>
</template>

<script>
export default {
    name: "ready-print",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


.fusion-pro {

}

</style>