

export default {

    equipment: {
        proC5300: {
            id: 'proC5300',
            name: 'Pro C5300',
            ppm: 65,
            volume: 150000,
            volume2: 0,
            volumeMax: 150000,
            volumeAbbr: "150k",
            volumeSuffix: "Color pages per month",
            baseFootage: 25,
            materialFootage: 35,
            totalFootage: 60,
            excludedBy: [
                "paperWidth13plus",
                "paperLength19plus",
                'paperGSM360plus',
                'moreThan4Colors',
                'micrInk',
                "banner275d300",
                "banner275d400",
                "banner405d218",
                "banner495s360",
                "paperAdditional3Drawers",
                "bannerFeeder",
                "stackerBannerHC",
                'kyoceraStacker',
                "ringBind",
                "bookletmaking200pg",
                "perfectBind"
            ],
            addedFootage: {
                'banner275s360':21,
                'banner275d300':21,
                'banner275d400':21,
                'banner405d218':22,
                'banner495s360':22,
                'paperAdditional3Drawers':23,
                'paperAdditional2Drawers':28,
                'bannerFeeder':41,
                'inserterInterposer':32,
                'stackerBannerHC':37,
                'stacker':27,
                'kyoceraStacker':54,
                'gbcPunch':12,
                'ringBind':19,
                'stapling':23,
                'folding':12,
                'bookletmaking120pg':72,
                'bookletmaking140pg':90,
                'bookletmaking200pg':90,
                'perfectBind':28,
            }
        },
        proC5310: {
            id: 'proC5310',
            name: 'Pro C5310',
            ppm: 80,
            volume: 150000,
            volume2: 0,
            volumeMax: 150000,
            volumeAbbr: "150k",
            volumeSuffix: "Color pages per month",
            baseFootage: 25,
            materialFootage: 35,
            totalFootage: 60,
            excludedBy: [
                "paperWidth13plus",
                "paperLength19plus",
                'paperGSM360plus',
                'moreThan4Colors',
                'micrInk',
                "banner275d300",
                "banner275d400",
                "banner405d218",
                "banner495s360",
                "paperAdditional3Drawers",
                'kyoceraStacker',
                "bannerFeeder",
                "stackerBannerHC",
                "ringBind",
                "bookletmaking200pg",
                "perfectBind"
            ],
            addedFootage: {
                'banner275s360':21,
                'banner275d300':21,
                'banner275d400':21,
                'banner405d218':22,
                'banner495s360':22,
                'paperAdditional3Drawers':23,
                'paperAdditional2Drawers':28,
                'bannerFeeder':41,
                'inserterInterposer':32,
                'stackerBannerHC':37,
                'stacker':27,
                'kyoceraStacker':54,
                'gbcPunch':12,
                'ringBind':19,
                'stapling':23,
                'folding':12,
                'bookletmaking120pg':72,
                'bookletmaking140pg':90,
                'bookletmaking200pg':90,
                'perfectBind':28,
            }
        },

        proC7200: {
            id: 'proC7200',
            name: 'Pro C7200',
            ppm: 85,
            volume: 216000,
            volume2: 0,
            volumeMax: 240000,
            volumeAbbr: "240k",
            volumeSuffix: "Color pages per month",
            baseFootage: 42,
            materialFootage: 45,
            totalFootage: 87,
            excludedBy: [
                "paperWidth13plus",
                "paperLength19plus",
                'paperGSM360plus',
                "banner275d400",
                "banner405d218",
                'kyoceraStacker',
            ],
            addedFootage: {
                'banner275s360':21,
                'banner275d300':21,
                'banner275d400':21,
                'banner405d218':22,
                'banner495s360':22,
                'paperAdditional3Drawers':23,
                'paperAdditional2Drawers':28,
                'bannerFeeder':41,
                'inserterInterposer':32,
                'stackerBannerHC':37,
                'stacker':27,
                'kyoceraStacker':54,
                'gbcPunch':12,
                'ringBind':19,
                'stapling':23,
                'folding':12,
                'bookletmaking120pg':72,
                'bookletmaking140pg':90,
                'bookletmaking200pg':90,
                'perfectBind':28,
            }
        },

        proC7210: {
            id: 'proC7210',
            name: 'Pro C7210',
            ppm: 95,
            volume: 216000,
            volume2: 0,
            volumeMax: 240000,
            volumeAbbr: "240k",
            volumeSuffix: "Color pages per month",
            baseFootage: 42,
            materialFootage: 45,
            totalFootage: 87,
            excludedBy: [
                "paperWidth13plus",
                "paperLength19plus",
                'paperGSM360plus',
                "banner275d400",
                "banner405d218",
                'kyoceraStacker',
            ],
            addedFootage: {
                'banner275s360':21,
                'banner275d300':21,
                'banner275d400':21,
                'banner405d218':22,
                'banner495s360':22,
                'paperAdditional3Drawers':23,
                'paperAdditional2Drawers':28,
                'bannerFeeder':41,
                'inserterInterposer':32,
                'stackerBannerHC':37,
                'stacker':27,
                'kyoceraStacker':54,
                'gbcPunch':12,
                'ringBind':19,
                'stapling':23,
                'folding':12,
                'bookletmaking120pg':72,
                'bookletmaking140pg':90,
                'bookletmaking200pg':90,
                'perfectBind':28,
            }
        },

        proC9200: {
            id: 'proC9200',
            name: 'Pro C9200',
            ppm: 115,
            volume: 652050,
            volume2: 347950,
            volumeMax: 1000000,
            volumeAbbr: "1M",
            volumeSuffix: "Color pages per month",
            baseFootage: 48,
            materialFootage: 55,
            totalFootage: 103,
            excludedBy: [
                "paperWidth13plus",
                "paperLength19plus",
                'scanning',
                'moreThan4Colors',
                'micrInk',
                "paperAdditional3Drawers",
                'kyoceraStacker',


            ],
            addedFootage: {
                'banner275s360':21,
                'banner275d300':21,
                'banner275d400':21,
                'banner405d218':22,
                'banner495s360':22,
                'paperAdditional3Drawers':23,
                'paperAdditional2Drawers':28,
                'bannerFeeder':41,
                'inserterInterposer':32,
                'stackerBannerHC':37,
                'stacker':27,
                'kyoceraStacker':54,
                'gbcPunch':12,
                'ringBind':19,
                'stapling':23,
                'folding':12,
                'bookletmaking120pg':72,
                'bookletmaking140pg':90,
                'bookletmaking200pg':90,
                'perfectBind':28,
            }
        },

        proC9210: {
            id: 'proC9210',
            name: 'Pro C9210',
            ppm: 135,
            volume: 765450,
            volume2: 234550,
            volumeMax: 1000000,
            volumeAbbr: "1M",
            volumeSuffix: "Color pages per month",
            baseFootage: 48,
            materialFootage: 65,
            totalFootage: 113,
            excludedBy: [
                "paperWidth13plus",
                "paperLength19plus",
                'scanning',
                'moreThan4Colors',
                'micrInk',
                "paperAdditional3Drawers",
                'kyoceraStacker',


            ],
            addedFootage: {
                'banner275s360':21,
                'banner275d300':21,
                'banner275d400':21,
                'banner405d218':22,
                'banner495s360':22,
                'paperAdditional3Drawers':23,
                'paperAdditional2Drawers':28,
                'bannerFeeder':41,
                'inserterInterposer':32,
                'stackerBannerHC':37,
                'stacker':27,
                'kyoceraStacker':54,
                'gbcPunch':12,
                'ringBind':19,
                'stapling':23,
                'folding':12,
                'bookletmaking120pg':72,
                'bookletmaking140pg':90,
                'bookletmaking200pg':90,
                'perfectBind':28,
            }
        },


        kodakSX2900: {
            id: 'kodakSX2900',
            name: 'Kodak Nexpress SX2900',
            ppm: 83,
            volume: 392175,
            volume2: 294131,
            volumeMax: 686306,
            volumeAbbr: "686k",
            volumeSuffix: "Color pages per month",
            baseFootage: 250,
            materialFootage: 100,
            totalFootage: 350,
            excludedBy: [
                'paperGSM360plus',
                'scanning',
                'banner275s360',
                'banner275d300',
                'banner275d400',
                'banner405d218',
                'banner495s360',
                'paperAdditional3Drawers',
                'paperAdditional2Drawers',
                'bannerFeeder',
                'inserterInterposer',
                'stackerBannerHC',
                'stacker',
                'kyoceraStacker',
                'gbcPunch',
                'ringBind',
                'stapling',
                'folding',
                'bookletmaking120pg',
                'bookletmaking140pg',
                'bookletmaking200pg',
                'perfectBind',
            ],
            addedFootage: {
                'banner275s360':21,
                'banner275d300':21,
                'banner275d400':21,
                'banner405d218':22,
                'banner495s360':22,
                'paperAdditional3Drawers':23,
                'paperAdditional2Drawers':28,
                'bannerFeeder':41,
                'inserterInterposer':32,
                'stackerBannerHC':37,
                'stacker':27,
                'kyoceraStacker':54,
                'gbcPunch':12,
                'ringBind':19,
                'stapling':23,
                'folding':12,
                'bookletmaking120pg':72,
                'bookletmaking140pg':90,
                'bookletmaking200pg':90,
                'perfectBind':28,
            }
        },

        kodakSX3300: {
            id: 'kodakSX3300',
            name: 'Kodak Nexpress SX3300',
            ppm: 100,
            volume: 472500,
            volume2: 354375,
            volumeMax: 826875,
            volumeAbbr: "826k",
            volumeSuffix: "Color pages per month",
            baseFootage: 250,
            materialFootage: 100,
            totalFootage: 350,
            excludedBy: [
                'paperGSM360plus',
                'scanning',
                'banner275s360',
                'banner275d300',
                'banner275d400',
                'banner405d218',
                'banner495s360',
                'paperAdditional3Drawers',
                'paperAdditional2Drawers',
                'bannerFeeder',
                'inserterInterposer',
                'stackerBannerHC',
                'stacker',
                'kyoceraStacker',
                'gbcPunch',
                'ringBind',
                'stapling',
                'folding',
                'bookletmaking120pg',
                'bookletmaking140pg',
                'bookletmaking200pg',
                'perfectBind',
            ],
            addedFootage: {
                'banner275s360':21,
                'banner275d300':21,
                'banner275d400':21,
                'banner405d218':22,
                'banner495s360':22,
                'paperAdditional3Drawers':23,
                'paperAdditional2Drawers':28,
                'bannerFeeder':41,
                'inserterInterposer':32,
                'stackerBannerHC':37,
                'stacker':27,
                'kyoceraStacker': 54,
                'gbcPunch':12,
                'ringBind':19,
                'stapling':23,
                'folding':12,
                'bookletmaking120pg':72,
                'bookletmaking140pg':90,
                'bookletmaking200pg':90,
                'perfectBind':28,
            }
        },

        kodakSX3900: {
            id: 'kodakSX3900',
            name: 'Kodak Nexpress SX3900',
            ppm: 120,
            volume: 567000,
            volume2: 425250,
            volumeMax: 992250,
            volumeAbbr: "1M",
            volumeSuffix: "Color pages per month",
            baseFootage: 250,
            materialFootage: 100,
            totalFootage: 350,
            excludedBy: [

                'paperGSM360plus',
                'scanning',
                'banner275s360',
                'banner275d300',
                'banner275d400',
                'banner405d218',
                'banner495s360',
                'paperAdditional3Drawers',
                'paperAdditional2Drawers',
                'bannerFeeder',
                'inserterInterposer',
                'stackerBannerHC',
                'stacker',
                'kyoceraStacker',
                'gbcPunch',
                'ringBind',
                'stapling',
                'folding',
                'bookletmaking120pg',
                'bookletmaking140pg',
                'bookletmaking200pg',
                'perfectBind',
            ],
            addedFootage: {
                'banner275s360':21,
                'banner275d300':21,
                'banner275d400':21,
                'banner405d218':22,
                'banner495s360':22,
                'paperAdditional3Drawers':23,
                'paperAdditional2Drawers':28,
                'bannerFeeder':41,
                'inserterInterposer':32,
                'stackerBannerHC':37,
                'stacker':27,
                'kyoceraStacker': 54,
                'gbcPunch':12,
                'ringBind':19,
                'stapling':23,
                'folding':12,
                'bookletmaking120pg':72,
                'bookletmaking140pg':90,
                'bookletmaking200pg':90,
                'perfectBind':28,
            }
        },
        TASKalfa15000: {
            id: 'TASKalfa15000',
            name: 'TASKalfa15000',
            ppm: 146,
            volume: 827820,
            volume2: 172180,
            volumeMax: 1000000,
            volumeAbbr: "1M",
            volumeSuffix: "Color pages per month",
            baseFootage: 96,
            materialFootage: 100,
            totalFootage: 196,
            excludedBy: [
                "paperWidth13plus",
                "paperLength19plus",
                'paperGSM360plus',
                'moreThan4Colors',
                'micrInk',
                'banner275s360',
                'banner275d300',
                'banner275d400',
                'banner405d218',
                'banner495s360',

                'paperAdditional3Drawers',

                'bannerFeeder',
                'inserterInterposer',
                'stackerBannerHC',
                'stacker',

                'ringBind',

                'folding',
                'bookletmaking120pg',
                'bookletmaking140pg',
                'bookletmaking200pg',
                'perfectBind',
            ],
            addedFootage: {
                'banner275s360':21,
                'banner275d300':21,
                'banner275d400':21,
                'banner405d218':22,
                'banner495s360':22,
                'paperAdditional3Drawers':23,
                'paperAdditional2Drawers':28,
                'bannerFeeder':41,
                'inserterInterposer':32,
                'stackerBannerHC':37,
                'stacker':27,
                'kyoceraStacker': 54,
                'gbcPunch':12,
                'ringBind':19,
                'stapling':23,
                'folding':12,
                'bookletmaking120pg':72,
                'bookletmaking140pg':90,
                'bookletmaking200pg':90,
                'perfectBind':28,
            }
        },
    },

    siteFunctionDetails: [
        {
            id: 'volumePerMonth',
            type: 'number',
            required: true,
            component: 'PulloutQuestionNumber',
            label: 'Volume Monthly',
            default: 0,
            validationInfo: {
                isValid: (value) => { return (value < 8000000) },
                warningMsg: "With this high volume of color pages per month you may consider an Inkjet machine.",
                overrideValue: undefined
            },
            additionalInfo: (context) => {
                if(context.interview.questions.X118){
                    if(context.interview.questions.X118.answer.final){
                        let yearlyValue = parseInt(context.interview.questions.X118.answer.final);
                        if(yearlyValue){
                            let monthlyValue = parseInt(yearlyValue / 12);
                            return `Site Survey indicated ${ monthlyValue.toLocaleString() } color pages per month ( ${ yearlyValue.toLocaleString() } yearly )`
                        }

                    }
                }

                return ''
            },
            description: "Color pages per month"
        },
        {
            id: 'jobsPerMonth',
            type: 'number',
            required: true,
            component: 'PulloutQuestionNumber',
            label: 'Jobs Per Month',
            default: 0,
            validationInfo: {
                isValid: (value) => { return (value < 501) },
                warningMsg: "If total jobs per month exceeds 500, an MIS system is recommended.",
                overrideValue: undefined
            },
            description: "Color jobs per month"
        },
        {
            id: 'paperSize',
            type: 'map',
            required: true,
            component: 'PulloutQuestionMultiChoice',
            label: 'Paper Size',
            default: {},
            options: [
                {
                    id: "paperWidth13plus",
                    label: 'Paper Width Size >13"',
                    default: false
                },
                {
                    id: "paperLength19plus",
                    label: 'Paper Length Size >19"',
                    default: false

                }
            ]
        },
        {
            id: 'paperGSM360plus',
            type: 'boolean',
            required: true,
            component: 'PulloutQuestionSwitch',
            label: 'Paper GSM > 360 GSM',
            default: false
        },
        {
            id: 'scanning',
            type: 'boolean',
            required: true,
            component: 'PulloutQuestionSwitch',
            label: 'Scanning',
            default: false
        },

        {
            id: 'moreThan4Colors',
            type: 'boolean',
            required: true,
            component: 'PulloutQuestionSwitch',
            label: '> 4 colors',
            default: false
        },
        {
            id: 'micrInk',
            type: 'boolean',
            required: true,
            component: 'PulloutQuestionSwitch',
            label: 'MICR Ink',
            default: false
        },
        {
            id: 'bannerPrinting',
            type: 'map',
            required: true,
            component: 'PulloutQuestionMultiSwitch',
            label: 'Banner Printing',
            default: {},
            options: [
                {
                    id: "banner275s360",
                    label: "Banner Simplex 27.5\" 360GSM",
                    default: false
                },
                {
                    id: "banner275d300",
                    label: "Banner Auto-Duplex 27.5\" 300GSM",
                    default: false
                },
                {
                    id: "banner275d400",
                    label: "Banner Auto-Duplex 27.5\" 400GSM",
                    default: false
                },
                {
                    id: "banner405d218",
                    label: "Banner Auto-Duplex 40.5\" 218GSM",
                    default: false
                },
                {
                    id: "banner495s360",
                    label: "Banner Simplex 49.5\" 360GSM",
                    default: false
                }
            ]
        },

        {
            id: 'inserterInterposer',
            type: 'boolean',
            required: true,
            component: 'PulloutQuestionSwitch',
            label: 'Inserter Interposer 2 Drawers (cvr & body)',
            default: false
        },
        {
            id: 'inlineFinishing',
            type: 'map',
            required: true,
            component: 'PulloutQuestionMultiChoice',
            label: 'In-Line Finishing',
            default: {},
            options: [
                {
                    id: "stacker",
                    label: "High Capacity Stacker",
                    default: false
                },
                {
                    id: "stackerBannerHC",
                    label: "Banner HC Stacker",
                    default: false
                },
                {
                    id: "kyoceraStacker",
                    label: "Kyocera High Capacity Stacker",
                    default: false
                },
                {
                    id: "stapling",
                    label: "Stapling",
                    default: false
                },
                {
                    id: "folding",
                    label: "Folding",
                    default: false
                },
                {
                    id: "gbcPunch",
                    label: "GBC Punching",
                    default: false
                },
                {
                    id: "ringBind",
                    label: "Ring Bind",
                    default: false

                },
                {
                    id: "perfectBind",
                    label: "Perfect Bind Small Volume (w/cvr inserter)",
                    default: false,
                    excludes: ["bookletmaking120pg","bookletmaking140pg","bookletmaking200pg"]
                }


            ]
        },
        {
            id: 'bookletMaking',
            type: 'map',
            component: 'PulloutQuestionMultiSwitch',
            required: true,
            label: 'Bookletmaking',
            default: {},
            options: [
                {
                    id: "bookletmaking120pg",
                    label: "Bookletmaking 120pg (w/face trim, square spine)",
                    default: false,
                    excludes: ["perfectBind","bookletmaking140pg","bookletmaking200pg"]

                },
                {
                    id: "bookletmaking140pg",
                    label: "Bookletmaking 140pg (w/face trim, square spine)",
                    default: false,
                    excludes: ["perfectBind","bookletmaking120pg","bookletmaking200pg"]

                }, {
                    id: "bookletmaking200pg",
                    label: "Bookletmaking 200pg (w/face trim, square spine)",
                    default: false,
                    excludes: ["perfectBind","bookletmaking120pg","bookletmaking140pg"]

                }

            ]
        },
        {
            id: 'paperAdditional',
            type: 'map',
            component: 'PulloutQuestionMultiSwitch',
            required: true,
            label: 'Paper Additional',
            default: {},
            options: [
                {
                    id: "paperAdditional2Drawers",
                    label: "Paper Feed HC Vacuum 2 Drawers",
                    default: false

                },
                {
                    id: "paperAdditional3Drawers",
                    label: "Paper Feed Friction 3 Drawers",
                    default: false

                },
                {
                    id: "bannerFeeder",
                    label: "Banner Paper HC Feeder",
                    default: false

                },

            ]
        },

    ]
}

