


<template>
<div class="ops-checklist">

    <h4>Operations Checklist for a Print/Mail Operation</h4>
    <hr>
    <p class="bold-label">Administration:</p>
    <ul>
      <li>Sales & Marketing Plan</li>
      <li>Rates</li>
      <li>Reports</li>
    </ul>
    <p class="bold-label">Facility:</p>
    <ul>
      <li>Location</li>
      <li>Floor Plan (layout, electrical, data drops, HVAC)</li>
      <li>Resource List</li>
    </ul>
    <p class="bold-label">Software & Equipment:</p>
    <ul>
      <li>Software Listing</li>
      <li>Equipment Listing</li>
      <li>Raw Materials</li>
    </ul>
    <p class="bold-label">Personnel</p>

</div>
</template>

<script>
export default {
    name: "ops-checklist",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


.avanti-slingshot {

}

</style>
