<template>
    <div v-bind:class="classObject">
        <span class="rating-mark" v-on:click="setValue(0)">NA</span>
        <span class="rating-mark" v-on:click="setValue(1)">L</span>
        <span class="rating-mark" v-on:click="setValue(2)">M</span>
        <span class="rating-mark" v-on:click="setValue(3)">H</span>
        <span class="rating-mark" v-on:click="toggleDownArrow">+</span>
    </div>
</template>

<script>
    export default {
        name: "form-rating-bar",

        data: function(){
            return {
                downArrow: false
            }
        },
        props: {


            level: {
                type: Number,
                required: false,
                default: 0
            }
        },

        computed: {
            classObject: function(){
                return {
                    "form-rating-bar": true,
                    "level-na": (this.level == 0),
                    "level-low": (this.level == 1),
                    "level-medium": (this.level == 2),
                    "level-high": (this.level == 3),
                    "checked": this.downArrow
                }
            }
        },

        methods: {
            toggleDownArrow: function(){

                this.$emit('expand',!this.downArrow);
                this.downArrow = !this.downArrow;
            },

            setValue: function(value){

                this.$emit('change', value)
            }
        },
    }
</script>

<style scoped>


    .form-rating-bar {
        display: flex;
        justify-content: right;
        align-items: center;
    }

    .rating-mark {
        border: 1px solid var(--mid-gray);
        background: white;
        outline: none;
        padding: 0.5em 0.7em;
        color: var(--mid-gray);
        border-left: none;
        user-select: none;
    }




    .rating-mark:first-child {
        border-top-left-radius: 1em;
        border-bottom-left-radius: 1em;
        border-left: 1px solid var(--mid-gray);
    }

    .rating-mark:last-child {
        border-top-right-radius: 1em;
        border-bottom-right-radius: 1em;
    }

    .level-na .rating-mark:nth-child(1) {
        color: white;
        background-color: var(--primary-color);
    }

    .level-low .rating-mark:nth-child(2) {
        color: white;
        background-color: var(--primary-color);
    }

    .level-medium .rating-mark:nth-child(3) {
        color: white;
        background-color: var(--primary-color);
    }

    .level-high .rating-mark:nth-child(4) {
        color: white;
        background-color: var(--primary-color);
    }

</style>
