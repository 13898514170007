


<template>
<div class="pre-customer-profile">
  <div class="mb-8">
    <p class="py-4 px-6 bg-gray-100 border rounded font-bold">Enter the organization and customer contact information or click Questionnaire to review analysis.</p>
  </div>
  <div class="">
    <div class="pb-4">
      <label>Organization Name</label>
      <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="customerProfile.organizationName" @change="onProfileChange" />

    </div>
    <div class="pb-4 flex">
      <div class="w-1/2 pr-4">
        <label>ORC Continuous Feed Cust #</label>
        <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="customerProfile.organizationCode" @change="onProfileChange" />
      </div>
      <div class="w-1/2 pl-4">
        <label>ORC Cut Sheet Cust #</label>
        <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="customerProfile.organizationCode2" @change="onProfileChange" />
      </div>
    </div>
    <div class="pb-4">
      <label>Customer Contact Name</label>
      <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="customerProfile.customerContact" @change="onProfileChange"/>
    </div>
    <div class="pb-4 flex">
      <div class="pr-4 w-1/2">
        <label>Phone</label>
        <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="customerProfile.customerPhone" @change="onProfileChange"/>
      </div>
      <div class="pl-4 w-1/2">
        <label>Cell</label>
        <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="customerProfile.customerCell" @change="onProfileChange"/>
      </div>

    </div>
    <div class="pb-4">
      <label>Street Address</label>
      <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="customerProfile.address" @change="onProfileChange"/>
    </div>
    <div class="pb-4 flex">
      <div class="pr-4 w-1/2">
        <label>City</label>
        <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="customerProfile.city" @change="onProfileChange"/>
      </div>
      <div class="pl-4 w-1/2">
        <label>State / Province</label>
        <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="customerProfile.state" @change="onProfileChange"/>
      </div>

    </div>

    <div class="pb-4 flex">
      <div class="pr-4 w-1/2">
        <label>Postal Code</label>
        <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="customerProfile.postalcode" @change="onProfileChange"/>
      </div>
      <div class="pl-4 w-1/2">
        <label>Country</label>
        <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="customerProfile.country" @change="onProfileChange"/>
      </div>

    </div>

    <div class="pb-4">
      <label>IT Contact</label>
      <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="customerProfile.itContact" @change="onProfileChange"/>
    </div>
    <div class="pb-4 flex">
      <div class="pr-4 w-1/2">
        <label>IT Phone</label>
        <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="customerProfile.itPhone" @change="onProfileChange"/>
      </div>
      <div class="pl-4 w-1/2">
        <label>IT Email</label>
        <input type="email" class="block w-full border p-2 mt-1 rounded" v-model="customerProfile.itEmail" @change="onProfileChange"/>
      </div>

    </div>

    <div class="pb-4">
      <label>SE Name</label>
      <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="customerProfile.seName" @change="onProfileChange"/>
    </div>
    <div class="pb-4">
      <label>PPS</label>
      <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="customerProfile.pps" @change="onProfileChange"/>
    </div>


    <div class="pt-4 mt-4 border-t">
      <div class="pb-4 flex justify-between">
        <div class="">
          <button class="button button-blue button-next" @click="onBegin">Questionnaire <img class="inline-block ml-2 h-3" src="../../assets/icons/next-arrow-icon.svg" /></button>
        </div>

      </div>
    </div>

  </div>
</div>
</template>

<script>

//import OrganizationInput from "@/components/OrganizationInput";
export default {
    name: "needs-customer-profile",
  components: {},

  mounted: function() {

  },
  props: {
    analysis: {
      type: Object,
      required: true
    }
  },
    data: function(){
        return {

        }
    },
    methods: {


      onProfileChange: function(){
        this.$emit("change");
      },

      onBegin: function(){
        this.$emit('link', { id: 'questionnaire'});
      }
    },
    computed: {

      customerProfile: function(){
        if(this.analysis){
          return this.analysis.customerProfile;
        }

        return null;
      },

    }
}
</script>

<style scoped>


.pre-customer-profile {

}

</style>
