


<template>
<div class="web-crd">
  <h4>RSA WebCRD™</h4>
  <hr>
  <p>WebCRD™ offers the only turnkey web-to-print automated workflow from user submission through print production. End users submit, manage and reorder ad hoc, catalog, and personalize variable data digital print jobs from WebCRD™. Leverage the industry’s only production workflow to release jobs to any production printer without re-ticketing, reducing costs and building volume.</p>
  <ul>
    <li>WebCRD™ is more than a storefront — it’s the industry’s only turnkey web-to-print workflow solution.</li>
    <li>The fastest job submission on the planet! Creating new orders is fast and easy with single-screen ticketing, interactive quoting, graphical finishing option icons, and presets for popular jobs.</li>
    <li>Keeps print centers running smoothly and profitably with the industry’s only built - in production workflow, eliminating the need to re-ticket jobs and print directly on all production printers.</li>
    <li>RSA’s Customer Success Assurance is RSA’s approach to ensure an on-time implementation with the industry’s only 100% satisfaction guarantee.</li>
  </ul>
  <p class="bold-label">Key Features:</p>
  <ul>
    <li>SurePDF Adobe® print driver with single-screen job ticketing, interactive quoting, and on-screen document preview showing all finishing options prior to ordering.</li>
    <li>Simple, do-it-yourself variable print ordering for end users leverages FusionPro®, the most commonly used VDP creation software in the industry.</li>
    <li>Production workflow eliminates re-ticketing and operator "touches" with ticketing direct to all production printers.</li>
    <li>RSA's implementation process, featuring its online WebCRD™ QuickStart Wizard and a 100% satisfaction guarantee.</li>
  </ul>
  <p class="bold-label">Benefits:</p>
  <ul>
    <li>Improve customer satisfaction by eliminating mistakes before the print-ready file ever reaches print production.</li>
    <li>Expand services and capture more print: offer advanced VDP options with WebCRD Dynamics™.</li>
    <li>Improve productivity and job turnaround, automating the production process. Do more with less, lower costs, and increase print shop volume without adding staff.</li>
    <li>Implement on time with RSA’s Customer Success Assurance, including training and after-sale support.</li>
  </ul>

</div>
</template>

<script>
export default {
    name: "web-crd",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


.web-crd {

}

</style>