<template>
  <div class="bg-white border-l bg-gray-50 overflow-y-scroll">

      <div class="flex justify-between items-center px-8 py-4 border-b bg-white sticky top-0">
        <h2 class="text-xl leading-none">Solution Info</h2>
        <div class="w-8 h-8 p-2 border-gray-300 border-2 rounded-full flex justify-center items-center mt-1 ml-3 opacity-50 hover:opacity-100 cursor-pointer" @click="close">
          <img  class="h-3 inline-block " src="../../assets/icons/close-icon.svg" />
        </div>
      </div>
      <div class="p-8">
          <component :is="solution" class="report-typography"></component>
      </div>





  </div>
</template>

<script>




export default {
  name: "side-panel-config-boilerplate",
  components: {
    AvantiSlingshot: () => import('../Solutions/AvantiSlingshot.vue'),
    MarketDirect: () => import('../Solutions/MarketDirect.vue'),
    MarcomCentral: () => import('../Solutions/MarcomCentral.vue'),
    WebCrd: () => import('../Solutions/WebCrd.vue'),
    EfiPace: () => import('../Solutions/EfiPace.vue'),
    PrintSmithVision: () => import('../Solutions/PrintSmithVision.vue'),
    EfiQuickPrint: () => import('../Solutions/EfiQuickPrint.vue'),
    FusionPro: () => import('../Solutions/FusionPro.vue'),
    ProductionWorkflowAssessment: () => import('../Solutions/ProductionWorkflowAssessment.vue'),
    EFICrossMedia: () => import('../Solutions/EFICrossMedia.vue'),
    EFICrossMediaCampaigns: () => import('../Solutions/EFICrossMediaCampaigns.vue'),
    ProductionWorkflow: () => import('../Solutions/ProductionWorkflow.vue'),
    RsaQdirect: () => import('../Solutions/RsaQdirect.vue'),

    CcmAssessment: () => import('../Solutions/CcmAssessment.vue'),
    CampaignServices: () => import('../Solutions/CampaignServices.vue'),
    PlanetPressConnect: () => import('../Solutions/PlanetPressConnect.vue'),
    PresConnect: () => import('../Solutions/PresConnect.vue'),
    PrintShopMailConnect: () => import('../Solutions/PrintShopMailConnect.vue'),
    QuadientImpress: () => import('../Solutions/QuadientImpress.vue'),
    QuadientInspire: () => import('../Solutions/QuadientInspire.vue'),
    UluroSolutions: () => import('../Solutions/UluroSolutions.vue'),
    CCMDesign: () => import('../Solutions/CCMDesign.vue'),
    PrintShopMail: () => import('../Solutions/PrintShopMail.vue'),

    FacilityBestPractices: () => import('../Solutions/FacilityBestPractices.vue'),
    PurchasingBestPractices: () => import('../Solutions/PurchasingBestPractices.vue'),
    InventoryBestPractices: () => import('../Solutions/InventoryBestPractices.vue'),
    ContentManagement: () => import('../Solutions/ContentManagement.vue'),

    MailOptimization: () => import('../Solutions/MailOptimization.vue'),
    BccMailManager: () => import('../Solutions/BccMailManager.vue'),
    QuadientBulkMailer: () => import('../Solutions/QuadientBulkMailer.vue'),
    QuadientAims: () => import('../Solutions/QuadientAims.vue'),
    ProcessDirector: () => import('../Solutions/ProcessDirector.vue'),
    ElectronicBillPayment: () => import('../Solutions/ElectronicBillPayment.vue'),
    StandardOperatingProcedures: () => import('../Solutions/StandardOperatingProcedures.vue'),
    SupportTraining: () => import('../Solutions/SupportTraining.vue'),
    OpsChecklist: () => import('../Solutions/OpsChecklist.vue'),
    RicohConsultingServices: () => import('../Solutions/RicohConsultingServices.vue'),
    SecurityConformance: () => import('../Solutions/SecurityConformance.vue'),
    DisasterRecovery: () => import('../Solutions/DisasterRecovery.vue'),

  },
  mounted: function() {


  },
  props: {
    solution: {
      type: String,
      required: true
    },



  },
  data: function () {
    return {

      processing: false,

    }
  },
  methods: {

    close: function(){
      this.$emit('hide');
    },


  },
  computed: {

  }
}
</script>

<style scoped>


.side-panel-new-assessment {

}

</style>
