


<template>
<div class="summary-export max-w-3xl">
  <div class="mb-8">
    <p class="py-4 px-6 bg-gray-100 border rounded font-bold" v-if="options.instructions">{{ options.instructions }}</p>
  </div>






  <div class="pb-4" v-if="doc">
    <div>
      <h2 class="font-bold text-xl pb-2 border-b-2">Export Options</h2>
    </div>

    <div v-if="exportSettings">
      <div class="flex flex-wrap">

        <div class="w-1/2">

          <h3 class="text-lg font-bold mt-6 mb-2">Workflow</h3>
          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="ordering1" class="flex-shrink-0 w-6 h-6" value="MarketDirect" v-model="doc.exportSettings.solutions.workflow"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('MarketDirect')">EFI MarketDirect Storefront</h2>
          </div>
          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="ordering2" class="flex-shrink-0 w-6 h-6" value="MarcomCentral" v-model="doc.exportSettings.solutions.workflow"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('MarcomCentral')">Marcom Central</h2>
          </div>
          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="ordering3" class="flex-shrink-0 w-6 h-6" value="WebCrd" v-model="doc.exportSettings.solutions.workflow"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('WebCrd')">RSA Web CRD</h2>
          </div>


          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="mis1" class="flex-shrink-0 w-6 h-6" value="AvantiSlingshot" v-model="doc.exportSettings.solutions.workflow"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('AvantiSlingshot')">Avanti Slingshot</h2>
          </div>
          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="mis2" class="flex-shrink-0 w-6 h-6" value="EfiPace" v-model="doc.exportSettings.solutions.workflow"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('EfiPace')">EFI Pace</h2>
          </div>
          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="mis3" class="flex-shrink-0 w-6 h-6" value="PrintSmithVision" v-model="doc.exportSettings.solutions.workflow"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('PrintSmithVision')">EFI PrintSmith Vision</h2>
          </div>
          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="mis4" class="flex-shrink-0 w-6 h-6" value="EfiQuickPrint" v-model="doc.exportSettings.solutions.workflow"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('EfiQuickPrint')">EFI QuickPrint Suite</h2>
          </div>


          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="workflow1" class="flex-shrink-0 w-6 h-6" value="FusionPro" v-model="doc.exportSettings.solutions.workflow"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('FusionPro')">Fusion Pro VDP Creator</h2>
          </div>
          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="workflow2" class="flex-shrink-0 w-6 h-6" value="ProductionWorkflowAssessment" v-model="doc.exportSettings.solutions.workflow"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('ProductionWorkflowAssessment')">Workflow Assessments</h2>
          </div>
          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="workflow3" class="flex-shrink-0 w-6 h-6" value="EFICrossMedia" v-model="doc.exportSettings.solutions.workflow"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('EFICrossMedia')">MarketDirect Cross Media</h2>
          </div>
          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="workflow4" class="flex-shrink-0 w-6 h-6" value="EFICrossMediaCampaigns" v-model="doc.exportSettings.solutions.workflow"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('EFICrossMediaCampaigns')">MarketDirect Cross Media Campaigns</h2>
          </div>

          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="workflow5" class="flex-shrink-0 w-6 h-6" value="ProductionWorkflow" v-model="doc.exportSettings.solutions.workflow"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('ProductionWorkflow')">Production Workflow Automation</h2>
          </div>

          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="workflow6" class="flex-shrink-0 w-6 h-6" value="RsaQdirect" v-model="doc.exportSettings.solutions.workflow"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('RsaQdirect')">RSA QDirect</h2>
          </div>

        </div>

        <div class="w-1/2">

          <h3 class="text-lg font-bold mt-6 mb-2">Composition</h3>
          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="composition1" class="flex-shrink-0 w-6 h-6" value="CcmAssessment" v-model="doc.exportSettings.solutions.composition"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('CcmAssessment')">CCM Assessment</h2>
          </div>
          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="composition2" class="flex-shrink-0 w-6 h-6" value="CampaignServices" v-model="doc.exportSettings.solutions.composition"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('CampaignServices')">CCM Campaign Services</h2>
          </div>
          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="composition3" class="flex-shrink-0 w-6 h-6" value="PlanetPressConnect" v-model="doc.exportSettings.solutions.composition"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('PlanetPressConnect')">PlanetPress Connect</h2>
          </div>
          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="composition4" class="flex-shrink-0 w-6 h-6" value="PresConnect" v-model="doc.exportSettings.solutions.composition"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('PresConnect')">PReS Connect</h2>
          </div>

          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="composition5" class="flex-shrink-0 w-6 h-6" value="PrintShopMailConnect" v-model="doc.exportSettings.solutions.composition"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('PrintShopMailConnect')">PrintShop Mail Connect</h2>
          </div>

          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="composition6" class="flex-shrink-0 w-6 h-6" value="QuadientImpress" v-model="doc.exportSettings.solutions.composition"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('QuadientImpress')">Quadient Impress</h2>
          </div>

          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="composition7" class="flex-shrink-0 w-6 h-6" value="QuadientInspire" v-model="doc.exportSettings.solutions.composition"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('QuadientInspire')">Quadient Inspire</h2>
          </div>

          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="composition8" class="flex-shrink-0 w-6 h-6" value="UluroSolutions" v-model="doc.exportSettings.solutions.composition"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('UluroSolutions')">Uluro Solutions</h2>
          </div>
          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="composition9" class="flex-shrink-0 w-6 h-6" value="CCMDesign" v-model="doc.exportSettings.solutions.composition"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('CCMDesign')">CCM Design as a Service</h2>
          </div>
          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="composition10" class="flex-shrink-0 w-6 h-6" value="PrintShopMail" v-model="doc.exportSettings.solutions.composition"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('PrintShopMail')">PrintShop Mail</h2>
          </div>

        </div>

        <div class="w-1/2">

          <h3 class="text-lg font-bold mt-6 mb-2">Management</h3>
          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="practices1" class="flex-shrink-0 w-6 h-6" value="FacilityBestPractices" v-model="doc.exportSettings.solutions.admin"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('FacilityBestPractices')">Facilities Best Practices</h2>
          </div>
          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="practices2" class="flex-shrink-0 w-6 h-6" value="PurchasingBestPractices" v-model="doc.exportSettings.solutions.admin"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('PurchasingBestPractices')">Purchasing Best Practices</h2>
          </div>
          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="practices3" class="flex-shrink-0 w-6 h-6" value="InventoryBestPractices" v-model="doc.exportSettings.solutions.admin"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('InventoryBestPractices')">Inventory Best Practices</h2>
          </div>
          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="practices4" class="flex-shrink-0 w-6 h-6" value="ContentManagement" v-model="doc.exportSettings.solutions.admin"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('ContentManagement')">Content Management</h2>
          </div>

        </div>

        <div class="w-1/2">

          <h3 class="text-lg font-bold mt-6 mb-2">Mail</h3>
          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="mail1" class="flex-shrink-0 w-6 h-6" value="MailOptimization" v-model="doc.exportSettings.solutions.mail"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('MailOptimization')">Mail Optimization Consulting</h2>
          </div>
          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="mail2" class="flex-shrink-0 w-6 h-6" value="BccMailManager" v-model="doc.exportSettings.solutions.mail"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('BccMailManager')">BCC Mail Manager</h2>
          </div>
          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="mail3" class="flex-shrink-0 w-6 h-6" value="QuadientBulkMailer" v-model="doc.exportSettings.solutions.mail"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('QuadientBulkMailer')">Quadient Bulk Mailer</h2>
          </div>
          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="mail4" class="flex-shrink-0 w-6 h-6" value="QuadientAims" v-model="doc.exportSettings.solutions.mail"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('QuadientAims')">Quadient AIMS</h2>
          </div>
          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="mail5" class="flex-shrink-0 w-6 h-6" value="ProcessDirector" v-model="doc.exportSettings.solutions.mail"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('ProcessDirector')">Ricoh Process Director</h2>
          </div>

        </div>

        <div class="w-1/2">

          <h3 class="text-lg font-bold mt-6 mb-2">Other Recommended Solutions</h3>
          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="other1" class="flex-shrink-0 w-6 h-6" value="ElectronicBillPayment" v-model="doc.exportSettings.solutions.other"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('ElectronicBillPayment')">Electronic Bill Presentment and Payment</h2>
          </div>
          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="other2" class="flex-shrink-0 w-6 h-6" value="StandardOperatingProcedures" v-model="doc.exportSettings.solutions.other"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('StandardOperatingProcedures')">SOP Best Practices</h2>
          </div>
          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="other3" class="flex-shrink-0 w-6 h-6" value="SupportTraining" v-model="doc.exportSettings.solutions.other"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('SupportTraining')">Support & Training</h2>
          </div>
          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="other4" class="flex-shrink-0 w-6 h-6" value="OpsChecklist" v-model="doc.exportSettings.solutions.other"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('OpsChecklist')">Operations Checklist</h2>
          </div>
          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="other5" class="flex-shrink-0 w-6 h-6" value="RicohConsultingServices" v-model="doc.exportSettings.solutions.other"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('RicohConsultingServices')">Ricoh Consulting Services</h2>
          </div>
          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="other6" class="flex-shrink-0 w-6 h-6" value="SecurityConformance" v-model="doc.exportSettings.solutions.other"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('SecurityConformance')">Security Conformance</h2>
          </div>
          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="other7" class="flex-shrink-0 w-6 h-6" value="DisasterRecovery" v-model="doc.exportSettings.solutions.other"/>
            <h2 class="ml-4 text-lg hover-link" @click="showBoilerplate('DisasterRecovery')">Disaster Recovery</h2>
          </div>

        </div>



      </div>
    </div>

  </div>

  <div class="pt-8 mt-4">
    <p class="pb-2 font-bold border-b mb-4 text-lg">Generate Reports</p>
    <div class="pb-16 flex justify-between">

      <div class="flex gap-4">
        <button @click="openSnapshotPreview" class="button button-green">Snapshot</button>
        <button @click="openPreview" class="button button-blue button-next">Full Transactional</button>
        <button @click="openPodPreview" class="button button-blue">Full POD</button>
        <button @click="openPodTransPreview" class="button button-blue button-next">Full POD+Transactional</button>
      </div>

    </div>
  </div>




</div>
</template>

<script>

export default {
    name: "config-summary-export",
  components: {},
  props: {
      doc: {
        type: Object,
        required: true
      },
      api: {
        type: Object,
        required: true
      },
      options: {
        default: function(){
          return {
            instructions: "Select solutions boilerplate to include in Full Report."}
          }

      }
    },
    mounted() {

    },
  data: function(){
        return {
          hasUnsavedChanges: false,
          saving: false
        }
        },
    methods: {

      openPreview: function(){


        if(this.hasUnsavedChanges){
          this.saving = true;


          this.api.save(this.doc).then(()=>{
            this.$router.push({
              name: "ConfigReport",
              query: {
                r: this.$route.params.configuration,
                api: "sc",
                t: "scf"
              }
            })
          })
        }else{
          this.$router.push({
            name: "ConfigReport",
            query: {
              r: this.$route.params.configuration,
              api: "sc",
              t: "scf"
            }
          })
        }


      },

      openSnapshotPreview: function(){


        if(this.hasUnsavedChanges){
          this.saving = true;


          this.api.save(this.doc).then(()=>{
            this.$router.push({
              name: "ConfigReport",
              query: {
                r: this.$route.params.configuration,
                api: "sc",
                t: "scs"
              }
            })
          })
        }else{
          this.$router.push({
            name: "ConfigReport",
            query: {
              r: this.$route.params.configuration,
              api: "sc",
              t: "scs"
            }
          })
        }


      },

      openPodPreview: function(){


        if(this.hasUnsavedChanges){
          this.saving = true;


          this.api.save(this.doc).then(()=>{
            this.$router.push({
              name: "ConfigReport",
              query: {
                r: this.$route.params.configuration,
                api: "sc",
                t: "scp"
              }
            })
          })
        }else{
          this.$router.push({
            name: "ConfigReport",
            query: {
              r: this.$route.params.configuration,
              api: "sc",
              t: "scp"
            }
          })
        }


      },

      openPodTransPreview: function(){


        if(this.hasUnsavedChanges){
          this.saving = true;


          this.api.save(this.doc).then(()=>{
            this.$router.push({
              name: "ConfigReport",
              query: {
                r: this.$route.params.configuration,
                api: "sc",
                t: "scpt"
              }
            })
          })
        }else{
          this.$router.push({
            name: "ConfigReport",
            query: {
              r: this.$route.params.configuration,
              api: "sc",
              t: "scpt"
            }
          })
        }


      },



      onChange: function(){
          this.hasUnsavedChanges = true;
      },

      showBoilerplate: function(solution){
          this.$emit('boilerplate',solution);
      },

      generateDoc: async function(){

        if(this.saving){
          return;
        }

        if(this.hasUnsavedChanges){
          this.saving = true;

          console.log("saving");

          await this.api.save(this.doc).then(() =>{
            this.saving = false;
            this.hasUnsavedChanges = false;
            return true;
          }).catch((error) => {
            console.error(error);
            alert("Settings could not be saved. Try again.");
            this.saving = false;
            return false;
          })
        }


        // this.$router.push({
        //   name: "NeedsReport",
        //   query: {
        //     r: this.$route.params.doc,
        //     t: "na"
        //   }
        // })
      },

      generateSnapshot: async function(){

        if(this.saving){
          return;
        }

        if(this.hasUnsavedChanges){
          this.saving = true;
          let userEmail = this.$store.getters.getUserProfile.email;

          console.log("saving");

          await this.api.update(this.doc.id, {
            exportSettings: this.doc.exportSettings
          }, userEmail).then(() =>{
            this.saving = false;
            this.hasUnsavedChanges = false;
            return true;
          }).catch((error) => {
            console.error(error);
            alert("Settings could not be saved. Try again.");
            this.saving = false;
            return false;
          })
        }


        // this.$router.push({
        //   name: "NeedsReport",
        //   query: {
        //     r: this.$route.params.doc,
        //     t: "na"
        //   }
        // })
      },

    },
    computed: {

      exportSettings: function(){
        if(this.doc){
          return this.doc.exportSettings;
        }

        return null

      },
    }
}
</script>

<style scoped>


.summary-export {

}

</style>
