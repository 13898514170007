


<template>
<div class="config-question-text">
  <div class="md:flex justify-between items-center">
    <label class="mb-1 md:mb-0 block">{{ question.label }}</label>
    <input class="w-full md:w-96 p-2 border rounded bg-white" type="text" v-model="answer.value" @change="$emit('change')" />
  </div>
</div>
</template>

<script>
export default {
    name: "config-question-text",
    props: {
      question: {
        type:Object,
        required: true
      },
      answer: {
        type:Object,
        required: true
      }
    },
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


.config-question-text {

}

</style>
