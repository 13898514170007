


<template>
<div class="market-direct">
<h4>ePS MarketDirect StoreFront</h4>
  <hr>
  <p>ePS MarketDirect StoreFront® is a powerful eCommerce solution that is deployed on a hosted platform that allows you to upgrade your capabilities as your business grows. In addition, it also offers automation to MIS solutions like ePS PrintSmith™ Vision via optional integration modules. MarketDirect StoreFront delivers innovative tools which let you effortlessly and quickly publish stunning customized web stores - where you can sell everything from print and non-print products, variable data print, direct mail, marketing kits and even cross-media marketing campaigns.</p>
  <p>MarketDirect StoreFront is the award-winning flexible eCommerce solution that offers the industry-leading print buyer experience designed to win new business, acquire new customers and grow your existing business online. Providing a complete, streamlined workflow from the document creator through the print production environment to finishing and delivery. MarketDirect StoreFront is a feature-rich and industry-leading web-to-print solution.</p>
  <p class="bold-label">Key Features:</p>
  <ul>
    <li>eCommerce sites that can be branded for a specific client, company or department with custom colors, logos and products</li>
    <li>Streamlined job submission designed to integrate with an MIS system to provide end-to-end business integration</li>
    <li>Powerful integration with MarketDirect Cross Media Marketing</li>
    <li>Multi-language, currency, company and plant support</li>
    <li>Industry-specific software development, training and support</li>

  </ul>
  <p class="bold-label">Customer Benefits:</p>
  <ul>
    <li>Support for a wide range of eCommerce products including; Print on demand, Static inventory, Variable Data Print, Cross Media Marketing, SmartCanvas Design online products and kitted products</li>
    <li>Designed for commercial printers, packaging printers, in-plant print centers and franchise networks</li>
    <li>Scalable and flexible modular licensing options enable customers to configure according to requirements</li>
    <li>Integration options for shipping, payment gateways and XML data exchange</li>
  </ul>
  <p class="bold-label">Automated Integrated Workflows:</p>
  <p>MarketDirect StoreFront helps you grow your business by providing a complete eCommerce toolset that will enable you to increase sales and provides the integration to MIS and ERP solutions to increase productivity and reduce labor costs through automation.</p>
  <ul>
    <li>MarketDirect StoreFront integrates with MIS and ERP solutions</li>
    <li>Integration eliminates repetitive tasks and reduces manual touchpoints while maximizing your productivity and profitability</li>
    <li>Real-time status updates are bidirectional, which allows for immediate communication to both operators and customers alike</li>
  </ul>
  <p class="bold-label">Hosting Options:</p>
  <p>MarketDirect StoreFront provides users options that fit each of their specific needs including:</p>
  <p><b>Cloud:</b> A cloud-based (SaaS) deployment model is a compelling option with a convenient pay-as-you-go model. The EFI experts will continuously monitor performance and keep your site running 24/7. The team also takes care of maintaining the server, installing upgrades and upscaling when needed.</p>
  <p><b>Self-Hosted:</b> If you want to incorporate MarketDirect StoreFront into your existing IT architecture, the self-hosted deployment option (on-premise) enables you to retain full control of your Web application on your network, behind the firewall.</p>


</div>
</template>

<script>
export default {
    name: "market-direct",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


.market-direct {

}

</style>