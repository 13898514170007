<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.96 20.61">
    <defs></defs>
    <title>icon-x</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <rect className="cls-1" x="-3.36" y="9.31" width="26.69" height="2"
              transform="translate(-4.37 10.34) rotate(-46.03)"/>
        <rect className="cls-1" x="8.98" y="-3.13" width="2" height="26.2"
              transform="translate(-4.13 9.98) rotate(-45)"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "icon-x"
}
</script>

<style scoped>



.icon-x {

}

</style>
