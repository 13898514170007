

export default function(configuration, classification){

    ////console.log("classification", classification);

    let equipment = configuration.equipment;
    let siteFunctionDetails = configuration.siteFunctionDetails;

    function detailOptionMap(list){
        var flatMap = {};
        list.forEach(function(detail){
            if(detail.type == "map"){
                var options = detail.options;
                options.forEach(function(option){
                    flatMap[option.id] = option;
                })
            }else{
                flatMap[detail.id] = detail;
            }
        })

        return flatMap;
    }

    function totalEquipmentSpeedPerShift(models, speedKey = "ppm"){
        let speed = models.reduce((total, item)=>{
            let baseSpeed = (speedKey === "ppm")?(item.model[speedKey] * 0.75):item.model[speedKey]
            return (total + baseSpeed)
        },0)

        return speed;
    }

    function getTotalShifts(models){
        return models.reduce((total, item)=>{
            if(item.shifts > total){
                total = item.shifts
            }
            return total
        },1)
    }

    function totalEquipmentSpeed(models, speedKey = "ppm", shiftOverride){
        let speed = models.reduce((total, item)=>{

            //let extraShiftSpeed = 0
            let shifts = parseInt(shiftOverride?shiftOverride:item.shifts);
            let baseSpeed = (speedKey === "ppm")?(item.model[speedKey] * 0.75):item.model[speedKey]
            if(shifts > 1){
                baseSpeed = (shifts * (speedKey === "ppm")?(item.model[speedKey] * 0.75):item.model[speedKey] * 0.9)
            }

            return (total + baseSpeed)
        },0)

        return speed;
    }

    function laborCalculation(category, models, volume, jobs, setupTime = 5){
        console.log(category,models, volume, jobs, setupTime)

        let equipmentSpeed = 0
        let runMinutes = 0
        let setupMinutes = 0
        let laborMinutes = 0
        let staffing = 0;

        if(category === 'wideFormat'){
            equipmentSpeed = totalEquipmentSpeedPerShift(models, "opSpeed")


            if(models.length > 1){
                equipmentSpeed = equipmentSpeed * 0.9;
            }

            let shifts = getTotalShifts(models)
            console.log("shifts",shifts);


            runMinutes = (volume / equipmentSpeed);
            setupMinutes = jobs * setupTime;
            laborMinutes = runMinutes + setupMinutes;

            if((models.length === 3) && (laborMinutes > 8190)){
                //calculated run minutes is too much for 1 shift, we need to try reducing devices and adding a shift

            }

            staffing = laborMinutes / 8190;

        }else{
            equipmentSpeed = totalEquipmentSpeed(models, "ppm")

            if(models.length > 1){
                equipmentSpeed = equipmentSpeed * 0.9;
            }

            runMinutes = volume / equipmentSpeed;
            setupMinutes = jobs * setupTime;
            laborMinutes = runMinutes + setupMinutes;
            staffing = laborMinutes / 8190; //8190 minutes available per staff member per day
        }







        return {
            category: category,
            models: models.length,
            shifts: models.reduce((total, item)=>{
                total.push(`${item.model.name} @ ${item.shifts}`)
                return total
            },[]),
            jobs: jobs,
            totalRunMinutes: runMinutes,
            totalSetupMinutes: setupMinutes,
            totalEquipmentSpeed: equipmentSpeed,
            totalLaborMinutes: laborMinutes,
            staffing: staffing
        }
    }

    return {
        totalEquipmentSpeedPerShift: totalEquipmentSpeedPerShift,
        summary: function(details, customConfigDeprecated, questionId){
            console.log("deprecated custom config?",customConfigDeprecated)
            console.log(questionId)
            let customConfig = details.customConfiguration;
            ////console.log("details", details);
            //using answer inteface as defined in MainStore answers[questionId].details

            if(Object.prototype.hasOwnProperty.call(configuration,'generateSummary')){
                //console.log("generating custom detail summary")
                let generatedReport = configuration.generateSummary(details);
                this.recommendation = generatedReport;
                return generatedReport;
            }else{
                //console.log("generating standard detail summary")
            }

            var requiredDetails = [];
            var specs = [];

            var _detailMap = this.detailMapPlusOptions;
            ////console.log("Detail Map", _detailMap);

            for(var detailId in details){

                if(details[detailId] === true){
                    requiredDetails.push(detailId);
                }else if( typeof(details[detailId]) == "object" ){

                    var nestedDetails = details[detailId];
                    for(var nestedId in nestedDetails){
                        if(nestedDetails[nestedId] == true){
                            requiredDetails.push(nestedId);
                        }
                    }
                }else{
                    ////console.log("possible spec", detailId, details[detailId]);
                    //Specs are site function number or string value fields. -- they do not pertain to each device
                    if(details[detailId]){

                        ////console.log('spec', detailId,details[detailId],_detailMap[detailId]);

                        specs.push({
                            id: detailId,
                            value: details[detailId],
                            label: _detailMap[detailId].label
                        })




                    }
                }
            }



            var modelList = this.equipmentList();



            var clashingRequirements = requiredDetails.some((detailId) => {
                let detailExcludeSwitches = _detailMap[detailId].excludes;
                if(detailExcludeSwitches){
                    let clash = detailExcludeSwitches.some((exclusionId) => {
                        return (requiredDetails.indexOf(exclusionId) > -1);
                    })

                    if(clash){
                        return true;
                    }
                }

            })


            var eligibleModels = [];


            //if no globally clashing requirements are found, then try to identify eligible models. Keep in mind,
            //the exclusions at the device level could also end up eliminating all model recommendations
            if(!clashingRequirements){
                eligibleModels = modelList.filter(function(model){
                    let ineligible = requiredDetails.find(function(detail){
                        return model.excludedBy.indexOf(detail) > -1;
                    })

                    return !ineligible;


                })
            }else{
                //console.log("Clashing requirements, so no models recommended for ", configuration.id)
            }




            eligibleModels.sort(function(a,b){
                //sort models by volume. Highest speed printer takes priority?

                if(a.volume > b.volume){
                    return 1;
                }else if(a.volume < b.volume){
                    return -1
                }else{
                    return 0;
                }
            })

            //console.log("Eligible models",eligibleModels)

            var modelMultiplier = 1;
            var shifts = 1;

            let isRollModel = requiredDetails.includes("printOnRollOnly");
            let isRigidOnly = requiredDetails.includes("printOnRigidOnly");
            let isRigidOrRoll = requiredDetails.includes("printOnRigidOrRoll");

            let isWideFormat = (isRollModel || isRigidOrRoll || isRigidOnly)

            let volume = parseInt(details.volumePerMonth);
            let jobsSetupTime = (details.jobsPerMonth * details.jobSetupTime?details.jobSetupTime:5)

            let minimumSpeedNeeded = volume / (8190 - jobsSetupTime); //total volume by minutes in a shift - setup minutes)

            let bestModel = isWideFormat?eligibleModels.find(function(model){
                    return model.opSpeed > minimumSpeedNeeded
                }):
                eligibleModels.find(function(model){
                    return model.ppm > minimumSpeedNeeded;
                })


            if(!bestModel){
                //console.log("no single model has enough volume. Must check against multiple shifts (volume max)")



                if(isWideFormat){
                    //we can add up to 3 models on a single shift for Roll only wide format

                    console.log("need to add multiple models")
                    bestModel = eligibleModels.find(function(model){
                        let maxShiftSpeed = (model.opSpeed * 0.9) * 3
                        let neededMinutes = volume / maxShiftSpeed;
                        return neededMinutes <= (8190 - jobsSetupTime);
                    });

                    if(bestModel){

                        let deviceSpeed = bestModel.opSpeed * 0.9
                        let volumePerDevice = 8190 * deviceSpeed;
                        modelMultiplier = Math.ceil(volume / volumePerDevice)

                        console.log(`Found best model:${modelMultiplier} of ${bestModel.name} with volume of ${volumePerDevice} each`)
                    }

                    if(!bestModel){
                        //no device is available that can handle volume even with 3x devices on a single shift.

                        bestModel = eligibleModels.find(function(model){
                            let maxShiftSpeed = (model.opSpeed * 0.9) * 4
                            let neededMinutes = volume / maxShiftSpeed;
                            return neededMinutes <= (8190 - jobsSetupTime);
                        });

                        //if we can get by with 2 on 2 shifts
                        if(bestModel){
                            modelMultiplier = 2
                            shifts = 2;
                        }

                        //otherwise try 3 on 2 shifts
                        if(!bestModel){
                            bestModel = eligibleModels.find(function(model){
                                let maxShiftSpeed = (model.opSpeed * 0.9) * 6
                                let neededMinutes = volume / maxShiftSpeed;
                                return neededMinutes <= (8190 - jobsSetupTime);
                            });
                            if(bestModel){
                                shifts = 2;
                                modelMultiplier = 3;
                            }
                        }
                    }
                }
                else{
                    bestModel = eligibleModels.find(function(model){
                        return model.volumeMax > volume;
                    });

                    if(bestModel){
                        shifts = 2;
                    }
                }



            }

            if(!bestModel && eligibleModels.length){

                bestModel = eligibleModels.pop();
                modelMultiplier = Math.ceil(details.volumePerMonth / (bestModel.volume * 1.0));
                shifts = 1;
            }

            var totalFootprint = this.modelFootprint(bestModel, requiredDetails) * modelMultiplier;

            var models = [];

            if(bestModel){



                for(var i=0; i<modelMultiplier; i++){
                    var modelInstance = {
                        model: bestModel,
                        shifts: shifts,
                        options: requiredDetails.reduce((reqDetails, detailId ) => {

                            reqDetails[detailId] = true;
                            return reqDetails
                        }, {}),
                        optionNames: requiredDetails.reduce((reqNames, detailId ) => {
                            //console.log(_detailMap);
                            reqNames.push(_detailMap[detailId].label);
                            return reqNames
                        }, []).join(", "),
                        footprint: this.modelFootprint(bestModel, requiredDetails),
                        name: ""
                    }

                    models.push(modelInstance);
                }
            }


            //return [bestModel?(prefix+bestModel.name+suffix):"No model fits these specs"];

            var recommendationWarning = bestModel?undefined:"No single device can fulfill the specified requirements. Use the customize button to set up a custom configuration.";

            //console.log("creating summary report",models)

            var summaryReport =  {
                model: bestModel?bestModel:{name: "No model available for these specs"},
                models: models,
                volume: volume,
                numOfModels: bestModel?modelMultiplier:undefined,
                numOfShifts: bestModel?shifts:undefined,
                footprint: totalFootprint,
                requirements: requiredDetails.map((detailId) => {
                    return _detailMap[detailId];
                }),
                specs: specs,
                staffing: 0,
                classification: classification,
                description: volume.toLocaleString() + " "+(bestModel?(bestModel.volumeSuffix?bestModel.volumeSuffix:"pages per month"):""),
                volumeSuffix: (bestModel?(bestModel.volumeSuffix?bestModel.volumeSuffix:"pages per month"):""),
                summaryLabel: "Equipment Details",
                recommendationWarning: recommendationWarning

            }

            summaryReport.labor = laborCalculation(questionId, (customConfig?.models)?customConfig.models:summaryReport.models, volume, details.jobsPerMonth, details.jobSetupTime)
            if(summaryReport.labor?.staffing){
                summaryReport.staffing = summaryReport.labor.staffing
            }

            //console.log("Custom Config?", customConfig?.models);
            //console.log("Summary report", summaryReport);

            if(details.volumePerMonth){
                ////console.log(summary);
                this.recommendation = summaryReport;
                return summaryReport
            }else{
                this.recommendation = undefined;
            }




        },

        equipmentList: function() {
            var list = [];
            for(var modelId in equipment){
                list.push(equipment[modelId]);
            }
            return list;
        },

        modelFootprint: function ( model, requirements ){

            if(!model){ return 0 }

            var baseFootage = model.totalFootage;
            var totalFootage = requirements.reduce(function(total, currentId){


                if(model.addedFootage[currentId]){
                    return (total + model.addedFootage[currentId]);
                }else{
                    return total + 0;
                }

            },baseFootage)

            return Math.ceil(totalFootage);
        },

        detailList: siteFunctionDetails,

        detailMap: (siteFunctionDetails.reduce(function(map, obj){
            map[obj.id] = obj;
            return map;
        },{})),

        detailMapPlusOptions: detailOptionMap(siteFunctionDetails),

        recommendation: undefined,

        customConfiguration: undefined
        //see summaryReport in summary for properties
        /* If custom is set, we will need to handle things a little differently?

        models should be an array: []


         */



    }

}
