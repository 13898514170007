//import numeral from 'numeral';

/*

Recommendation Configurator using rated input for single item (Low, Medium, High, or N/A)

configuration = {
    requirements: {
        low:{},
        medium: {},
        high: {}
    },

    ratingDetails: [
        { detail definition }
    ]
}

 */

export default function(configuration, classification){


    let ratingDetails = configuration.ratingDetails;
    let requirementDetails = configuration.requirements;

    return {
        summary: function(details){
            //console.log("details", details);
            //using answer inteface as defined in MainStore answers[questionId].details

            var rating = ratingDetails[0].options[details.level];
            var recommendation = requirementDetails[rating.id];


            if(recommendation) {

                var requiredItems = [];
                var softwareItems = [];

                if(recommendation.equipment) {
                    recommendation.equipment.forEach(function(item){
                        requiredItems.push({
                            label: item
                        })
                    })
                }

                if(recommendation.software) {
                    recommendation.software.forEach(function(item){
                        softwareItems.push({
                            label: item
                        })
                    })
                }

                if(recommendation.otherItems) {
                    recommendation.otherItems.forEach(function(item){
                        requiredItems.push({
                            label: item
                        })
                    })
                }

                let totalFootprint = recommendation.totalFootage;
                let totalStaffing = recommendation.staffing;

                let softwareDesc = softwareItems.map((softwareItem) => {
                    return softwareItem.label;
                }).join(", ");
                let equipmentDesc = requiredItems.map((equipmentItem) => {
                    return equipmentItem.label;
                }).join(", ");

                var summaryReport = {
                    description: rating.description,
                    model: undefined,
                    software: softwareDesc,
                    equipment: equipmentDesc,
                    volume: undefined,
                    numOfModels: 0,
                    numOfShifts: 0,
                    footprint: totalFootprint,
                    requirements: requiredItems,
                    specs: [],
                    staffing: totalStaffing,
                    staffingCode: recommendation.staffingCode,
                    classification: classification

                }

                this.recommendation = summaryReport;

                return summaryReport;

            }else{

                this.recommendation = undefined;

            }


        },

        recommendation: undefined,

        detailList: ratingDetails

    }

}
