<template>
  <div class="side-panel absolute top-0 right-0 w-full h-full bg-white border-l bg-gray-50">
    <div v-if="!detailPanel">
      <div class="flex justify-between items-center px-8 py-4 border-b bg-white">
        <h2 class="text-xl leading-none">Questionnaire Settings</h2>
        <div class="w-8 h-8 p-2 border-gray-300 border-2 rounded-full flex justify-center items-center mt-1 ml-3 opacity-50 hover:opacity-100 cursor-pointer" @click="close">
          <img  class="h-3 inline-block " src="../../assets/icons/close-icon.svg" />
        </div>
      </div>
      <div class="p-8">
        <div class="pb-8">
          <label class="text-sm font-bold">Current Respondent</label>
          <div class="flex w-full items-center">
            <select-input class="flex-grow" v-model="currentVoice" :options="respondentOptions" prompt-text="Select Respondent" :use-id="true" @change="onRespondentChange" />
            <plus-icon size="8" class="ml-2 cursor-pointer" @click.native="addRespondent"></plus-icon>
          </div>
          <div v-if="currentVoice" class="text-sm pl-2 border-l border-black ml-1 mt-4">
            <p class="font-bold hover:underline cursor-pointer" @click="editRespondent">Title: {{ respondentInfo.title }}</p>
            <p class="">Added {{ addDate }} by {{ respondentInfo.reviewer }}</p>
            <p class="text-gray-500 hover:underline cursor-pointer mt-2" @click="editRespondent">{{ respondentInfo.notes }}</p>
            <p class="pt-2"><span class="text-brand-blue hover:underline cursor-pointer" @click="editRespondent">Edit info</span></p>
          </div>




        </div>
        <div class="pb-4 flex items-center">
          <input type="checkbox" id="checkbox" class="flex-shrink-0 w-6 h-6" v-model="showDepartmentQuestionsOnly" />
          <h2 class="ml-4 font-bold text-sm" :class="showDepartmentQuestionsOnly?'':'opacity-50'">Hide questions that don't apply to this respondent. <span class="text-xs block" v-if="respondentInfo">(Only show those relevant to {{ api.schema.voiceOptions[respondentInfo.department].label }})</span></h2>
        </div>

      </div>

      <div class="pt-6 border-t px-8">
        <div class="pb-4 flex justify-between">
          <div class="mr-8">
            <button class="button border-gray-400" @click="close">Cancel</button>
          </div>
          <div class="">
            <button class="button button-blue" @click="save">Apply</button>
          </div>
        </div>
      </div>
    </div>
    <div v-if="detailPanel">
      <side-panel-respondents :analysis="analysis" :api="api" :payload="detailPanel.payload" @close="closeDetailPanel" @update="onDetailUpdate"></side-panel-respondents>
    </div>
  </div>
</template>

<script>


import SelectInput from "@/components/SelectInput";
import PlusIcon from "@/components/PlusIcon";
import SidePanelRespondents from "@/components/NeedsAnalysis/SidePanelRespondents";

export default {
  name: "side-panel-needs-filters",
  components: {SidePanelRespondents, PlusIcon, SelectInput },
  mounted: function() {
    if(this.session && this.session.respondent){ //we use id for session
      this.currentVoice = this.session.respondent
    }

    if(this.filterInfo){ //we use id for session
      this.showDepartmentQuestionsOnly = !!(this.filterInfo.showDepartmentQuestionsOnly)
    }

  },
  props: {

    session: {
      type: Object,
      required: false
    },

    filterInfo: {
      type: Object,
      required: false
    },

    analysis: {
      type: Object,
      required: true
    },

    api: {
      type: Object,
      required: true
    },


  },
  data: function () {
    return {
      currentVoice: undefined,
      detailPanel: undefined,
      processing: false,
      detailPanelComponents: {
        respondents: "SidePanelRespondents"
      },
      showDepartmentQuestionsOnly: true
    }
  },
  methods: {

    toggleShowAll: function(){
      this.showDepartmentQuestionsOnly = !this.showDepartmentQuestionsOnly;
    },

    addRespondent: function(){
      this.detailPanel = {
        component: this.detailPanelComponents.respondents,
        payload: null
      }
    },

    editRespondent: function(){
      this.detailPanel = {
        component: this.detailPanelComponents.respondents,
        payload: this.respondentInfo
      }
    },

    onRespondentChange: function(){
      if(this.currentVoice === 0){
        this.addRespondent();
      }
    },



    close: function(){
      this.$emit('close');
    },

    save: async function(){

      this.$emit("update-settings", {
        "session":{
          "key": "respondent",
          "value": this.currentVoice
        },
        "filtering": {
          showDepartmentQuestionsOnly: this.showDepartmentQuestionsOnly
        }
      })
      this.close();

    },

    closeDetailPanel: function(){
      this.detailPanel = null;
    },
    onDetailUpdate: function(update){
      console.log(update);

      if(update.field === "respondent"){
        this.currentVoice = update.value;
      }
    },

  },
  computed: {

    addDate: function(){
      if(this.respondentInfo){
        let added = new Date(this.respondentInfo.ts);
        return added.toLocaleDateString();
      }

      return ""
    },
    respondents: function(){
      return this.analysis.respondents;
    },

    respondentInfo: function(){
      if(this.respondents){
        return this.respondents[this.currentVoice];
      }

      return null
    },

    respondentOptions: function(){

      this.detailPanel;

      let _respondents = Object.keys(this.respondents);
      if(_respondents.length){
        let options = _respondents.map((respondent) => {
          return {
            id: respondent,
            label: `${this.respondents[respondent].name} - ${this.api.schema.voiceOptions[this.respondents[respondent].department].label}`
          }
        })
        // let me = this.$store.getters.getUserProfile.userid;
        // options.push({ id: me, label: "My Notes"})
        return options;
      }else{
        return [
          { id: 0, label: "Add New Respondent"}
        ]
      }

    },
  }
}
</script>

<style scoped>


.side-panel-new-assessment {

}

.button[disabled]{
  opacity: 0.5;
  pointer-events: none;
}

</style>
