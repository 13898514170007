


<template>
<div class="">
  <h4>Content Management</h4>
  <hr>
  <p>Inspire Designer and Inspire Content Manager enables customers to manage customer communications from a single, central platform, map, merge and process data from all applications and systems. Control access to your design templates and manage versions of content and applications for automated deployments. Easily control configurations for all your different environments (including development, test, and production) and automate deployment of flexible release packages between them to control what gets delivered to customers and when. https://www.quadient.com/experience/inspire-designer-icm</p>

</div>
</template>

<script>
export default {
    name: "content-management",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


</style>
