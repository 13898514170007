


<template>
<div class="">
  <h4>Quadient AIMS - Automated Insertion Management System</h4>
  <hr>
  <p>Working with your Quadient folder inserter, AIMS by Quadient provides real-time information to manage your mail center and every mail piece with absolute accuracy, and efficiency. The AIMS solution seamlessly integrates to the data logging and statistical functions of Quadient’s production folder inserters using our Integrated Mail Operating System (IMOS).</p>
  <p>IMOS records the progress of each mail piece as it is processed; creating statistical data on every job, then transfers this data to AIMS. By combining this level of integrity and detail with a SQL database server and browser-based application, Quadient has created a modern, intuitive way to manage every individual mail piece and your mail center with total confidence.</p>
  <p class="bold-label"></p>
  <p>Businesses face a multitude of regulatory issues and customer demands to guarantee the integrity of sensitive business communications. AIMS by Quadient provides two solutions in one: real-time information and statistics to manage your mail center and verification capability confirming that every piece of mail in your job has been accurately processed, providing complete closed-loop, piece-level integrity. AIMS provides the verification and assurance that compliance requirements have been met and peace of mind that important business communications are secure.</p>
  <p>AIMS by Quadient allows customers to monitor and manage mail center output, productivity levels, and most importantly, to verify mail piece accountability and ensure regulatory compliance. Quadient offers a range of production-level folder inserters, output management software and AIMS solutions to help businesses process sensitive business communications.</p>

  <p class="bold-label">Key Features</p>
  <p>AIMS licensing is based upon the number of inserters connected. This allows many different views of the browser-based interface simultaneously.</p>
  <p>The Dynamic Dashboard enables visibility and management of the throughput of the mail center on a real-time basis. Track the performance of your mail center or multi-location networked operation, and view the dashboard to increase productivity and accountability.</p>
  <ul>
    <li>Monitor day-to-day folder inserter output or historical summary data</li>
    <li>Examine operator activity including mail piece output, uptime, downtime, and errors</li>
    <li>Review job timing and completion status</li>
  </ul>
  <p>Statistical reports capture all Dashboard data for detailed analysis. AIMS generates reports based on key performance indicators. All statistics are viewable on-screen or printable in tabular and graphical formats.</p>
  <p><b>AIMS-100 (Audit Trail)</b> allows you to generate job files on the fly as the inserter is running, giving you complete traceability of each document processed. Hand mailed items can be updated and damaged items can be removed. This is best suited for mail centers where closed-loop verification is not required.</p>
  <p><b>AIMS-500 (Closed Loop Verification)</b> offers a complete, integrated closed-loop solution when combined with Output Management Solutions. With automated database creation and reprint generation, the icon-based screens make viewing the integrity of your documents simple. AIMS 500 also provides Audit Trail as needed.</p>
  <p><b>AIMS-1000 (External Loop)</b> includes a unique interface-mapping module, which allows it to communicate with almost any existing or planned ADF implementation. Files are imported, converted for use within AIMS and then exported in their original format after processing. AIMS-1000 is not required for a Ricoh based ADF.</p>
  <p><b>Quadient Impress Automate</b> is a user-friendly, cloud-based, and scalable platform and includes powerful applications and services that integrate easily with legacy systems. Quadient Impress helps you automate your entire customer communication workflow giving you the flexibility to send transactional documents via any combination of channels - Print, Digital and Outsource. The platform’s modular architecture allows you to configure your solution to meet your specific needs and evolve at your own pace as your business grows. With Quadient Impress you can:</p>
  <ul>
    <li>Replace inefficient, manual processes</li>
    <li>Offer digital delivery through a secure, branded document portal</li>
    <li>Centralize communications while leveraging your own mail center</li>
    <li>Prepare and deliver mail without leaving your desk</li>

  </ul>

</div>
</template>

<script>
export default {
    name: "quadient-aims",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


</style>
