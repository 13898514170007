import faunadb from 'faunadb';
import EquipmentRecommendation from "@/configurators/EquipmentRecommendation";
import Mail from "@/configurators/Mail.config";
import CutSheetMonochrome from "@/configurators/CutSheetMonochrome.config";
import CutSheetColor from "@/configurators/CutSheetColor.config";
import WideFormat from "@/configurators/WideFormat.config";
import ContinuousFeedInkjet from "@/configurators/ContinuousFeedInkjet.config";
import Envelope from "@/configurators/Envelope.config";
import OfflineFinishingRecommendation from "@/configurators/OfflineFinishingRecommendation";
import OfflineFinishing from "@/configurators/OfflineFinishing.config";
import ServiceRecommendation from "@/configurators/ServiceRecommendation";
import Scanning from "@/configurators/Scanning.config";
import IncomingMailing from "@/configurators/IncomingMailing.config";
import OutgoingMail from "@/configurators/OutgoingMail.config";
import CopierFleet from "@/configurators/CopierFleet.config";
import DesignDevelopment from "@/configurators/DesignDevelopment.config";
import VDPPersonalization from "@/configurators/VDPPersonalization.config";
import ColorManagement from "@/configurators/ColorManagement.config";
import WebToPrintMIS from "@/configurators/WebToPrintMIS.config";
import ParcelFreight from "@/configurators/ParcelFreight.config";
import CourierDelivery from "@/configurators/CourierDelivery.config";
import Fulfillment from "@/configurators/Fulfillment.config";
import ManageOutsourcedPrint from "@/configurators/ManageOursourcedPrint.config";
import Receptionist from "@/configurators/Receptionist.config";
import OtherSpecialtyServices from "@/configurators/OtherSpecialtyServices.config";
import ReceptionPhone from "@/configurators/ReceptionPhone.config";
import CustomerCounter from "@/configurators/CustomerCounter.config";
import LoadingDock from "@/configurators/LoadingDock.config";
import FulfillmentStorageOnly from "@/configurators/FulfillmentStorageOnly.config";
import siteConfig from "@/schema/siteConfig";


const q = faunadb.query;
const collection = "printconfigs";



const questions = {
    'facilityName':{
        id: 'facilityName',
        type: 'string',
        required: true,
        component: "ConfigQuestionText",
        label: 'Organization Name',
        default: ''
    },
    'facilityContact':{
        id: 'facilityContact',
        type: 'string',
        required: true,
        component: "ConfigQuestionText",
        label: 'Customer Contact',
        default: ''

    },
    'facilityAddress': {
        id: 'facilityAddress',
        type: 'string',
        required: true,
        component: "ConfigQuestionText",
        label: 'Street Address',
        default: ''
    },
    'facilityCity': {
        id: 'facilityCity',
        type: 'string',
        required: true,
        component: "ConfigQuestionText",
        label: 'City',
        default: ''
    },
    'facilityState': {
        id: 'facilityState',
        type: 'string',
        required: true,
        component: "ConfigQuestionText",
        label: 'State',
        default: ''
    },
    'facilityPostal': {
        id: 'facilityPostal',
        type: 'string',
        required: true,
        component: "ConfigQuestionText",
        label: 'Postal Code',
        default: ''
    },
    'facilityCountry': {
        id: 'facilityCountry',
        type: 'string',
        required: true,
        component: "ConfigQuestionText",
        label: 'Country',
        default: ''
    },
    'facilityJobsPerYearWideFormat': {
        id: 'facilityJobsPerYearWideFormat',
        type: 'number',
        required: true,
        component: "ConfigQuestionText",
        label: 'Wide Format Jobs Per Year',
        default: '',
        formatInfo: {
            format: "0,0"
        }
    },
    'facilityJobsPerYear': {
        id: 'facilityJobsPerYear',
        type: 'number',
        required: true,
        component: "ConfigQuestionText",
        label: 'Job Per Year For All Other Production',
        default: '',
        validationInfo: {
            isValid: (value) => { return (value < 6000) },
            warningMsg: "This high level of jobs processed annually may require Order Submission and MIS software, additional staff and equipment",
            overrideValue: undefined
        },
        formatInfo: {
            format: "0,0"
        }
    },

    'facilityVertical':{
        id: 'facilityVertical',
        type: 'string',
        required: true,
        component: "ConfigQuestionSelect",
        label: 'Vertical Market',
        options: [

            {
                id: "education",
                label: "Education"
            },
            {
                id: "insurance",
                label: "Insurance"
            },
            {
                id: "financial",
                label: "Financial"
            },
            {
                id: "government",
                label: "Government"
            },
            {
                id: "healthcare",
                label: "Healthcare"
            },
            {
                id: "manufacturing",
                label: "Manufacturing"
            },
            {
                id: "retail",
                label: "Retail"
            },
            {
                id: "religious",
                label: "Religious"
            },
            {
                id: "other",
                label: "Other"
            },
        ],
        default: "Other"
    },
    'functionCutSheetMonochrome': {
        id: 'functionCutSheetMonochrome',
        configurator: EquipmentRecommendation(CutSheetMonochrome, "siteFunction"),
        type: 'boolean',
        required: true,
        component: "ConfigQuestionSwitchDetail",
        label: 'Cut Sheet Monochrome',
        default: false,
        details: undefined
    },

    'functionCutSheetColor': {
        id: 'functionCutSheetColor',
        configurator: EquipmentRecommendation(CutSheetColor, "siteFunction"),
        type: 'boolean',
        required: true,
        component: "ConfigQuestionSwitchDetail",
        label: 'Cut Sheet Color',
        default: false,
        details: undefined
    },

    'wideFormat': {
        id: 'wideFormat',
        configurator: EquipmentRecommendation(WideFormat, "siteFunction"),
        type: 'boolean',
        required: true,
        component: "ConfigQuestionSwitchDetail",
        label: 'Wide Format',
        default: false,
        details: undefined
    },

    'continuousFeedInkJet': {
        id: 'continuousFeedInkJet',
        configurator: EquipmentRecommendation(ContinuousFeedInkjet, "siteFunction"),
        type: 'boolean',
        required: true,
        component: "ConfigQuestionSwitchDetail",
        label: 'Continuous Feed Ink Jet',
        default: false,
        details: undefined
    },

    'envelopePrinting': {
        id: 'envelopePrinting',
        configurator: EquipmentRecommendation(Envelope, "siteFunction"),
        type: 'boolean',
        required: true,
        component: "ConfigQuestionSwitchDetail",
        label: 'Envelope Printing',
        default: false,
        details: undefined
    },

    'mailing': {
        id: 'mailing',
        configurator: EquipmentRecommendation(Mail, "siteFunction"),
        type: 'boolean',
        required: true,
        component: "ConfigQuestionSwitchDetail",
        label: 'Mailing',
        default: false,
        details: undefined,
        detailPanelComponent: "PulloutMailingMenu",
        detailSummaryComponent: 'EquipmentDetailSummaryMailing'
    },

    'offlineFinishing': {
        id: 'offlineFinishing',
        configurator: OfflineFinishingRecommendation(OfflineFinishing, "offlineFinishing"),
        type: 'boolean',
        required: true,
        component: "ConfigQuestionSwitchDetail",
        label: 'Offline Finishing',
        default: false,
        details: undefined
    },

    'serviceScanning': {
        id: 'serviceScanning',
        configurator: ServiceRecommendation(Scanning, "siteService"),
        type: 'boolean',
        required: true,
        component: "ConfigQuestionSwitchDetail",
        label: 'Scanning',
        default: false,
        details: undefined
    },

    'serviceMailing': {
        id: 'serviceMailing',
        configurator: ServiceRecommendation(IncomingMailing, "siteService"),
        type: 'boolean',
        required: true,
        component: "ConfigQuestionSwitchDetail",
        label: 'Incoming Mail / Distribution',
        default: false,
        details: undefined
    },

    'serviceOutgoingMail': {
        id: 'serviceOutgoingMail',
        configurator: ServiceRecommendation(OutgoingMail, "siteService"),
        type: 'boolean',
        required: true,
        component: "ConfigQuestionSwitchDetail",
        label: 'Outgoing Metered Mail',
        default: false,
        details: undefined
    },

    'serviceCopierFleet': {
        id: 'serviceCopierFleet',
        configurator: ServiceRecommendation(CopierFleet, "siteService"),
        type: 'boolean',
        required: true,
        component: "ConfigQuestionSwitchDetail",
        label: 'Copier Fleet',
        default: false,
        details: undefined
    },

    'serviceDesignDevelopment': {
        id: 'serviceDesignDevelopment',
        configurator: ServiceRecommendation(DesignDevelopment, "siteService"),
        type: 'boolean',
        required: true,
        component: "ConfigQuestionSwitchDetail",
        label: 'Design and Document Development',
        default: false,
        details: undefined
    },

    'serviceVDP': {
        id: 'serviceVDP',
        configurator: ServiceRecommendation(VDPPersonalization, "siteService"),
        type: 'boolean',
        required: true,
        component: "ConfigQuestionSwitchDetail",
        label: 'VDP / Personalization',
        default: false,
        details: undefined
    },

    'serviceColorManagement': {
        id: 'serviceColorManagement',
        configurator: ServiceRecommendation(ColorManagement, "siteService"),
        type: 'boolean',
        required: true,
        component: "ConfigQuestionSwitchDetail",
        label: 'Color Management',
        default: false,
        details: undefined
    },

    'serviceWebToPrintMIS': {
        id: 'serviceWebToPrintMIS',
        configurator: ServiceRecommendation(WebToPrintMIS, "siteService"),
        type: 'boolean',
        required: true,
        component: "ConfigQuestionSwitchDetail",
        label: 'Web-to-Print or MIS',
        default: false,
        details: undefined
    },

    'serviceParcelFreight': {
        id: 'serviceParcelFreight',
        configurator: ServiceRecommendation(ParcelFreight, "siteService"),
        type: 'boolean',
        required: true,
        component: "ConfigQuestionSwitchDetail",
        label: 'Parcel / Freight',
        default: false,
        details: undefined
    },

    'serviceCourier': {
        id: 'serviceCourier',
        configurator: ServiceRecommendation(CourierDelivery, "siteService"),
        type: 'boolean',
        required: true,
        component: "ConfigQuestionSwitchDetail",
        label: 'Courier Delivery',
        default: false,
        details: undefined
    },

    'serviceFullfillment': {
        id: 'serviceFullfillment',
        configurator: ServiceRecommendation(Fulfillment, "siteService"),
        type: 'boolean',
        required: true,
        component: "ConfigQuestionSwitchDetail",
        label: 'Fulfillment',
        default: false,
        details: undefined
    },

    'serviceOutsourcedPrint': {
        id: 'serviceOutsourcedPrint',
        configurator: ServiceRecommendation(ManageOutsourcedPrint, "siteService"),
        type: 'boolean',
        required: true,
        component: "ConfigQuestionSwitchDetail",
        label: 'Manage Outsourced Print',
        default: false,
        details: undefined
    },

    'serviceReceptionist': {
        id: 'serviceReceptionist',
        configurator: ServiceRecommendation(Receptionist, "siteService"),
        type: 'boolean',
        required: true,
        component: "ConfigQuestionSwitchDetail",
        label: 'Receptionist (not for the operation)',
        default: false,
        details: undefined
    },

    'serviceOther': {
        id: 'serviceOther',
        configurator: ServiceRecommendation(OtherSpecialtyServices, "siteService"),
        type: 'boolean',
        required: true,
        component: "ConfigQuestionSwitchDetail",
        label: 'Other Specialty Services',
        default: false,
        details: undefined
    },

    'facilityReceptionPhone': {
        id: 'facilityReceptionPhone',
        configurator: ServiceRecommendation(ReceptionPhone, "facilityItem"),
        type: 'boolean',
        required: true,
        component: "ConfigQuestionSwitchDetail",
        label: 'Reception for Operation',
        default: false,
        details: undefined
    },

    'facilityCustomerCounter': {
        id: 'facilityCustomerCounter',
        configurator: ServiceRecommendation(CustomerCounter, "facilityItem"),
        type: 'boolean',
        required: true,
        component: "ConfigQuestionSwitchDetail",
        label: 'Customer Counter',
        default: false,
        details: undefined
    },

    'facilityLoadingDock': {
        id: 'facilityLoadingDock',
        configurator: ServiceRecommendation(LoadingDock, "facilityItem"),
        type: 'boolean',
        required: true,
        component: "ConfigQuestionSwitchDetail",
        label: 'Loading Dock',
        default: false,
        details: undefined
    },

    'facilityFullfillmentStorage': {
        id: 'facilityFullfillmentStorage',
        configurator: ServiceRecommendation(FulfillmentStorageOnly, "facilityItem"),
        type: 'boolean',
        required: true,
        component: "ConfigQuestionSwitchDetail",
        label: 'Fulfillment Storage Only',
        default: false,
        details: undefined
    },

    'summaryReport': {
        id: 'summaryReport',
        type: 'boolean',
        required: true,
        component: "ConfigSummaryExport",
        label: 'Summary Export',
        default: false,
        details:[]
    }




};

function defaultValueForType(dataType){
    let defaultValue = false;

    switch (dataType) {
        case 'string':
            defaultValue = '';
            break;

        case 'boolean':
            defaultValue = false;
            break;

        case 'number':
            defaultValue = 0;
            break;

        case 'enum':
            defaultValue = 0;
            break;

        case 'array':
            defaultValue = [];
            break;


        default:
            defaultValue = false;
    }

    return defaultValue;
}

function defaultAnswers(questions) {

    let answers = {};
    for (let questionId in questions) {
        let question = questions[questionId];
        answers[questionId] = defaultAnswer(question);
    }

    return answers;
}

function defaultAnswer(question) {
    let answer = {
        value: (question.default !== undefined) ? question.default : defaultValueForType(question.type)
    };

    if (question['details'] === undefined) {
        if (question['configurator'] !== undefined) {
            question['details'] = question.configurator.detailList;
        }
    }

    if (question['details'] !== undefined) {
        let questionDetails = question.details;
        answer.details = {};

        questionDetails.forEach(function (detail) {
            if (detail.type == 'map') {
                var detailMap = {};
                detail.options.forEach(function (option) {
                    detailMap[option.id] = option.default;
                })
                answer.details[detail.id] = detailMap;

            } else {
                answer.details[detail.id] = (detail.default !== undefined) ? detail.default : defaultValueForType(detail.type);
            }

            //console.log(detail.id, detail.default, detail.type, answer.details[detail.id]);
        })

    }

    return answer;
}


function getAll(options){
    let token = localStorage.getItem("token");
    let user = options.user;
    var client = new faunadb.Client({ secret: token })

    if(user.level === 'admin'){
        return client.query(
            q.Paginate(
                q.Match(
                    q.Index("printconfigs_by_ts")
                )
            ),
        ).then((response) => {

            if(response.data){
                return response.data
            }else{
                throw new Error("Network error.");
            }
        })
    }else{
        return client.query(
            q.Paginate(
                q.Union(
                    q.Match(
                        q.Index("printconfigs_for_user_by_ts"),
                        user.userid
                    ),
                    q.Match(
                        q.Index("printconfigs_for_public_by_ts"),
                        true
                    )
                )

            ),
        ).then((response) => {

            if(response.data){
                return response.data
            }else{
                throw new Error("Network error.");
            }
        })
    }
}

function load(recordId){
    let token = localStorage.getItem("token");

    var client = new faunadb.Client({ secret: token })

    return client.query(
        q.Get(q.Ref(q.Collection(collection), recordId))
    ).then((response) => {


        if(response.data){
            let record = response.data;
            console.log(record)
            record.id = response.ref.value.id;
            if(!record.answers.mailing){
                record.answers.mailing = defaultAnswer(questions.mailing)
            }

            if(!record.answers.functionCutSheetMonochrome.details.customConfiguration){
                record.answers.functionCutSheetMonochrome.details.customConfiguration = null
            }

            if(!record.answers.functionCutSheetColor.details.customConfiguration){
                record.answers.functionCutSheetColor.details.customConfiguration = null
            }
            if(!record.answers.wideFormat.details.customConfiguration){
                record.answers.wideFormat.details.customConfiguration = null
            }

            if(!record.answers.continuousFeedInkJet.details.customConfiguration){
                record.answers.continuousFeedInkJet.details.customConfiguration = null
            }

            if(!record.answers.envelopePrinting.details.customConfiguration){
                record.answers.envelopePrinting.details.customConfiguration = null
            }

            if(!record.answers.mailing.details.customConfiguration){
                record.answers.mailing.details.customConfiguration = null
            }

            if(!record.interview) {
                record.interview = {
                    questions: {}
                };

            }

            Object.keys(siteConfig.questions).forEach((qId) => {

                    if(!record.interview.questions[qId]) {


                        let values = {
                            answer: {
                                "final": 0,
                                "Current": 0
                            },
                            hidden: siteConfig.questions[qId].hidden ? true : false,
                            active: true,
                            notes: "",
                            questionID: qId
                        }

                        if (siteConfig.questions[qId].allowOther) {
                            values.answer.final = ""
                        }

                        let q = siteConfig.questions[qId];
                        if (q.optional) {
                            values.active = false;
                        }
                        if (q.type == 6) {
                            values.answer.final = false;
                        }


                        if (q.defaults.length) {
                            if (q.cols.length) {
                                q.cols.forEach((col, index) => {
                                    if (q.dataFormat) {
                                        values.answer[col] = parseFloat(q.defaults[index]);
                                    } else {
                                        values.answer[col] = q.defaults[index];
                                    }

                                })
                            } else {
                                if (q.dataFormat) {
                                    values.answer.final = parseFloat(q.defaults[0]);
                                } else {
                                    values.answer.final = q.defaults[0];
                                }
                            }
                        }

                        record.interview.questions[qId] = values;
                    }
                })




            return record;
        }else{
            throw new Error("Document error.");
        }
    })
}

function generate(options){

    let created = Date.now();
    let record = {
        created: created,
        documentType: "Print Shop Configuration",
        admin: options.user.userid,
        user: options.user.userid,
        customerProfile: options.customer,
        userProfile: options.user,
        projectName: options.projectName,
        answers: defaultAnswers(questions),
        footage: 0,
        facilityItems: [],
        equipment: [],
        offlineFinishing: [],
        services: [],
        supportEquipment: [],
        productionStaff: [],
        managementStaff: [],
        title: "",
        jobsPerYear: 0,
        jobBreakdowns:[], //array of { function names and totals }
        staffingBreakdowns: { //by staffing codes
            ss: 0,
            ds: 0,
            sm: 0,
            op: 0,
            ts: 0
        },
        managementProfile: undefined,
        setupMinutes: 0,
        equipmentMinutes: 0,
        equipmentStaffing: 0,
        interview: {
            questions: {}
        },
        exportSettings: {
            solutions: {
                ordering: [],
                mis: [],
                workflow: [],
                composition: [],
                admin: [],
                mail: [],
                other: []
            }
        }
    }

    Object.keys(siteConfig.questions).forEach((qId) => {

        let values = {
            answer: {
                "final": 0,
                "Current": 0
            },
            hidden: siteConfig.questions[qId].hidden?true:false,
            active: true,
            notes: "",
            questionID: qId
        }

        if(siteConfig.questions[qId].allowOther){
            values.answer.final = ""
        }

        let q = siteConfig.questions[qId];
        if(q.optional){
            values.active = false;
        }
        if(q.type == 6){
            values.answer.final = false;
        }


        if(q.defaults.length){
            if(q.cols.length){
                q.cols.forEach((col, index) => {
                    if(q.dataFormat){
                        values.answer[col] = parseFloat(q.defaults[index]);
                    }else{
                        values.answer[col] = q.defaults[index];
                    }

                })
            }else{
                if(q.dataFormat) {
                    values.answer.final = parseFloat(q.defaults[0]);
                }else{
                    values.answer.final = q.defaults[0];
                }
            }
        }

        record.interview.questions[qId] = values;
    })



    record.answers.facilityName.value = options.customer.organizationName;

    return record;
}

function save(record){

    console.log("saving...");
    let token = localStorage.getItem("token");
    var client = new faunadb.Client({ secret: token })

    if(record.id){
        return client.query(
            q.Update(
                q.Ref(q.Collection(collection), record.id),
                {
                    data: record
                }
            )
        ).then((response) =>{
            return response;
        })
    }else{
        return client.query(
            q.Create(
                q.Collection(collection),
                {
                    data: record
                }
            )
        ).then((response) =>{
            return response;
        })
    }


}

function update(recordId,record,user){

    console.log("updating...");

    if(recordId){

        let token = localStorage.getItem("token");
        var client = new faunadb.Client({ secret: token })

        record.lastEdit = {
            ts: Date.now(),
            user: user
        }

        return client.query(
            q.Update(
                q.Ref(q.Collection(collection), recordId),
                {
                    data: record
                }
            )
        ).then((response) =>{
            return response;
        })
    }

}

function deleteRecord(recordId){
    let token = localStorage.getItem("token");
    var client = new faunadb.Client({ secret: token })

    return client.query(
        q.Delete(
            q.Ref(q.Collection(collection), recordId))
    ).then((response) =>{
        return response;
    })
}




export default {
    reports: {
       scf: {
           title: "Configuration Report",
       },
        sc: {
            title: "Configuration Summary",
        }
    },
    generate,
    save,
    update,
    load,
    getAll,
    delete:deleteRecord,
    questions: questions,
    defaultAnswers: defaultAnswers,
    defaultAnswer: defaultAnswer,
    schema: siteConfig
}
