


<template>
<div class="">
  <h4>Inventory Best Practices</h4>
  <hr>
  <p>Inventory management is a careful balancing act between stock availability and the cost of holding inventory.</p>
  <p>It is critical to maintain and monitor inventory levels to avoid production stoppage. A Print and Mail operation will need to hold inventory of production materials, including paper, envelopes and equipment supplies. Materials and supplies should be categorized by type and given a material number for easy tracking. Every month all materials and supplies should be cycle counted and reconciled to maintain accurate inventory levels.</p>
  <p>Best practices are to maintain safety stock inventory for emergencies to mitigate risk. Material should be stored within a temperature range of 65 - 85° and with relative humidity of 40% - 60%.</p>
</div>
</template>

<script>
export default {
    name: "inventory-best-practices",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


</style>
