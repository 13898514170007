<template>
    <div :class="classObject">
        <form-radio-dot class="switch-dot" :checked="checked"></form-radio-dot>
        <span class="box-label">{{ label }}</span>
    </div>
</template>

<script>

    import FormRadioDot from "./FormRadioDot.vue";

    export default {
        components: {

            FormRadioDot
        },
        props: {
            checked: {
                type: Boolean,
                required: false,
                default: false
            },
            label: {
                type: String,
                required: true
            }
        },
        computed: {
            classObject: function(){
                return {
                    "pullout-question-multi-switch-box":true,
                    "checked": this.checked
                }
            }
        },

        name: "pullout-question-multi-switch-box"
    }
</script>

<style scoped>

    .pullout-question-multi-switch-box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        align-items: center;
        background-color: var(--near-white-2);
        padding: 0.5em;
        text-align: center;
        border: 1px solid var(--near-white-1);
        box-sizing: border-box;
        color: var(--dark-gray);
      border-radius: 5px;
    }

    .switch-dot {
        font-size: 0.8em;
    }

    .box-label {
        margin-top: 0.5em;
    }

    .pullout-question-multi-switch-box.checked {
        color: var(--near-black-1);
        background-color: white;
        border: 1px solid var(--mid-gray);
    }

</style>
