export default {
    requirements: {
        low: {
            id: 'low',
            name: 'Low',
            totalFootage: 100,
            staffing: 1,
            equipment: [],
            software: [],
            otherItems: []
        },

        medium: {
            id: 'medium',
            name: 'Medium',
            totalFootage: 200,
            staffing:2,
            equipment: [],
            software: [],
            otherItems: []
        },

        high: {
            id: 'high',
            name: 'High',
            totalFootage: 300,
            staffing: 3,
            equipment: [],
            software: [],
            otherItems: []
        },
    },

    ratingDetails: [
        {
            id: 'level',
            type: 'enum',
            required: false,
            component: 'PulloutQuestionMultiSwitchList',
            default: 0,
            label: "Level of Need:",
            options: [
                {
                    id: "none",
                    label: "None",
                    value: 0
                },

                {
                    id: "low",
                    label: "Low",
                    description: "1 location",
                    value: 1
                },
                {
                    id: "medium",
                    label: "Medium",
                    description: "2 locations",
                    value: 2
                },
                {
                    id: "high",
                    label: "High",
                    description: "3 locations",
                    value: 3
                }
            ]
        }

    ]
}