<template>
  <div class="home relative h-full bg-gray-50">
    <div class="text-xs px-8 py-4 pt-4 bg-white">
      <router-link to="/">Home</router-link>
    </div>
    <div class="relative border-t-4 flex-grow">
      <div class="p-8 relative">
        <div class="max-w-xl mx-auto mt-4">
          <div class="mb-8 mt-8 flex items-center justify-center" v-if="noAccess">
            <img class="block h-12 w-12 sm:h-20 sm:w-20 object-contain mx-4" src="../assets/icons/gears-icon.svg" />
            <img class="block h-12 w-12 sm:h-20 sm:w-20 object-contain mx-4" src="../assets/icons/pencil-paper-icon.svg" />
            <img class="block h-12 w-12 sm:h-20 sm:w-20 object-contain mx-4" src="../assets/icons/color-calc-icon.svg" />
          </div>
          <h1 class="font-bold text-2xl mb-8 text-center">Welcome to the<br>Ricoh Consulting Services Apps Portal</h1>

          <div class="bg-white px-6 sm:px-8 py-6 flex items-center shadow rounded border cursor-pointer hover:border-brand-blue" v-if="$store.getters.hasWorkflowAccess" @click="showWorkflows">
            <div class="pr-4 sm:pr-8 flex-shrink-0">
              <img class="block h-12 w-12 sm:h-20 sm:w-20 object-contain" src="../assets/icons/pencil-paper-icon.svg" />
            </div>
            <div>
              <h2 class="text-lg font-bold mb-1">Assessments</h2>
              <p class="text-sm text-gray-500">Assess workflows and recommend solutions to enhance revenue and productivity.</p>
            </div>
            <div class="pl-8 pr-2 sm:pr-4 flex-shrink-0">
              <img class="w-3" src="../assets/icons/chevron-right-icon.svg" />
            </div>
          </div>

          <div class="mt-3 bg-white px-6 sm:px-8 py-6 flex items-center shadow rounded border cursor-pointer hover:border-brand-blue" v-if="$store.getters.hasCalculatorAccess" @click="showCalcs">
            <div class="pr-4 sm:pr-8 flex-shrink-0">
              <img class="block h-12 w-12 sm:h-20 sm:w-20 object-contain" src="../assets/icons/color-calculator-icon.svg" />
            </div>
            <div>
              <h2 class="text-lg font-bold mb-1">Cost Calculators</h2>
              <p class="text-sm text-gray-500">Calculate costs, rates, and prices associated with your production printing operation.</p>
            </div>
            <div class="pl-8 pr-2 sm:pr-4 flex-shrink-0">
              <img class="w-3" src="../assets/icons/chevron-right-icon.svg" />
            </div>
          </div>

          <div class="mt-3 bg-white px-6 sm:px-8 py-6 flex items-center shadow rounded border cursor-pointer hover:border-brand-blue" v-if="$store.getters.hasPrintShopConfigAccess" @click="showPrintShopConfig">
            <div class="pr-4 sm:pr-8 flex-shrink-0">
              <img class="block h-12 w-12 sm:h-20 sm:w-20 object-contain" src="../assets/icons/print-config-icon.svg" />
            </div>
            <div>
              <h2 class="text-lg font-bold mb-1">Print Shop Configurator</h2>
              <p class="text-sm text-gray-500">Configure a Print Shop based on an organization's requirements.</p>
            </div>
            <div class="pl-8 pr-2 sm:pr-4 flex-shrink-0">
              <img class="w-3" src="../assets/icons/chevron-right-icon.svg" />
            </div>
          </div>

          <div class="mt-3 bg-white px-6 sm:px-8 py-6 flex items-center shadow rounded border cursor-pointer hover:border-brand-blue" v-if="$store.getters.hasNeedsAnalysisAccess" @click="showNeedsAnalysis">
            <div class="pr-4 sm:pr-8 flex-shrink-0">
              <img class="block h-12 w-12 sm:h-20 sm:w-20 object-contain" src="../assets/icons/analysis-icon.svg" />
            </div>
            <div>
              <h2 class="text-lg font-bold mb-1">Needs Analysis</h2>
              <p class="text-sm text-gray-500">Identify an organization's needs and requirements.</p>
            </div>
            <div class="pl-8 pr-2 sm:pr-4 flex-shrink-0">
              <img class="w-3" src="../assets/icons/chevron-right-icon.svg" />
            </div>
          </div>

<!--          <div class="mt-3 bg-white px-6 sm:px-8 py-6 flex items-center shadow rounded border cursor-pointer hover:border-brand-blue" v-if="$store.getters.hasMailingAccess" @click="showMailing">-->
<!--            <div class="pr-4 sm:pr-8 flex-shrink-0">-->
<!--              <img class="block h-12 w-12 sm:h-20 sm:w-20 object-contain" src="../assets/icons/mail-icon.svg" />-->
<!--            </div>-->
<!--            <div>-->
<!--              <h2 class="text-lg font-bold mb-1">Mail Assessments</h2>-->
<!--              <p class="text-sm text-gray-500">Assess mail operations and recommend solutions to enhance revenue and productivity.</p>-->
<!--            </div>-->
<!--            <div class="pl-8 pr-2 sm:pr-4 flex-shrink-0">-->
<!--              <img class="w-3" src="../assets/icons/chevron-right-icon.svg" />-->
<!--            </div>-->
<!--          </div>-->

          <div class="mt-3 bg-white px-6 py-6 shadow rounded border cursor-pointer hover:border-brand-blue" v-if="noAccess">

            <div>
              <p class="text-sm text-gray-500 text-center">It looks like your account does not have access to any of our productivity applications yet. Contact your Ricoh representative to upgrade your account.</p>
            </div>

          </div>

        </div>
        <footer class="mt-4 w-full text-center text-sm text-gray-400 p-2"><p>&copy;2021 Ricoh USA, Inc. All Rights Reserved.</p></footer>
      </div>

    </div>


  </div>
</template>

<script>
export default {
  name: "home",
  props: {},
  data: function () {
    return {}
  },
  methods: {
    showWorkflows: function(){
        this.$router.push({
          name: "Assessments"
        })
    },

    showCalcs: function(){
      this.$router.push({
        name: "Calculators"
      })

    },
    showPrintShopConfig: function(){
      this.$router.push({
        name: "PrintShopConfigurator"
      })

    },

    showMailing: function(){
      this.$router.push({
        name: "MailingAssessments"
      })

    },

    showNeedsAnalysis: function(){
      this.$router.push({
        name: "NeedsAnalysis"
      })

    }
  },
  computed: {
    noAccess: function(){
      return !(this.hasCalculatorAccess || this.hasWorkflowAccess || this.hasMailingAccess)
    },

    hasWorkflowAccess: function(){
      return this.$store.getters.hasWorkflowAccess;
    },

    hasMailingAccess: function(){
      return this.$store.getters.hasMailingAccess;
    },

    hasCalculatorAccess: function(){
      return this.$store.getters.hasCalculatorAccess;
    },

    hasNeedsAnalysisAccess: function(){
      return this.$store.getters.hasNeedsAnalysisAccess;
    }
  }
}
</script>

<style scoped>


.home {

}

</style>
