export default {



    generateSummary: function(details){
        //console.log("generateSummary");
        var requiredDetails = [];
        var specs = [];

        let modelList = Object.keys(this.equipment).map((modelId)=>{
            return this.equipment[modelId]
        });

        let detailMap = {}
        this.siteFunctionDetails.forEach((detail) => {
            if(detail.type == "map"){
                var options = detail.options;
                options.forEach(function(option){
                    detailMap[option.id] = option;
                })
            }else{
                detailMap[detail.id] = detail;
            }
        })

        //isolate the requirements
        for(let detailId in details){



            if(details[detailId] === true){
                requiredDetails.push(detailId);
            }else if( typeof(details[detailId]) == "object" ){

                var nestedDetails = details[detailId];
                for(var nestedId in nestedDetails){
                    if(nestedDetails[nestedId] == true){
                        requiredDetails.push(nestedId);
                    }else{
                        if(nestedId.endsWith('ColorPct')){
                            requiredDetails.push(nestedId);
                        }
                    }
                }
            }else{
                //console.log("possible spec", detailId, details[detailId]);
                //Specs are site function number or string value fields. -- they do not pertain to each device

                if(details[detailId]){

                    //console.log('spec', detailId,details[detailId],_detailMap[detailId]);

                    if(detailId.endsWith('ColorPct')){
                        requiredDetails.push(detailId);
                    }else{
                        specs.push({
                            id: detailId,
                            value: details[detailId],
                            label: detailMap[detailId].label
                        })
                    }





                }
            }
        }

        var clashingRequirements = requiredDetails.some((detailId) => {
            let detailExcludeSwitches = detailMap[detailId].excludes;
            if(detailExcludeSwitches){
                let clash = detailExcludeSwitches.some((exclusionId) => {
                    return (requiredDetails.indexOf(exclusionId) > -1);
                })

                if(clash){
                    return true;
                }
            }

        })


        var eligibleModels = [];

        if(!clashingRequirements){
            eligibleModels = modelList.filter(function(model){
                let ineligible = requiredDetails.find(function(detail){
                    return model.excludedBy.indexOf(detail) > -1;
                })

                return !ineligible;


            })
        }else{
            console.log("Clashing requirements, so no models recommeded for Mailing")
        }

        eligibleModels.sort((a,b) => {
            return (a.volumeMax - b.volumeMax)
        })






        var modelMultiplier = 1;
        var shifts = 1;

        let volumes = {
            env10: parseInt(details.env10mailings) * parseInt(details.env10runQuantity),
            env6x9: parseInt(details.env6x9mailings) * parseInt(details.env6x9runQuantity),
            flat: parseInt(details.flatMailings) * parseInt(details.envFlatRunQuantity),
        }

        volumes.total = volumes.env10 + volumes.env6x9 + volumes.flat;
        volumes.jobs = parseInt(details.env10mailings) + parseInt(details.env6x9mailings) + parseInt(details.flatMailings);



        if(volumes.total){

            let inserts = {
                env10: 1,
                env6x9: 7,
                flat: parseInt(details.envFlatInserts)
            }

            //console.log("details", details);

            for(let i=1; i<8; i++){
                //console.log(i, details.env10inserts['env10inserts'+i]);
                if(details.env10inserts['env10inserts'+i]){
                    inserts.env10 = i;
                    //console.log("inserts env 10", i)
                    break;
                }
            }

            for(let i=8; i<13; i++){
                if(details.env6x9inserts['env6x9inserts'+i]){
                    inserts.env6x9 = i;
                    //console.log("inserts env 6x9", i)
                    break;
                }
            }

            volumes.env10Sheets = volumes.env10 * inserts.env10;
            volumes.env6x9Sheets = volumes.env6x9 * inserts.env6x9;
            volumes.flatSheets = volumes.flat * inserts.flat;

            volumes.env10ColorImages = parseInt(volumes.env10Sheets * parseInt(details.env10insertsColorPct) / 100.0) * 2;
            volumes.env6x9ColorImages = parseInt(volumes.env6x9Sheets * parseInt(details.env6x9insertsColorPct) / 100.0) * 2;
            volumes.flatColorImages = parseInt(volumes.flatSheets * parseInt(details.envFlatInsertsColorPct) / 100.0) * 2;

            volumes.totalSheets = volumes.env10Sheets + volumes.env6x9Sheets + volumes.flatSheets;

            volumes.totalColorImages = volumes.env10ColorImages + volumes.env6x9ColorImages + volumes.flatColorImages;
            volumes.totalBWImages = (volumes.totalSheets * 2) - volumes.totalColorImages;


        }


        let processingTimes = eligibleModels.map((model) => {


            let stats = {
                model: model
            }

            if(volumes.env10){
                stats.env10Setup = parseInt(details.env10mailings) * model.setupTime / 60.0;
                let env10speed = model.volume;
                let env10SpeedAdjustment = requiredDetails.reduce((total, requirement) => {
                    if(model.speedAdjustments[requirement]){
                        if(requirement.startsWith('env10')){
                            total -= model.speedAdjustments[requirement];
                        }
                    }
                }, 1.0);

                stats.env10Runtime = volumes.env10 / (model.volume * (env10speed  * env10SpeedAdjustment));
            }else{
                stats.env10Setup = 0;
                stats.env10Runtime = 0;
            }

            if(volumes.env6x9){
                stats.env6x9Setup = parseInt(details.env6x9mailings) * model.setupTime / 60.0;
                let env6x9speed = model.volume;
                let env6x9SpeedAdjustment = requiredDetails.reduce((total, requirement) => {
                    if(model.speedAdjustments[requirement]){
                        if(requirement.startsWith('env6x9')){
                            total -= model.speedAdjustments[requirement];
                        }
                    }
                }, 1.0);

                stats.env6x9Runtime = volumes.env6x9 / (model.volume * (env6x9speed  * env6x9SpeedAdjustment));
            }else{
                stats.env6x9Setup = 0;
                stats.env6x9Runtime = 0;
            }

            if(volumes.flat){
                stats.flatSetup = parseInt(details.flatMailings) * model.setupTime / 60.0;
                let flatSpeed = model.volume;
                let flatSpeedAdjustment = requiredDetails.reduce((total, requirement) => {
                    if(model.speedAdjustments[requirement]){
                        if(requirement.startsWith('flat')){
                            total -= model.speedAdjustments[requirement];
                        }
                    }
                }, 1.0);

                stats.flatRuntime = volumes.flat / (model.volume * (flatSpeed  * flatSpeedAdjustment));
            }else{
                stats.flatSetup = 0;
                stats.flatRuntime = 0;
            }

            stats.totalTime = stats.flatSetup + stats.flatRuntime + stats.env10Setup + stats.env10Runtime + stats.env6x9Setup + stats.env6x9Runtime;
            return stats

        });

        processingTimes.sort(function(a,b){
           return a.totalTime - b.totalTime;
        })

        //console.log("processing time", processingTimes)

        let volumeEligibleModels = processingTimes.filter((processingTime) => {
            return (volumes.total < processingTime.model.volumeMax);
        })

        var bestModel = volumeEligibleModels.length?volumeEligibleModels[0].model:null;
        // if(!bestModel){
        //     bestModel = processingTimes.length?processingTimes[0]:null;
        // }


        if(!bestModel && processingTimes.length){ //doesn't have a volume eligible model

            bestModel = processingTimes.length?processingTimes[0].model:null;
            modelMultiplier = Math.ceil(volumes.total / (bestModel.volumeMax * 1.0));
            shifts = 1;
        }

        var totalFootprint = this.modelFootprint(bestModel, requiredDetails) * modelMultiplier;

        //var prefix = `${modelMultiplier} `;
        // var suffix = `: ${totalFootprint} sq ft.`
        //
        // if(shifts > 1){
        //     suffix = " (2 shifts)" + suffix;
        // }

        var models = [];

        if(bestModel){











                let modelOptions = requiredDetails.reduce((reqDetails, detailId ) => {

                    reqDetails[detailId] = true;
                    return reqDetails
                }, {})

                let postage = [];
                let flatStiched = false;
                let addtionalOps = []

                let optionNames = [];

                Object.keys(modelOptions).forEach((option) => {
                    if(modelOptions[option]){

                        if(option.toLowerCase().includes("postage")){
                            if(!postage.includes(detailMap[option].label)){
                                postage.push(detailMap[option].label)
                            }
                        }else if(option === "envFlatStiched"){
                            flatStiched = true;
                        }else if(detailMap[option].addon){
                            addtionalOps.push(detailMap[option].summaryLabel?detailMap[option].summaryLabel:detailMap[option].label);
                        }

                    }
                })

                let commaList = (list)=>{
                    if(list && list.length){
                        if(list.length > 2){
                            return list.slice(0,-1).join(", ") + " and " + list.slice(-1);
                        }if(list.length === 2){
                            return list.join(" and ");
                        }else{
                            return list[0]
                        }
                    }
                }

                if(volumes.totalSheets){
                    let mailings = [];
                    if(volumes.env10Sheets){
                        mailings.push("#10")
                    }
                    if(volumes.env6x9Sheets){
                        mailings.push("6x9")
                    }
                    if(volumes.flatSheets){
                        mailings.push("Flat")
                    }

                    optionNames.push(`${commaList(mailings)} Mailings`)

                    if(postage.length){
                        optionNames.push(commaList(postage))
                    }

                    if(flatStiched){
                        this.question.configurator.detailMapPlusOptions.envFlatStiched.label
                    }

                }

                console.log(detailMap, addtionalOps);

                let optionsList = optionNames.concat(addtionalOps);

                let _modelFootprint = this.modelFootprint(bestModel, requiredDetails);

                // if(modelMultiplier > 10){
                //     console.log("Too many models needed...");
                //     modelMultiplier = 1;
                // }




            //for(let i=0; i<modelMultiplier; i++){
                let modelInstance = {
                    quantity: modelMultiplier,
                    model: bestModel,
                    shifts: shifts,
                    options: modelOptions,
                    optionNames: optionsList.join(", "),
                    footprint: _modelFootprint,
                    name: ""
                }

                models.push(modelInstance);
            //}
        }


        //return [bestModel?(prefix+bestModel.name+suffix):"No model fits these specs"];

        var recommendationWarning = bestModel?undefined:"No single device can fulfill the specified requirements. Use the customize button to set up a custom configuration.";

        var report =  {
            model: bestModel?bestModel:{name: "No model available for these specs"},
            models: models,
            volume: volumes.total,
            volumes: volumes,
            numOfModels: bestModel?modelMultiplier:undefined,
            numOfShifts: bestModel?shifts:undefined,
            footprint: totalFootprint,
            requirements: requiredDetails.map((detailId) => {
                return detailMap[detailId];
            }),
            specs: specs,
            staffing: 0,
            classification: "siteFunction",
            description: volumes.total.toLocaleString() + " "+(bestModel?(bestModel.volumeSuffix?bestModel.volumeSuffix:"Mailings per month"):""),
            volumeSuffix: (bestModel?(bestModel.volumeSuffix?bestModel.volumeSuffix:"Mailings per month"):""),
            summaryLabel: "Equipment Details",
            recommendationWarning: recommendationWarning

        }

        //console.log("report", report)


        if(report.volume){
            //console.log(summary);
            return report;
        }else{
            return undefined
        }


    },


    modelFootprint: function ( model, requirements ){

        //console.log("Model footprint calc", specs, requirements);

        if(!model){ return 0 }

        var baseFootage = model.totalFootage;
        var totalFootage = requirements.reduce(function(total, currentId){


            if(model.addedFootage[currentId]){
                return (total + model.addedFootage[currentId]);
            }else{
                return total + 0;
            }

        },baseFootage)

        return Math.ceil(totalFootage);
    },

    equipment: {
        DS85: {
            id: 'DS85',
            name: 'DS85',
            ppm: 42.66,
            volume: 2560,
            volume2: 0,
            volumeMax: 358400,
            volumeAbbr: "358k",
            runMin: 0,
            runMax: 15000,
            volumeSuffix: "Mailings per month",
            baseFootage: 60,
            materialFootage: 70,
            totalFootage: 130,
            excludedBy: [
                'flatMailings',
                'flatMailings',
                'envFlatStiched',
                'env10postageInline',
                'env6x9postageInline',
                'envFlatPostageInline',
                'volumeIntegrity',
                'videoRecords',
                'envelopePrinting',
                'trayStacker',
                'rollFeeder'

            ],
            addedFootage: {
                env10postageOffline: 40,
                env6x9postageOffline: 40,
                envFlatPostageOffline: 40,
            },
            setupTime: 5,
            speedAdjustments: {
                env10inserts1: 0.1,
                env10inserts5: 0.1,
                env10inserts6: 0.2,
                env10lbs18: 0.2,
                env6x9lbs18: 0.2,
                envFlatLbs18: 0.2,
                env6x9mailings: 0.3,
                flatMailings: 0.7
            },
        },

        DS1200: {
            id: 'DS1200',
            name: 'DS1200',
            ppm: 128,
            volume: 7680,
            volume2: 0,
            volumeMax: 1075200,
            volumeAbbr: "1M",
            runMin: 3000,
            runMax: 1000000000,
            volumeSuffix: "Mailings per month",
            baseFootage: 225,
            materialFootage: 300,
            totalFootage: 525,
            excludedBy: [
                'envFlatStiched',
                'volumeIntegrity',
                'trayStacker',
                'rollFeeder'
            ],
            addedFootage: {
                env10inserts4: 22,
                env10inserts5: 44,
                env10inserts6: 66,
                env6x9inserts10: 22,
                env6x9inserts11: 44,
                env6x9inserts12: 66,
                envFlatInserts: 100,
                env10postageInline: 22,
                env6x9postageInline: 22,
                envFlatPostageInline: 22,
                printOnEnvelopes: 20,
            },
            setupTime: 7,
            speedAdjustments: {
                env10inserts1: 0.1,
                env10inserts5: 0.1,
                env10inserts6: 0.2,
                env10lbs18: 0.2,
                env6x9lbs18: 0.2,
                envFlatLbs18: 0.2,
                env6x9inserts10: 0.1,
                env6x9inserts11: 0.12,
                env6x9inserts12: 0.15,
                env6x9mailings: 0.3,
                flatMailings: 0.7
            },
        },

        Epic: {
            id: 'Epic',
            name: 'Epic',
            ppm: 224,
            volume: 13440,
            volume2: 0,
            volumeMax: 1881600,
            volumeAbbr: "1.9M",
            runMin: 15001,
            runMax: 1000000000,
            volumeSuffix: "Mailings per month",
            baseFootage: 625,
            materialFootage: 500,
            totalFootage: 1125,
            excludedBy: [
                'differentSizes'
            ],
            addedFootage: {
                env10inserts4: 36,
                env10inserts5: 72,
                env10inserts6: 108,
                env6x9inserts10: 36,
                env6x9inserts11: 72,
                env6x9inserts12: 108,
                envFlatInserts: 100,
                env10postageInline: 22,
                env6x9postageInline: 22,
                envFlatPostageInline: 22,
                envFlatStiched: 30,
                printOnEnvelopes: 20,
                trayStacker: 100,
                rollFeeder: 120
            },
            setupTime: 7,
            speedAdjustments: {
                env10inserts1: 0.1,
                env10inserts5: 0.1,
                env10inserts6: 0.2,
                env10lbs18: 0.2,
                env6x9lbs18: 0.2,
                envFlatLbs18: 0.2,
                env6x9inserts12: 0.05,
                env6x9mailings: 0.3,
                flatMailings: 0.7
            },

        }


    },

    siteFunctionDetails: [
        {
            id: 'mailingsPerMonth',
            type: 'number',
            required: true,
            component: 'PulloutQuestionNumber',
            label: 'Monthly Mailings',
            default: 0,
            description: "Total mailings per month"
        },

        {
            id: 'env10mailings',
            type: 'number',
            required: true,
            component: 'PulloutQuestionNumber',
            label: '#10 envelope mailing jobs per month',
            summaryLabel: "#10 envelope",
            default: 0,
            description: "with 1-6 inserts",
            triggerGroup: "env10",
            triggerCondition: function(ref){
                return (ref.answers.env10mailings > 0)
            },
        },

        {
            id: 'env6x9mailings',
            type: 'number',
            required: true,
            component: 'PulloutQuestionNumber',
            label: '6x9 envelope mailing jobs per month',
            summaryLabel: "6x9 envelope",
            default: 0,
            description: "with 7-12 inserts",
            triggerGroup: "env6x9",
            triggerCondition: function(ref){
                return (ref.answers.env6x9mailings > 0)
            }
        },

        {
            id: 'flatMailings',
            type: 'number',
            required: true,
            component: 'PulloutQuestionNumber',
            label: 'Flat mailing jobs per month',
            summaryLabel: "Flat",
            default: 0,
            description: "over 12 inserts, just needs to be flat",
            excludes: ['DS85'],
            triggerGroup: "envFlat",
            triggerCondition: function(ref){
                return (ref.answers.envFlatMailings > 0)
            }
        },

        {
            id: 'env10inserts',
            type: 'map',
            required: true,
            component: 'PulloutQuestionMultiSwitch',
            label: 'Average amount of inserts in #10 envelope mailings?',
            default: {},
            options: [
                {
                    id: "env10inserts1",
                    label: "1",
                    summaryLabel: "1 insert per #10 envelope",
                    default: false,
                },
                {
                    id: "env10inserts2",
                    label: "2",
                    summaryLabel: "2 inserts per #10 envelope",
                    default: false,

                },
                {
                    id: "env10inserts3",
                    label: "3",
                    summaryLabel: "3 inserts per #10 envelope",
                    default: false,

                },
                {
                    id: "env10inserts4",
                    label: "4",
                    summaryLabel: "4 inserts per #10 envelope",
                    default: false,

                },
                {
                    id: "env10inserts5",
                    label: "5",
                    summaryLabel: "5 inserts per #10 envelope",
                    default: false,

                },
                {
                    id: "env10inserts6",
                    label: "6",
                    summaryLabel: "6 inserts per #10 envelope",
                    default: false,

                }
            ],
            group: "env10"
        },

        {
            id: 'env10insertSize',
            type: 'map',
            required: true,
            component: 'PulloutQuestionMultiSwitch',
            label: 'What sheet size is the typical insert in a #10 envelope mailing',
            default: {},
            options: [
                {
                    id: "env10insertSingle",
                    label: "3.5 x 8.5 or single sheet",
                    summaryLabel: "3.5 x 8.5 or single sheet insert size",
                    default: false,
                    multiplier: 0.3
                },
                {
                    id: "env10insert2panel",
                    label: "7 x 8.5 or 2-panel fold sheet",
                    summaryLabel: "7 x 8.5 or 2-panel fold sheet insert size",
                    default: false,
                    multiplier:1.0
                },
                {
                    id: "env10insert3panel",
                    label: "8.5 x 11 or 3-panel fold",
                    summaryLabel: "8.5 x 11 or 3-panel fold insert size",
                    default: false,
                    multiplier: 1.0
                },
                {
                    id: "env10insert4panel",
                    label: "8.5 x 14 or 4-panel fold",
                    summaryLabel: "8.5 x 14 or 4-panel fold insert size",
                    default: false,
                    multiplier: 1.0
                }
            ],
            group: "env10"
        },

        {
            id: 'env10lbs',
            type: 'map',
            required: true,
            component: 'PulloutQuestionMultiChoice',
            label: 'What is the typical lbs of paper used in #10 envelope mailings?',
            description: "Select any that apply",
            default: {},
            options: [
                {
                    id: "env10lbs18",
                    label: "18# or lighter paper",
                    default: false,
                    multiplier: 0.8
                },
                {
                    id: "env10lbs20",
                    label: "20# paper",
                    default: false,
                    multiplier: 1.0
                },
                {
                    id: "env10lbs24",
                    label: "24# paper",
                    default: false,
                    multiplier: 1.0
                },
                {
                    id: "env10lbs28",
                    label: "28# or heavier paper",
                    default: false,
                    multiplier: 1.0
                },
            ],
            group: "env10"
        },

        {
            id: 'env10insertsColorPct',
            type: 'number',
            required: true,
            component: 'PulloutQuestionNumber',
            label: 'Percentage of color inserts in #10 envelope mailings?',
            summaryLabel: "% color inserts",
            default: 0,
            description: "",
            group: "env10",
            suffix: "%"
        },

        {
            id: 'env10runQuantity',
            type: 'number',
            required: true,
            component: 'PulloutQuestionNumber',
            label: 'Average average run quantity in #10 envelope mailings?',
            default: 0,
            description: "",
            group: "env10"
        },

        {
            id: 'env10postage',
            type: 'map',
            required: true,
            component: 'PulloutQuestionMultiChoice',
            label: 'What is the typical method of postage used in #10 mailings?',
            description: "Select any that apply",
            default: {},
            options: [
                {
                    id: "env10postageStamps",
                    label: "Stamped postage",
                    default: false,

                },
                {
                    id: "env10postageInline",
                    label: "Metered in-line postage",
                    default: false,

                },
                {
                    id: "env10postageOffline",
                    label: "Metered off-line postage",
                    default: false,

                },
                {
                    id: "env10postageIndicia",
                    label: "Indicia postage",
                    default: false,

                },
            ],
            group: "env10"
        },

        {
            id: 'env6x9inserts',
            type: 'map',
            required: true,
            component: 'PulloutQuestionMultiSwitch',
            label: 'Average amount of inserts in #10 envelope mailings?',
            default: {},
            options: [
                {
                    id: "env6x9inserts7",
                    label: "7",
                    summaryLabel: "7 inserts per 6x9 envelope",
                    default: true,
                },
                {
                    id: "env6x9inserts8",
                    label: "8",
                    summaryLabel: "8 inserts per 6x9 envelope",
                    default: false,

                },
                {
                    id: "env6x9inserts9",
                    label: "9",
                    summaryLabel: "9 inserts per 6x9 envelope",
                    default: false,

                },
                {
                    id: "env6x9inserts10",
                    label: "10",
                    summaryLabel: "10 inserts per 6x9 envelope",
                    default: false,

                },
                {
                    id: "env6x9inserts11",
                    label: "11",
                    summaryLabel: "11 inserts per 6x9 envelope",
                    default: false,

                },
                {
                    id: "env6x9inserts12",
                    label: "12",
                    summaryLabel: "12 inserts per 6x9 envelope",
                    default: false,

                }
            ],

        },

        {
            id: 'env6x9insertSize',
            type: 'map',
            required: true,
            component: 'PulloutQuestionMultiSwitch',
            label: 'What sheet size is the typical insert in a 6x9 envelope mailing',
            default: {},
            options: [
                {
                    id: "env6x9insertSingle",
                    label: "5.5 x 8.5 or single sheet",
                    default: false,
                    multiplier: 0.5
                },
                {
                    id: "env6x9insert2panel",
                    label: "8.5 x 11 fold to 5.5 x 8.5 2-panel ",
                    default: false,
                    multiplier:1.0
                },
                {
                    id: "env6x9insert3panel",
                    label: " 8.5 x 14 fold to 5.5 x 8.5 3-panel",
                    default: false,
                    multiplier: 1.0
                }

            ],
            group: "env6x9"
        },

        {
            id: 'env6x9lbs',
            type: 'map',
            required: true,
            component: 'PulloutQuestionMultiChoice',
            label: 'What is the typical lbs of paper used in 6x9 mailings?',
            description: "Select any that apply",
            default: {},
            options: [
                {
                    id: "env6x9lbs18",
                    label: "18# or lighter paper",
                    default: false,
                    multiplier: 0.8
                },
                {
                    id: "env6x9lbs20",
                    label: "20# paper",
                    default: false,
                    multiplier: 1.0
                },
                {
                    id: "env6x9lbs24",
                    label: "24# paper",
                    default: false,
                    multiplier: 1.0
                },
                {
                    id: "env6x9lbs28",
                    label: "28# or heavier paper",
                    default: false,
                    multiplier: 1.0
                },
            ],
            group: "env6x9"
        },

        {
            id: 'env6x9insertsColorPct',
            type: 'number',
            required: true,
            component: 'PulloutQuestionNumber',
            label: 'Percentage of color inserts in 6x9 envelope mailings?',
            summaryLabel: "% color inserts",
            default: 0,
            description: "",
            group: "env6x9",
            suffix: "%"
        },

        {
            id: 'env6x9runQuantity',
            type: 'number',
            required: true,
            component: 'PulloutQuestionNumber',
            label: 'Average average run quantity in 6x9 envelope mailings?',
            default: 0,
            description: "",
            group: "env6x9"
        },

        {
            id: 'env6x9postage',
            type: 'map',
            required: true,
            component: 'PulloutQuestionMultiChoice',
            label: 'What is the typical method of postage used in 6x9 mailings?',
            description: "Select any that apply",
            default: {},
            options: [
                {
                    id: "env6x9postageStamps",
                    label: "Stamp postage",
                    default: false,

                },
                {
                    id: "env6x9postageInline",
                    label: "Metered in-line postage",
                    default: false,

                },
                {
                    id: "env6x9postageOffline",
                    label: "Metered off-line postage",
                    default: false,

                },
                {
                    id: "env6x9postageIndicia",
                    label: "Indicia postage",
                    default: false,

                },
            ],
            group: "env6x9"
        },

        {
            id: 'envFlatInserts',
            type: 'number',
            required: true,
            component: 'PulloutQuestionNumber',
            label: 'Average amount of insert sheets in flat mailings?',
            summaryLabel: " inserts per flat envelope",
            default: 13,
            description: "A number that is 13 or more",
            group: "envFlat"
        },

        {
            id: 'envFlatInsertsColorPct',
            type: 'number',
            required: true,
            component: 'PulloutQuestionNumber',
            label: 'Percentage of color inserts in flat mailings?',
            summaryLabel: '% color inserts',
            default: 0,
            description: "",
            group: "envFlat",
            suffix: "%"
        },

        {
            id: 'envFlatLbs',
            type: 'map',
            required: true,
            component: 'PulloutQuestionMultiChoice',
            label: 'What is the typical lbs of paper used in 6x9 mailings?',
            description: "Select any that apply",
            default: {},
            options: [
                {
                    id: "envFlatLbs18",
                    label: "18# or lighter paper",
                    default: false,
                    multiplier: 0.8
                },
                {
                    id: "envFlatLbs20",
                    label: "20# paper",
                    default: false,
                    multiplier: 1.0
                },
                {
                    id: "envFlatLbs24",
                    label: "24# paper",
                    default: false,
                    multiplier: 1.0
                },
                {
                    id: "envFlatLbs28",
                    label: "28# or heavier paper",
                    default: false,
                    multiplier: 1.0
                },
            ],
            group: "envFlat"
        },

        {
            id: 'envFlatStiched',
            type: 'boolean',
            required: true,
            component: 'PulloutQuestionSwitch',
            label: 'Are there requirements for stitched material?',
            summaryLabel: "Stitched material",
            default: false,
            group: "envFlat"
        },

        {
            id: 'envFlatRunQuantity',
            type: 'number',
            required: true,
            component: 'PulloutQuestionNumber',
            label: 'Average average run quantity in flat mailings?',
            default: 0,
            description: "",
            group: "envFlat"
        },

        {
            id: 'envFlatPostage',
            type: 'map',
            required: true,
            component: 'PulloutQuestionMultiChoice',
            label: 'What is the typical method of postage used in flat mailings?',
            description: "Select any that apply",
            default: {},
            options: [
                {
                    id: "envFlatPostageStamps",
                    label: "Stamp postage",
                    default: false,

                },
                {
                    id: "envFlatPostageInline",
                    label: "Metered in-line postage",
                    default: false,

                },
                {
                    id: "envFlatPostageOffline",
                    label: "Metered off-line postage",
                    default: false,

                },
                {
                    id: "envFlatPostageIndicia",
                    label: "Indicia postage",
                    default: false,

                },
            ],
            group: "envFlat"
        },
        {
            id: 'additionalEquipment',
            type: 'map',
            required: true,
            component: 'PulloutQuestionMultiChoice',
            label: 'Additional Equipment Accessories',
            default: {},
            options: [
                {
                    id: "videoRecords",
                    label: "Is there a need for compliance confirmation and video records?",
                    default: false,
                    summaryLabel: "Cameras and video capturing equipment",
                    excludes: ["DS85","DS1200"],
                    addon: true
                },
                {
                    id: "volumeIntegrity",
                    label: "Closed loop verification for volume integrity",
                    default: false,
                    summaryLabel: "JetVision inspection system",
                    excludes: ["DS85"],
                    addon: true
                },
                {
                    id: "scanningSecurity",
                    label: "1-D & 2-D scanning for document control and security",
                    summaryLabel: "Barcode scanning equipment",
                    default: false,
                    addon: true
                },
                {
                    id: "envelopePrinting",
                    label: "Is there a need to print on the envelopes?",
                    default: false,
                    summaryLabel: "In-line inkjet envelope printing equipment",
                    excludes: ["DS85"],
                    addon: true
                },
                {
                    id: "trayStacker",
                    label: "Large volumes (>5k) of presorted mail will require a tray stacker & sleeve bander",
                    default: false,
                    summaryLabel: "Sleave banding and tray stacking equipment",
                    excludes: ["DS85","DS1200"],
                    addon: true
                },
                {
                    id: "rollFeeder",
                    label: "Large  volume per mailings (>50k) would dictate having a paper roll feeder with sheet conversion",
                    default: false,
                    summaryLabel: "Paper roll feeder with sheet conversion equipment",
                    excludes: ["DS85","DS1200"],
                    addon: true

                },
                {
                    id: "differentSizes",
                    label: "Must mail several different size envelopes each month?",
                    default: false,
                    summaryLabel: "Multiple mailing sizes per month",
                    excludes: ["Epic"],
                    addon: true
                }


            ]
        },



    ]
}

