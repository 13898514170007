


<template>
<div>

  <h4>ObjectifLune PReS Connect</h4>
  <hr>
  <p>PReS Connect provides a unique set of features, tools and benefits to customers looking to automate and improve on business processes.  With a track record of over 25 years, PlanetPress brings proven functionality and reliability to your print and workflow needs.</p>
  <p>By combining a robust design environment with a powerful workflow tool within the same license, PReS Connect offers users a highly capable toolset that can handle most any type of repetitive job in an efficient and automated way.  PReS Connect supports high volume print output and includes AFP/IPDS output for roll fed printers.</p>
  <p>PReS Connect is a software solution that completely automates outbound communications for high-volume business communications.</p>
  <p>It is a solution for the entire communication process from document composition and enhancement to distribution and transmission via any channel, to archiving and reporting. Everything can be automated, and with the ability to go digital every step of the way.</p>
  <p>PReS Connect can redesign all outgoing documents to ensure a modern, consistent look and can output the resulting files to any third-party system or channel.</p>

  <p class="bold-label">Key Features:</p>
  <ul>
    <li>Standalone software Designer easy to learn and work with thanks to drag and drop and wizards</li>
    <li>Advanced capabilities for experts (HTML, CSS, Javascript)</li>
    <li>A multichannel solution: create content once, and use for multiple purposes and formats (Print, Email, Web, SMS, Mobile)</li>
    <li>Accepts nearly any type of data input including a wide range of print streams</li>
    <li>User-friendly interface</li>
    <li>Easy workflow tools for automated workflows</li>
    <li>Wide variety of printing options (paper handling, finishing and imposition)</li>
    <li>Optimized output (PostScript, PCL, PDF, PPML and more)</li>
    <li>AFP/IPDS Output</li>
    <li>Print Management Dashboard</li>
    <li>AFP Input (Optional)</li>
    <li>PCL Input (Optional)</li>
    <li>PDF tools</li>
    <li>Inserter controls </li>
    <li>Multilingual interface</li>

  </ul>
  <p></p>

  <p class="bold-label"><b>Customer Benefits:</b></p>
  <p class="bold-label">Standalone Multichannel Designer</p>
  <ul>
    <li>Enables complete flexibility with document layout and host systems</li>
    <li>Supports highly dynamic, rules driven elements</li>
  </ul>

  <p class="bold-label">Powerful Workflow/Automation tool is included</p>
  <ul>
    <li>Implementation of automated workflows enables greater workplace productivity</li>
    <li>Eliminates labor-intensive manual tasks</li>
    <li>Moving to digital enables automated processes to deliver a wealth of data that can be used for process improvement and optimization as well as providing data for compliance and audit.</li>
  </ul>

  <p class="bold-label">Enhanced communication</p>
  <ul>
    <li>Ability to improve the customer experience by introducing interactive documents, personalized web portals, payment buttons, and SMS messages</li>
    <li>Enhanced reliability of data and integrity of documents</li>
  </ul>

  <p class="bold-label">Reduced labor costs</p>
  <ul>
    <li>Reduction of manual labor (Manual handling of documents)</li>
    <li>Elimination of human error</li>
    <li>Improved staff productivity and efficiency</li>
  </ul>

  <p class="bold-label">Reduced mail costs</p>
  <ul>
    <li>Postal discounts (requires third party tools)</li>
    <li>Reduced number of pages printed</li>
    <li>Mailings to the same recipient combined (householding)</li>
    <li>Archiving (document management system)</li>
  </ul>

  <p class="bold-label">“Black box” solution</p>
  <ul>
    <li>Can be configured to run entirely in the background</li>
    <li>Ability to make improvements without changing core applications</li>
    <li>No change to current staff work routines</li>
    <li>Integration with existing systems</li>
    <li>Printer agnostic</li>
  </ul>

  <p class="bold-label">Scalable solution</p>
  <ul>
    <li>Ability to grow with your company</li>
    <li>Natively supports a primary/secondary server configuration to allow multiple instances of PReS Connect to work as one.</li>
  </ul>

</div>
</template>

<script>
export default {
    name: "pres-connect",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


</style>
