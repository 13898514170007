<template>
  <div class="detail-summary">
    <p class="spec-item">{{ detailSummary.volumes.total | decimal(0) }} Mailings per month</p>
    <p class="spec-item">{{ detailSummary.volumes.totalSheets | decimal(0) }} Sheets per month</p>
    <p class="spec-item">{{ detailSummary.volumes.totalBWImages | decimal(0) }} B/W mail insert images per month</p>
    <p class="spec-item">{{ detailSummary.volumes.totalColorImages | decimal(0) }} Color mail insert images per month</p>
    <template v-if="env10">
      <p class="requirement-item">+ #10 Mailings ({{ commaList(reqValues(groupedReqs.env10)) }})</p>
    </template>
    <template v-if="env6x9">
      <p class="requirement-item">+ 6x9 Mailings ({{ commaList(reqValues(groupedReqs.env6x9)) }})</p>
    </template>
    <template v-if="flat">
      <p class="requirement-item">+ Flat Mailings ({{ commaList(reqValues(groupedReqs.flat)) }})</p>
    </template>

    <p class="requirement-item" v-for="req in groupedReqs.additional" :key="req.id">+ {{ req.summaryLabel?req.summaryLabel:req.label }}</p>

    <div class="recommendation">
      <div class="recommendation-label" v-if="detailSummary"><h5>{{ detailSummary.summaryLabel }} </h5><button v-if="detailSummary.models" class="button text-xs px-2 py-1 modify-button reversed" @click="onModifyClick">CUSTOMIZE</button></div>

      <div class="model-configurations" v-if="customConfiguration">
        <div class="model-summary" v-for="(model,index) in customConfiguration.models" :key="'m'+index">
          <p class="model-name"><span>{{ model.quantity?model.quantity:1 | decimal(0) }}</span> {{ model.model.name }} <span v-if="(model.shifts > 1)">running {{ model.shifts }} shifts</span><span class="footage"> ({{ model.footprint }} sq ft.)</span></p>
          <p class="model-options">
            <template v-for="(option, index) in filteredModelOptions(model)">
              <span class="model-option" :key="index">{{ option }}</span>
            </template>
          </p>

        </div>
        <p class="staffing" v-if="detailSummary.staffing">{{ detailSummary.staffing }} employee(s)</p>
      </div>
      <div class="model-configurations" v-else-if="detailSummary">
        <p class="model-name" v-if="detailSummary.recommendationWarning">{{ detailSummary.recommendationWarning }}</p>
        <div v-if="detailSummary.models">
          <div class="model-summary" v-for="(model,index) in detailSummary.models" :key="'m'+index">
            <p class="model-name"><span>{{ model.quantity?model.quantity:1 | decimal(0) }}</span> {{ model.model.name }} <span v-if="(model.shifts > 1)">running {{ model.shifts }} shifts</span><span class="footage"> ({{ model.footprint }} sq ft.)</span></p>
            <p class="model-options">
              <template  v-for="(option, index) in filteredModelOptions(model)">
                <span class="model-option" :key="index">{{ option }}</span>
              </template>
            </p>
          </div>
        </div>

        <p class="staffing" v-if="detailSummary.staffing">{{ detailSummary.staffing }} employee(s)</p>
        <p class="staffing" v-if="!detailSummary.models">{{ detailSummary.footprint }} sq ft</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "EquipmentDetailSummaryMailing",
  props: {
    question: {
      type: Object,
      required: true
    },
    answer: {
      type:Object,
      required: true
    },
    detailSummary: {
      required: true
    },
    customConfiguration: {
      required: false
    }
  },
  methods: {


    onModifyClick: function(){
      this.$emit("modify");
    },

    reqValues: function(reqList){
      return reqList.map((req) => {



        if(req.type === "number"){
          let value = this.answer.details[req.id];
          return `${value}${req.summaryLabel?req.summaryLabel:req.label}`;
        }else{
          return req.summaryLabel?req.summaryLabel:req.label;
        }

      })
    },

    commaList: function(list){
      if(list && list.length){
        if(list.length > 2){
          return list.slice(0,-1).join(", ") + " and " + list.slice(-1);
        }if(list.length === 2){
          return list.join(" and ");
        }else{
          return list[0]
        }
      }
    },

    filteredModelOptions: function(model){

      let postage = [];
      let flatStiched = false;

      let options = [];

      Object.keys(model.options).forEach((option) => {
        if(model.options[option]){

          if(option.toLowerCase().includes("postage")){
            if(!postage.includes(this.question.configurator.detailMapPlusOptions[option].label)){
              postage.push(this.question.configurator.detailMapPlusOptions[option].label)
            }
          }else if(option === "envFlatStiched"){
            flatStiched = true;
          }
        }
      })



      if(this.mailingReqs.length){
        options.push(`${this.commaList(this.mailingReqs)} mailings`);

        if(postage.length){
          options.push(this.commaList(postage))
        }

        if(flatStiched){
          this.question.configurator.detailMapPlusOptions.envFlatStiched.label
        }
      }

      this.groupedReqs.additional.forEach((additional) => {
        options.push(additional.summaryLabel?additional.summaryLabel:additional.label)
      })

      return options;
    }

  },
  computed: {
    isActive: function(){
      return this.answer.value;
    },

    env10: function(){
      return this.detailSummary.volumes.env10;
    },

    env6x9: function(){
      return this.detailSummary.volumes.env6x9;
    },

    flat: function(){
      return this.detailSummary.volumes.flat;
    },

    mailingReqs: function(){
      let reqs = [];
      if(this.env10){
        reqs.push("#10")
      }

      if(this.env6x9){
        reqs.push("6x9")
      }

      if(this.flat){
        reqs.push("Flat")
      }

      return reqs;
    },

    groupedReqs: function(){

      let reqs = {
        env10: [],
        env6x9: [],
        flat: [],
        additional: []
      }

      this.detailSummary.requirements.forEach((req) => {
        if(req.id === 'env10mailings' ){
          return;
        }

        if(req.id === 'env6x9mailings' ){
          return;
        }

        if(req.id === 'flatMailings' ){
          return;
        }

        if(req.id === 'mailingsPerMonth' ){
          return;
        }

        if (req.id.startsWith('env10')){
            reqs.env10.push(req);
        }else if(req.id.startsWith('env6x9')){
          if(req.id !== 'env6x9mailings'){
            reqs.env6x9.push(req);
          }
        }else if(req.id.startsWith('envFlat')){
            reqs.flat.push(req);
        }else{
          reqs.additional.push(req);
        }
      })

      return reqs

    },
  }
}
</script>

<style scoped>

.detail-summary {
  padding: 0 3em;

}

.modify-button {
  position: relative;
  font-size: 0.5em;
  margin: 0 1em;

}

.spec-item {
  color: var(--near-black-2);
  display: block;
  font-weight: bold;
  font-size: 0.8em;
}

.requirement-item {
  color: var(--near-black-2);
  display: block;
  font-size: 0.8em;
  margin-top: 0.5em;
}

.recommendation {
  margin-top: 1em;
}

.recommendation-label {
  border-bottom: 1px dashed var(--mid-gray);
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  display: flex;
}

.model-name {
  padding-top: 0.5em;
  font-size: 0.8em;
  font-weight: bold;
  color: var(--near-black-2);
}

.footage {
  padding-top: 0.5em;
  font-size: 0.8em;
  color: var(--near-black-2);
}

.staffing {
  padding-top: 0.5em;
  font-size: 0.8em;
  color: var(--near-black-2);
}

.model-configurations {

  position: relative;
}

.model-summary {
  margin: 0.5em 0;
  width: 48%;
  margin-right: 1%;
}

.model-options {
  font-size: 0.6em;
  color: var(--near-black-2);
}

.model-option {
  display: block;
  margin-top: 0.5em;
}

.model-option:before {
  content: "+ ";

}

.model-option:last-child:after {
  content: ""
}

</style>
