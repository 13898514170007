<template>
    <div class="pullout-question-multi-switch-list">
        <form-question-label class="question-label" >{{ detail.label }}</form-question-label>
        <div class="box-container">
            <pullout-question-multi-switch-list-item class="switch-box" v-for="option in options" :key="option.id" :label="option.label" :description="option.description" :checked="(option.value == answers[detail.id])" @click.native="onChangeValue(option.value)"></pullout-question-multi-switch-list-item>
        </div>
    </div>
</template>

<script>

    import FormQuestionLabel from "./FormQuestionLabel.vue";
    import PulloutQuestionMultiSwitchListItem from "./PulloutQuestionMultiSwitchListItem.vue";


    export default {
        name: "pullout-question-multi-switch-list",
        components: {
            PulloutQuestionMultiSwitchListItem,
            FormQuestionLabel
        },
        props: {
            detail: {
                type: Object,
                required: true
            },

            answers: {
                required: true,
            }
        },

        computed: {
            options: function(){
                /* detail options needs to be an array of options:
                [
                    {
                    id: "optionId",
                    label: "Option Label" ,
                    default: false
                    },
                    ...
                 ]

                 */
                return this.detail.options;
            }
        },

        methods: {
            onChangeValue: function(value){

              this.answers[this.detail.id] = value

                console.log("MultiSwitch Click", this.answers[this.detail.id]);

                // let update = {
                //     id: this.detail.id,
                //     value: value
                // }
                //
                //
                // this.$emit('change', update);
            }


        }
    }
</script>

<style scoped>


    .pullout-question-multi-switch-list {

    }

    .box-container {


    }


    .switch-box {
        box-sizing: border-box;
        border-top: 1px solid var(--mid-gray);
    }

    .switch-box:first-child {
        border:none;
    }


</style>
