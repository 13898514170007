


<template>
<div class="config-question-switch-detail" :class="{ 'is-active': this.isActive}">
  <div class="button-items">
    <form-radio-dot :checked="isActive" @click.native="onActiveStatusChange"></form-radio-dot>
    <form-question-label v-bind:class="labelClassObject" >{{ question.label }}</form-question-label>
    <button class="button text-xs py-1 px-2 detail-button action" v-if="isActive" @click="onDetailClick">REQUIREMENTS</button>
  </div>
  <component v-if="detailSummary" :is="question.detailSummaryComponent?question.detailSummaryComponent:'EquipmentDetailSummary'" class="detail-summary" :question="question" :answer="answer" :detail-summary="detailSummary" :custom-configuration="customConfiguration" @modify="onModifyClick" @autoGenerate="onAutoGenerate"></component>
<!--  <div v-if="detailSummary" class="detail-summary">-->

<!--    <p class="spec-item" v-if="detailSummary.description">{{ detailSummary.description }}</p>-->

<!--    <p class="requirement-item" v-for="requirement in detailSummary.requirements" :key="requirement.id">+ {{ requirement.summaryLabel?requirement.summaryLabel:requirement.label }} <span v-if="requirement.description">( {{ requirement.description }} )</span></p>-->
<!--    <div class="recommendation">-->
<!--      <div class="recommendation-label" v-if="detailSummary"><h5>{{ detailSummary.summaryLabel }} </h5><button v-if="detailSummary.models" class="modify-button reversed" @click="onModifyClick">CUSTOMIZE</button></div>-->

<!--      <div class="model-configurations" v-if="customConfiguration">-->
<!--        <div class="model-summary" v-for="(model,index) in customConfiguration.models" :key="'m'+index">-->
<!--          <p class="model-name"><span>1</span> {{ model.model.name }} <span v-if="(model.shifts > 1)">running {{ model.shifts }} shifts</span><span class="footage"> ({{ model.footprint }} sq ft.)</span></p>-->
<!--          <p class="model-options">-->
<!--            <template v-for="(option, index) in model.options">-->
<!--              <span class="model-option"  :key="option.id" v-if="option">{{ question.configurator.detailMapPlusOptions[index].label }}</span>-->
<!--            </template>-->
<!--          </p>-->

<!--        </div>-->
<!--        <p class="staffing" v-if="detailSummary.staffing">{{ detailSummary.staffing }} employee(s)</p>-->
<!--      </div>-->
<!--      <div class="model-configurations" v-else-if="detailSummary">-->
<!--        <p class="model-name" v-if="detailSummary.recommendationWarning">{{ detailSummary.recommendationWarning }}</p>-->
<!--        <div v-if="detailSummary.models">-->
<!--          <div class="model-summary" v-for="(model,index) in detailSummary.models" :key="'m'+index">-->
<!--            <p class="model-name"><span>1</span> {{ model.model.name }} <span v-if="(model.shifts > 1)">running {{ model.shifts }} shifts</span><span class="footage"> ({{ model.footprint }} sq ft.)</span></p>-->
<!--            <p class="model-options">-->
<!--              <template  v-for="(option, index) in model.options">-->
<!--                <span class="model-option" :key="option.id" v-if="option">{{ question.configurator.detailMapPlusOptions[index].label }}</span>-->
<!--              </template>-->
<!--            </p>-->
<!--          </div>-->
<!--        </div>-->

<!--        <p class="staffing" v-if="detailSummary.staffing">{{ detailSummary.staffing }} employee(s)</p>-->
<!--        <p class="staffing" v-if="!detailSummary.models">{{ detailSummary.footprint }} sq ft</p>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

</div>
</template>

<script>
import FormRadioDot from "@/components/PrintConfig/FormRadioDot";
import FormQuestionLabel from "@/components/PrintConfig/FormQuestionLabel";
import EquipmentDetailSummary from "@/components/PrintConfig/EquipmentDetailSummary";
import EquipmentDetailSummaryMailing from "@/components/PrintConfig/EquipmentDetailSummaryMailing";

export default {
    name: "config-question-switch-detail",
  components: {EquipmentDetailSummaryMailing, EquipmentDetailSummary, FormQuestionLabel, FormRadioDot},
  props: {
      question: {
        type: Object,
        required: true
      },
      answer: {
        type:Object,
        required: true
      }
    },
    data: function(){
        return {

        }
        },
    methods: {
      onActiveStatusChange: function(){
        //console.log("change answer active")
        this.answer.value = !this.answer.value;
        //console.log("change answer active", this.answer.value )
        ////console.log("active", value)

        ////console.log("ActiveStatusChange",value);
        // var newValue = this.cloneAnswerState();
        // this.isActive = value;
        // newValue.active = this.isActive;
        // this.$store.dispatch('updateAnswer', {
        //   id: this.question.id,
        //   value: newValue
        // })
      },

      cloneAnswerState: function(){
        if(this.answer){
          var currentAnswerState = this.answer;
          let clonedAnswer = {};
          for( var prop in currentAnswerState ){
            clonedAnswer[prop] = currentAnswerState[prop];
          }
          return clonedAnswer;
        }else{
          return {};
        }
      },

      onDetailClick: function(){
        this.$emit("detail-click",this.question);
      },

      onModifyClick: function() {
        this.$emit("modify-click");
      },

      onAutoGenerate: function(){
        //console.log("Autogenerate", this.answer)
        this.$emit("auto-generate");
        //this.answer.details.customConfiguration = null;
      }
    },
    computed: {
      labelClassObject: function(){
        return {
          "question-label": true,
          "dark": this.isActive
        }

      },

      isActive: function(){
        return this.answer.value;
      },

      detailSummary: function() {
        if(this.isActive && this.question.configurator){

          if(this.answer.details?.customConfiguration){
            //console.log("showing custom config summary");
          }else if(this.question.configurator.recommendation){
            //console.log("showing standard config summary");
          }else{
            //console.log("showing new summary");
          }

          return this.question.configurator.summary(this.answer.details, this.question.configurator.customConfiguration, this.question.id);
        }

        return null;
      },

      customConfiguration: function() {
        //console.log("compute customConfig")
        return this.answer.details?.customConfiguration;
        //return this.question.configurator.customConfiguration;
      },
    }
}
</script>

<style scoped>


.config-question-switch-detail {

}

.button-items {
  display: flex;
  align-items: center;
}

.question-label {
  margin-left: 1em;
  flex-grow: 1;
}

.question-label.dark {
  color: var(--primary-color);
}

.detail-button {
  position: relative;
  font-size: 0.8em;

}

.modify-button {
  position: relative;
  font-size: 0.5em;
  margin: 0 1em;

}

.detail-button:hover {

}

.detail-summary {
  padding: 0 3em;

}

.spec-item {
  color: var(--near-black-2);
  display: block;
  font-weight: bold;
  font-size: 0.8em;
}

.requirement-item {
  color: var(--near-black-2);
  display: block;
  font-size: 0.8em;
  margin-top: 0.5em;
}

.recommendation {
  margin-top: 1em;
}

.recommendation-label {
  border-bottom: 1px dashed var(--mid-gray);
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  display: flex;
}

.model-name {
  padding-top: 0.5em;
  font-size: 0.8em;
  font-weight: bold;
  color: var(--near-black-2);
}

.footage {
  padding-top: 0.5em;
  font-size: 0.8em;
  color: var(--near-black-2);
}

.staffing {
  padding-top: 0.5em;
  font-size: 0.8em;
  color: var(--near-black-2);
}

.model-configurations {

  position: relative;
}

.model-summary {
  margin: 0.5em 0;
  width: 48%;
  margin-right: 1%;
}

.model-options {
  font-size: 0.6em;
  color: var(--near-black-2);
}

.model-option {
  display: block;
  margin-top: 0.5em;
}

.model-option:before {
  content: "+ ";

}

.model-option:last-child:after {
  content: ""
}

</style>
