//import numeral from 'numeral';

/*

Recommendation Configurator using rated input for single item (Low, Medium, High, or N/A)

configuration = {
    requirements: {
        low:{},
        medium: {},
        high: {}
    },

    ratingDetails: [
        { detail definition }
    ]
}

 */

export default function(configuration, classification){


    let finishingDetails = configuration.finishingDetails;
    //let detailMap = {};

    return {
        summary: function(details){
            console.log("details", details, classification);
            //using answer inteface as defined in MainStore answers[questionId].details



            var requiredItems = [];
            let totalFootprint = 0;
            let totalStaffing = 0;

            finishingDetails.forEach(function(detail){
                if(details[detail.id]){

                    let rating = detail.options[details[detail.id]];
                    if(rating){

                        totalFootprint += rating.totalFootage;
                        totalStaffing += rating.staffing;

                        requiredItems.push({
                            id: detail.id,
                            label: detail.label,
                            summaryLabel: detail.label,
                            volumeLabel: rating.summaryLabel,
                            description: rating.description,
                            equipment: rating.equipment,
                            software: rating.software,
                            otherItems: rating.otherItems,
                            totalFootage: rating.totalFootage,
                            staffing: rating.staffing,
                            staffingCode: rating.staffingCode

                        })
                    }
                }




            })



            if(requiredItems.length > 0) {





                var summaryReport = {
                    description: undefined, //rating.description,
                    model: undefined,
                    software: undefined,
                    volume: undefined,
                    numOfModels: 1,
                    numOfShifts: 1,
                    footprint: totalFootprint,
                    requirements: requiredItems, //requiredItems
                    specs: [],
                    staffing: (parseInt(totalStaffing * 10.0)/10),
                    classification: classification,
                    summaryLabel: "Additional Staffing & Footprint"

                }

                this.recommendation = summaryReport;

                return summaryReport;

            }else{

                this.recommendation = undefined;

            }



        },

        recommendation: undefined,

        detailList: finishingDetails

    }

}
