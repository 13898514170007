<template>
  <div class="">
    <div class="flex justify-between items-center px-8 py-4 border-b bg-white">
      <h2 class="text-xl leading-none">Respondent Info</h2>
      <button class="button text-xs" @click="close">Cancel</button>
    </div>
    <div class="p-8">
      <div class="">

        <div class="" v-if="respondent">

            <div class="mb-4">
              <label class="font-bold mb-2 block text-sm">Respondent Name</label>
              <input type="text" class="p-2 border bg-white w-full rounded" v-model="respondent.name" />
              <p class="text-sm text-red-500" v-if="isDuplicate">This name and department combo already exist!</p>
            </div>

          <div class="mb-4">
            <label class="font-bold mb-2 block text-sm">Respondent Title</label>
            <input type="text" class="p-2 border bg-white w-full rounded" v-model="respondent.title" />
          </div>

          <div class="mb-4">
              <label class="font-bold mb-2 block text-sm">Department</label>
              <select-input v-model="respondent.department" :options="voices" prompt-text="Select Department" :use-id="true"/>
          </div>


          <div class="mb-4">
            <label class="font-bold mb-2 block text-sm">Other Info</label>
            <textarea type="text" rows="10" class="p-2 border bg-white w-full rounded" v-model="respondent.notes" placeholder="Location, contact info, misc notes..."></textarea>
          </div>

        </div>


        <div class="pt-6 mt-4 border-t">
          <div class="pb-4 flex justify-between">
            <div class="mr-8">

            </div>
            <div class="">
              <button class="button button-blue" @click="save" :disabled="!isValid || processing">{{ processing?"Saving...":"Save"}}</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>


import SelectInput from "@/components/SelectInput";
export default {
  name: "side-panel-respondents",
  components: {SelectInput },
  mounted: function() {
    if(this.payload && this.payload.id){
      this.respondent = {...this.analysis.respondents[this.payload.id] }
    }else{
      this.respondent = this.api.generateRespondent();
    }
  },
  props: {


    payload: {
      type: Object,
      required: false
    },

    analysis: {
      type: Object,
      required: true
    },

    api: {
      type: Object,
      required: true
    },


  },
  data: function () {
    return {
      respondent: null,
      processing: false
    }
  },
  methods: {


    close: function(){
      this.$emit('close');
    },

    save: async function(){

      if(this.isValid){

        if(!this.respondent.id){
          this.respondent.id = Date.now() + "";
        }

        let updatedRespondent = {...this.respondent};



        let updateData = { respondents: {} }
        updateData.respondents[this.respondent.id] = updatedRespondent;

        let user = this.$store.getters.getUserProfile.email;

        this.processing = true;

        await this.api.update( this.analysis.id, updateData,user)
            .then((response) => {

              console.log("Saved", response);
              this.analysis.respondents[this.respondent.id] = updatedRespondent;
              this.$emit("update",{field:"respondent", value: this.respondent.id});
              this.close();
            })
            .catch((error) => {
              console.error(error);
              alert("There was an issue processing this request.")
              return false;
            })

        this.processing = false;

      }


    }
  },
  computed: {

    isValid: function(){
      return (!this.isDuplicate && this.respondent && this.respondent.name && this.respondent.department)
    },

    isDuplicate: function(){
      if(this.respondent){
        Object.keys(this.analysis.respondents).find((item) => {
            return ((item.name ===  this.respondent.name ) && (item.department === this.respondent.department) && (item.id !== this.respondent.id))
        })
      }
      return false;
    },

    voices: function(){
      let voiceOptions = Object.keys(this.api.schema.voiceOptions).map((voice) => {
        return this.api.schema.voiceOptions[voice];
      })

      return voiceOptions;
    }
  }
}
</script>

<style scoped>


.side-panel-new-assessment {

}

.button[disabled]{
  opacity: 0.5;
  pointer-events: none;
}

</style>
