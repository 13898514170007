


<template>
<div class="color-gap">
<h4>Ricoh Professional Color Management Services</h4>
  <hr>
  <p>Ricoh Professional Color Management Services are designed to address every part of the Color Management chain – from the inception of a design to final production.</p>
  <p>Our goal is to make it easy to get the level of color accuracy and repeatability you require to please your customers – whether it’s based on a simple, internal “house standard” or industry compliant contract proofing specifications.</p>
  <p>Ricoh Color Management Services are device agnostic because effective Color Management requires the inclusion of all print manufacturing components, including pre-press, monitors, lighting, and any type of production output device, regardless of technology or manufacturer.</p>
  <p>Beyond traditional calibration and profiling services, Ricoh Professional Color Management also offers comprehensive training and consulting services. These services may include on-site or remote application and design training, Design as a Service, development of custom Standard Operating Procedures, G7 Master print shop qualification, as well as consulting services to help businesses transition/augment from “Offset to Digital” or enter and navigate highly profitable digital embellishment and special effects printing market opportunities.</p>
  <p>Ricoh USA maintains a Professional Services team including over 70 Idealliance certified <i>“Color Management Professional Masters”</i>, as well as 30 Idealliance certified <i>“G7 Experts”</i>, and even our own <i>“G7 Expert Trainer”</i> (licensed by Idealliance to conduct G7 Expert trainings).</p>
  <p>Ricoh Professional Color Management makes it easy and affordable to get the color you want - whether your business consists of a single printing device or hundreds of decentralized devices including light booths, monitors, printers, scanners, and cameras.</p>
<p></p>
</div>
</template>

<script>
export default {
    name: "color-gap",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


.color-gap {

}

</style>