export default {

    responseStatus: {
        "0":{ "label":"Disregard", "code":0, "id":"0", "description": "This response is either irrelevant or incorrect."},
        "1":{ "label":"Valid", "id":"1", "code":1, "description": "Default response status."},
        "2":{ "label":"Important", "id":"2","code":2, "description": "This response contains important information."},
    },

    voiceOptions:
        {
            "executive": {"id": "executive", "label": "Executive Mgmt.", "shortLabel":"Executive", initials:"EX", order: 0},
            "production": {"id": "production", "label": "Production Mgmt.", "shortLabel":"Production", initials:"PM",order: 1},
            "sales": {"id": "sales", "label": "Sales", "shortLabel":"Sales", initials:"SA",order: 2},
            "marketing": {"id": "marketing", "label": "Marketing", "shortLabel":"Marketing", initials:"MK",order: 3},
            "service": {"id": "service", "label": "Customer Service", "shortLabel":"Service", initials:"SV",order: 4},
            "scanning": {"id": "scanning", "label": "Scanning", "shortLabel":"Scanning", initials:"SC",order: 5},
            "design": {"id": "design", "label": "Design", "shortLabel":"Design", initials:"DS",order: 6},
            "prePress": {"id": "prePress", "label": "Pre Press", "shortLabel":"PrePress", initials:"PP",order: 7},
            "pressOps": {"id": "pressOps", "label": "Press Operators", "shortLabel":"Operators", initials:"PO",order: 8},
            "finishing": {"id": "finishing", "label": "Finishing / Bindery", "shortLabel":"Finishing", initials:"FN",order: 9},
            "mailing": {"id": "mailing", "label": "Mailing", "shortLabel":"Mailing", initials:"ML",order: 10},
            "fulfillment": {"id": "fulfillment", "label": "Fulfillment", "shortLabel":"Fulfillment", initials:"FF",order: 11},
            "notes": {"id": "notes", "label": "Analysis Team", "shortLabel":"Notes", initials:"NOTE",order: 12}
        }
    ,
    sections: {
        "generalInfo": {
            id: "generalInfo",
            label: "General Info",
            description: "",
            groups: [
                { label: "", questions:["NA007","NA017","NA018","NA019","NA020","NA021","NA022","NA023","NA024","NA025","NA026","NA027","NA028","NA029","NA030", "NANOTES"]}
                ]
        },
        "equipment": {
            id: "equipment",
            label: "Equipment",
            description: "",
            groups: [
                    { label: "", questions:["NB002","NB003","NB004","NB005","NB008","NB009","NBNOTES"] }
                ]
        },
        "workflow": {
            id: "workflow",
            label: "Workflow Efficiency",
            description: "",
            groups: [
                { label: "Make Ready", questions:["NC002","NC003","NC004","NC005","NC008"] },
                { label: "Analytics", questions:["NC010","NC011","NC012","NC013","NC014"] },
                { label: "Print Management", questions:["NC016","NC017","NC018","NC019","NCNOTES"] },
            ]
        },
        "security": {
            id: "security",
            label: "Security",
            description: "",
            groups: [{
                label: "", questions: ["NI002","NI003","NI004","NI005","NI006","NI007","NINOTES"]
            }]
        },
        "color": {
            id: "color",
            label: "General Color",
            description: "",
            groups: [{
                label: "", questions: ["ND002","ND003","ND004","ND005","ND006","ND007","ND008","ND009","ND010","ND011","ND012","ND013","ND014","ND015","ND016","ND017","ND018","ND019","ND020","ND021","ND022","ND023","ND024","ND025","NDNOTES"]
            }
            ]
        },
        "colorDiscovery": {
            id: "colorDiscovery",
            label: "Color Discovery",
            warning: "Consultants Only",
            description: "",
            groups: [{
                label: "", questions: ["ND2001","ND2002","ND2003","ND2004","ND2005","ND2006","ND2007","ND2008","ND2009","ND2010","ND2011","ND2012","ND2013","ND2014","ND2015","ND2016","ND2017","ND2018","ND2019","ND2020","ND2021","ND2022","ND2023","ND2024","ND2025","ND2026","ND2027","ND2028","ND2029","ND2030","ND2031","ND2032","ND2033","ND2034","ND2035","ND2036","ND2037","ND2038","ND2039","ND2040","ND2041","ND2042","ND2043","ND2044","ND2045","ND2046","ND2047","ND2048","ND2049","ND2050","ND2051","ND2052","ND2053","NDNOTES2"]
            }
            ]
        },
        "crossMedia": {
            id: "crossMedia",
            label: "Cross Media",
            description: "",
            groups: [
                { label: "", questions: ["NE002","NE003","NE004","NE005","NE006","NE007","NE008","NE009","NE010","NE011","NE012","NE013","NE014","NE015","NE016","NE017","NE018","NE019","NENOTES"] }
            ]
        },
        "industrialPrint": {
            id: "industrialPrint",
            label: "Industrial Print",
            description: "",
            groups: [
                { label: "", questions: ["NF002","NF003","NF004","NF005","NF006","NF007","NF008","NF009","NF010","NF011","NF012","NF013","NF014","NF015","NFNOTES"] }
            ]
        },
        "advancedSolutions": {
            id: "advancedSolutions",
            label: "Advanced Solutions",
            description: "",
            groups: [
                { label: "", questions: ["NG002","NG003","NG004","NG005","NG006","NG007","NG008","NG009","NG010","NG011","NG012","NG013","NG014","NG015","NG016","NG017","NGNOTES"] }
            ]
        },
        "advancedFinishing": {
            id: "advancedFinishing",
            label: "Advanced Finishing",
            description: "",
            groups: [
                { label: "", questions: ["NH002","NH003","NH004","NH005","NH006","NH007","NH008","NH009","NHNOTES"] }
            ]
        }
    },
    questions: {
        "NA007": {
            "id": "NA007",
            "label": "Number of Locations",
            "description": "",
            "voices": ["executive","production"],
            "type": 1,
            "hint": ""
        },
        "NA017": {
            "id": "NA017",
            "label": "Commercial Printer/CRD/Data Center",
            "description": "",
            "voices": ["executive","production"],
            "type": 1,
            "hint": ""
        },
        "NA018": {
            "id": "NA018",
            "label": "Document Types",
            "description": "Bus. Cards/Flyers/VDP/Direct Mail/Packaging/etc",
            "voices": ["production","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "NA019": {
            "id": "NA019",
            "label": "Paper Stock Substrate Requirements",
            "description": "Size/Paper type/GSM/Coated-Uncoated/Tabs/NCR/Other",
            "voices": ["production","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "NA020": {
            "id": "NA020",
            "label": "Staffing",
            "description": "Print Shop - Hours/shifts per day/How many days per week (after first visit ask if there are any staffing changes)",
            "voices": ["executive","production"],
            "type": 1,
            "hint": ""
        },
        "NA021": {
            "id": "NA021",
            "label": "Client OS",
            "description": "Mac Version(s) / Windows Version(s)",
            "voices": ["production"],
            "type": 1,
            "hint": ""
        },
        "NA022": {
            "id": "NA022",
            "label": "Software Applications",
            "description": "Please list",
            "voices": ["production","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "NA023": {
            "id": "NA023",
            "label": "Job Volume",
            "description": "Demand - Mission Critical Jobs/Largest Vol Jobs/Production Periods",
            "voices": ["production","pressOps"],
            "type": 1,
            "hint": ""
        },
        "NA024": {
            "id": "NA024",
            "label": "Job Outsourcing",
            "description": "Third Party",
            "voices": ["production"],
            "type": 1,
            "hint": ""
        },
        "NA025": {
            "id": "NA025",
            "label": "SLA",
            "description": "Customers/Vendors",
            "voices": ["executive","production"],
            "type": 1,
            "hint": ""
        },
        "NA026": {
            "id": "NA026",
            "label": "Security Requirements",
            "description": "",
            "voices": ["executive","production"],
            "type": 1,
            "hint": ""
        },
        "NA027": {
            "id": "NA027",
            "label": "Other Challenges",
            "description": "Machine Uptime / Service Responsiveness / Delivery Challenges / Job Tracking / Machine Utilization / Operator Efficiency",
            "voices": ["production"],
            "type": 1,
            "hint": ""
        },
        "NA028": {
            "id": "NA028",
            "label": "Any areas we can help with?",
            "description": "ie. corporate initiatives, rebranding, right-sizing, acquisitions, security Concerns, relocating, remote workforce...",
            "voices": ["executive","production","sales","marketing"],
            "type": 1,
            "hint": ""
        },
        "NA029": {
            "id": "NA029",
            "label": "Any new revenue streams you are looking into?",
            "description": "",
            "voices": ["executive","production"],
            "type": 1,
            "hint": ""
        },
        "NA030": {
            "id": "NA030",
            "label": "What keeps you up at night?",
            "description": "",
            "voices": ["executive","production"],
            "type": 1,
            "hint": ""
        },
        "NANOTES": {
            "id": "NANOTES",
            "label": "General Notes",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","scanning","prePress","pressOps","finishing","mailing"],
            "type": 1,
            "hint": ""
        },

        "NB002": {
            "id": "NB002",
            "label": "Existing Ricoh Customer",
            "description": "Yes/No-Model #'s of Current Equipment (Pro series)",
            "voices": ["executive","production","pressOps"],
            "type": 1,
            "hint": ""
        },
        "NB003": {
            "id": "NB003",
            "label": "DFE Controllers",
            "description": "",
            "voices": ["production","pressOps"],
            "type": 1,
            "hint": ""
        },
        "NB004": {
            "id": "NB004",
            "label": "Non-Ricoh Equipment",
            "description": "",
            "voices": ["executive","production","pressOps"],
            "type": 1,
            "hint": ""
        },
        "NB005": {
            "id": "NB005",
            "label": "Volume",
            "description": "Total Projected Average Monthly Volume - B&W / Color",
            "voices": ["executive","production","pressOps"],
            "type": 1,
            "hint": ""
        },
        "NB008": {
            "id": "NB008",
            "label": "Finishing Equipment",
            "description": "Cutters, Folders, Inserters, etc.",
            "voices": ["executive","production","service","prePress","pressOps","finishing",],
            "type": 1,
            "hint": ""
        },
        "NB009": {
            "id": "NB009",
            "label": "Future Needs",
            "description": "What equipment do you need that you do not have today?",
            "voices": ["executive","production","service","scanning","prePress","pressOps","finishing","mailing"],
            "type": 1,
            "hint": ""
        },

        "NBNOTES": {
            "id": "NBNOTES",
            "label": "General Notes",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","scanning","prePress","pressOps","finishing","mailing"],
            "type": 1,
            "hint": ""
        },

        "NC002": {
            "id": "NC002",
            "label": "Are you assembling documents from multiple sources?",
            "description": "",
            "voices": ["production","scanning","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "NC003": {
            "id": "NC003",
            "label": "Are you preparing documents for output devices from a variety of vendors?",
            "description": "",
            "voices": ["production","scanning","design","prePress","pressOps","finishing"],
            "type": 1,
            "hint": ""
        },
        "NC004": {
            "id": "NC004",
            "label": "Do you struggle with proprietary file formats?  (RDOs)",
            "description": "",
            "voices": ["production","sales","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "NC005": {
            "id": "NC005",
            "label": "Is your original a hard copy document that needs to be cleaned up?",
            "description": "",
            "voices": ["production","scanning","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "NC006": {
            "id": "NC006",
            "label": "Do you have to do late-stage editing?",
            "description": "",
            "voices": ["production","service","scanning","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "NC007": {
            "id": "NC007",
            "label": "Are there any tasks that you perform that you wish could be automatic?",
            "description": "",
            "voices": ["production","service","scanning","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "NC008": {
            "id": "NC008",
            "label": "Do you currently use any make-ready software?",
            "description": "",
            "voices": ["production","scanning","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },

        "NC010": {
            "id": "NC010",
            "label": "Do you currently track Key Performance Indicators in your press room?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "NC011": {
            "id": "NC011",
            "label": "Can you determine the true production costs per individual job?",
            "description": "",
            "voices": ["production","sales","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "NC012": {
            "id": "NC012",
            "label": "Do you know the time and resources required to complete jobs?",
            "description": "",
            "voices": ["executive","production","sales","service","design","prePress","pressOps","finishing"],
            "type": 1,
            "hint": ""
        },
        "NC013": {
            "id": "NC013",
            "label": "Do you have consistent data that tracks and compares shifts and devices?",
            "description": "",
            "voices": ["executive","production","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "NC014": {
            "id": "NC014",
            "label": "Do you have multiple brands of digital print equipment?",
            "description": "",
            "voices": ["executive","production","sales","design","prePress","pressOps","finishing"],
            "type": 1,
            "hint": ""
        },
        "NC015": {
            "id": "NC015",
            "label": "Do you need to Automate Repetitive Manual Steps on Print Workflow?",
            "description": "",
            "voices": ["production","service","scanning","design","prePress","pressOps","finishing"],
            "type": 1,
            "hint": ""
        },
        "NC016": {
            "id": "NC016",
            "label": "Do you need to Automate Repetitive Manual Steps on Print Workflow?",
            "description": "",
            "voices": ["production","service","scanning","design","prePress","pressOps","finishing"],
            "type": 1,
            "hint": ""
        },
        "NC017": {
            "id": "NC017",
            "label": "Does your prepress and operators spend most of their time on repetitive setup tasks?",
            "description": "",
            "voices": ["production","design","prePress","pressOps","finishing"],
            "type": 1,
            "hint": ""
        },
        "NC018": {
            "id": "NC018",
            "label": "Do you need to shorten the review and approval process?",
            "description": "",
            "voices": ["executive","production","service","scanning","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "NC019": {
            "id": "NC019",
            "label": "Do you have clear procedures for backing up data, maintaining and updating software of your print production devices?",
            "description": "",
            "voices": ["executive","production","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "NCNOTES": {
            "id": "NCNOTES",
            "label": "General Notes",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","scanning","prePress","pressOps","finishing","mailing"],
            "type": 1,
            "hint": ""
        },

        "ND002": {
            "id": "ND002",
            "label": "Any specific pain points?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },

        "ND003": {
            "id": "ND003",
            "label": "Top initiatives over the next 6 mo.",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },

        "ND004": {
            "id": "ND004",
            "label": "Do all of your color devices match each other?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },

        "ND005": {
            "id": "ND005",
            "label": "Do you currently have a Color Management system or policy in place?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },

        "ND006": {
            "id": "ND006",
            "label": "Is Color Correction currently a profit center? Do you currently charge for color alterations or fixes?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },

        "ND007": {
            "id": "ND007",
            "label": "Do you offer high quality printer or soft (monitor) proofs? If so, how are they produced?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },

        "ND008": {
            "id": "ND008",
            "label": "How do you set color expectations with customers?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },

        "ND009": {
            "id": "ND009",
            "label": "How is color correction handled?",
            "description": "",
            "voices": ["production","sales","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },

        "ND010": {
            "id": "ND010",
            "label": "Are customer files standardized before they enter production? Preflight, corrected to a standard, etc.?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },

        "ND011": {
            "id": "ND011",
            "label": "Are spot colors (i.e. Pantone, etc.) used in production? If yes, is their use encouraged, and how are they managed?",
            "description": "",
            "voices": ["production","sales","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },

        "ND012": {
            "id": "ND012",
            "label": "With what hardware do you calibrate (proofers, monitors, scanners etc.)?",
            "description": "",
            "voices": ["production","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND013": {
            "id": "ND013",
            "label": "What tools are you using to color manage?",
            "description": "",
            "voices": ["production","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND014": {
            "id": "ND014",
            "label": "How do you color manage between printing devices?",
            "description": "",
            "voices": ["production","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },

        "ND015": {
            "id": "ND015",
            "label": "Do you have to prove a certain level of color accuracy to your color-critical customers?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },

        "ND016": {
            "id": "ND016",
            "label": "How often do you calibrate?",
            "description": "",
            "voices": ["production","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },

        "ND017": {
            "id": "ND017",
            "label": "What tools do you use to calibrate?",
            "description": "",
            "voices": ["production","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },

        "ND018": {
            "id": "ND018",
            "label": "Are you using custom color profiles? If yes, what kind, and how are they being used?",
            "description": "",
            "voices": ["production","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },

        "ND019": {
            "id": "ND019",
            "label": "What is the average time you spend color correcting color critical jobs?",
            "description": "",
            "voices": ["production","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },

        "ND020": {
            "id": "ND020",
            "label": "What is the average number of sheets used to set up for color critical jobs?",
            "description": "",
            "voices": ["production","pressOps"],
            "type": 1,
            "hint": ""
        },

        "ND021": {
            "id": "ND021",
            "label": "Are temperature & humidity managed and consistent?",
            "description": "",
            "voices": ["production","pressOps"],
            "type": 1,
            "hint": ""
        },

        "ND022": {
            "id": "ND022",
            "label": "Could color training make you more productive?",
            "description": "",
            "voices": ["sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },

        "ND023": {
            "id": "ND023",
            "label": "Could new/improved tools (i.e. software, hardware) make you more productive?",
            "description": "",
            "voices": ["design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },

        "ND024": {
            "id": "ND024",
            "label": "Do you have established color guidelines or color standards in place?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND025": {
            "id": "ND025",
            "label": "How do you track the level of color quality and consistency over time?",
            "description": "",
            "voices": ["executive","production","marketing","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },

        "ND2001": {
            "id": "ND2001",
            "label": "Is your shop a certified G7 master shop or are you interested in becoming G7 Master qualified?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },

        "ND2002": {
            "id": "ND2002",
            "label": "To what extent is your production output centralized or decentralized?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2003": {
            "id": "ND2003",
            "label": "Are the images used in your documents created from many different or unknown sources?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2004": {
            "id": "ND2004",
            "label": "Are some colored documents created without knowing which output device will produce them? ",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2005": {
            "id": "ND2005",
            "label": "Does your organization produce separations for offset printing of color documents either internally or through a service provider?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2006": {
            "id": "ND2006",
            "label": "Are some images used in both print and interactive/online media where cross-media color accuracy is desirable?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2007": {
            "id": "ND2007",
            "label": "Does your organization output to multiple devices that must be able to produce a similar appearance?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2008": {
            "id": "ND2008",
            "label": "Is matching color displays across multiple workstations currently implemented or desirable?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2009": {
            "id": "ND2009",
            "label": "Is simulating the characteristics of a press or printer for proofing purposes currently implemented or desirable?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2010": {
            "id": "ND2010",
            "label": "Do you currently create your own ICC profiles of the color devices in your environment?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2011": {
            "id": "ND2011",
            "label": "Do you design and have full control over your content / print jobs?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2012": {
            "id": "ND2012",
            "label": "Do you have any additional processes and/or hardware/software in your print workflow(s)?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2013": {
            "id": "ND2013",
            "label": "What are your color targets for your monitors?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2014": {
            "id": "ND2014",
            "label": "What are your color targets for your proofs?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2015": {
            "id": "ND2015",
            "label": "What are your color targets for your digital presses?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2016": {
            "id": "ND2016",
            "label": "What are your color targets for your offset presses?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2017": {
            "id": "ND2017",
            "label": "What are your color targets for your output files?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2018": {
            "id": "ND2018",
            "label": "Is your production environment climate controlled and clean?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2019": {
            "id": "ND2019",
            "label": "Describe your average production volumes:",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2020": {
            "id": "ND2020",
            "label": "Describe your peak production volumes:",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2021": {
            "id": "ND2021",
            "label": "How many color jobs do you run on average per day?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2022": {
            "id": "ND2022",
            "label": "How many color jobs do you run maximally per day?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2023": {
            "id": "ND2023",
            "label": "Which stocks/media/substrates are predominantly used?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2024": {
            "id": "ND2024",
            "label": "Does your equipment/staff adjust settings, register paper types, and print optimally for each substrate?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2025": {
            "id": "ND2025",
            "label": "Do you have controlled lighting conditions (5000K, daylight, viewing booths, munsell gray backgrounds)?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2026": {
            "id": "ND2026",
            "label": "Do you have a signage department or large format product lines?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2027": {
            "id": "ND2027",
            "label": "Do your departments work together and understand each other’s process?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2028": {
            "id": "ND2028",
            "label": "Have your employees been tested for color blindness?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2029": {
            "id": "ND2029",
            "label": "Do you have a need for soft proofing?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2030": {
            "id": "ND2030",
            "label": "Do you use UV or Hybrid UV inks?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2031": {
            "id": "ND2031",
            "label": "Who currently services your equipment?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2032": {
            "id": "ND2032",
            "label": "Who currently provides your company with consumables?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2033": {
            "id": "ND2033",
            "label": "What are your calibration policies?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2034": {
            "id": "ND2034",
            "label": "What do you calibrate?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2035": {
            "id": "ND2035",
            "label": "Are you currently sending CIP3, CIP4, or JDF in your workflows?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2036": {
            "id": "ND2036",
            "label": "Do you currently use ICC profiles or device links in any applications?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2037": {
            "id": "ND2037",
            "label": "Do you utilize any UCR/GCR techniques or ink/toner savings?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2038": {
            "id": "ND2038",
            "label": "Please indicate which applications are owned and/or used to produce color documents.",
            "description": "Also, indicate the version and platform if possible.",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2039": {
            "id": "ND2039",
            "label": "Please indicate what color spaces are currently utilized by your organization for saving images that are used in color documents.",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2040": {
            "id": "ND2040",
            "label": "Please indicate what file formats are currently utilized by your organization for saving images that are used in color documents.",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2041": {
            "id": "ND2041",
            "label": "Does your organization utilize stock photographs (such as Photo CD’s or downloaded images) that are incorporated into color documents?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2042": {
            "id": "ND2042",
            "label": "Do you scan or capture images digitally? If so, how?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2043": {
            "id": "ND2043",
            "label": "Please indicate which color management DFE’s are currently owned and/or used by your company. Also, indicate the version and platform if possible.",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2044": {
            "id": "ND2044",
            "label": "Please indicate how often training on color management and/or color reproduction is conducted within your organization (either internally or from an outside provider)?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2045": {
            "id": "ND2045",
            "label": "Do you currently utilize outside consultants or other service providers for designing, maintaining, or training on color equipment?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2046": {
            "id": "ND2046",
            "label": "Who within your organization is primarily responsible for designing and maintaining color management processes and color output devices?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2047": {
            "id": "ND2047",
            "label": "Do you currently have a Quality Control process? If so, please explain.",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2048": {
            "id": "ND2048",
            "label": "How do you prove your color standards to yourself or your customers?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2049": {
            "id": "ND2049",
            "label": "What is your ΔE tolerance for color fidelity?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2050": {
            "id": "ND2050",
            "label": "Are you having difficulties matching Pantones, CMYK, or Grayscales?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2051": {
            "id": "ND2051",
            "label": "Do you have established color baselines and standards for your devices?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2052": {
            "id": "ND2052",
            "label": "Please indicate which printing devices are used by your organization.",
            "description": "Also, indicate the number or devices in use and the make/model if possible.",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "ND2053": {
            "id": "ND2053",
            "label": "Please indicate which color measurement devices your organization currently owns and/or uses?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },


        "NDNOTES": {
            "id": "NDNOTES",
            "label": "General Notes",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","scanning","prePress","pressOps","finishing","mailing"],
            "type": 1,
            "hint": ""
        },

        "NDNOTES2": {
            "id": "NDNOTES2",
            "label": "General Discovery Notes",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","scanning","prePress","pressOps","finishing","mailing"],
            "type": 1,
            "hint": ""
        },

        "NE002": {
            "id": "NE002",
            "label": "Web Ordering- Self Managed/Hosted",
            "description": "",
            "voices": ["executive","production"],
            "type": 1,
            "hint": ""
        },

        "NE003": {
            "id": "NE003",
            "label": "VDP-Current Application-Vendor/Mail Merge Work/Transactional Forms",
            "description": "",
            "voices": ["executive","production"],
            "type": 1,
            "hint": ""
        },

        "NE004": {
            "id": "NE004",
            "label": "How many documents do you send by mail every month?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","mailing","fulfillment"],
            "type": 1,
            "hint": ""
        },
        "NE005": {
            "id": "NE005",
            "label": "Are you able to easily send your customers communication?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","mailing","fulfillment"],
            "type": 1,
            "hint": ""
        },

        "NE006": {
            "id": "NE006",
            "label": "Do you struggle with returns and misplaced inventories?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","mailing"],
            "type": 1,
            "hint": ""
        },

        "NE007": {
            "id": "NE007",
            "label": "Do you know your monthly mailing costs?",
            "description": "",
            "voices": ["executive","marketing"],
            "type": 1,
            "hint": ""
        },
        "NE008": {
            "id": "NE008",
            "label": "Are you able to automatically create postal reports?",
            "description": "",
            "voices": ["executive","marketing"],
            "type": 1,
            "hint": ""
        },

        "NE009": {
            "id": "NE009",
            "label": "How do you check that the right piece of paper is in the right envelope?",
            "description": "",
            "voices": ["executive","production","sales","marketing","finishing","mailing"],
            "type": 1,
            "hint": ""
        },

        "NE010": {
            "id": "NE010",
            "label": "What is your error rate?",
            "description": "",
            "voices": ["executive","production","marketing","design","finishing","mailing","fulfillment"],
            "type": 1,
            "hint": ""
        },

        "NE011": {
            "id": "NE011",
            "label": "How much time is spent fixing errors?",
            "description": "",
            "voices": ["executive","production","marketing","design","finishing","mailing","fulfillment"],
            "type": 1,
            "hint": ""
        },

        "NE012": {
            "id": "NE012",
            "label": "Can you adapt and respond to market opportunities quickly?",
            "description": "",
            "voices": ["executive","production","sales","marketing"],
            "type": 1,
            "hint": ""
        },

        "NE013": {
            "id": "NE013",
            "label": "Are you interested in automating tasks and reducing workload?",
            "description": "",
            "voices": ["executive","production","marketing","service","design","finishing","mailing","fulfillment"],
            "type": 1,
            "hint": ""
        },

        "NE014": {
            "id": "NE014",
            "label": "What causes your production delays?",
            "description": "",
            "voices": ["executive","production","design","finishing","mailing"],
            "type": 1,
            "hint": ""
        },

        "NE015": {
            "id": "NE015",
            "label": "How do you enhance existing documents?",
            "description": "",
            "voices": ["executive","production","sales","marketing","design"],
            "type": 1,
            "hint": ""
        },

        "NE016": {
            "id": "NE016",
            "label": "Do you sort by hand?",
            "description": "",
            "voices": ["production","finishing","mailing"],
            "type": 1,
            "hint": ""
        },

        "NE017": {
            "id": "NE017",
            "label": "Are you taking advantage of mail postage discounts?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","mailing"],
            "type": 1,
            "hint": ""
        },

        "NE018": {
            "id": "NE018",
            "label": "Are you adding integrity marks for folding and stuffing machines?",
            "description": "",
            "voices": ["production","design","finishing","mailing"],
            "type": 1,
            "hint": ""
        },
        "NE019": {
            "id": "NE019",
            "label": "Do you have a transpromo strategy?",
            "description": "",
            "voices": ["executive","production","sales","marketing","mailing"],
            "type": 1,
            "hint": ""
        },

        "NENOTES": {
            "id": "NENOTES",
            "label": "General Notes",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","scanning","prePress","pressOps","finishing","mailing"],
            "type": 1,
            "hint": ""
        },

        "NF002": {
            "id": "NF002",
            "label": "Wide Format-Size/Color-B&W/Roll Feed-Sheet Feed/Ink Jet-Laser",
            "description": "",
            "voices": ["pressOps"],
            "type": 1,
            "hint": ""
        },

        "NF003": {
            "id": "NF003",
            "label": "Wide Format-Paper Types",
            "description": "",
            "voices": ["production","sales","marketing","pressOps"],
            "type": 1,
            "hint": ""
        },

        "NF004": {
            "id": "NF004",
            "label": "Do you have all of your customer’s business?",
            "description": "",
            "voices": ["executive","sales"],
            "type": 1,
            "hint": ""
        },

        "NF005": {
            "id": "NF005",
            "label": "Are you outsourcing any wide format?",
            "description": "",
            "voices": ["executive","production"],
            "type": 1,
            "hint": ""
        },
        "NF006": {
            "id": "NF006",
            "label": "Do you have a need to run substrates or materials other than paper?",
            "description": "",
            "voices": ["production","sales","marketing","pressOps"],
            "type": 1,
            "hint": ""
        },
        "NF007": {
            "id": "NF007",
            "label": "What type of products are you being asked for that you can’t currently produce in house?",
            "description": "",
            "voices": ["production","sales","marketing"],
            "type": 1,
            "hint": ""
        },
        "NF008": {
            "id": "NF008",
            "label": "Would you like to increase your product and services portfolio?",
            "description": "",
            "voices": ["executive"],
            "type": 1,
            "hint": ""
        },
        "NF009": {
            "id": "NF009",
            "label": "Have your customers asked you for promotional items?",
            "description": "",
            "voices": ["sales","marketing"],
            "type": 1,
            "hint": ""
        },
        "NF010": {
            "id": "NF010",
            "label": "Would you like to create ADA compliant signage?",
            "description": "",
            "voices": ["executive","sales"],
            "type": 1,
            "hint": ""
        },
        "NF011": {
            "id": "NF011",
            "label": "Do you have need for outdoor print?",
            "description": "",
            "voices": ["executive","sales","marketing"],
            "type": 1,
            "hint": ""
        },
        "NF012": {
            "id": "NF012",
            "label": "Would you like to create custom packaging?",
            "description": "",
            "voices": ["executive","sales","marketing"],
            "type": 1,
            "hint": ""
        },
        "NF013": {
            "id": "NF013",
            "label": "Do your customers have need of POS Display?",
            "description": "",
            "voices": ["executive","sales","marketing"],
            "type": 1,
            "hint": ""
        },
        "NF014": {
            "id": "NF014",
            "label": "Do you have need to print directly to rigid materials?",
            "description": "",
            "voices": ["production","pressOps"],
            "type": 1,
            "hint": ""
        },
        "NF015": {
            "id": "NF015",
            "label": "Do you have need of contour cutting or routing?",
            "description": "",
            "voices": ["executive","production","pressOps"],
            "type": 1,
            "hint": ""
        },

        "NFNOTES": {
            "id": "NFNOTES",
            "label": "General Notes",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","scanning","prePress","pressOps","finishing","mailing"],
            "type": 1,
            "hint": ""
        },

        "NG002": {
            "id": "NG002",
            "label": "How many estimates are created each month?",
            "description": "",
            "voices": ["executive","production","sales","design","fulfillment"],
            "type": 1,
            "hint": ""
        },
        "NG003": {
            "id": "NG003",
            "label": "How long does it take to prepare your estimates?",
            "description": "",
            "voices": ["production","sales","design","fulfillment"],
            "type": 1,
            "hint": ""
        },
        "NG004": {
            "id": "NG004",
            "label": "How much time accounting for work pulling reports?",
            "description": "",
            "voices": ["executive","production"],
            "type": 1,
            "hint": ""
        },
        "NG005": {
            "id": "NG005",
            "label": "How many job tickets are created in an online storefront?",
            "description": "",
            "voices": ["production","service","prePress","pressOps","fulfillment"],
            "type": 1,
            "hint": ""
        },
        "NG006": {
            "id": "NG006",
            "label": "How many job tickets are created manually?",
            "description": "",
            "voices": ["production","service","design","prePress","pressOps","fulfillment"],
            "type": 1,
            "hint": ""
        },
        "NG007": {
            "id": "NG007",
            "label": "How long does it take to prepare a job ticket?",
            "description": "",
            "voices": ["production","prePress","pressOps"],
            "type": 1,
            "hint": ""
        },
        "NG008": {
            "id": "NG008",
            "label": "What kind of inventory items do you manage for production print?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","fulfillment"],
            "type": 1,
            "hint": ""
        },
        "NG009": {
            "id": "NG009",
            "label": "How does marketing collaborate on materials?",
            "description": "",
            "voices": ["executive","marketing"],
            "type": 1,
            "hint": ""
        },
        "NG010": {
            "id": "NG010",
            "label": "How do you replace out of date marketing collateral?",
            "description": "",
            "voices": ["executive","sales","marketing"],
            "type": 1,
            "hint": ""
        },
        "NG011": {
            "id": "NG011",
            "label": "How long does it take to create a PO?",
            "description": "",
            "voices": ["executive","production","sales","service","prePress","fulfillment"],
            "type": 1,
            "hint": ""
        },
        "NG012": {
            "id": "NG012",
            "label": "How much time is spent tracking jobs per day?",
            "description": "",
            "voices": ["executive","production","design","prePress","pressOps","fulfillment"],
            "type": 1,
            "hint": ""
        },
        "NG013": {
            "id": "NG013",
            "label": "What is the cost of re-work, errors and job changes each month?",
            "description": "",
            "voices": ["executive","production","prePress","pressOps","finishing"],
            "type": 1,
            "hint": ""
        },
        "NG014": {
            "id": "NG014",
            "label": "How many days after project completion do you invoice jobs?",
            "description": "",
            "voices": ["executive","production","service","pressOps","fulfillment"],
            "type": 1,
            "hint": ""
        },
        "NG015": {
            "id": "NG015",
            "label": "How long does it take to track job labor per employee per day?",
            "description": "",
            "voices": ["executive","production"],
            "type": 1,
            "hint": ""
        },
        "NG016": {
            "id": "NG016",
            "label": "Who owns the customer experience?",
            "description": "",
            "voices": ["executive","production","sales","marketing","service"],
            "type": 1,
            "hint": ""
        },
        "NG017": {
            "id": "NG017",
            "label": "How are jobs currently submitted by your customers?",
            "description": "",
            "voices": ["executive","production","sales","service","design","prePress","pressOps","fulfillment"],
            "type": 1,
            "hint": ""
        },
        "NGNOTES": {
            "id": "NGNOTES",
            "label": "General Notes",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","scanning","prePress","pressOps","finishing","mailing"],
            "type": 1,
            "hint": ""
        },

        "NH002": {
            "id": "NH002",
            "label": "Desired Finishing",
            "description": "Saddle Stitch Booklets/Perfect Binding/Ring/Square Fold and Trim /Punching /Folding /Stapling /Cover Imposing",
            "voices": ["executive","production"],
            "type": 1,
            "hint": ""
        },

        "NH003": {
            "id": "NH003",
            "label": "Are you outsourcing any types of finishing?",
            "description": "(this may open up several topics)",
            "voices": ["executive","production"],
            "type": 1,
            "hint": ""
        },
        "NH004": {
            "id": "NH004",
            "label": "Are there finishing items that are difficult to produce that you wish there was a better way to get done?",
            "description": "",
            "voices": ["executive","production","finishing"],
            "type": 1,
            "hint": ""
        },
        "NH005": {
            "id": "NH005",
            "label": "Are there jobs that require manual labor and in some cases temporary labor?",
            "description": "",
            "voices": ["executive","production","pressOps"],
            "type": 1,
            "hint": ""
        },
        "NH006": {
            "id": "NH006",
            "label": "Is there a finishing function that is causing a bottlenecks and making it hard to achieve desired customer deadlines?",
            "description": "",
            "voices": ["executive","production"],
            "type": 1,
            "hint": ""
        },
        "NH007": {
            "id": "NH007",
            "label": "Ask about specific types of finishing.",
            "description": "(ie: punching for bound books, laminating, bookletmaking, folding, Slitting cutting creasing, perfect binding)",
            "voices": ["executive","production","pressOps","finishing"],
            "type": 1,
            "hint": ""
        },
        "NH008": {
            "id": "NH008",
            "label": "Do you need to support output from multiple engines or sources?",
            "description": "(ie: b&w, 2nd color unit, offset)",
            "voices": ["executive","production","pressOps","finishing"],
            "type": 1,
            "hint": ""
        },

        "NH009": {
            "id": "NH009",
            "label": "Wishlist for finishing:",
            "description": "",
            "voices": ["executive","production"],
            "type": 1,
            "hint": ""
        },

        "NHNOTES": {
            "id": "NHNOTES",
            "label": "General Notes",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","scanning","prePress","pressOps","finishing","mailing"],
            "type": 1,
            "hint": ""
        },

        "NI002": {
            "id": "NI002",
            "label": "Are you fully confident in your handling of personally identifiable information (PII)?",
            "description": "",
            "voices": ["executive","production"],
            "type": 1,
            "hint": ""
        },

        "NI003": {
            "id": "NI003",
            "label": "Are you equipped to handle all potential security issues on your own?",
            "description": "",
            "voices": ["executive","production"],
            "type": 1,
            "hint": ""
        },
        "NI004": {
            "id": "NI004",
            "label": "Are you aware of the current vulnerabilities your print business faces?",
            "description": "",
            "voices": ["executive","production"],
            "type": 1,
            "hint": ""
        },
        "NI005": {
            "id": "NI005",
            "label": "Does your internal IT security team manage the Ricoh print production devices?",
            "description": "",
            "voices": ["executive","production"],
            "type": 1,
            "hint": ""
        },
        "NI006": {
            "id": "NI006",
            "label": "Have you implemented any security procedures to integrate with current production print equipment?",
            "description": "",
            "voices": ["executive","production"],
            "type": 1,
            "hint": ""
        },
        "NI007": {
            "id": "NI007",
            "label": "Do you have clear procedures for backing up data and for maintaining and updating software of the print production devices?",
            "description": "",
            "voices": ["executive","production"],
            "type": 1,
            "hint": ""
        },

        "NINOTES": {
            "id": "NINOTES",
            "label": "General Notes",
            "description": "",
            "voices": ["executive","production","sales","marketing","service","design","scanning","prePress","pressOps","finishing","mailing"],
            "type": 1,
            "hint": ""
        },













    },
    notes: {
        "summary": {
            "id": "summary",
            "label": "Summary Note",
            "description": "Note or comment to include in the Summary of the analysis report",
            "voices": ["notes"],
            "type": 1,
            "hint": ""
        },
        "background": {
            "id": "background",
            "label": "Background Note",
            "description": "Note or comment to include in the Background section of the analysis report",
            "voices": ["notes"],
            "type": 1,
            "hint": ""
        },
        "other": {
            "id": "other",
            "label": "Miscellaneous Note",
            "description": "Note or comment to include in the analysis report addendum",
            "voices": ["notes"],
            "type": 1,
            "hint": ""
        },
    }
}
