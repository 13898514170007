

export default {

    equipment: {
        PSI3655GA: {
            id: 'PSI3655GA',
            name: 'PSI 3655GA',
            ppm: 55,
            volume: 150000,
            volume2: 0,
            volumeMax: 150000,
            volumeAbbr: "150k",
            volumeSuffix: "Color envelopes per month",
            baseFootage: 54,
            materialFootage: 75,
            totalFootage: 129,
            excludedBy: [
                'offsetPlate'
            ],
            addedFootage: {}
        },

        PSIDTP2432: {
            id: 'PSIDTP2432',
            name: 'PSI DTP 2432',
            ppm: 30,
            volume: 50000,
            volume2: 0,
            volumeMax: 50000,
            volumeAbbr: "50k",
            volumeSuffix: "Color envelopes per month",
            baseFootage: 54,
            materialFootage: 50,
            totalFootage: 104,
            excludedBy: [
                'paperGSM320',
                'offsetPlate'
            ],
            addedFootage: {}
        },

        xanteImpressia: {
            id: 'xanteImpressia',
            name: 'Xanté Impressia',
            ppm: 78,
            volume: 150000,
            volume2: 0,
            volumeMax: 150000,
            volumeAbbr: "150k",
            volumeSuffix: "Color envelopes per month",
            baseFootage: 60,
            materialFootage: 75,
            totalFootage: 135,
            excludedBy: [
                'paperGSM320',
                'offsetPlate'
            ],
            addedFootage: {}
        },
        xanteEnPress: {
            id: 'xanteEnPress',
            name: 'Xanté En Press',
            ppm: 60,
            volume: 150000,
            volume2: 0,
            volumeMax: 150000,
            volumeAbbr: "150k",
            volumeSuffix: "Color envelopes per month",
            baseFootage: 60,
            materialFootage: 75,
            totalFootage: 135,
            excludedBy: [


            ],
            addedFootage: {}
        },
        quadientMach6: {
            id: 'quadientMach6',
        name: 'Quadient Mach 6 Inkjet',
            ppm: 125,
            volume: 500000,
            volume2: 0,
            volumeMax: 500000,
            volumeAbbr: "500k",
            volumeSuffix: "Color envelopes per month",
            baseFootage: 60,
            materialFootage: 100,
            totalFootage: 160,
            excludedBy: [
                'offsetPlate'
            ],
            addedFootage: {}
        }


    },

    siteFunctionDetails: [
        {
            id: 'volumePerMonth',
            type: 'number',
            required: true,
            component: 'PulloutQuestionNumber',
            label: 'Volume Monthly',
            default: 0,
            description: "Color envelopes per month",
            additionalInfo: (context) => {
                if(context.interview.questions.X204){
                    if(context.interview.questions.X204.answer.final){
                        let yearlyValue = parseInt(context.interview.questions.X204.answer.final);
                        if(yearlyValue){
                            let monthlyValue = parseInt(yearlyValue / 12);
                            return `Site Survey indicated ${ monthlyValue.toLocaleString() } envelopes per month ( ${ yearlyValue.toLocaleString() } yearly )`
                        }

                    }
                }

                return ''
            },
        },
        {
            id: 'jobsPerMonth',
            type: 'number',
            required: true,
            component: 'PulloutQuestionNumber',
            label: 'Jobs Per Month',
            default: 0,
            description: "jobs per month"
        },

        {
            id: 'paperGSM320',
            type: 'boolean',
            required: true,
            component: 'PulloutQuestionSwitch',
            label: 'Paper GSM >320',
            default: false
        },
        {
            id: 'offsetPlate',
            type: 'boolean',
            required: true,
            component: 'PulloutQuestionSwitch',
            label: 'Offset Plate Output',
            default: false
        },



    ]
}

