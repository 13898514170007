


<template>
<div class="">
  <h4>ObjectifLune Connect Send</h4>
  <hr>
  <p>Objectif Lune Connect Send is an add on capability for PlanetPress Connect® and PreS® Connect. It is a great fit for print facilities looking to increase their volumes. Connect Send is a job submission tool based on the simplicity of using a windows print driver. It enables a streamlined workflow to get one-off, and small batch jobs into the print facility – and then commingle them together as a single, larger run. Leverage additional capabilities in the Connect software to manage dynamic backgrounds, link to mail sorting software, add inserter control marks and more.</p>
  <ul>
    <li>Powered by a Windows® print driver, no logins or websites to remember
    <li>Enables the automation of a variety of different mail projects and document types to be efficiently managed and integrated into the print shop workflow</li>
    <li>The use of uploads avoids having to support data and jobs submitted via USB, email attachment or FTP, while the workflow tool can keep everyone informed when a job is pending</li>
    <li>Simplifies the end user’s workflow as they no longer need to print a job to file, go to a website, remember their login and find the upload page, then navigate to the job they saved and upload it. Just print to the Connect Send printer driver.</li>
    <li>Leverage the workflow capabilities of the workflow tool for adding in routing, grouping, sorting, mail software integration, notifications and more.</li>
    <li>Easy onboarding of new customers or users, with minimal or no training</li>
  </ul>
  <p class="bold-label">Benefits</p>
  <ul>
    <li>Short implementation times</li>
    <li>Flexible licensing model – options for transaction based or user based</li>
    <li>Customized to fit each print facility’s needs and those of their end users</li>
    <li>Reduces manual labor</li>
    <li>Eliminates human error</li>
    <li>Improves customer experience</li>
    <li>Minimal or no training needed</li>
    <li>No login, or new accounts or websites to remember</li>
    <li>Quick to install, just a Windows® print driver needed</li>
    <li>Print output to a specific print driver</li>
    <li>Depending on configuration, preview, approval and tracking could be used to enhance the usability</li>
  </ul>

  <p class="bold-label">Additional Modules, Options and Expansions</p>
  <ul>
    <li>Connect Send is natively built into the PlanetPress Connect and PreS Connect software – but in the fully automated mode only – no user web interaction</li>
    <li>The user interaction is enabled with the added Objectif Lune Connect Send license – either transaction or user based model</li>
    <li>The web page interaction for the user is built in Connect, and managed by the workflow tool, so it can be customized to meet all types of specific needs an end customer may have</li>

  </ul>

</div>
</template>

<script>
export default {
    name: "objectif-connect-send",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


</style>
