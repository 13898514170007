<template>
  <div class="ccmdesign">
      <h4>Customer Communications Management Design as a Service</h4>
    <hr>
      <p>Transactional documents are more than just sharing information, they are an opportunity to connect with your customer in a meaningful way. An updated document layout, combined with branding, personalized messaging, and integrated marketing strategies can deliver a clear and cohesive message your customer can understand. This updated design will drive engagement, yielding increased response rates and lowering costs.</p>
    <p>Ricoh’s Design Consultants are equipped to help you improve these regular communication touchpoints and integrate them with your other channels, providing a consistent message and giving you the opportunity to make the most out of each customer document. If interested, please let us know and we can setup initial meetings to discuss your objectives.</p>
  </div>
</template>

<script>
export default {
  name: "ccm-design",
  props: {},
  data: function () {
    return {}
  },
  methods: {},
  computed: {}
}
</script>

<style scoped>


.ccmdesign {

}

</style>