<template>
  <div class="bg-white border-l bg-gray-50">
    <div v-if="!detailPanel">
      <div class="flex justify-between items-center px-8 py-4 border-b bg-white">
        <h2 class="text-xl leading-none">Edit Response<span class="opacity-50 text-xs ml-2" v-if="question">{{ question.id }}</span></h2>
        <div class="w-8 h-8 p-2 border-gray-300 border-2 rounded-full flex justify-center items-center mt-1 ml-3 opacity-50 hover:opacity-100 cursor-pointer" @click="close">
          <img  class="h-3 inline-block " src="../../assets/icons/close-icon.svg" />
        </div>
      </div>
      <div class="p-8">
        <div class="">

          <div class="">
            <div class="pb-4">
              <label class="text-sm font-bold">Respondent <span v-if="respondentInfo" class="font-normal ml-2">|<span class="ml-2 text-brand-blue hover:underline cursor-pointer" @click="editRespondent">View Profile</span></span></label>
              <div class="flex w-full items-center">
                <select-input class="flex-grow" v-model="currentVoice" :options="respondentOptions" prompt-text="Select Respondent" :use-id="true" @change="onRespondentChange" :class="isEditable?'':'pointer-events-none'" />
                <plus-icon size="8" class="ml-2 cursor-pointer" @click.native="addRespondent" v-if="isEditable"></plus-icon>
              </div>


            </div>

          </div>


          <div class="mt-4" v-if="question">

            <p class="font-bold">{{ question.label }}</p>
            <label class="mb-2 text-sm block italic opacity-75" v-if="question.description">{{ question.description }}</label>

          </div>
          <div>
            <textarea class="block w-full border p-2 mt-1 rounded" rows="10" v-model="answerValue" :disabled="!isEditable"/>
          </div>
          <div class="flex items-center mt-2">
            <label class="mr-2">Flag this response for followup</label><input type="checkbox" :disabled="!startedAsFlagged" :checked="!status" @change="toggleFlagged"/>
          </div>

          <div class="mt-2 text-sm" >
            <div v-if="lastEditTime">Edited {{ lastEditTime }} by {{ lastEditor }}</div>
            <div class="opacity-50">{{ editableMsg }}</div>
          </div>






          <div class="pt-6 mt-4 border-t">
            <div class="pb-4 flex justify-between">
              <div class="mr-8">
                <button class="button border-gray-400" @click="close">Cancel</button>
              </div>
              <div class="">
                <button class="button button-blue" :disabled="!isValid" :class="isValid?'':'opacity-50 pointer-events-none'" @click="save">{{ processing?"Saving...":"Save"}}</button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <component v-if="detailPanel" :is="detailPanel.component" :analysis="analysis" :api="api" :payload="detailPanel.payload" @close="closeDetailPanel" @update="onDetailUpdate"></component>


  </div>
</template>

<script>
import SelectInput from "@/components/SelectInput";
import PlusIcon from "@/components/PlusIcon";
import SidePanelRespondents from "@/components/NeedsAnalysis/SidePanelRespondents";


export default {
  name: "side-panel-needs-answer",
  components: {SidePanelRespondents, PlusIcon, SelectInput},
  mounted: function() {

    if(this.editInfo.category){
      this.category = this.editInfo.category;

      if(this.category === "notes"){
        let userId = this.$store.getters.getUserProfile.userid;
        if(this.analysis.respondents[userId]){
          this.currentVoice = userId;
        }
      }

    }

    if(this.answer){

      this.editTimeWindow = Date.now() - this.answer.ts;
      this.answerValue = this.answer.value;
      this.currentVoice = this.answer.respondent;
      this.status = this.answer.status?this.answer.status:0;

      console.log(this.answer);

      if(!this.status){
        this.startedAsFlagged = true;
      }


      if(this.answer.reviewer){

        if((this.answer.reviewer !== this.reviewer)) {
          this.isEditable = false;
        }else {
          if(this.editTimeWindow > (24 * 60 * 60 * 1000)) {
            this.isEditable = false;
          }
        }

      }

    }else if(this.session && this.session.respondent){ //we use id for session
      if(this.category === "responses"){
        this.currentVoice = this.session.respondent
      }
    }



  },
  props: {
    editInfo: {
      type: Object,
      required: true
    },

    session: {
      required: false,
    },

    api: {
      type: Object,
      required: true
    },

    analysis: {
      type: Object,
      required: true
    }

  },
  data: function () {
    return {
      currentVoice: undefined,
      category: "responses",
      answerValue: "",
      status: 1,
      editTimeWindow: 0,
      isEditable: true,
      startedAsFlagged: false,
      detailPanel: undefined,
      processing: false,
      statusChanged: false,
      detailPanelComponents: {
        respondents: "SidePanelRespondents"
      }
    }
  },
  methods: {

    toggleFlagged: function(){
      this.status = !this.status
      this.statusChanged = true;
    },

    onDetailUpdate: function(update){
      console.log(update);

      if(update.field === "respondent"){
        this.currentVoice = update.value;
      }
    },

    addRespondent: function(){
      this.detailPanel = {
        component: this.detailPanelComponents.respondents,
        payload: null
      }
    },

    editRespondent: function(){
      this.detailPanel = {
        component: this.detailPanelComponents.respondents,
        payload: this.respondentInfo
      }
    },

    onRespondentChange: function(){
      if(this.currentVoice === 0){
        this.addRespondent();
      }
    },

    closeDetailPanel: function(){
      this.detailPanel = null;
    },


    close: function(){
      this.$emit('close');
    },

    save: async function(){

      console.log("saving");


      let user =  this.reviewer;
      let editTime = Date.now();

      let _answer = this.answer?this.answer:this.api.generateBlankComment(this.question.id);
      _answer.value = this.answerValue;
      _answer.reviewer = this.isEditable?user:this.answer.reviewer;
      _answer.question = this.question.id;
      _answer.respondent = this.respondentInfo.id;
      _answer.ts = this.isEditable?editTime:this.answer.ts;
      _answer.status = this.status;

      if(!_answer.id){
        _answer.id = _answer.ts + "_"+this.question.id;

      }



      let updateData = { }
      updateData[this.category] = {}
      updateData[this.category][_answer.id] = _answer

      //simulated
      this.$set(this.analysis[this.category], _answer.id, _answer);
      this.processing = true;

      await this.api.update( this.analysis.id, updateData,user)
          .then(() => {

            this.processing = false;
            this.analysis[this.category][_answer.id] = _answer;

          })
          .catch((error) => {
            console.error(error)
            alert("There was a problem processing this request.")
            return false;
          })

      this.processing = false;

      //console.log(answer);

      console.log("Updating session...");

      let shouldUpdateSession = false;

      if(this.session && this.session.respondent){

        if(this.session.respondent !== this.currentVoice){
          shouldUpdateSession = true;
        }
      }else if(!this.session){
        shouldUpdateSession = true;
      }

      if(shouldUpdateSession){
        if(confirm(`Would like to use ${this.respondentInfo.name} as your default respondent?`)){
          this.$emit("update-session", {
            "key": "respondent",
            "value": _answer.respondent
          })
        }
      }

      this.close();

    }
  },
  computed: {

    editableMsg: function(){
        if(this.answer){
          if(this.answer.reviewer === this.reviewer){
            let remainingTime = (24 * 60 * 60 * 1000) - this.editTimeWindow;
            if(remainingTime > 0){
              return "This response is editable by you for first 24 hours."
            }
            else {
              return "This response is not longer editable."
            }
          }else {
            return "This response is not editable."
          }
        }

        return ""

    },

    isValid: function(){
      return (this.processing || (this.isEditable && this.respondentInfo && this.answerValue) || (this.statusChanged && this.respondentInfo && this.answerValue))
    },

    reviewer: function(){
      return this.$store.getters.getUserProfile.email;
    },

    respondents: function(){
      return this.analysis.respondents;
    },

    respondentInfo: function(){
      if(this.respondents){
        if(this.currentVoice){
          return this.respondents[this.currentVoice];
        }
      }

      return null
    },

    respondentOptions: function(){

      this.detailPanel;

      let _respondents = Object.keys(this.respondents);
      if(_respondents.length){
        return _respondents.map((respondent) => {
          return {
            id: respondent,
            label: `${this.respondents[respondent].name} - ${this.api.schema.voiceOptions[this.respondents[respondent].department].label}`
          }
        })
      }else{
        return [
          { id: 0, label: "Add New Respondent"}
        ]
      }

    },

    question: function(){
      if(this.category === "responses"){
        return this.api.schema.questions[this.editInfo.questionId]
      }

      return this.api.schema[this.category][this.editInfo.questionId];

    },

    voiceLabels: function(){
      return this.question.voices.map((voiceId) => {
        return this.api.schema.voiceOptions[voiceId].label
      })
    },

    voiceId: function(){
      return Object.keys(this.api.schema.voiceOptions).find((department) => {
        return this.api.schema.voiceOptions[department].label === this.currentVoice;
      })
    },

    answer: function(){
      return this.analysis[this.category][this.editInfo.responseId];
    },

    lastEditor: function(){
      if(this.answer && this.answer.reviewer){
        return this.answer.reviewer;
      }

      return "";
    },

    lastEditTime: function(){
      if(this.answer && this.answer.ts){
        return this.$timeAgo.format(this.answer.ts);
      }

      return ""
    }
  }
}
</script>

<style scoped>


.side-panel-new-assessment {

}

</style>
