


<template>
<div class="">
  <h4>Facility Best Practices</h4>
  <hr>
  <p>The facility should take into consideration administration offices, computer room, production space, employee break area. The production area requires a loading dock, sealed concrete floor, proper light and industrial electrical service. The production space will need to accommodate the equipment, workstations, workspace and raw material storage.</p>
  <p class="bold-label">Best practices to optimize the production area:</p>
  <ul>
    <li>Logical / efficient job flow</li>
  <li>Economic utilization of space</li>
  <li>Reduced steps and employee fatigue</li>
  <li>Increased productivity</li>
  <li>Maximizing production space</li>

  </ul>
</div>
</template>

<script>
export default {
    name: "facility-best-practices",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


</style>
