import faunadb from 'faunadb';
import schema from '../schema/needs'
import {writeFileXLSX,utils} from "xlsx";

const q = faunadb.query;
const collection = "needsAnalysis";


function defaultValueForType(dataType){
    let defaultValue = false;

    switch (dataType) {
        case 1:
            defaultValue = '';
            break;

        default:
            defaultValue = '';
    }

    return defaultValue;
}

function defaultAnswers(questions) {

    let answers = {};
    for (let questionId in questions) {
        let question = questions[questionId];
        answers[questionId] = defaultAnswer(question);
    }

    return answers;
}

function generateBlankComment(qId) {

    let comment = {
        id: 0, //should be set at first save
        reviewer: "",
        question: qId,
        ts: 0,
        value: "",
        voice: "",
        session: "", //session id
        respondent: "",
        status: 1 // 0 to disregard, 2 for important
    }

    return comment
}

function generateSession() {



    let session = {
        id: 0, //should be set at first save
        reviewer: "",
        ts: 0,
        voice: "",
        respondent: "",
        notes: "",
        date: "",
        respondentTitle: ""

    }

    return session
}

function generateRespondent() {



    let respondent = {
        id: 0, //should be set at first save
        reviewer: "",
        ts: 0,
        name: "",
        notes: "",
        title: ""
    }

    return respondent
}

function defaultComments(questions) {

    let comments = {};
    for (let questionId in questions) {
        //let question = questions[questionId];
        comments[questionId] = {};
    }

    return comments;
}

function defaultAnswer(question) {
    let answer = {
        lastEdit: null,
        voices: {}
    };
    Object.keys(schema.voiceOptions).forEach((voice) => {
        answer.voices[voice] = {
            reviewer: "",
            ts: 0,
            value: defaultValueForType(question.type),
            edits: []
        };
    })

    answer.voices.notes = {
        reviewer: "",
        ts: 0,
        value: defaultValueForType(1),
        edits: []
    };

    return answer;
}

function getCount(){
    let token = localStorage.getItem("token");
    var client = new faunadb.Client({ secret: token })

    return client.query(
        q.Count(
            q.Paginate(
                q.Documents(q.Collection(collection)),
                {size: 10000}
            )
        ),
    ).then((response) => {

        console.log(response)

        if(response.data){
            return response.data
        }else{
            throw new Error("Network error.");
        }
    })
}


function getAll(size=100){
    let token = localStorage.getItem("token");
    var client = new faunadb.Client({ secret: token })

    return client.query(
        q.Paginate(
            q.Match(
                q.Index(collection+"_by_ts_city_state_se_org_created")
            ),
            {size: size}
        ),
    ).then((response) => {

        console.log(response)

        if(response.data){
            return response.data
        }else{
            throw new Error("Network error.");
        }
    })
}

function load(recordId){
    let token = localStorage.getItem("token");

    var client = new faunadb.Client({ secret: token })

    return client.query(
        q.Get(q.Ref(q.Collection(collection), recordId))
    ).then((response) => {


        if(response.data){
            let record = response.data;
            record.id = response.ref.value.id;
            return record;
        }else{
            throw new Error("Document error.");
        }
    })
}

function responsesByQuestion(doc){
    let _responsesByQuestion = {};

    if(!(doc.responses)){
        return _responsesByQuestion;
    }
    let rKeys = Object.keys(doc.responses);

    if(rKeys.length){

        rKeys.forEach((rKey) => {
            let response = doc.responses[rKey];
            if(!_responsesByQuestion[response.question]){
                _responsesByQuestion[response.question] = {}
            }
            _responsesByQuestion[response.question][rKey] = response;
        })

        return _responsesByQuestion;
    }
    return _responsesByQuestion;

}

async function exportReport(){

    let token = localStorage.getItem("token");

    var client = new faunadb.Client({ secret: token })
    let loadNext = true;
    let after

    let records = []

    do{
        let options = { size: 100}
        if(after){
            options.after = q.Ref(q.Collection(collection),after[0].value.id)
        }

        let _records = await client.query(
            q.Map(q.Paginate(
                    q.Match(
                        q.Index("all_needs_analysis")
                    ),
                    options
                ),
                q.Lambda("X", q.Get(q.Var("X")))
            ),
        ).then((response) => {
            console.log(response)
            after = response.after
            if(!after){ loadNext = false }
            if(response.data){
                return response.data
            }else{
                throw new Error("Network error.");
            }
        })

        if(_records){
            records = records.concat(_records)
        }

    }while(loadNext)



    //console.log(records)

    let headers = [
        {group:"customerProfile",key:"organizationName",label:"Org Name",width:"32",heading:"Customer Profile"},
        {group:"created",key:"",label:"Created",width:"32", type: "date"},
        {group:"userProfile",key:"email",label:"Created By",width:"32"},
        {group:"lastEdit",key:"ts",label:"Last Edit",width:"32", type:"date"},
        {group:"lastEdit",key:"user",label:"Last Edit By",width:"32"},
        {group:"customerProfile",key:"organizationCode",label:"ORC CF Cust #:",width:"12"},
        {group:"customerProfile",key:"organizationCode2",label:"ORC CS Cust #:",width:"12"},
        {group:"customerProfile",key:"customerContact",label:"Customer Contact",width:"32"},
        {group:"customerProfile",key:"customerPhone",label:"Phone",width:"12"},
        {group:"customerProfile",key:"customerCell",label:"Cell",width:"12"},
        {group:"customerProfile",key:"address",label:"Address",width:"32"},
        {group:"customerProfile",key:"city",label:"City",width:"24"},
        {group:"customerProfile",key:"state",label:"State",width:"12"},
        {group:"customerProfile",key:"country",label:"Country",width:"18"},
        {group:"customerProfile",key:"postalcode",label:"Postal",width:"8"},
        {group:"customerProfile",key:"customerEmail",label:"Email",width:"32"},
        {group:"customerProfile",key:"itContact",label:"IT Contact",width:"32"},
        {group:"customerProfile",key:"itPhone",label:"IT Phone",width:"12"},
        {group:"customerProfile",key:"itEmail",label:"IT Email",width:"32"},
        {group:"customerProfile",key:"seName",label:"SE Name",width:"32"},
    ]

    let sectionList = [
        { label: "General Info", id: "generalInfo", sections:["generalInfo"]},
        { label: "Equipment", id: "equipment", sections:["equipment"]},
        { label: "Workflow", id: "workflow", sections:["workflow"]},
        { label: "Security", id: "security", sections:["security"]},
        { label: "Color", id: "color", sections:["color"]},
        { label: "Color Discovery", id: "colorDiscovery", sections:["colorDiscovery"]},
        { label: "Cross Media", id: "crossMedia", sections:["crossMedia"]},
        { label: "Industrial Print", id: "industrialPrint", sections:["industrialPrint"]},
        { label: "Advanced Solutions", id: "advancedSolutions", sections:["advancedSolutions"]},
        { label: "Advanced Finishing", id: "advancedFinishing", sections:["advancedFinishing"]},
    ]

    sectionList.forEach((sectionInfo)=>{

        let section = schema.sections[sectionInfo.id]
        section.groups.forEach((group, gIndex)=>{
            group.questions.forEach((qId, qIndex)=>{
                let col = {

                    group: "responses",
                    key: qId,
                    label: schema.questions[qId].label,
                    width: 0,
                    heading:(gIndex + qIndex)?"":section.label,
                }

                headers.push(col)
            })
        })


    })


    let report = records.map((record)=>{

        let responses = responsesByQuestion(record.data)

       return headers.map((header)=>{

           let val = ""
           if(header.group === "responses"){
               if(responses[header.key]){
                   let response = Object.keys(responses[header.key]).map((resId)=>{
                       return `${responses[header.key][resId].reviewer}\n${responses[header.key][resId].value}`
                   })

                   val = response.join("\n\n")
               }
               return val
           }



           if(header.key){
               val = record.data[header.group][header.key]
           }else{
               val = record.data[header.group]
           }
           if(header.type === 'date'){
               val = new Date(val).toLocaleDateString()
           }
           return val
       })
    })

    report.unshift(headers.map((header)=>{
        return header.label
    }))





    const worksheet = utils.aoa_to_sheet(report)
    worksheet["!cols"] = headers.map((header,index)=>{
        if(header.width){
            return {
                wch: report.reduce((w, r) => Math.max(w, r[index].length), 10)
            }
        }else{
            return {
                wch: 32
            }
        }

    })
    const workbook = utils.book_new()
    utils.book_append_sheet(workbook,worksheet,"Needs Analysis Data Export")
    writeFileXLSX(workbook,`Needs_Analysis_Export.xlsx`,{compression:true})

}

function generate(options){

    let created = Date.now();
    let record = {
        created: created,
        documentType: "Needs Analysis",
        admin: options.user.userid,
        user: options.user.userid,
        lastEdit: null,
        customerProfile: {
            "customerContact" : "",
            "customerEmail": "",
            "customerPhone": "",
            "customerCell": "",
            "assessmentDate" : "",
            "assessmentType" : options.customer.assessmentType,
            "address" : "",
            "organizationName" : options.customer.organizationName,
            "organizationCode": options.customer.organizationCode,
            "organizationCode2": options.customer.organizationCode2,
            "organizationId": options.customer.organizationId,
            "city" : "",
            "state" : "",
            "country" : "",
            "postalcode" : "",
            "email": "",
            "itContact": "",
            "itPhone":"",
            "itEmail":"",
            "seName":"",
            "pps":""
    },
        userProfile: options.user,
        answers: defaultAnswers(schema.questions),
        comments: defaultComments(schema.questions),
        sessions: {},
        responses: {},
        respondents: {

        },
        reviewers: {},
        notes: {
            background: {},
            summary: {},
            other: {}
        },
        exportSettings: {
            notes: {
                summary: true,
                background: true
            },
            respondents: [],
            departments: "all",
            showBlanks: true
        }
    }

    let defaultRespondent = generateRespondent();
    defaultRespondent.id = options.user.userid;
    defaultRespondent.title = options.user.title;
    defaultRespondent.reviewer = options.user.email;
    defaultRespondent.ts = Date.now();
    defaultRespondent.department = "notes"
    defaultRespondent.name = options.user.firstname + " " + options.user.lastname;

    record.respondents[options.user.userid] = defaultRespondent


    return record;
}

function update(recordId,record,user){

    console.log("saving...");



    if(recordId){

        let token = localStorage.getItem("token");
        var client = new faunadb.Client({ secret: token })

        record.lastEdit = {
            ts: Date.now(),
            user: user
        }

        return client.query(
            q.Update(
                q.Ref(q.Collection(collection), recordId),
                {
                    data: record
                }
            )
        ).then((response) =>{
            return response;
        })
    }

}

function save(record,user){

    console.log("saving...");
    let token = localStorage.getItem("token");
    var client = new faunadb.Client({ secret: token })

    record.lastEdit = {
        ts: Date.now(),
        user: user
    }

    if(record.id){
        return client.query(
            q.Update(
                q.Ref(q.Collection(collection), record.id),
                {
                    data: record
                }
            )
        ).then((response) =>{
            return response;
        })
    }else{
        return client.query(
            q.Create(
                q.Collection(collection),
                {
                    data: record
                }
            )
        ).then((response) =>{
            return response;
        })
    }


}

function deleteRecord(recordId){
    let token = localStorage.getItem("token");
    var client = new faunadb.Client({ secret: token })

    return client.query(
        q.Delete(
            q.Ref(q.Collection(collection), recordId))
    ).then((response) =>{
        return response;
    })
}


export default {
    generate,
    save,
    load,
    update,
    getAll,
    getCount,
    exportReport,
    delete:deleteRecord,
    schema: schema,
    defaultAnswers: defaultAnswers,
    defaultComments: defaultComments,
    defaultAnswer: defaultAnswer,
    generateBlankComment,
    generateSession,
    generateRespondent
}
