


<template>
  <div v-if="r" id="report">

    <section class="">
      <table class="titlebox absolute text-white" style="font-size: 1vw; left: 11em; width:28em; margin-top: 11em;">
        <tr><td class="font-bold" style="font-size: 2.5em; padding-bottom: 0.5em;" data-cell-style="TitlePageTitleCell">NEEDS ANALYSIS</td></tr>
        <tr><td class="font-bold leading-none" style="font-size: 2em; padding-bottom: 1em;" data-cell-style="TitlePageSubtitleCell">{{ r.customerProfile.organizationName }}</td></tr>
        <tr><td class="leading-snug" style="font-size: 1.2em;" data-cell-style="TitlePageCell">Confidential: For the exclusive use of Ricoh.  This document cannot be shared with any other parties without written permission from all of the parties referenced here.</td>
        </tr>
        <tr><td style="padding-bottom: 2em;">  </td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell">V1.0</td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell"><span>{{ r.userProfile.firstname }} {{ r.userProfile.lastname }}</span></td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell"><span>{{ r.userProfile.title }}</span></td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell"><span>{{ currentDate }}</span></td></tr>
      </table>

      <img class="w-full -mx-8 block" data-float="true" data-ratio="1.2941" src="/reports/title-bkg.png" />



      <p></p>



    </section>

    <section>
      <p class="toc-title">TABLE OF CONTENTS</p>
      <p data-toc="true" data-alias="Table of Contents" data-levels="1-2">Table of Contents here.</p>
    </section>

    <section>
      <h1>Overview</h1>
      <h2>Background</h2>
      <p>This document contains questions and responses for <span class="font-bold">{{ r.customerProfile.organizationName }}</span> that are relevant to <span class="font-bold">{{ departmentLabel }}</span>, as well as any related analysis or notes from our team.</p>

      <p class="font-bold" v-if="analysis.exportSettings.notes.background">Notes:</p>
      <template v-for="note in backgroundNotes">
        <p v-if="analysis.exportSettings.notes.background" data-bullet="true"  :key="note.id"><span :title="note.id" data-type="background" @click="editNote" >{{ note.value }}</span> <span class="text-sm text-gray-500 pointer-events-none">{{ r.respondents[note.respondent].name }} - {{ dateString(note.ts) }}</span></p>
      </template>

      <br>

      <h2>Organization</h2>
      <table>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Org Name:</td><td><span title="customerProfile" type="" @click="editOrg">{{ r.customerProfile.organizationName }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>ORC Continuous Feed Cust #:</td><td><span title="customerProfile" type="" @click="editOrg">{{ r.customerProfile.organizationCode }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>ORC Cut Sheet Cust #:</td><td><span title="customerProfile" type="" @click="editOrg">{{ r.customerProfile.organizationCode2 }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Customer Contact</td><td></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Name:</td><td><span title="customerProfile" type="" @click="editOrg">{{ r.customerProfile.customerContact }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Phone:</td><td><span title="customerProfile" type="" @click="editOrg">{{ r.customerProfile.customerPhone }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Cell:</td><td><span title="customerProfile" type="" @click="editOrg">{{ r.customerProfile.customerCell }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Address:</td><td><span title="customerProfile" type="" @click="editOrg">{{ r.customerProfile.address }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>City:</td><td><span title="customerProfile" type="" @click="editOrg">{{ r.customerProfile.city }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>State:</td><td><span title="customerProfile" type="" @click="editOrg">{{ r.customerProfile.state }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Country:</td><td><span title="customerProfile" type="" @click="editOrg">{{ r.customerProfile.country }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Postal Code:</td><td><span title="customerProfile" type="" @click="editOrg">{{ r.customerProfile.postalCode }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Email:</td><td><span title="customerProfile" type="" @click="editOrg">{{ r.customerProfile.email }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Other Contact Info:</td><td></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>IT Contact:</td><td><span title="customerProfile" type="" @click="editOrg">{{ r.customerProfile.itContact }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>IT Phone:</td><td><span title="customerProfile" type="" @click="editOrg">{{ r.customerProfile.itPhone }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>IT Email:</td><td><span title="customerProfile" type="" @click="editOrg">{{ r.customerProfile.itEmail }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>SE Name:</td><td><span title="customerProfile" type="" @click="editOrg">{{ r.customerProfile.seName }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>PPS:</td><td><span title="customerProfile" type="" @click="editOrg">{{ r.customerProfile.pps }}</span></td>
        </tr>

      </table>
      <br>

      <h2>Respondent Info</h2>
      <table>
        <tr>
          <th>Name</th><th>Title</th><th>Department</th><th>Notes</th>
        </tr>
        <tr v-for="respondent in selectedRespondents" :key="respondent" @click="editSettings">
          <td>{{ analysis.respondents[respondent].name }}</td><td><span>{{ analysis.respondents[respondent].title }}</span></td><td><span>{{ (analysis.respondents[respondent].department === "notes")?"Ricoh Team":schema.voiceOptions[analysis.respondents[respondent].department].label }}</span></td><td><span>{{ analysis.respondents[respondent].notes }}</span></td>
        </tr>
      </table>






    </section>
    <section v-for="section in filteredSections" :key="section.id">
      <h1>{{ section.label }}</h1>

      <template v-for="(group,index) in section.groups">

        <h2 v-if="group.label" :key="section.id + '-'+index+'l'">{{ group.label }}</h2>
        <template v-for="(question, index) in group.questions">
          <div data-container="true" :key="question" v-if="isRelevant(question)">
            <h3>{{ index + 1 }}. {{ schema.questions[question].label }}</h3>

            <p v-for="response in responses[question]" :key="response.id" data-bullet="true">
              <span :data-question="question" :data-response="response.id" @click="editResponse">{{ response.value }}</span> <span class="text-sm text-gray-500 pointer-events-none">( {{ dateString(response.ts) }} - {{ r.respondents[response.respondent].name }} / {{ r.respondents[response.respondent].title }} )</span> <span class="font-bold text-red-500" :data-question="question" :data-response="response.id" @click="editResponse" v-if="!response.status">FLAGGED FOR FOLLOWUP</span>
            </p>


            <p  v-if="r.exportSettings.showBlanks" class="text-gray-500 footnote mt-4" data-bullet="true">
              <span class="w-64" :title="question" :data-question="question" @click="addResponse">_______________________________________________________________________</span>
            </p>
            <p v-else-if="!responses[question]" class="text-gray-500 footnote">
              <span :data-question="question" @click="addResponse">No responses for this question.</span>
            </p>


          </div>


        </template>

      </template>


    </section>
    <section>
      <h1>Summary</h1>
      <h2>Team Notes</h2>
      <template v-for="note in summaryNotes">
        <p v-if="analysis.exportSettings.notes.summary" data-bullet="true"  :key="note.id"><span :title="note.id" data-type="summary" @click="editNote" >{{ note.value }}</span> <span class="text-sm text-gray-500 pointer-events-none">{{ r.respondents[note.respondent].name }} - {{ dateString(note.ts) }}</span></p>
      </template>
      <p v-if="!summaryNotes.length"><span data-type="summary" @click="editNote">There are no summary notes at this time.</span></p>

    </section>









  </div>
</template>

<script>
import schema from "@/schema/needs";


export default {
    name: "report-needs-analysis",
  components: {

  },
  mounted: function(){
    this.$emit("mounted");
  },
    props: {
      analysis: {
        required: true,
      },
      schema: {
        required: true
      }
    },
    data: function(){
        return {

        }
        },
    methods: {


      isRelevant: function(qId){

        //console.log(qId);

        if(this.analysis.exportSettings){
          if(this.department.includes("all") || this.department.includes("notes")){
            return true;
          }else{
            return this.department.some((voice) => {
              return this.schema.questions[qId].voices.includes(voice)
            });
          }
        }

        return true;
      },

      dateString: function(ts){
        let date = new Date(ts);
        return (date.getMonth()+1) + "/"+date.getDate() + "/"+date.getFullYear();
      },

      editOrg: function(){
        if(confirm("Exit the preview and update the organization profile for this document?")){
          this.$router.push({
            name: 'NeedsAnalysisDetails',
            params: {
              analysis: this.$route.query.r
            },
            query: {
              tab: "org"
            }
          })
        }
      },



      editSettings: function(){
        if(confirm("Exit the preview and update the export settings for this document?")){
          this.$router.push({
            name: 'NeedsAnalysisDetails',
            params: {
              analysis: this.$route.query.r
            },
            query: {
              tab: "summary",
              section: "reportexport"
            }
          })
        }
      },

      editNote: function(e){
        if(confirm(`Exit the preview and update the ${e.target.dataset.type} notes for this document?`)){
          this.$router.push({
            name: 'NeedsAnalysisDetails',
            params: {
              analysis: this.$route.query.r
            },
            query: {
              tab: "summary",
              section: "report"+e.target.dataset.type
            }

          })
        }
      },

      addResponse: function(e){
        this.$emit("edit", e)
      },

      editResponse: function(e){
        this.$emit("edit", e);
      },

      onChange: function(e){

        this.$emit("change",e);

      }
    },
    computed: {

      selectedRespondents: function(){
        if(this.analysis.exportSettings){
          return this.analysis.exportSettings.respondents
        }
        return null
      },

      orgRespondents: function(){

        let _respondents = this.selectedRespondents?this.selectedRespondents:Object.keys(this.analysis.respondents);

        return _respondents.filter((respondent) => {
          if(this.analysis.respondents[respondent]){
            return (this.analysis.respondents[respondent].department !== "notes")
          }
          return false;
        })

      },

      teamRespondents: function(){
        let _respondents = this.selectedRespondents?this.selectedRespondents:Object.keys(this.analysis.respondents);

        return _respondents.filter((respondent) => {
          if(this.analysis.respondents[respondent]){
            return (this.analysis.respondents[respondent].department === "notes")
          }
          return false;
        })
      },

      department: function(){
        if(this.analysis.exportSettings) {
          let voice = (this.analysis.exportSettings.departments &&  this.analysis.exportSettings.departments.length) ? this.analysis.exportSettings.departments : ["all"];
          return voice;
        }

        return ["all"]
      },

      departmentLabel: function(){
        if(this.department.includes('all')){
          return "All Departments"
        }else {
          if(this.department.length > 1){
            let labels = this.department.map((departmentId)=> {
              return this.schema.voiceOptions[departmentId].label
            })
            return `${labels.splice(0,labels.length-1).join(",")} and ${labels[labels.length-1]}`;
          }else{
            return this.schema.voiceOptions[this.department[0]].label
          }
        }
      },

       r: function(){
         return this.analysis;
       },

      q: function(){
        return this.r.answers;
      },


      iQ: function(){
        return schema.questions;
      },

      notes: function(){
        if(this.r){
          return Object.keys(this.r.notes).map((id) => {
            return this.r.notes[id]
          })
        }
        return [];
      },

      backgroundNotes: function(){
         return this.notes.filter((note) => {
           return note.question === "background"
         })
      },

      summaryNotes: function(){
        return this.notes.filter((note) => {
          return note.question === "summary"
        })
      },

      responses: function(){



         if(this.r){
           let _responses = {};

              Object.keys(this.r.responses).forEach((rId) => {

                let qId = this.r.responses[rId].question;

                if(!_responses[qId]){
                  _responses[qId] = [];
                }

                _responses[qId].push(this.r.responses[rId]);
              })


           return _responses;
         }

         return null;
      },

      currentDate: function(){
        return (new Date(Date.now())).toLocaleDateString()
      },

      filteredSections: function(){
        let sections = {};
        Object.keys(this.schema.sections).forEach((sectionId) => {
          let section = this.schema.sections[sectionId];
          let groups = [];
          section.groups.forEach((group) => {
            let questions = group.questions.filter((qId) => {
              return this.isRelevant(qId);
            })

            if(questions.length){
              groups.push({
                label: group.label,
                questions: questions
              })
            }
          })
          if(groups.length){
            sections[sectionId] = {
              id: section.id,
              label: section.label,
              description: section.description,
              groups: groups
            }
          }
        })

        return sections;

      }


    }
}
</script>

<style scoped>


.report-production-workflow {

}

p[data-bullet="true"]{
  display: list-item;
  margin-left: 1em;
  margin-bottom: 0;
}

p[data-bullet="true"]:last-child {
  margin-bottom: 1em;
}

</style>
