export default {
  "reportTitle": "Site Configuration Report",
  "groups":{
    "Organization Data":{ "label":"Organization Data", "id":"orgdata","questions":[

      "X200",
      "X201",
      "X202",
      "I1003",
      "X118",
      "X119",
        "X119B",
        "X119C",
      "X203",
      "X204",
      "X205",
        "X206",
    ]},
    "Software & Equipment":{ "label":"Software & Equipment", "id":"software","questions":[
      "X120",
      "X121",
      "X207",
      "X208",
      "X209",
      "X210",
      "X211",
      "X212",
      "X213",
    ]},
    "Preference Management":{ "label":"Preference Management", "id":"prefs","questions":[
        "X154",
        "X155",
        "X156",
        "X157"
      ]},
    "Campaign Management":{ "label":"Campaign Management", "id":"campaigns","questions":[
        "X158",
        "X159",
        "X160"
      ]},
    "Content and Data Management":{ "label":"Content and Data Management", "id":"contentdata","questions":[
        "X132",
        "X133",
        "X134",
        "X135",
        "X136",
        "X137",
        "X214",
        "X215",
        "X216",
        "X217"
      ]},
    "Approval Processes":{ "label":"Approval Processes", "id":"approvals","questions":[
        "X141",
        "X218",
        "X219",
        "X220",
        "X221",
      ]},

    "Document Archival":{ "label":"Document Archival", "id":"archival","questions":[
        "X222",
        "X223",
        "X224",
        "X225",
        "X226",
        "X227",
      ]},

    "Communications/Correspondence Methods":{ "label":"Communications / Correspondence Methods", "id":"communication","questions":[
        "X143"
      ]},

    "Production Resources/Sourcing":{ "label":"Production Resources/Sourcing", "id":"proresources","questions":[
        "X228",
        "X229"
      ]},

    "Distribution/Mailing Procedures":{ "label":"Distribution/Mailing Procedures", "id":"distribution","questions":[
        "X230",
        "X231",
        "X232"
      ]},

    "Inventory Management":{ "label":"Inventory Management", "id":"inventory","questions":[
        "X233",
        "X234",
        "X235",
        "X236"
      ]},

    "Service Level Agreements":{ "label":"Service Level Agreements", "id":"sla","questions":[
        "X237",
        "X238",
        "X239"
      ]},

    "Reports":{ "label":"Reports", "id":"reports","questions":[
        "X240",
        "X241",
        "X242"
      ]},

    "Tracking/Auditing Compliance":{ "label":"Tracking/Auditing Compliance", "id":"tracking","questions":[
        "X147",
        "X148",
        "X149",
        "X243",
        "X244",
        "X245",
        "X246",
        "X169",
        "X170",
        "X171"
      ]},

    "Disaster Recovery":{ "label":"Disaster Recovery", "id":"disaster","questions":[
      "X165",
      "X166",
        "X167",
        "X168"
    ]}
  },

  "stages":{
    "Organization Data":{ "id": "orgdata", "markerLabel": "Organization<br>Data", "label": "Organization Data", "sections":["orgdata"],
      "instructions": "Please fill out the forms below from information provided by the Customer."
    },
    "Software & Equipment":{ "id": "platforms", "markerLabel": "Software &<br>Equipment", "label": "Software and Equipment", "sections":["software"], "instructions": "Please fill out the forms below from information provided by the Customer."},
    "Customer Communications":{ "id": "customerComm", "markerLabel": "Customer<br>Communications", "label": "Customer Communications", "sections":["prefs","campaigns","contentdata","approvals","archival","communication"], "instructions": "Please fill out the forms below from information provided by the Customer."},
    "Print / Mail":{ "id": "printMail", "markerLabel": "Print/Mail", "label": "Print/Mail", "sections":["proresources","distribution","inventory","sla"], "instructions": "Please fill out the forms below from information provided by the Customer."},
    "Administration":{ "id": "admin", "markerLabel": "Administration", "label": "Administration", "sections":["reports","tracking","disaster"], "instructions": "Please fill out the forms below from information provided by the Customer."},
  },


  "questions":{

    "I1003":{
      "id": "I1003",
      "num": 1,
      "type":2,
      "question": "Total Number of Jobs per Year",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X106":{
      "id": "X106",
      "num": 1,
      "type":8,
      "question": "Departments Reviewed",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "choices": [
          "Executive Management (CXO/CMO/CEO)",
        "Business Development",
        "Marketing",
        "Customer Service",
        "Legal/Compliance",
        "IT",
        "Operations/Output Services",
        "Department/Business Unit Head(s)"
      ]
    },

    "X107":{
      "id": "X107",
      "num": 1,
      "type":2,
      "question": "Total Quantity of Templates Developed Annually",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X108":{
      "id": "X108",
      "num": 1,
      "type":2,
      "question": "Total Quantity of Template Changes Annually",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X109":{
      "id": "X109",
      "num": 1,
      "type":2,
      "question": "Average quantity of programmed pages per template",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X110":{
      "id": "X110",
      "num": 1,
      "type":2,
      "question": "Total Quantity of Monthly Print Communications Processed thru Templates",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X111":{
      "id": "X111",
      "num": 1,
      "type":2,
      "question": "Total Quantity of Monthly Email Communications Processed thru Templates",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X112":{
      "id": "X112",
      "num": 1,
      "type":2,
      "question": "Total # of Employees who Process Communications",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X113":{
      "id": "X113",
      "num": 1,
      "type":2,
      "question": "Print Communications Developers",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X114":{
      "id": "X114",
      "num": 1,
      "type":2,
      "question": "Email Communications Developers",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X115":{
      "id": "X115",
      "num": 1,
      "type":2,
      "question": "Automation or Processing Staff",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X116":{
      "id": "X116",
      "num": 1,
      "type":2,
      "question": "Administrative/Project Managers",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X117":{
      "id": "X117",
      "num": 1,
      "type":2,
      "question": "",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X118":{
      "id": "X118",
      "num": 1,
      "type":2,
      "question": "Total Quantity of Impressions Produced per Year on Digital Cut-Sheet Equipment - Color",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X119":{
      "id": "X119",
      "num": 1,
      "type":2,
      "question": "Total Quantity of Impressions Produced per Year on Digital Cut-Sheet Equipment - Monochrome",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X119B":{
      "id": "X119B",
      "num": 1,
      "type":2,
      "question": "Total Sq Ft of Images Produced per Year on Wide Format Equipment",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X119C":{
      "id": "X119C",
      "num": 1,
      "type":2,
      "question": "Total Quantity of Impressions Produced per Year on Continuous Feed Inkjet Equipment",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X120":{
      "id": "X120",
      "num": 1,
      "type":8,
      "question": "Software",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":true,
      "choices": [
        "Quadient Inspire",
        "OpenText Exstream",
        "PlanetPress",
        "Documaker",
        "Documerge",
        "Adobe",
        "Uluro",
        "Isis Papyrus",
        "Elixir Tango",
        "MessagePoint",
        "SmartComm Smart Communications",
        "CinCom",
        "EngageOne",
        "DOC1",
        "MS Office Suite",
        "XMPIE",
        "Other Module or Details"
      ]
    },
    "X121":{
      "id": "X121",
      "num": 1,
      "type":8,
      "question": "Platforms",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":true,
      "choices": [
        "Salesforce",
        "Oracle",
        "Guidewire",
        "WyPro",
        "SAP",
        "NCR",
        "IBM",
        "Other"
      ]
    },
    "X122":{
      "id": "X122",
      "num": 1,
      "type":8,
      "question": "Design Request Software",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":true,
      "choices": [
          "Adobe Creative Cloud",
        "Homegrown System",
        "Excel",
        "Monday",
        "Hive",
        "Wryke",
        "Smart Sheet",
        "Quadient Inspire Interactive",
        "Workgroups DaVinci",
        "Workfront Marketing",
        "Other"
      ]
    },
    "X123":{
      "id": "X123",
      "num": 1,
      "type":8,
      "question": "Prioritization and daily status are managed actively by management using:",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":true,
      "selectOne": true,
      "choices": [
        "SLA",
        "FIFO",
        "Other"
      ]
    },
    "X124":{
      "id": "X124",
      "num": 1,
      "type":8,
      "question": "Customer Journey Mapping",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "selectOne": true,
      "trigger" : {
        "target": "X124A",
        "condition": "has been implemented"
      },
      "choices": [
        "has been implemented",
        "is being investigated",
        "is not being investigated"
      ]
    },
    "X124A":{
      "id": "X124A",
      "num": 1,
      "type":8,
      "question": "Customer Journey Mapping is processed with",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "enabled": false,
      "hidden":true,
      "selectOne": true,
      "allowOther": true,
      "otherPlaceholder": "Enter mapping software here",
      "choices": [
        "Manual Processes",
        "Software"
      ]
    },
    "X125":{
      "id": "X125",
      "num": 1,
      "type":3,
      "question": "Additional Customer Journey Mapping Comments",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X126":{
      "id": "X126",
      "num": 1,
      "type":5,
      "question": "Communications are designed for",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "choices": [
        "print",
        "electronic/digital distribution",
        "both print and electronic distribution"
      ]
    },
    "X127":{
      "id": "X127",
      "num": 1,
      "type":8,
      "question": "Print pieces are programmed with",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":true,
      "choices": [
        "Quadient Inspire",
        "OpenText Exstream",
        "PlanetPress",
        "Documaker",
        "Documerge",
        "Adobe",
        "Uluro",
        "Isis Papyrus",
        "Elixir Tango",
        "MessagePoint",
        "SmartComm Smart Communications",
        "CinCom",
        "EngageOne",
        "DOC1",
        "MS Office Suite",
        "XMPIE",
        "Adobe Creative Suite (including Dreamweaver and XD)",
        "Other"
      ]
    },
    "X128":{
      "id": "X128",
      "num": 1,
      "type":8,
      "question": "Electronic pieces are programmed with",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":true,
      "choices": [
        "Mosaico",
        "Email",
        "Monster",
        "BEE",
        "Quadient Inspire",
        "Open Text Exstream",
        "Smart Comm",
        "Other"
      ]
    },
    "X129":{
      "id": "X129",
      "num": 1,
      "type":3,
      "question": "Please provide any detail on requirements to redesign your statements or rebrand in the near future.",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X130":{
      "id": "X130",
      "num": 1,
      "type":3,
      "question": "Provide information on your organization's sustainability goals, green initiatives or digital transformation.",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X131":{
      "id": "X131",
      "num": 1,
      "type":3,
      "question": "Additional Communications Development Comments",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X132":{
      "id": "X132",
      "num": 1,
      "type":8,
      "question": "Content is stored",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":true,
      "selectOne": true,
      "choices": [
        "File Server",
        "ECM System",
        "Other"
      ]
    },
    "X133":{
      "id": "X133",
      "num": 1,
      "type":8,
      "question": "ECM Software Platform is",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "selectOne": true,
      "choices": [
        "Open Text",
        "OnBase",
        "SharePoint",
        "FileNet",
        "Confluence",
        "LaserFiche",
        "Egnyte",
        "Alfresco",
        "DocuShare",
        "Iworks",
        "Doxim",
        "Inspire Content Manager",
          "Other"
      ]
    },
    "X134":{
      "id": "X134",
      "num": 1,
      "type":8,
      "question": "Content is categorized, tagged, and organized via a consistent methodology.",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "selectOne": true,
      "choices": [
        "Yes",
        "No"
      ]
    },
    "X135":{
      "id": "X135",
      "num": 1,
      "type":8,
      "question": "Data is stored in",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "selectOne": true,
      "choices": [
        "a single database",
        "multiple databases"
      ]
    },
    "X136":{
      "id": "X136",
      "num": 1,
      "type":8,
      "question": "Data is stored using",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":true,
      "selectOne": true,
      "choices": [
        "Microsoft SQL Server",
        "Oracle RDMS",
        "My SQL",
        "PostgreSQL",
        "IBM DB2",
        "Google Big Table",
        "Amazon RDS",
        "Other"
      ]
    },
    "X137":{
      "id": "X137",
      "num": 1,
      "type":3,
      "question": "Additional Content and Data Management Comments",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X138":{
      "id": "X138",
      "num": 1,
      "type":5,
      "question": "Ways for line-of-business user to directly modify content in communications",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "choices": [
        "one way",
        "multiple ways",
        "no way"
      ]
    },
    "X139":{
      "id": "X139",
      "num": 1,
      "type":8,
      "question": "The platform for Business User enablement is",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther": true,
      "selectOne":true,
      "choices": [
        "Planet Press",
        "Quadient Inspire",
        "Open Text Empower",
        "Smart Comm",
          "Other"
      ]
    },
    "X140":{
      "id": "X140",
      "num": 1,
      "type":3,
      "question": "Additional Business User Enablement Comments",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X141":{
      "id": "X141",
      "num": 1,
      "type":8,
      "question": "Approvals are managed via",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":true,
      "choices": [
        "Quadient Inspire Approval",
        "Quadient Inspire Interactive",
        "Open Text",
        "Workgroups",
        "Workfront",
        "Homegrown",
        "Other"
      ]
    },
    "X142":{
      "id": "X142",
      "num": 1,
      "type":3,
      "question": "Additional Approval Processes Comments",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X143":{
      "id": "X143",
      "num": 1,
      "type":8,
      "question": "Communications are produced for",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "selectOne": true,
      "choices": [
        "print",
        "electronic/digital distribution",
        "both print and electronic/digital distribution"
      ]
    },
    "X144":{
      "id": "X144",
      "num": 1,
      "type":5,
      "question": "Production is managed via",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "choices": [
        "PlanetPress Connect",
        "Quadient Inspire Scaler",
        "Quadient Inspire Automation",
        "Exstream Communications Center",
        "Command Center",
        "Racami",
        "Ricoh Process Director",
        "Crawford Conductor",
        "Homegrown Systems"
      ]
    },
    "X145":{
      "id": "X145",
      "num": 1,
      "type":5,
      "question": "Communications are produced via",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "choices": [
        "batch",
        "on demand",
        "both batch and on demand"
      ]
    },
    "X146":{
      "id": "X146",
      "num": 1,
      "type":3,
      "question": "Additional Communications Processing and Delivery Comments",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X147":{
      "id": "X147",
      "num": 1,
      "type":5,
      "question": "End to end tracking system in place",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "trigger": {
        "target": "X148:X149",
        "condition": "Yes"
      },
      "choices": [
        "Yes",
        "No"
      ]
    },
    "X148":{
      "id": "X148",
      "num": 1,
      "type":8,
      "question": "If there is an end to end tracking system in place (Yes above), what is the system used for tracking?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "hidden": false,
      "allowOther":true,
      "selectOne": true,
      "choices": [
        "Ricoh Process Director",
        "Crawford Conductor",
        "Sefas Producer",
        "Canon PRISMA Production",
        "BlueCrest Output Manager",
        "Other"
      ]
    },
    "X149":{
      "id": "X149",
      "num": 1,
      "type":8,
      "question": "There is no comprehensive tracking system in place. The tracking that is available is",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "hidden": true,
      "allowOther": true,
      "selectOne": true,
      "choices": [
        "some databases with some log files",
        "mostly log files",
        "manual spreadsheets",
        "manual processes",
        "a homegrown system",
        "other"
      ]
    },
    "X149A":{
      "id": "X149A",
      "num": 1,
      "type":3,
      "question": "Additional Communication Auditing/Tracking Comments",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X150":{
      "id": "X150",
      "num": 1,
      "type":5,
      "question": "Is workflow automation in place?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "trigger": {
        "target": "X151",
        "condition": "Yes"
      },
      "choices": [
        "Yes",
        "No"
      ]
    },
    "X151":{
      "id": "X151",
      "num": 1,
      "type":8,
      "question": "The system that is used is:",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":true,
      "selectOne": true,
      "choices": [
        "Ricoh Process Director",
        "Crawford Conductor",
        "Sefas Producer",
        "Canon PRISMA Production Workflow",
        "Blue Crest Output Manager",
        "Quadient Inspire Automation",
        "Quadient Inspire Scaler",
        "Racami",
        "Planet Press Connect",
        "Exstream Communications Center",
        "HP Command Center",
        "Homegrown",
        "Other"
      ],
      "hidden": true
    },
    "X152":{
      "id": "X152",
      "num": 1,
      "type":5,
      "question": "Is this workflow automation system used as a single platform for all automation?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "choices": [
        "Yes",
        "No"
      ]
    },
    "X153":{
      "id": "X153",
      "num": 1,
      "type":3,
      "question": "Additional Automation Processes and Platforms Comments",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X154":{
      "id": "X154",
      "num": 1,
      "type":5,
      "question": "Is there a Preference Management database?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "trigger": {
        "target": "X155:X156",
        "condition": "Yes"
      },
      "choices": [
        "Yes",
        "No"
      ]
    },
    "X155":{
      "id": "X155",
      "num": 1,
      "type":8,
      "question": "If so, is it self-service through a customer portal? manually managed internally? Other?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":true,
      "selectOne": true,
      "choices": [
        "Customer self-service portal with a backend database",
        "Customer Service/CRM system with backend database",
        "Homegrown system with a backend database",
        "Manual entry into spreadsheets",
        "Other"
      ]
    },
    "X156":{
      "id": "X156",
      "num": 1,
      "type":5,
      "question": "Is Preference Management planned to be implemented in the next 12 months",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "choices": [
        "Yes",
        "No"
      ]
    },
    "X157":{
      "id": "X157",
      "num": 1,
      "type":3,
      "question": "Additional Preference Management Comments",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X158":{
      "id": "X158",
      "num": 1,
      "type":8,
      "question": "Campaigns are tracked and triggered",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":true,
      "choices": [
        "manually via Project Managers",
        "manually via Marketing Staff",
        "manually via other Staff",
        "through Quadient Inspire OCC",
        "through PlanetPress Connect",
        "through OpenText Campaign Manager",
        "through EFI Market Direct",
        "through Marcom Central",
        "through Adobe Campaign Manager",
        "through a Homegrown System",
        "Other"
      ]
    },
    "X159":{
      "id": "X159",
      "num": 1,
      "type":5,
      "question": "Is there capability to produce reports or dashboards based on campaign data",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "choices": [
        "Yes",
        "No"
      ]
    },
    "X160":{
      "id": "X160",
      "num": 1,
      "type":3,
      "question": "Additional Campaign Management Comments",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X161":{
      "id": "X161",
      "num": 1,
      "type":5,
      "question": "Management views cloud platforms",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "choices": [
        "openly",
        "cautiously",
        "negatively"
      ]
    },
    "X162":{
      "id": "X162",
      "num": 1,
      "type":5,
      "question": "CCM cloud integration is viewed as",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "choices": [
        "necessary",
        "not necessary"
      ]
    },
    "X163":{
      "id": "X163",
      "num": 1,
      "type":5,
      "question": "Cloud platform integration is",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "choices": [
        "complete",
        "in progress",
        "planned in next 12 months",
        "not planned in next 12 months"
      ]
    },
    "X164":{
      "id": "X164",
      "num": 1,
      "type":3,
      "question": "Additional Integration and Cloud Strategy Comments",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X165":{
      "id": "X165",
      "num": 1,
      "type":3,
      "question": "The current Disaster Recovery Plan that ensures that your organization can continue to distribute printed materials and other communications in the event of a disaster includes...",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X166":{
      "id": "X166",
      "num": 1,
      "type":2,
      "question": "The Disaster Recovery production site is located xx miles from your current operation.",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X167":{
      "id": "X167",
      "num": 1,
      "type":3,
      "question": "Describe the SLA's (Service Level Agreements)/required turnaround times and types of materials that need to be distributed in the event of a disaster.",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X168":{
      "id": "X168",
      "num": 1,
      "type":3,
      "question": "How do you track SLA’s to ensure they are met? SLAs are tracked through...",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X169":{
      "id": "X169",
      "num": 1,
      "type":5,
      "question": "Is your current outbound mail regulated?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "choices": [
        "Yes",
        "No"
      ]
    },
    "X170":{
      "id": "X170",
      "num": 1,
      "type":3,
      "question": "Requirements to track and report outbound mail from production completion through delivery include ...",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X171":{
      "id": "X171",
      "num": 1,
      "type":2,
      "question": "The % of printed materials that contain Personal Health Information/HIPAA is...",
      "caption": "%",
      "dataFormat":"%",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X200":{
      "id": "X200",
      "num": 1,
      "type":8,
      "question": "Please indicate the type of organization your company is classified as:",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":true,
      "selectOne": true,
      "choices": [
        "Debt Collection Agency",
        "Financial Institution",
        "Insurance Company",
        "Healthcare Organization",
        "Educational Organization",
        "Retail Organization",
        "State and Local Government Agency",
        "Manufacturer",
        "Commercial Print/Mail Service Provider",
        "Other"
      ]
    },

    "X201":{
      "id": "X201",
      "num": 1,
      "type":8,
      "question": "The types of customers your organization services includes:",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":true,
      "choices": [
        "Consumers",
        "Businesses",
        "Students",
        "Other"
      ]
    },
    "X202":{
      "id": "X202",
      "num": 1,
      "type":8,
      "question": "The type of communication materials distributed by your organization includes:",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":true,
      "choices": [
        "Letters",
        "Flyers/Sell Sheets",
        "Booklets/Catalogs",
        "Post Cards",
        "Signage",
        "Business Cards",
        "Envelopes",
        "Other"
      ]
    },

    "X203":{
      "id": "X203",
      "num": 1,
      "type":2,
      "question": "Total Quantity of MICR Impressions/Checks Produced per Year",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X204":{
      "id": "X204",
      "num": 1,
      "type":2,
      "question": "Total Quantity of Envelopes Processed per Year",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X205":{
      "id": "X205",
      "num": 1,
      "type":8,
      "question": "Min # of Inserts per Envelope Range",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":false,
      "selectOne": true,
      "choices": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10"
      ]
    },

    "X206":{
      "id": "X206",
      "num": 1,
      "type":8,
      "question": "Max # of Inserts per Envelope Range",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":false,
      "selectOne": true,
      "choices": [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10"
      ]
    },

    "X207":{
      "id": "X207",
      "num": 1,
      "type":3,
      "question": "Print Resources include...",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },
    "X208":{
      "id": "X208",
      "num": 1,
      "type":8,
      "question": "Is Scanning performed for distribution or archival of documents?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "selectOne": true,
      "choices": [
        "Yes",
        "No"
      ]
    },

    "X209":{
      "id": "X209",
      "num": 1,
      "type":8,
      "question": "Scanning equipment:",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":true,
      "choices": [
        "Fujitsu",
        "Kodak",
        "OPEX",
        "Other"
      ]
    },

    "X210":{
      "id": "X210",
      "num": 1,
      "type":8,
      "question": "Scanning/capture software:",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":true,
      "choices": [
        "Kofax",
        "Ephesoft",
        "Opentext",
        "Healthware",
        "Other"
      ]
    },

    "X211":{
      "id": "X211",
      "num": 1,
      "type":3,
      "question": "Types of documents scanned include...",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X212":{
      "id": "X212",
      "num": 1,
      "type":2,
      "question": "Total # of pages scanned per year?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X213":{
      "id": "X213",
      "num": 1,
      "type":2,
      "question": "Total # of pages that could potentially be scanned for distribution or archival?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X214":{
      "id": "X214",
      "num": 1,
      "type":8,
      "question": "Data used to create communications is...",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":true,
      "choices": [
        "Mainframe",
        "Flat File",
        "XML",
        "JSON",
        "Other"
      ]
    },

    "X215":{
      "id": "X215",
      "num": 1,
      "type":8,
      "question": "Data is imported into...",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":true,
      "choices": [
        "Quadient Inspire",
        "Microsoft Word",
        "Uluro",
        "Exstream",
        "Other"
      ]
    },

    "X216":{
      "id": "X216",
      "num": 1,
      "type":8,
      "question": "Full composed output is created in...",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "selectOne": true,
      "choices": [
        "PDF",
        "Postscript",
        "HTML"
      ]
    },

    "X217":{
      "id": "X217",
      "num": 1,
      "type":8,
      "question": "Output is passed to...",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":true,
      "choices": [
        "Print vendor",
        "Other"
      ]
    },

    "X218":{
      "id": "X218",
      "num": 1,
      "type":2,
      "question": "# of Approvers required for each project?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X219":{
      "id": "X219",
      "num": 1,
      "type":3,
      "question": "Documents and content that require approvals include...",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X220":{
      "id": "X220",
      "num": 1,
      "type":8,
      "question": "The current workflow process includes the ...",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "selectOne": true,
      "choices": [
        "secure transfer of client data",
        "unsecured transfer of client data"
      ]
    },

    "X221":{
      "id": "X221",
      "num": 1,
      "type":8,
      "question": "Output is passed to...",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":true,
      "choices": [
        "Outsourced Print Service Provider",
        "Other"
      ]
    },

    "X222":{
      "id": "X222",
      "num": 1,
      "type":3,
      "question": "The current archival process is...",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X223":{
      "id": "X223",
      "num": 1,
      "type":3,
      "question": "The requirements for archival are ...",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X224":{
      "id": "X224",
      "num": 1,
      "type":8,
      "question": "The following correspondence types are archived:",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "choices": [
        "Invoices/Statements",
        "Letters",
        "Email"
      ]
    },

    "X225":{
      "id": "X225",
      "num": 1,
      "type":2,
      "question": "The volume of documents that are archived includes how many files?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X226":{
      "id": "X226",
      "num": 1,
      "type":2,
      "question": "The storage space required to archive documents is _____ gigabytes?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X227":{
      "id": "X227",
      "num": 1,
      "type":3,
      "question": "The retention policy includes ...",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X228":{
      "id": "X228",
      "num": 1,
      "type":2,
      "question": "# of Suppliers?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X229":{
      "id": "X229",
      "num": 1,
      "type":3,
      "question": "The current purchasing process includes ...",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X230":{
      "id": "X230",
      "num": 1,
      "type":8,
      "question": "Is proof of delivery required?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "selectOne": true,
      "choices": [
        "Yes",
        "No"
      ]
    },

    "X231":{
      "id": "X231",
      "num": 1,
      "type":8,
      "question": "Is there informed visibility for piece level tracking?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "selectOne": true,
      "choices": [
        "Yes",
        "No"
      ]
    },

    "X232":{
      "id": "X232",
      "num": 1,
      "type":8,
      "question": "Is critical mail regulated?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "selectOne": true,
      "choices": [
        "Yes",
        "No"
      ]
    },

    "X233":{
      "id": "X233",
      "num": 1,
      "type":3,
      "question": "The software system that manages inventory is ...",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X234":{
      "id": "X234",
      "num": 1,
      "type":3,
      "question": "How many different paper types are needed to produce the printed documents?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X235":{
      "id": "X235",
      "num": 1,
      "type":3,
      "question": "How many different envelope types are needed for distributing printed documents?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X236":{
      "id": "X236",
      "num": 1,
      "type":3,
      "question": "How many different inserts are inventoried and included with the different document types?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X237":{
      "id": "X237",
      "num": 1,
      "type":2,
      "question": "SLA/Turnaround time requirements are  ____  # of days.",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X238":{
      "id": "X238",
      "num": 1,
      "type":2,
      "question": "Data files must be processed within ____ hours to meet SLA requirements.",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X239":{
      "id": "X239",
      "num": 1,
      "type":3,
      "question": "SLAs are tracked through ...",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X240":{
      "id": "X240",
      "num": 1,
      "type":8,
      "question": "What reports are used to monitor the business?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":true,
      "choices": [
        "On-time delivery",
        "Job detail",
        "Job cost",
        "Daily schedules",
        "Equipment productivity",
        "Employee productivity",
        "Sales/revenue",
        "Inventory",
        "Other"
      ]
    },

    "X241":{
      "id": "X241",
      "num": 1,
      "type":8,
      "question": "Reports are generated ...",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "allowOther":true,
      "choices": [
        "manually",
        "through the organization's ERP software",
        "no report are generated to monitor production",
        "no reports are generated to monitor financial data",
        "Other"
      ]
    },

    "X242":{
      "id": "X242",
      "num": 1,
      "type":2,
      "question": "How many hours are spent developing reports on a monthly basis to manage and monitor the organization and production activity?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X243":{
      "id": "X243",
      "num": 1,
      "type":3,
      "question": "Systems that are integrated and share information include:",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X244":{
      "id": "X244",
      "num": 1,
      "type":3,
      "question": "Systems that re siloed and do not share information include:",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[]
    },

    "X245":{
      "id": "X245",
      "num": 1,
      "type":8,
      "question": "Does your organization have a security framework in place to manage PII?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "selectOne": true,
      "choices": [
        "Yes",
        "No"
      ]
    },

    "X246":{
      "id": "X246",
      "num": 1,
      "type":8,
      "question": "Does your organization have a security framework to assess, monitor and mitigate cybersecurity risks?",
      "caption": "#",
      "dataFormat":"#",
      "hint": "",
      "cols":[],
      "defaults":[],
      "selectOne": true,
      "choices": [
        "Yes",
        "No"
      ]
    },




  },

  "survey":{
    "CS01":{
      "id": "CS01",
      "num": 1,
      "type":1,
      "weight": 15,
      "question": "Regulatory Compliance",
      "shortLabel": "Reg. Compliance",
      "caption": "<p>Evaluate if a customer can control, protect, and track how communications are created within the organization.  This topic is prevalent within customer service groups and claims departments across industries.  For example, a claim denial letter in an insurance organization is a critical piece of communication that has a major impact on the customer and on the payer.  The language used in that letter is very specific and requires approval to make sure it is compliant to regulations.  Many organizations create those letters using MS Word and rely on the tribal knowledge of the CSR’s to properly fill out any variable information.  These processes are prone to mistakes and the organization really has no capabilities to track who created the letter, what content was put in the letter, and when it was sent out.</p><p>Regulatory compliance is about evaluating how capable a customer is to control what information goes into a letter, who has access to create or change a letter, and that it is delivered through only the proper channel(s).  Some organizations must be able to show and prove this information or they could be subject to fines.</p> ",
      "criteria5": "Fully automated and processes aligned. Controls and protection are in place to know who, when, where,  and how communications were created and delivered. Maintains a low risk for a negative impact on the business.",
      "criteria4": "Fully automated controls and protection are in place to know who, when, where,  and how communications were created and delivered.  Some negative impact on the business occurs.",
      "criteria3": "Some automated controls and protection are in place to know who, when, where,  and how communications were created and delivered.  Some negative impact on the business occurs.",
      "criteria2": "Manual Controls and protection in place and you do not know the process by which communications are delivered to your Customer.  They understand they are at risk for compliance issues.",
      "criteria1": "No controls and protection in place and you do not know the process by which communications are delivered to your Customer.  Not aware if they are in compliance or not.",
      "criteriaNA": "Not Applicable",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 needs improvement."
    },
    "CS02":{
      "id": "CS02",
      "num": 2,
      "type":1,
      "weight": 10,
      "question": "Preference Management",
      "shortLabel": "Pref. Management",
      "caption": "Evaluate if a company can meet customer expectations regarding how they want to be communicated with.  Boomers want paper and email, Gen Z want Mobile.  How effective is the organization at identifying, recording, and using customer preference information to provide a personalized experience for their customers preferred or regulated communication channel.",
      "criteria5": "Customers have full control of updating their preferences via a customer web portal or mobile device.",
      "criteria4": "Disparate process to capture Customer preferences - automated and manual -  and retains preferences in a centralized repository. No mobile ability for Customer to control preferences.",
      "criteria3": "Manually capture Customer preferences. (CSR, phone, email,) and retains preference in a centralized repository.",
      "criteria2": "Manually capture Customer preferences. (CSR, phone, email,). Non centralized or no repository.  No ability to retain the customer preference.",
      "criteria1": "Do not know what the Customers preferences are.  And no way to capture Customer preferences.",
      "criteriaNA": "Not Applicable",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 needs improvement."
    },
    "CS03":{
      "id": "CS03",
      "num": 3,
      "type":1,
      "weight": 5,
      "question": "Customer Journey Mapping",
      "shortLabel": "Journey Mapping",
      "caption": "Evaluate if a customer is using a CJM methodology to understand where and how their customer communications are impacting the customer experience.",
      "criteria5": "Using full CJM tools and methodology to understand the customer experience and can implement changes into the operations.",
      "criteria4": "Using CJM Technology to understand the customer journey.",
      "criteria3": "Some CJM activity but all manual efforts.",
      "criteria2": "Would like to do some CJM activity but not sure how to get there.",
      "criteria1": "No CJM Activity.",
      "criteriaNA": "Not Applicable",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 needs improvement."
    },
    "CS04":{
      "id": "CS04",
      "num": 4,
      "type":1,
      "weight": 15,
      "question": "Omni-Channel Delivery (Print, Mobile, email, SMS, Web)",
      "shortLabel": "Omni-Channel Delivery",
      "caption": "Evaluate a company’s ability to create, manage, and deliver communications across multiple channels according to customer preference or regulatory requirements.  Many customers have multiple channels that they can use, but they are managed separately and are very difficult to orchestrate customer communications across multiple channels throughout the customer journey.  How easily can a company orchestrate multiple types of communications across multiple channels for one or many customers for both batch and real time processes.",
      "criteria5": "Automated batch and real time processes to create, manage, and deliver communications across multiple channels according to customer preference or regulatory requirements. This includes print, mobile, email, SMS and web. ",
      "criteria4": "Automated batch processes to create, manage, and deliver communications across multiple channels according to customer preference or regulatory requirements. This includes print, mobile, email, SMS and web. No real time processes available.",
      "criteria3": "Hybrid processes to create, manage, and deliver communications across multiple channels according to customer preference or regulatory requirements. Limited delivery channels.",
      "criteria2": "Cumbersome process to create, manage, and deliver communications across multiple channels according to customer preference or regulatory requirements. Limited delivery channels. Reliance on IT.",
      "criteria1": "No ability to create, manage, and deliver communications across multiple channels according to customer preference or regulatory requirements.",
      "criteriaNA": "Not Applicable",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 needs improvement."
    },
    "CS05":{
      "id": "CS05",
      "num": 5,
      "type":1,
      "weight": 10,
      "question": "CCM Template Management & Development Efficiency",
      "shortLabel": "Template Management",
      "caption": "Evaluate a company's process for delivery of a new template or make changes to existing templates.  The management of the template process can be very inefficient especially in older CCM systems.  Some legacy systems don’t even have a GUI environment to develop in.  ",
      "criteria5": "Development is well thought-out plan with project manager and has a single design application for all communication methods. ",
      "criteria4": "Development is well thought out but requires several design applications for the different communication methods. ",
      "criteria3": "Development is done by several different applications but the same development  teams.",
      "criteria2": "Development is done with several different applications and several different designers.",
      "criteria1": "Unsure or outsourced development.",
      "criteriaNA": "Not Applicable",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 needs improvement."
    },
    "CS06":{
      "id": "CS06",
      "num": 6,
      "type":1,
      "weight": 5,
      "question": "Business User Enablement",
      "shortLabel": "User Enablement",
      "caption": "Evaluate how quickly a business operation or product group can create or maintain customer communications.  IT Department resources are stretched to the limit and IT can become a bottle neck when it comes to customer communication management.  Sometimes just changing a paragraph within a customer letter can take weeks or months.  Business user enablement gives the business a controlled editing environment to change content they are authorized to change.  They are not meant to be power users, but given control over content, in a controlled environment, where they can bypass IT bottlenecks and create communication quickly that are critical to their business.  Proper business user enablement has controls to access information, and approval workflows that will not allow changes to be put into production until the proper authority has approved and released the changes.  ",
      "criteria5": "Authorized Business Users  can change content without reliance on IT with version control and approval.",
      "criteria4": "Authorized Business Users  can change content without reliance on IT and without version control and approval.",
      "criteria3": "Some ability to change content but still reliant on IT for Customer Communication Management with no version control and approval.",
      "criteria2": "Some streamlined manual processes in place to change content but still reliant on IT Customer Communication Management with no version control and approval.",
      "criteria1": "Full reliance on IT for Customer Communication Management.",
      "criteriaNA": "Not Applicable",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 needs improvement."
    },
    "CS07":{
      "id": "CS07",
      "num": 7,
      "type":1,
      "weight":10,
      "question": "Content Integration with Core Systems",
      "shortLabel": "Content Integration",
      "caption": "Evaluate how a company integrates data sources to drive customer communications.  Siloed or disparate information can be a barrier to a company’s customer communications strategy.  Evaluate how many data sources are necessary support their customer communications strategy and are there gaps integrating with that data.  Customers can also be going through core system refreshes where a change in the data source can cause rewrites of customer communications.  These are perfect opportunities to make changes to a customer’s CCM platform to take advantage of new CCM technologies.  A good example of this is an insurance company’s policy administration syste+B4+/B2m, or a healthcare payer’s medical management system.",
      "criteria5": "All core business systems are connected and use the same database. ",
      "criteria4": "Some systems are connected or middleware is used for data consolidation automatically. ",
      "criteria3": "Are connected but use disparate database systems.",
      "criteria2": "Data is being pulled from multiple systems but is one directional and a manual process is required for updating each system separately. ",
      "criteria1": "Systems are not connected and data collection is very manual.",
      "criteriaNA": "Not Applicable",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 needs improvement."
    },
    "CS08":{
      "id": "CS08",
      "num": 8,
      "type":1,
      "weight": 10,
      "question": "Cloud Strategies",
      "caption": "Is a company open to CCM Cloud strategies?  Are they looking to get access to a best in class CCM platform but not have to have a large capital expenditure for an on-premises license?  They can purchase based on their current usage and increase as needed. ",
      "criteria5": "Optimized Cloud Strategy. Uses a secure cloud-based multichannel document automation platform.  Portal for internal and external customers. Cost effective. Strategy allows for growth.",
      "criteria4": "Sufficient cloud strategy. Uses a secure cloud-based multichannel document automation platform. Portal is not Customer friendly. Possibly costly.",
      "criteria3": "Limited cloud strategy that does not encompass all systems or allow for a company's growth.  Costly.",
      "criteria2": "Ad-hoc cloud strategy.",
      "criteria1": "No cloud strategy in place.",
      "criteriaNA": "Not Applicable",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 needs improvement."
    },
    "CS09":{
      "id": "CS09",
      "num": 9,
      "type":1,
      "weight": 5,
      "question": "Disaster Recovery",
      "caption": "Does the customer have a Disaster Recovery Plan for CCM processing?  Are they prepared for a DR event?  Is it a manual process to enable DR or is it an automatic process?",
      "criteria5": "Fully automated DR processes are in place for CCM processing and the policies are reviewed and updated on an annual basis. The DR Plan covers all delivery channels.",
      "criteria4": "Partially automated DR processes are in place for CCM processing and the policies are reviewed and updated on an annual basis. ",
      "criteria3": "Manual DR processes are in place for CCM processing.  Policies are not documented or reviewed on a regular basis.",
      "criteria2": "Informal DR processes are in place for CCM processing.  Policies are not documented or reviewed on a regular basis.",
      "criteria1": "No disaster recovery process in place.",
      "criteriaNA": "Not Applicable",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 needs improvement."
    },
    "CS10":{
      "id": "CS10",
      "num": 10,
      "type":1,
      "weight": 10,
      "question": "Auditing and Tracking of Communications",
      "shortLabel": "Auditing & Tracking",
      "caption": "Evaluate a company’s ability to prove compliance or disputes by being able to prove who, what, and when a communication was created.  This applies for industries that can be negatively impacted by fines associated with regulations or proof is needed that communications were delivered for auditing purposes.  A good example of this is check applications or Explanation of Benefits in the Healthcare industry.",
      "criteria5": "Automatic tracking and audit of all communications in a single dashboard.",
      "criteria4": "Able to track and audit all communications but using different systems.",
      "criteria3": "Some communications are tracked or audited with some automation.",
      "criteria2": "Some communications are tracked or audited but it is a manual process of getting the results. ",
      "criteria1": "No Tracking or Auditing",
      "criteriaNA": "Not Applicable",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 needs improvement."
    },
    "CS11":{
      "id": "CS11",
      "num": 11,
      "type":1,
      "weight": 5,
      "question": "Customer Service Integration",
      "shortLabel": "Customer Service",
      "caption": "Evaluate the ability of customer service reps to create a customer communication while interacting with a customer in real-time and remain compliant.  During a phone call, chat message, or via an email, customer service reps must respond to customers in a timely manner to provide a great customer experience.  Can a customer service rep orchestrate a communication experience where they can create a letter, email, SMS message in real-time and communicate with the customer according to preference while maintaining regulatory compliance.",
      "criteria5": "Fully automated Customer Service Integration with the ability to deliver real time, omni-channel communications.  Fully compliant.",
      "criteria4": "Fully automated Customer Service Integration with the ability to deliver real time communication.  Lacking  omni-channel delivery.  ",
      "criteria3": "Some automated Customer Service Integration.  Lacking omni-channel  and real time delivery.",
      "criteria2": "Manual Customer Service Integration ability. Not real time.  Lacking compliancy.",
      "criteria1": "No ability for Customer Service Integration.",
      "criteriaNA": "Not Applicable",
      "hint": "Rate on a scale of 1-5. 5 is state-of-the-art, 1 needs improvement."
    },
  }
}















