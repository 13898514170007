


<template>
<div class="report-generator">
  <div class="px-8 py-4 border-b w-full sticky top-0 bg-white z-30 flex items-center justify-end">

    <button class="button mr-4" @click="saveEdits" v-if="hasUnsavedChanges">Save Edits</button>
    <button class="button button-blue" @click="exportDoc">Export Doc</button>
    <div class="w-8 h-8 p-2 border-gray-300 border-2 rounded-full flex justify-center items-center mt-1 ml-3 opacity-50 hover:opacity-100 cursor-pointer" @click="exitPreview">
      <img  class="h-3 inline-block " src="../assets/icons/close-icon.svg" />
    </div>
  </div>

  <component @mounted="onTemplateMount" :is="reportTemplate.component" class="m-8 mt-16 report" ref="report" :report-data="reportData" :api="api" v-if="reportTemplate && reportData" @edit="onEdit"></component>
  <form-pullout v-if="editInfo" :question="editInfo.question" :answer="editInfo.answer" @close="closeSidePanel" @change="onChange"></form-pullout>

</div>
</template>

<script>
/* eslint-disable no-unused-vars */

import { ImageRun, AlignmentType, PageSize, UnderlineType, BorderStyle, ShadingType, WidthType, Document, HeadingLevel, Media, Packer, Paragraph, TableOfContents, Table, TableRow, TableCell, TabStopPosition, TabStopType, TextRun, Numbering } from "docx";
import { saveAs } from "file-saver"
import FormPullout from "@/components/FormPullout";




export default {
    name: "generic-report-generator",
  components: {
    FormPullout

    },
  props: {},
    mounted: function(){

      console.log("mounting");


        (async () => {
          if (this.$route.query.api) {
            console.log("loading api");
            // import module for side effects
            let apiModule;
            switch (this.$route.query.api){
              case "sc":
                apiModule = await import('@/plugins/printconfig.js')
                this.api = apiModule.default;
                console.log("API", this.api)
                if(this.$route.query.r){
                  console.log("loading report")
                  this.prepareReport(this.$route.query.r);
                }
                break;
            }
          }
        })();






    },
    data: function(){
        return {
          api: undefined,
          reportTemplate: undefined,
          hasUnsavedChanges: false,
          solutionIds: "000000000000",
          disabled: true,
          r: undefined,
          sidePanel: false,
          sourceQuestion: undefined,
          currentSession: null,
          editInfo: undefined,
          images: {}

        }
    },
    methods: {


      onTemplateMount: function(){
        //this.numberHeadings();
      },

      onChange: function(){
        //console.log("changes");
        //this.$sdm.calculate(this.r);
        this.hasUnsavedChanges = true;
      },

      onEdit: function(editInfo){

        console.log(editInfo);

        this.editInfo = editInfo;

        //this should be:
        //{
        //   question: schema question definition,
        //   answer: document answer container,
        //}
      },

      closeSidePanel: function(){
        this.editInfo = undefined;
      },

      exitPreview: function(){
        if(this.hasUnsavedChanges){
          if(!confirm("You have unsaved edits. If you leave now, you will lose those changes.")){
            return;
          }
        }
        this.$router.back();
      },



      saveEdits: function(){

        if(confirm("This will save any edits you make in preview mode. Are you sure?")){
          this.api.save(this.r).then(() => {
            alert("Your edits have been saved.");
            this.hasUnsavedChanges = false;
          }).catch((error) => {
            console.error(error);
            alert("There was a problem with this operation. Try again.")
          })
        }

      },


      loadReportTemplate: function(){

        console.log("loading template");
        if(this.r){

          let componentName = '';

          if(this.$route.query.t){

            switch (this.$route.query.t){
              case "na":
                    this.reportTemplate = {
                      component: () => import(/* webpackChunkName: "about" */ '@/views/ReportNeedsAnalysis.vue')
                    }
                    break;
              case "scf":
                this.reportTemplate = {
                  component: () => import(/* webpackChunkName: "about" */ '@/views/ReportSiteConfigFullTransactional.vue')
                }
                break;
              case "scp":
                this.reportTemplate = {
                  component: () => import(/* webpackChunkName: "about" */ '@/views/ReportSiteConfigFullPod.vue')
                }
                break;
              case "scpt":
                this.reportTemplate = {
                  component: () => import(/* webpackChunkName: "about" */ '@/views/ReportSiteConfigFullPodTransactional.vue')
                }
                break;
              case "scs":
                this.reportTemplate = {
                  component: () => import(/* webpackChunkName: "about" */ '@/views/ReportSiteConfigSummary.vue')
                }
                break;

            }

          }


        }
      },

      prepareReport: function(id){
        if(this.api){
          this.api.load(id).then((response) => {


            this.r = response;
            this.loadReportTemplate();
          })
        }


      },

      numberHeadings: function(){

        let counters = {
          H1: 0,
          H2: 0,
          H3: 0
        }

        //console.log("reportRef", this.$refs.report);

        this.$refs.report.$el.querySelectorAll('section').forEach((section) => {
              section.children.forEach((node) => {
                if(node.nodeName === "H1"){
                  counters.H1++;
                  counters.H2 = 0;
                  counters.H3 = 0;
                  node.dataset.num = counters.H1+".";
                }else if(node.nodeName === "H2"){
                  counters.H2++;
                  counters.H3 = 0;
                  node.dataset.num = counters.H1 +"."+counters.H2
                }else if(node.nodeName === "H3"){
                  counters.H3++;
                  node.dataset.num = counters.H1 +"."+counters.H2+"."+counters.H3;
                }else if(node.dataset.container){
                  node.children.forEach((subnode) => {
                    if(subnode.nodeName === "H1"){
                      counters.H1++;
                      counters.H2 = 0;
                      counters.H3 = 0;
                      subnode.dataset.num = counters.H1+".";
                    }else if(subnode.nodeName === "H2"){
                      counters.H2++;
                      counters.H3 = 0;
                      subnode.dataset.num = counters.H1 +"."+counters.H2
                    }else if(subnode.nodeName === "H3"){
                      counters.H3++;
                      subnode.dataset.num = counters.H1 +"."+counters.H2+"."+counters.H3;
                    }
                  })
                }
              });
        })
      },




      exportDoc: async function(){
        let reportTitle = "Findings Report";

        if(this.api.reports){
          if(this.api.reports[this.$route.query.t]){
            reportTitle = this.api.reports[this.$route.query.t].title
          }
        }

        await this.$docx.generateFile(this.$refs.report.$el, {
          title: reportTitle,
          numberHeadings: { H1:true, H2: true, H3: true }
        })
      },



    },
    computed: {

      schema: function(){
        if(this.api){
          return this.api.schema;
        }
        return null;
      },

      reportData: function(){
        return this.r;
      },

    }
}
</script>

<style>


.report-generator {

}

.report section {
  @apply mb-16 pb-16 border-b-2 border-red-600;
}

.report section h1 {
  @apply text-2xl font-bold mb-4;
}

.report section h2 {
  @apply text-lg font-bold mt-8 mb-2 text-red-700;
}

.report section h3 {
  @apply font-bold;
}

.report section h4 {
  @apply font-bold text-lg mt-4;
}

.report hr {
  @apply mb-4;
}

.report .container + .container {
  @apply border-t;
}

.report section h1 + h2 {
  @apply mt-4;
}

.report section p {
  @apply mb-4;
}

.report section p.no-mb {
  @apply mb-0;
}


.report section ul {
  @apply ml-4 mb-4;
}

.report section span[title] {
  @apply bg-yellow-100 cursor-pointer;
}

.report section span:hover {
  @apply bg-yellow-200
}


.report section th {
  @apply bg-blue-100 border border-black p-2;
}

.report section td {
  @apply border border-black p-2;
}

.report section h1:before {
  /*content: attr(data-num);*/
  /*@apply mr-2;*/
}

.report section h2:before {
  /*content: attr(data-num);*/
  /*@apply mr-2;*/
}

.report section h3:before {
  /*content: attr(data-num);*/
  /*@apply mr-2;*/
}

.report section .title {
  @apply text-2xl font-bold mb-0;
}

.report section .subtitle {
  @apply text-lg font-bold mb-4;
}

.report section table.titlebox tr {
  @apply border-none;
}

.report section .titlebox th {
  @apply bg-blue-100 border-none p-0;
}

.report section .titlebox td {
  @apply border-none p-0;
}

.report section .titlebox span {
  background-color: transparent;
}

.report section .bold-label {
  @apply font-bold mb-0;
}

.report section ul {
  @apply list-disc;
}


</style>
