


<template>
<div class="user-profile">
  <div class="text-xs px-8 py-2 pt-4 bg-white">
    <router-link to="/">Home</router-link> /
    <router-link to="/admin?tab=orgs">Admin  / </router-link>
    <span>Organization</span> /
  </div>

  <div class="pt-0 bg-white">

    <div class="flex justify-between w-full border-b-4 pb-4 px-8" v-if="profile">
      <h1 class="text-3xl leading-none">{{ profile.name }}</h1>
      <div class="flex -mt-2 text-sm" v-if="hasAdminAccess">
        <button class="button flex items-center" @click="update" :class="!hasUnsavedChanges?'opacity-75 pointer-events-none':'button-green'"><span>Save Profile</span></button>
      </div>
    </div>


  </div>

  <div class="px-8 py-4 w-full max-w-3xl" v-if="profile">

    <div class="">
      <label class="text-sm">Organization Name</label>
      <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="profile.name"  @change="onChange"/>
    </div>

    <div class="sm:flex w-full mt-4">
      <div class="sm:pr-8 sm:w-1/2">
        <label class="text-sm">ORC CF Cust #</label>
        <input type="text" class="block w-full border p-2 mt-1 rounded"  v-model="profile.orgCode"  @change="onChange"/>
        <p class="text-sm text-gray-500 mt-1">(Oracle Continuous Feed Customer Number)</p>
      </div>
      <div class="mt-4 sm:mt-0 sm:pl-4 sm:w-1/2">
        <label class="text-sm">ORC CS Cust #</label>
        <input type="text" class="block w-full border p-2 mt-1 rounded"  v-model="profile.orgCode2"  @change="onChange"/>
        <p class="text-sm text-gray-500 mt-1">(Oracle Cut Sheet Customer Number)</p>
      </div>
    </div>




    <div class="pt-4 mt-8 border-t hidden">
        <div>
          <h2>Related Documents</h2>
        </div>
        <div class="mt-4 border rounded text-sm" v-if="documents">
          <table class="w-full">
            <thead>
              <tr class="bg-gray-100">
                <th class="pl-4 text-left">Type</th>
                <th class="text-left">Name</th>
                <th class="pr-4 text-left">Last Update</th>
                <th class="pr-4 text-left">User</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="document in documents" :key="document.id">
                <td class="px-4"></td>
                <td class="pr-4"></td>
                <td class="pr-4"></td>
                <td class="pr-4"></td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>

    <p class="mt-8 text-gray-500">
      This portal is restricted to authorized Ricoh account managers and customers. Please contact your system administrator for any account issues.
    </p>





  </div>
</div>
</template>

<script>


export default {
    name: "customer-profile",
  components: {},
  created: function(){
      console.log("created");
      this.loadProfile();


    },

  watch: {
    // call again the method if the route changes
    '$route': 'loadProfile'
  },

    props: {},
    data: function(){
        return {
          profile: undefined,
          hasUnsavedChanges: false,
          documents: []
        }
    },
    methods: {


      loadProfile: function(){
        if(this.companyRef){
          let q = this.$db.query;
          let client = new this.$db.Client({ secret: this.$store.getters.getToken });

          return client.query(
              q.Get(q.Ref(q.Collection("customers"), this.companyRef))
          ).then((response) => {


            if(response.data){
              let record = response.data;
              record.id = response.ref.value.id;
              this.profile = record;


            }else{
              throw new Error("Document error.");
            }
          }).catch((error) => {
            console.error(error);
            alert("Something went wrong this operation. Please try again.")
          })
        }
        this.hasUnsavedChanges = false;
      },

      onBack: function(){
        this.$router.back();
      },

      onChange: function(){
        this.hasUnsavedChanges = true;
      },

      update: function(){
        if(this.hasUnsavedChanges) {
          if(this.companyRef){
            let q = this.$db.query;
            let client = new this.$db.Client({ secret: this.$store.getters.getToken });

            client.query(
                q.Update(q.Ref(q.Collection("customers"), this.companyRef),{data: this.profile})
            ).then((response) => {


              if(response.data){
                let record = response.data;
                record.id = response.ref.value.id;
                this.profile = record;

                alert("Organization has been updated.")


              }else{
                throw new Error("Document error.");
              }
            }).catch((error) => {
              console.error(error);
              alert("Something went wrong this operation. Please try again.")
            })
          }
          this.hasUnsavedChanges = false;

        }

      }

    },
    computed: {
      companyRef: function(){
        return this.$route.params.orgid
      },

      isAdmin: function(){
        return this.$store.getters.isAdmin;
      },

      hasAdminAccess: function(){
        return this.$store.getters.hasAdminAccess;
      },

      admin: function(){
        return this.$store.getters.getUserProfile;
      },

      hasWorkflowAccess: function(){
        return this.$store.getters.hasWorkflowAccess
      },

      hasCalculatorAccess: function(){
        return this.$store.getters.hasCalculatorAccess
      },

      hasAccess: function(){
        return this.$store.getters.hasAccess;
      },

    }
}
</script>

<style scoped>


.user-profile {

}

</style>