


<template>
<div class="marcom-central">
  <h4>Marcom Central</h4>
  <hr>
  <p>MarcomCentral is a vendor-hosted system that streamlines marketing operations by allowing end-users located anywhere in the world to gain access to a Web-based marketing portal for the ordering, customization, production, and management of marketing assets, templates, and finished products.</p>
  <p>MarcomCentral provides branded online storefronts for customers' marketing materials. Expand prospects and increase revenue with a wider product offering that strengthens business partnerships. MarcomCentral's SaaS vendor-hosted model has the ability to quickly launch a Web-to-print offering with minimal impact on in-house resources. Service providers can attract a wider customer base with a feature-rich offering that provides the full array of functions required, ranging from complex VDP rules to customized workflow approvals to intelligent form displays that provide a rich user experience.</p>
  <p>MarcomCentral provides an extremely deep and thorough feature set that opens the doors to new enterprise accounts. A number of prebuilt integrations complement the capability to connect to nearly any third-party system. MarcomCentral is delivered on a SaaS platform which provides seamless product updates and expedited installations.</p>
  <p class="bold-label">Key Features:</p>
  <ul>
    <li>SaaS infrastructure</li>
    <li>Cross-media capabilities</li>
    <li>Integrations with Web services and data feeds</li>
    <li>Comprehensive feature set</li>
  </ul>
  <p class="bold-label">Benefits:</p>
  <ul>
    <li>Customer storefronts are launched quickly without the headache of servers, software, and other resources. Certified by SecurityMetrics®, MarcomCentral provides ultimate security and reliability.</li>
    <li>Support print and digital marketing through email, Web, social media, and mobile. Online personalized images and on-demand digital delivery expand the service offering.</li>
    <li>Easily connect with third-party applications including digital front end and print MIS systems as well as inventory, accounting, and shipping solutions.</li>
    <li>The deepest feature set on the market provides an astonishing number of configurable features for administrators, allowing ultra-customized enterprise storefront solutions.</li>

  </ul>

</div>
</template>

<script>
export default {
    name: "marcom-central",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


.marcom-central {

}

</style>