<template>
  <div class="eficross-media-campaign">
    <h4>EFI MarketDirect Cross Media Campaigns – Driven by Workflow Automation Team</h4>
    <hr>
    <p>Ricoh’s Workflow Automation Team offers full campaign services through EFI™ MarketDirect Cross Media. Through this offer, the WFA Team works with the customer to conceptualize and create a multi-channel marketing campaign, and then execute on behalf of you. Through the execution of this campaign, the WFA Team will gather the data and provide an analytics report of the findings, as well as work with you to create a blueprint of next steps to drive your business goals.</p>
    <p class="bold-label">Key Features of Campaign Creation:</p>
    <ul>
      <li>Planning calls</li>
      <li>Storyboard blueprint and ideation</li>
      <li>Storyboard creation</li>
      <li>Campaign creation and arrangement</li>
      <li>Campaign testing</li>
      <li>Execution</li>
      <li>Go Forward Consulting</li>
      <li>Presentation of results & analytics</li>
      <li>Dashboard Access</li>

    </ul>
    <p class="bold-label">Ricoh Workflow Automation Process:</p>
    <ul>
      <li>Work with Customer to Conceptualize the scope and target for your personalized campaign</li>
      <li>Industry Best Practices and data driven decision making provided</li>
      <li>Customer-created branding (logos, images, videos, etc.) and campaign verbiage taken by the WFA Team and crafted to fit within the marketing deliverable</li>
      <li>Database cleansing to ensure that the campaign is effectively reaching your customers through the multi-channels agreed upon for the campaign.</li>
      <li>Guidance on the most effective means of communication and engagement, so that your customers feel the personal touch and drives the best response rate.</li>
    </ul>

  </div>
</template>

<script>
export default {
  name: "efi-cross-media-campaigns",
  props: {},
  data: function () {
    return {}
  },
  methods: {},
  computed: {}
}
</script>

<style scoped>


.eficross-media-campaign {

}

</style>