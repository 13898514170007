


<template>
<div class="print-shop-mail">

  <h4>ObjectifLune PrintShop Mail Connect</h4>
  <hr>
  <p>PrintShop Mail Connect is a standalone variable data composition tool designed to make the creation and production of targeted promotional communications simple. Now you can design for print and email using one tool, without the need for plug-ins to external design software.</p>
  <p>Direct mail and email blasts are the simplest and most commonly used promotional communication vehicles out there. But they work a lot better when they are personalized. PrintShop Mail Connect provides you with just that - one tool to bring that data-driven personalization you know and love from PrintShop Mail to both printed and digital campaigns.</p>
  <p class="bold-label">Key Features:</p>
  <ul>
    <li>Data mapping (configuring the input data) is done through easy-to-use wizards</li>
    <li>Variable data fields on the output document can be placed via drag and drop</li>
    <li>Users can configure jobs for simplex or duplex, multi-up imposition and apply paper handling commands for tray calls and more. Additionally, add crop marks, account for bleed and use custom paper sizes.</li>
    <li>Allows you to communicate relevant information and targeted messages to customers based on preferences</li>
    <li>Leverages new communication trends without changing existing systems or executing complex programming</li>
    <li>Ensures digital communications remain flexible and stable with native HTML integration</li>
  </ul>

  <p class="bold-label">Customer Benefits:</p>
  <ul>
    <li>A multi-channel solution supporting documents in print and HTML based emails</li>
    <li>The same designer for both outputs, share elements between them to streamline design</li>
    <li>Simplified wizards for configuring the input data (Excel, delimited, etc.)</li>
    <li>Easy to learn and use</li>
    <li>A standalone desktop application requiring minimal setup and configuration</li>
  </ul>
  <p>Additional Modules, Options and Expansions:</p>
  <ul>
    <li>PrintShop Mail Connect offers licensing as a perpetual license or an annual subscription</li>
    <li>Optional modules include performance packs and AFP output</li>
  </ul>

</div>
</template>

<script>
export default {
    name: "print-shop-mail-connect",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


.print-shop-mail {

}

</style>
