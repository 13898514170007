


<template>
<div class="fusion-pro">
  <h4>FusionPro VDP Suite</h4>
  <hr>
  <p>Bringing template design, data file definition, business logic and print stream creation together in one intuitive platform to produce revenue generation, personalized, customer-centric marketing materials. The FusionPro VDP Suite features FusionPro VDP Creator, FusionPro VDP Producer, FusionPro VDP Server and FusionPro Expressions.</p>
  <p>FusionPro VDP Creator – Merge data, text and imagery with FusionPro VDP Creator. This desktop-based solution is for designing personalized templates for variable data publishing campaigns.</p>
  <ul>
    <li>Design and output targeted VDP print streams with 12 optimized VDP output formats via an Adobe Acrobat® plug-in.</li>
    <li>Create powerful business-logic rules via a simple drag-and-drop interface or through JavaScript.</li>
    <li>Incorporate FusionPro VDP Creator templates into any other FusionPro solution to meet specific client needs using the scalable VDP workflow.</li>
  </ul>
  <p>FusionPro VDP Producer – Compose high-volume personalized print output through a plug and play workflow with this server-based solution. FusionPro VDP Producer frees up workstations by offloading the composition process from local desktop machines to the server which allows jobs to process 20% faster than VDP Creator.</p>
  <ul>
    <li>Reduce the number of hands-on processes required for VDP workflow</li>
    <li>Process simultaneous jobs with quick throughput via a centralized job que</li>
    <li>Work on other business processes on your desktop while VDP Producer processes workflows on the server</li>
  </ul>
  <p>FusionPro VDP Server – Create custom workflows to produce and manage large-scale personalized documents using FusionPro VDP Server. This high-volume hands-off VDP composition tool allows you to generate tens of thousands of document pages per hour from local layouts created with FusionPro VDP Creator.</p>
  <ul>
    <li>Flexible web service API to launch document composition</li>
    <li>Lights out automation</li>
    <li>Compose multiple VDP jobs simultaneously with maximum composition performance</li>
    <li>Handle any size job with ease</li>
    <li>Integrate your own web-based solution via an open API</li>
    <li>Monitor jobs with the ability to pause, cancel and resubmit via an intuitive GUI</li>
  </ul>
  <p>FusionPro Watch – this solution can be setup to watch a set of hot folders for input and execute composition against a specified template for each folder when templates are dropped in. Just define templates to run based on the names of the data files and FusionPro Watch will create multiple compositions against the appropriate template automatically.</p>
  <p>FusionPro Expressions – Combine data and imagery to produce truly unique and compelling personalized images with FusionPro Expressions. This image personalization solution is available on both Mac and PC platforms and offers unlimited usage with no charge per image, providing limitless possibilities.</p>
  <ul>
    <li>Increase readership, response rates, brand value and service offerings with the use of personalized images within print, email, Web, and mobile campaigns.</li>
    <li>Get working quicker with prebuilt templates</li>
    <li>Expand the possibilities with MarcomCentral’s Image Library</li>
  </ul>
  <p>FusionPro VDP Creator boasts an unmatched feature set and leads all competitive solutions with nearly 60,000 installations worldwide. Using an Adobe® Acrobat® plug-in for both Mac and Windows platforms, it offers the most comprehensive set of output formats in the marketplace, ensuring maximum compatibility throughout the VDP workflow. Our technology brings template design, data file definition, business logic and print stream creation together into one scalable and intuitive platform to produce customer-centric, revenue generating, personalized marketing materials.</p>
  <p class="bold-label">Get Personal</p>
  <p>Deliver stronger impact and improve response rates with one-to-one messaging and compelling personalized imagery. Businesses of all sizes can leverage customer data to offer a wide range of highly personalized applications – from customized billing transactions, to tailored brochures and newsletters or targeted direct mail campaigns.</p>
  <p class="bold-label">Easy Start-up, User-Friendly Workflow</p>
  <p>Built-in templated rules eliminate the need for custom programming for most VDP tasks. An intuitive, easy-to-use GUI makes everything from one-off variable input to highly complex, data-rich document creation possible, empowering you to stay in demand with customer requests and provide more relevant marketing collateral.</p>
  <p class="bold-label">Dynamic Design Capabilities</p>
  <p>FusionPro integrates with Adobe InDesign® and Acrobat, giving you a seamless plug-and-play interface for flexible VDP document creation from almost any design file that can be converted to PDF. The software is easy-to-use and includes an arsenal of intelligent capabilities so that within minutes, your PDF document could be a live 1-to-1 communication.</p>
  <p class="bold-label">Fluid Data Integration</p>
  <p>From simple to complex or large-scale variable data output, FusionPro’s intuitive functionality can handle the most <strong>sophisticated</strong> data requirements. Using a simple GUI for data definition, you can easily extract and combine customer data from a variety of file formats as well as import data from multiple sources with our multi-line record support feature.</p>
  <p class="bold-label">Smarter Workflow Efficiencies</p>
  <p>FusionPro allows you to automate with speed and accuracy, creating everything from engaging personalized communication to data-rich business documents in a faster, smarter and more cost-effective way. Develop efficiency across your operation with features like flexible output options compatible with most print workflows, a variety of imposition options to reduce production time and mailing costs, and hot folder and watch folder support bring customer jobs and data in more fluidly.</p>
</div>
</template>

<script>
export default {
    name: "fusion-pro",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


.fusion-pro {

}

</style>