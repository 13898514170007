<template>
  <div class="creative-services">
      <h4>Creative Services</h4>
    <hr>
    <p>Ricoh's marketing and creative design services offer a range of solutions designed to enhance brand visibility and communication. Their services typically include:</p>
    <ul class="mt-4">
      <li><b>Graphic Design: </b>Creating visually compelling materials such as brochures, flyers, banners, and digital assets that align with brand identity.</li>
      <li><b>Print and Digital Marketing:</b> Developing and producing high-quality print materials and digital content to support marketing campaigns, from direct mail to social media graphics.</li>
      <li><b>Brand Management:</b> Ensuring consistent brand messaging across all marketing channels, from traditional print to digital media.</li>
      <li><b>Content Creation:</b> Crafting engaging content, including copywriting, video production, and interactive media, to effectively convey brand messages.</li>
      <li><b>Campaign Management:</b> Overseeing the execution of marketing campaigns, from concept development to final delivery, ensuring timely and effective results.</li>
      <li><b>Data-Driven Marketing:</b> Utilizing analytics and customer data to tailor marketing strategies that target specific audiences, increasing the effectiveness of campaigns.</li>

    </ul>
   <p>Ricoh’s services are tailored to meet the needs of businesses looking to enhance their marketing efforts, whether through traditional print or modern digital platforms. They leverage their expertise in technology and innovation to provide end-to-end solutions that support their clients’ marketing goals.</p>
  </div>
</template>

<script>
export default {
  name: "creative-services",
  props: {},
  data: function () {
    return {}
  },
  methods: {},
  computed: {}
}
</script>

<style scoped>


.ccmdesign {

}

</style>