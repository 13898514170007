<template>
  <ul class="span" @click="clickHandler">
    <li v-if="!items.length"><span class="inline-block px-2"></span></li>
    <li v-for="(item,index) in items" :key="index">{{ item }}</li>
  </ul>
</template>

<script>
export default {
  name: "BulletedNotes",
  props: ['questions','questionId','onClick'],
  computed: {
    items: function(){
      let question =  this.questions[this.questionId]
      let notes = this.questions[this.questionId].notes;

      if(question && notes){
        let list = notes.split(",")
        if(list[list.length-1] === 'Other'){
          if(question.answer?.final){
            list[list.length - 1] = question.answer.final
          }
        }

        return list;
      }

      return []
    }
  },
  methods: {
    clickHandler: function(){
      if(this.onClick){
        console.log("click")
        let e = {
          target: {

              title: this.questionId

          }
        }

        this.onClick(e)
      }
    }
  }
}
</script>

<style scoped>

  ul {
    @apply bg-yellow-200 cursor-pointer;
  }


</style>