


<template>
  <div v-if="r" id="report">

    <section class="">
      <table class="titlebox absolute text-white" style="font-size: 1vw; left: 11em; width:28em; margin-top: 11em;">
        <tr><td class="font-bold" style="font-size: 2.5em; padding-bottom: 0.5em;" data-cell-style="TitlePageTitleCell">FINDINGS REPORT</td></tr>
        <tr><td class="font-bold leading-none" style="font-size: 2em; padding-bottom: 1em;" data-cell-style="TitlePageSubtitleCell">Customer Communications Management Review</td></tr>
        <tr><td class="leading-snug" style="font-size: 1.2em;" data-cell-style="TitlePageCell">Confidential: For the exclusive use of Ricoh and <span>{{ r.customerProfile.organizationName }}</span>.  This document cannot be shared with any other parties without written permission from all of the parties referenced here.</td>
        </tr>
        <tr><td style="padding-bottom: 2em;">  </td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell">V1.0</td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell"><span>{{ r.userProfile.firstname }} {{ r.userProfile.lastname }}</span></td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell"><span>{{ r.userProfile.title }}</span></td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell"><span>{{ currentDate }}</span></td></tr>
      </table>

      <img class="w-full -mx-8 block" data-float="true" data-ratio="1.2941" src="/reports/title-bkg.png" />


      <p></p>



    </section>
    <section>
      <p class="font-bold no-mb">Prepared for:</p>
      <p class="font-bold no-mb "><span >{{ r.customerProfile.organizationName }}</span> - <span >{{ r.customerProfile.customerContact }}</span></p>
      <p class="no-mb"><span >{{ r.customerProfile.address }}</span></p>
      <p class="no-mb"><span >{{ r.customerProfile.city }}</span>, <span >{{ r.customerProfile.state }}</span> <span >{{ r.customerProfile.postalcode }}</span></p>
      <p class="mt-8 no-mb font-bold">Prepared by:</p>
      <p class="no-mb"><span>{{ r.userProfile.firstname }}</span> <span>{{ r.userProfile.lastname }}</span></p>
      <p class="no-mb" v-if="r.userProfile.organization"><span>{{ r.userProfile.organization }}</span></p>
      <p class="no-mb"><span>{{ r.userProfile.title }}</span></p>
      <p class="no-mb mt-8"><span>{{ currentDate }}</span></p>
    </section>

    <section>
      <p class="toc-title">TABLE OF CONTENTS</p>
      <p data-toc="true" data-alias="Table of Contents" data-levels="1-3">Table of Contents here.</p>
    </section>





    <section>
      <h1>Overview</h1>
      <h2>Background</h2>

      <div data-container="true" v-if="!option2">
        <p>Many businesses are limited due to an extremely manual process for line of business communication creation and deployment. Complex communications are replicated over and over, with a lack of clarity on data, graphics, standards, and version control. While some business users may feel they are a “master” of their current processes - the risks they pose to the business from a compliance and legal standpoint is extremely high. Common challenges in the current practice are as follows: </p>
        <ul>
          <li><b>Risk</b> - exposure to highly sensitive client data or letter content by human error or lack of formalized processes </li>
          <li><b>Disparate Data</b> - customer information for communication is stored in various locations - users search an extensive number of templates to search for the “right” materials</li>
          <li><b>Lack of ownership</b> - shared content storage has no clear access rights policy or ownership</li>
          <li><b>Poor audit trails</b> - Template changes (corporate identity, graphics, text) are not trackable</li>
          <li><b>Slow time to market</b> - IT involved in small changes to business templates and lengthy approval processes driven by email</li>
          <li><b>Duplicate efforts</b> - processes and content are replicated because it’s perceived faster than trying to look for official version/process</li>
        </ul>
        <p>Traditional composition solutions won’t cut it when the business must meet the needs not only of
          the customer - but also regulatory bodies. These solutions do not enable an audit trail, compliance on the paragraph level, or enable a speedy omni-channel process.
        </p>

      </div>
      <div data-container="true" v-if="option2">
        <p>Now, more than ever, forward-thinking organizations must understand how their customers want to communicate with them. Today’s multi-generational customers expect to choose how, when, and where to receive information – true omni-channel. Along with highly personalized correspondence, they want the ability to have electronic payment methods and in real-time, two-way digital communication. In addition, the business workflows that manage customer data for the processing of transactional documents are shifting due to the convergence of digital distribution and paper-based solutions. Understanding workflow processes, organizational goals and customer preferences are the foundation to defining opportunities to enhance the customer’s experience.  Simplifying workflow processes contribute to organizational efficiency and the potential for cost savings.</p>
        <p>Ricoh’s CCM Assessment reviews the components of an organization’s customer communication management strategies and provides recommendations and scalable solutions to automate and simplify current CCM processes. The CCM Assessment was conducted to benchmark the current state against industry best practices and identify solutions to enhance <span >{{ r.customerProfile.organizationName }}</span>’s customer communication initiatives.</p>
      </div>

      <h2>Methodology</h2>
      <p><span>{{ r.userProfile.firstname }}</span> <span>{{ r.userProfile.lastname }}</span> met with <span >{{ r.customerProfile.customerContact }}</span> on <span >{{ r.customerProfile.assessmentDate }}</span> to review the organization’s Customer Communication Management processes. The goal was to review all processes and business requirements from marketing and legal compliance through multi-channel output.  Customer Communication Management processes were evaluated against industry standards and performance benchmarks.</p>
      <p>Processes reviewed:</p>
      <ul class="list-disc">
        <li>Design Requests</li>
        <li>Communication Development</li>
        <li>Content and Data Management</li>
        <li>Business User Enablement</li>
        <li>Approval Processes</li>
        <li>Communication Processing and Delivery</li>
        <li>Communication Auditing/Tracking</li>
        <li>Automation Processes and Platforms</li>
        <li>Preference Management</li>
        <li>Campaign Management</li>
        <li>Integrations and Cloud Strategies</li>

      </ul>

      <h2>Site</h2>
      <p class="no-mb"><span >{{ r.customerProfile.organizationName }}</span> - <span >{{ r.customerProfile.customerContact }}</span></p>
      <p class="no-mb"><span >{{ r.customerProfile.address }}</span></p>
      <p class="no-mb"><span >{{ r.customerProfile.city }}</span>, <span >{{ r.customerProfile.state }}</span> <span >{{ r.customerProfile.postalcode }}</span></p>

      <h2>Departments Reviewed</h2>
      <table>
        <tr>
          <th>No.</th><th class="text-left">Departments/Area</th>
        </tr>
        <tr v-for="(department, index) in departmentsReviewed" :key="'dep'+index">
          <td>{{ index + 1 }}</td><td>{{ department }}</td>
        </tr>

      </table>

    </section>
    <section>
      <h1>Current State</h1>
      <h2>Organization</h2>
      <p><span >{{ r.customerProfile.organizationName }}</span>’s CCM/Composition operations develop <span title="X107.final" @click="onSpanClick">{{ q.X107.answer.final | decimal(0) }}</span> new templates and perform <span title="X108.final" @click="onSpanClick">{{ q.X108.answer.final | decimal(0) }}</span> template changes annually. There are approximately <span title="X109.final" @click="onSpanClick">{{ q.X109.answer.final | decimal(0) }}</span> page images per template. These templates process approximately <span title="X110.final" @click="onSpanClick">{{ q.X110.answer.final | decimal(0) }}</span> print and <span title="X111.final" @click="onSpanClick">{{ q.X111.answer.final | decimal(0) }}</span> email communications monthly. They have a total of <span title="X112.final" @click="onSpanClick">{{ q.X112.answer.final | decimal(0) }}</span> employees with the following number of employees in different work groups:</p>
      <table>
        <tr>
          <td>Print Communications Developers:</td><td><span title="X113.final" @click="onSpanClick">{{ q.X113.answer.final | decimal(2) }}</span></td>
        </tr>
        <tr>
          <td>Email Communications Developers:</td><td><span title="X114.final" @click="onSpanClick">{{ q.X114.answer.final | decimal(2) }}</span></td>
        </tr>
        <tr>
          <td>Production Staff:</td><td><span title="X115.final" @click="onSpanClick">{{ q.X115.answer.final | decimal(2) }}</span></td>
        </tr>
        <tr>
          <td>Administrative/Project Managers:</td><td><span title="X116.final" @click="onSpanClick">{{ q.X116.answer.final | decimal(2) }}</span></td>
        </tr>
        <tr>
          <td>Employees that Create Communications:</td><td><span title="X117.final" @click="onSpanClick">{{ q.X117.answer.final | decimal(2) }}</span></td>
        </tr>

      </table>
      <p></p>

      <p>The volume of transactional based communications produced on digital equipment includes <span title="X118.final" @click="onSpanClick">{{ q.X118.answer.final | decimal(0) }}</span> color and <span title="X119.final" @click="onSpanClick">{{ q.X119.answer.final | decimal(0) }}</span> monochrome impressions. </p>

      <h2>Software and Platforms</h2>
      <p>Software and Platforms to process transactional based communications include:</p>
      <p class="bold-label"><span title="X120.notes" @click="onSpanClick">Software:</span></p>
      <ul class="list-disc">
        <li v-for="(software, index) in softwareItems" :key="'software'+index">{{ software }}</li>
      </ul>

      <p class="bold-label"><span title="X121.notes" @click="onSpanClick">Platforms:</span></p>
      <ul class="list-disc">
        <li v-for="(hardware, index) in hardwareItems" :key="'hardware'+index">{{ hardware }}</li>
      </ul>


      <h2>CCM Processes</h2>

      <h3>Design Requests/ Customer Journey Mapping </h3>
      <p>Design request software/service is <span title="X122.notes" @click="onSpanClick">{{ listValue(q.X122) }}</span>.</p>
      <p>Prioritization and daily status are managed actively by management by <span title="X123.notes" @click="onSpanClick">{{ listValue(q.X123) }}</span>. Customer Journey Mapping <span title="X124.notes" @click="onSpanClick">{{ q.X124.notes }}</span><span v-if="q.X124.notes === 'has been implemented'"> and is managed through <span title="X124A.notes" @click="onSpanClick">{{ listValue(q.X124A) }}</span></span>.</p>
      <p v-if="q.X125.notes"><span title="X125.notes" @click="onSpanClick">{{ q.X125.notes }}</span></p>

      <p></p>
      <h3>Communication Development</h3>
      <p>Communications are designed for <span title="X126.notes" @click="onSpanClick">{{ q.X126.notes }}</span>. Print pieces are programmed with <span title="X127.notes" @click="onSpanClick">{{ listValue(q.X127) }}</span> while electronic pieces are programmed with <span title="X128.notes" @click="onSpanClick">{{ listValue(q.X128) }}</span>.</p>
      <p v-if="q.X129.notes"><span title="X129.notes" @click="onSpanClick">{{ q.X129.notes }}</span></p>
      <p v-if="q.X130.notes"><span title="X130.notes" @click="onSpanClick">{{ q.X130.notes }}</span></p>
      <p v-if="q.X131.notes"><span title="X131.notes" @click="onSpanClick">{{ q.X131.notes }}</span></p>

      <p></p>
      <h3>Content and Data Management</h3>
      <p>Content is stored on a <span title="X132.notes" @click="onSpanClick">{{ listValue(q.X132) }}</span>. The ECM software platform is <span title="X133.notes" @click="onSpanClick">{{ listValue(q.X133) }}</span>.</p>
      <p>Content <span title="X134.notes" @click="onSpanClick">{{ (q.X134.notes === 'Yes')?'is':'is NOT' }}</span> categorized, tagged, and organized via a consistent methodology. Data is stored in <span title="X135.notes" @click="onSpanClick">{{ q.X135.notes }}</span>, primarily using <span title="X136.notes" @click="onSpanClick">{{ listValue(q.X136) }}</span>.</p>
      <p v-if="q.X137.notes"><span title="X137.notes" @click="onSpanClick">{{ q.X137.notes }}</span></p>

      <p></p>
      <h3>Business User Enablement</h3>
      <p>There is currently <span title="X138.notes" @click="onSpanClick">{{ q.X138.notes }}</span> for business users to directly modify content in communications. <span v-if="q.X139.notes">The platform for Business User enablement is <span title="X139.notes" @click="onSpanClick">{{ listValue(q.X139) }}</span>.</span></p>
      <p v-if="q.X140.notes"><span title="X140.notes" @click="onSpanClick">{{ q.X140.notes }}</span></p>

      <p></p>
      <h3>Approval Processes</h3>
      <p>Approvals are managed via <span title="X141.notes" @click="onSpanClick">{{ listValue(q.X141) }}</span>.</p>
      <p v-if="q.X142.notes"><span title="X142.notes" @click="onSpanClick">{{ q.X142.notes }}</span></p>

      <p></p>
      <h3>Communications Output Processing and Delivery</h3>
      <p>Communications are produced for <span title="X143.notes" @click="onSpanClick">{{ q.X143.notes }}</span> and production is managed via <span title="X144.notes" @click="onSpanClick">{{ listValue(q.X144) }}</span>. Communications are produced via <span title="X145.notes" @click="onSpanClick">{{ q.X145.notes }}</span> application interfaces.</p>
      <p v-if="q.X146.notes"><span title="X146.notes" @click="onSpanClick">{{ q.X146.notes }}</span></p>

      <p></p>
      <h3>Communication Auditing/Tracking</h3>
      <p>There <span title="X147.notes" @click="onSpanClick">{{ (q.X147.notes === 'Yes' )?'is':'is not' }}</span> an end-to-end tracking system in place.  <span v-if="q.X147.notes === 'Yes'">The system used for tracking is <span title="X148.notes" @click="onSpanClick">{{ listValue(q.X148) }}</span>.</span>  <span v-if="q.X147.notes !== 'Yes'">The tracking that is available is <span title="X149.notes" @click="onSpanClick">{{ q.X149.notes }}</span>.</span></p>
      <p v-if="q.X149A.notes"><span title="X149A.notes" @click="onSpanClick">{{ q.X149A.notes }}</span></p>



      <p></p>
      <h3>Automation Processes and Platforms</h3>
      <p>There <span title="X150.notes" @click="onSpanClick">{{ (q.X150.notes === 'Yes')?'is':'is not' }}</span> a workflow automation system in place.  <span v-if="q.X150.notes === 'Yes'">The system that is used is <span title="X151.notes" @click="onSpanClick">{{ q.X151.notes }}</span>.</span></p>
      <p>They <span title="X152.notes" @click="onSpanClick">{{ (q.X152.notes === 'Yes')?'':'do not' }}</span> use this as a single platform for all automation.</p>
      <p v-if="q.X153.notes"><span title="X153.notes" @click="onSpanClick">{{ q.X153.notes }}</span></p>

      <p></p>
      <h3>Preference Management</h3>
      <p>Preference management data <span title="X154.notes" @click="onSpanClick">{{ (q.X154.notes === "Yes")?'is':'is not' }}</span> available.  <span v-if="q.X154.notes === 'Yes'">The preference management data is managed via <span title="X155.notes" @click="onSpanClick">{{ q.X155.notes }}</span>.</span>  <span v-if="q.X154.notes !== 'Yes'">Preference management <span title="X156.notes" @click="onSpanClick">{{ (q.X156.notes === "Yes")?'is':'is not' }}</span> planning to be implemented in the next 12 months.</span></p>
      <p v-if="q.X157.notes"><span title="X157.notes" @click="onSpanClick">{{ q.X157.notes }}</span></p>

      <p></p>
      <h3>Campaign Management</h3>
      <p>Campaigns are tracked and triggered <span title="X158.notes" @click="onSpanClick">{{ q.X158.notes }}</span>. There <span title="X159.notes" @click="onSpanClick">{{ (q.X159.notes === 'Yes')?'is':'is no' }}</span> capability to produce reports or dashboards based on campaign data.</p>
      <p v-if="q.X160.notes"><span title="X160.notes" @click="onSpanClick">{{ q.X160.notes }}</span></p>

      <p></p>
      <h3>Integrations and Cloud Strategies</h3>
      <p>Management views cloud platforms <span title="X161.notes" @click="onSpanClick">{{ q.X161.notes }}</span>. CCM cloud integration is viewed as <span title="X162.notes" @click="onSpanClick">{{ q.X162.notes }}</span>. Cloud platform integration is <span title="X163.notes" @click="onSpanClick">{{ q.X163.notes }}</span>.</p>
      <p v-if="q.X164.notes"><span title="X164.notes" @click="onSpanClick">{{ q.X164.notes }}</span></p>

      <p></p>
      <h3>Disaster Recovery</h3>
      <p>The current Disaster Recovery Plan that ensures that your organization can continue to distribute printed materials and other communications in the event of a disaster includes <span title="X165.notes" @click="onSpanClick">{{ q.X165.notes }}</span>. The Disaster Recovery production site is located <span title="X166.final" @click="onSpanClick">{{ q.X166.answer.final }}</span> miles from your current operation.</p>
      <p>SLA's (Service Level Agreements)/required turnaround times and types of materials that need to be distributed in the event of a disaster include <span title="X167.notes" @click="onSpanClick">{{ q.X167.notes }}</span></p>
      <p>SLAs are tracked through <span title="X168.notes" @click="onSpanClick">{{ q.X168.notes }}</span>.</p>
      <p>Critical mail <span title="X169.notes" @click="onSpanClick">{{ (q.X169.notes === 'Yes')?'is':'is not' }}</span> regulated.</p>
      <p>Requirements to track and report outbound mail from production completion through delivery include <span title="X170.notes" @click="onSpanClick">{{ q.X170.notes }}</span>.</p>
      <p>The % of printed materials that contain Personal Health Information/HIPAA is <span title="X171.final" @click="onSpanClick">{{ q.X171.answer.final | decimal(1) }}%</span>.</p>
      <p></p>




    </section>
    <section>
      <h1>Findings</h1>
      <h2>CCM Snapshot Assessment Scorecard Summary</h2>
      <p><span >{{ r.customerProfile.organizationName }}</span>’s CCM Assessment processes are rated as {{ r.scorecard.final }} on a scale of 1 to 5.  The overall rating would rise if some of the issues discussed in this document would be addressed. </p>
      <assessment-scorecard data-svg="true" data-ratio="0.8" title="CCM Snapshot Assessment Scorecard" answer-key="final" :assessment="r" :questions="survey"></assessment-scorecard>

      <p class="mt-4">See Appendix 1 for a detailed scale of the CCM Best Practices Scorecard.</p>
      <p>The current team has made changes and improvements over time within the budget constraints and resources availability. However, substantial room for improvement exists with respect to CCM development efficiency, customer journey mapping and customer service integration.</p>

    </section>

    <section>
      <h1>Recommendations</h1>


      <h2>Proposed Solution</h2>
      <p>Based on our assessment of your production operation, Ricoh recommends incorporating the following solutions to streamline  <span >{{ r.customerProfile.organizationName }}</span>'s workflow.</p>

      <component data-container="true" v-for="(solution) in solutions.Other" :key="solution.id" :is="solution.component"></component>


      <h2>Benefits</h2>
      <p>The proposed solution will align with organizational goals and objectives and provide a CCM solution with administrative efficiency and improved customer experience.</p>
      <p>Details for the targeted cost savings and potential increased revenue include:   XXXXXX</p>
      <p>CCM solutions work towards streamlining and simplifying the process of complex document assembly, enabling enterprises to quickly create and deliver customer-facing documents that are personalized, easy to understand, regulatory compliant, and send omni-channel from a single platform.</p>

      <h4>Reduce Costs:</h4>
      <p class="font-bold">Increase Speed in Development</p>
      <ul>
        <li>Increase efficiencies within data processing, merging and transformation</li>
        <li>Eliminate the need of any complex data transformation initiatives to standardize data before it can be used within CCM Platform</li>
        <li>Integrate directly with existing core systems or print and mail processing applications</li>
        <li>Reduce development times for templates</li>
        <li>Develop templates faster from 3rd party applications (PDF, Word, InDesign)</li>
        <li>Have greater reuse of content and resources across enterprise, and reduce templates needed and maintained</li>
        <li>Significantly reduce the time to make changes such as product information across all of your communications</li>
        <li>Share assets (such as logos, Images, content etc.) and increase reusability across templates managed from a central location.</li>
        <li>Simplify complex document assembly</li>
        <li>Enable production of print, mobile and web communications through single platform and design</li>


      </ul>

      <p class="font-bold">More Efficient Processes and Greater Control</p>
      <ul>
        <li>Enable version control, validity of dates, approval status and full approval tracking</li>
        <li>Eliminate scanning of proof prints, and enable remote web approval with full audit trail on approvals</li>
        <li>Use barcodes to create better document security and more efficient operations</li>
        <li>Create centralized, enterprise wide process automation and task management, and eliminate many of the manual and redundant steps currently in place</li>
        <li>Automate on-demand requests from members and deliver their communications quickly to their preferred channel</li>
        <li>Scale production servers to create unparalleled production capacity</li>

      </ul>

      <h4>Regulatory Compliance:</h4>
      <p class="font-bold">Empower the business to control communications</p>
      <ul>
        <li>Drive more control over Compliance changes with business authoring</li>
        <li>Give your experts the control to make fast document and regulatory changes over the web</li>
        <li>Rapidly develop, modify and manage enterprise of templates</li>
        <li>Manage, track, audit, and approve content changes easily</li>
        <li>Increase control over defining the approval processes for different departments, templates, transactional processes</li>
        <li>Control the ad-hoc documents Sales and CSRs produce</li>
        <li>Sales logs into a browser and produces content that is locked down by the business and always up to date</li>
        <li>CSRs create letters through a web portal with locked down templates that can print through a centralized print production facility</li>
        <li>CX Team gains Enterprise control of communications</li>
        <li>Meet consumer demands and improve time to market with communications</li>
        <li>Link customer journey to your templates to optimize the experience</li>
        <li>Enable Omni-Channel proofing and control member experience through every channel (Print, Web, Email, SMS, HTML5)</li>
        <li>Multichannel and cloud based proofing drastically cut approval and implementation time</li>
      </ul>

      <h4>Better Customer Experience</h4>
      <p class="font-bold">Drive Omni-Channel Customer Experience</p>
      <ul>
        <li>Reduce physical print and deliver a true Omni-Channel Experience more efficiently</li>
        <li>A single solution for print and digital saves up to 70% of design time</li>
        <li>Business manages and drives paperless adoption in areas such paperless claims</li>
        <li>SMS and mobile push notifications</li>
        <li>Provide a powerful customer experience</li>
        <li>Execute efficient complex transactions and onboarding by e-signatures and e-forms</li>
        <li>Personalized digital up-sell and cross-sell promotions with output such as Interactive Welcome Kits</li>

      </ul>

    </section>

    <section>
      <h1>Appendix</h1>
      <h2>Appendix 1 - CCM Benchmark Scorecard Ratings</h2>
      <table class="text-xs" data-cell-style="AppendixCell">
        <tr>
          <th></th>
          <th>5</th>
          <th>4</th>
          <th>3</th>
          <th>2</th>
          <th>1</th>
          <th>N/A</th>
          <th>Weight</th>
        </tr>
        <tr v-for="rating in survey" :key="rating.id">
          <td>{{ rating.question }}</td>
          <td>{{  rating.criteria5 }}</td>
          <td>{{  rating.criteria4 }}</td>
          <td>{{  rating.criteria3 }}</td>
          <td>{{  rating.criteria2 }}</td>
          <td>{{  rating.criteria1 }}</td>
          <td>{{  rating.criteriaNA }}</td>
          <td>{{ r.survey.questions[rating.id].weight }}%</td>
        </tr>
      </table>
    </section>



  </div>
</template>

<script>
import schema from "@/schema/ccm";

import ProductionWorkflow from "@/components/Solutions/ProductionWorkflow";
import RicohConsultingServices from "@/components/Solutions/RicohConsultingServices";
import ColorGap from "@/components/Solutions/ColorGap";

import AssessmentScorecard from "@/components/AssessmentScorecard";
import CampaignServices from "@/components/Solutions/CampaignServices";
import CCMDesign from "@/components/Solutions/CCMDesign";
import EFICrossMedia from "@/components/Solutions/EFICrossMedia";
import EFICrossMediaCampaigns from "@/components/Solutions/EFICrossMediaCampaigns";
import InteractiveVideo from "@/components/Solutions/InteractiveVideo";
import ObjectifLuneProducts from "@/components/Solutions/ObjectifLuneProducts";
import ProcessDirector from "@/components/Solutions/ProcessDirector";
import QuadientInspire from "@/components/Solutions/QuadientInspire";
import MailAssessment from "@/components/Solutions/MailAssessment";


export default {
    name: "report-ccm-snapshot",
  components: {
    MailAssessment,
    QuadientInspire,
    ProcessDirector,
    ObjectifLuneProducts,
    InteractiveVideo,

    EFICrossMediaCampaigns,
    EFICrossMedia,
    CCMDesign,
    CampaignServices,

    ProductionWorkflow,
    RicohConsultingServices,
    ColorGap,
    AssessmentScorecard
  },
  mounted: function(){
      this.$emit("mounted");
  },
    props: {
      reportData: {
        required: true,
      }
    },
    data: function(){
        return {

        }
        },
    methods: {

      listValue: function(answer){

        let list = answer.notes;




        let selections = list.split(",");
        if(selections.length > 2){
          selections[selections.length -1] = 'and '+selections[selections.length -1];
        }

        let result;



        if(selections.length < 3){
          result = selections.join(' and ');
        }else{
          result = selections.join(', ');
        }

        if(answer.answer.final){

          let otherText = "Other";

          let question = this.iQ[answer.questionID];


          if(question.choices){
            otherText = question.choices[question.choices.length - 1];
          }

          result = result.replace(otherText, answer.answer.final)

        }

        return result;
      },

      bulletValue: function(answer){

        let list = answer.notes;






        if(answer.answer.final){

          let otherText = "Other";

          let question = this.iQ[answer.questionID];


          if(question.choices){
            otherText = question.choices[question.choices.length - 1];
          }

          list = list.replace(otherText, answer.answer.final)


        }

        let selections = list.split(",");

        return selections;
      },

      onSpanClick: function(e){

        let questionData;

        if(e.target.title){
          let qPath = e.target.title.split(".");
          if(qPath[0].startsWith("X")){
            let question = this.iQ[qPath[0]];
            let answer = this.q[qPath[0]];

            if(question){
              questionData = {
                title: "Interview Question",
                answer: answer,
                question: question
              }
              this.$emit("edit",questionData)
            }
          }
        }

      },

      onChange: function(e){

        this.$emit("change",e);

      }
    },
    computed: {
       r: function(){
         return this.reportData;
       },

      q: function(){
        return this.r.interview.questions;
      },

      softwareItems: function(){
         return this.bulletValue(this.q.X120);
      },

      hardwareItems: function(){
        return this.bulletValue(this.q.X121);
      },


      strengthsWeakness: function(){
        let _listing = {
          strength: [],
          weakness: []
        }

        if(this.r){
          Object.keys(this.survey).forEach((key) => {
            let question = this.survey[key];
            let answer = this.r.survey.questions[key];

            if(answer.answer.final && answer.answer.final !=="NA"){
              let intValue = parseInt(answer.answer.final);
              if(intValue > 3){
                _listing.strength.push({
                  title: question.question,
                  criteria: question['criteria'+intValue],
                  grade: (intValue === 4)?"Very Good":"Best-in-Class",
                  notes: answer.notes
                })
              }

              if(intValue < 3){
                _listing.weakness.push({
                  title: question.question,
                  criteria: question['criteria'+intValue],
                  grade: (intValue === 2)?"Sufficient":"Substandard",
                  notes: answer.notes
                })
              }
            }

          })
        }

        return _listing;


      },


      option2: function(){
        if(this.r){
          return (this.r.exportSettings.option === "2");
        }
        return false;
      },

      solutions: function(){


        if(this.r){
          let _solutions = {
            Other: this.r.exportSettings.solutions.Other.map((otherID)=>{
              return this.solutionList[otherID]
            })
          };

          if(this.r.exportSettings.solutions.MIS){
            _solutions.MIS = this.solutionList[this.r.exportSettings.solutions.MIS]
          }

          if(this.r.exportSettings.solutions.Storefront){
            _solutions.Storefront = this.solutionList[this.r.exportSettings.solutions.Storefront]
          }

          if(this.r.exportSettings.solutions.VDP){
            _solutions.VDP = this.solutionList[this.r.exportSettings.solutions.VDP]
          }

          return _solutions;

        }

        return {}

      },

      survey: function(){
        return schema.survey;
      },

      iQ: function(){
        return schema.questions;
      },

      currentDate: function(){
        return (new Date(Date.now())).toLocaleDateString()
      },

      scorecardGrade: function(){
        if(this.r && this.r.scorecard && this.r.scorecard.final){
          if(this.r.scorecard.final < 1.5){
            return "Sub-Standard"
          }else if(this.r.scorecard.final < 2.5){
            return "Sufficient"
          }else if(this.r.scorecard.final < 3.5){
            return "Good"
          }else if(this.r.scorecard.final < 4.5){
            return "Very Good"
          }else if(this.r.scorecard.final < 5.1){
            return "Best-in-Class"
          }


        }

        return "NA"
      },

      departmentsReviewed: function(){

         if(this.r){
           let departments = this.r.interview.questions.X106.notes;
           return departments.split(',')
         }

         return [];
      },

      solutionList: function() {
         return {
            "CampaignServices": {name: "Customer Communications Management Campaign Services", component: "CampaignServices", category: "Other"},
           "CCMDesign": {name: "Customer Communications Management Design as a Service", component: "CCMDesign", category: "Other"},
           "EFICrossMedia": {name: "EFI MarketDirect Cross Media", component: "EFICrossMedia", category: "Other"},
           "EFICrossMediaCampaigns": {name: "EFI MarketDirect Cross Media Campaigns – Driven by Workflow Automation Team", component: "EFICrossMediaCampaigns", category: "Other"},
           "InteractiveVideo": {name: "Interactive Video", component: "InteractiveVideo", category: "Other"},
           "ObjectifLuneProducts": {name: "Objectif Lune Products", component: "ObjectifLuneProducts", category: "Other"},
           "ProcessDirector": {name: "RICOH ProcessDirector", component: "ProcessDirector", category: "Other"},
           "QuadientInspire": {name: "Quadient Inspire", component: "QuadientInspire", category: "Other"},
           "ColorGap": {name: "Color Gap Analysis", component: "ColorGap", category: "Other"},
           "RicohConsultingServices": {
             name: "Ricoh Consulting Services",
             component: "RicohConsultingServices",
             category: "Other"
           },
           "ProductionWorkflow": {name: "Production Workflow", component: "ProductionWorkflow", category: "Other"},
           "MailAssessment": {name: "Mail Assessment", component: "MailAssessment", category: "Other"},

          }
       }
    }
}
</script>

<style scoped>


.report-production-workflow {

}

</style>
