


<template>
<div class="link-list">
  <div v-if="listTitle" class="font-bold mb-4">{{ listTitle }}</div>
  <div class="pl-4">


    <div class="border-l-2 pl-4 py-2 link-list-item cursor-pointer" v-for="link in links" :key="link.id" :class="(selection.id == link.id)?'text-black border-brand-blue':'text-gray-500'" :data-empty="(link.count - link.hidden)" :data-inactive="!link.count" >
      <div class="hover:underline" @click="selectLink(link)">{{ link.label }} <small v-if="link.count">(<span>{{ link.count - link.hidden }})</span></small></div>
      <div v-if="(selection.id == link.id) && (link.sections.length > 1)">
        <div :key="sectionIndex" v-for="(questionSection, sectionIndex) in link.sections" @click="selectLink(link, sectionIndex)" class="text-sm mt-1 hover:underline" :class="(section === sectionIndex)?'text-brand-blue':''">{{ questionSection.label }} <small>(<span>{{ questionSection.relevant }})</span></small></div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>

export default {
    name: "timeline-list",
  components: {},
  props: {
      listTitle: {
        type: String,
        default: ""
      },
      links: {
        type: Array,
        default: function(){
          return [];
        }
      },
      selection: {
        required: true
      },
      section: {
        default: 0
      }
    },
    data: function(){
        return {}
        },
    methods: {
      selectLink: function(link, section=0){
        this.$emit("change", {...link, section: section});
      }
    },
    computed: {}
}
</script>

<style scoped>


.link-list {

}

.link-list .link-list-item {

}

.link-list-item[data-empty="true"]{
  opacity: 0.2;
}

.link-list-item[data-inactive="true"]{
  pointer-events: none;
}

</style>
