

export default {

    equipment: {


        MimakiUVJFX200: {
            id: 'MimakiUVJFX200',
            name: 'Mimaki UVJFX200',
            ppm: 0,
            opSpeed: 1.675,
            volume: 10552.5,
            volume2: 10552.5,
            volumeMax: 21105,
            volumeAbbr: "21.1K",
            volumeSuffix: "sq ft of images per month",
            baseFootage: 323,
            materialFootage: 180,
            totalFootage: 508,
            excludedBy: [

                "printOnRollOnly",
                "printOnRigidOrRoll",
                "rollSize36",
                "rollSize44",
                "rollSize60",
                "rigidSize18x24",
                "rigidSize24x36",
                "rigidSize4x8",
                "rigidSize5x10",
                "rigidThickness4",
                "rigidThickness6",
                "rigidThickness7",

            ],
            addedFootage: {}
        },

        EFIPro16hUVLED : {
            id: 'EFIPro16hUVLED',
            name: 'EFI Pro 16h UVLED ',
            ppm: 0,
            opSpeed:1.4875,
            volume: 9371.25,
            volume2: 9371.25,
            volumeMax: 18742.5,
            volumeAbbr: "18.7K",
            volumeSuffix: "sq ft of images per month",
            baseFootage: 338,
            materialFootage: 180,
            totalFootage: 518,
            excludedBy: [
                'clearInk',
                "printOnRollOnly",
                "printOnRigidOnly",
                "rollSize36",
                "rollSize44",
                "rigidSize18x24",
                "rigidSize24x36",
                "rigidSize5x10",
                "rigidThickness4",
                "rigidThickness6",
                "rigidThickness7",
            ],
            addedFootage: {}
        },

        EFIPro30fUV : {
            id: 'EFIPro30fUV',
            name: 'EFI Pro 30f UV',
            ppm: 0,
            opSpeed:6,
            volume: 37800,
            volume2: 37800,
            volumeMax: 75600,
            volumeAbbr: "75.6K",
            volumeSuffix: "sq ft of images per month",
            baseFootage: 400,
            materialFootage: 244,
            totalFootage: 644,
            excludedBy: [
                'clearInk',
                "printOnRollOnly",
                "printOnRigidOrRoll",
                "scannerCopy",
                "rollSize60",
                "rollSize36",
                "rollSize44",
                "rigidSize18x24",
                "rigidSize24x36",
                "rigidSize4x8",
                "rigidThickness2",
                "rigidThickness6",
                "rigidThickness7",
            ],
            addedFootage: {}
        },

        EFIPro24fUV : {
            id: 'EFIPro24fUV',
            name: 'EFI Pro 24f UV',
            ppm: 0,
            opSpeed:1.775,
            volume: 11182.5,
            volume2: 11182.5,
            volumeMax: 22365,
            volumeAbbr: "22.4K",
            volumeSuffix: "sq ft of images per month",
            baseFootage: 320,
            materialFootage: 180,
            totalFootage: 500,
            excludedBy: [
                'clearInk',
                "printOnRollOnly",
                "printOnRigidOrRoll",
                "scannerCopy",
                "rollSize60",
                "rollSize36",
                "rollSize44",
                "rigidSize18x24",
                "rigidSize24x36",
                "rigidSize5x10",
                "rigidThickness4",
                "rigidThickness6",
                "rigidThickness7",
            ],
            addedFootage: {}
        },

        RicohT7210 : {
            id: 'RicohT7210',
            name: 'Ricoh T7210',
            ppm: 0,
            opSpeed:6.7375,
            volume: 42446.25,
            volume2: 42446.25,
            volumeMax: 84892.5,
            volumeAbbr: "84.9K",
            volumeSuffix: "sq ft of images per month",
            baseFootage: 323,
            materialFootage: 244,
            totalFootage: 567,
            excludedBy: [

                "printOnRollOnly",
                "printOnRigidOrRoll",
                "scannerCopy",
                "rollSize60",
                "rollSize36",
                "rollSize44",
                "rigidSize18x24",
                "rigidSize24x36",
                "rigidSize4x8",
                "rigidThickness2",
                "rigidThickness6",
                "rigidThickness7",
            ],
            addedFootage: {}
        },

        RicohTF6250 : {
            id: 'RicohTF6250',
            name: 'Ricoh TF6250',
            ppm: 0,
            opSpeed:4.3,
            volume: 27090,
            volume2: 27090,
            volumeMax: 54180,
            volumeAbbr: "54.2K",
            volumeSuffix: "sq ft of images per month",
            baseFootage: 320,
            materialFootage: 180,
            totalFootage: 500,
            excludedBy: [
                "printOnRollOnly",
                "printOnRigidOrRoll",
                "scannerCopy",
                "rollSize60",
                "rollSize36",
                "rollSize44",
                "rigidSize18x24",
                "rigidSize24x36",
                "rigidSize5x10",
                "rigidThickness2",
                "rigidThickness6",
                "rigidThickness7",
            ],
            addedFootage: {}
        },

        RicohTF6251 : {
            id: 'RicohTF6251',
            name: 'Ricoh TF6251',
            ppm: 0,
            opSpeed:4.3,
            volume: 27090,
            volume2: 27090,
            volumeMax: 54180,
            volumeAbbr: "54.2K",
            volumeSuffix: "sq ft of images per month",
            baseFootage: 320,
            materialFootage: 180,
            totalFootage: 500,
            excludedBy: [
                "printOnRollOnly",
                "printOnRigidOnly",
                "scannerCopy",
                "rollSize60",
                "rollSize36",
                "rollSize44",
                "rigidSize18x24",
                "rigidSize24x36",
                "rigidSize5x10",
                "rigidThickness2",
                "rigidThickness6",
                "rigidThickness7",
            ],
            addedFootage: {}
        },

        XanteX16 : {
            id: 'XanteX16',
            name: 'XanteX16',
            ppm: 0,
            opSpeed:0.625,
            volume: 3937.5,
            volume2: 3937.5,
            volumeMax: 7875,
            volumeAbbr: "7.9K",
            volumeSuffix: "sq ft of images per month",
            baseFootage: 48,
            materialFootage: 100,
            totalFootage: 148,
            excludedBy: [
                'clearInk',
                "printOnRollOnly",
                "printOnRigidOrRoll",
                "scannerCopy",
                "rollSize60",
                "rollSize36",
                "rollSize44",
                "rollSize85",
                "rigidSize4x8",
                "rigidSize24x36",
                "rigidSize5x10",
                "rigidThickness2",
                "rigidThickness6",
                "rigidThickness4",
            ],
            addedFootage: {}
        },

        XanteX33 : {
            id: 'XanteX33',
            name: 'XanteX33',
            ppm: 0,
            opSpeed:1.485,
            volume: 7875,
            volume2: 7875,
            volumeMax: 15750,
            volumeAbbr: "15.8K",
            volumeSuffix: "sq ft of images per month",
            baseFootage: 80,
            materialFootage: 100,
            totalFootage: 180,
            excludedBy: [
                'clearInk',
                "printOnRollOnly",
                "printOnRigidOrRoll",
                "scannerCopy",
                "rollSize60",
                "rollSize36",
                "rollSize44",
                "rollSize85",
                "rigidSize4x8",
                "rigidSize18x24",
                "rigidSize5x10",
                "rigidThickness2",
                "rigidThickness7",
                "rigidThickness4",
            ],
            addedFootage: {}
        },

        RicohL5160 : {
            id: 'RicohL5160',
            name: 'Ricoh L5160',
            ppm: 0,
            opSpeed:2.0625,
            volume: 12993.75,
            volume2: 12993.75,
            volumeMax: 25987.5,
            volumeAbbr: "25.9K",
            volumeSuffix: "sq ft of images per month",
            baseFootage: 100,
            materialFootage: 100,
            totalFootage: 200,
            excludedBy: [

                "printOnRigidOrRoll",
                "printOnRigidOnly",
                "UVLED",
                'clearInk',
                "scannerCopy",
                "rollSize36",
                "rollSize44",
                "rollSize85",
                "rigidSize4x8",
                "rigidSize18x24",
                "rigidSize5x10",
                "rigidSize24x36",
                "rigidThickness2",
                "rigidThickness6",
                "rigidThickness7",
                "rigidThickness4",
            ],
            addedFootage: {}
        },

        RolandVG2 : {
            id: 'RolandVG2',
            name: 'Roland VG2  w/in-line contour cutting',
            ppm: 0,
            opSpeed:1.725,
            volume: 10867.5,
            volume2: 10867.5,
            volumeMax: 21735,
            volumeAbbr: "21.7K",
            volumeSuffix: "sq ft of images per month",
            baseFootage: 100,
            materialFootage: 100,
            totalFootage: 200,
            excludedBy: [

                "printOnRigidOrRoll",
                "printOnRigidOnly",
                "UVLED",
                'clearInk',
                "scannerCopy",
                "rollSize36",
                "rollSize44",
                "rollSize85",
                "rigidSize4x8",
                "rigidSize18x24",
                "rigidSize5x10",
                "rigidSize24x36",
                "rigidThickness2",
                "rigidThickness6",
                "rigidThickness7",
                "rigidThickness4",
            ],
            addedFootage: {}
        },

        EpsonP9000 : {
            id: 'EpsonP9000',
            name: 'Epson P9000  w/in-line rotary cutter',
            ppm: 0,
            opSpeed:0.3875,
            volume: 2441.25,
            volume2: 2441.25,
            volumeMax: 4883,
            volumeAbbr: "4.9K",
            volumeSuffix: "sq ft of images per month",
            baseFootage: 78,
            materialFootage: 100,
            totalFootage: 178,
            excludedBy: [

                "printOnRigidOrRoll",
                "printOnRigidOnly",
                "UVLED",
                'clearInk',
                'whiteInk',
                "scannerCopy",
                "rollSize36",
                "rollSize60",
                "rollSize85",
                "rigidSize4x8",
                "rigidSize18x24",
                "rigidSize5x10",
                "rigidSize24x36",
                "rigidThickness2",
                "rigidThickness6",
                "rigidThickness7",
                "rigidThickness4",
            ],
            addedFootage: {}
        },

        EpsonT5270 : {
            id: 'EpsonT5270',
            name: 'Epson T5270',
            ppm: 0,
            opSpeed:0.875,
            volume: 5512.5,
            volume2: 5512.5,
            volumeMax: 11025,
            volumeAbbr: "11K",
            volumeSuffix: "sq ft of images per month",
            baseFootage: 50,
            materialFootage: 50,
            totalFootage: 100,
            excludedBy: [

                "printOnRigidOrRoll",
                "printOnRigidOnly",
                "UVLED",
                'clearInk',
                'whiteInk',
                "rollSize44",
                "rollSize60",
                "rollSize85",
                "rigidSize4x8",
                "rigidSize18x24",
                "rigidSize5x10",
                "rigidSize24x36",
                "rigidThickness2",
                "rigidThickness6",
                "rigidThickness7",
                "rigidThickness4",
            ],
            addedFootage: {}
        },










    },

    siteFunctionDetails: [
        {
            id: 'volumePerMonth',
            type: 'number',
            required: true,
            component: 'PulloutQuestionNumber',
            label: 'Volume Monthly',
            default: 0,
            description: "sq ft of images per month",
            additionalInfo: (context) => {
                if(context.interview.questions.X119B){
                    if(context.interview.questions.X119B.answer.final){
                        let yearlyValue = parseInt(context.interview.questions.X119B.answer.final);
                        if(yearlyValue){
                            let monthlyValue = parseInt(yearlyValue / 12);
                            return `Site Survey indicated ${ monthlyValue.toLocaleString() } sq ft of images per month ( ${ yearlyValue.toLocaleString() } yearly )`
                        }

                    }
                }

                return ''
            },
        },
        {
            id: 'jobsPerMonth',
            type: 'number',
            required: true,
            component: 'PulloutQuestionNumber',
            label: 'Jobs Per Month',
            default: 0,
            validationInfo: {
                isValid: (value) => { return (value < 501) },
                warningMsg: "If total jobs per month exceeds 500, an MIS system is recommended.",
                overrideValue: undefined
            },
            description: "jobs per month"
        },
        {
            id: 'jobSetupTime',
            type: 'number',
            required: true,
            component: 'PulloutQuestionNumber',
            label: 'Job Setup Time',
            default: 5,
            validationInfo: {
                isValid: (value) => { return (value > 0) },
                warningMsg: "Setup time needs to be a value greater than 0 minutes",
                overrideValue: undefined
            },
            description: "minutes per job"
        },
        {
            id: 'UVLED',
            type: 'boolean',
            required: true,
            component: 'PulloutQuestionSwitch',
            label: 'UV LED',
            default: false
        },
        {
            id: 'scannerCopy',
            type: 'boolean',
            required: true,
            component: 'PulloutQuestionSwitch',
            label: 'Scanner / Copy',
            default: false
        },

        {
            id: 'clearInk',
            type: 'boolean',
            required: true,
            component: 'PulloutQuestionSwitch',
            label: 'Clear Ink',
            default: false
        },
        {
            id: 'whiteInk',
            type: 'boolean',
            required: true,
            component: 'PulloutQuestionSwitch',
            label: 'White Ink',
            default: false
        },

        {
            id: 'printingOn',
            type: 'map',
            required: true,
            component: 'PulloutQuestionMultiSwitch',
            label: 'Can Print On Rigid or Roll',
            default: {},
            options: [
                {
                    id:"printOnRollOnly",
                    label: "Print on Roll Only",
                    default: false
                },
                {
                    id:"printOnRigidOnly",
                    label: "Print on Rigid Only",
                    default: false
                },
                {
                    id: "printOnRigidOrRoll",
                    label: "Print on Rigid or Roll",
                    default: false
                }
            ]
        },
        {
            id: 'rollSize',
            type: 'map',
            required: true,
            component: 'PulloutQuestionMultiSwitch',
            label: 'Roll Size',
            default: {},
            options: [
                {
                    id:"rollSize36",
                    label: '36"',
                    default: false
                },
                {
                    id:"rollSize44",
                    label: '44"',
                    default: false
                },
                {
                    id:"rollSize60",
                    label: '60"',
                    default: false
                },
                {
                    id:"rollSize85",
                    label: '85"',
                    default: false
                }
            ]
        },

        {
            id: 'rigidSize',
            type: 'map',
            required: true,
            component: 'PulloutQuestionMultiSwitch',
            label: 'Rigid Material Size',
            default: {},
            options: [
                {
                    id:"rigidSize18x24",
                    label: '18" x 24"',
                    default: false
                },
                {
                    id:"rigidSize24x36",
                    label: '24" x 36"',
                    default: false
                },
                {
                    id:"rigidSize4x8",
                    label: "4' x 8'",
                    default: false
                },
                {
                    id:"rigidSize5x10",
                    label: "5' x 10'",
                    default: false
                },
            ]
        },

        {
            id: 'rigidThickness',
            type: 'map',
            required: true,
            component: 'PulloutQuestionMultiSwitch',
            label: 'Rigid Thickness up to',
            default: {},
            options: [
                {
                    id:"rigidThickness2",
                    label: '2"',
                    default: false
                },
                {
                    id:"rigidThickness4",
                    label: '4"',
                    default: false
                },
                {
                    id:"rigidThickness6",
                    label: '6"',
                    default: false
                },
                {
                    id:"rigidThickness7",
                    label: '7.75"',
                    default: false
                },

            ]
        },
    ]
}

