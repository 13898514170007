


<template>
<div class="">
  <h4>Standard Operating Procedures - Best Practices</h4>
  <hr>
  <p>Develop a procedures guide for the Print Center. Include operating procedures, troubleshooting, maintenance, service information/contacts, supplies and techniques for processing typical jobs. Keep the guide near equipment for ease of access by operators.</p>
  <p class="bold-label">Below are some key standard operating procedures to improve consistency and quality:</p>
  <ul>
    <li>Processing common jobs</li>
    <li>Quality check process</li>
    <li>Action plans for common problems</li>
    <li>Continuous improvement program</li>
  </ul>
</div>
</template>

<script>
export default {
    name: "standard-operating-procedures",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


</style>
