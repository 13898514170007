


<template>
<div class="avanti-slingshot">

  <h4>Avanti Slingshot</h4>
  <hr>
  <p>Avanti Slingshot is the next generation Print MIS (Management Information System), incorporating business intelligence (CRM, dashboards and reporting), production planning (estimating, imposition, planned purchasing, sales orders, inventory management and scheduling), warehousing, shipping and billing into one easy-to-use, easy-to-implement system.</p>
  <p>If you are looking to provide your clients with more than just “ink on paper” or shifting from a Print Service Provider to a Marketing Service Provider, that shift typically involves adding new lines of business to your portfolio; including Direct Mail, Fulfillment, Marketing Services and Large Format.</p>
  <p>We believe that it is extremely difficult, if not impossible, to stretch your old MIS system to handle all of the complexities and workflows for these very different lines of business. Avanti Slingshot was designed, from the ground up, to support this new way of doing business.</p>
  <p class="bold-label">Customer Benefits:</p>
    <ul>
      <li>Browser-based, providing secure anywhere/anytime access on any device.</li>
      <li>Supports multiple lines of business including offset, digital, large format, fulfillment, mailing and marketing services.</li>
      <li>JDF integration, connecting “islands of automation”.</li>
      <li>Designed to dramatically decrease implementation time through Estimating Standards library, import/migration tools and a QuickStart Database.</li>
      <li>Intuitive User Interface reduces end user training time.</li>
    </ul>
  <p class="bold-label">Key Features:</p>
  <ul>
    <li>Browser-based system</li>
    <li>JDF Integration</li>
    <li>Intuitive GUI</li>
    <li>Automated purchasing</li>
    <li>Advanced inventory management, warehousing and fulfillment</li>
    <li>Import/Migration Tools and Avanti’s QuickStart Database</li>
    <li>Full document and asset management throughout the system</li>

  </ul>
  <p class="bold-label">Benefits:</p>
  <ul>
    <li>Secure access on any device, anytime, anywhere.</li>
    <li>Reduce end-user training time.</li>
    <li>Reduce bottlenecks and touchpoints through automation.</li>
    <li>A robust API enables the integration of third-party applications and eliminates the need to input data multiple times, reducing errors.</li>
    <li>Decreases implementation time through Estimating Standards Library and speeds up job processing.</li>

  </ul>



</div>
</template>

<script>
export default {
    name: "avanti-slingshot",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


.avanti-slingshot {

}

</style>