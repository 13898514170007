<template>
  <div v-if="detailSummary" class="detail-summary">

    <p class="spec-item" v-if="detailSummary.description">{{ detailSummary.description }}</p>

    <p class="requirement-item" v-for="requirement in detailSummary.requirements" :key="requirement.id">+ {{ requirement.summaryLabel?requirement.summaryLabel:requirement.label }} <span v-if="requirement.description">( {{ requirement.description }} )</span></p>
    <div class="recommendation">
      <div class="recommendation-label" v-if="detailSummary"><h5>{{ detailSummary.summaryLabel }} </h5><button v-if="detailSummary.models" class="button text-xs px-2 py-1 modify-button reversed" @click="onModifyClick">CUSTOMIZE</button><button v-if="detailSummary.models" class="button text-xs px-2 py-1 modify-button reversed" @click="onAutoGenerate">AUTO GENERATE</button></div>

      <div class="model-configurations" v-if="customConfiguration">
        <div class="model-summary" v-for="(model,index) in customConfiguration.models" :key="'m'+index">
          <p class="model-name"><span>1</span> {{ model.model.name }} <span v-if="(model.shifts > 1)">running {{ model.shifts }} shifts</span><span class="footage"> ({{ model.footprint }} sq ft.)</span></p>
          <p class="model-options">
            <template v-for="(option, index) in model.options">
              <span class="model-option"  :key="option.id" v-if="option">{{ question.configurator.detailMapPlusOptions[index].label }}</span>
            </template>
          </p>

        </div>
        <p class="staffing" v-if="detailSummary.staffing">Estimated Staffing: {{ detailSummary.staffing | decimal(2) }} employee(s)</p>

      </div>
      <div class="model-configurations" v-else-if="detailSummary">
        <p class="model-name" v-if="detailSummary.recommendationWarning">{{ detailSummary.recommendationWarning }}</p>
        <div v-if="detailSummary.models">
          <div class="model-summary" v-for="(model,index) in detailSummary.models" :key="'m'+index">
            <p class="model-name"><span>1</span> {{ model.model.name }} <span v-if="(model.shifts > 1)">running {{ model.shifts }} shifts</span><span class="footage"> ({{ model.footprint }} sq ft.)</span></p>
            <p class="model-options">
              <template  v-for="(option, index) in model.options">
                <span class="model-option" :key="option.id" v-if="option">{{ question.configurator.detailMapPlusOptions[index].label }}</span>
              </template>
            </p>
          </div>
        </div>

        <p class="staffing" v-if="detailSummary.staffing">Estimated Staffing: {{ detailSummary.staffing | decimal(2) }} employee(s)</p>
        <p class="staffing" v-if="!detailSummary.models">Footprint: {{ detailSummary.footprint }} sq ft</p>

      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "EquipmentDetailSummary",
  props: {
    question: {
      type: Object,
      required: true
    },
    answer: {
      type:Object,
      required: true
    },
    detailSummary: {
      required: true
    },

  },
  mounted() {
    //console.log("Mounted Detail Summary")
    //console.log("answer",this.answer)
    //console.log("customConfiguation", this.customConfiguration)
  },

  methods: {


    onModifyClick: function(){
      this.$emit("modify");
    },

    onAutoGenerate: function(){
      this.$emit("autoGenerate");
    }
  },
  computed: {
    isActive: function(){
      return this.answer.value;
    },

    customConfiguration: function(){
      return this.answer?.details?.customConfiguration;
    }

  }
}
</script>

<style scoped>

.detail-summary {
  padding: 0 3em;

}

.modify-button {
  position: relative;
  font-size: 0.5em;
  margin: 0 1em;

}

.spec-item {
  color: var(--near-black-2);
  display: block;
  font-weight: bold;
  font-size: 0.8em;
}

.requirement-item {
  color: var(--near-black-2);
  display: block;
  font-size: 0.8em;
  margin-top: 0.5em;
}

.recommendation {
  margin-top: 1em;
}

.recommendation-label {
  border-bottom: 1px dashed var(--mid-gray);
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  display: flex;
}

.model-name {
  padding-top: 0.5em;
  font-size: 0.8em;
  font-weight: bold;
  color: var(--near-black-2);
}

.footage {
  padding-top: 0.5em;
  font-size: 0.8em;
  color: var(--near-black-2);
}

.staffing {
  padding-top: 0.5em;
  font-size: 0.8em;
  color: var(--near-black-2);
}

.model-configurations {

  position: relative;
}

.model-summary {
  margin: 0.5em 0;
  width: 48%;
  margin-right: 1%;
}

.model-options {
  font-size: 0.6em;
  color: var(--near-black-2);
}

.model-option {
  display: block;
  margin-top: 0.5em;
}

.model-option:before {
  content: "+ ";

}

.model-option:last-child:after {
  content: ""
}

</style>
