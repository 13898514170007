<template>
    <div :class="classObject">

        <form-question-label v-bind:class="labelClassObject" >{{ detail.label }}</form-question-label>
        <form-radio-dot :checked="answers[detail.id]" @click.native="onChangeValue"></form-radio-dot>
    </div>
</template>

<script>
    import FormQuestionLabel from "./FormQuestionLabel.vue";
    import FormRadioDot from "./FormRadioDot.vue";

    export default {
        name: "pullout-question-switch",
        components: {
            FormRadioDot,
            FormQuestionLabel
        },
        props: {
            detail: {
                type: Object,
                required: true
            },

          answers: {
            required: true
          }
        },

        computed: {
            classObject: function(){
                return {
                    "pullout-question-switch": true,
                    "is-active": this.answers[this.detail.id]
                }
            },

            labelClassObject: function(){
                return {
                    "question-label": true,
                    "dark": this.answers[this.detail.id]
                }

            }
        },

        methods: {
            onChangeValue: function(){

              this.answers[this.detail.id] = !this.answers[this.detail.id]
              console.log(this.answers[this.detail.id]);
                // let update = {
                //     id: this.detail.id,
                //     value: value
                // }
                // this.$emit('change', update);
            }
        }
    }
</script>

<style scoped>


    .pullout-question-switch {
        display: flex;
        align-items: center;
        justify-content: space-between;


    }

    .question-label {

    }

</style>
