<template>
    <div class="pullout-question-number">
        <form-question-label class="question-label">{{ detail.label }}<span v-if="detail.description"> ({{ detail.description }})</span></form-question-label>
<!--        <input class="question-input" type="text" :value="formattedAnswer" @input="onChangeValue" :data-warning="!(!warning)"/>-->
        <p class="inline-block bg-yellow-300" v-if="additionalInfo">{{ additionalInfo }}</p>
      <div class="flex items-center">
        <span class="pr-2" v-if="detail.prefix">{{ detail.prefix }}</span>
        <number-input class="question-input" v-model="answers[detail.id]" @change="onChangeValue" :data-warning="!(!warningMsg)"></number-input>
        <span class="pl-2" v-if="detail.suffix">{{ detail.suffix }}</span>
        <div v-if="triggerButton" class="ml-2 button text-xs" :class="answers[detail.id]?'button-green cursor-pointer':'pointer-events-none'" @click="$emit('trigger')">DETAILS</div>
      </div>

        <p v-if="warningMsg">{{ warningMsg }}</p>
    </div>
</template>

<script>
    import FormQuestionLabel from './FormQuestionLabel.vue'
    //import numeral from 'numeral';
    //import { Validator } from '../../configurators/helpers.js';
    import NumberInput from "@/components/NumberInput";

    export default {
        components: {
          NumberInput,
            FormQuestionLabel
        },
        name: "pullout-question-number",
        props: {
            detail: {
                type: Object,
                required: true
            },

            answers: {
                required: true
            },

            context: {
                required: false,
            },

            triggerButton: {
              type: String,
              default: ""
            }

        },
        data: function(){
            return {
                warning: undefined
            }
        },
        computed: {
            isValid: function () {
              if(this.detail.validationInfo){
                return this.detail.validationInfo.isValid(this.answers[this.detail.id]);
              }
              return true;
            },
            warningMsg: function(){
              if(!this.isValid){
                return this.detail.validationInfo.warningMsg;
              }

              return null;
            },
            overrideValue: function(){
              if(!this.isValid){
                return this.detail.validationInfo.overrideValue;
              }

              return null;
            },

            additionalInfo: function(){
              if(this.detail.additionalInfo){
                return this.detail.additionalInfo(this.context);
              }

              return ''
            }
        },
        methods: {
            onChangeValue: function(e){

              console.log(e);



                // let update = {
                //     id: this.detail.id,
                //     value: numeral(e.target.value).value()
                // }



                //this.$emit('change', update);


            }
        },

        mounted: function(){
            if(this.detail.validationInfo){
                // let validation = Validator(this.answer, this.detail.validationInfo);
                // console.log(validation);
                // this.warning = validation.warningMsg;
            }else {
                console.log("No validation");
            }
        }
    }
</script>

<style scoped>


    .pullout-question-number {

        position: relative;
    }

    .question-label {
        display: block;
        margin: 0.5em 0;
    }

    .question-input {
        position: relative;
        font-size: 1em;
        padding: 0.5em;
        box-sizing: border-box;
        width: 100%;
        margin: 0.5em 0;
      border: 1px solid var(--mid-gray);
      border-radius: 5px;
    }

    .question-input[data-warning = "true"]{
        background-color: yellow;
    }

</style>
