

export default {

    equipment: {




        pro8300: {
            id: 'pro8300',
            name: 'Pro8300',
            ppm: 96,
            volume: 544320,
            volume2: 544320,
            volumeMax: 1000000,
            volumeAbbr: "1M",
            volumeSuffix: 'B&W pages per month',
            baseFootage: 30,
            materialFootage: 40,
            totalFootage: 70,
            excludedBy: [
                'paperGSM300plus',
                'bannerPrinting',
                'micrInk',
                'inserterInterposer',
                'stacker',
                'stackerBannerHC',
                'gbcPunch',
                'ringBind',
                'bookletmaking140pg',
                'bookletmaking200pg',
                'perfectBindSmall'


            ],
            addedFootage: {

                'paperAdditional3Drawers85': 23,
                'paperAdditional3Drawers': 23,
                'paperAdditional2Drawers': 28,
                'inserterInterposerTray': 9,
                'inserterInterposer': 32,

                'stacker': 24,
                'stackerBannerHC': 24,

                'holePunch': 20,
                'stapling': 23,
                'folding': 12,
                'gbcPunch': 12,
                "ringBind": 19,

                "bookletmaking120pg": 72,
                "bookletmaking140pg": 84,
                "bookletmaking200pg": 84,
                "perfectBindSmall": 28,

            }
        },

        pro8310: {
            id: 'pro8310',
            name: 'Pro8310',
            ppm: 111,
            volume: 629370,
            volume2: 472028,
            volumeMax: 1000000,
            volumeAbbr: "1M",
            volumeSuffix: 'B&W pages per month',
            baseFootage: 32,
            materialFootage: 45,
            totalFootage: 77,
            excludedBy: [],
            addedFootage: {

                'paperAdditional3Drawers85': 23,
                'paperAdditional3Drawers': 23,
                'paperAdditional2Drawers': 28,
                'inserterInterposerTray': 9,
                'inserterInterposer': 32,

                'stacker': 24,
                'stackerBannerHC': 24,

                'holePunch': 20,
                'stapling': 23,
                'folding': 12,
                'gbcPunch': 12,
                "ringBind": 19,

                "bookletmaking120pg": 72,
                "bookletmaking140pg": 84,
                "bookletmaking200pg": 84,
                "perfectBindSmall": 28,

            }
        },

        pro8320: {
            id: 'pro8320',
            name: 'Pro8320',
            ppm: 136,
            volume: 771120,
            volume2: 578340,
            volumeMax: 1000000,
            volumeAbbr: "1M",
            volumeSuffix: 'B&W pages per month',
            baseFootage: 32,
            materialFootage: 55,
            totalFootage: 87,
            excludedBy: [],
            addedFootage: {

                'paperAdditional3Drawers85': 23,
                'paperAdditional3Drawers': 23,
                'paperAdditional2Drawers': 28,
                'inserterInterposerTray': 9,
                'inserterInterposer': 32,

                'stacker': 24,
                'stackerBannerHC': 24,

                'holePunch': 20,
                'stapling': 23,
                'folding': 12,
                'gbcPunch': 12,
                "ringBind": 19,

                "bookletmaking120pg": 72,
                "bookletmaking140pg": 84,
                "bookletmaking200pg": 84,
                "perfectBindSmall": 28,

            }
        },

    },

    siteFunctionDetails: [
        {
            id: 'volumePerMonth',
            type: 'number',
            required: true,
            component: 'PulloutQuestionNumber',
            label: 'Volume Monthly',
            default: 0,
            validationInfo: {
                isValid: (value) => { return (value < 5000000) },
                warningMsg: "With this high volume of monochrome pages per month you may consider an Inkjet machine.",
                overrideValue: undefined
            },
            additionalInfo: (context) => {
                if(context.interview.questions.X119){
                    if(context.interview.questions.X119.answer.final){
                        let yearlyValue = parseInt(context.interview.questions.X119.answer.final);
                        if(yearlyValue){
                            let monthlyValue = parseInt(yearlyValue / 12);
                            return `Site Survey indicated ${ monthlyValue.toLocaleString() } B&W pages per month ( ${ yearlyValue.toLocaleString() } yearly )`
                        }

                    }
                }

                return ''
            },
            description: "B&W pages per month"
        },

        {
            id: 'jobsPerMonth',
            type: 'number',
            required: true,
            component: 'PulloutQuestionNumber',
            label: 'Jobs Per Month',
            default: 0,
            validationInfo: {
                isValid: (value) => { return (value < 501) },
                warningMsg: "If total jobs per month exceeds 500, an MIS system is recommended.",
                overrideValue: undefined
            },
            description: "B&W jobs per month"
        },
        {
            id: 'paperGSM300plus',
            type: 'boolean',
            required: true,
            component: 'PulloutQuestionSwitch',
            label: 'Paper GSM > 300 GSM',
            default: false
        },
        {
            id: 'bannerPrinting',
            type: 'boolean',
            required: true,
            component: 'PulloutQuestionSwitch',
            label: 'Banner Simplex 27.5" 300 GSM',
            default: false
        },
        {
            id: 'micrInk',
            type: 'boolean',
            required: true,
            component: 'PulloutQuestionSwitch',
            label: 'MICR Ink',
            default: false
        },
        {
            id: 'scanning',
            type: 'boolean',
            required: true,
            component: 'PulloutQuestionSwitch',
            label: 'Scanning',
            default: false
        },

        {
            id: 'inserter',
            type: 'map',
            required: true,
            component: 'PulloutQuestionMultiSwitch',
            label: 'Inserter',
            default: false,
            options: [
                {
                    id: 'inserterInterposer',
                    label: 'Inserter Interposer 2 Drawers (cvr & body)',
                    default: false,
                },
                {
                    id: 'inserterInterposerTray',
                    label: 'Inserter Interposer Tray',
                    default: false
                }

            ]
        },

        {
            id: 'inlineFinishing',
            type: 'map',
            required: true,
            component: 'PulloutQuestionMultiChoice',
            label: 'In-Line Finishing',
            default: {},
            options: [
                {
                    id: "stacker",
                    label: "High Capacity Stacker",
                    default: false
                },
                {
                    id: "stackerBannerHC",
                    label: "Banner HC Stacker",
                    default: false
                },
                {
                    id: "holePunch",
                    label: "Hole Punching",
                    default: false
                },
                {
                    id: "stapling",
                    label: "Stapling",
                    default: false
                },
                {
                    id: "folding",
                    label: "Folding",
                    default: false
                },
                {
                    id: "gbcPunch",
                    label: "GBC Punch",
                    default: false
                },
                {
                    id: "ringBind",
                    label: "Ring Bind",
                    default: false

                }


            ]
        },
        {
            id: 'bookletMaking',
            type: 'map',
            component: 'PulloutQuestionMultiSwitch',
            required: true,
            label: 'Bookletmaking',
            default: {},
            options: [
                {
                    id: "bookletmaking120pg",
                    label: "Bookletmaking 120pg (w/face trim, square spine)",
                    default: false,
                    excludes: ["bookletmaking140pg","bookletmaking200pg","perfectBindSmall"]

                },
                {
                    id: "bookletmaking140pg",
                    label: "Bookletmaking 140pg (w/face trim, square spine)",
                    default: false,
                    excludes: ["bookletmaking120pg","bookletmaking200pg","perfectBindSmall"]

                }, {
                    id: "bookletmaking200pg",
                    label: "Bookletmaking 200pg (w/face trim, square spine)",
                    default: false,
                    excludes: ["bookletmaking120pg","bookletmaking140pg","perfectBindSmall"]

                }

            ]
        },
        {
            id: 'paperAdditional',
            type: 'map',
            component: 'PulloutQuestionMultiSwitch',
            required: true,
            label: 'Paper Additional',
            default: {},
            options: [
                {
                    id: "paperAdditional2Drawers",
                    label: "Paper Additional 2 Drawers",
                    default: false

                },
                {
                    id: "paperAdditional3Drawers",
                    label: "Paper Additional 3 Drawers",
                    default: false

                },
                {
                    id: "paperAdditional3Drawers85",
                    label: "Paper Additional 3 Drawers 8.5 x 11",
                    default: false

                },

            ]
        },

        {
            id: 'perfectBindSmall',
            type: 'boolean',
            required: true,
            component: 'PulloutQuestionSwitch',
            label: 'Perfect Bind Small Volume',
            default: false
        },





    ]
}

