


<template>
<div class="needs-questionnaire py-8">
  <div class="p-4 bg-gray-100 rounded-md mb-4 -ml-4 text-gray-600" v-if="!listing.relevant && filterRelevant"><span class="font-bold text-lg">There are no {{ listing.label }} related questions for {{ departmentLabel }}.</span><span class="block text-sm">There are {{ listing.hidden }} {{ listing.label }} questions currently hidden. To see all questions, change your respondent or viewing options in the <span class="underline text-brand-blue hover:text-black cursor-pointer" @click="showSettings">questionnaire settings</span>.</span></div>
  <div class="p-4 bg-gray-100 rounded-md mb-4 -ml-4" v-else>
    <div class="font-bold text-xl text-gray-600">{{ sectionTitle }} <span v-if="sectionWarning" class="text-red-500"> – {{ sectionWarning }}</span> <span class="text-sm">{{ questionCountSummary }}</span></div>
    <p class="text-sm text-gray-500 max-w-xl">Use the <b>+</b> button to enter responses for each question below. Change the viewing options for questions in the <span class="underline text-brand-blue hover:text-black cursor-pointer" @click="showSettings">questionnaire settings</span>.</p>
  </div>
    <div v-for="section in sections" :key="section.id">
      <div v-for="group in section.groups" :key="group.label">
          <needs-question v-for="question in group.questions" :key="question" :hide-irrelevant="filterRelevant" :question="api.schema.questions[question]" :analysis="analysis" :responses="responsesByQuestion[question]" :voice="department" :api="api" @edit="editAnswer" @settings="$emit('settings')"></needs-question>
      </div>
    </div>
    <div class="flex items-center pt-4 pb-12 border-t">
      <button class="button button-blue inline-flex items-center mr-2" @click="goBack" v-if="previous"><img class="w-4 h-4 inline-block transform rotate-180" src="../../assets/icons/next-arrow-icon.svg" /> <span class="ml-2">{{ api.schema.sections[previous].label }}</span></button><button class="button button-blue inline-flex items-center" @click="goNext" v-if="next">{{ api.schema.sections[next].label }} <img class="w-4 h-4 inline-block ml-2" src="../../assets/icons/next-arrow-icon.svg" /></button>

    </div>
</div>
</template>

<script>

import NeedsQuestion from "@/components/NeedsAnalysis/NeedsQuestion";

export default {
    name: "needs-questionnaire",
  components: {
      NeedsQuestion
  },
  props: {
      analysis: {
        type: Object,
        required: true
      },
      listing: {
        type: Object,
        required: true
      },

      responsesByQuestion: {
        type: Object,
        required: true
      },

      filterRelevant: {
        type: Boolean,
        default: true
      },

    next: {
        type:String,
      required: false,
      default: ""
    },

    previous: {
      type:String,
      required: false,
      default: ""
    },

      api: {
        type: Object,
        required: true
      },

    department: {
      type: String,
      default: "all",
    },

    section: {
        default: 0
    }

  },
    data: function(){
        return {}
        },
    methods: {

      showSettings: function(){
        this.$emit("settings")
      },
      editAnswer: function(info){
        this.$emit("edit-answer", info)
      },

      goBack: function(){
        this.$emit("showPrevious")
      },

      goNext: function(){
        this.$emit("showNext")
      }
    },
    computed: {

      sectionTitle: function(){
        if(this.targetSection){
          return this.sections[0].label;
        }

        return this.listing.label
      },

      sectionWarning: function(){
        if(this.targetSection){
          return this.sections[0].warning;
        }

        return this.listing.warning
      },

      questionCountSummary: function(){



        if(this.targetSection){
          if(this.filterRelevant){
            return `(Showing the ${ this.questionCounts.relevant } of ${ this.questionCounts.total } questions in this section that are relevant for this respondent.)`
          }

          if(this.listing.hidden){

            return `(${ this.questionCounts.relevant } of ${this.questionCounts.total} possible questions in this section are relevant for this respondent.)`
          }

          return `(${ this.questionCounts.total } questions in this section are relevant for this respondent.)`

        }else{
          if(this.filterRelevant){
            return `(Showing the ${ this.questionCounts.relevant } of ${ this.listing.count } questions in this section that are relevant for this respondent.)`
          }

          if(this.listing.hidden){
            let total = this.listing.relevant + this.listing.hidden;
            return `(${ this.questionCounts.relevant } of ${total} possible questions in this section are relevant for this respondent.)`
          }

          return `(${ this.listing.count } questions in this section are relevant for this respondent.)`
        }







      },

      departmentLabel: function(){
        if(this.department && this.department !== "all"){
          return (this.api.schema.voiceOptions[this.department].label)
        }

        return "the selected department"
      },

      targetSection: function(){
        if(this.listing){
          return ((this.listing.sections.length > 1) && (this.section || this.section === 0))
        }else{
          return false;
        }
      },

      sections: function(){
        if(this.listing){
          if(this.targetSection){
            return [this.listing.sections[parseInt(this.section)]]
          }else{
            return this.listing.sections
          }
        }

        return []
      },

      questionCounts: function(){
        let count =  { relevant: 0, total: 0};

        if(this.targetSection && this.sections[0].count){
          count.total = this.sections[0].count;
          count.relevant = this.sections[0].relevant;
          return count;
        }

        this.sections.forEach((section) => {

              section.groups.forEach((group) => {
                if(!this.department || (this.department === "all") || (this.department === "notes")) {
                  count.total += group.questions.length;
                  count.relevant += group.questions.length;
                }else{
                  group.questions.forEach((question) => {
                    count.total++;
                    if(this.api.schema.questions[question].voices.includes(this.department)){
                      count.relevant++;
                    }
                  })
                }
              })
        })

        return count;
      },
    }
}
</script>

<style scoped>


.needs-questionnaire {

}

</style>
