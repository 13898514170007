


<template>
<div class="">
  <h4>Disaster Recovery/Business Continuity Planning</h4>
  <hr>
  <p>Business Continuity and Disaster Recovery Services are a powerful way to keep the operations of a company running seamlessly and without disruption if unforeseen circumstances arise such as natural disasters, overflow or human error.</p>
  <p>When disasters strike such as equipment and software failure, power outages due to storms, floods or even labor disputes, business stops. If your customer is prepared and enforces their Disaster Recovery Plan, business resumes with minimal interruption.</p>
  <p>Many companies today have developed a disaster recovery plan or elements of a plan. However, various organizations are missing a vital aspect of Business Continuity: print, mail and e-mail.</p>
  <p>When customers have no way of printing, mailing or emailing their checks, statements, invoices, notices or memos, that’s where Ricoh completes their Disaster Recovery Plan.</p>
  <p>We have two data, print and mail center facilities located in the Midwest and western part of the United States where your customers can view and access their secure information via wireless or landline.</p>
  <p>All Business Continuity Services are planned, managed and executed through Ricoh Consulting Group.</p>
</div>
</template>

<script>
export default {
    name: "disaster-recovery",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


</style>
