<template>
    <div class="pullout-modify-panel">
        <div class="menu-header">
            <icon-x class="close-button" @click.native="hide"></icon-x>
            <h3 class="header-label">{{ question.label }}</h3>


        </div>
        <div class="pullout-question">
            <product-detail-grid :question="question" :answers="answers"></product-detail-grid>
        </div>
        <footer class="pullout-footer">
            <button class="button px-2 py-1 save-button" @click="hide">SAVE/RETURN</button>
        </footer>
    </div>
</template>

<script>

    import IconX from './IconX.vue';

    import ProductDetailGrid from "./ProductDetailGrid.vue";

    export default {
        name: "pullout-modify-panel",
        components: {
            ProductDetailGrid,
            IconX,
        },
        props: {
            question: {
                type: Object,
                required: true
            },
            answers: {
                type: Object,
                required: true
            }

        },

        computed: {
            details: function(){
                if(this.question.configurator){
                    return this.question.configurator.detailList;
                }else{
                    return this.question.details;
                }



            },
            detailSummary: function(){
                if(this.question.configurator){
                    return this.question.configurator.summary(this.answers);
                }

                return null;

            }
        },

        methods: {
            hide: function(){
                this.$emit('hide');
            },

            onDetailChange: function(detail){

              console.log(detail)
                // var update = {
                //     questionId: this.question.id,
                //     detail: detail
                // }
                // this.$store.dispatch('updateDetail',update);
            },

            clearDetails: function(){
                //this.$store.dispatch('resetDetails', this.question.id);
            }
        }
    }
</script>

<style scoped>


    .pullout-modify-panel {

        overflow-y: scroll;
        background-color: var(--near-white-2);
      box-shadow: 0 3px 16px rgba(64,64,64,0.1);
    }

    .menu-header {
        background-color: white;
      box-shadow: 0 3px 16px rgba(64,64,64,0.1);
        height: 3em;
        box-sizing: border-box;
        padding: 0.5em 1em;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .header-label {
        padding-left: 1em;
        padding-right: 1em;
    }

    .close-button {
        fill: var(--mid-gray);
        height: 1em;
        cursor: pointer;

    }

    .close-button:hover {
        fill: var(--near-black-2);
    }

    .pullout-question {
        padding: 1em;
        border-bottom: 1px solid var(--mid-gray);
        font-size: 0.8em;
        margin: 0 1em;
    }

    .model-choices {
        position: fixed;
        left: 3em;
        top: 3em;
        padding: 3em;
        border: 1px solid var(--mid-gray);
        background-color: white;
    }


    .ineligible {
        text-decoration: line-through;
    }

    .pullout-footer {
        padding: 1em;
        text-align: center;

    }

    .save-button {
        background-color: var(--primary-color);
        color: white;
        border-color: transparent;
        font-size: 1.0em;
    }

    .save-button:hover {
        background-color: white;
        color: var(--primary-color);
        border-color: var(--primary-color);
        font-size: 1.0em;
    }


</style>
