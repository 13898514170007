import { render, staticRenderFns } from "./PrintShopMailConnect.vue?vue&type=template&id=280f151c&scoped=true&"
import script from "./PrintShopMailConnect.vue?vue&type=script&lang=js&"
export * from "./PrintShopMailConnect.vue?vue&type=script&lang=js&"
import style0 from "./PrintShopMailConnect.vue?vue&type=style&index=0&id=280f151c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "280f151c",
  null
  
)

export default component.exports