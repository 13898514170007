<template>

    <div class="pullout-details-menu">



        <div class="menu-header">
            <icon-x class="close-button" @click.native="hide"></icon-x>
            <h3 class="header-label">{{ question.label }}</h3>
            <button class="button text-xs clear-button warning" @click="clearDetails">CLEAR</button>

        </div>
        <component class="pullout-question" v-for="detail in details" v-bind:is="detail.component" :detail="detail" :answers="answers" :key="detail.id" :context="context" @change="onDetailChange"></component>
        <footer class="pullout-footer">
            <button class="button text-sm save-button" @click="hide">SAVE/RETURN</button>
        </footer>



    </div>



</template>

<script>

    import IconX from './IconX.vue';
    import PulloutQuestionNumber from './PulloutQuestionNumber.vue';
    import PulloutQuestionSwitch from './PulloutQuestionSwitch.vue';
    import PulloutQuestionMultiSwitch from './PulloutQuestionMultiSwitch.vue'
    import PulloutQuestionMultiChoice from './PulloutQuestionMultiChoice.vue'
    import PulloutQuestionMultiSwitchList from './PulloutQuestionMultiSwitchList.vue'
    import PulloutQuestionRatedList from './PulloutQuestionRatedList.vue'
    import printconfig from "@/plugins/printconfig";


    export default {
        components: {
            IconX,
            PulloutQuestionNumber,
            PulloutQuestionSwitch,
            PulloutQuestionMultiSwitch,
            PulloutQuestionMultiChoice,
            PulloutQuestionMultiSwitchList,
            PulloutQuestionRatedList
        },
        name: "pullout-details-menu",
        props: {
            question: {
                type: Object,
                required: true
            },
            answers: {
                type: Object,
                required: true
            },

            context: {
                type: Object,
                required: true
            }

        },

        computed: {
            details: function(){
                if(this.question.configurator){
                    return this.question.configurator.detailList;
                }else{
                    return this.question.details;
                }



            },
            detailSummary: function(){
                if(this.question.configurator){
                    return this.question.configurator.summary(this.answers);
                }

                return null;

            }
        },

        methods: {
            hide: function(){
                this.$emit('hide');
            },

            onDetailChange: function(detail){
                var update = {
                    questionId: this.question.id,
                    detail: detail
                }
                this.$store.dispatch('updateDetail',update);
                this.$emit("change");
            },

            clearDetails: function(){

              if(!window.confirm("This will clear your custom configuration for "+ this.question.label+".")){
                return;
              }

              let defaultAnswer = printconfig.defaultAnswer(this.question);

              this.details.forEach((detail) => {
                this.answers[detail.id] = defaultAnswer.details[detail.id];
              })

            }
        }
    }
</script>

<style scoped>

    .pullout-details-menu {
        overflow-y: scroll;
        background-color: var(--near-white-2);
      box-shadow: 0 3px 16px rgba(64,64,64,0.1);
    }

    .menu-header {
        background-color: white;
      box-shadow: 0 3px 16px rgba(64,64,64,0.1);
        height: 3em;
        box-sizing: border-box;
        padding: 0.5em 1em;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .header-label {
        padding-left: 1em;
        padding-right: 1em;
    }

    .close-button {
        fill: var(--mid-gray);
        height: 1em;
        cursor: pointer;

    }

    .close-button:hover {
        fill: var(--near-black-2);
    }

    .pullout-question {
        padding: 1em;
        border-bottom: 1px solid var(--mid-gray);
        font-size: 0.8em;
        margin: 0 1em;
    }

    .model-choices {
        position: fixed;
        left: 3em;
        top: 3em;
        padding: 3em;
        border: 1px solid var(--mid-gray);
        background-color: white;
    }


    .ineligible {
        text-decoration: line-through;
    }

    .pullout-footer {
        padding: 1em;
        text-align: center;

    }

    .save-button {
        background-color: var(--primary-color);
        color: white;
        border-color: transparent;
        font-size: 1.0em;
    }

    .save-button:hover {
        background-color: white;
        color: var(--primary-color);
        border-color: var(--primary-color);
        font-size: 1.0em;
    }





</style>
