<template>
    <div class="product-detail-grid">

        <table class="grid-table">
            <thead>
            <tr>
                <td class="borderless"></td>
                <td class="borderless center text-center" v-for="(product, index) in products" :key="index"><icon-x class="inline-block cursor-pointer w-6 h-6 border-gray-300 bg-white p-1 border rounded-full fill-current hover:bg-red-500 hover:text-white hover:border-red-500" @click.native="removeProduct(product)"></icon-x></td>
                <td class="borderless center"></td>

            </tr>
            <tr>
                <td class="borderless"><button class="button text-sm px-2 py-1 action" @click="addProduct">Add Equipment</button></td>
                <td class="borderless center align-vertical" v-for="(product, index) in products" :key="index"><select class="fill-width" v-model="product.model" @change="onModelChange(product)"><option v-for="(model, modelIndex) in productList" :key="modelIndex" :value="model">{{ model.name }}</option></select></td>
                <td  class="borderless"></td>
            </tr>
            <tr>
                <td class="borderless"></td>
                <td class="center light-shade" v-for="(product, index) in products" :key="index"><input type="number" v-model="product.shifts" @input="onShiftChange" /><span class="small shift-label">Shift(s)</span></td>
                <td class="borderless center"><span class="small">Feature Volume</span></td>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(attribute, attributeIndex) in attributes" :key="attributeIndex">
                <td class="attribute-label right" :data-active="attribute.active">{{ attribute.detail.label }}</td>
                <td class="center" v-for="(product, index) in products" :key="index"><input v-if="(attribute.detail.type != 'map')" type="checkbox" v-model="product.options[attribute.detail.id]" @change="onOptionChange(product, attribute.detail.id)" :disabled="(product.model.excludedBy.indexOf(attribute.detail.id) > -1)"/><select v-if="(attribute.detail.type == 'map')" v-model="product.options[attribute.detail.id]"><option :value="undefined">None</option><option v-for="(option, optionIndex) in productAttributeOptions(product, attribute.detail.options)" :key="optionIndex" :value="option.id" >{{ option.label }}</option></select></td>
                <td class="right light-shade" :data-volume-warning="attributeVolumeStatus(attribute)"> <span>{{ attribute.monthlyVolume | decimal(0)}}</span></td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
                <td class="borderless right small">Model Volume</td>
                <td class="center  light-shade" v-for="(product, index) in products" :key="index"><span>{{ ((product.shifts == '2')?product.model.volumeMax:product.model.volume) | decimal(0) }}</span></td>
                <td class="borderless"></td>


            </tr>
            <tr>
                <td class="borderless right small">Footprint (Sq. Ft.)</td>
                <td class="center  light-shade" v-for="(product, index) in products" :key="index"><span>{{ product.footprint | decimal(0) }}</span></td>
                <td class="borderless"></td>


            </tr>

            </tfoot>
        </table>

    </div>
</template>

<script>
    import IconX from './IconX.vue'


    //import { NumberFormatter } from "../configurators/helpers.js";

    export default {
        name: "product-detail-grid",
        components: {

            IconX,
        },
        props: {
            question: {
                type: Object,
                required: true
            },
            answers: {
                type: Object,
                required: true
            }
        },
        data: function(){
            return {
                attributes: [

                ],

                products: [

                ],

                productList: [],

                recommendation: undefined


            }
        },

        computed: {
            totalMonthlyVolume: function(){

                return this.products.reduce((total, current)=>{
                    return (total + current.model.monthlyVolume);
                }, 0)
            },

            totalPeakVolume: function(){
                return this.products.reduce((total, current)=>{
                    return (total + current.model.peakVolume);
                }, 0)
            },

            detailSummary: function() {
                if(this.question.configurator){
                    //return this.question.configurator.summary(this.$store.getters.answerDetails(this.question.id));
                    return this.question.configurator.recommendation;
                }

                return null;
            },

            requirementsList: function(){
                if(this.detailSummary){
                    //return this.question.configurator.summary(this.$store.getters.answerDetails(this.question.id));
                    return this.detailSummary.requirements.map((req) => { return req.id });
                }
                return []
            },

            targetMonthlyVolume: function(){
                if(this.detailSummary.volume){
                    //let volume = NumberFormatter(this.detailSummary.volume, {format: "0,0"}).raw;
                    return this.detailSummary.volume;
                }else{
                    return 0;
                }

            }
        },

        methods: {
            addProduct: function(product, shifts){
                if(shifts == undefined){
                    shifts = 1;
                }

                if(this.productList.indexOf(product) == -1){
                    product = this.productList[0];
                }

                var optionObj = {};

                this.products.push({
                    model: product,
                    options: optionObj,
                    optionNames: {},
                    shifts: shifts,
                    footprint: 0,
                    name: ""
                })
            },

            attributeVolumeStatus: function(attribute){
                if(attribute.monthlyVolume){
                    if(attribute.monthlyVolume < this.targetMonthlyVolume){
                        return "warning";
                    }else{
                        return "good";
                    }
                }else{

                    //console.log(this.requirementsList, attribute);

                    if(this.requirementsList.indexOf(attribute.detail.id) > -1 ){
                        return "required";
                    }else{
                        return "unused";
                    }


                }
            },

            productAttributeOptions: function(product, attributeOptions){



                var productSpecificOptions = [];


                attributeOptions.forEach((option) => {
                    if(product.model.excludedBy.indexOf(option.id) == -1){
                        productSpecificOptions.push(option);
                    }
                })

                //console.log(product.model.id, productSpecificOptions);

                return productSpecificOptions;
            },

            removeProduct: function(item){
                const index = this.products.indexOf(item);

                if (index !== -1) {
                    this.products.splice(index, 1);
                    this.calculateAllAttributeVolumes();
                }


            },

            onOptionChange: function(product, attributeId){

              console.log(product, attributeId);

                let optionDetails = this.question.configurator.detailMapPlusOptions[attributeId];
                if(optionDetails && optionDetails.excludes){
                    optionDetails.excludes.forEach( (exclusion) => {
                        if(product.options[exclusion]){
                            product.options[exclusion] = false;
                            this.calculateAttributeVolumes(exclusion);
                        }
                    })
                }

                var optionNames = [];
                for(var optionId in product.options){
                    if(product.options[optionId]) {
                        optionNames.push(this.question.configurator.detailMapPlusOptions[optionId].label);
                    }
                }

                product.optionNames = optionNames.join(", ");

                this.calculateAttributeVolumes(attributeId);
                this.calculateProductFootage(product);
            },

            onModelChange: function(product){
                console.log("modelChange");
                product.model.excludedBy.forEach((exclusionId) => {
                    product.options[exclusionId] = false;
                })

                this.calculateAllAttributeVolumes();
                this.calculateProductFootage(product);
            },

            onShiftChange: function(){
                console.log("shiftChange");
                this.calculateAllAttributeVolumes();
            },

            calculateAttributeVolumes: function(attributeId){

                //console.log(attributeId);

                var isActive = false;

                var attributeMonthly = this.products.reduce((total, current) => {
                    //console.log(current.options, current.options[attributeId]);
                    if(current.options[attributeId]) {

                        isActive = true;

                        if (parseInt(current.shifts) == 2) {
                            return (total + current.model.volumeMax)
                        } else if (parseInt(current.shifts) == 1) {
                            return (total + current.model.volume)
                        }
                    }else{
                        return total;
                    }
                    //return (total + current[at]
                }, 0);

                var attribute = this.attributes.find( (obj) => {
                    return obj.detail.id == attributeId;
                })

                //console.log(attribute);

                attribute.monthlyVolume = attributeMonthly;
                attribute.active = isActive;

            },

            calculateAllAttributeVolumes: function(){
                //console.log("all volumes");


                this.attributes.forEach( (attribute) => {
                    this.calculateAttributeVolumes(attribute.detail.id);
                })
            },

            calculateProductFootage: function(product){

                var footage = product.model.totalFootage;
                for(var option in product.options){
                    if(product.options[option]){
                        if(product.model.addedFootage[option]){
                            footage += product.model.addedFootage[option];
                        }
                    }
                }

                product.footprint = footage;

            }
        },

        mounted: function() {
            console.log(this.question, this.detailSummary, this.answers);
            this.productList = this.question.configurator.equipmentList();

            if(this.question.details){
                this.question.details.forEach((detail) => {



                    if(detail.component == "PulloutQuestionMultiChoice"){

                        if(detail.options){
                            detail.options.forEach((option) => {
                                this.attributes.push({
                                    detail: option,
                                    monthtlyVolume: 0,
                                    peakVolume: 0
                                })
                            })
                        }


                    }else if(detail.type == "map"){

                        if(detail.options){
                            detail.options.forEach((option) => {
                                this.attributes.push({
                                    detail: option,
                                    monthtlyVolume: 0,
                                    peakVolume: 0,
                                    group: detail.id
                                })
                            })
                        }

                    }
                    else if(detail.type == "boolean" ) {


                        var attribute = {
                            detail: detail,
                            monthlyVolume: 0,
                            peakVolume: 0,
                            active: false
                        }


                        this.attributes.push(attribute);
                    }




                })
            }


            if(this.answers.customConfiguration){

                console.log("Using custom configuration");

                this.products = this.answers.customConfiguration.models;



            }else if(this.question.configurator.recommendation){

                console.log("Using standard configuration");
                this.products = this.question.configurator.recommendation.models;
                this.answers.customConfiguration = {
                  models: this.products
                }
                // this.question.configurator.customConfiguration = {
                //     models: this.products
                // }
            }

            /*

            this.recommendation = this.question.configurator.summary(this.answers);

            if(this.recommendation.numOfModels){

                var product = this.productList.find( (item) => {
                    return item.id == this.recommendation.model.id;
                })

                if(product){
                    for(var i=0; i<this.recommendation.numOfModels; i++){

                        this.addProduct(product, this.recommendation.numOfShifts);
                    }
                }

            }

            this.products.forEach((product) => {

                //check the requirements from the pullout menu
                this.detailSummary.requirements.forEach((option) => {

                    //go through each requirement and see if check that option in each product, but do it by cross referencing the attributes table

                    this.attributes.forEach((obj) => {

                        //does attribute item match a requiremnt item?
                        if (obj.detail.id == option.id) {
                            //if so set the product option to true for that attribute id
                            product.options[obj.detail.id] = true;
                        }

                    })

                })

                this.calculateProductFootage(product);
            })

            */

            this.calculateAllAttributeVolumes();


        }
    }
</script>

<style scoped>



    .product-detail-grid {

    }

    .grid-table {

        border-collapse: collapse;
        width: 100%;
    }

    .grid-table select.fill-width {
        width: 100%;
    }

    .grid-table td {
        border: 1px solid black;
        padding: 0.5em;
        background-color: white;
        border: 1px solid var(--mid-gray);

    }

    .grid-table tbody tr:hover td {
        background-color: var(--primary-color);
        color: white;
        cursor: pointer;
    }

    .grid-table td.borderless {
        border: none;
        background-color: transparent;
    }

    .grid-table td.dark {
        background-color: var(--near-black-1);
        color: var(--near-white-1);
    }

    .grid-table td.center {
        text-align: center;
    }

    .grid-table td.right {
        text-align: right;
    }

    .grid-table td.align-vertical {

    }

    .small {
        font-size: 0.8em;
    }

    .remove-x {
        width: 1em;
        height: 1em;
        margin: 0.5em;
        stroke: var(--near-white-1);
        border: 1px solid var(--mid-gray);
        border-radius: 2em;
        padding: 0.5em;
        background-color: white;
    }

    .shift-label {
        margin-left: 0.5em;
    }

    .grid-table td.attribute-label {
        background-color: #f0f0f0;
        color: var(--dark-gray);
        text-align: right;
        padding-right: 2em;

    }

    .grid-table td.attribute-label[data-active="true"] {
        background-color: var(--primary-color);
        color: white;
        text-align: right;
        padding-right: 2em;

    }

    .grid-table td.light-shade {
        background-color: #f0f0f0;


    }

    .grid-table td .number-value {


    }

    .grid-table td[data-volume-warning = "warning"]{
        background-color: yellow;
    }

    .grid-table td[data-volume-warning = "warning"] .number-value:hover:before {
        content: "Volume does not meet monthly requirement.";
        display: block;
        position: absolute;
        margin-top: -2em;
        right: 0;
        padding: 1em;
        background-color: white;
        border: 1px solid var(--primary-color);
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        box-shadow: 0 0 10px black;
        color: var(--primary-color);

    }

    .grid-table td[data-volume-warning = "required"]{
        background-color: yellow;
    }

    .grid-table td[data-volume-warning = "required"] .number-value:after {
        content: "!";
        border: 1px solid var(--primary-color);
        padding: 0.25em 0.75em;
        border-radius: 2em;

    }

    .grid-table td[data-volume-warning = "required"] .number-value:hover:before {
        content: "Required option!";
        display: block;
        position: absolute;
        margin-top: -2em;
        padding: 1em;
        background-color: white;
        border: 1px solid var(--primary-color);
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        box-shadow: 0 0 10px black;
        color: var(--primary-color);

    }

    .grid-table td[data-volume-warning = "good"]{
        background-color: lightgreen;
    }




</style>
