<template>
  <div class="efifiery-job-flow">
      <h4>EFI Fiery Job Flow</h4>
    <hr>
    <p>Fiery® JobFlow™, part of the Fiery Workflow Suite is an easy-to-use, cost-effective browser-based solution that automates the steps in a print processing workflow. Users can streamline the production of print jobs, such as brochures, business cards, direct mail, catalogs, and more. Once jobs go through the JobFlow workflows, they are sent to print through a Fiery server or Fiery Central print production solution. The configurable Fiery JobFlow workflows integrate powerful tools for PDF conversion, preflight checks, correction and editing of PDF files, image enhancement, document imposition, job ticketing, notifications and approval.</p>
    <p class="bold-label">Highlights:</p>
    <ul>
      <li>Automates job processing from submission to output, to expedite production, increase capacity and reduce inefficient work</li>
      <li>Drives productivity by eliminating bottlenecks and automating repetitive tasks</li>
      <li>Provides email notifications on job status</li>
      <li>Proactively identifies file issues and make last-minute changes</li>
      <li>Leverages your current resources for maximum efficiency with minimum manual work</li>
      <li>Converts all incoming jobs into PDF files</li>
      <li>Automatically corrects errors in media size, page orientation in addition to adjusting bleed and crop-box properties</li>

    </ul>
    <p class="bold-label">Summary:</p>
    <ul>
      <li>Browser-based prepress solution that easily automates job workflows</li>
      <li>Streamlines the production of print jobs, while increasing efficiency</li>
      <li>Configures job-specific workflows</li>
    </ul>

  </div>
</template>

<script>
export default {
  name: "efi-fiery-job-flow",
  props: {},
  data: function () {
    return {}
  },
  methods: {},
  computed: {}
}
</script>

<style scoped>


.eficross-media {

}

</style>