


<template>
<div class="">
  <h4>Digital Maturity Assessment</h4>
  <hr>
  <p>A Digital Maturity Assessment is a comprehensive evaluation designed to measure an organization's digital communications maturity across various dimensions. It helps businesses understand where they stand in their digital transformation journey, identifies strengths and weaknesses, and provides actionable insights for improvement. This assessment serves as a foundational tool for organizations aiming to accelerate their digital transformation and stay competitive in the evolving digital landscape.</p>
  <p class="bold-label">Methodology:</p>
  <ul>
  <li><b>Inspiration Session:</b> A one hour virtual session with selected LOB unit owners and those involved in the touchpoints related to billing and paperless conversion.</li>
  <li><b>Digital Maturity Survey:</b> A questionnaire for LOB and relevant stakeholders to complete that precedes the data gathering workshop. The questionnaire should take 30-60 minutes to complete.</li>
  <li><b>Data Gathering Workshop:</b> A two hour virtual workshop where we have each LOB review the current touchpoints and journeys for customers to go paperless, interact and self-serve as well as dig deeper into areas assessed in the survey.</li>
  <li><b>Analysis:</b> Post all data points being gathered, a Digital Maturity Score is calculated</li>
  </ul>
  <p class="bold-label">Findings and Recommendations:</p>
  <ul>
  <li><b>Executive Report:</b> Comprehensive report with Maturity Score per LOB and how it compares across the industry, observations of current state and recommendations for next steps towards desired future state.</li>
  <li><b>Executive Readout:</b> 90-120 minute readout session for leadership to gain clarity into the report and recommendations.</li>
  </ul>

</div>
</template>

<script>
export default {
    name: "digital-maturity",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


</style>
