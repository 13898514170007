export default {
    requirements: {
        low: {
            id: 'low',
            name: 'Low',
            totalFootage: 32,
            staffing: 0.25,
            staffingCode: "sm",
            equipment: [],
            software: ["Web-to-print system"],
            otherItems: ["Workstation"]
        },

        medium: {
            id: 'medium',
            name: 'Medium',
            totalFootage: 64,
            staffing:0.5,
            staffingCode: "sm",
            equipment: [],
            software: ["W2P & MIS System"],
            otherItems: ["Workstation"]
        },

        high: {
            id: 'high',
            name: 'High',
            totalFootage:64,
            staffing: 0.75,
            staffingCode: "sm",
            equipment: [],
            software: ["W2P & MIS w/equipment integration"],
            otherItems: ["Data collection and workflow automation"]
        },
    },

    ratingDetails: [
        {
            id: 'level',
            type: 'enum',
            required: false,
            component: 'PulloutQuestionMultiSwitchList',
            default: 0,
            label: "Level of Need:",
            options: [
                {
                    id: "none",
                    label: "None",
                    value: 0
                },

                {
                    id: "low",
                    label: "Low",
                    description: "up to 630 jobs per month",
                    value: 1
                },
                {
                    id: "medium",
                    label: "Medium",
                    description: "up to 1,470 jobs per month",
                    value: 2
                },
                {
                    id: "high",
                    label: "High",
                    description: "2,300 plus jobs per month",
                    value: 3
                }
            ]
        }

    ]
}
