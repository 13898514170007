import { render, staticRenderFns } from "./ConfigQuestionSelect.vue?vue&type=template&id=c687220e&scoped=true&"
import script from "./ConfigQuestionSelect.vue?vue&type=script&lang=js&"
export * from "./ConfigQuestionSelect.vue?vue&type=script&lang=js&"
import style0 from "./ConfigQuestionSelect.vue?vue&type=style&index=0&id=c687220e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c687220e",
  null
  
)

export default component.exports