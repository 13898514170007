


<template>
<div class="print-net">
  <h4>PrintNet</h4>
  <hr>
  <p>PrintNet Solutions is a state-of-the-art, 100% cloud-based Web-to-Print submission software which completely automates the electronic print job submission process. PrintNet also centralizes incoming job orders by providing an organized and efficient dashboard, making it easy for the Print Center staff to manage their incoming print orders.</p>
  <p>PrintNet provides an unlimited number of user-friendly storefronts, making it simple for anyone to pull files into the system, add instructions on how they want their job run, and submit their print order quickly and efficiently – for a timely and perfectly run print order every time.</p>

  <p class="bold-label">Customer Benefits:</p>
  <ul>
    <li>Unlimited user-friendly web-to-print submission storefronts provided</li>
    <li>Organized and efficient dashboard provided to Print Center manager and staff to help them easily manage the Print Center's incoming print orders</li>
    <li>PrintNet coverts 340 file types to a ready to run PDF eliminating the need for conversion software</li>
    <li>PrintNet is 100% cloud-based, so no installation on servers behind the firewall is required making implementation and training quick and easy</li>
    <li>Complete static forms manager with connectivity to share point available</li>
    <li>Ability to send documents via smartboard, tablets and cell phones providing unlimited training and education opportunities</li>
  </ul>
  <p>PrintNet Solutions has a flexible pricing model, making it an affordable option with a low cost of entry – without sacrificing power and efficiency. PrintNet is vertically focused and has integrated Web-to-print system, forms and curriculum management, smart board technology and cloud-based printing functions into one SaaS platform. In addition, PrintNet has the ability to be very nimble in the software development approach to the rapidly changing dynamics and requirements of the printing industry.</p>
  <p class="bold-label">Key Features:</p>
  <ul>
    <li>Provides Print Center with a user-friendly dashboard to easily receive print orders - eliminating emails, FTP, walk-ins, thumb drives or other typical job submissions</li>
    <li>Several connection points available such as Ricoh TotalFlow® Production Manager, SSO, LDAP, Fiery, SciQuest, Heartland and Authorized.Net</li>
    <li>Forces the print job submitter to answer all of the simple questions such as color or black and white, single-sides or double-sided, collated, bindery, etc.</li>
    <li>Automatically converts 340 file types to a ready-to-run ripped PDF in its process of digitally sending print orders to the Print Center</li>
    <li>A layered pricing and reporting system that allows Print Center staff to fully automate invoicing, change backs and reporting</li>
  </ul>
</div>
</template>

<script>
export default {
    name: "print-net",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


.print-net {

}

</style>