


<template>
<div class="">
  <h4>Ricoh Return Mail Services</h4>
  <hr>
  <p>Ricoh’s Return Mail Services is a fully managed offering — built around a robust and repeatable process - that facilitates the collection, digitization, and remediation of undeliverable mail. This service provides a cloud-hosted portal that routes Return to Sender (RTS) mail, based on specific characteristics, so that users can view and remediate only the RTS mail that impacts their specific job duties, while providing organizational oversight, metrics and analytics to measure the company impact (savings, resources, space etc.) and track regulation compliance. Return Mail Services are available through an all-inclusive fee-(transactional fee based on the number of mail pieces) and includes reporting and analytics, technology solutions, scanning devices, and Ricoh's Consulting services.</p>

</div>
</template>

<script>
export default {
    name: "ricoh-return-mail",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


</style>
