export default {
    finishingDetails: [
        {
            id: 'offlineCutterGuillotine',
            type: 'enum',
            required: false,
            component: 'PulloutQuestionRatedList',
            default: 0,
            label: "Cutter Guillotine",
            options: [
                {
                    id: "none",
                    label: "None",
                    value: 0
                },

                {
                    id: "low",
                    label: "Low",
                    summaryLabel:"Low Volume",
                    description: "up to 200 simple jobs per month",
                    value: 1,
                    totalFootage: 64,
                    staffing: 0.15,
                    staffingCode: "ft1",
                    equipment: ['Duplo Pro 490 Max 19"'],
                    software: [],
                    otherItems: []
                },
                {
                    id: "medium",
                    label: "Medium",
                    summaryLabel:"Medium Volume",
                    description: "up to 400 simple jobs per month",
                    value: 2,
                    totalFootage: 64,
                    staffing: 0.25,
                    staffingCode: "ft1",
                    equipment: ['Duplo Pro 660 Max 26"'],
                    software: [],
                    otherItems: []
                },
                {
                    id: "high",
                    label: "High",
                    summaryLabel:"High Volume",
                    description: "up to 800 simple jobs per month",
                    value: 3,
                    totalFootage: 120,
                    staffing: 0.5,
                    staffingCode: "ft1",
                    equipment: ['Duplo Pro 800 Max 31"'],
                    software: [],
                    otherItems: []
                }
            ]
        },

        {
            id: 'offlineFolding',
            type: 'enum',
            required: false,
            component: 'PulloutQuestionRatedList',
            default: 0,
            label: "Folding",
            options: [
                {
                    id: "none",
                    label: "None",
                    value: 0
                },

                {
                    id: "low",
                    label: "Low",
                    summaryLabel:"Low Volume",
                    description: "100 jobs or 40,000 folds per month ",
                    value: 1,
                    totalFootage: 50,
                    staffing: 0.15,
                    staffingCode: "ft1",
                    equipment: ['Duplo DF-777'],
                    software: [],
                    otherItems: []
                },
                {
                    id: "medium",
                    label: "Medium",
                    summaryLabel:"Medium Volume",
                    description: "180 jobs or 80,000 folds per month",
                    value: 2,
                    totalFootage: 50,
                    staffing: 0.25,
                    staffingCode: "ft1",
                    equipment: ['Duplo DF-980'],
                    software: [],
                    otherItems: []
                },
                {
                    id: "high",
                    label: "High",
                    summaryLabel:"High Volume",
                    description: "300 jobs or 240,000 folds per month",
                    value: 3,
                    totalFootage: 50,
                    staffing: 0.5,
                    staffingCode: "ft1",
                    equipment: ['Duplo DF-1200'],
                    software: [],
                    otherItems: []
                }
            ]
        },

        {
            id: 'offlineScoreFoldPerf',
            type: 'enum',
            required: false,
            component: 'PulloutQuestionRatedList',
            default: 0,
            label: "Score / Fold / Perf",
            options: [
                {
                    id: "none",
                    label: "None",
                    value: 0
                },

                {
                    id: "low",
                    label: "Low",
                    summaryLabel:"Low Volume",
                    description: "42 jobs or 52,500 folds per month ",
                    value: 1,
                    totalFootage: 50,
                    staffing: 0.15,
                    staffingCode: "ft1",
                    equipment: ['Morgana DigiFold Pro'],
                    software: [],
                    otherItems: []
                },
                {
                    id: "medium",
                    label: "Medium",
                    summaryLabel:"Medium Volume",
                    description: "63 jobs or 118,000 folds per month",
                    value: 2,
                    totalFootage: 50,
                    staffing: 0.25,
                    staffingCode: "ft1",
                    equipment: ['Morgana DigiFold Pro'],
                    software: [],
                    otherItems: []
                },
                {
                    id: "high",
                    label: "High",
                    summaryLabel:"High Volume",
                    description: "126 jobs or 236,000 folds per month ",
                    value: 3,
                    totalFootage: 50,
                    staffing: 0.5,
                    staffingCode: "ft1",
                    equipment: ['Duplo CP375'],
                    software: [],
                    otherItems: []
                }
            ]
        },

        {
            id: 'offlineBusinessCardTrimmers',
            type: 'enum',
            required: false,
            component: 'PulloutQuestionRatedList',
            default: 0,
            label: "Business Card Trimmers",
            options: [
                {
                    id: "none",
                    label: "None",
                    value: 0
                },

                {
                    id: "low",
                    label: "Low",
                    summaryLabel:"Low Volume",
                    description: "40 jobs of 500 bus cards 20,000 total per month",
                    value: 1,
                    totalFootage: 50,
                    staffing: 0.05,
                    staffingCode: "ft1",
                    equipment: ['Duplo CC-228'],
                    software: [],
                    otherItems: []
                },
                {
                    id: "medium",
                    label: "Medium",
                    summaryLabel:"Medium Volume",
                    description: "80 jobs of 500 bus cards 40,000 total per month",
                    value: 2,
                    totalFootage: 50,
                    staffing: 0.1,
                    staffingCode: "ft1",
                    equipment: ['Duplo CC-330'],
                    software: [],
                    otherItems: []
                },
                {
                    id: "high",
                    label: "High",
                    summaryLabel:"High Volume",
                    description: "120 jobs of 500 bus cards 60,000 total per month",
                    value: 3,
                    totalFootage: 65,
                    staffing: 0.15,
                    staffingCode: "ft1",
                    equipment: ['Duplo DC-646'],
                    software: [],
                    otherItems: []
                }
            ]
        },

        {
            id: 'offlineTrimScoreSlit',
            type: 'enum',
            required: false,
            component: 'PulloutQuestionRatedList',
            default: 0,
            label: "Trim / Score / Slit",
            options: [
                {
                    id: "none",
                    label: "None",
                    value: 0
                },

                {
                    id: "low",
                    label: "Low",
                    summaryLabel:"Low Volume",
                    description: "60 jobs @ 30,000 bus cards or 40 jobs @ 14,400 postcards per month",
                    value: 1,
                    totalFootage: 50,
                    staffing: 0.08,
                    staffingCode: "ft1",
                    equipment: ['Duplo DC-616'],
                    software: [],
                    otherItems: []
                },
                {
                    id: "medium",
                    label: "Medium",
                    summaryLabel:"Medium Volume",
                    description: "120 jobs @ 60,000 bus cards or 80 jobs @ 28,800 postcards per month",
                    value: 2,
                    totalFootage: 50,
                    staffing: 0.15,
                    staffingCode: "ft1",
                    equipment: ['Duplo DC-646'],
                    software: [],
                    otherItems: []
                },
                {
                    id: "high",
                    label: "High",
                    summaryLabel:"High Volume",
                    description: "240 jobs @ 120,000 bus cards or 160 jobs @ 57,600 postcards per month",
                    value: 3,
                    totalFootage: 50,
                    staffing: 0.3,
                    staffingCode: "ft1",
                    equipment: ['Duplo DC-646'],
                    software: [],
                    otherItems: []
                }
            ]
        },

        {
            id: 'offlineCollatorStapler',
            type: 'enum',
            required: false,
            component: 'PulloutQuestionRatedList',
            default: 0,
            label: "Collator w/ Stapler",
            options: [
                {
                    id: "none",
                    label: "None",
                    value: 0
                },

                {
                    id: "low",
                    label: "Low",
                    summaryLabel:"Low Volume",
                    description: "20 job up to 36,000 sets per month",
                    value: 1,
                    totalFootage: 50,
                    staffing: 0.25,
                    staffingCode: "ft1",
                    equipment: [],
                    software: [],
                    otherItems: []
                },
                {
                    id: "medium",
                    label: "Medium",
                    summaryLabel:"Medium Volume",
                    description: "20 jobs up to 100,000 sets per month",
                    value: 2,
                    totalFootage: 120,
                    staffing: 0.5,
                    staffingCode: "ft1",
                    equipment: [],
                    software: [],
                    otherItems: []
                },
                {
                    id: "high",
                    label: "High",
                    summaryLabel:"High Volume",
                    description: "20 jobs up to 250,000 sets per month",
                    value: 3,
                    totalFootage: 120,
                    staffing: 1,
                    staffingCode: "ft1",
                    equipment: [],
                    software: [],
                    otherItems: []
                }
            ]
        },

        {
            id: 'offlineSaddleStitching',
            type: 'enum',
            required: false,
            component: 'PulloutQuestionRatedList',
            default: 0,
            label: "Saddle Stitching",
            options: [
                {
                    id: "none",
                    label: "None",
                    value: 0
                },

                {
                    id: "low",
                    label: "Low",
                    summaryLabel:"Low Volume",
                    description: "20 jobs up to 36,000 books per month",
                    value: 1,
                    totalFootage: 50,
                    staffing: 0.25,
                    staffingCode: "ft1",
                    equipment: ['Duplo DBM-150'],
                    software: [],
                    otherItems: []
                },
                {
                    id: "medium",
                    label: "Medium",
                    summaryLabel:"Medium Volume",
                    description: "20 jobs up to 100k books per month",
                    value: 2,
                    totalFootage: 120,
                    staffing: 0.5,
                    staffingCode: "ft1",
                    equipment: ['Duplo DBM-350'],
                    software: [],
                    otherItems: []
                },
                {
                    id: "high",
                    label: "High",
                    summaryLabel:"High Volume",
                    description: "20 jobs up to 250,000 books per month",
                    value: 3,
                    totalFootage: 120,
                    staffing: 1,
                    staffingCode: "ft1",
                    equipment: ['Duplo DBM-600'],
                    software: [],
                    otherItems: []
                }
            ]
        },

        {
            id: 'offlinePerfectBind',
            type: 'enum',
            required: false,
            component: 'PulloutQuestionRatedList',
            default: 0,
            label: "Perfect Bind",
            options: [
                {
                    id: "none",
                    label: "None",
                    value: 0
                },

                {
                    id: "low",
                    label: "Low",
                    summaryLabel:"Low Volume",
                    description: "20 jobs up to 2,000 books per month",
                    value: 1,
                    totalFootage: 50,
                    staffing: 0.25,
                    staffingCode: "ft1",
                    equipment: ['Duplo DB-280'],
                    software: [],
                    otherItems: []
                },
                {
                    id: "medium",
                    label: "Medium",
                    summaryLabel:"Medium Volume",
                    description: "10 jobs up to 4,200 books per month",
                    value: 2,
                    totalFootage: 64,
                    staffing: 0.5,
                    staffingCode: "ft1",
                    equipment: ['Duplo KB-4000 PUR'],
                    software: [],
                    otherItems: []
                },
                {
                    id: "high",
                    label: "High",
                    summaryLabel:"High Volume",
                    description: "10 jobs up to 27,000 books per month",
                    value: 3,
                    totalFootage: 120,
                    staffing: 1,
                    staffingCode: "ft1",
                    equipment: ['CP Bourg BB3002/BBL/CMT3300'],
                    software: [],
                    otherItems: []
                }
            ]
        },

        {
            id: 'offlineCoilBindPunch',
            type: 'enum',
            required: false,
            component: 'PulloutQuestionRatedList',
            default: 0,
            label: "Coil Bind w/ Punch",
            options: [
                {
                    id: "none",
                    label: "None",
                    value: 0
                },

                {
                    id: "low",
                    label: "Low",
                    summaryLabel:"Low Volume",
                    description: "up to 200 books per month",
                    value: 1,
                    totalFootage: 50,
                    staffing: 0.05,
                    staffingCode: "ft1",
                    equipment: [],
                    software: [],
                    otherItems: []
                },
                {
                    id: "medium",
                    label: "Medium",
                    summaryLabel:"Medium Volume",
                    description: "up to 400 books per month",
                    value: 2,
                    totalFootage: 100,
                    staffing: 0.1,
                    staffingCode: "ft1",
                    equipment: [],
                    software: [],
                    otherItems: []
                },
                {
                    id: "high",
                    label: "High",
                    summaryLabel:"High Volume",
                    description: "up to 600 books per month",
                    value: 3,
                    totalFootage: 150,
                    staffing: 0.15,
                    staffingCode: "ft1",
                    equipment: [],
                    software: [],
                    otherItems: []
                }
            ]
        },

        {
            id: 'offlinePadding',
            type: 'enum',
            required: false,
            component: 'PulloutQuestionRatedList',
            default: 0,
            label: "Padding",
            options: [
                {
                    id: "none",
                    label: "None",
                    value: 0
                },

                {
                    id: "low",
                    label: "Low",
                    summaryLabel:"Low Volume",
                    description: "up to 200 pads per month",
                    value: 1,
                    totalFootage: 25,
                    staffing: 0.02,
                    staffingCode: "ft1",
                    equipment: [],
                    software: [],
                    otherItems: []
                },
                {
                    id: "medium",
                    label: "Medium",
                    summaryLabel:"Medium Volume",
                    description: "up to 400 pads per month",
                    value: 2,
                    totalFootage: 25,
                    staffing: 0.05,
                    staffingCode: "ft1",
                    equipment: [],
                    software: [],
                    otherItems: []
                },
                {
                    id: "high",
                    label: "High",
                    summaryLabel:"High Volume",
                    description: "up to 1,000 pads per Month",
                    value: 3,
                    totalFootage: 25,
                    staffing: 0.07,
                    staffingCode: "ft1",
                    equipment: [],
                    software: [],
                    otherItems: []
                }
            ]
        },

        {
            id: 'offlineRoundCorner',
            type: 'enum',
            required: false,
            component: 'PulloutQuestionRatedList',
            default: 0,
            label: "Round Corner",
            options: [
                {
                    id: "none",
                    label: "None",
                    value: 0
                },

                {
                    id: "low",
                    label: "Low",
                    summaryLabel:"Low Volume",
                    description: "up to 8,000 sheets  per month",
                    value: 1,
                    totalFootage: 50,
                    staffing: 0.03,
                    staffingCode: "ft1",
                    equipment: [],
                    software: [],
                    otherItems: []
                },
                {
                    id: "medium",
                    label: "Medium",
                    summaryLabel:"Medium Volume",
                    description: "up to 18,000 sheets per month",
                    value: 2,
                    totalFootage: 50,
                    staffing: 0.05,
                    staffingCode: "ft1",
                    equipment: [],
                    software: [],
                    otherItems: []
                },
                {
                    id: "high",
                    label: "High",
                    summaryLabel:"High Volume",
                    description: "up to 36,000 sheets per month",
                    value: 3,
                    totalFootage: 50,
                    staffing: 0.08,
                    staffingCode: "ft1",
                    equipment: [],
                    software: [],
                    otherItems: []
                }
            ]
        },

        {
            id: 'offlineHoleDrill',
            type: 'enum',
            required: false,
            component: 'PulloutQuestionRatedList',
            default: 0,
            label: "Hole Drill",
            options: [
                {
                    id: "none",
                    label: "None",
                    value: 0
                },

                {
                    id: "low",
                    label: "Low",
                    summaryLabel:"Low Volume",
                    description: "up to 16,000 sheets  per month",
                    value: 1,
                    totalFootage: 50,
                    staffing: 0.03,
                    staffingCode: "ft1",
                    equipment: [],
                    software: [],
                    otherItems: []
                },
                {
                    id: "medium",
                    label: "Medium",
                    summaryLabel:"Medium Volume",
                    description: "up to 36,000 sheets  per month",
                    value: 2,
                    totalFootage: 50,
                    staffing: 0.05,
                    staffingCode: "ft1",
                    equipment: [],
                    software: [],
                    otherItems: []
                },
                {
                    id: "high",
                    label: "High",
                    summaryLabel:"High Volume",
                    description: "up to 72,000 sheets  per month",
                    value: 3,
                    totalFootage: 50,
                    staffing: 0.08,
                    staffingCode: "ft1",
                    equipment: [],
                    software: [],
                    otherItems: []
                }
            ]
        },

        {
            id: 'offlineShrinkWrap',
            type: 'enum',
            required: false,
            component: 'PulloutQuestionRatedList',
            default: 0,
            label: "Shrink Wrap",
            options: [
                {
                    id: "none",
                    label: "None",
                    value: 0
                },

                {
                    id: "low",
                    label: "Low",
                    summaryLabel:"Low Volume",
                    description: "360 packages or 40 jobs per month",
                    value: 1,
                    totalFootage: 50,
                    staffing: 0.03,
                    staffingCode: "ft1",
                    equipment: [],
                    software: [],
                    otherItems: []
                },
                {
                    id: "medium",
                    label: "Medium",
                    summaryLabel:"Medium Volume",
                    description: "720 packages or 80 jobs per month",
                    value: 2,
                    totalFootage: 50,
                    staffing: 0.08,
                    staffingCode: "ft1",
                    equipment: [],
                    software: [],
                    otherItems: []
                },
                {
                    id: "high",
                    label: "High",
                    summaryLabel:"High Volume",
                    description: "1,680 packages or 120 jobs per month",
                    value: 3,
                    totalFootage: 100,
                    staffing: 0.15,
                    staffingCode: "ft1",
                    equipment: [],
                    software: [],
                    otherItems: []
                }
            ]
        },

        {
            id: 'offlineDieCut',
            type: 'enum',
            required: false,
            component: 'PulloutQuestionRatedList',
            default: 0,
            label: "Die Cut",
            options: [
                {
                    id: "none",
                    label: "None",
                    value: 0
                },

                {
                    id: "low",
                    label: "Low",
                    summaryLabel:"Low Volume",
                    description: "up to 40,000 12x18 sheets per month",
                    value: 1,
                    totalFootage: 104,
                    staffing: 0.15,
                    staffingCode: "ft1",
                    equipment: ['Duplo UD-300'],
                    software: [],
                    otherItems: []
                },
                {
                    id: "medium",
                    label: "Medium",
                    summaryLabel:"Medium Volume",
                    description: "up to 70,000 12x18 sheets per month",
                    value: 2,
                    totalFootage: 104,
                    staffing: 0.25,
                    staffingCode: "ft1",
                    equipment: ['Duplo UD-300'],
                    software: [],
                    otherItems: []
                },
                {
                    id: "high",
                    label: "High",
                    summaryLabel:"High Volume",
                    description: "up to 106,000 12x18 sheets per month",
                    value: 3,
                    totalFootage: 104,
                    staffing: 0.35,
                    staffingCode: "ft1",
                    equipment: ['Duplo UD-300'],
                    software: [],
                    otherItems: []
                }
            ]
        },

        {
            id: 'offlineUVCoating',
            type: 'enum',
            required: false,
            component: 'PulloutQuestionRatedList',
            default: 0,
            label: "UV Coating",
            options: [
                {
                    id: "none",
                    label: "None",
                    value: 0
                },

                {
                    id: "low",
                    label: "Low",
                    summaryLabel:"Low Volume",
                    description: "20 job, up to 6,800 sheets per month (simplex)",
                    value: 1,
                    totalFootage: 55,
                    staffing: 0.15,
                    staffingCode: "ft1",
                    equipment: ['Duplo Ultra 100A 13"'],
                    software: [],
                    otherItems: []
                },
                {
                    id: "medium",
                    label: "Medium",
                    summaryLabel:"Medium Volume",
                    description: "60 jobs, up to 34,500 sheets per month (simplex)",
                    value: 2,
                    totalFootage: 55,
                    staffing: 0.25,
                    staffingCode: "ft1",
                    equipment: ['Duplo Ultra 200A 20-28"'],
                    software: [],
                    otherItems: []
                },
                {
                    id: "high",
                    label: "High",
                    summaryLabel:"High Volume",
                    description: "120 jobs, up to 71,760  sheets per month (simplex)",
                    value: 3,
                    totalFootage: 55,
                    staffing: 0.35,
                    staffingCode: "ft1",
                    equipment: ['Duplo Ultra 300A 20-28"'],
                    software: [],
                    otherItems: []
                }
            ]
        },

        {
            id: 'offlineLaminating',
            type: 'enum',
            required: false,
            component: 'PulloutQuestionRatedList',
            default: 0,
            label: "Laminating",
            options: [
                {
                    id: "none",
                    label: "None",
                    value: 0
                },

                {
                    id: "low",
                    label: "Low",
                    summaryLabel:"Low Volume",
                    description: "20 jobs, 840 8.5x11 duplex per month",
                    value: 1,
                    totalFootage: 50,
                    staffing: 0.05,
                    staffingCode: "ft1",
                    equipment: ['Drylam ALM3222'],
                    software: [],
                    otherItems: []
                },
                {
                    id: "medium",
                    label: "Medium",
                    summaryLabel:"Medium Volume",
                    description: "40 jobs, 1680 8.5x11 duplex per month",
                    value: 2,
                    totalFootage: 50,
                    staffing: 0.1,
                    staffingCode: "ft1",
                    equipment: ['Drylam ALM3222'],
                    software: [],
                    otherItems: []
                },
                {
                    id: "high",
                    label: "High",
                    summaryLabel:"High Volume",
                    description: "60 jobs, 2520 8.5x11 duplex per month",
                    value: 3,
                    totalFootage: 50,
                    staffing: 0.15,
                    staffingCode: "ft1",
                    equipment: ['Drylam ALM3222'],
                    software: [],
                    otherItems: []
                }
            ]
        },

        {
            id: 'offlineMailInsertion',
            type: 'enum',
            required: false,
            component: 'PulloutQuestionRatedList',
            default: 0,
            label: "Mail Insertion w/RPD software",
            options: [
                {
                    id: "none",
                    label: "None",
                    value: 0
                },

                {
                    id: "low",
                    label: "Low",
                    summaryLabel:"Low Volume",
                    description: "40 jobs, up to 63,000 env per month",
                    value: 1,
                    totalFootage: 144,
                    staffing: 0.4,
                    staffingCode: "ft2",
                    equipment: ['Neopost DS-90i'],
                    software: [],
                    otherItems: []
                },
                {
                    id: "medium",
                    label: "Medium",
                    summaryLabel:"Medium Volume",
                    description: "60 jobs, up to 570k env per month",
                    value: 2,
                    totalFootage: 300,
                    staffing: 1,
                    staffingCode: "ft2",
                    equipment: ['Neopost GS-1200'],
                    software: ['Ricoh Process Director RPD'],
                    otherItems: []
                },
                {
                    id: "high",
                    label: "High",
                    summaryLabel:"High Volume",
                    description: "60 job, up to 2.47M env per month",
                    value: 3,
                    totalFootage: 900,
                    staffing: 2,
                    staffingCode: "ft2",
                    equipment: ['Pitney Bowes 26'],
                    software: ['Ricoh Process Director RPD'],
                    otherItems: []
                }
            ]
        },

        {
            id: 'offlineHandFinishing',
            type: 'enum',
            required: false,
            component: 'PulloutQuestionRatedList',
            default: 0,
            label: "Hand Finishing",
            options: [
                {
                    id: "none",
                    label: "None",
                    value: 0
                },

                {
                    id: "low",
                    label: "Low",
                    summaryLabel:"Low Volume",
                    description: "20 hrs per month",
                    value: 1,
                    totalFootage: 100,
                    staffing: 0.125,
                    staffingCode: "ft1",
                    equipment: [],
                    software: [],
                    otherItems: []
                },
                {
                    id: "medium",
                    label: "Medium",
                    summaryLabel:"Medium Volume",
                    description: "40 hrs per month",
                    value: 2,
                    totalFootage: 200,
                    staffing: 0.25,
                    staffingCode: "ft1",
                    equipment: [],
                    software: [],
                    otherItems: []
                },
                {
                    id: "high",
                    label: "High",
                    summaryLabel:"High Volume",
                    description: "80 hrs per month",
                    value: 3,
                    totalFootage: 300,
                    staffing: 0.5,
                    staffingCode: "ft1",
                    equipment: [],
                    software: [],
                    otherItems: []
                }
            ]
        },

        {
            id: 'offlineWideFormatFlatbedCutter',
            type: 'enum',
            required: false,
            component: 'PulloutQuestionRatedList',
            default: 0,
            label: "Wide Format Flatbed Cutter",
            options: [
                {
                    id: "none",
                    label: "None",
                    value: 0
                },

                {
                    id: "low",
                    label: "Low",
                    summaryLabel:"Low Volume",
                    description: "120-4'x8' boards per month",
                    value: 1,
                    totalFootage: 221,
                    staffing: 0.25,
                    staffingCode: "wf2",
                    equipment: ['Colex SX-3232'],
                    software: [],
                    otherItems: []
                },
                {
                    id: "medium",
                    label: "Medium",
                    summaryLabel:"Medium Volume",
                    description: "280-4'x8' boards per month",
                    value: 2,
                    totalFootage: 277,
                    staffing: 0.5,
                    staffingCode: "wf2",
                    equipment: ['Colex SX-1631'],
                    software: [],
                    otherItems: []
                },
                {
                    id: "high",
                    label: "High",
                    summaryLabel:"High Volume",
                    description: "600-4'x8' boards per month",
                    value: 3,
                    totalFootage: 425,
                    staffing: 1,
                    staffingCode: "wf2",
                    equipment: ['Colex SX-1616'],
                    software: [],
                    otherItems: []
                }
            ]
        },

        {
            id: 'offlineWideFormatHandFinishing',
            type: 'enum',
            required: false,
            component: 'PulloutQuestionRatedList',
            default: 0,
            label: "Wide Format Hand Finishing w/Cutter & Table",
            options: [
                {
                    id: "none",
                    label: "None",
                    value: 0
                },

                {
                    id: "low",
                    label: "Low",
                    summaryLabel:"Low Volume",
                    description: "20 hours per month",
                    value: 1,
                    totalFootage: 140,
                    staffing: 0.125,
                    staffingCode: "wf1",
                    equipment: ['Hand Cutter, 4\' x 8\' Table'],
                    software: [],
                    otherItems: []
                },
                {
                    id: "medium",
                    label: "Medium",
                    summaryLabel:"Medium Volume",
                    description: "40 hours per month",
                    value: 2,
                    totalFootage: 270,
                    staffing: 0.25,
                    staffingCode: "wf1",
                    equipment: ['Hand Cutter, 5\' x 12\' table'],
                    software: [],
                    otherItems: []
                },
                {
                    id: "high",
                    label: "High",
                    summaryLabel:"High Volume",
                    description: "80 hours per month",
                    value: 3,
                    totalFootage: 355,
                    staffing: 0.5,
                    staffingCode: "wf1",
                    equipment: ['Hand Cutter, 5\' x 12\' Table, Staging Area'],
                    software: [],
                    otherItems: []
                }
            ]
        },
        {
            id: 'offlineWideFormatLaminator',
            type: 'enum',
            required: false,
            component: 'PulloutQuestionRatedList',
            default: 0,
            label: "Wide Format Laminator",
            options: [
                {
                    id: "none",
                    label: "None",
                    value: 0
                },

                {
                    id: "low",
                    label: "Low",
                    summaryLabel:"Low Volume",
                    description: "10 posters per month",
                    value: 1,
                    totalFootage: 36,
                    staffing: 0.02,
                    staffingCode: "wf1",
                    equipment: ['3 ft p/m'],
                    software: [],
                    otherItems: []
                },
                {
                    id: "medium",
                    label: "Medium",
                    summaryLabel:"Medium Volume",
                    description: "60 posters per month",
                    value: 2,
                    totalFootage: 96,
                    staffing: 0.08,
                    staffingCode: "wf1",
                    equipment: ['14 ft p/m'],
                    software: [],
                    otherItems: []
                },
                {
                    id: "high",
                    label: "High",
                    summaryLabel:"High Volume",
                    description: "150 posters per month",
                    value: 3,
                    totalFootage: 120,
                    staffing: 0.3,
                    staffingCode: "wf1",
                    equipment: ['20 ft p/m'],
                    software: [],
                    otherItems: []
                }
            ]
        }





    ]
}
