


<template>
  <div class="home flex flex-col h-full relative">
    <div class="text-xs px-8 py-2 pt-4 bg-white">
      <router-link to="/">Home</router-link> /
      <router-link to="/admin">Admin</router-link>
    </div>
    <div class="bg-white">

      <div class="flex justify-between w-full pt-0  pb-4 px-8">
        <h1 class="text-3xl leading-none">Admin Dashboard</h1>
        <div class="flex -mt-2">
          <button class="button text-sm mr-4" @click="createCustomer" ><img class="inline-block mr-2 w-4 h-4" src="../assets/icons/edit-icon.svg" />New Org</button>
          <button class="button button-blue text-sm" @click="createUser" ><img class="inline-block mr-2 h-3" src="../assets/icons/new-user-icon.svg" />New User</button>
        </div>
      </div>


    </div>

    <div class="px-8">
    <tab-bar class="pl-8" :tabs="tabs" :selection="currentTab" @change="onTabSelect"></tab-bar>
    </div>

    <div class="border-t-4 px-8 py-4 overflow-y-scroll" v-if="currentTab.id === 'users'">
      <p class="ml-4 text-gray-400">Showing {{ this.filteredUsers.length }} of {{ this.sortedUsers.length }} Active Users</p>
      <div class="mt-2">
        <div class="border-t-4 px-4 py-2 flex items-center bg-gray-50 relative">
          <div class="relative">
            <div class="inline-block relative">
              <input class="px-2 py-1 text-xs border rounded-full w-64 focus:outline-none focus:ring focus:border-blue-300 bg-white" type="text" placeholder="Filter by Name, Email, Org, or App code" v-model="searchText" />
              <button class="text-xs py-1 px-2 absolute right-0 rounded-full" v-if="searchText" @click="searchText = ''">&times;</button>
            </div>

            <button class="button text-xs py-1 px-2 ml-2 hidden">Submit</button>
          </div>

        </div>
      </div>

      <table class="w-full text-sm">
        <tr>
          <th class="text-left pl-4 hover:underline cursor-pointer" @click="setSort('lastname','firstname')">Last Name</th>
          <th class="text-left hover:underline cursor-pointer" @click="setSort('firstname','lastname')">First Name</th>
          <th class="text-left hover:underline cursor-pointer" @click="setSort('levelNum','lastname')">Level</th>
          <th class="text-left hover:underline cursor-pointer" @click="setSort('email','')">Email</th>
          <th class="text-left hover:underline cursor-pointer" @click="setSort('lastAccess','')">Last Access</th>
          <th class="text-left hover:underline cursor-pointer" @click="setSort('organization','lastname')">Org.</th>
          <th class="text-left hover:underline cursor-pointer" @click="setSort('createdBy','lastname')">Creator</th>
          <th class="text-left hover:underline cursor-pointer" @click="setSort('expiration')">Expiration</th>
          <th class="text-left px-2">WF</th>
          <th class="text-left">CCM</th>
          <th class="text-left">MLG</th>
          <th class="text-left">MAP</th>
          <th class="text-left">PMP</th>
          <th class="text-left">EQR</th>
          <th class="text-left">SCC</th>
          <th class="text-left">PRJ</th>
          <th class="text-left">CFG</th>
          <th class="text-left">NA</th>
          <th></th>
        </tr>
        <tr v-for="user in filteredUsers" :key="user.email" class="hover:bg-blue-50">
          <td class="pl-4">{{ user.data.lastname }}</td>
          <td>{{ user.data.firstname }}</td>
          <td>{{ user.data.levelNum }}</td>
          <td>{{ user.data.email }}</td>
          <td>{{ user.data.lastAccess | formatTS('ms') }}</td>
          <td><span :class="!user.data.organizationId?'text-red-600':''">{{ user.data.organization }}</span></td>
          <td>{{ user.data.createdBy }}</td>
          <td>{{ asDate(user.data.expiration) }}</td>
          <td class="text-center"><span class="w-4 h-4 rounded-full inline-block border" :class="user.data.apps.PWF?'bg-brand-green':''" ></span></td>
          <td class="text-center"><span class="w-4 h-4 rounded-full inline-block border" :class="user.data.apps.CCM?'bg-brand-green':''" ></span></td>
          <td class="text-center"><span class="w-4 h-4 rounded-full inline-block border" :class="user.data.apps.MLG?'bg-brand-green':''" ></span></td>
          <td class="text-center"><span class="w-4 h-4 rounded-full inline-block border" :class="user.data.apps.MAP?'bg-brand-green':''" ></span></td>
          <td class="text-center"><span class="w-4 h-4 rounded-full inline-block border" :class="user.data.apps.PMP?'bg-brand-green':''" ></span></td>
          <td class="text-center"><span class="w-4 h-4 rounded-full inline-block border" :class="user.data.apps.EQR?'bg-brand-green':''" ></span></td>
          <td class="text-center"><span class="w-4 h-4 rounded-full inline-block border" :class="user.data.apps.SPC?'bg-brand-green':''" ></span></td>
          <td class="text-center"><span class="w-4 h-4 rounded-full inline-block border" :class="user.data.apps.PRC?'bg-brand-green':''" ></span></td>
          <td class="text-center"><span class="w-4 h-4 rounded-full inline-block border" :class="user.data.apps.CFG?'bg-brand-green':''" ></span></td>
          <td class="text-center"><span class="w-4 h-4 rounded-full inline-block border" :class="user.data.apps.NA?'bg-brand-green':''" ></span></td>
          <td class="text-center"><button class="text-xs border p-1 bg-white hover:bg-brand-blue hover:text-white" @click="editUser(user)">EDIT</button></td>
        </tr>
      </table>
    </div>

    <div class="border-t-4 px-8 py-4 overflow-y-scroll" v-if="currentTab.id === 'orgs'">
      <p class="ml-4 text-gray-400">{{ this.sortedCustomers.length }} Results</p>
      <table class="w-full text-sm">
        <tr>
          <th class="text-left pl-4 hover:underline cursor-pointer" >Organization</th>
          <th class="text-left hover:underline cursor-pointer" >ORC CF Cust #</th>
          <th class="text-left hover:underline cursor-pointer" >ORC CS Cust #</th>
          <th></th>
        </tr>
        <tr v-for="customer in sortedCustomers" :key="customer.ref.value.id" class="hover:bg-blue-50">
          <td class="pl-4">{{ customer.data.name }}</td>
          <td>{{ customer.data.orgCode }}</td>
          <td>{{ customer.data.orgCode2 }}</td>
          <td class="text-center"><button class="text-xs border p-1 bg-white hover:bg-brand-blue hover:text-white" @click="editOrg(customer)">EDIT</button></td>
        </tr>
      </table>
    </div>

    <div class="border-t-4 px-8 py-4 overflow-y-scroll" v-if="currentTab.id === 'activity'">
      <table class="w-full text-sm">
        <tr>
          <th class="text-left pl-4">Date</th>
          <th class="text-left">Action</th>
          <th class="text-left">Result</th>
          <th class="text-left">User</th>
          <th class="text-left">IP</th>

        </tr>
        <tr v-for="entry in logs" :key="entry.ref.value.id" class="hover:bg-blue-50">
          <td class="pl-4">{{ entry.ts | formatTS }}</td>
          <td>{{ entry.data.action }}</td>
          <td>{{ entry.data.result }}</td>
          <td>{{ entry.data.user }}</td>
          <td>{{ entry.data.address }}</td>
        </tr>
      </table>
    </div>


    <div class="fixed w-screen h-screen top-0 right-0 bg-black bg-opacity-50" v-if="(sidePanel || customerPanel)"></div>
    <transition name="slide">
      <div class="fixed w-full h-full max-w-xl top-0 right-0 shadow-lg" v-if="sidePanel">
        <side-panel-new-user class="side-panel absolute top-0 right-0 w-full h-screen bg-white border-l bg-gray-50 overflow-y-scroll"  @close="closeSidePanel" @created="onNewUser"></side-panel-new-user>
      </div>
    </transition>

    <transition name="slide">
      <div class="fixed w-full h-full max-w-xl top-0 right-0 shadow-lg" v-if="customerPanel">

        <side-panel-new-customer class="side-panel absolute top-0 right-0 w-full h-screen bg-white border-l bg-gray-50 overflow-y-scroll"  @close="closeSidePanel" @created="onNewCustomer"></side-panel-new-customer>
      </div>
    </transition>


  </div>
</template>

<script>

import TabBar from "@/components/TabBar";
import SidePanelNewUser from "@/components/SidePanelNewUser";
import SidePanelNewCustomer from "@/components/SidePanelNewCustomer";
export default {
    name: "admin-dashboard",
  components: {SidePanelNewCustomer, SidePanelNewUser, TabBar},
  props: {},
    mounted: function() {
      if(this.$route.query.tab){
        this.setTabFromQuery();
      }else{
        this.loadUsers();
      }
    },
    watch: {
      // call again the method if the route changes
      '$route': 'setTabFromQuery'
    },

  data: function(){
        return {
          sidePanel: false,
          customerPanel: false,
          users: [],
          customers: [],
          logs: [],
          tabs: [
            { id: "users", label: "Users"},
            { id: "orgs", label: "Organizations"},
            { id: "activity", label: "Activity"},
          ],
          tabIndex: 0,
          sortKey: "lastname",
          secondSortKey: "firstname",
          sortDirection: 1,
          searchText: ""
        }
        },
    methods: {

      asDate: function(value){
        if(value){
          let date = new Date(value);
          if(date){
            return date.toLocaleDateString()
          }
        }

        return ""
      },

      setSort: function(first,second){
        if(this.sortKey === first){
          this.sortDirection = -this.sortDirection
        }else{
          this.sortKey = first;
          this.secondSortKey = second;
          this.sortDirection = 1;
        }
      },

      closeSidePanel: function(){
        this.sidePanel = false;
        this.customerPanel = false;
      },

      onNewUser: function(){
        this.loadUsers();
        this.sidePanel = false;
      },

      onNewCustomer: function(){
        this.loadCustomers();
        this.customerPanel = false;
      },


      createUser: function(){
        this.sidePanel = true;
      },

      createCustomer: function(){
        this.customerPanel = true;
      },

      editUser: function(user){
        this.$router.push({
          name: "EditUser",
          params: {
            userid: user.ref.value.id
          }
        })
      },

      editOrg: function(org){
        console.log(org);
        this.$router.push({
          name: "EditOrg",
          params: {
            orgid: org.ref.value.id
          }
        })
      },

      editCustomer: function(customer){
          console.log(customer);
      },

      onTabSelect: function(e){
        if(e.id !== this.currentTab.id){
          this.$router.push({
            name: "Admin",
            query: {
              tab: e.id
            }
          })
        }

      },

      setTabFromQuery: function(){
        if(this.$route.query.tab){
          this.setTab({ id: this.$route.query.tab})
        }

      },

      setTab: function(e){

        console.log(e.id);
        let tgtIndex = this.tabs.findIndex((tab) => {
          console.log(tab.id, e.id);
          return (tab.id === e.id);
        })

        if(this.tabs[tgtIndex].id === "activity"){
          if(!this.logs.length){
            this.loadLogs();
          }
        }

        if(this.tabs[tgtIndex].id === "orgs"){



          if(!this.customers.length){
            this.loadCustomers();
          }
        }

        if(this.tabs[tgtIndex].id === "users"){
          if(!this.users.length){
            this.loadUsers();
          }
        }

        this.tabIndex = tgtIndex;
      },

      loadUsers: function(){
        this.loadData("all_users")
        .then((response) => {

          if(response.data){
            response.data.forEach((user) => {
              let domain = user.data.domain.split(",");



              let apps = {}

              if(user.data.domain === "*"){
                apps = {
                  "PWF": true,
                    "CCM": true,
                    "EQR": true,
                    "SPC": true,
                    "PRC": true,
                  "CFG": true,
                  "MLG": true,
                  "NA": true
                }
              }else{
                domain.forEach((app) => {
                  apps[app] = true;
                })
              }

              user.data.apps = apps;
              user.data.levelNum = this.levels[user.data.level]
              if(user.data.creator){
                user.data.createdBy = user.data.creator.username;
              }else{
                user.data.createdBy = "-";
              }

            });




            let adminLevel = this.levels[this.adminProfile.level];

            this.users = response.data.filter((record) => {
              if(record.data.levelNum <= adminLevel){




                if(!(record.data.apps.EQR || record.data.apps.SPC || record.data.apps.PRC || record.data.apps.CCM || record.data.apps.PWF || record.data.apps.CFG || record.data.apps.NA)){
                  return true;
                }

                if(this.$store.getters.hasPrintShopConfigAccess){
                  if(record.data.apps.CFG){
                    return true;
                  }
                }

                if(this.$store.getters.hasNeedsAnalysisAccess){
                  if(record.data.apps.NA){
                    return true;
                  }
                }


                if(this.$store.getters.hasCalculatorAccess){
                  if(record.data.apps.EQR || record.data.apps.SPC || record.data.apps.PRC){
                    return true;
                  }
                }

                if(this.$store.getters.hasWorkflowAccess){
                  if(record.data.apps.CCM || record.data.apps.PWF){
                    return true;
                  }
                }

                if(this.$store.getters.hasMailingAccess){
                  if(record.data.apps.MLG){
                    return true;
                  }
                }
              }
            });
          }

        })
        .catch((error) => {
          console.error(error)
        })
      },

      loadCustomers: function(){
        let q = this.$db.query;
        let client = new this.$db.Client({ secret: this.$store.getters.getToken });

        client.query(
            q.Map(
                q.Paginate(
                    q.Match(
                        q.Index("all_customers")
                    ),
                    {size: 300}
                ),
                q.Lambda("X", q.Get(q.Var("X")))
            )
        )
            .then((response) => {
              console.log("customers", response);
              if(response.data){
                this.customers = response.data;
              }
            })
            .catch((error) => {
              console.error(error)
            })

      },



      loadLogs: function(){
        let q = this.$db.query;
        let client = new this.$db.Client({ secret: this.$store.getters.getToken });

        client.query(
            q.Map(
                q.Paginate(
                    q.Match(
                        q.Index("access_by_action"),
                        "login"
                    ),
                ),
                q.Lambda("X", q.Get(q.Select([1], q.Var("X"))))
                // q.Lambda("X", q.Get(q.Select([1],q.Var("X")))
              )
            )
            .then((response) => {
              console.log("logs", response);
              if(response.data){
                this.logs = response.data;
              }
            })
            .catch((error) => {
              console.error(error)
            })

      },


      loadData: function(index, params){
        let q = this.$db.query;
        let client = new this.$db.Client({ secret: this.$store.getters.getToken });

        return client.query(
            q.Map(
              q.Paginate(
                  q.Match(
                      q.Index(index),
                      params
                  ),
                  { size: 500 }
              ),
                q.Lambda("X", q.Get(q.Var("X")))
            )
        )
        .then((response) => {
          return response;
        })
      }
    },
    computed: {
      adminProfile: function(){
        return this.$store.getters.getUserProfile;
      },

      sortedCustomers: function(){
        if(this.customers){
          return [...this.customers].sort((a,b) => {
            if(a.data.name < b.data.name){
              return -1
            }else if(a.data.name > b.data.name){
              return 1
            }else{
              return 0
            }
          })
        }

        return [];
      },

      sortedUsers: function(){

        if(this.sortKey === "lastAccess"){

          return [...this.users].sort((a,b) => {

            if(this.sortDirection < 0){
              return ((a.data[this.sortKey]?a.data[this.sortKey]:0) - (b.data[this.sortKey]?b.data[this.sortKey]:0));
            }else{
              return ((b.data[this.sortKey]?b.data[this.sortKey]:0) - (a.data[this.sortKey]?a.data[this.sortKey]:0));
            }

          });

        }else{

          return [...this.users].sort((a,b) => {
            if (a.data[this.sortKey] < b.data[this.sortKey]){
              return -this.sortDirection;
            }else if (a.data[this.sortKey] > b.data[this.sortKey]){
              return this.sortDirection;
            }else {
              if(this.secondSortKey){
                if(a.data[this.secondSortKey] < b.data[this.secondSortKey]){
                  return -this.sortDirection;
                }else if(a.data[this.secondSortKey] > b.data[this.secondSortKey]){
                  return this.sortDirection;
                }
              }

            }
            return 0;
          });

        }


      },

      filteredUsers: function(){
        console.log(this.sortedUsers)
        if(this.searchText){

          let searchTerms = this.searchText.toLowerCase();
          let multiword = searchTerms.includes('+');
          let searchWords = searchTerms.split("+");


          return this.sortedUsers.filter((user) => {
            if(searchTerms){

              let searchIn = `${user.data.firstname} ${user.data.lastname} ${user.data.levelnum} ${user.data.email} ${user.data.organization} ${user.data.apps.PWF?"PWF":""} ${user.data.apps.CCM?"CCM":""} ${user.data.apps.EQR?"EQR":""} ${user.data.apps.SPC?"SCC":""} ${user.data.apps.PRC?"PRJ":""} ${user.data.apps.CFG?"CFG":""} ${user.data.apps.NA?"NA":""}  ${user.data.apps.MLG?"MLG":""} ${user.data.apps.MAP?"MAP":""} ${user.data.apps.PMP?"PMP":""}`;

              if(multiword){

                let foundAll = true;

                for( let i=0; i<searchWords.length; i++){
                  foundAll = (searchIn.toLowerCase().search(searchWords[i].trim()) > -1);
                  if(!foundAll){
                    break;
                  }
                }
                return foundAll;

              }else{
                return (searchIn.toLowerCase().search(searchTerms) > -1)
              }




            }
            return true
          })
        }
        return this.sortedUsers;
      },

      currentTab: function(){
        return this.tabs[this.tabIndex];
      },

      levels: function(){
        return {
          "0": 0,
          "user": 1,
          "manager": 2,
          "admin": 3

        }
      }
    }
}
</script>

<style scoped>


.admin {

}

</style>
