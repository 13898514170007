


<template>
<div class="analysis-details flex flex-col h-full relative">
  <div class="text-xs px-8 py-2 pt-4 bg-white">
    <router-link to="/">Home</router-link> /
    <router-link to="/needs">Needs Analysis</router-link>
  </div>
  <div class="px-8 pt-0 bg-white">
    <h1 class="text-3xl mb-6 leading-none flex"><span class="mr-2">{{ title }}</span><button v-if="analysis" @click="update" :disabled="!hasUnsavedChanges" class="button text-xs py-1 px-2 ml-2 -mb-1" :class="hasUnsavedChanges?'border-red-600 text-red-600 hover:bg-red-600 hover:text-white':''"><span v-if="hasUnsavedChanges">Save Changes</span><span v-else>Saved</span></button></h1>
      <tab-bar :tabs="tabs" :selection="currentTab" @change="setTab" v-if="analysis"></tab-bar>
  </div>

  <div class="flex-grow relative bg-gray-50" v-if="analysis">
<!--    <component :is="currentTab.component" :analysis="analysis" @tab="showTab" @autosave="save"></component>-->
    <div v-if="currentTab.id === 'customer'" class="w-full p-8">

      <needs-customer-profile :analysis="analysis" @change="onDataChange" @link="setTab" class="max-w-2xl"></needs-customer-profile>

    </div>
    <div v-if="currentTab.id === 'summary'" class="w-full">

      <needs-summary-panel v-if="analysis" :analysis="analysis" :api="api" @change="onDataChange" class="" @note="editAnswer"></needs-summary-panel>

    </div>
    <div v-if="currentTab.id === 'questionnaire'" class="">
      <div class="px-8 flex w-full">
        <div class="w-48 lg:w-64 flex-shrink-0 sticky top-0 self-start pt-8">
          <div class="">


            <div class="mb-4">
              <div class="text-xs mb-1">Questions For:</div>
              <div class="font-bold w-48 leading-tight hover:underline cursor-pointer group" @click="filterPanel = true">
                {{  filteredVoiceLabel }}<span class="ml-2 text-xxs opacity-50 hover:opacity-100">Edit</span></div>
            </div>

            <timeline-list class="" :links="filteredQuestionList" :selection="currentQuestionGroup" :section="currentQuestionGroupSection" @change="setQuestionGroup" v-if="analysis"></timeline-list>
          </div>
        </div>
        <div class="flex-grow">
          <component :is="currentMode.component" :analysis="analysis" :listing="currentQuestionGroup" :section="currentQuestionGroupSection" :filter-relevant="filterInfo.showDepartmentQuestionsOnly" :responses-by-question="responsesByQuestion" :next="nextGroupId" :previous="previousGroupId" @showPrevious="showPrevious" @showNext="showNext" :api="api" :current-voice="currentDepartment" :department="currentDepartment" @edit-answer="editAnswer" @settings="filterPanel = true"></component>
        </div>
      </div>
    </div>

  </div>

  <div class="fixed w-full h-full top-0 right-0 bg-black bg-opacity-50" v-if="editInfo || filterPanel"></div>

  <transition name="slide">
    <div class="fixed w-full h-full max-w-xl top-0 right-0 shadow-lg" v-if="editInfo">
      <side-panel-needs-answer class="side-panel absolute top-0 right-0 w-full h-screen bg-white border-l bg-gray-50 overflow-y-scroll" :edit-info="editInfo" :session="currentSession" :api="api" :analysis="analysis" @update-session="updateSession" @close="closeSidePanel"></side-panel-needs-answer>
    </div>
  </transition>

  <transition name="slide">
    <div class="fixed w-full h-full max-w-xl top-0 right-0 shadow-lg" v-if="filterPanel">
      <side-panel-needs-filters class="side-panel absolute top-0 right-0 w-full h-screen bg-white border-l bg-gray-50 overflow-y-scroll" :session="currentSession" :filter-info="filterInfo" :api="api" :analysis="analysis" @update-settings="updateSettings" @close="closeSidePanel"></side-panel-needs-filters>
    </div>
  </transition>


</div>

</template>

<script>
import needsAnalysis from "@/plugins/needsAnalysis";
import TabBar from "@/components/TabBar";

import NeedsQuestionnaire from "@/components/NeedsAnalysis/NeedsQuestionnaire";
import SidePanelNeedsAnswer from "@/components/NeedsAnalysis/SidePanelNeedsAnswer";

import TimelineList from "@/components/TimelineList";
import SelectInput from "@/components/SelectInput";
import SidePanelNeedsFilters from "@/components/NeedsAnalysis/SidePanelNeedsFilters";
import NeedsCustomerProfile from "@/components/NeedsAnalysis/NeedsCustomerProfile";
import NeedsSummaryPanel from "@/components/NeedsAnalysis/NeedsSummaryPanel";


export default {
  name: "analysis-details",
  components: {
    NeedsSummaryPanel,
    NeedsCustomerProfile,
    SidePanelNeedsFilters, SelectInput, TimelineList, SidePanelNeedsAnswer, NeedsQuestionnaire, TabBar},
  mounted: function(){

      this.loadData();
  },

  props: {},
    data: function(){
        return {
          api: needsAnalysis,
          analysis: null,
          saving: false,
          loading: false,
          currentSession: null,
          hasUnsavedChanges: false,
          modeId: "question",
          modes: {
            question: {
              component: "NeedsQuestionnaire"
            }
          },
          editInfo: undefined,
          filterInfo: {
            focus: undefined,
            showDepartmentQuestionsOnly: true
          },
          filterPanel: false,
        }
    },
    methods: {
      onDataChange: function(){
        this.hasUnsavedChanges = true;
      },

      loadData: function(){
        //console.log("loading analysis", this.$route.params.analysis);
        this.$store.state.analysis = undefined;
        if(this.$route.params.analysis){
          this.api.load(this.$route.params.analysis).then((analysis) => {



            console.log(analysis);
            if(this.validDocumentCheck(analysis)){
              this.analysis = analysis;
            }else{
              alert("This document is no longer in a valid format.");
              return;
            }

            if(localStorage.getItem("showDepartmentQuestionsOnly")){
              this.filterInfo.showDepartmentQuestionsOnly = !!localStorage.getItem("showDepartmentQuestionsOnly");
            }

            if(localStorage.getItem("session-respondent")){
              let respondent = localStorage.getItem("session-respondent");
              if(this.analysis.respondents[respondent]){
                this.currentSession = {
                  respondent: respondent
                }
              }else{
                this.updateSession({
                  key: "respondent",
                  value: this.$store.getters.getUserProfile.userid
                })
              }

            }else{
              this.updateSession({
                key: "respondent",
                value: this.$store.getters.getUserProfile.userid
              })
            }

          }).catch((error) => {
            console.log("ERROR",error);
          })
        }
      },

      //used to update out of date document versions
      validDocumentCheck: function(doc){
        console.log ("Respondents", doc.respondents);

        try {
          if(Object.keys(doc.respondents).length){
            return true
          }else{
            console.log("failed responents length test");
          }

          if(Object.keys(doc.respondents).length === 0 && doc.respondents.constructor === Object){

            return true;
          }else{
            console.log("failed responents empty object test");
          }

        }catch(e){
          return false;
        }



        return false;
      },

      editAnswer: function(info){
        console.log("edit answer", info);

        this.editInfo = info
      },

      closeSidePanel: function(){

        this.editInfo = undefined;
        this.filterPanel = false;
      },

      setTab: async function(tab){

        if(this.hasUnsavedChanges){
          await this.update();
        }

        this.$router.push({
          name: this.$route.name,
          params: this.$route.params,
          query: {
            tab: tab.id
          }
        })
        window.scrollTo(0,0);
      },

      setQuestionGroup: function(group){

        console.log("set group");

        let query =  {
          tab: this.$route.query.tab,
          group: group.id,
        }

        if(group.section){
          query.section = group.section
        }

        this.$router.push({
          name: this.$route.name,
          params: this.$route.params,
          query: query
        })
        window.scrollTo(0,0);
      },

      setSection: function(section){
        this.$router.push({
          name: this.$route.name,
          params: this.$route.params,
          query: {
            tab: this.currentQuestionGroup.id,
            section: section.id
          }
        })
      },

      showPrevious: function(){
        this.setQuestionGroup({id:this.previousGroupId})
      },

      showNext: function(){
        this.setQuestionGroup({id:this.nextGroupId})
      },

      goHome: function(){
        this.$router.push({
          name: "Home"
        })
      },

      update: function(){
        let updatedProfile = this.analysis.customerProfile;
        let userEmail = this.$store.getters.getUserProfile.email;
        this.saving = true;
        this.api.update(this.analysis.id, {
          customerProfile: updatedProfile,
          notes: {
            value: this.analysis.notes.value
          }
        }, userEmail).then(() =>{
          this.saving = false;
          this.hasUnsavedChanges = false;
          return true;
        }).catch((error) => {
          console.error(error);
          alert("Record could not be saved. Try again.");
          this.saving = false;
          return false;
        })

      },

      save: function(){

        let userEmail = this.$store.getters.getUserProfile.email;
        this.saving = true;
        this.api.save(this.analysis, userEmail).then(() =>{
          this.saving = false;
          this.hasUnsavedChanges = false;
        }).catch((error) => {
          console.error(error);
          alert("Record could not be saved. Try again.");
          this.saving = false;
        })

      },

      updateSession: function(e){
        if(e.key){
          let session = {}
          session[e.key] = e.value;
          this.currentSession = session;
          localStorage.setItem("session-"+e.key, e.value);
          return
        }

        this.currentSession = null;
        return
      },

      updateFiltering: function(e){
        if(e){
          this.filterInfo.showDepartmentQuestionsOnly = !!e.showDepartmentQuestionsOnly;
          localStorage.setItem("showDepartmentQuestionsOnly", e.value);
          return
        }
      },

      updateSettings: function(e){
        if(e.session){
          this.updateSession(e.session)
        }

        if(e.filtering){
          this.updateFiltering(e.filtering)
        }

      }

    },
    computed: {

    filteredVoiceLabel: function(){
      if(this.currentDepartment === "notes"){
        return "All Departments"
      }else{
        if(this.api.schema.voiceOptions[this.currentDepartment]){
          return this.api.schema.voiceOptions[this.currentDepartment].label
        }
      }


      return "All Departments"
    },

      currentDepartment: function(){
        if(this.analysis && this.currentSession && this.currentSession.respondent){
          if(this.analysis.respondents[this.currentSession.respondent]){
            return this.analysis.respondents[this.currentSession.respondent].department;
          }
        }

        return "all"
      },

    currentMode: function(){
      return this.modes[this.modeId];
    },

      currentSections: function(){
        return this.currentQuestionGroup.sections
      },

      currentTitle: function(){
        return this.currentTab.label
      },

    isAdmin: function(){
      return this.$store.getters.hasAdminAccess;
    },
    tabs: function(){


      return [
        { label: "Customer Info", id: "customer"},
        { label: "Questionnaire", id: "questionnaire"},
        { label: "Summary", id: "summary"},
      ]
    },

      questionGroups: function(){


        return [
          { label: "General Info", id: "generalInfo", sections:["generalInfo"]},
          { label: "Equipment", id: "equipment", sections:["equipment"]},
          { label: "Workflow", id: "workflow", sections:["workflow"]},
          { label: "Security", id: "security", sections:["security"]},
          { label: "Color", id: "color", sections:["color","colorDiscovery"]},
          { label: "Cross Media", id: "crossMedia", sections:["crossMedia"]},
          { label: "Industrial Print", id: "industrialPrint", sections:["industrialPrint"]},
          { label: "Advanced Solutions", id: "advancedSolutions", sections:["advancedSolutions"]},
          { label: "Advanced Finishing", id: "advancedFinishing", sections:["advancedFinishing"]},
        ]
      },


      title: function(){
        if(this.analysis){
          return this.analysis.customerProfile.organizationName;
        }else{
          return "Loading Record..."
        }
      },

      currentTab: function(){

        var tab = this.tabs.find((tab) => {
          return tab.id == this.$route.query.tab;
        })

        if(!tab){
          tab = this.tabs[0];
        }

        return tab;

      },

      currentQuestionGroup: function(){

        var group = this.filteredQuestionList.find((group) => {
          return group.id == this.$route.query.group;
        })

        if(!group){
          group = this.filteredQuestionList[0];
        }

        return group;

      },

      currentQuestionGroupSection: function(){
        if(this.$route.query.section){
          return this.$route.query.section
        }else{
          return 0
        }
      },

      nextGroupId: function(){
        if(this.currentQuestionGroup){


          let currentIndex = this.filteredQuestionList.findIndex((group)=>{
            return (this.currentQuestionGroup.id === group.id);
          });
          let tgtIndex = currentIndex + 1;

          if(tgtIndex < this.filteredQuestionList.length){
            return this.filteredQuestionList[tgtIndex].id;
          }
        }

        return ""
      },

      previousGroupId: function(){
        if(this.currentQuestionGroup && this.filteredQuestionList){
          let currentIndex = this.filteredQuestionList.findIndex((group)=>{
            return (this.currentQuestionGroup === group);
          });
          let tgtIndex = currentIndex - 1;
          if(tgtIndex > -1){
            return this.filteredQuestionList[tgtIndex].id;
          }
        }

        return ""
      },




      voiceIds: function(){
        return Object.keys(this.api.schema.voiceOptions)
      },

      tableColumns: function(){

        let voices = this.voiceIds.map((voice) => {
          return this.api.schema.voiceOptions[voice]
        })
        return [{ label: "Question", shortLabel: "Question", id:"question"}, ...voices ]
      },

      filteredQuestionList: function(){

          let questionList = [];

          this.questionGroups.forEach((qGroup)=> {

            let blocks = [];
            let qCount = 0;
            let qCountRelevant = 0;
            let qHidden = 0;

            qGroup.sections.forEach((sectionId) => {
              let groups = [];
              let groupQCount = 0;
              let groupQRelevant = 0;
              let groupQHidden = 0;

              this.api.schema.sections[sectionId].groups.forEach((group) => {
                let questions = [];



                if(this.filterInfo.showDepartmentQuestionsOnly){

                  console.log("showing only department questions");

                  if(!this.currentDepartment || (this.currentDepartment === "all") || (this.currentDepartment === "notes")){
                    //should show all as relevant and visible
                    console.log("all relevant")
                    questions = group.questions;

                  }else{
                    questions = group.questions.filter((question) => {
                      return this.api.schema.questions[question].voices.includes(this.currentDepartment)
                    })
                  }

                  groupQRelevant += questions.length;
                  groupQCount += group.questions.length;
                  groupQHidden += (group.questions.length - questions.length);

                  qCountRelevant += questions.length;
                  qCount += group.questions.length;
                  qHidden += (group.questions.length - questions.length);



                }else{
                  console.log("showing all department questions");
                  questions = group.questions;
                  let relevant = 0;
                  if(!this.currentDepartment || (this.currentDepartment === "all") || (this.currentDepartment === "notes")){
                    //should show all as relevant and visible
                    relevant = group.questions.length;
                  }else{
                    relevant = group.questions.reduce((total,question) => {
                      if(this.api.schema.questions[question].voices.includes(this.currentDepartment)){
                        total += 1;
                      }
                      return total;
                    },0)
                  }

                  groupQRelevant += relevant;
                 groupQCount += group.questions.length;
                  groupQHidden += (group.questions.length - relevant);

                  qCountRelevant += relevant;
                  qCount += group.questions.length;
                  qHidden += (group.questions.length - relevant);



                }


                if(questions.length){

                  groups.push(
                      {
                        label: group.label,
                        questions: questions
                      }
                  );
                }
              })


              if(groups.length){
                blocks.push(
                    {
                      id: sectionId,
                      label: this.api.schema.sections[sectionId].label,
                      warning: this.api.schema.sections[sectionId].warning,
                      groups: groups,
                      count: groupQCount,
                      relevant: groupQRelevant,
                      hidden: groupQHidden
                    }
                )
              }


            });


            questionList.push({
              id: qGroup.id,
              label: qGroup.label,
              sections: blocks,
              count: qCount,
              relevant: qCountRelevant,
              hidden: qHidden
            })

          }
       )

        return questionList;



      },

      responses: function(){
        if(this.analysis){
          return this.analysis.responses;
        }
        return null;
      },

      responsesByQuestion: function(){
        let _responsesByQuestion = {};

        if(!(this.analysis && this.analysis.responses)){
          return _responsesByQuestion;
        }
        let rKeys = Object.keys(this.analysis.responses);

        if(rKeys.length){

          rKeys.forEach((rKey) => {
            let response = this.responses[rKey];
            if(!_responsesByQuestion[response.question]){
              _responsesByQuestion[response.question] = {}
            }
            _responsesByQuestion[response.question][rKey] = response;
          })

          return _responsesByQuestion;
        }
        return _responsesByQuestion;
      }

    }
}
</script>

<style scoped>


.analysis-details {

}




</style>
