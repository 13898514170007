


<template>
<div class="">
  <h4>Customer Communication Management Consulting</h4>
  <hr>
  <p>Transformations Uluro uCampaign streamlines every step of your mail and electronic campaigns increases accuracy, efficiency, making it possible to quickly deliver effective campaigns for transactional documents, SMS messaging or HTML email. This web-based tool that will enable your clients to make direct document modifications online, including importing documents created in Microsoft Word, add variable data messaging as campaigns to run for designated time periods based on certain business rules or enter promotional information for use in a document’s white space. Then they can specify whether a campaign is to be sent using print or electronic media, including SMS messaging or HTML email. </p>
  <p>https://www.uluro.com/Services/CollaborativeCampaigns</p>
</div>
</template>

<script>
export default {
    name: "ccm-assessment",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


</style>
