<template>
  <div class="eficross-media">
      <h4>EFI MarketDirect Cross Media</h4>
    <hr>
    <p>EFI™ MarketDirect Cross Media is a customer communication and marketing campaign solution. It’s designed to make engaging with your customers both exciting and new, easy and hassle free. It achieves this by providing an intuitive yet powerful user experience where you can quickly create rich personalized communications across nearly any medium including; email, web, print, mobile and social media.</p>
    <p class="bold-label">Competitive Positioning:</p>
    <p>Today lead generation and customer prospecting require focused and targeted marketing messages. Delivering integrated campaigns that combine direct mail, landing pages, social media, email and mobile messaging has traditionally been complex and expensive – but MarketDirect changes that. With powerful template driven marketing campaigns, you can get a campaign up and running quickly – without any programming experience!</p>
    <p class="bold-label">Key Features:</p>
    <ul>
      <li>Host marketing campaigns in one centralized location</li>
      <li>Create stunning websites for mobile and desktop</li>
      <li>Send personalized email with automatic triggers</li>
      <li>Design rich VDP documents for direct mail</li>
      <li>Embed personalized URLs and QR codes in your marketing</li>
      <li>Send out interactive customer surveys</li>
      <li>Build marketing apps and Facebook apps</li>
      <li>Connects to CRM systems</li>
    </ul>
    <p class="bold-label">Customer Benefits:</p>
    <ul>
      <li>Design for all platforms and channels</li>
      <li>No HTML/CSS coding experience is necessary to develop and configure campaigns</li>
      <li>Ability to track and monitor major metrics</li>
      <li>SmartCampaign is a convenient solution to build campaigns – fast! It’s designed to enable you to get campaigns up and running, with a Campaign Wizard that makes it easier than ever to get started:</li>
      <li><b>Design Editor:</b> Powerful HTML 5 based browser-based Design Editor</li>
      <li><b>Storyboards:</b> For automated campaign creation</li>
      <li><b>Intelligent Themes:</b> For rapid campaign build out and deployment</li>
      <li><b>Data Visualizer:</b> For simple and powerful data connectivity</li>

    </ul>

  </div>
</template>

<script>
export default {
  name: "efi-cross-media",
  props: {},
  data: function () {
    return {}
  },
  methods: {},
  computed: {}
}
</script>

<style scoped>


.eficross-media {

}

</style>