


<template>
<div class="">
  <h4>Purchasing Best Practices</h4>
  <hr>
  <p>Purchasing Process complexity is sometimes overlooked however it is an important element. Purchasers must understand the Economic Order Quantity (EOC) that helps determine reorder quantities and reorder points for the lowest cost for holding inventory.</p>
  <p class="bold-label">Best Practice is to understand Elements to Determine EOC:</p>
  <ul>
    <li>Procurement/Carrying cost</li>
    <li>Risks and the cost impacted if you run out of stock</li>
    <li>Lead time for products</li>
    <li>Average consumption</li>
    <li>Overs/spoilage amounts</li>
  </ul>
  <p>Best practices are to have at least three main suppliers and always quote requests to all three. It is also important to maintain a strong business relationship with your suppliers.</p>
</div>
</template>

<script>
export default {
    name: "purchasing-best-practices",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


</style>
