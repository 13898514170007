

export default {

    equipment: {


        infoPrint5000MP: {
            id: 'infoPrint5000MP',
            name: 'InfoPrint 5000 MP',
            ppm: 1831,
            volume: 11073888,
            volume2: 11073888,
            volumeMax: 22147776,
            volumeAbbr: "22.1M",
            volumeSuffix: "pages per month",
            baseFootage: 600,
            materialFootage: 1200,
            totalFootage: 1800,
            excludedBy: [

                'colorResolutionMedium',
                'colorResolutionHigh',
                'precoat',
                'postcoat',
                'dryer',
                'monochrome'
            ],
            addedFootage: {
                'precoat': 40,
                'postcoat': 40,
                'dryer': 40,
                'cut': 40,
                'perforate': 40,
                'pageInsertion': 40,
                "folding": 56,
                "saddleStitch": 350,
                "stacker": 40,
                "perfectBind": 350,
                "rewinder": 40
            }
        },


        proVC40000: {
            id: 'proVC40000',
            name: 'Pro VC40000',
            ppm: 1717,
            volume: 10384416,
            volume2: 10384416,
            volumeMax: 20768832,
            volumeAbbr: "20.7M",
            volumeSuffix: "pages per month",
            baseFootage: 600,
            materialFootage: 1300,
            totalFootage: 1900,
            excludedBy: [


                'colorResolutionHigh', 'colorResolutionMedium',

            ],
            addedFootage: {
                'precoat': 40,
                'postcoat': 40,
                'dryer': 40,
                'cut': 40,
                'perforate': 40,
                'pageInsertion': 40,
                "folding": 56,
                "saddleStitch": 350,
                "stacker": 40,
                "perfectBind": 350,
                "rewinder": 40
            }
        },

        proVC60000: {
            id: 'proVC60000',
            name: 'Pro VC60000',
            ppm: 2145,
            volume: 12972960,
            volume2: 12972960,
            volumeMax: 25945920,
            volumeAbbr: "25.9M",
            volumeSuffix: "pages per month",
            baseFootage: 2300,
            materialFootage: 1600,
            totalFootage: 3900,
            excludedBy: [

                'monochrome',
                'colorResolutionLow'
            ],
            addedFootage: {
                'precoat': 40,
                'postcoat': 40,
                'dryer': 40,
                'cut': 40,
                'perforate': 40,
                'pageInsertion': 40,
                "folding": 56,
                "saddleStitch": 350,
                "stacker": 40,
                "perfectBind": 350,
                "rewinder": 40
            }
        },

        proVC70000: {
            id: 'proVC70000',
            name: 'Pro VC70000',
            ppm: 2145,
            volume: 12972960,
            volume2: 12972960,
            volumeMax: 25945920,
            volumeAbbr: "13.2M",
            volumeSuffix: "pages per month",
            baseFootage: 2300,
            materialFootage: 1600,
            totalFootage: 3900,
            excludedBy: [
                'micrInk',
                'monochrome',
                'colorResolutionLow'
            ],
            addedFootage: {
                'precoat': 40,
                'postcoat': 40,
                'dryer': 40,
                'cut': 40,
                'perforate': 40,
                'pageInsertion': 40,
                "folding": 56,
                "saddleStitch": 350,
                "stacker": 40,
                "perfectBind": 350,
                "rewinder": 40
            }
        },
    },

    siteFunctionDetails: [
        {
            id: 'volumePerMonth',
            type: 'number',
            required: true,
            component: 'PulloutQuestionNumber',
            label: 'Volume Monthly',
            default: 0,
            description: "pages per month",
            additionalInfo: (context) => {
                if(context.interview.questions.X119C){
                    if(context.interview.questions.X119C.answer.final){
                        let yearlyValue = parseInt(context.interview.questions.X119C.answer.final);
                        if(yearlyValue){
                            let monthlyValue = parseInt(yearlyValue / 12);
                            return `Site Survey indicated ${ monthlyValue.toLocaleString() } pages per month ( ${ yearlyValue.toLocaleString() } yearly )`
                        }

                    }
                }

                return ''
            },
        },
        {
            id: 'jobsPerMonth',
            type: 'number',
            required: true,
            component: 'PulloutQuestionNumber',
            label: 'Jobs Per Month',
            default: 0,
            validationInfo: {
                isValid: (value) => { return (value < 501) },
                warningMsg: "If total jobs per month exceeds 500, an MIS system is recommended.",
                overrideValue: undefined
            },
            description: "jobs per month"
        },

        {
            id: 'monochrome',
            type: 'boolean',
            required: true,
            component: 'PulloutQuestionSwitch',
            label: 'Monochrome Printing',
            default: false
        },

        {
            id: 'colorResolution',
            type: 'map',
            required: true,
            component: 'PulloutQuestionMultiSwitch',
            label: 'Color Resolution',
            default: {},
            options: [
                {
                    id: "colorResolutionLow",
                    label: 'Low Resolution Color',
                    summaryLabel: 'Low Resolution Color',
                    default: false
                },

                {
                    id: "colorResolutionMedium",
                    label: 'Medium Resolution Color',
                    summaryLabel: 'Medium Resolution Color',
                    default: false
                },

                {
                    id: "colorResolutionHigh",
                    label: 'High Resolution Color',
                    summaryLabel: 'High Resolution Color',
                    default: false
                }

            ]
        },

        {
            id: 'micrInk',
            type: 'boolean',
            required: true,
            component: 'PulloutQuestionSwitch',
            label: 'MiCR Ink',
            default: false
        },

        {
            id: 'precoat',
            type: 'boolean',
            required: true,
            component: 'PulloutQuestionSwitch',
            label: 'Precoat',
            default: false
        },

        {
            id: 'postcoat',
            type: 'boolean',
            required: true,
            component: 'PulloutQuestionSwitch',
            label: 'Post Coater',
            default: false
        },

        {
            id: 'dryer',
            type: 'boolean',
            required: true,
            component: 'PulloutQuestionSwitch',
            label: 'Dryer',
            default: false
        },

        {
            id: 'cut',
            type: 'boolean',
            required: true,
            component: 'PulloutQuestionSwitch',
            label: 'Cutter',
            default: false
        },

        {
            id: 'perforate',
            type: 'boolean',
            required: true,
            component: 'PulloutQuestionSwitch',
            label: 'Perforate',
            default: false
        },

        {
            id: 'pageInsertion',
            type: 'boolean',
            required: true,
            component: 'PulloutQuestionSwitch',
            label: 'Page Insertion',
            default: false
        },

        {
            id: 'folding',
            type: 'boolean',
            required: true,
            component: 'PulloutQuestionSwitch',
            label: 'Folding',
            default: false
        },

        {
            id: 'saddleStitch',
            type: 'boolean',
            required: true,
            component: 'PulloutQuestionSwitch',
            label: 'Saddle Stitching',
            default: false
        },

        {
            id: 'stacker',
            type: 'boolean',
            required: true,
            component: 'PulloutQuestionSwitch',
            label: 'Stacker',
            default: false
        },

        {
            id: 'perfectBind',
            type: 'boolean',
            required: true,
            component: 'PulloutQuestionSwitch',
            label: 'Perfect Bind',
            default: false
        },

        {
            id: 'rewinder',
            type: 'boolean',
            required: true,
            component: 'PulloutQuestionSwitch',
            label: 'Rewinder',
            default: false
        }

    ]
}

