
import printconfig from "@/plugins/printconfig";


export default {
    state:{
        sections:{
            'facilityProfile' :
                {
                    'id':'facilityProfile',
                    'label': 'Facility Profile',
                    'description': 'Please enter some basic information about this facility.',
                    'questionIds':[
                        'facilityName',
                        'facilityVertical',
                        'facilityContact',
                        'facilityAddress',
                        'facilityCity',
                        'facilityState',
                        'facilityPostal',
                        'facilityCountry',


                    ]

                },
            'siteSurvey' :
                {
                    'id':'siteSurvey',
                    'label': 'Site Survey',
                    'description': 'Please fill out the forms below from information provided by the Customer.',
                    'questionIds':[
                    ]

                },
            'siteFunctions' :
                {
                    'id':'siteFunctions',
                    'label': 'Site Functions',
                    'description': 'Select all site functions needed for this facility:',
                    'questionIds':[
                        'functionCutSheetMonochrome',
                        'functionCutSheetColor',
                        'wideFormat',
                        'continuousFeedInkJet',
                        'envelopePrinting',
                        'mailing',
                        'offlineFinishing'
                    ]
                },
            'siteServices' :
                {
                    'id':'siteServices',
                    'label': 'Other Site Services',
                    'description': 'Are there any other site services needed for this facility?',
                    'questionIds':[
                        'serviceScanning',
                        'serviceMailing',
                        'serviceOutgoingMail',
                        'serviceCopierFleet',
                        'serviceDesignDevelopment',
                        'serviceVDP',
                        'serviceColorManagement',
                        'serviceWebToPrintMIS',
                        'serviceParcelFreight',
                        'serviceCourier',
                        'serviceFullfillment',
                        'serviceOutsourcedPrint',
                        'serviceOther'


                    ]
                },
            'facilityItems':
                {
                    'id':'facilityItems',
                    'label': 'Facility Items',
                    'description': 'Are there any other items needed for this facility?',
                    'questionIds':[
                        'facilityReceptionPhone',
                        'facilityCustomerCounter',
                        'facilityLoadingDock',
                        'facilityFullfillmentStorage'

                    ]
                },
            'siteLevel' :
                {
                    'id':'siteLevel',
                    'label': 'Desired Site Level',
                    'description': 'Select the desired level:',
                    'questionIds':[]
                },
            'configurationSummary' :
                {
                    'id':'configurationSummary',
                    'label': 'Summary Export',
                    'description': null,
                    'questionIds':['summaryReport']
                }
        },
        sectionOrder: [
            'facilityProfile',
            'siteSurvey',
            'siteFunctions',
            'siteServices',
            'facilityItems',
            'configurationSummary'
        ],
        activeSectionId: 'facilityProfile',
        questions: printconfig.questions,
        answers: printconfig.defaultAnswers(printconfig.questions),
        interview: undefined,


    },
    getters: {


        sectionList: state => {
            return state.sectionOrder.map(function(sectionId){
                return {
                    id: sectionId,
                    label: state.sections[sectionId].label
                };
            })
        },

        defaultSection: state => {
            if(state.sectionOrder && state.sectionOrder.length){
                return state.sections[state.sectionOrder[0]];
            }else{
                throw "Store Error: sectionOrder is not defined correctly, default section cannot be defined";
            }
        },

        activeQuestionList: (state) => {
            if(state.sections[state.activeSectionId]){
                let section = state.sections[state.activeSectionId];

                let questions = section.questionIds.map(function(questionId){
                    return state.questions[questionId];
                })


                return questions;
            }
        },

        activeSection: state => {

            var section;
            if(state.activeSectionId){
                section = state.sections[state.activeSectionId];
            }else {
                section = state.getters.defaultSection;
            }

            return section;

        },

        nextSection: state => {
            var currentIndex = state.sectionOrder.indexOf(state.activeSectionId);
            if( currentIndex + 1 < state.sectionOrder.length){
                return state.sections[state.sectionOrder[currentIndex + 1]];
            }
        },

        previousSection: state => {
            var currentIndex = state.sectionOrder.indexOf(state.activeSectionId);
            if(currentIndex > 0){
                return state.sections[state.sectionOrder[currentIndex - 1]];
            }
        },
    },
    mutations: {
        activeSectionId (state, sectionId ) {
            if(state.sections[sectionId]){
                state.activeSectionId = sectionId
            }
        },


        setDetail (state, detailInfo){



            if(state.answers[detailInfo.questionId]){
                console.log("setDetail", detailInfo.questionId,  state.answers[detailInfo.questionId].details[detailInfo.detail.id], detailInfo.detail.value);

                state.answers[detailInfo.questionId].details[detailInfo.detail.id] = detailInfo.detail.value;


            }


        },

        clearCustomConfig (state, answerId){
            if(state.questions[answerId].configurator && state.questions[answerId].configurator.customConfiguration){
                state.questions[answerId].configurator.customConfiguration = undefined;
            }

            if(state.answers[answerId].details?.customConfiguration){
                state.answers[answerId].details.customConfiguration = undefined;
            }
        }

    },
    actions: {


        setActiveSectionId (context, sectionId) {
            if(context.state.sections[sectionId]){
                context.commit("activeSectionId",sectionId)
            }
        },

        gotoPreviousSection (context) {
            var currentIndex =  context.state.sectionOrder.indexOf(context.state.activeSectionId);
            if(currentIndex > 0){
                context.commit("activeSectionId",context.state.sectionOrder[currentIndex - 1]);
            }
        },

        gotoNextSection (context) {
            var currentIndex =  context.state.sectionOrder.indexOf(context.state.activeSectionId);
            if((currentIndex + 1) < context.state.sectionOrder.length){
                context.commit("activeSectionId",context.state.sectionOrder[currentIndex + 1]);
            }
        },


        updateDetail (context, detailInfo){
            //console.log("Action", detailInfo.questionId, detailInfo.detail );
            context.commit('setDetail', detailInfo);
        },

        clearDetails (context, answerId){
            console.log("clearDetails", context,answerId)
            var details = context.state.questions[answerId].details;
            details.forEach(function(detail){
                var detailInfo = {
                    questionId: answerId,
                    detail: {
                        id: detail.id,
                        value: detail.default
                    }
                }
                context.commit('setDetail', detailInfo);
            })


            context.commit("clearCustomConfig", answerId);



        },

    }
}
