


<template>
<div class="">
  <h4>Ricoh Hybrid Mail Services</h4>
  <hr>
  <p>Is a solution that is specifically designed for today’s modern hybrid working environment, allowing staff to submit documents from any location offering remote and secure transmission. Hybrid mail is the answer to all your ad hock mail concerns. Customers no longer need to rely on manual processes, local hardware and stationery or even be in the office. Now organizations can process inbound and outbound correspondence consistently, securely and without compromise. This is a completely customized solution that can reside both on-premises or in the cloud. </p>
  <ul>
    <li>Secure transmission</li>
    <li>No end user training is required</li>
    <li>Easy driver deployment</li>
    <li>No changes to the way employees and staff work</li>
    <li>Preview and job options</li>
    <li>Submit print jobs 24/7</li>
    <li>Supports workers at home</li>
    <li>Remote deployment</li>
  </ul>
</div>
</template>

<script>
export default {
    name: "ricoh-hybrid-mail",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


</style>
