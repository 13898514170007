<template>

      <div data-container="true">

        <table>

          <tr>
            <th colspan="4" style='color:#155e8f; text-align:left;'><h3 style="marginTop: 6pt;">Requirements</h3></th>

          </tr>
          <tr>
            <td colspan="5">
          <ul>

            <li class="group-heading list-item" v-for="(jobsBreakdown, index) in report.jobBreakdowns" :key="index">
                {{ jobsBreakdown.label }}: {{ (jobsBreakdown.monthly * 12) | decimal(0) }} jobs per year, {{ jobsBreakdown.volumeDesc }}

            </li>
          </ul>
            </td>
          </tr>

            <tr class="group-heading">
                <th colspan="4" style='color:#155e8f; text-align:left;'><h3 style="marginTop: 6pt;">Facility</h3></th>

            </tr>



            <tr>

                <td colspan="4">
                    <p>{{ report.footage | decimal(0) }} sq ft Building <span v-if="report.facilityItems.length">including:</span></p>
                    <ul v-if="report.facilityItems.length">
                        <li>{{ report.baseFootage | decimal(0) }} sq ft for Aisles/hallways, Lockers, Recycling/trash, Bathrooms, General supply storage</li>
                        <li v-for="facilityItem in report.facilityItems" :key="facilityItem.label">{{ facilityItem.description }}</li>
                    </ul>
                </td>
            </tr>




            <tr class="group-heading">
                <th colspan="4" style='color:#155e8f; text-align:left;'><h3 style="marginTop: 6pt;">Equipment</h3></th>


            </tr>

            <template v-for="(item, index) in report.equipment">
            <tr v-if="report.equipment" :key="'e'+index">
                <td valign="top">{{ item.quantity?item.quantity:1 | decimal(0) }}</td>
              <td valign="top"><span>{{ item.model.name }}</span> <span class="options-list" v-if="item.optionNames"> <span> w/{{ item.optionNames }}</span></span></td>
                <td valign="top" class="center-align">{{ item.shifts }} shift(s)</td>
                <td valign="top"><span v-if="item.model">up to {{ item.model.volumeAbbr }} {{ item.model.volumeSuffix }}</span></td>
            </tr>
            </template>
            <tr v-for="finishing in report.offlineFinishing" :key="finishing.description">
                <td valign="top">1</td>
                <td valign="top">{{ finishing.volumeLevel }} {{ finishing.name }}</td>
                <td valign="top" class="center-align">1 shift</td>
                <td valign="top">{{ finishing.description }}</td>
            </tr>
            <tr v-for="support in report.supportEquipment" :key="support.description">
                <td valign="top">{{ support.devices }}</td>
                <td valign="top" colspan="3">{{ support.description }}</td>
            </tr>




            <tr class="group-heading" v-if="report.services.length">
                <th colspan="2" style='color:#155e8f; text-align:left;'><h3 style="marginTop: 6pt;">Other Site Services</h3></th>

                <th style='color:#155e8f; text-align:left;'><h3 style="marginTop: 6pt;">Additional Equipment</h3></th>
                <th style='color:#155e8f; text-align:left;'><h3 style="marginTop: 6pt;">Software</h3></th>
            </tr>

            <tr v-for="service in report.services" :key="service.label">

                <td valign="top">{{ service.label }}</td>
                <td valign="top">{{ service.description }}</td>
                <td valign="top">{{ service.equipment }}</td>
                <td valign="top">{{ service.software }}</td>
            </tr>


            <tr class="group-heading">
                <th colspan="4" style='color:#155e8f; text-align:left;'><h3 style="marginTop: 6pt;">Production Staff</h3></th>
            </tr>

            <tr v-for="prodStaff in report.productionStaff" :key="prodStaff.description">
                <td valign="top">{{ prodStaff.employees.toFixed(1) }}</td>
                <td valign="top" colspan="3">{{ prodStaff.description }}</td>


            </tr>

            <tr class="group-heading">
                <th colspan="4" style='color:#155e8f; text-align:left;'><h3 style="marginTop: 6pt;">Management Staff</h3></th>


            </tr>

            <tr v-for="mgmtStaff in report.managementStaff" :key="mgmtStaff.description">
                <td valign="top">{{ mgmtStaff.employees }}</td>
                <td valign="top" colspan="3">{{ mgmtStaff.description }}</td>


            </tr>
        </table>
        <h2 class="mt-4">Staffing Profiles</h2>

        <div data-container="true" v-for="prodDesc in report.productionStaff" :key="prodDesc.profile.id">

            <p class="bold-label"><b><u><span style="text-align:left;">{{ prodDesc.description }}</span></u></b></p>

            <p>Qualifications:</p>
            <ul>
              <li v-for="(qual, index) in prodDesc.profile.qualifications" :key="index">{{ qual }}</li>
            </ul>
            <p>Skills:</p>
            <ul>
              <li v-for="(skill, index) in prodDesc.profile.skills" :key="index">{{ skill }}</li>
            </ul>


        </div>

        <div data-container="true" v-if="report.managementProfile">



            <p class="bold-label"><b><u><span style="text-align:left;">{{ report.managementProfile.title }}</span></u></b></p>

            <p>Qualifications:</p>
            <ul>
              <li v-for="(qual,index) in report.managementProfile.qualifications" :key="index">{{ qual }}</li>
            </ul>
            <p>Skills:</p>
            <ul>
              <li v-for="(skill,index) in report.managementProfile.skills" :key="index">{{ skill }}</li>
            </ul>


        </div>
      </div>

</template>

<script>
    //import FormQuestionLabel from './FormQuestionLabel.vue'
    //import SummaryReportGroupHeader from "./SummaryReportGroupHeader.vue";

    import printconfig from "@/plugins/printconfig";


    let staffProfiles = {
        sm: {
            id:"sm",
            title: "Supervisor/Manager",
            qualifications: [
              '2+ years of experience with managing print production operation.',
              '5+ years of experience in the graphic communications industry.',
              'Demonstrate a high level of computer skills including proficiency in equipment, accounting and MIS software.',
              'Experience in developing, managing, and evaluating staff members.',
              'Thorough understanding of purchasing and procuring supplies and services.'
            ],
            skills: [
              'Leadership and interpersonal skills to communicate effectively with a diverse group of people.',
              'Ability to set goals by defining and prioritizing specific, realistic objectives.',
              'Customer relation skills to effectively manage the customer experience.',
              'Knowledge of accounting methods -billing, invoicing, operating cost and profitability.',
              'Excellent presentation, written and oral communication skills to carry out sales and marketing initiatives.',
            ]
        },

        as: {
          id:"as",
          title: "Administrative Staff",
          qualifications: [
            '2+ years of experience in the graphic communications industry.',
            'Demonstrate a high level of computer skills including proficiency in MS Office suite and MIS software.',
            'Customer service skills and ability to work with diverse work groups.',
            'Requires effective interpersonal communication skills to effectively collaborate with coworkers.',
          ],
          skills: [
            'Accounting skills - billing, invoicing, accounts receivable, operating costs.',
            'Good organizational skills and ability to prioritize.',
            'Excellent written and verbal communication skills.',
            'Understanding of purchasing and procuring supplies and services.',
            'Understanding of parcel and mailing regulations.',
          ]
        },

      dt1: {
        id:"dt1",
        title: "Digital Output Technician I (B/W output - cut sheet)",
        qualifications: [
          'Requires 1-2 years of related work experience with operating digital copiers and other related equipment.',
          'Demonstrate competency in computer skills including proficiency in MS Office suite and equipment software.',
          'Requires effective interpersonal communication skills to effectively collaborate with coworkers.',
          'Requires troubleshooting and problem-solving skills.',
        ],
        skills: [
          'Ability to perform repair/service procedures including diagnostics, installation, removal and retrofits on a core set of highly complex production equipment.',
          'Possess mechanical/technical skills.',
          'Good organizational skills and ability to prioritize.',
          'Ability to read and comprehend written technical information in the form of manuals, parts books and related publications.',
          'Excellent customer relations skills and verbal communication skills.',
        ]
      },

      dt2: {
        id:"dt2",
        title: "Digital Output Technician II (B/W & color output - cut sheet)",
        qualifications: [
          'Requires 2-4 years of related work experience with operating digital copiers and other related equipment.',
            'Demonstrate a high level of computer skills and file formatting, including proficiency in advanced equipment software.',
            'Requires effective interpersonal communication skills to effectively collaborate with coworkers.',
            'Requires troubleshooting and problem-solving skills.',
          'Comprehensive understanding of color theory, color management and the ability to adjust color devices to match proofs.',
        ],
        skills: [
          'Ability to perform repair/service procedures including diagnostics, installation, removal and retrofits on a core set of highly complex production equipment.',
          'Possess mechanical/technical skills.',
          'Good organizational skills and ability to prioritize.',
          'Ability to read and comprehend written technical information in the form of manuals, parts books and related publications.',
          'Excellent customer relations skills and verbal communication skills.',
          'Understand the characteristics of paper and other printing substrates.',
        ]
      },

      cfs: {
        id:"cfs",
        title: "Continuous Feed Specialist (Inkjet CF printer w/inline finishing)",
        qualifications: [
          'Requires 2-4 years of related work experience.',
          'Demonstrate an advanced  level of computer skills and file formatting, including proficiency in data management and advanced equipment software.',
          'Requires effective interpersonal communication skills to effectively collaborate with coworkers.',
            'Requires troubleshooting and problem-solving skills.',
          'Comprehensive understanding of color theory, color management and the ability to adjust color devices to match proofs.',
        ],
        skills: [
          'Ability to perform repair/service procedures including diagnostics, installation, removal and retrofits on a core set of highly complex production equipment.',
          'Possess mechanical/technical skills.',
          'Good organizational skills and ability to prioritize.',
          'Ability to read and comprehend written technical information in the form of manuals, parts books and related publications.',
          'Excellent customer relations skills and verbal communication skills.',
          'Understand the characteristics of paper and other printing substrates.',
        ]
      },

      wf1: {
        id:"wf1",
        title: "Wide Format Specialist I (Roll output materials only)",
        qualifications: [
          'Requires 1-2 years of related work experience.',
          'Demonstrate a high level of computer skills and file formatting, including proficiency in advanced workflow software.',
          'Requires effective interpersonal communication skills to effectively collaborate with coworkers.',
            'Requires troubleshooting and problem-solving skills.',
          'Advanced understanding of color theory, color management and the ability to adjust color devices to match proofs.',
        ],
        skills: [
          'Ability to perform repair/service procedures including diagnostics, installation, removal and retrofits on a core set of highly complex production equipment.',
          'Ability to operate, adjust, and troubleshoot to resolve mechanical issues.',
          'Good organizational skills and ability to prioritize.',
          'Ability to read and comprehend written technical information in the form of manuals, parts books and related publications.',
          'Excellent customer relations skills and verbal communication skills.',
          'Understand the characteristics of flexible roll and rigid printing substrates and the associated printing processes.',
        ]
      },

      wf2: {
        id:"wf2",
        title: "Wide Format Specialist II (Roll & Rigid output materials)",
        qualifications: [
          'Requires 2-4 years of related work experience.',
          'Demonstrate a high level of computer skills and file formatting, including proficiency in advanced workflow software.',
          'Requires effective interpersonal communication skills to effectively collaborate with coworkers.',
          'Requires troubleshooting and problem-solving skills.',
          'Advanced understanding of color theory, color management and the ability to adjust color devices to match proofs.',
        ],
        skills: [
          'Ability to perform repair/service procedures including diagnostics, installation, removal and retrofits on a core set of highly complex production equipment.',
          'Possess mechanical/technical skills.',
          'Good organizational skills and ability to prioritize.',
          'Ability to read and comprehend written technical information in the form of manuals, parts books and related publications.',
          'Excellent customer relations skills and verbal communication skills.',
          'Understand the characteristics of flexible roll and rigid printing substrates and the associated printing processes.',
        ]
      },

      ft1: {
        id:"ft1",
        title: "Finishing Technician I (Basic machine & hand finishing)",
        qualifications: [
          'Requires 1-2 years of related work experience.',
          'Demonstrate competency in computer skills including proficiency in MS Office suite and equipment software.',
          'Requires effective interpersonal communication skills to effectively collaborate with coworkers.',
          'Requires troubleshooting and problem-solving skills.',
          'Ability to perform repetitive manual tasks.',
        ],
        skills: [
          'Ability to perform repair/service procedures including diagnostics, installation, removal and retrofits on a core set of highly complex production equipment.',
          'Possess mechanical/technical skills.',
          'Good organizational skills and ability to prioritize.',
          'Ability to read and comprehend written technical information in the form of manuals, parts books and related publications.',
          'Excellent customer relations skills and verbal communication skills.'
        ]
      },

      ft2: {
        id:"ft2",
        title: "Finishing Technician II (Basic & complex machine finishing, hand finishing)",
        qualifications: [
          'Requires 2-4 years of related work experience.',
          'Demonstrate an advanced level of computer skills and file formatting, including proficiency in advanced equipment software.',
          'Requires effective interpersonal communication skills to effectively collaborate with coworkers.',
            'Requires troubleshooting and problem-solving skills.',
          'Ability to perform repetitive manual tasks.',
        ],
        skills: [
          'Ability to perform repair/service procedures including diagnostics, installation, removal and retrofits on a core set of highly complex production equipment.',
          'Possess mechanical/technical skills.',
          'Good organizational skills and ability to prioritize.',
          'Ability to read and comprehend written technical information in the form of manuals, parts books and related publications.',
          'Excellent customer relations skills and verbal communication skills.',
        ]
      },

      da: {
        id:"da",
        title: "Distribution Associate & Other Services (Incoming mail/parcel & distribution, basic machine finishing & hand finishing)",
        qualifications: [
          'Requires 1-2 years of related work experience.',
          'May require valid driver’s license.',
          'Demonstrate competent computer skills in MS Office suite, equipment software and mail/shipping software.',
          'Requires effective interpersonal communication skills to effectively collaborate with coworkers.',
          'Requires troubleshooting and problem-solving skills.',
          'Ability to perform repetitive manual tasks.',
        ],
        skills: [
          'Requires knowledge and understanding of shipping/receiving procedures.',
          'Good organizational skills and ability to prioritize.',
          'Ability to work independently and possess the dexterity to complete projects.',
          'Excellent customer relations skills and verbal communication skills.',
          'Possess mechanical/technical skills.',
        ]
      },

      ddps: {
        id:"ddps",
        title: "Document Design & Prepress Specialist (Design, VDP,  file prep, color management, mail optimization)",
        qualifications: [
          '3+ years experience in a related field is required.',
          'Experience with graphic design software, Adobe products, Photoshop, Fusion Pro and related software.',
          'Experience in design, illustration and page layout.',
          'Experience in VDP and mail merge.',
        ],
        skills: [
          'Demonstrated creativity and ability to develop files for digital and printed output.',
          'Demonstrated ability to organize and prioritize multiple complex projects with firm deadlines.',
          'Excellent interpersonal skills to effectively interact with a diverse group of people in a team environment.',
          'Demonstrated ability to effectively lead a team for complex projects.',
          'Well-developed ability to ensure color accuracy across multiple software programs.',
          'Possesses troubleshooting and problem-solving skills.',
          'Excellent customer relations skills and verbal communication skills.',
        ]
      },





    }



    export default {
        name: "summary-report",
        components: {

        },
        methods: {
            exportReport: function(){
                var reportTable = this.$el.querySelector(".report-container").innerHTML;
                var link = document.createElement("a");
                //var mimeTypeHtml = 'text/html';
                var mimeTypeDoc = 'application/msword';
                link.setAttribute('download',"config-report.doc");
                link.setAttribute('href','data:' + mimeTypeDoc + ';charset=utf-8,'+encodeURIComponent(reportTable));
                link.click();
            },

            equipmentOptionList: function(item){
                return item.options;
            }
        },
        props: {
            configuration: {
              type: Object,
              required: true
            },
        },
        data: function(){
            return {
                tableStyle: {
                    fontFamily: "Helvetica, Arial, sans_serif"
                },

                headingStyle: {
                    color: "#155e8f",
                    fontWeight: "bold",
                    borderBottom:"solid windowtext 1.0pt",
                    msoBorderBottomAlt:"solid windowtext .5pt"
                },

                hrStyle: {
                    margin: 0
                }


            }

        },
        computed: {
            report: function(){
                //let summaries = [];
                let totals = {
                    footage: 0,
                    facilityItems: [],
                    equipment: [],
                    offlineFinishing: [],
                    services: [],
                    supportEquipment: [],
                    productionStaff: [],
                    managementStaff: [],
                    title: "",
                    customer: "",
                    jobsPerYear: 0,
                    jobBreakdowns:[], //array of { function names and totals }
                    staffingBreakdowns: { //by staffing codes

                        sm: 0,
                        as: 0,
                        dt1: 0,
                        dt2: 0,
                        cfs: 0,
                        wf1: 0,
                        wf2: 0,
                        ft1: 0,
                        ft2: 0,
                        da: 0,
                        ddps: 0


                      // ss: 0,
                      // ds: 0,
                      // op: 0,
                      // ts: 0,
                    },
                    managementProfile: undefined,
                    setupMinutes: 0,
                    wfSetupMinutes: 0,
                    nonWfSetupMinutes: 0,

                    equipmentMinutes: 0,
                    wfEquipmentMinutes: 0,
                  nonWfEquipmentMinutes: 0,
                    equipmentStaffing: 0

                }

                // var nonContinuousFeedImagingDevices1 = 0;
                // var nonContinuousFeedImagingDevices2 = 0;
                // var nonContinuousFeedImagingDevices3 = 0;
              // eslint-disable-next-line no-unused-vars
                let wideFormatDevices = 0;
              // eslint-disable-next-line no-unused-vars
                let continuousFeedDevices = 0;
                //let monochromeDevices = 0;
                let nonMonochromeDevices = 0;

                //
                //
                // var techOps1 = 0;
                // var techOps2 = 0;
                // var techOps3 = 0;
                //
                // var designSpecs = 0;

              // eslint-disable-next-line no-unused-vars
                let supportStaff = 0;
                var managers = 0;
                var supervisors = 0;
                var admin = 0;
                //var operationsSpecs = 0;

                var mfdDevices = 0;
                var workstations = 0;



                for(var questionId in this.questions){
                    let question = this.questions[questionId];

                    if(question.id == "facilityVertical"){
                        var vertical = this.configuration.answers["facilityVertical"].value;
                        if(!vertical || (vertical.toLowerCase() === "other")){
                            totals.title = "Site Requirements"
                        }else{
                            totals.title = (vertical.charAt(0).toUpperCase() + vertical.substring(1)) + " Site Requirements";
                        }
                    }

                    if(question.id == "facilityName"){
                        var customerName = this.configuration.answers["facilityName"].value;
                        if(customerName){
                            totals.customer = customerName
                        }else{
                            totals.customer = undefined;
                        }
                    }


                    /*
                    if(question.id == "facilityJobsPerYear"){
                        var jobs = parseInt(this.$store.getters.answerValue("facilityJobsPerYear"));
                        if(!isNaN(jobs)){
                            totals.jobsPerYear = jobs;
                            totals.setupMinutes = (jobs / 12.0) * 5;
                        }

                    }

                    */

                    if(question.configurator){
                        if(this.configuration.answers[question.id].value){
                            let summary = question.configurator.summary(this.configuration.answers[questionId].details);

                            //console.log(questionId,question.configurator.recommendation)
                            console.log("summary", summary, questionId);

                            if(summary){
                              if(summary.classification != "siteFunction" && summary.footprint){
                                totals.footage += summary.footprint;
                              }

                              if(summary.classification == "facilityItem"){

                                let staffingCode = summary.staffingCode;

                                if(staffingCode){
                                  totals.staffingBreakdowns[staffingCode] += summary.staffing;
                                }else{
                                  totals.staffingBreakdowns['da'] += summary.staffing;
                                }

                                totals.facilityItems.push({
                                  label: question.label,
                                  description: summary.description
                                });
                              }

                              if(summary.classification == "siteFunction"){

                                var models = [];
                                var equipmentShifts = 0;
                                console.log(this.configuration)

                                if(this.configuration.answers[questionId]?.details?.customConfiguration){
                                  models = this.configuration.answers[questionId].details.customConfiguration.models;
                                  console.log("Using custom configuration models", this.configuration.answers[questionId].details.customConfiguration.models)
                                }else{
                                  console.log("Using recommendation models")
                                  models = question.configurator.recommendation.models;
                                }

                                //console.log("report", models);

                                if(models.length > 0) {

                                  var totalNonContinousEquipmentSpeed = 0;


                                  for (var m = 0; m < models.length; m++) {

                                    var equipmentItem = models[m];
                                    equipmentShifts += parseFloat(equipmentItem.shifts);





                                    if (question.id == "continuousFeedInkJet") {

                                      continuousFeedDevices++;

                                    } else if (question.id == "wideFormat") {

                                      wideFormatDevices++;
                                      //totalNonContinousEquipmentSpeed += (equipmentItem.model["opSpeed"]?equipmentItem.model["opSpeed"]:(equipmentItem.model.ppm * 0.75));

                                    } else {

                                      if (question.id == "cutSheetMonochrome"){
                                        //monochromeDevices++;
                                      }else{
                                        nonMonochromeDevices++;
                                      }

                                      totalNonContinousEquipmentSpeed += (equipmentItem.model["opSpeed"]?equipmentItem.model["opSpeed"]:(equipmentItem.model.ppm * 0.75));

                                    }

                                    totals.footage += equipmentItem.footprint;

                                    //console.log("added equipment", equipmentItem);


                                  }

                                  var monthlyJobs = this.configuration.answers[question.id].details["jobsPerMonth"];

                                  var monthlyVolume = this.configuration.answers[question.id].details["volumePerMonth"];

                                  if(question.id === 'mailing'){
                                    monthlyJobs = summary.volumes.jobs;
                                    monthlyVolume = summary.volumes.total;
                                  }


                                  if(monthlyVolume && ((question.id != "continuousFeedInkJet") || (question.id != "wideFormat"))){
                                    if(models.length > 1){
                                      totalNonContinousEquipmentSpeed = totalNonContinousEquipmentSpeed * 0.9;
                                    }


                                  }



                                  //console.log("setupMinutes and jobs", totals.setupMinutes, monthlyJobs);


                                  if(monthlyJobs){

                                    if (question.id == "wideFormat") {


                                      totals.jobsPerYear += monthlyJobs;
                                      totals.setupMinutes += (monthlyJobs * 20.0);
                                      totals.wfSetupMinutes += (monthlyJobs * 20.0);

                                      console.log("WF Setup minutes", (monthlyJobs * 20.0))

                                      let wfCode = "wf1";

                                      models.forEach((wfModel) => {
                                        if(wfModel.options){
                                          let wf2 = Object.keys(wfModel.options).find((option) => {
                                            return (option.startsWith('rigid'))
                                          })

                                          if(wf2){
                                            wfCode = "wf2"
                                          }
                                        }
                                      })

                                      //console.log(wfCode, models);

                                      if(models.length < 4){
                                        totals.staffingBreakdowns[wfCode] += 1;
                                      }else{
                                        totals.staffingBreakdowns[wfCode] += (models.length - 1);
                                      }

                                      //console.log("wide format jobs", totals.setupMinutes, monthlyJobs);


                                    }else if (question.id == "continuousFeedInkJet"){
                                      totals.jobsPerYear += monthlyJobs;
                                      totals.setupMinutes += (monthlyJobs * 3.0);




                                      totals.staffingBreakdowns.cfs += models.length;
                                      totals.staffingBreakdowns.da += (models.length * 0.25);

                                      //console.log("continous feed jobs", totals.setupMinutes, monthlyJobs);


                                    }else if (question.id == "mailing"){


                                      //totals.jobsPerYear += monthlyJobs;
                                      //totals.setupMinutes += (monthlyJobs * 3.0);

                                      //monthlyJobs = 100000;




                                      totals.staffingBreakdowns.ft2 += models.length;
                                      //console.log("continous feed jobs", totals.setupMinutes, monthlyJobs);


                                    }else{
                                      totals.jobsPerYear += monthlyJobs;
                                      totals.setupMinutes += (monthlyJobs * 5.0);

                                      totals.nonWfSetupMinutes += (monthlyJobs * 5.0);

                                      console.log("Cut sheet Setup minutes", (monthlyJobs * 5.0))

                                      //console.log("all other job types", totals.setupMinutes, monthlyJobs);
                                    }

                                    totals.jobBreakdowns.push({
                                      label: question.label,
                                      monthly: monthlyJobs,
                                      volumeDesc: summary.description
                                    })
                                  }

                                  //console.log("setupMinutes", totals.setupMinutes, totals.jobBreakdowns);


                                  var shiftAdjustment = (equipmentShifts * 1.0 / models.length);

                                  var runMinutes = 0;

                                  if(totalNonContinousEquipmentSpeed){
                                    runMinutes = (monthlyVolume / (totalNonContinousEquipmentSpeed * 1.0)) * shiftAdjustment;
                                  }

                                  console.log("total Jobs per year",totals.jobsPerYear, totals.jobBreakdowns)
                                  console.log("monthlyVolume",monthlyVolume, totalNonContinousEquipmentSpeed)
                                  console.log("setupMinutes",totals.setupMinutes)
                                  console.log("traditional run minutes", runMinutes, totalNonContinousEquipmentSpeed, shiftAdjustment);


                                  if(!isNaN(runMinutes)){
                                    totals.equipmentMinutes += runMinutes;
                                  }
                                }

                                totals.equipment = totals.equipment.concat(models);


                                /*
                                totals.equipment.push({
                                    models: models,
                                    description: summary.description,
                                    requirements: summary.requirements,
                                    devices: models.length,
                                    label: question.label
                                    //shifts: equipmentItem.shifts
                                });
                                */
                              }

                              if(summary.classification == "offlineFinishing"){







                                summary.requirements.forEach(function(offlineFinishingItem){

                                  //console.log(offlineFinishingItem);

                                  supportStaff += offlineFinishingItem.staffing;

                                  let staffingCode = offlineFinishingItem.staffingCode;

                                  if(staffingCode){
                                    //console.log("adding staffing code", staffingCode);
                                    totals.staffingBreakdowns[staffingCode] += offlineFinishingItem.staffing;
                                  }else{
                                    totals.staffingBreakdowns['ft1'] += offlineFinishingItem.staffing;
                                  }

                                  totals.offlineFinishing.push({
                                    name: offlineFinishingItem.summaryLabel,
                                    volumeLevel: offlineFinishingItem.volumeLabel,
                                    description: offlineFinishingItem.description,
                                    equipment: offlineFinishingItem.equipment,
                                    devices: summary.numOfModels,
                                    shifts: summary.numOfShifts
                                  });
                                })


                              }

                              if(summary.classification == "siteService"){

                                /*
                                if((question.id == "serviceDesignDevelopment") || (question.id == "serviceVDP")){
                                    designSpecs += summary.staffing;
                                }else{
                                    techOps1 += summary.staffing;
                                }

                                */

                                let staffingCode = summary.staffingCode;

                                if(staffingCode){
                                  totals.staffingBreakdowns[staffingCode] += summary.staffing;
                                }else{
                                  totals.staffingBreakdowns['da'] += summary.staffing;
                                }

                                //console.log("service summary", summary);


                                totals.services.push({
                                  description: summary.description,
                                  label: question.label,
                                  additionalItems: summary.requirements,
                                  equipment: summary.equipment,
                                  software: summary.software
                                });
                              }
                            }





                        }

                    }
                }

                //console.log(nonContinuousFeedImagingDevices1,nonContinuousFeedImagingDevices2,nonContinuousFeedImagingDevices3);

                //techOps1 += Math.ceil(nonContinuousFeedImagingDevices1 / 3);
                //techOps2 += Math.ceil(nonContinuousFeedImagingDevices2 / 3);
                //techOps3 += Math.ceil(nonContinuousFeedImagingDevices3 / 3);

                //var techOpsTotal = techOps1 + techOps2 + techOps3;
                var techOpsForEquipment = 0;


                techOpsForEquipment = (totals.nonWfSetupMinutes + totals.equipmentMinutes)/8190.0;

                console.log("tech ops",totals.nonWfSetupMinutes, totals.equipmentMinutes, techOpsForEquipment)

                if(nonMonochromeDevices){
                  totals.staffingBreakdowns.dt2 += techOpsForEquipment;
                }else{
                  totals.staffingBreakdowns.dt1 += techOpsForEquipment;
                }



                //totals.staffingBreakdowns.ts += techOpsForEquipment;

                //console.log("labor", totals.setupMinutes, totals.equipmentMinutes, techOpsForEquipment, totals.staffingBreakdowns);




                var productionEmployees = totals.staffingBreakdowns.dt1 +
                    totals.staffingBreakdowns.dt2 +
                    totals.staffingBreakdowns.cfs +
                    totals.staffingBreakdowns.wf1 +
                    totals.staffingBreakdowns.wf2 +
                    totals.staffingBreakdowns.ft1 +
                    totals.staffingBreakdowns.ft1 +
                    totals.staffingBreakdowns.da +
                    totals.staffingBreakdowns.ddps;

                if(totals.staffingBreakdowns.dt1 > 0) {
                    totals.productionStaff.push({
                        employees: totals.staffingBreakdowns.dt1,
                        description: staffProfiles.dt1.title,
                        profile: staffProfiles.dt1
                    })
                }

                if(totals.staffingBreakdowns.dt2 > 0){
                    totals.productionStaff.push({
                        employees: totals.staffingBreakdowns.dt2,
                        description: staffProfiles.dt2.title,
                        profile: staffProfiles.dt2
                    })
                }

                if(totals.staffingBreakdowns.cfs > 0){
                    totals.productionStaff.push({
                        employees: totals.staffingBreakdowns.cfs,
                        description: staffProfiles.cfs.title,
                        profile: staffProfiles.cfs
                    })
                }

              if(totals.staffingBreakdowns.ft1 > 0){
                totals.productionStaff.push({
                  employees: totals.staffingBreakdowns.ft1,
                  description: staffProfiles.ft1.title,
                  profile: staffProfiles.ft1
                })
              }

              if(totals.staffingBreakdowns.ft2 > 0){
                totals.productionStaff.push({
                  employees: totals.staffingBreakdowns.ft2,
                  description: staffProfiles.ft2.title,
                  profile: staffProfiles.ft2
                })
              }

              if(totals.staffingBreakdowns.wf1 > 0){
                totals.productionStaff.push({
                  employees: totals.staffingBreakdowns.wf1,
                  description: staffProfiles.wf1.title,
                  profile: staffProfiles.wf1
                })
              }

              if(totals.staffingBreakdowns.wf2 > 0){
                totals.productionStaff.push({
                  employees: totals.staffingBreakdowns.wf2,
                  description: staffProfiles.wf2.title,
                  profile: staffProfiles.wf2
                })
              }



                if(totals.staffingBreakdowns.da > 0){
                    totals.productionStaff.push({
                        employees: totals.staffingBreakdowns.da,
                        description: staffProfiles.da.title,
                        profile: staffProfiles.da
                    })
                }

              if(totals.staffingBreakdowns.ddps > 0){
                totals.productionStaff.push({
                  employees: totals.staffingBreakdowns.ddps,
                  description: staffProfiles.ddps.title,
                  profile: staffProfiles.ddps
                })
              }

              let managerTitle = "";

                if(productionEmployees > 12){
                    managers = 1;
                    supervisors = 1;
                    admin = 1;
                    totals.footage += (100 + 64 + 128);
                    managerTitle = "Chief Site Manager"
                }

                if((productionEmployees > 6) && (productionEmployees < 13)){
                    managers = 1;
                    supervisors = 0;
                    admin = 1;
                    totals.footage += (100 + 0 + 64);
                  managerTitle = "Senior Site Manager"
                }

                if((productionEmployees > 2) && (productionEmployees < 7)){
                    managers = 1;
                    supervisors = 0;
                    admin = 0;
                    totals.footage += (100);
                    managerTitle = "Junior Site Manager"
                }

                if((productionEmployees > 0) && (productionEmployees < 4)){
                    managers = 0;
                    supervisors = 0.5;
                    admin = 0;
                    totals.footage += (64);
                }


                if(managers){


                    totals.managementStaff.push({
                        employees: managers,
                        description: managerTitle,
                        profile: staffProfiles.sm
                    })

                    totals.managementProfile = staffProfiles.sm;
                }

                if(supervisors){
                    totals.managementStaff.push({
                        employees: supervisors,
                        description: "Supervisor Staff",
                        profile: staffProfiles.sm
                    })

                    totals.managementProfile = staffProfiles.sm;
                }

                if(admin){
                    totals.managementStaff.push({
                        employees: admin,
                        description: "Administrative Staff",
                        profile: staffProfiles.as
                    })

                    totals.managementProfile = staffProfiles.as;
                }

                //facility space additions

                if(productionEmployees > 14){
                    totals.footage += 5000;
                    totals.baseFootage = 5000;
                }else if(productionEmployees > 6){
                    totals.footage += 2500;
                    totals.baseFootage = 2500;
                }else if(productionEmployees > 0){
                    totals.footage += 1000;
                    totals.baseFootage = 1000;
                }

                //business support additions

                if(productionEmployees > 12){
                    totals.footage += 50;
                    mfdDevices = 2;

                }else if(productionEmployees > 3){
                    totals.footage += 25;
                    mfdDevices = 1;

                }

                workstations = Math.ceil((productionEmployees-totals.staffingBreakdowns.ds) + managers + supervisors + admin );
                //console.log(designSpecs, techOps1, managers, supervisors, admin, supportStaff);

                if(workstations > 0){
                    totals.supportEquipment.push({
                        devices: workstations,
                        description: "Admin/Production Workstation Computer(s)"
                    })
                }

                if(mfdDevices > 0){
                    totals.supportEquipment.push({
                        devices: mfdDevices,
                        description: "MFD Device(s)"
                    })
                }

                //console.log("totals", totals.equipment)


                return totals;
            },
          questions: function(){
              return printconfig.questions;
          }

        }
    }
</script>

<style scoped>


    .summary-report {
        position: relative;
      padding-top: 0;

    }

    .summary-table {
        position: relative;
        width: 100%;
    }

    .inner-table {
        position: relative;
        width: 100%;
    }

    .equipment-item-col {
        padding-right: 1em;
    }

    .service-additional-item {
        display: block;

    }

    th {
        text-align: left;

        padding: 5px;
        vertical-align: bottom;

        font-size: 14px;
        padding-top: 1em;

    }

    tr {
        position: relative;
      border-bottom: none;
    }

    tr:first-child {
        padding-top: 0;
    }

    td {
        vertical-align: top;
        padding: 5px;
        color: black;
        font-size: 14px;
    }

    td.inner-table-container {
        padding: 0;
    }

    .col-bullet:before {
        content: "+";
        padding-right: 0.5em;
    }

    .col-bullet.bullet-dashed:before {
        content: "-";
        display: block;
        position: absolute;
        margin-left: -0.7em;

    }

    .col-bullet {
        display: block;
        margin-left: 0.7em;
        margin-bottom: 0.3em;

    }

    .center-align {
        text-align: center;
    }

    .table-heading {

    }

    .equipment-heading {
        display: block;
        font-weight: bold;
        border-bottom: 1px solid black;
        padding: 0.2em 0.5em;
        background-color: var(--near-black-2);
        color: var(--near-white-1);
    }

    .equipment-subheading {

        font-weight: normal;
        font-size: 0.8em;

    }

    .inner-table tr td:nth-child(2) {
        width: 30%;
    }

    .inner-table tr td:nth-child(4) {
        width: 50%;
    }

    tr.group-heading td {
        text-align: left;
        vertical-align: bottom;
        padding: 5px;
        font-size: 14px;

        font-weight: bold;
        color: black;
    }

    tr.group-heading.list-item td {
        padding-top: 0.5em;
    }

    .options-list .option-item:before {
        content: ', ';
    }

    .options-list .option-item:first-child:before {
        content: '';
    }
    .export-button {
        position: absolute;
        right: 0em;
        top: 0em;
        z-index: 1000;
        font-size: 0.8em;
    }

    tr p.list-header {
        padding-bottom: 0.5em;
    }

    ul {
      list-style: disc;
      padding-left: 2em;
      margin-block-start: 1em;
      margin-block-end: 1em;
    }

    li {
      margin: 0;
    }




</style>
