<template>
    <div :class="classObject">
        <div v-if="checked" class="active-mark"></div>
    </div>
</template>

<script>
    export default {
        name: "form-radio-dot",
        props: {
            checked: {
                type: Boolean,
                required: false,
                default: false
            }
        },

        computed: {
            classObject: function(){
                return {
                    "form-radio-dot": true,
                    "checked": this.checked
                }
            }
        },

        methods: {

        },

        created() {

        }
    }
</script>

<style scoped>

    .form-radio-dot {
        display: flex;
      justify-content: center;
      align-items: center;
        width: 2em;
        height: 2em;
        border: 1px solid var(--mid-gray);
        border-radius: 100%;
        position: relative;
        background-color: white;
        cursor: pointer;
    }

    .form-radio-dot.checked {
        border-color: var(--near-black-2);
    }

    .active-mark {

        background-color: var(--primary-color);
        border-radius: 100%;
        width: 50%;
        height: 50%;
    }

</style>
