<template>
    <label class="form-question-label">
        <slot></slot>
    </label>
</template>

<script>
    export default {
        name: "form-question-label"
    }
</script>

<style scoped>


    .form-question-label {

        color: var(--dark-gray);

    }

    .form-question-label.dark {
        color: var(--near-black-2);
    }

</style>
