<template>
    <div class="pullout-question-rated-list">
        <div class="question-line">
            <form-question-label class="question-label" >{{ detail.label }}</form-question-label>
            <form-rating-bar :level="answers[detail.id]" @expand="onExpand" @change="onChangeValue"></form-rating-bar>
        </div>
        <div class="answer-description" v-if="(!expanded && answerDescription)">{{ answerDescription }}</div>
        <div class="box-container" v-if="expanded">
            <pullout-question-multi-switch-list-item class="switch-box" v-for="option in options" :key="option.id" :label="option.label" :description="option.description" :checked="(option.value == answers[detail.id])" @click.native="onChangeValue(option.value)"></pullout-question-multi-switch-list-item>
        </div>
    </div>
</template>

<script>

    import FormQuestionLabel from "./FormQuestionLabel.vue";
    import PulloutQuestionMultiSwitchListItem from "./PulloutQuestionMultiSwitchListItem.vue";
    import FormRatingBar from "./FormRatingBar.vue";


    export default {
        name: "pullout-question-rated-list",
        components: {
            PulloutQuestionMultiSwitchListItem,
            FormQuestionLabel,
            FormRatingBar
        },

        data: function(){
            return {
                expanded: false
            }
        },
        props: {
            detail: {
                type: Object,
                required: true
            },

            answers: {
                required: true,
            }
        },

        computed: {
            options: function(){
                /* detail options needs to be an array of options:
                [
                    {
                    id: "optionId",
                    label: "Option Label" ,
                    default: false
                    },
                    ...
                 ]

                 */
                return this.detail.options;
            },

            answerDescription: function(){
                if(this.detail.options[this.answers[this.detail.id]]['description']){
                    return this.detail.options[this.answers[this.detail.id]]['description'];
                }

                return ""
            }
        },

        methods: {
            onChangeValue: function(value){

                console.log("MultiSwitch Click", value);

                this.answers[this.detail.id] = value;

                // let update = {
                //     id: this.detail.id,
                //     value: value
                // }
                //
                //
                // this.$emit('change', update);
            },

            onExpand: function(value){
                this.expanded = value;
            }


        }
    }
</script>

<style scoped>


    .pullout-question-rated-list {

    }

    .question-line {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .box-container {


    }


    .switch-box {
        box-sizing: border-box;
        border-top: 1px solid var(--mid-gray);
    }

    .switch-box:first-child {
        border:none;
    }

    .answer-description {
        margin: 1em 0;
        margin-bottom: 0.5em;
        padding: 0.5em 1em;
        border: 1px dashed var(--mid-gray);
    }


</style>
