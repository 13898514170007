<template>
    <div class="pullout-question-multi-switch">
        <form-question-label class="question-label" >{{ detail.label }}</form-question-label>
        <div class="box-container">
            <pullout-question-multi-switch-box class="switch-box" v-for="option in options" :key="option.id" :label="option.label" :checked="answers[detail.id][option.id]" @click.native="onChangeValue(option.id)"></pullout-question-multi-switch-box>
        </div>
    </div>
</template>

<script>

    import FormQuestionLabel from "./FormQuestionLabel.vue";
    import PulloutQuestionMultiSwitchBox from "./PulloutQuestionMultiSwitchBox.vue";


    export default {
        name: "pullout-question-multi-switch",
        components: {
            PulloutQuestionMultiSwitchBox,
            FormQuestionLabel
        },
        props: {
            detail: {
                type: Object,
                required: true
            },

            answers: {
                required: true,
            }
        },

        computed: {
            options: function(){
                /* detail options needs to be an array of options:
                [
                    {
                    id: "optionId",
                    label: "Option Label" ,
                    default: false
                    },
                    ...
                 ]

                 */
                return this.detail.options;
            }
        },

        methods: {
            onChangeValue: function(optionId){

              let answerOptions = this.answers[this.detail.id];
              this.options.forEach((option)=> {
                    if(option.id == optionId ){

                      console.log("changing answer");
                      answerOptions[optionId] = !answerOptions[optionId];
                    }else {
                      answerOptions[option.id] = false;
                    }
                })


                //console.log("MultiSwitch Click");

                // var newAnswer = {};
                // this.options.forEach((option)=> {
                //     if(option.id == value.id ){
                //         newAnswer[option.id] = this.answer[option.id];
                //     }else {
                //         newAnswer[option.id] = false;
                //     }
                // })
                //
                // newAnswer[value.id] = !newAnswer[value.id];
                //
                //
                // let update = {
                //     id: this.detail.id,
                //     value: newAnswer
                // }
                //
                //
                // this.$emit('change', update);
            }


        }
    }
</script>

<style scoped>


    .pullout-question-multi-switch {

    }

    .box-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 0.2em;
        grid-row-gap: 0.2em;
        padding: 1em 0;

    }


    .switch-box {
        box-sizing: border-box;
        min-height: 6em;

    }


</style>
