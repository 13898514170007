


<template>
  <div v-if="r" id="report">

    <section class="">
      <table class="titlebox absolute text-white" style="font-size: 1vw; left: 11em; width:28em; margin-top: 11em;">
        <tr><td class="font-bold" style="font-size: 2.5em; padding-bottom: 0.5em;" data-cell-style="TitlePageTitleCell">FINDINGS REPORT</td></tr>
        <tr><td class="font-bold leading-none" style="font-size: 2em; padding-bottom: 1em;" data-cell-style="TitlePageSubtitleCell">Marketing Automation Review</td></tr>
        <tr><td class="leading-snug" style="font-size: 1.2em;" data-cell-style="TitlePageCell">Confidential: For the exclusive use of Ricoh and <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span>.  This document cannot be shared with any other parties without written permission from all of the parties referenced here.</td>
        </tr>
        <tr><td style="padding-bottom: 2em;">  </td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell">V1.0</td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell"><span>{{ r.userProfile.firstname }} {{ r.userProfile.lastname }}</span></td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell"><span>{{ r.userProfile.title }}</span></td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell"><span>{{ currentDate }}</span></td></tr>
      </table>

      <img class="w-full -mx-8 block" data-float="true" data-ratio="1.2941" src="/reports/title-bkg.png" />



      <p></p>



    </section>

    <section>
      <p class="font-bold no-mb">Prepared for:</p>
      <p class="font-bold no-mb "><span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> - <span  @click="onSpanClick">{{ r.customerProfile.customerContact }}</span></p>
      <p class="no-mb"><span  @click="onSpanClick">{{ r.customerProfile.address }}</span></p>
      <p class="no-mb"><span  @click="onSpanClick">{{ r.customerProfile.city }}</span>, <span  @click="onSpanClick">{{ r.customerProfile.state }}</span> <span  @click="onSpanClick">{{ r.customerProfile.postalcode }}</span></p>
      <p class="mt-8 no-mb font-bold">Prepared by:</p>
      <p class="no-mb"><span>{{ r.userProfile.firstname }}</span> <span>{{ r.userProfile.lastname }}</span></p>
      <p class="no-mb" v-if="r.userProfile.organization"><span>{{ r.userProfile.organization }}</span></p>
      <p class="no-mb"><span>{{ r.userProfile.title }}</span></p>
      <p class="no-mb mt-8"><span>{{ currentDate }}</span></p>
    </section>
    <section>
      <p class="toc-title">TABLE OF CONTENTS</p>
      <p data-toc="true" data-alias="Table of Contents" data-levels="1-3">Table of Contents here.</p>
    </section>
    <section v-if="true">
      <h1>Overview</h1>
      <h2>Background</h2>

      <p>Many campaign teams are operating their demand and lead generation programs at suboptimal performance levels due to the complexity of operating large marketing automation platforms (MAP), misalignment of marketing and sales expectations and cross functional commitment to lead processes, conversion and tracking.  Additionally, marketers may also measure performance based on lead volume, cost per lead or other tactical metrics rather than contribution to business growth and attributable sold revenue.  Marketing teams should conduct a marketing automation assessment to:</p>
      <ul class="list-disc">
        <li><b>Optimize Efficiency:</b> Identify and address inefficiencies in current marketing processes.</li>
        <li><b>Enhance Lead Management:</b> Refine lead scoring and nurturing processes for better lead conversion.</li>
        <li><b>Drive more directly attributable revenue:</b> Generate more sales from campaign and contribute a higher percentage of overall sales.</li>
        <li><b>Improve ROI:</b> Ensure that the marketing automation platform is used effectively to maximize return on investment.</li>
        <li><b>Ensure Integration:</b> Verify seamless integration with other tools and systems for smooth operations.</li>
        <li><b>Stay Competitive:</b> Stay updated with the latest features and best practices in marketing automation to maintain a competitive edge.</li>
      </ul>
      <p>With insights from a Ricoh MAP Assessment, teams can streamline their marketing efforts, align sales and marketing teams, and ultimately achieve better business outcomes. This MAP Assessment includes a full survey of {{ r.customerProfile.organizationName }}’s marketing and campaign operations, a scorecard of critical category ratings (by leadership owner/sponsor and cross functional team participants) and observations gathered by our marketing consultants during interviews and conversations with key marketing team members.  This finding documents the current state of {{ r.customerProfile.organizationName }}’s marketing operations against industry best practices and provides recommendations to streamline marketing operations and optimize and improve performance.</p>

      <h2>Methodology</h2>
      <p><span>{{ r.userProfile.firstname }}</span> <span>{{ r.userProfile.lastname }}</span>, Ricoh’s <span>{{ r.userProfile.title }}</span> visited with <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> on <span  @click="onSpanClick">{{ r.customerProfile.assessmentDate }}</span> to review the organization’s Marketing Automation platform capabilities and processes to provide insights and feedback on immediate issues, roadmap updates, and best practices. This assessment covers areas such as admin set-up, user roles, communication limits, branding domains, email and landing page settings, tracking, CRM sync, lead database health, lead engagement, program templates, lead scoring, nurture campaigns, and more. Deliverables include audit findings documentation, prioritized recommendations, and a review session.</p>
      <p>Marketing automation capabilities and processes reviewed:</p>
      <ul class="list-disc">
        <li>Goals and Objectives</li>
        <li>Strategy and Planning</li>
        <li>Team Adoption and Support</li>
        <li>Data Management</li>
        <li>Campaign Execution</li>
        <li>Customer Segmentation and Personalization</li>
        <li>Lead Generation and Nurturing</li>
        <li>Workflow Automation</li>
        <li>Analytics and Reporting</li>
        <li>Integration with CRM and other Systems</li>
      </ul>

      <h2>Site</h2>
      <p class="no-mb"><span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> - <span  @click="onSpanClick">{{ r.customerProfile.customerContact }}</span></p>
      <p class="no-mb"><span  @click="onSpanClick">{{ r.customerProfile.address }}</span></p>
      <p class="no-mb"><span  @click="onSpanClick">{{ r.customerProfile.city }}</span>, <span  @click="onSpanClick">{{ r.customerProfile.state }}</span> <span  @click="onSpanClick">{{ r.customerProfile.postalcode }}</span></p>

      <h2>Departments Reviewed</h2>
      <table>
        <tr>
          <th>No.</th><th class="text-left">Departments/Area</th>
        </tr>
        <tr>
          <td>1</td><td>Executive Management (CXO/CMO/CEO)</td>
        </tr>
        <tr>
          <td>2</td><td>Sales/Business Development</td>
        </tr>
        <tr>
          <td>3</td><td>Marketing</td>
        </tr>
        <tr>
          <td>4</td><td>Customer Service</td>
        </tr>
        <tr>
          <td>5</td><td>Legal/Compliance</td>
        </tr>
        <tr>
          <td>6</td><td>IT</td>
        </tr>
        <tr>
          <td>7</td><td>Operations/Output Services</td>
        </tr>
        <tr>
          <td>8</td><td>Department/Business Unit Head(s)</td>
        </tr>
      </table>

    </section>
    <section v-if="true">
      <h1>Current State</h1>
      <h2>Organization</h2>
      <p><span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span>’s Marketing automation platform manages a wide range of formats including campaign and advertising materials, brochures, sales collateral, external communications materials and digital assets. Various teams develop <span title="X3401.final" @click="onSpanClick">{{ q.X3401.answer.final | decimal(0) }}</span> campaigns annually. These campaigns produce approximately <span title="X109.final" @click="onSpanClick">{{ q.X109.answer.final | decimal(0) }}</span> direct mail and <span title="X110.final" @click="onSpanClick">{{ q.X110.answer.final | decimal(0) }}</span> digital outbound touches monthly. There are a total of <span title="X3225.final" @click="onSpanClick">{{ q.X3225.answer.final | decimal(1) }}</span> employees contributing to the campaign process.</p>

      <h3 class="mt-8">Business Goals and Marketing Objectives</h3>
      <p class="">The marketing team’s goals and objectives are <span title="X120.notes" @click="onSpanClick">{{ q.X120.notes }}</span> aligned with the organizations strategic and revenue goals:</p>
      <ul class="">
        <li class="ml-4">Marketing priorities and objectives: <span title="X121.notes" @click="onSpanClick">{{ q.X121.notes }}</span></li>
        <li class="ml-4">Sales targets: <span title="X122.notes" @click="onSpanClick">{{ q.X122.notes }}</span></li>
        <li class="ml-4">Key performance indicators (KPIs): <span title="X123.notes" @click="onSpanClick">{{ q.X123.notes }}</span></li>
      </ul>
      <p class="font-bold ml-4">Performance goals and measurement:</p>
      <ul>
        <li class="ml-4">Marketing Qualified Lead volume (MQL): <span title="X124.notes" @click="onSpanClick">{{ q.X124.notes }}</span></li>
        <li class="ml-4">Sales Qualified Lead (SQL) Volume: <span title="X125.notes" @click="onSpanClick">{{ q.X125.notes }}</span></li>
        <li class="ml-4">Cost per lead: <span title="X126.notes" @click="onSpanClick">{{ q.X126.notes }}</span></li>
        <li class="ml-4">Sold revenue attributable to Marketing Leads: <span title="X127.notes" @click="onSpanClick">{{ q.X127.notes }}</span></li>
      </ul>

      <p class="scoreframe mt-2">The <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> team Scorecard rating for Strategic Use of Campaign ROI/Directly Attributable Sales was <span title="S009" class="font-bold" @click="onSpanClick">{{ reportData.survey.questions.S009.answer.pre }}</span> <span title="S009" @click="onSpanClick" v-if="reportData.survey.questions.S009.answer.pre !== 'NA'">({{ scoreRating(reportData.survey.questions.S009.answer.pre) }})</span>. </p>

      <h2>Software and Platforms</h2>
      <p>Software and Platforms to support creation, distribution and management of marketing assets and campaigns.</p>

      <ul>
        <li>
          <b>Creative Software:</b><br />
          <span title="X221.notes" @click="onSpanClick">{{ q.X221.notes }}</span>
        </li>
        <li>
          <b>Project or Work Management Platforms: </b><br />
          <span title="X222.notes" @click="onSpanClick">{{ q.X222.notes }}</span>
        </li>
        <li>
          <b>Marketing Automation Platforms:</b><br />
          <span title="X223.notes" @click="onSpanClick">{{ q.X223.notes }}</span>
        </li>
        <li>
          <b>Customer Relationship Management Platforms:</b><br />
          <span title="X224.notes" @click="onSpanClick">{{ q.X224.notes }}</span>
        </li>
        <li>
          <b>Digital Asset Management Platforms:</b><br />
          <span title="X225.notes" @click="onSpanClick">{{ q.X225.notes }}</span>
        </li>
        <li>
          <b>Sales Enablement or Marketing Asset Distribution Platforms:</b><br />
          <span title="X226.notes" @click="onSpanClick">{{ q.X226.notes }}</span>
        </li>
        <li>
          <b>Website Authoring and Content Management:</b><br />
          <span title="X227.notes" @click="onSpanClick">{{ q.X227.notes }}</span>
        </li>
      </ul>

      <p class="bold-label">Integration: </p>
      <p class="">The marketing automation platform is integrated with these platforms: <span title="X228.notes" @click="onSpanClick">{{ q.X228.notes }}.</span></p>
      <p>The marketing automation platform provides integration via <span title="X229.notes" @click="onSpanClick">{{ q.X229.notes }}.</span></p>
      <p class="scoreframe mt-2">The <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> team Scorecard rating for Integration was <span title="S012" class="font-bold" @click="onSpanClick">{{ reportData.survey.questions.S012.answer.pre }}</span> <span title="S012" @click="onSpanClick" v-if="reportData.survey.questions.S012.answer.pre !== 'NA'">({{ scoreRating(reportData.survey.questions.S012.answer.pre) }})</span>. </p>

      <h2>Marketing Automation Platform and Processes</h2>
      <h3>Marketing Automation Platform</h3>
      <p>The existing primary marketing automation platforms are <span title="X3100.notes" @click="onSpanClick">{{ listNotes(q.X3100) }}</span>.  This <span title="X3101.notes" @click="onSpanClick">{{ q.X3101.notes === 'Yes' ? 'is':'is not' }}</span> an enterprise deployed platform selected by <span title="X3102.notes" @click="onSpanClick">{{ listNotes(q.X3102) }}</span>.  Marketing <span title="X3103.notes" @click="onSpanClick">{{ q.X3103.notes === 'Yes' ? 'did':'did not' }}</span> participate in the evaluation and selection of the platform.  The platform <span title="X3104.notes" @click="onSpanClick">{{ q.X3104.notes === 'Yes' ? 'provides':'does not provide' }}</span> functionality that addresses the marketing goals and targeting of primary lines of business and audiences.  </p>
      <p class="scoreframe mt-2">The <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> team Scorecard rating for Marketing Automation Implementation and Usage was <span title="S001" class="font-bold" @click="onSpanClick">{{ reportData.survey.questions.S001.answer.pre }}</span> <span title="S001" @click="onSpanClick" v-if="reportData.survey.questions.S001.answer.pre !== 'NA'">({{ scoreRating(reportData.survey.questions.S001.answer.pre) }})</span>. </p>

      <p>Other platforms are in use <span title="X3106.notes" @click="onSpanClick">{{ q.X3106.notes }}</span>.  They are used for <span title="X3107.notes" @click="onSpanClick">{{ listNotes(q.X3107) }}</span> by the <span title="X3108.notes" @click="onSpanClick">{{ listNotes(q.X3108) }}</span> teams.</p>
      <p><b>Annual Contract Cost for Primary MAP:</b> <span title="X3109" @click="onSpanClick">{{ q.X3109.answer.final | decimal(0)  }}</span></p>
      <p><b>Platform Support provided by:</b> <span title="X3110.notes" @click="onSpanClick">{{ listNotes(q.X3110) }}</span>.</p>
      <p class="scoreframe mt-2">The <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> team Scorecard rating for Marketing Operations (MOps) Support was <span title="S010" class="font-bold" @click="onSpanClick">{{ reportData.survey.questions.S010.answer.pre }}</span> <span title="S010" @click="onSpanClick" v-if="reportData.survey.questions.S010.answer.pre !== 'NA'">({{ scoreRating(reportData.survey.questions.S010.answer.pre) }})</span>. </p>
      <p><b>Vendor specific support:</b> Marketing teams and leaders are <span title="X3111.notes" @click="onSpanClick">{{ q.X3111.notes }}</span> with the vendor provided support they receive to resolve issues, obtain guidance and best practices and forward-looking plans for the platform.  Leaders <span title="X3112.notes" @click="onSpanClick">{{ q.X3112.notes === 'Yes' ? 'would':'would not' }}</span> recommend their platform to other marketers.</p>
      <p><b>Additional consulting:</b> Additional support <span title="X3113.notes" @click="onSpanClick">{{ q.X3113.notes === 'Yes' ? 'is':'is not' }}</span> available to build campaigns, review workflows, suggest changes/optimizations.</p>

      <h3>Adoption and usage: Internal and External Teams</h3>
      <p>The existing marketing automation platform is utilized by <span title="X3200.notes" @click="onSpanClick">{{ q.X3200.notes }}</span> marketing teams within the organization.  The existing platform is utilized by <span title="X3202.notes" @click="onSpanClick">{{ listNotes(q.X3202) }}</span>.</p>
      <p class="font-bold">Team members using the MAP platform: </p>
      <p>There are a total of <span title="X3225.final" @click="onSpanClick">{{ q.X3225.answer.final | decimal(2) }}</span> team members utilizing the platform.</p>

      <p>User counts by department: (Including all levels i.e. Coordinator, Manager, Director, Sr Director, VP): </p>
      <ul>
        <li>Campaign: <span title="X3203.final" @click="onSpanClick">{{ q.X3203.answer.final | decimal(2) }}</span></li>
        <li>Demand Gen: <span title="X3204.final" @click="onSpanClick">{{ q.X3204.answer.final | decimal(2) }}</span></li>
        <li>Content Creator: <span title="X3205.final" @click="onSpanClick">{{ q.X3205.answer.final | decimal(2) }}</span></li>
        <li>Project/Program: <span title="X3215.final" @click="onSpanClick">{{ q.X3215.answer.final | decimal(2) }}</span></li>
        <li>Product/Line of Business: <span title="X3216.final" @click="onSpanClick">{{ q.X3216.answer.final | decimal(2) }}</span></li>
        <li>Sales Ops: <span title="X3206.final" @click="onSpanClick">{{ q.X3206.answer.final | decimal(2) }}</span></li>
        <li>Reporting Analyst: <span title="X3207.final" @click="onSpanClick">{{ q.X3207.answer.final | decimal(2) }}</span></li>
        <li>Marketing Ops: <span title="X3208.final" @click="onSpanClick">{{ q.X3208.answer.final | decimal(2) }}</span></li>
        <li>IT: <span title="X3220.final" @click="onSpanClick">{{ q.X3220.answer.final | decimal(2) }}</span></li>
        <li>Sr Leader (VP, SVP, CMO): <span title="X3211.final" @click="onSpanClick">{{ q.X3211.answer.final | decimal(2) }}</span></li>
        <li>Other: <span title="X3221.final" @click="onSpanClick">{{ q.X3221.answer.final | decimal(2) }}</span></li>
      </ul>

      <p>Of these users and departments, who has primary responsibility for campaign development and execution:  <span title="X3222.notes" @click="onSpanClick">{{ q.X3222.notes }}</span></p>
      <p>If multiple platforms are used by different teams, document why?</p>
      <bulleted-notes :onClick="onSpanClick" :questions="q" questionId="X3223" />
      <p>If one platform cannot satisfy the requirements of all teams, explain why?: <span title="X3224.notes" @click="onSpanClick">{{ q.X3224.notes }}</span></p>

      <h3>Targeting and Data Management - Customer Segmentation and Personalization:</h3>
      <p class="mt-4">Number of audiences or business unit segments managed within the MAP: <span title="X3302.final" @click="onSpanClick">{{ q.X3302.answer.final | decimal(0) }}</span></p>
      <p>Criteria that are important for your targeting:</p>
      <bulleted-notes :onClick="onSpanClick" :questions="q" questionId="X3303" />

      <p>Defined customer personas <span title="X3310" @click="onSpanClick">{{ q.X3310.answer.final ? 'are':'are not' }}</span> in use. Key characteristics based on your targeting are: <span title="X3311.notes" @click="onSpanClick">{{ q.X3311.notes }}</span></p>
      <p>Personas are updated and reviewed: <span title="X3312.notes" @click="onSpanClick">{{ q.X3312.notes }}</span></p>
      <p>Buyer journey mapping <span title="X3313.notes" @click="onSpanClick">{{ q.X3313.notes === 'Yes' ? 'is':'is not' }}</span> utilized.  </p>
      <p>Journey mapping is: <span title="X3314.notes" @click="onSpanClick">{{ q.X3314.notes }}</span></p>
      <p>Journey mapping is not done because: <span title="X3315.notes" @click="onSpanClick">{{ q.X3315.notes }}</span></p>

      <p class="scoreframe mt-2">The <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> team Scorecard rating for Persona and Journey Mapping was <span title="S002" class="font-bold" @click="onSpanClick">{{ reportData.survey.questions.S002.answer.pre }}</span> <span title="S002" @click="onSpanClick" v-if="reportData.survey.questions.S002.answer.pre !== 'NA'">({{ scoreRating(reportData.survey.questions.S002.answer.pre) }})</span>. </p>

      <p>You <span title="X3317.final" @click="onSpanClick">{{ q.X3317.answer.final ? 'have':'do not have' }}</span> a process for processing and cleansing your prospect database<span title="X3317.notes" @click="onSpanClick">{{ q.X3317.notes ? ':':'.' }}</span></p>
      <bulleted-notes v-if="q.X3317.notes" :onClick="onSpanClick" :questions="q" questionId="X3317" />

      <p>This data is collected and processed <span title="X3318.notes" @click="onSpanClick">{{ q.X3318.notes }}</span>.</p>

      <p class="scoreframe mt-2">The <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> team Scorecard rating for Contact and Client Data Health and Hygiene was <span title="S011" class="font-bold" @click="onSpanClick">{{ reportData.survey.questions.S011.answer.pre }}</span> <span title="S011" @click="onSpanClick" v-if="reportData.survey.questions.S011.answer.pre !== 'NA'">({{ scoreRating(reportData.survey.questions.S011.answer.pre) }})</span>. </p>

      <h3>Campaign Execution - Lead Generation and Nurturing:</h3>

      <p>Number of campaigns managed within the MAP per month/year: <span title="X3401.final" @click="onSpanClick">{{ q.X3401.answer.final | decimal(0) }}</span></p>
      <p>Campaigns are scheduled or timed: <span title="X3402.notes" @click="onSpanClick">{{ q.X3402.notes }}</span></p>

      <p>Percentage of campaigns that are managed in MAP: <span title="X3403" @click="onSpanClick">{{ q.X3403.answer.final | decimal(0)  }}</span>%</p>
      <p>Types of campaigns that are managed using MAP:</p>
      <bulleted-notes :onClick="onSpanClick" :questions="q" questionId="X3404" />

      <p>Tactics utilized in campaigns:</p>
      <bulleted-notes :onClick="onSpanClick" :questions="q" questionId="X3406" />

      <p class="scoreframe mt-2">The <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> team Scorecard rating for Campaign Dev and Deployment Efficiency and Effectiveness was <span title="S002" class="font-bold" @click="onSpanClick">{{ reportData.survey.questions.S002.answer.pre }}</span> <span title="S002" @click="onSpanClick" v-if="reportData.survey.questions.S002.answer.pre !== 'NA'">({{ scoreRating(reportData.survey.questions.S002.answer.pre) }})</span></p>
      <p>Of the projects that are NOT managed in MAP, what are challenges or why not:</p>
      <bulleted-notes :onClick="onSpanClick" :questions="q" questionId="X3409" />

      <p>Campaigns <span title="X3410" @click="onSpanClick">{{ q.X3410.answer.final ? 'include':'do not include' }}</span> logic to change paths based on responses from prospective buyers.</p>
      <p>Nurture tracks <span title="X3411" @click="onSpanClick">{{ q.X3411.answer.final  ? 'are':'are not' }}</span> available within your campaigns to move prospects through their buying journey.</p>
      <p class="scoreframe mt-2">The <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> team Scorecard rating for Campaign Cycles and Retargeting / Nurturing was <span title="S004" class="font-bold" @click="onSpanClick">{{ reportData.survey.questions.S004.answer.pre }}</span> <span title="S004" @click="onSpanClick" v-if="reportData.survey.questions.S004.answer.pre !== 'NA'">({{ scoreRating(reportData.survey.questions.S004.answer.pre) }})</span></p>

      <p>Content is utilized within campaigns: <span title="X3413.notes" @click="onSpanClick">{{ q.X3413.notes }}</span></p>
      <p>How is that content delivered within the campaign experience:</p>
      <bulleted-notes :onClick="onSpanClick" :questions="q" questionId="X3414" />

      <p>There <span title="X3415" @click="onSpanClick">{{ q.X3415.answer.final ? 'are':'are not' }}</span> sufficient marketing team resources to create this content. Outside resources <span title="X3416" @click="onSpanClick">{{ q.X3416.answer.final ? 'are':'are not' }}</span> being used to create content.</p>
      <p class="scoreframe mt-2">The <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> team Scorecard rating for Content Usage was <span title="S005" class="font-bold" @click="onSpanClick">{{ reportData.survey.questions.S005.answer.pre }}</span> <span title="S005" @click="onSpanClick" v-if="reportData.survey.questions.S005.answer.pre !== 'NA'">({{ scoreRating(reportData.survey.questions.S005.answer.pre) }})</span></p>
      <p>Campaigns <span title="X3418" @click="onSpanClick">{{ q.X3418.answer.final ? 'include':'do not include' }}</span> A/B testing and tracking capabilities.</p>
      <p>Tracking data is utilized to improve results: <span title="X3419.notes" @click="onSpanClick">{{ q.X3419.notes }}</span></p>
      <p>Campaign and sales enablement materials <span title="X3420" @click="onSpanClick">{{ q.X3420.answer.final ? 'are':'are not' }}</span> routed/linked with leads to enable easy follow-up by the sales rep.</p>
      <p class="scoreframe mt-2">The <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> team Scorecard rating for Sales Enablement was <span title="S007" class="font-bold" @click="onSpanClick">{{ reportData.survey.questions.S007.answer.pre }}</span> <span title="S007" @click="onSpanClick" v-if="reportData.survey.questions.S007.answer.pre !== 'NA'">({{ scoreRating(reportData.survey.questions.S007.answer.pre) }})</span></p>

      <h3>Sales and Cross Functional Engagement:</h3>
      <p class="mt-4">Sales leaders <span title="X3501.notes" @click="onSpanClick">{{ q.X3501.answer.final ? 'are':'are not' }}</span>  invested in the lead process and agree on the approach.</p>
      <p>Marketing <span title="X3502.notes" @click="onSpanClick">{{ q.X3502.answer.final ? 'is':'is not' }}</span> collaborating with Sales Ops and Financial teams on the lead process and attribution of sales to leads.</p>
      <p>There <span title="X3503.notes" @click="onSpanClick">{{ q.X3503.answer.final ? 'are':'are not' }}</span> regular meetings to review lead volume and status of lead conversion and sales results with front line sales managers, directors, VPs.</p>
      <p>Lead reporting is reviewed <span title="X3504.notes" @click="onSpanClick">{{ q.X3504.notes }}</span>.</p>
      <p>Lead quality <span title="X3505.notes" @click="onSpanClick">{{ q.X3505.answer.final ? 'has been':'has not been' }}</span> validated by Marketing, Marketing Ops, Sales and Sales Ops.</p>
      <p>Leads are routed to individual sales teams and reps using: <span title="X3505.notes" @click="onSpanClick">{{ q.X3505.notes }}</span></p>
      <p>Sales rep and territory data is updated <span title="X3507.notes" @click="onSpanClick">{{ q.X3507.notes }}</span></p>
      <p>Sales teams <span title="X3508.notes" @click="onSpanClick">{{ q.X3508.answer.final ? 'are':'are not' }}</span> actively opening and working leads.</p>
      <p>Sales reps and leaders <span title="X3509.notes" @click="onSpanClick">{{ q.X3509.answer.final ? 'have':'do not have' }}</span> concerns about the quality or value of leads.</p>
      <p class="scoreframe mt-2">The <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> team Scorecard rating for Sales Satisfaction with Marketing Leads was <span title="S006" class="font-bold" @click="onSpanClick">{{ reportData.survey.questions.S006.answer.pre }}</span> <span title="S006" @click="onSpanClick" v-if="reportData.survey.questions.S006.answer.pre !== 'NA'">({{ scoreRating(reportData.survey.questions.S006.answer.pre) }})</span></p>

      <h3>Workflow Automation:</h3>
      <p>The team <span title="X3601.notes" @click="onSpanClick">{{ q.X3601.answer.final ? 'is':'is not' }}</span> using automated workflows and triggers to build campaigns that push prospects through the campaign journey.</p>
      <p>The team <span title="X3602.notes" @click="onSpanClick">{{ q.X3602.answer.final ? 'is':'is not' }}</span> automate repetitive campaign tasks (follow-ups, nurture campaigns, drip campaigns, lead routing).</p>
      <p>Describe how workflows are automated to deliver content based on triggers and conditions: <span title="X3603.notes" @click="onSpanClick">{{ q.X3603.notes }}</span></p>
      <p>Campaign Replication or Update - Existing campaigns <span title="X3604.notes" @click="onSpanClick">{{ q.X3604.answer.final ? 'can':'can not' }}</span> be easily replicated or updated with the platform.</p>

      <h3>Performance, Reporting and Analytics</h3>
      <p class="scoreframe mt-2">The <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span> team Scorecard rating for Reporting and Analytics was <span title="S008" class="font-bold" @click="onSpanClick">{{ reportData.survey.questions.S008.answer.pre }}</span> <span title="S008" @click="onSpanClick" v-if="reportData.survey.questions.S008.answer.pre !== 'NA'">({{ scoreRating(reportData.survey.questions.S008.answer.pre) }})</span></p>
      <p class="font-bold mt-8">Performance:</p>
      <p>The marketing team <span title="X3701.notes" @click="onSpanClick">{{ q.X3701.notes === 'Yes' ? 'is':'is not' }}</span> achieving lead goals and campaign KPIs.</p>
      <p>The challenges for meeting lead goals: <span title="X3702.notes" @click="onSpanClick">{{ q.X3702.notes }}</span></p>
      <p>What are the current MQL to SQL conversion rates: <span title="X3703.final" @click="onSpanClick">{{ q.X3703.answer.final | decimal(0) }}</span>%</p>
      <p>What is the current close ratio for leads converted to SQLs: <span title="X3704.final" @click="onSpanClick">{{ q.X3704.answer.final | decimal(0) }}</span>%</p>
      <p>Sold revenue data <span title="X3705.notes" @click="onSpanClick">{{ q.X3705.answer.final ? 'is':'is not' }}</span> being regularly or in real-time connected with marketing lead data.</p>
      <p>There <span title="X3706.notes" @click="onSpanClick">{{ q.X3706.answer.final ? 'is':'is not' }}</span> a consolidated data platform to aggregate the sales and marketing data.</p>
      <p class="font-bold mt-8">Reporting:</p>
      <p>Marketing lead reports and attributable sales reports are published <span title="X3707.notes" @click="onSpanClick">{{ q.X3707.notes }}</span>.</p>
      <p>Lead reports are reviewed with Sales leaders <span title="X3708.notes" @click="onSpanClick">{{ q.X3708.notes }}</span>.</p>
      <p>Marketing <span title="X3709.notes" @click="onSpanClick">{{ q.X3709.answer.final ? 'is':'is not' }}</span> able to provide timely reports on directly attributable sales.</p>
      <p>Marketing <span title="X3710.notes" @click="onSpanClick">{{ q.X3710.answer.final ? 'is':'is not' }}</span> able to provide timely reports on indirectly attributable sales.</p>
      <p>Cohort reporting for leads <span title="X3711.notes" @click="onSpanClick">{{ q.X3711.answer.final ? 'is':'is not' }}</span> produced to view sales attributed to specific campaigns periods.</p>
      <p class="font-bold mt-8">Analytics:</p>
      <p>There <span title="X3712.notes" @click="onSpanClick">{{ q.X3712.notes === 'Yes' ? 'is':'is not' }}</span> an agreed ROI or ROAS calculation.  <span v-if="q.X3712.notes === 'Yes'" title="X3713.notes" @click="onSpanClick">The calculation used is {{ q.X3713.notes }}.</span></p>
      <p>The attribution methodology is <span title="X3314.notes" @click="onSpanClick">{{ q.X3314.notes }}</span>.</p>
      <p>The attribution methodology <span title="X3715.notes" @click="onSpanClick">{{ q.X3715.notes === 'Yes' ? 'is':'is not' }}</span> accepted throughout the organization?  <span v-if="q.X3715.notes === 'No'" title="X3716.notes" @click="onSpanClick">This is because {{ q.X3716.notes }}</span></p>
      <p>Long sales cycle attribution rules: The agreed time frame where indirectly attributable leads are credited to longer team sales is <span title="X3717.notes" @click="onSpanClick">{{ q.X3717.notes }}</span> days.</p>
      <p v-if="q.X3718.notes === 'Yes'">Your <span title="X3718.notes" @click="onSpanClick">{{ q.X3718.notes === 'Yes' ? 'process':'' }}</span> for correlating direct mail delivery timing with digital touch points and response is <span title="X3719.notes" @click="onSpanClick">{{ q.X3719.notes }}</span>.</p>
      <p v-else>You <span title="X3718.notes" @click="onSpanClick">{{ 'do not have' }}</span> a process for correlating direct mail delivery timing with digital touch points and response.</p>
      <p>Additional insights acquired from discussions: <span title="X3720.notes" @click="onSpanClick">{{ q.X3720.notes }}</span></p>

      <h3>Team Skills, Training and Support: </h3>
      <p>The campaign team <span title="X3801.notes" @click="onSpanClick">{{ q.X3801.notes === 'Yes' ? 'has':'does not have' }}</span> the skills and expertise necessary to design and execute effective campaigns. <span v-if="q.X3801.notes !== 'Yes'">The gaps and issues are <span title="X3802.notes" @click="onSpanClick">{{ q.X3802.notes }}</span>.</span></p>
      <p>The campaign team <span title="X3803.notes" @click="onSpanClick">{{ q.X3803.notes === 'Yes' ? 'understands':'does not understand' }}</span> the platform. The campaign team <span title="X3804.notes" @click="onSpanClick">{{ q.X3804.notes === 'Yes' ? 'is':'is not' }}</span> using industry best practices to drive performance. The team <span title="X3805.notes" @click="onSpanClick">{{ q.X3805.notes === 'Yes' ? 'has':'does not have' }}</span> the ability to leverage advanced features of the platform. <span v-if="(q.X3803.notes !== 'Yes') || (q.X3804.notes !== 'Yes') || (q.X3805.notes !== 'Yes')">The gaps and issues are </span><span v-if="(q.X3803.notes !== 'Yes') || (q.X3804.notes !== 'Yes') || (q.X3805.notes !== 'Yes')" title="X3806.notes" @click="onSpanClick">{{ q.X3806.notes }}.</span></p>
      <p v-if="(q.X3803.notes !== 'Yes') || (q.X3804.notes !== 'Yes') || (q.X3805.notes !== 'Yes')">Training needs necessary to address gaps and issues: <span title="X3807.notes" @click="onSpanClick">{{ q.X3807.notes }}</span>.</p>
      <p>The marketing operations team <span title="X3808.notes" @click="onSpanClick">{{ q.X3808.notes === 'Yes' ? 'provides':'does not provide' }}</span> sufficient support to campaign team and other users to maximize effectiveness of the platform. <span class="no-hover" v-if="q.X3808.notes !== 'Yes'">The gaps and issues are <span title="X3809.notes" @click="onSpanClick">{{ q.X3809.notes }}</span>.</span></p>

      <h3>Team Satisfaction with MAP</h3>
      <p>Marketing teams are <span class="lowercase" title="X3901.notes" @click="onSpanClick">{{ q.X3901.notes }}</span> with the marketing automation platform.  Satisfaction with the platform is based on the <span title="X3902.notes" @click="onSpanClick">{{ q.X3902.answer.final ? 'ability':'inability' }}</span> of the platform to address the specific requirements of the Campaign teams, their goals and the engagement and use of the leads by the Sales teams.</p>
      <p>Concerns users have with the existing platform:</p>
      <bulleted-notes :onClick="onSpanClick" :questions="q" questionId="X3903" />
    </section>

    <section v-if="true">
      <h1>Findings</h1>
      <h2>Team's MAP Assessment Scorecard Summary</h2>
      <p><span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span>’s MAP Assessment processes are rated as <span class="uppercase">{{ scorecardGrade }}</span> with a rating of {{ r.scorecard.final }} on a scale of 1 to 5. The overall rating would rise if some of the issues discussed in this document would be addressed. </p>
      <assessment-scorecard data-svg="true" data-ratio="0.8" title="Team Assessment Scorecard" answer-key="pre" :assessment="r" :questions="survey"></assessment-scorecard>

      <p class="mt-4">See Appendix 1 for a detailed scale of the MAP Best Practices Scorecard.</p>
      <p>The current team has made changes and improvements over time within the budget constraints and resources availability. However, substantial room for improvement exists with respect to PMP development efficiency, project management best practices and integration.</p>

      <h2>Strengths</h2>
      <p>The strengths of the operation include:</p>


      <div data-container="true" v-for="(strength, index) in strengthsWeakness.strength" :key="'s'+index">
        <h3>{{ strength.title }}</h3>
        <p><span v-html="strength.criteria" />  This is considered {{ strength.grade }}.</p>
        <p>Notes: {{ strength.notes }}</p>
      </div>


      <h2>Issues / Risks / Challenges</h2>
      <p> The opportunities for improvement include:</p>

      <div data-container="true" v-for="(weakness, index) in strengthsWeakness.weakness" :key="'w'+index">
        <h3>{{ weakness.title }}</h3>
        <p><span v-html="weakness.criteria" /> This is considered {{ weakness.grade }}.</p>
        <p>Notes: {{ weakness.notes }}</p>
      </div>
    </section>
    <section>
      <h1>Recommendations</h1>
      <p>Based on our assessment of your production operation, Ricoh recommends incorporating the following software solutions to streamline <span  @click="onSpanClick">{{ r.customerProfile.organizationName }}</span>'s workflow.</p>

      <h2>Proposed Solutions</h2>
      <component data-container="true" v-for="(solution) in solutions.Other" :key="solution.id" :is="solution.component"></component>
    </section>

    <section>
      <h1>Appendix</h1>
      <h2>Appendix 1 - Marketing Automation Review Scorecard Ratings</h2>
      <table class="text-xs" data-cell-style="AppendixCell">
        <tr>
          <th></th>
          <th>5</th>
          <th>4</th>
          <th>3</th>
          <th>2</th>
          <th>1</th>
          <th>N/A</th>
        </tr>
        <tr v-for="rating in survey" :key="rating.id">
          <td class="align-top">{{ rating.question }}</td>
          <td class="align-top">{{  stripHTML(rating.criteria5) }}</td>
          <td class="align-top">{{  stripHTML(rating.criteria4) }}</td>
          <td class="align-top">{{  stripHTML(rating.criteria3) }}</td>
          <td class="align-top">{{  stripHTML(rating.criteria2) }}</td>
          <td class="align-top">{{  stripHTML(rating.criteria1) }}</td>
          <td class="align-top">{{  stripHTML(rating.criteriaNA) }}</td>
        </tr>
      </table>
    </section>

  </div>

</template>

<script>
import workflow from "@/schema/marketingMap";
import utils from "@/plugins/utils"
import BulletedNotes from "@/components/BulletedNotes";
import AssessmentScorecard from "@/components/AssessmentScorecard";
import CreativeServices from "@/components/Solutions/CreativeServices";
import MarcomCentral from "@/components/Solutions/MarcomCentral";
import Robohead from "@/components/Solutions/Robohead";
import RicohMarketingServices from "@/components/Solutions/RicohMarketingServices";
import AugmentedReality from "@/components/Solutions/AugmentedReality";
import MailAssessment from "@/components/Solutions/MailAssessment";
import DigitalMaturity from "@/components/Solutions/DigitalMaturity";
import DigitalAttribution from "@/components/Solutions/DigitalAttribution";
import ProjectManagementConsulting from "@/components/Solutions/ProjectManagementConsulting";
import MarketingAutomation from "@/components/Solutions/MarketingAutomation";


export default {
    name: "report-map",
  components: {
    MarketingAutomation,
    ProjectManagementConsulting,
    DigitalAttribution,
    DigitalMaturity,
    Robohead,
    MarcomCentral,
    CreativeServices,
      BulletedNotes,
    RicohMarketingServices,
    AugmentedReality,
    MailAssessment,
    AssessmentScorecard
  },
  mounted: function(){
    this.$emit("mounted");
  },
    props: {
      reportData: {
        required: true,
      }
    },
    data: function(){
        return {

        }
        },
    methods: {
      listNotes: function(val){
        return utils.listNotes(val)
      },

      onSpanClick: function(e){

        let questionData;



        if(e.target.title) {
          let qPath = e.target.title.split(".");
          if (qPath[0].startsWith("X") || qPath[0].startsWith("V")) {
            let question = this.iQ[qPath[0]];
            let answer = this.q[qPath[0]];

            if (question) {
              questionData = {
                title: "Interview Question",
                answer: answer,
                question: question
              }
              this.$emit("edit", questionData)
            }
          } else if (qPath[0].startsWith("S")) {
            let question = this.reportData.survey.questions[qPath[0]];
            let answer = this.reportData.survey.questions[qPath[0]];

            console.log('criteria'+answer.final, answer)

            if (question) {
              questionData = {
                title: this.survey[qPath[0]].question,
                answer: answer,
                question: {
                  id: qPath[0],
                  answer: question.answer,
                },
                criteria: this.survey[qPath[0]]['criteria'+answer.answer.final]
              }
              this.$emit("edit", questionData)
            }
          }
        }

      },

      onChange: function(e){

        this.$emit("change",e);

      },

      scoreRating: function(score){

        if(score === "NA") {
          return score;
        }
        let intValue = parseFloat(score);
        if(intValue > 4.5 ){
          return "Best-in-Class"
        }
        if(intValue > 3.5 ){
          return "Very Good"
        }
        if(intValue > 2.5 ){
          return "Good"
        }
        if(intValue > 1.5 ){
          return "Sufficient"
        }
        if(intValue > 0 ){
          return "Needs Improvement"
        }
      },

      stripHTML: function(html){
        let tmp = document.createElement("DIV")
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
      }
    },
    computed: {
       r: function(){
         return this.reportData;
       },

      q: function(){
        return this.r.interview.questions;
      },


      strengthsWeakness: function(){
        let _listing = {
          strength: [],
          weakness: []
        }

        if(this.r){
          Object.keys(this.survey).forEach((key) => {
            let question = this.survey[key];
            let answer = this.r.survey.questions[key];

            if(answer.answer.final && answer.answer.final !=="NA"){
              let intValue = parseInt(answer.answer.final);
              if(intValue > 3){
                _listing.strength.push({
                  title: question.question,
                  criteria: question['criteria'+intValue],
                  grade: (intValue === 4)?"Very Good":"Best-in-Class",
                  notes: answer.notes
                })
              }

              if(intValue < 3){
                _listing.weakness.push({
                  title: question.question,
                  criteria: question['criteria'+intValue],
                  grade: (intValue === 2)?"Sufficient":"Substandard",
                  notes: answer.notes
                })
              }
            }

          })
        }

        return _listing;


      },


      option2: function(){
        if(this.r){
          return (this.r.exportSettings.option === "2");
        }
        return false;
      },

      solutions: function(){


        if(this.r){
          let _solutions = {
            Other: this.r.exportSettings.solutions.Other.map((otherID)=>{
              return this.solutionList[otherID]
            })
          };

          return _solutions;

        }

        return {}

      },

      survey: function(){
        return workflow.survey;
      },

      iQ: function(){
        return workflow.questions;
      },

      currentDate: function(){
        return (new Date(Date.now())).toLocaleDateString()
      },

      scorecardGrade: function(){
        if(this.r && this.r.scorecard && this.r.scorecard.final){
          if(this.r.scorecard.final < 1.5){
            return "Sub-Standard"
          }else if(this.r.scorecard.final < 2.5){
            return "Sufficient"
          }else if(this.r.scorecard.final < 3.5){
            return "Good"
          }else if(this.r.scorecard.final < 4.5){
            return "Very Good"
          }else if(this.r.scorecard.final < 5.1){
            return "Best-in-Class"
          }


        }

        return "NA"
      },

      solutionList: function() {
         return {
            "AvantiSlingshot": {name: "Avanti Slingshot", component: "AvantiSlingshot", category: "MIS"},
            "EfiPace": {name: "EFI Pace", component: "EfiPace", category: "MIS"},
            "PrintSmithVision": {name: "PrintSmith Vision", component: "PrintSmithVision", category: "MIS"},
            "FusionPro": {name: "Fusion Pro", component: "FusionPro", category: "VDP"},
           "PrintShopMailConnect": {name: "Objectif Lune Print Shop Mail Connect", component: "PrintShopMailConnect", category: "VDP"},
            "EFIFieryJobMaster": {name: "EFI Fiery Job Master", component: "EFIFieryJobMaster", category: "VDP"},
           "EFIFieryJobFlow": {name: "EFI Fiery Job Flow", component: "EFIFieryJobFlow", category: "VDP"},
           "ReadyPrint": {name: "RSA ReadyPrint", component: "ReadyPrint", category: "VDP"},
           "EnfocusSwitch": {name: "Enfocus Switch", component: "EnfocusSwitch", category: "VDP"},
           "Robohead": {name: "Robohead", component: "Robohead", category: "Other"},
           "CreativeServices": {name: "Creative Services", component: "CreativeServices", category: "Other"},
            "MarcomCentral": {name: "Marcom Central", component: "MarcomCentral", category: "Other"},
            "WebCrd": {name: "Web CRD", component: "WebCrd", category: "Storefront"},
            "MarketDirect": {name: "Market Direct", component: "MarketDirect", category: "Storefront"},
            "PrintNet": {name: "PrintNet", component: "PrintNet", category: "Storefront"},
            "ColorGap": {name: "Color Gap Analysis", component: "ColorGap", category: "Other"},
            "RicohConsultingServices": {
              name: "Ricoh Consulting Services",
              component: "RicohConsultingServices",
              category: "Other"
            },
           "RicohMarketingServices": {
             name: "Marketing Services",
             component: "RicohMarketingServices",
             category: "Other"
           },
           "AugmentedReality": {
             name: "Augmented Reality",
             component: "AugmentedReality",
             category: "Other"
           },
           "MarketingAutomation": {
             name: "Marketing Automation",
             component: "MarketingAutomation",
             category: "Other"
           },
           "DigitalAttribution": {
             name: "Digital Attribution Capabilities",
             component: "DigitalAttribution",
             category: "Other"
           },
           "ProjectManagementConsulting": {
             name: "Project Management Consulting",
             component: "ProjectManagementConsulting",
             category: "Other"
           },
           "DigitalMaturity": {
             name: "Digital Maturity Assessment",
             component: "DigitalMaturity",
             category: "Other"
           },
           "MailAssessment": {
             name: "Mail Assessment",
             component: "MailAssessment",
             category: "Other"
           },
            "ProductionWorkflow": {name: "Production Workflow", component: "ProductionWorkflow", category: "Other"},
          }
       }
    }
}
</script>

<style scoped>


.report-production-workflow {

}

span[title]:empty:after {
  content: '\0000a0';
  padding: 0px 0.5em;
}

.scoreframe {
  border: 1px solid black;
  padding: 1em;
  background-color: #F8F8F8;
}

</style>