


<template>
<div class="">
  <h4>Electronic Bill Presentment and Payment</h4>
  <hr>
  <p>An electronic bill presentment and payment (EBPP) solution, Uluro uPayments doesn't require time-consuming, expensive programming. EBPP is convenient, simple, secured “pay now” options for transactions such as monthly invoice payments. An easy-to-use, brandable cloud-based subscription service, uPayments shortens time to cash and reduces costs related to printing, postage and mailing.</p>
</div>
</template>

<script>
export default {
    name: "electronic-bill-payment",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


</style>
