export default {
    listNotes: function(val){

        console.log(val)
        let items = val.notes.split(",")

        if(items[items.length - 1] === "Other"){
            if(val.answer?.final){
                items[items.length - 1] = val.answer.final
            }
        }

        if(items.length > 1){
            items[items.length - 1] = "and "+items[items.length - 1]
        }

        if(items.length === 2){
            return items.join(" ")
        }
        return items.join(", ")
    },
}