


<template>
<div class="quadient-inspire">
<h4>Quadient Inspire</h4>
  <hr>
  <p>The Quadient® Inspire™ modular enterprise communications platform enables you to create, design and deliver in-brand personalized messages to your customers in their preferred digital or non-digital format. Ideal for print service providers and enterprises, Quadient® Inspire™ integrates your communications processes to provide a single view of each customer and a single interface to simplify business-wide collaboration, content management and compliance.</p>
  <ul>
    <li>Accelerate go-to-market plans within a rapid development print environment that includes quality control, security and compliance features.</li>
    <li>Launch new offerings quickly using a common document process that supports collaboration and enables centralized control.</li>
    <li>Improve response rates based on insights that incorporate demographics, transaction history and predictive modeling.</li>

  </ul>
<p class="bold-label">Software Information:</p>
  <p>No matter what applications you are running, the integrated Quadient Inspire platform can save time because it maintains continuity across document design and production functions. End-to-end, cohesive functionality simplifies personalized communications, as well as training, maintenance, service and integration of your applications.</p>
<p>You can communicate with and engage your customers across digital and paper channels, shorten time to market for new offerings and leverage the scalability and operational efficiencies of the cloud.</p>
<p class="bold-label">Customer Benefits:</p>
  <ul>
    <li>Keeps everyone on the same page and allows collaboration among many people within a common document process and workflow orientation</li>
    <li>Offers multiple modules that allow you to tailor a solution to match your requirements</li>
    <li>Provides a Rapid Application Development environment with advanced quality control features to enable quick and accurate setup, proofing and output of documents</li>
    <li>Conforms to existing IT Infrastructures so you can leave your current data structure alone</li>
    <li>Create many types of documents across many platforms and send them to multiple output channels</li>
    <li>Streamlines your document process by enabling you to automate it and hook it up to other business processes such as ERP, ECM, accounting, call center or data analytics</li>
    <li>Provides the same industrial-strength toolset that runs the world’s largest direct mailers, statement processors and print service providers</li>

  </ul>
  <p><b>Key Features & Benefits:</b></p>
  <p class="bold-label">Inspire Designer</p>
  <ul>
    <li>Obtain a full-function document design interface for high-impact, personalized direct marketing, transactional and on-demand documents. Incorporates data inputs, design imports, data preparation, design workflow, and complex, graphical page layout.</li>
  </ul>
  <p class="bold-label">Inspire Interactive</p>
  <ul>
    <li>Utilize Web-based document design for creating documents based on standard templates, enabling LOB ownership and enterprise consistency.</li>
  </ul>
  <p class="bold-label">Inspire Connect</p>
  <ul>
    <li>Automate multichannel customer communications with this Web services interface.</li>
  </ul>
  <p class="bold-label">Inspire Approval</p>
  <ul>
    <li>Maintain accuracy and control with the document proofing and approval system.</li>
  </ul>

  <p class="bold-label">Inspire Content Manager</p>
  <ul>
    <li>Use version control management to facilitate both collaboration and accurate deployment of new document applications.</li>
  </ul>

  <p class="bold-label">Inspire Content Manager</p>
  <ul>
    <li>Use version control management to facilitate both collaboration and accurate deployment of new document applications.</li>
  </ul>

  <p class="bold-label">Inspire Print Output</p>
  <ul>
  <li>Acquire comprehensive document print data stream support, including Advanced Function Presentation™ (AFP™), PostScript, PCL, Metacode, XML and PDF.</li>
  </ul>
  <p class="bold-label">Inspire Multi-Channel</p>
  <ul>
  <li>Match each customer’s channel preference with print and non-print choices.</li>
  </ul>
  <p class="bold-label">Inspire Campaign Manager</p>
  <ul>
  <li>Target and execute customer communications campaigns across multiple channels and multiple touch points.</li>
  </ul>
  <p class="bold-label">Inspire Compare</p>
  <ul>
  <li>Maintain document integrity from version to version.</li>
  </ul>
  <p class="bold-label">Inspire Content Manager Web</p>
  <ul>
  <li>Centralize management of brand assets in support of analytics-driven Precision Marketing.</li>
  </ul>
  <p class="bold-label">Inspire Dashboard</p>
  <ul>
  <li>Track and analyze customer communication activities so you can fine tune future campaigns for the purpose of increasing response rates.</li>
  </ul>
  <p class="bold-label">Inspire Storefront</p>
  <ul>
  <li>Develop and manage branded Web portals (storefronts), enabling customers to provide Web-based self-service front-ends to document services.</li>
  </ul>


</div>
</template>

<script>
export default {
    name: "quadient-inspire",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


.quadient-inspire {

}

</style>
