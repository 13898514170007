


<template>
  <div id="report">

    <section class="">
      <table class="titlebox absolute text-white" style="font-size: 1vw; left: 11em; width:28em; margin-top: 11em;">
        <tr><td class="font-bold" style="font-size: 2.5em; padding-bottom: 0.5em;" data-cell-style="TitlePageTitleCell">Findings Report</td></tr>
        <tr><td class="font-bold leading-none" style="font-size: 2em; padding-bottom: 1em;" data-cell-style="TitlePageSubtitleCell">Print and Mail Operations Configuration (POD)</td></tr>
        <tr><td style="padding-bottom: 1em;">  </td></tr>
        <tr><td class="leading-snug" style="font-size: 1.2em;" data-cell-style="TitlePageCell">Confidential: For the exclusive use of Ricoh and <span>{{ r.customerProfile.organizationName?r.customerProfile.organizationName:'XXXX' }}</span>.  This document cannot be shared with any other parties without written permission from all of the parties referenced here.</td>
        </tr>
        <tr><td style="padding-bottom: 2em;">  </td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell">V1.0</td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell"><span>{{ r.userProfile.firstname }} {{ r.userProfile.lastname }}</span></td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell"><span>{{ r.userProfile.title }}</span></td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell"><span>{{ currentDate }}</span></td></tr>
      </table>

      <img class="w-full -mx-8 block" data-float="true" data-ratio="1.2941" src="/reports/title-bkg.png" />



      <p></p>



    </section>

    <section>
      <p class="font-bold no-mb">Prepared for:</p>
      <p class="font-bold no-mb "><span>{{ r.customerProfile.organizationName?r.customerProfile.organizationName:'XXXX' }}</span> - <span @click="onSpanClick" title="facilityContact">{{q.facilityContact.value?q.facilityContact.value:'XXXX'}}</span></p>
      <p class="no-mb"><span @click="onSpanClick" title="facilityAddress">{{q.facilityAddress.value?q.facilityAddress.value:'XXXX'}}</span></p>
      <p class="no-mb"><span @click="onSpanClick" title="facilityCity">{{q.facilityCity.value?q.facilityCity.value:'XXXX'}}</span>, <span @click="onSpanClick" title="facilityState">{{q.facilityState.value?q.facilityState.value:'XXXX'}}</span> <span @click="onSpanClick" title="facilityPostal">{{q.facilityPostal.value?q.facilityPostal.value:'XXXX'}}</span></p>
      <p class="mt-8 no-mb font-bold">Prepared by:</p>
      <p class="no-mb"><span>{{ r.userProfile.firstname }}</span> <span>{{ r.userProfile.lastname }}</span></p>
      <p class="no-mb" v-if="r.userProfile.organization"><span>{{ r.userProfile.organization }}</span></p>
      <p class="no-mb"><span>{{ r.userProfile.title }}</span></p>
      <p class="no-mb mt-8"><span>{{ currentDate }}</span></p>
    </section>

    <section>
      <p class="toc-title">TABLE OF CONTENTS</p>
      <p data-toc="true" data-alias="Summary" data-levels="1-3">Table of Contents here.</p>
    </section>

    <section>
        <h1>Overview</h1>
        <h2>Background</h2>
        <p>The most proficient and cost-effective Print Operations are right sized with an optimum configuration for equipment, software and personnel within a facility that can accommodate the operations for efficient production workflows. You must take into consideration the volumes and types of work, turnaround times, customer requirements and capacity of equipment in order to develop a right-sized operation.</p>
        <p>To assist organizations in the development and optimization of production operations, Ricoh has developed a program to assess a customer’s requirements and provide the information needed to configure an onsite operation. Ricoh solution consultants work collaboratively with customers to define workflow processes, software and equipment solutions as well as best practices for effective onsite production.</p>


      <h2>Methodology</h2>
      <p><span>{{ r.userProfile.firstname }}</span> <span>{{ r.userProfile.lastname }}</span> met with <span @click="onSpanClick" title="facilityContact">{{q.facilityContact.value?q.facilityContact.value:'XXXX'}}</span> to review the organization’s print and mail requirements. The goal was to review all workflows and business requirements for print and distribution of communications and configure an onsite operation to meet customer needs.</p>
      <p>Areas reviewed:</p>
      <ul class="list-disc">
        <li>Administration</li>
        <li>Order Intake</li>
        <li>Pre-Press Design and File Processing</li>
        <li>Production Workflow</li>
        <li>Software and Equipment</li>
        <li>Printed Materials</li>
        <li>Envelopes</li>
        <li>Mail Processing</li>
        <li>Job Status Tracking</li>
        <li>Reports</li>
        <li>Integration Requirements</li>
      </ul>

      <h2>Site</h2>
      <p class="no-mb"><span @click="onSpanClick" title="customerProfile.organizationName">{{ r.customerProfile.organizationName?r.customerProfile.organizationName:'XXXX' }}</span> - <span @click="onSpanClick" title="facilityContact">{{q.facilityContact.value?q.facilityContact.value:'XXXX'}}</span></p>
      <p class="no-mb"><span @click="onSpanClick" title="facilityAddress">{{q.facilityAddress.value?q.facilityAddress.value:'XXXX'}}</span></p>
      <p class="no-mb"><span @click="onSpanClick" title="facilityCity">{{q.facilityCity.value?q.facilityCity.value:'XXXX'}}</span>, <span @click="onSpanClick" title="facilityState" >{{q.facilityState.value?q.facilityState.value:'XXXX'}}</span> <span @click="onSpanClick" title="facilityPostal">{{q.facilityPostal.value?q.facilityPostal.value:'XXXX'}}</span></p>

      <h2>Operations Reviewed</h2>
      <table>
        <tr>
          <th>No.</th><th class="text-left">Departments/Area</th>
        </tr>
        <tr>
          <td>1</td><td>Pre-Production</td>
        </tr>
        <tr>
          <td>2</td><td>Production</td>

        </tr>
        <tr>
          <td>3</td><td>Post-Production</td>
        </tr>

      </table>
    </section>

    <section>
      <h1>Current State</h1>
      <h2>Organization</h2>
      <p><span @click="onSpanClick" title="customerProfile.organizationName">{{ r.customerProfile.organizationName?r.customerProfile.organizationName:'XXXX' }}</span> is a <span title="X200.notes" @click="onSpanClick">{{  iQ.X200.notes }}</span> that processes print requirements for <span title="X201.notes" @click="onSpanClick">{{  iQ.X201.notes }}</span>.</p>
      <p>The types of printed materials include <span title="X202.final" @click="onSpanClick">{{  listValue(iQ.X202) }}</span>.</p>
      <p>Approximately <span title="I1003.final" @click="onSpanClick">{{  (iQ.I1003.answer.final/12) | decimal(0) }}</span> projects are processed monthly. The total monthly volume includes <span title="X118.final" @click="onSpanClick">{{  (iQ.X118.answer.final/12) | decimal(0) }}</span> color, <span title="X119.final" @click="onSpanClick">{{  (iQ.X119.answer.final/12) | decimal(0) }}</span> monochrome and <span title="X203.final" @click="onSpanClick">{{  (iQ.X203.answer.final/12) | decimal(0) }}</span> MICR and <span title="X204.final" @click="onSpanClick">{{  (iQ.X204.answer.final/12) | decimal(0) }}</span> square feet of wide format (posters, banners, signs). The number of envelopes processed monthly is <span title="X204.final" @click="onSpanClick">{{  (iQ.X204.answer.final/12) | decimal(0) }}</span>.</p>

      <h2>Software and Platforms</h2>
      <p>Software to process materials include:</p>
      <ul class="list-disc">
        <li v-for="(software, index) in softwareItems" :key="'software'+index">{{ software }}</li>
      </ul>


      <h2>Processes</h2>
      <h3>Document Development</h3>
      <p>Overview of document development process.</p>
      <p>Paragraph that captures important data to define current state and information to configure solution.</p>

      <h3>Approval Processes</h3>
      <p>Overview of approval process.</p>
      <p>Paragraph that captures important data to define current state and information to configure solution.</p>
      <p>Approvals are managed via <span title="X141.notes" @click="onSpanClick">{{  iQ.X141.notes?iQ.X141.notes:'XXXX' }}</span></p>

      <h3>Document Workflow</h3>
      <p>Overview of document workflow.</p>
      <p>Paragraph that captures important data to define current state and information to configure solution.</p>

      <h3>Production Resources/Sourcing</h3>
      <p>Overview of resources needed for raw materials and production of print. </p>
      <p>Paragraph that captures important data to define current state and information to configure solution.</p>

      <h3>Distribution/Shipping Procedures</h3>
      <p>Overview of distribution/mailing procedures.</p>
      <p>Paragraph that captures important data to define current state and information to configure solution.</p>

      <h3>Inventory Management</h3>
      <p>Overview of inventory management.</p>
      <p>Paragraph that captures important data to define current state and information to configure solution.</p>

      <h3>SLAs</h3>
      <p>Overview of service level agreements/turnaround time requirements.</p>
      <p>Paragraph that captures important data to define current state and information to configure solution.</p>

      <h3>Reports</h3>
      <p>Overview of reports required to manage and monitor the operation and project workflow.</p>
      <p>Paragraph that captures important data to define current state and information to configure solution.</p>

      <h3>Scheduling/Tracking</h3>
      <p>Overview of scheduling/tracking of jobs for the organization/operation.</p>
      <p>Paragraph that captures important data to define current state and information to configure solution.</p>

      <h3>System Integration</h3>
      <p>Overview of the systems that need to be integrated/connected within an organization/operation.</p>
      <p>Paragraph that captures important data to define current state and information to configure solution.</p>

      <h3>Disaster Recovery</h3>
      <p>The current Disaster Recovery Plan that ensures that your organization can continue to distribute printed materials and other communications in the event of a disaster includes <span title="X165.notes" @click="onSpanClick">{{  iQ.X165.notes?iQ.X165.notes:'XXXX' }}</span>. The Disaster Recovery production site is located <span title="X166.final" @click="onSpanClick">{{  iQ.X166.answer.final | decimal(0) }}</span> miles from your current operation.</p>
      <p>SLA's (Service Level Agreements)/required turnaround times and types of materials that need to be distributed in the event of a disaster include <span title="X167.notes" @click="onSpanClick">{{  iQ.X167.notes?iQ.X167.notes:'XXXX' }}</span>.</p>
    </section>
    <section>
      <h1>Recommendations</h1>
      <h2>Proposed Solutions — Operation's Profile</h2>
      <p>Based on the information that was gathered as a part of this study, we have configured an onsite operation to include the following components:</p>
      <summary-report :configuration="reportData"></summary-report>
      <p></p>
      <h2>Workflow</h2>
      <p>A best-in-class print production workflow includes the following components. Our recommendations for the solutions that would drive this workflow are included after the diagram.</p>
      <img class="w-full mb-8" src="/reports/config-workflow.png" data-ratio="0.5886" />
<!--      <component data-container="true" v-for="(solution) in solutions.Other" :key="solution.id" :is="solution.component"></component>-->

      <template v-if="solutions.workflow">
        <h2>Workflow Solutions</h2>
        <component data-container="true" v-for="(solution) in solutions.workflow" :key="solution" :is="solution"></component>
        <p></p>
      </template>

      <template v-if="solutions.admin">
        <h2>Management</h2>
        <component data-container="true" v-for="(solution) in solutions.admin" :key="solution" :is="solution"></component>
        <p></p>
      </template>

      <template v-if="solutions.other">
        <h2>Other Recommended Solutions</h2>
        <component data-container="true" v-for="(solution) in solutions.other" :key="solution" :is="solution"></component>
        <p></p>
      </template>

      <h2>Benefits</h2>
      <p>The proposed solution will align with organizational goals and objectives, provide compliance along with administrative efficiency and improved customer experience. Onsite production has the potential to provide the following benefits:</p>
      <p class="bold-label">Empower the business to control communications</p>
      <ul>
        <li>Drive more control over correspondence and communications</li>
        <li>Meet requirements to improve time to market with communications</li>
      </ul>

      <p class="bold-label">Integration/Interoperability</p>
      <ul>
      <li>Integrate directly with existing core systems or print and mail processing applications</li>
      <li>Enable production of print, mobile and web communications through single platform and design</li>
      </ul>

      <p class="bold-label">Workflow Efficiency</p>
      <p class="bold-label">Eliminate Human Errors and Reduce Costs by Automating Manual Tasks</p>
      <p>RICOH can help teams manage deadlines by automating workflow processes and providing visibility into every job from receipt to delivery.</p>
      <ul>
      <li>Easily build workflows using a drag-and-drop interface.</li>
      <li>Specify finishing, media and duplex instructions to sheet-fed or continuous feed printers when work is scheduled or change them on the fly.</li>
      <li>Merge electronic forms with variable data in one printing pass, eliminating storage costs and manual workflows of preprinted forms.</li>
      <li>Integrate with other systems via web services.</li>
      </ul>

      <p class="bold-label">More Efficient Processes and Greater Control</p>
      <ul>
      <li>Enable version control, validity of dates, approval status and full approval tracking</li>
      <li>Document security and more efficient operations</li>
      <li>Create centralized, enterprise-wide process automation and task management, and eliminate manual and/or redundant steps</li>
      </ul>

      <p class="bold-label">Better Customer Experience:</p>
      <p class="bold-label">Drive Omni-Channel Customer Experience</p>
      <ul>
      <li>A single solution for print and digital saves up to 70% of design time</li>
      <li>Provides a powerful customer experience</li>
      <li>Personalized up-sell and cross-sell promotions with digital and print output</li>
      </ul>

      <p class="bold-label">When Site Survey DOES NOT select Commercial Print/Mail Service Provider, include the following –</p>

      <p><b>Key Values</b> of InPlants include the following:</p>

      <p class="bold-label">1.	Cost Savings</p>
      <ul>
      <li>Overall cost savings (25 to 30%)</li>
      <li>Less overhead</li>
      <li>Equipment specific to requirements (higher utilization = lower rates)</li>
      <li>Volume purchasing lowers overall costs</li>
      <li>No delivery fees / no rush fees</li>
      <li>Reduced cost for processing invoices</li>
      </ul>

      <p class="bold-label">2.	Customer Service</p>
      <ul>
      <li>Faster turnaround</li>
        <ul>
          <li>Development time - prior projects/business knowledge</li>
          <li>Responsiveness - no competing customers’ timelines</li>
          <li>Onsite delivery</li>
        </ul>

      <li>Convenience</li>
        <ul>
          <li>Location - accessibility/no delays to initiate production</li>
          <li>Central source</li>
          <li>No individual purchase orders for print</li>
          <li>No individual invoices to process for accounts payable</li>
        </ul>

      <li>Ease of Doing Business</li>
        <ul>
          <li>Collaborate</li>
          <li>Know the products, culture, relationships</li>
          <li>Print sourcing subject matter expert</li>
          <li>Assist in design and development</li>
        </ul>

      </ul>
      <p class="bold-label">3.	Quality</p>
      <ul>
      <li>Thorough understanding of brand - brand identity compliance</li>
      <li>Familiarity with products and services = reduced errors</li>
      <li>Dedicated to the success of the organization</li>
      <li>Closed loop processes from proof to print</li>
      <li>Color management</li>
      </ul>
      <p class="bold-label">4.	Security</p>
      <ul>
      <li>Confidentiality and trust</li>
      <li>Information stays internal to organization</li>
      <li>Compliance - centralized control and audit trail</li>
      <li>Vested interest in the organization’s success</li>
      <li>Control</li>
        <ul>
          <li>Brand</li>
          <li>Scheduling/service levels</li>
          <li>Visibility of overall print spend</li>
        </ul>

      </ul>

      <p>A typical organizational chart for an InPlant is as follows (smaller InPlants may have less administrative supervision compared to larger organizations)</p>

      <img class="w-full mb-8 max-w-screen-md" src="/reports/site-config-org-chart.png" data-ratio="0.7279" />

      <p>Software and equipment can include:</p>
      <p class="bold-label">Software</p>
      <ul>
        <li>Online Storefront (WebCRD, DSF, Marcom, etc.)</li>
        <li>Variable Data Software (Fusion Pro, XMPie, etc.)</li>
        <li>Print MIS (Slingshot, PrintSmith, etc.)</li>
      </ul>
      <p class="bold-label">Print Equipment</p>
      <ul>
        <li>1 Color Digital</li>
        <li>4 Color Digital</li>
        <li>Large Format (Roll-fed, Flatbed-High Volume, Rigid Substrate)</li>
        <li>Offset (High Volume)</li>
      </ul>

      <p class="bold-label">Finishing/Bindery Equipment</p>
      <ul>
        <li>Inline Finishing</li>
        <li>Offline Finishing</li>
        <li>Cutting</li>
        <li>Folding</li>
        <li>Saddle Stitch or Perfect Bound Booklet making</li>
        <li>Cut/Score/Crease</li>
        <li>Coil Binding</li>
        <li>Shrink-wrap</li>
      </ul>


      <p class="bold-label">Additional services that provide added value to the organization can include (the most prevalent are highlighted in bold):</p>
      <ul>
        <li>Design</li>
        <li>Mail - Incoming, Outgoing (including Packages)</li>
        <li>Copier Fleet Management</li>
        <li>Print Sourcing</li>
        <li>Fulfillment - Inventory Items, Paper</li>
        <li>Copyright Clearance</li>
        <li>Brand Identity Monitoring</li>
        <li>Proofreading</li>
        <li>Document Scanning</li>
        <li>Promotional Items (Trinkets, T-Shirts, Mugs, etc.)</li>
        <li>Garment Printing</li>
        <li>Engraving</li>
        <li>Data/Mailing List Acquisition/Prep</li>
        <li>Marketing Campaign Management</li>
        <li>Email or Mobile/SMS Messaging</li>
        <li>Website Design or Hosting</li>
        <li>Social Media Marketing</li>
        <li>Digital Asset Management</li>
        <li>CD/DVD/USB Duplication</li>
        <li>Photography</li>
        <li>Video Production</li>
        <li>Shredding</li>
      </ul>


      <p class="bold-label">Customers served by InPlants include the following main departments:</p>
      <ul>
        <li>Marketing</li>
        <li>Customer Communications</li>
        <li>Policy Development</li>
        <li>Human Resources / Benefits</li>
        <li>Facilities</li>
        <li>Training</li>
        <li>Safety and Security</li>
        <li>I/T</li>
        <li>Regional Offices</li>
        <li>Agents</li>
      </ul>


      <p class="bold-label">Typical products include the following printed materials:</p>
      <ul>
        <li>Identity Products - Letterhead, Envelopes, Business Cards, Name Tags</li>
        <li>Financial Documents – Bills and Statements</li>
        <li>Marketing Materials - Brochures, Posters</li>
        <li>Business Forms - Multi-Part Carbonless Forms, Note Pads</li>
        <li>Booklets - Saddle Stitched, Spiral Bound, Perfect Bound</li>
        <li>Labels, Tags</li>
        <li>Newsletters / Publications</li>
        <li>Posters / Banners</li>
      </ul>


      <p class="bold-label">It is important for InPlants to implement best practices to maintain their relevancy and presence. Following are best practices that contribute to the continued success of InPlants:</p>
      <ul>
      <li>Conduct Project Planning Meetings with Customers on a Regular Basis</li>
      <li>Effectively Market your Services (Open House, Direct Mail, etc.)</li>
      <li>Job Intake through Ecommerce Systems</li>
      <li>Integration between Ecommerce/Online Storefront and Print MIS</li>
      <li>Communicate Your Value - Track and Report:</li>
      <ul>
        <li>Production Statistics (jobs, impressions, productivity)</li>
        <li>Sales Statistics (volume, cost savings)</li>
        <li>Customer Satisfaction</li>
      </ul>


      <li>Continually Review and Implement Changes to Adapt to Customer Needs</li>
      <li>Certification Initiatives and Conformance:</li>

      <ul>
        <li>G7 Master Printer Certification</li>
        <li>FSC, SFI Sustainability Certifications</li>
        <li>ISO Quality Certification</li>
      </ul>
      </ul>




    </section>



  </div>
</template>

<script>

import schema from "@/schema/siteConfig"

import AvantiSlingshot from '@/components/Solutions/AvantiSlingshot'
    import MarketDirect from '@/components/Solutions/MarketDirect'
    import MarcomCentral from '@/components/Solutions/MarcomCentral'
    import WebCrd from '@/components/Solutions/WebCrd'
    import EfiPace from '@/components/Solutions/EfiPace'
    import PrintSmithVision from '@/components/Solutions/PrintSmithVision'
    import EfiQuickPrint from '@/components/Solutions/EfiQuickPrint'
    import FusionPro from '@/components/Solutions/FusionPro'
    import ProductionWorkflowAssessment from '@/components/Solutions/ProductionWorkflowAssessment'
    import EFICrossMedia from '@/components/Solutions/EFICrossMedia'
    import EFICrossMediaCampaigns from '@/components/Solutions/EFICrossMediaCampaigns'
    import ProductionWorkflow from '@/components/Solutions/ProductionWorkflow'
    import RsaQdirect from '@/components/Solutions/RsaQdirect'

    import CcmAssessment from '@/components/Solutions/CcmAssessment'
    import CampaignServices from '@/components/Solutions/CampaignServices'
    import PlanetPressConnect from '@/components/Solutions/PlanetPressConnect'
    import PresConnect from '@/components/Solutions/PresConnect'
    import PrintShopMailConnect from '@/components/Solutions/PrintShopMailConnect'
    import QuadientImpress from '@/components/Solutions/QuadientImpress'
    import QuadientInspire from '@/components/Solutions/QuadientInspire'
    import UluroSolutions from '@/components/Solutions/UluroSolutions'
    import CCMDesign from '@/components/Solutions/CCMDesign'
    import PrintShopMail from '@/components/Solutions/PrintShopMail'

    import FacilityBestPractices from '@/components/Solutions/FacilityBestPractices'
    import PurchasingBestPractices from '@/components/Solutions/PurchasingBestPractices'
    import InventoryBestPractices from '@/components/Solutions/InventoryBestPractices'
    import ContentManagement from '@/components/Solutions/ContentManagement'

    import MailOptimization from '@/components/Solutions/MailOptimization'
    import BccMailManager from '@/components/Solutions/BccMailManager'
    import QuadientBulkMailer from '@/components/Solutions/QuadientBulkMailer'
    import QuadientAims from '@/components/Solutions/QuadientAims'
    import ProcessDirector from '@/components/Solutions/ProcessDirector'
    import ElectronicBillPayment from '@/components/Solutions/ElectronicBillPayment'
    import StandardOperatingProcedures from '@/components/Solutions/StandardOperatingProcedures'
    import SupportTraining from '@/components/Solutions/SupportTraining'
    import OpsChecklist from '@/components/Solutions/OpsChecklist'
    import RicohConsultingServices from '@/components/Solutions/RicohConsultingServices'
    import SecurityConformance from '@/components/Solutions/SecurityConformance'
    import DisasterRecovery from '@/components/Solutions/DisasterRecovery'
import SummaryReport from "@/components/PrintConfig/SummaryReport";




export default {
    name: "report-site-config-full",
  components: {
    SummaryReport,
      AvantiSlingshot,
      MarketDirect,
      MarcomCentral,
      WebCrd,
      EfiPace,
      PrintSmithVision,
      EfiQuickPrint,
      FusionPro,
      ProductionWorkflowAssessment,
      EFICrossMedia,
      EFICrossMediaCampaigns,
      ProductionWorkflow,
      RsaQdirect,

      CcmAssessment,
      CampaignServices,
      PlanetPressConnect,
      PresConnect,
      PrintShopMailConnect,
      QuadientImpress,
      QuadientInspire,
      UluroSolutions,
      CCMDesign,
      PrintShopMail,

      FacilityBestPractices,
      PurchasingBestPractices,
      InventoryBestPractices,
      ContentManagement,

      MailOptimization,
      BccMailManager,
      QuadientBulkMailer,
      QuadientAims,
      ProcessDirector,
      ElectronicBillPayment,
      StandardOperatingProcedures,
      SupportTraining,
      OpsChecklist,
      RicohConsultingServices,
      SecurityConformance,
      DisasterRecovery,
  },
  mounted: function(){
    this.$emit("mounted");
  },
    props: {
      reportData: {
        required: true,
      },
      api: {
        required: true
      }
    },
    data: function(){
        return {
          schema: schema
        }
        },
    methods: {




      dateString: function(ts){
        let date = new Date(ts);
        return (date.getMonth()+1) + "/"+date.getDate() + "/"+date.getFullYear();
      },

      editOrg: function(){
        alert("Exit preview and go to the Facility Profile section to change organization information.")
      },

      editSettings: function(){
        console.log("edit Settings");
      },

      onChange: function(e){

        this.$emit("change",e);

      },

      onSpanClick: function(e){
        console.log(e);
        if(e.target.title){
          let qPath = e.target.title.split(".");
          if(qPath.length){
            let editInfo;

            if(this.iQ[qPath[0]]){
              //interview question
              editInfo = {
                question: this.schema.questions[qPath[0]],
                answer: this.reportData.interview.questions[qPath[0]]
              }
            }else if(this.q[qPath[0]]){
              //interview question
              editInfo = {
                question: this.api.questions[qPath[0]],
                answer: this.reportData.answers[qPath[0]]
              }
            }

            if(editInfo){
              this.$emit('edit',editInfo);
            }
          }
        }
        
        //this.$emit('edit',e.dataset.title);
      },
      listValue: function(answer){

        let list = answer.notes;

        if(!list){
          return "XXXX"
        }




        let selections = list.split(",");
        if(selections.length > 2){
          selections[selections.length -1] = 'and '+selections[selections.length -1];
        }

        let result;



        if(selections.length < 3){
          result = selections.join(' and ');
        }else{
          result = selections.join(', ');
        }

        if(answer.answer.final){

          let otherText = "Other";

          let question = this.iQ[answer.questionID];


          if(question.choices){
            otherText = question.choices[question.choices.length - 1];
          }

          result = result.replace(otherText, answer.answer.final)

        }

        return result;
      },

      bulletValue: function(answer){

        let list = answer.notes;






        if(answer.answer.final){

          let otherText = "Other";

          let question = this.iQ[answer.questionID];


          if(question.choices){
            otherText = question.choices[question.choices.length - 1];
          }

          list = list.replace(otherText, answer.answer.final)


        }

        let selections = list.split(",");

        return selections;
      },
    },
    computed: {

       r: function(){
         return this.reportData;
       },

      q: function(){
        return this.r.answers;
      },

      iQ: function(){
        return this.r.interview.questions;
      },

      currentDate: function(){
        return (new Date(Date.now())).toLocaleDateString()
      },

      softwareItems: function(){
        return this.bulletValue(this.iQ.X120);
      },

      platformItems: function(){
        return this.bulletValue(this.iQ.X121);
      },

      resourceItems: function(){
        return this.bulletValue(this.iQ.X207);
      },

      solutions: function(){


        if(this.r && this.r.exportSettings){

          return this.r.exportSettings.solutions;
        }

        return {}

      },
    }
}
</script>

<style scoped>


.report-production-workflow {

}

p[data-bullet="true"]{
  display: list-item;
  margin-left: 1em;
  margin-bottom: 0;
}

p[data-bullet="true"]:last-child {
  margin-bottom: 1em;
}

</style>
