


<template>
<div class="config-question-select">
  <div class="flex justify-between items-center">
    <label>{{ question.label }}</label>
    <select-input class="w-48" prompt-text="Select Market" :options="selections" v-model="answer.value" @change="$emit('change')"></select-input>
  </div>
</div>
</template>

<script>
import SelectInput from "@/components/SelectInput";
export default {
    name: "config-question-select",
  components: {SelectInput},
  props: {
      question: {
        type:Object,
        required: true
      },
      answer: {
        type:Object,
        required: true
      }
    },
    data: function(){
        return {}
        },
    methods: {},
    computed: {
      selections: function(){
        return this.question.options.map((option) => {
          return option.label
        })
      }
    }
}
</script>

<style scoped>


.config-question-select {

}

</style>
