


<template>
<div class="summary-export max-w-3xl">
  <div class="mb-8">
    <p class="py-4 px-6 bg-gray-100 border rounded font-bold" v-if="options.instructions">{{ options.instructions }}</p>
  </div>




  <div class="py-4" v-if="analysis && analysis.exportSettings">
    <div>
      <h2 class="font-bold text-xl pb-2 border-b-2">Export Options</h2>

    </div>
    <div class="flex flex-wrap mb-4">
      <div class="w-1/2">


          <h3 class="text-lg font-bold mt-6 mb-2">Background & Summary</h3>
          <div class="flex items-center py-2">
            <h2 class="">Show <span @click="showBackgroundNotes" class="underline hover:text-brand-blue cursor-pointer">Background Notes</span></h2>
          </div>
          <div class="flex items-center py-2">
            <input @change="onChange"  type="checkbox" id="summaryNotes" class="flex-shrink-0 w-6 h-6" v-model="analysis.exportSettings.notes.summary"/>
            <h2 class="ml-4">Show <span @click="showSummaryNotes" class="underline hover:text-brand-blue cursor-pointer">Summary Notes</span></h2>
          </div>
      </div>
      <div class="w-1/2">

        <h3 class="text-lg font-bold mt-6 mb-2">Add Response Inputs</h3>
        <div class="flex items-center py-2">
          <input @change="onChange" type="radio" :value="false" class="flex-shrink-0 w-6 h-6" v-model="analysis.exportSettings.showBlanks" />
          <div class="ml-4">
            <h2 class="">No Response Inputs</h2>
          </div>

        </div>

        <div class="flex py-2">
          <input @change="onChange" type="radio" :value="true" class="flex-shrink-0 w-6 h-6" v-model="analysis.exportSettings.showBlanks" />
          <div class="ml-4">
            <h2 class="">Under All Questions</h2>
            <p class="text-sm">Draws blank lines under each question for further input.</p>
          </div>

        </div>

      </div>

    </div>
    <div>
      <div class="flex flex-wrap">

        <div class="w-1/2">


          <h3 class="text-lg font-bold mt-6 mb-2">Show Questions For</h3>
          <div class="flex items-center py-2" >
            <input @change="onToggleAllDepartments"  type="checkbox" id="all"  class="flex-shrink-0 w-6 h-6" value="all" v-model="analysis.exportSettings.departments"/>
            <h2 class="ml-4">All Departments</h2>
          </div>
          <div class="flex items-center py-2" v-for="department in departments" :key="department">
            <input @change="onChangeDepartment"  type="checkbox" :id="department"  class="flex-shrink-0 w-6 h-6" :value="department" v-model="analysis.exportSettings.departments"/>
            <h2 class="ml-4">{{ api.schema.voiceOptions[department].label }}</h2>
          </div>



        </div>


        <div class="w-1/2">
          <div>

          <h3 class="text-lg font-bold mt-6 mb-2">Show Responses From <span class="text-sm ml-1">(toggle all <input type="checkbox" v-model="showAll" @change="onToggleAllRespondents" />)</span></h3>

          <div class="flex items-center py-2" v-for="respondent in respondents" :key="respondent">
            <input @change="onChange"  type="checkbox" :id="respondent" class="flex-shrink-0 w-6 h-6" :value="respondent" v-model="analysis.exportSettings.respondents"/>
            <div class="ml-4">
              <h2 class="">{{ analysis.respondents[respondent].name }}</h2>
              <p class="text-sm">{{ analysis.respondents[respondent].title }}</p>
            </div>

          </div>
          </div>



        </div>



      </div>

    </div>



  </div>

  <div class="pt-8 mt-4 border-t">
    <div class="pb-4 flex justify-between">
      <div class="pr-4">

      </div>
      <div class="pl-4">
        <button class="button button-blue button-next" @click="generateDoc">{{ saving?'Processing...':'Generate Summary Document'}}</button>
      </div>

    </div>
  </div>




</div>
</template>

<script>

export default {
    name: "needs-summary-export",
  components: {},
  props: {
      analysis: {
        type: Object,
        required: true
      },
      api: {
        type: Object,
        required: true
      },
      options: {
        default: function(){
          return {
            label: "Solution Costs",
            costsKey: "",
            instructions: "Costs instructions"
          }
        }
      }
    },
    mounted() {
        if(!this.analysis.exportSettings){
          this.analysis.exportSettings = {
            notes: {
              summary: true,
              background: true
            },
            respondents: [],
            departments: ["all"],
            showBlanks: true
          }
        }

        if(typeof(this.analysis.exportSettings.departments) === "string"){
          if(this.analysis.exportSettings.departments.length){
            this.analysis.exportSettings.departments = [this.analysis.exportSettings.departments]
          }else{
            this.analysis.exportSettings.departments = ["all"]
          }
        }


        if(!this.analysis.exportSettings.respondents.length){
          this.analysis.exportSettings.respondents = Object.keys(this.analysis.respondents);
        }

    },
  data: function(){
        return {
          hasUnsavedChanges: false,
          showAll: true,
          saving: false
        }
        },
    methods: {

      showBackgroundNotes: function(){
        this.$router.push({
          name: "NeedsAnalysisDetails",
          params: this.$route.params,
          query: {
            tab: "summary",
            section: "reportbackground"
          }
        })
      },
      showSummaryNotes: function(){
        this.$router.push({
          name: "NeedsAnalysisDetails",
          params: this.$route.params,
          query: {
            tab: "summary",
            section: "reportsummary"
          }
        })
      },

      onToggleAllRespondents: function(){
        if(this.showAll){
          this.exportSettings.respondents = [...this.respondents];
        }else{
          this.exportSettings.respondents = []
        }
      },

      onToggleAllDepartments: function(){

          this.exportSettings.departments = ["all"];


        this.onChangeDepartment();

      },

      onChange: function(){
          this.hasUnsavedChanges = true;
      },

      onChangeDepartment: function(){
        console.log("change department",this.exportSettings.departments )
        this.hasUnsavedChanges = true;

        if(this.exportSettings.departments.length > 1){
          if(this.exportSettings.departments.includes("all")){
            this.exportSettings.departments = this.exportSettings.departments.filter((dep) => {
              return dep !== "all";
            })
          }
        }




        this.analysis.exportSettings.respondents = this.respondents.filter((respondent) => {
          if(this.exportSettings.departments.includes("all")){
            return true
          }else{
            let respondentInfo = this.analysis.respondents[respondent];
            if(respondentInfo){
              if(this.exportSettings.departments.includes(respondentInfo.department)){
                return true;
              }
              if(respondentInfo.department === "notes"){
                return true;
              }
            }
          }
        })
      },

      newResponse: function(noteType){
        let noteInfo = {
          questionId: noteType,
          category: "notes"
        }
        this.$emit("note",noteInfo)
      },

      editResponse: function(response){
        let noteInfo = {
          questionId: response.question,
          category: "notes",
          responseId: response.id
        }
        this.$emit("note", noteInfo)
      },

      generateDoc: async function(){

        if(this.saving){
          return;
        }

        if(this.hasUnsavedChanges){
          this.saving = true;
          let userEmail = this.$store.getters.getUserProfile.email;

          console.log("saving");

          await this.api.update(this.analysis.id, {
            exportSettings: this.analysis.exportSettings
          }, userEmail).then(() =>{
            this.saving = false;
            this.hasUnsavedChanges = false;
            return true;
          }).catch((error) => {
            console.error(error);
            alert("Settings could not be saved. Try again.");
            this.saving = false;
            return false;
          })
        }


        this.$router.push({
          name: "NeedsReport",
          query: {
            r: this.$route.params.analysis,
            t: "na"
          }
        })
      },

    },
    computed: {

      exportSettings: function(){
        if(this.analysis){
          return this.analysis.exportSettings;
        }

        return null

      },

      respondents: function(){
        if(this.analysis && this.analysis.respondents){
          return Object.keys(this.analysis.respondents).map((id) => {
            return id;
          })
        }

        return []
      },

      respondentsForDepartment: function(){
        if(this.respondents.length){
          return [...this.respondents];
        }

        return [];
      },

      departments: function(){
          return Object.keys(this.api.schema.voiceOptions).map((id) => {
            return id;
          })
      },



    }
}
</script>

<style scoped>


.summary-export {

}

</style>
