<template>
    <div :class="classObject">
        <div class="label-box">
            <form-radio-dot class="switch-dot" :checked="checked"></form-radio-dot>
            <span class="box-label">{{ label }}</span>
        </div>
        <p v-if="description" class="description-text">
            {{ description }}
        </p>
    </div>
</template>

<script>

    import FormRadioDot from "./FormRadioDot.vue";

    export default {
        components: {
            FormRadioDot
        },
        props: {
            checked: {
                type: Boolean,
                required: false,
                default: false
            },
            label: {
                type: String,
                required: true
            },

            description: {
                type: String,
                required: false,
                default: "Description goes here"
            }
        },
        computed: {
            classObject: function(){
                return {
                    "pullout-question-multi-switch-list-item":true,
                    "checked": this.checked
                }
            }
        },

        name: "pullout-question-multi-switch-list-item"
    }
</script>

<style scoped>


    .pullout-question-multi-switch-list-item {

        box-sizing: border-box;
        color: var(--dark-gray);
        padding: 1em 0;
    }

    .label-box {
        display: flex;
        align-content: center;
        align-items: center;
    }

    .switch-dot {
        font-size: 0.8em;
    }

    .box-label {
        margin-left: 1em;
        font-weight: bold;
    }

    .pullout-question-multi-switch-list-item.checked {
        color: var(--near-black-1);
    }

    .description-text {
        padding-top: 0.5em;
        margin-left: 2.75em;
        box-sizing: border-box;

    }

</style>
