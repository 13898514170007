<template>
  <div class="side-panel absolute top-0 right-0 w-full h-full bg-white border-l bg-gray-50">
    <div class="flex justify-between px-8 py-4 border-b bg-white">
      <h2 class="text-xl leading-none">Create User Profile</h2>
      <img @click="close" class="h-4 inline-block mt-1 cursor-pointer opacity-50 hover:opacity-100" src="../assets/icons/close-icon.svg" />
    </div>
    <div class="p-8">
      <div class="">

        <div class="mt-4">
          <label class="text-sm" :data-invalid="!validFirstname">First Name</label>
          <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="userProfile.firstname"  />
        </div>

        <div class="mt-4">
          <label class="text-sm" :data-invalid="!validLastname" >Last Name</label>
          <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="userProfile.lastname" />
        </div>

        <div class="mt-4">
          <label class="text-sm">Organization</label>
          <organization-input v-model="orgProfile"></organization-input>
        </div>

        <div class="mt-4">
          <label class="text-sm" :data-invalid="!validEmail">User Email</label>
          <input type="text" class="block w-full border p-2 mt-1 rounded lowercase" v-model="userProfile.email"  />
        </div>

        <div class="mt-4">
          <label class="text-sm" :data-invalid="!validPassword">Temporary Password (12 characters or more)</label>
          <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="tempPassword" />
        </div>

        <p v-if="error" class="my-4 p-2 bg-red-500 text-white">{{ error }}</p>

        <p v-if="warning" class="my-4 p-2 bg-yellow-100 text-black border">{{ warning }}</p>

        <div class="mt-4">
          <p>Once this user is created, you will go back to the admin panel to grant them app access.</p>
        </div>


        <div class="pt-6 mt-4 border-t">
          <div class="pb-4 flex justify-between">
            <div class="mr-8">
              <button class="button border-gray-400" @click="close">Cancel</button>
            </div>
            <div class="">
              <button class="button button-blue" @click="create">Create</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

import OrganizationInput from "@/components/OrganizationInput";
export default {
  name: "side-panel-new-user",
  components: {OrganizationInput},
  props: {},
  data: function () {
    return {
      userProfile: {
        email: "",
        firstname: "",
        lastname: "",
        title: "",
        organization: "",
        level: "0",
        domain: "",
        status: "pending"
      },

      orgProfile: {
        "organizationName" : "",
            "organizationCode": "",
            "organizationCode2": "",
            "organizationId": "",
      },
      error: "",
      tempPassword: ""
    }
  },
  methods: {
    close: function(){
      this.$emit('close');
    },

    create: function() {

      if(!this.isValid){
        alert("Please fill out of the required fields");
        return;
      }

      let admin = this.$store.getters.getUserProfile;

      this.userProfile.email = this.userProfile.email.toLowerCase();

      this.userProfile.organization = this.orgProfile.organizationName;
      this.userProfile.organizationId = this.orgProfile.organizationId;


      let user = { ...this.userProfile, creator: { id: admin.userid, username: admin.email, firstname: admin.firstname, lastname: admin.lastname} }
      let pwd = prompt("To perform this operation, please enter your admin password");
      let temp = this.tempPassword;

      if(pwd){
        let payload = {
          user, admin, pwd, temp
        }
        this.$store.dispatch("addUser", payload)
        .then((response) => {
          if(response.error){
            console.log("Operation failed", response.error.description);
            if(response.error.description && response.error.description.includes("not unique")){

              this.error = "Operation failed. This email is already being used."
              alert(this.error);

            }else{
              this.error = "Operation failed. "+response.error.description;
              alert(this.error);
            }

          }else{
            console.log("User created", response)
            alert("User created. You can now go to the admin panel and set up the user app access.")
            this.$emit("created");
          }

        })
        .catch((error) => {
          console.log("Failed", error)
          alert("This operation has failed. Please try again.")
        })
      }

    }
  },
  computed: {

    validPassword: function(){
      return (this.tempPassword.length > 11);
    },

    validEmail: function() {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.userProfile.email)
    },

    validFirstname: function() {
      return this.userProfile.firstname.length > 0;
    },

    validLastname: function() {
      return this.userProfile.lastname.length > 0;
    },

    isValid: function(){
      return (this.validEmail && this.validFirstname && this.validLastname && this.validPassword);
    },

    warning: function(){
      if(!this.isValid){
        if(this.validFirstname && this.validLastname){
          if(this.validEmail){
            return "Please enter a longer password"

          }else{
            return "Please enter a valid email"
          }
        }

        return "Please enter all required fields"
      }

      return ""

    }
  }
}
</script>

<style scoped>


.side-panel-new-user {

}

label[data-invalid="true"]{
  @apply bg-yellow-200
}

</style>