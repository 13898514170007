


<template>
<div class="report-generator">
  <div class="px-8 py-4 border-b w-full sticky top-0 bg-white z-30 flex items-center justify-end">

    <button class="button mr-4" @click="saveEdits" v-if="hasUnsavedChanges">Save Edits</button>
    <button class="button button-blue" @click="exportDoc">Export Doc</button>
    <div class="w-8 h-8 p-2 border-gray-300 border-2 rounded-full flex justify-center items-center mt-1 ml-3 opacity-50 hover:opacity-100 cursor-pointer" @click="exitPreview">
      <img  class="h-3 inline-block " src="../assets/icons/close-icon.svg" />
    </div>
  </div>

  <div class="m-8 mt-16 report" v-if="r" ref="report" id="report">

    <section class="">
      <table class="titlebox absolute text-white" style="font-size: 1vw; left: 11em; width:28em; margin-top: 11em;">
        <tr><td class="font-bold" style="font-size: 2.5em; padding-bottom: 0.5em;" data-cell-style="TitlePageTitleCell">FINDINGS REPORT</td></tr>
        <tr><td class="font-bold leading-none" style="font-size: 2em; padding-bottom: 1em;" data-cell-style="TitlePageSubtitleCell">Production Workflow Snapshot Assessment</td></tr>
        <tr><td class="leading-snug" style="font-size: 1.2em;" data-cell-style="TitlePageCell">Confidential: For the exclusive use of Ricoh and <span>{{ r.customerProfile.organizationName }}</span>.  This document cannot be shared with any other parties without written permission from all of the parties referenced here.</td>
        </tr>
        <tr><td style="padding-bottom: 2em;">  </td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell">V1.0</td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell"><span>{{ r.userProfile.firstname }} {{ r.userProfile.lastname }}</span></td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell"><span>{{ r.userProfile.title }}</span></td></tr>
        <tr><td style="font-size: 1.2em;" data-cell-style="TitlePageCell"><span>{{ currentDate }}</span></td></tr>
      </table>

      <img class="w-full -mx-8 block" data-float="true" data-ratio="1.2941" src="/reports/title-bkg.png" />



      <p></p>



    </section>
    <section>
      <p class="font-bold no-mb">Prepared for:</p>
      <p class="font-bold no-mb "><span >{{ r.customerProfile.organizationName }}</span> - <span >{{ r.customerProfile.customerContact }}</span></p>
      <p class="no-mb"><span >{{ r.customerProfile.address }}</span></p>
      <p class="no-mb"><span >{{ r.customerProfile.city }}</span>, <span >{{ r.customerProfile.state }}</span> <span >{{ r.customerProfile.postalcode }}</span></p>
      <p class="mt-8 no-mb font-bold">Prepared by:</p>
      <p class="no-mb"><span>{{ r.userProfile.firstname }}</span> <span>{{ r.userProfile.lastname }}</span></p>
      <p class="no-mb" v-if="r.userProfile.organization"><span>{{ r.userProfile.organization }}</span></p>
      <p class="no-mb"><span>{{ r.userProfile.title }}</span></p>
      <p class="no-mb mt-8"><span>{{ currentDate }}</span></p>
    </section>





    <section>
      <h1>Overview</h1>
        <h2>Background</h2>
        <p>Print and mail operations face a number of market challenges as the print industry changes. To thrive in today’s environment, print organizations must be innovative, adaptable, and central to the ongoing mission of the customers they serve. Understanding the customer, improving efficiency, ensuring compliance, providing added value, expanding services, and focusing on continuous improvement are all critical components of a successful print operation.</p>
        <p>To retain and increase relevance with customers, it is important to take into account the following workflow components:</p>
        <ul>
          <li>Quoting process</li>
          <li>Job ticketing procedures</li>
          <li>Production planning</li>
          <li>Production monitoring</li>
          <li>Inventory management</li>
          <li>Shipping and delivery methods</li>
          <li>Financial and production reports</li>
          <li>Customer interaction</li>
          <li>Systems integration</li>

        </ul>

      <p><span >{{ r.customerProfile.organizationName }}</span> benchmarked their operations against industry best practices to determine opportunities for improvements to their print operations that have the potential to increase efficiency, reduce cost, provide added value and increased relevancy of their print operation.</p>

      <h2>Methodology</h2>
      <p><span>{{ r.userProfile.firstname }}</span> <span>{{ r.userProfile.lastname }}</span>, Ricoh’s <span>{{ r.userProfile.title }}</span> visited <span >{{ r.customerProfile.organizationName }}</span> on <span >{{ r.customerProfile.assessmentDate }}</span> to conduct a Production Workflow Snapshot Assessment. The assessment goals were to review job production workflows to identify areas of possible improvement and potential issues of compliance.</p>
        <p>Processes reviewed:</p>
        <ul class="list-disc">
          <li>Estimating</li>
          <li>Order Processing</li>
          <li>Job Ticket Development</li>
          <li>Production Workflow</li>
          <li>Finishing</li>
          <li>Inventory Management</li>
          <li>Shipping</li>
          <li>Billing/Invoicing</li>
          <li>Report Development</li>
        </ul>

      <h2>Site</h2>
        <p class="no-mb"><span >{{ r.customerProfile.organizationName }}</span> - <span >{{ r.customerProfile.customerContact }}</span></p>
        <p class="no-mb"><span >{{ r.customerProfile.address }}</span></p>
        <p class="no-mb"><span >{{ r.customerProfile.city }}</span>, <span >{{ r.customerProfile.state }}</span> <span >{{ r.customerProfile.postalcode }}</span></p>

      <h2>Departments Reviewed</h2>
        <table>
          <tr>
            <th>No.</th><th class="text-left">Departments/Area</th>
          </tr>
          <tr>
            <td>1</td><td>Management-Director, Manager, Supervisor, Sales</td>
          </tr>
          <tr>
            <td>2</td><td>Administration-Estimating, Job Ticketing, Customer Service</td>
          </tr>
          <tr>
            <td>3</td><td>Pre-Press</td>
          </tr>
          <tr>
            <td>4</td><td>Production Print</td>
          </tr>
          <tr>
            <td>5</td><td>Bindery/Finishing</td>
          </tr>
          <tr>
            <td>6</td><td>Fulfillment/Kitting</td>
          </tr>
          <tr>
            <td>7</td><td>Shipping/Receiving</td>
          </tr>
        </table>

    </section>
    <section>
      <h1>Current State</h1>
      <h2>Organization</h2>
      <p><span >{{ r.customerProfile.organizationName }}</span>’s production operations process <span title="I1003.final" @click="onSpanClick">{{ q.I1003.answer.final | decimal(0) }}</span> jobs per year with yearly sales of $<span title="I1001.final" @click="onSpanClick">{{ q.I1001.answer.final | decimal(0) }}</span>. They have a total of <span title="I1010.final" @click="onSpanClick">{{ q.I1010.answer.final | decimal(0) }}</span> employees with the following number of employees in different work groups:</p>
      <table>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Management:</td><td><span title="I1010A.final" @click="onSpanClick">{{ q.I1010A.answer.final | decimal(2) }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Estimators:</td><td><span title="I1011.final" @click="onSpanClick">{{ q.I1011.answer.final | decimal(2) }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Administrative/Job Ticket Devs/CSRs:</td><td><span title="I1011A.final" @click="onSpanClick">{{ q.I1011A.answer.final | decimal(2) }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Pre-Press:</td><td><span title="I1010D.final" @click="onSpanClick">{{ q.I1010D.answer.final | decimal(2) }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Production Print:</td><td><span title="I1010E.final" @click="onSpanClick">{{ q.I1010E.answer.final | decimal(2) }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Bindery/Finishing:</td><td><span title="I1010F.final" @click="onSpanClick">{{ q.I1010F.answer.final | decimal(2) }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Fulfillment/Kitting:</td><td><span title="I1010G.final" @click="onSpanClick">{{ q.I1010G.answer.final | decimal(2) }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Shipping/Receiving:</td><td><span title="I1010H.final" @click="onSpanClick">{{ q.I1010H.answer.final | decimal(2) }}</span></td>
        </tr>
        <tr data-height="0.25in" data-height-rule="exact">
          <td>Sales:</td><td><span title="I1014.final" @click="onSpanClick">{{ q.I1014.answer.final | decimal(2) }}</span></td>
        </tr>
      </table>
      <p class="mt-4">The main products and services offered include <span title="I1024.notes" @click="onSpanClick">{{ q.I1024.notes }}</span>. Customers of the operation include <span title="I1025.notes" @click="onSpanClick">{{ q.I1025.notes }}</span>.</p>
      <p>The volume of work produced on digital cut-sheet equipment includes <span title="I1006.final" @click="onSpanClick">{{ q.I1006.answer.final | decimal(0) }}</span> color and <span title="I1007.final" @click="onSpanClick">{{ q.I1007.answer.final | decimal(0) }}</span> monochrome impressions. The volume of work produced on offset equipment includes <span title="I1004.final" @click="onSpanClick">{{ q.I1004.answer.final | decimal(0) }}</span> color and <span title="I1005.final" @click="onSpanClick">{{ q.I1005.answer.final | decimal(0) }}</span> monochrome impressions. Wide format/signage materials produced include <span title="I1007A.final" @click="onSpanClick">{{ q.I1007A.answer.final | decimal(0) }}</span> square feet.</p>



      <h2>Equipment and Software</h2>
        <p>Equipment includes:</p>
        <p class="ml-8 no-mb font-bold">Digital:</p>
        <p class="ml-8 font-bold"><span title="I1025A.notes" @click="onSpanClick">{{ q.I1025A.notes }}</span></p>
        <p class="ml-8 no-mb font-bold">Offset:</p>
        <p class="ml-8 font-bold"><span title="I1025B.notes" @click="onSpanClick">{{ q.I1025B.notes }}</span></p>
        <p class="ml-8 no-mb font-bold">Finishing:</p>
        <p class="ml-8 font-bold"><span title="I1025C.notes" @click="onSpanClick">{{ q.I1025C.notes }}</span></p>
        <p class="ml-8 no-mb font-bold">Software:</p>
        <p class="ml-8 font-bold"><span title="I1025E.notes" @click="onSpanClick">{{ q.I1025E.notes }}</span></p>

      <h2>Workflow</h2>
        <h3>Estimating</h3>
        <p><span title="I1041.0" @click="onSpanClick">{{ q.I1041.answer.Current | decimal(0)  }}</span> estimates are produced per month. There are <span title="I1011.0" @click="onSpanClick">{{ q.I1011.answer.final }}</span> estimators on staff who spend 100% of their time producing estimates. It takes approximately <span title="I1042.0" @click="onSpanClick">{{ q.I1042.answer.Current | decimal(0)  }}</span> minutes to produce each estimate.</p>

        <h3>Job Ticket Development</h3>
        <p><span title="I1059.0" @click="onSpanClick">{{ q.I1059.answer.Current | decimal(0) }}</span> job tickets are produced each year. They are developed by a team of <span title="I1011A.0" @click="onSpanClick">{{ q.I1011A.answer.final }}</span> customer service reps. On average, it takes <span>{{ q.I1067A.answer.final | decimal(0) }}</span> minutes to develop each job ticket.</p>
        <p>Challenges and opportunities for Job Ticket Development include <span title="I1048A.notes" @click="onSpanClick">{{ q.I1048A.notes }}</span>.</p>

        <h3>Inventory</h3>
        <p><span title="I1068.0" @click="onSpanClick">{{ q.I1068.answer.Current | decimal(0) }}</span> inventory items are managed for print production and <span title="I1069.0" @click="onSpanClick">{{ q.I1069.answer.Current | decimal(0) }}</span> inventory items are managed that are finished goods/fulfillment items. <span title="I1070.0" @click="onSpanClick">{{ q.I1070.answer.Current | decimal(0) }}</span> minutes are spent each month managing all inventory items.</p>

        <h3>Purchasing / Accounts Payable</h3>
        <p>It takes <span title="I1084.0" @click="onSpanClick">{{ q.I1084.answer.Current }}</span> minutes to create a PO and <span title="I1085.0" @click="onSpanClick">{{ q.I1085.answer.Current }}</span> minutes to reconcile a PO. There are <span title="I1086.0" @click="onSpanClick">{{ q.I1086.answer.Current | decimal(0)  }}</span> POs processed per month. It is estimated that it costs an additional <span title="I1089.0" @click="onSpanClick">{{ q.I1089.answer.Current }}</span>% to purchase printed materials from external sources.</p>

        <h3>Job Tracking / Scheduling</h3>
        <p><span title="I1012.0" @click="onSpanClick">{{ q.I1012.answer.final }}</span> employees are involved in tracking jobs daily. Production meetings are scheduled for <span title="I1098.0" @click="onSpanClick">{{ q.I1098.answer.Current }}</span> minutes per day and are attended by <span title="I1099.0" @click="onSpanClick">{{ q.I1099.answer.Current | decimal(2)  }}</span> employees. Each administrative person spends <span title="I1096.0" @click="onSpanClick">{{ q.I1096.answer.Current }}</span> minutes per day tracking jobs and each management employee spends <span title="I1097.0" @click="onSpanClick">{{ q.I1097.answer.Current }}</span> minutes per day tracking jobs.</p>

        <h3>Production - Pre-Press</h3>
        <p>The process for preflighting files and prepping for production includes <span title="I1100.notes" @click="onSpanClick">{{ q.I1100.notes }}</span>.</p>

        <h3>Production - Press / Copy / Bindery / Finishing</h3>
        <p>The production area is notified of a project to plan their workload from <span title="I1102.notes" @click="onSpanClick">{{ q.I1102.notes }}</span>. Production employees’ labor is tracked and <span title="I1127A.notes" @click="onSpanClick">{{ q.I1127A.notes }} minutes is spent tracking labor daily.</span>.</p>

        <h3>Production - Kitting / Fulfillment</h3>
        <p>There are <span title="I1057.0" @click="onSpanClick">{{ q.I1057.answer.Current | decimal(0)  }}</span> jobs processed for kitting or fulfillment monthly.</p>

        <h3>Distribution - Mailing / Shipping / Delivery</h3>
        <p><span title="I1114.0" @click="onSpanClick">{{ q.I1114.answer.Current }}</span>% of jobs require UPS or FedEx tracking and it takes approximately <span title="I1113.0" @click="onSpanClick">{{ q.I1113.answer.Current }}</span> minutes each to track these jobs. <span title="I1115.0" @click="onSpanClick">{{ q.I1115.answer.Current | decimal(0)  }}</span> minutes are spent creating shipping documents for projects. Shipping costs are missed on approx. <span title="I1116.0" @click="onSpanClick">{{ q.I1116.answer.Current | decimal(0)  }}</span> jobs per month with an estimated cost of $<span title="I1117.0" @click="onSpanClick">{{ q.I1117.answer.Current | decimal(2)  }}</span> each.</p>

        <h3>Billing / Accounts Receivable</h3>
        <p>The invoicing process generally takes <span title="I1087.0" @click="onSpanClick">{{ q.I1087.answer.Current }}</span> minutes per project. Projects are invoiced <span title="I1123.0" @click="onSpanClick">{{ q.I1123.answer.Current }}</span> days after job completion. Payments are generally received from our customers in <span title="I1124.0" @click="onSpanClick">{{ q.I1124.answer.Current }}</span> days.</p>

        <h3>Labor Tracking / Management Reports</h3>
        <p>Labor is not tracked.</p>

    </section>
    <section>
      <h1>Findings</h1>
      <h2>Production Workflow Scorecard Summary</h2>
      <p><span >{{ r.customerProfile.organizationName }}</span>’s Production Workflow is rated as <span class="uppercase">{{ scorecardGrade }}</span> with a rating of {{ r.scorecard.final }} on a scale of 1 to 5.  The overall rating would rise if some of the issues discussed in this document could be rectified. </p>
      <assessment-scorecard data-svg="true" data-ratio="0.8" title="Production Workflow Scorecard" answer-key="final" :assessment="r" :questions="survey"></assessment-scorecard>

      <p class="mt-4">See Appendix 1 for a detailed Grading Scale of the Best Practices Scorecard.</p>
      <p>The current management team has made changes and improvements over time within the constraints of their budgets and resources availability. However, substantial room for improvement exists with respect to print cost tracking, profitability tracking, document design, inventory and other. </p>

    </section>

    <section>
      <h1>Recommendations</h1>
      <p>Based on our assessment of your production operation, Ricoh recommends incorporating the following software solutions to streamline <span >{{ r.customerProfile.organizationName }}</span>'s workflow.</p>


      <h2 v-if="solutions.MIS">Proposed Solution (MIS)</h2>
      <component data-container="true" v-if="solutions.MIS" :is="solutions.MIS.component"></component>

      <h3 v-if="solutions.MIS">Benefits</h3>
      <p v-if="solutions.MIS">The proposed solution will provide a streamlined workflow whereby both administrative and production staff will benefit. Info on streamlined workflow. Build workflow in findings report powerpoint and then copy here.</p>

      <h3 v-if="solutions.MIS">Investment</h3>
      <p v-if="solutions.MIS">The investment for the proposed solution is estimated* at:</p>
      <ul v-if="solutions.MIS">
        <li>{{ solutions.MIS.name }} – Initial Investment: ${{ r.costs.mis.totals.initial | decimal(0) }}; Yearly Maintenance: ${{ r.costs.mis.maintenance.year1 | decimal(0) }}</li>
      </ul>
      <p v-if="solutions.MIS">*Estimate is based on standard configuration. A formal proposal will be provided at your request.</p>


      <h2 v-if="solutions.Storefront">Proposed Solution (Online Storefront)</h2>
      <component  data-container="true" v-if="solutions.Storefront"  :is="solutions.Storefront.component"></component>

      <h3 v-if="solutions.Storefront">Benefits</h3>
      <p v-if="solutions.Storefront">The proposed solution will provide a streamlined workflow whereby both administrative and production staff will benefit. Info on streamlined workflow. Build workflow in findings report powerpoint and then copy here.</p>

      <h3 v-if="solutions.Storefront">Investment</h3>
      <p v-if="solutions.Storefront">The investment for the proposed solution is estimated* at:</p>
      <ul v-if="solutions.Storefront">
        <li>{{ solutions.Storefront.name }} – Initial Investment: ${{ r.costs.storefront.totals.initial | decimal(0) }}; Yearly Maintenance: ${{ r.costs.storefront.maintenance.year1 | decimal(0) }}</li>
      </ul>
      <p v-if="solutions.Storefront">*Estimate is based on standard configuration. A formal proposal will be provided at your request.</p>

      <h2 v-if="solutions.VDP">Proposed Solution (VDP)</h2>
      <component  data-container="true" v-if="solutions.VDP"  :is="solutions.VDP.component"></component>

      <h3 v-if="solutions.VDP">Benefits</h3>
      <p v-if="solutions.VDP">The proposed solution will provide a streamlined workflow whereby both administrative and production staff will benefit. Info on streamlined workflow. Build workflow in findings report powerpoint and then copy here.</p>

      <h3 v-if="solutions.VDP">Investment</h3>
      <p v-if="solutions.VDP">The investment for the proposed solution is estimated* at:</p>
      <ul v-if="solutions.VDP">
        <li>{{ solutions.VDP.name }} – Initial Investment: ${{ r.costs.vdp.totals.initial | decimal(0) }}; Yearly Maintenance: ${{ r.costs.vdp.maintenance.year1 | decimal(0) }}</li>
      </ul>
      <p v-if="solutions.VDP">*Estimate is based on standard configuration. A formal proposal will be provided at your request.</p>


      <h2>Savings Detail/Revenue Summary</h2>
      <h3 v-if="solutions.MIS || solutions.Storefront">PRINT MIS and DIGITAL STOREFRONT COST SAVINGS AND ADDITIONAL REVENUE SUMMARY</h3>
      <p v-if="solutions.MIS || solutions.Storefront">Details for the targeted cost savings and potential increased revenue include:</p>
      <savings-summary-table v-if="solutions.MIS || solutions.Storefront" :assessment="r"></savings-summary-table>
      <p class="mt-8"></p>
      <h3 v-if="solutions.VDP">VDP SAVINGS</h3>
      <p v-if="solutions.VDP">Details for the targeted cost savings and potential increased revenue include:</p>
      <roi-table-vdp-details v-if="solutions.VDP" :assessment="r"></roi-table-vdp-details>



      <h2>ROI</h2>
      <h3 v-if="solutions.MIS">MIS</h3>
      <p v-if="solutions.MIS">The proposed solution provides financial payback in {{ r.costs.mis.paybackPeriod | decimal(0) }} months which includes {{ r.savings.hoursMISSavingsAnnual | decimal(0) }} man hour savings valued at ${{ r.savings.costMISSavingsAnnual | decimal(0) }} and increased revenue potential of ${{ r.savings.revenue.total | decimal(0) }} per year.</p>
      <roi-table-mis v-if="solutions.MIS" :assessment="r"></roi-table-mis>
      <p></p>
      <roi-chart-mis-potential v-if="solutions.MIS" :assessment="r" class="max-w-3xl"></roi-chart-mis-potential>
      <roi-chart-mis-current-future v-if="solutions.MIS" :assessment="r" class="max-w-3xl"></roi-chart-mis-current-future>
      <p class="mt-8"></p>
      <h3 v-if="solutions.Storefront">Online Storefront</h3>
      <p v-if="solutions.Storefront">The proposed solution provides financial payback in {{ r.costs.storefront.paybackPeriod | decimal(0) }} months which includes {{ r.savings.hoursStorefrontSavingsAnnual | decimal(0) }} man hour savings valued at ${{ r.savings.costStorefrontSavingsAnnual | decimal(0) }} and increased revenue potential of ${{ r.savings.revenue.total | decimal(0) }} per year.</p>
      <roi-table-storefront v-if="solutions.Storefront" :assessment="r"></roi-table-storefront>
      <p></p>
      <roi-chart-storefront-comparison v-if="solutions.Storefront" :assessment="r" class="max-w-3xl"></roi-chart-storefront-comparison>
      <p class="mt-8"></p>
      <h3 v-if="solutions.VDP">VDP</h3>
      <p v-if="solutions.VDP">The proposed solution provides financial payback in {{ r.costs.vdp.paybackPeriod | decimal(0) }} months which includes {{ r.costs.vdp.orderDetails.jobTicketOrder.hourSavingsAnnual | decimal(0) }} man hour savings valued at ${{ r.costs.vdp.orderDetails.jobTicketOrder.costSavingsAnnual | decimal(0) }} and reword savings of ${{ r.costs.vdp.orderDetails.rework.costSavingsAnnual | decimal(0) }} for a total savings of ${{ r.costs.vdp.orderDetails.totalSavingsAnnual | decimal(0) }} per year.</p>
      <roi-table-vdp v-if="solutions.VDP" :assessment="r"></roi-table-vdp>
      <p></p>
      <roi-chart-vdp-comparison v-if="solutions.VDP" :assessment="r" class="max-w-3xl"></roi-chart-vdp-comparison>

    </section>

    <section v-if="solutions.Other.length">
      <h1>Other Recommendations</h1>
      <component data-container="true" v-for="(solution) in solutions.Other" :key="solution.id" :is="solution.component"></component>
    </section>

    <section>
      <h1>Appendix</h1>
      <h2>Appendix 1 - Workflow Assessment Scorecard Ratings</h2>
      <table class="text-xs" data-cell-style="AppendixCell">
        <tr>
          <th></th>
          <th>5</th>
          <th>4</th>
          <th>3</th>
          <th>2</th>
          <th>1</th>
          <th>N/A</th>
        </tr>
        <tr v-for="rating in survey" :key="rating.id">
          <td>{{ rating.question }}</td>
          <td>{{  rating.criteria5 }}</td>
          <td>{{  rating.criteria4 }}</td>
          <td>{{  rating.criteria3 }}</td>
          <td>{{  rating.criteria2 }}</td>
          <td>{{  rating.criteria1 }}</td>
          <td>{{  rating.criteriaNA }}</td>
        </tr>
      </table>
    </section>


  </div>
  <transition name="slide">
    <div class="fixed w-full h-full top-0 right-0 shadow-lg z-30 bg-black bg-opacity-50" v-if="sidePanel">
      <div v-if="sourceQuestion" class="side-panel absolute w-full max-w-2xl top-0 right-0 w-full h-screen bg-white border-l bg-gray-50 overflow-y-scroll">
        <div class="flex justify-between items-center px-8 py-4 border-b bg-white">
          <p class="text-xl leading-none">{{ sourceQuestion.question.id }}</p>
          <div class="w-8 h-8 border-gray-300 border-2 rounded-full flex justify-center items-center ml-3 opacity-50 hover:opacity-100 cursor-pointer" @click="closeSidePanel">
            <img  class="h-3 inline-block " src="../assets/icons/close-icon.svg" />
          </div>
        </div>
        <div class="py-8">

          <component :is="questionTypes[sourceQuestion.question.type]"  :question="sourceQuestion.question" :answer="sourceQuestion.answer" class="question flex items-center pb-4 border-b bg-gray-50 mx-8"  @change="onChange">
          </component>

          <p class="px-8 py-4 text-red-600">Warning:<br>Any edits you make here changes this value everywhere in this assessment. Any calculations relying on this value will also be recalculated.</p>
          <p class="px-8 py-4 text-red-600">This document will need to be saved before you close this preview if you wish to keep these changes outside of preview mode.</p>

        </div>
      </div>
    </div>
  </transition>



</div>
</template>

<script>
/* eslint-disable no-unused-vars */

import { AlignmentType, PageSize, UnderlineType, BorderStyle, ShadingType, WidthType, HeightRule, Document, HeadingLevel, Media, Packer, Paragraph, TableOfContents, Table, TableRow, TableCell, TabStopPosition, TabStopType, TextRun } from "docx";
import { saveAs } from "file-saver"
import workflow from "@/schema/workflow";
import AssessmentScorecard from "@/components/AssessmentScorecard";
import SavingsSummaryTable from "@/components/SavingsSummaryTable";

import RoiTableVdp from "@/components/ROITableVDP";
import RoiTableVdpDetails from "@/components/ROITableVDPDetails";
import RoiTableMis from "@/components/ROITableMIS";
import RoiTableStorefront from "@/components/ROITableStorefront";

import AvantiSlingshot from "@/components/Solutions/AvantiSlingshot";
import EfiPace from "@/components/Solutions/EfiPace";
import PrintSmithVision from "@/components/Solutions/PrintSmithVision";

import FusionPro from "@/components/Solutions/FusionPro";
import PrintShopMail from "@/components/Solutions/PrintShopMail";

import MarcomCentral from "@/components/Solutions/MarcomCentral";
import WebCrd from "@/components/Solutions/WebCrd";
import MarketDirect from "@/components/Solutions/MarketDirect";
import PrintNet from "@/components/Solutions/PrintNet";

import ColorGap from "@/components/Solutions/ColorGap";
import RicohConsultingServices from "@/components/Solutions/RicohConsultingServices";
import ProductionWorkflow from "@/components/Solutions/ProductionWorkflow";
import RoiChartMisPotential from "@/components/ROIChartMISPotential";
import RoiChartMisCurrentFuture from "@/components/ROIChartMISCurrentFuture";
import RoiChartStorefrontComparison from "@/components/ROIChartStorefrontComparison";
import RoiChartVdpComparison from "@/components/ROIChartVDPComparison";
import AssessmentInterviewQuestionBoolean from "@/components/AssessmentInterviewQuestionBoolean";
import AssessmentInterviewQuestionMultiChoice from "@/components/AssessmentInterviewQuestionMultiChoice";
import AssessmentInterviewQuestionMultiCol from "@/components/AssessmentInterviewQuestionMultiCol";
import AssessmentInterviewQuestionSelect from "@/components/AssessmentInterviewQuestionSelect";
import AssessmentInterviewQuestionSingleCol from "@/components/AssessmentInterviewQuestionSingleCol";
import AssessmentInterviewQuestionTextField from "@/components/AssessmentInterviewQuestionTextField";



export default {
    name: "snapshot-report-generator",
  components: {
    AssessmentInterviewQuestionTextField,
    AssessmentInterviewQuestionSingleCol,
    AssessmentInterviewQuestionSelect,
    AssessmentInterviewQuestionMultiCol,
    AssessmentInterviewQuestionMultiChoice,
    AssessmentInterviewQuestionBoolean,
    RoiChartVdpComparison,
    RoiChartStorefrontComparison,
    RoiChartMisCurrentFuture,
    RoiChartMisPotential,
    ProductionWorkflow,
    RicohConsultingServices,
    ColorGap,
    PrintNet,
    WebCrd,
    MarketDirect,
    MarcomCentral,
    PrintShopMail,
    FusionPro,
    RoiTableStorefront,
    RoiTableMis,
    RoiTableVdpDetails,
    RoiTableVdp, EfiPace, AvantiSlingshot, PrintSmithVision, SavingsSummaryTable, AssessmentScorecard},
  props: {},
    mounted: function(){
      if(this.$route.query.r){
        this.prepareReport(this.$route.query.r);
      }
    },
    data: function(){
        return {
          hasUnsavedChanges: false,
          solutionIds: "000000000000",
          disabled: true,
          r: undefined,
          sidePanel: false,
          sourceQuestion: undefined,
          solutionList: {

            "AvantiSlingshot": {name: "Avanti Slingshot", component: "AvantiSlingshot", category: "MIS"},
            "EfiPace": {name: "EFI Pace", component: "EfiPace", category: "MIS"},
            "PrintSmithVision": {name: "PrintSmith Vision", component: "PrintSmithVision", category: "MIS"},
            "FusionPro": {name: "Fusion Pro", component: "FusionPro", category: "VDP"},
            "PrintShopMail": {name: "PrintShop Mail", component: "PrintShopMail", category: "VDP"},
            "MarcomCentral": {name: "Marcom Central", component: "MarcomCentral", category: "Storefront"},
            "WebCrd": {name: "Web CRD", component: "WebCrd", category: "Storefront"},
            "MarketDirect": {name: "Market Direct", component: "MarketDirect", category: "Storefront"},
            "PrintNet": {name: "PrintNet", component: "PrintNet", category: "Storefront"},
            "ColorGap": {name: "Color Gap Analysis", component: "ColorGap", category: "Other"},
            "RicohConsultingServices": {
              name: "Ricoh Consulting Services",
              component: "RicohConsultingServices",
              category: "Other"
            },
            "ProductionWorkflow": {name: "Production Workflow", component: "ProductionWorkflow", category: "Other"},
          }
        }
    },
    methods: {

      onChange: function(){
        console.log("changes");
        this.$sdm.calculate(this.r);
        this.hasUnsavedChanges = true;
      },

      closeSidePanel: function(){
        this.sidePanel = false;
      },

      exitPreview: function(){
        if(this.hasUnsavedChanges){
          if(!confirm("You have unsaved edits. If you leave now, you will lose those changes.")){
            return;
          }
        }
        this.$router.back();
      },

      saveEdits: function(){

        if(confirm("This will save any edits you make in preview mode. Are you sure?")){
          this.$sdm.updateAssessment(this.r, this.r.id).then(() => {
            alert("Your edits have been saved.");
            this.hasUnsavedChanges = false;
          }).catch((error) => {
            console.error(error);
            alert("There was a problem with this operation. Try again.")
          })
        }

      },


      onSpanClick: function(e){
        if(e.target.title){
            let qPath = e.target.title.split(".");
            if(qPath[0].startsWith("I")){
              let question = this.iQ[qPath[0]];
              let answer = this.q[qPath[0]];


              if(question){
                this.sourceQuestion = {
                  title: "Interview Question",
                  answer: answer,
                  question: question
                }
                this.sidePanel = true;
                return;
              }
            }
        }


      },

      prepareReport: function(id){
        this.$sdm.loadAssessment(id).then((response) => {
          this.$sdm.calculate(response);
          this.r = response;
        })
        .then(() => {
          this.numberHeadings();
        })
      },

      numberHeadings: function(){

        let counters = {
          H1: 0,
          H2: 0,
          H3: 0
        }

        this.$refs.report.querySelectorAll('section').forEach((section) => {
              section.children.forEach((node) => {
                if(node.nodeName === "H1"){
                  counters.H1++;
                  counters.H2 = 0;
                  counters.H3 = 0;
                  node.dataset.num = counters.H1+".";
                }else if(node.nodeName === "H2"){
                  counters.H2++;
                  counters.H3 = 0;
                  node.dataset.num = counters.H1 +"."+counters.H2
                }else if(node.nodeName === "H3"){
                  counters.H3++;
                  node.dataset.num = counters.H1 +"."+counters.H2+"."+counters.H3;
                }else if(node.dataset.container){
                  node.children.forEach((subnode) => {
                    if(subnode.nodeName === "H1"){
                      counters.H1++;
                      counters.H2 = 0;
                      counters.H3 = 0;
                      subnode.dataset.num = counters.H1+".";
                    }else if(subnode.nodeName === "H2"){
                      counters.H2++;
                      counters.H3 = 0;
                      subnode.dataset.num = counters.H1 +"."+counters.H2
                    }else if(subnode.nodeName === "H3"){
                      counters.H3++;
                      subnode.dataset.num = counters.H1 +"."+counters.H2+"."+counters.H3;
                    }
                  })
                }
              });
        })
      },


      convertSVG: function(svg){

          if(svg){
            let svgXML = (new XMLSerializer()).serializeToString(svg);
            let canvas = document.createElement('canvas');
            canvas.width = 1000;
            canvas.height = 1000 * parseFloat(svg.dataset.ratio);

            let ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            let image = new Image();
            image.src = "data:image/svg+xml;base64," + btoa(svgXML);

            ctx.drawImage(image, 0, 0);

            image.onload = () => {
               ctx.drawImage(image, 0, 0);
               this.swapImage(image, svg);
            }


          }

      },

      swapImage: function(){

      },

      createDocTable: function(node){

          let defaultCellStyle = "Basic";

          if(node.dataset.cellStyle){
            defaultCellStyle = node.dataset.cellStyle;
          }


          let tableRows = [];

          let rows = [];

          node.children.forEach((tgroup) => {
            if(tgroup.nodeName === "TR"){
              rows.push(tgroup);
            }else{
              tgroup.children.forEach((trow) => {
                rows.push(trow);
              })
            }
          })

          rows.forEach((row) => {
            let cells = [];

            row.children.forEach((col) => {
              let cellStyle = col.dataset.cellStyle?col.dataset.cellStyle:defaultCellStyle;
              let cellContents = new Paragraph({ text: col.innerText, style: cellStyle});

              let margin = 120;
              if(defaultCellStyle.startsWith('Appendix')){
                margin = 60;
              }

              let borders = (cellStyle === "BlankCell")?this.noCellBorders:this.cellBorders;
              let colspan = parseInt(col.colSpan?col.colSpan:1);



              let shading = (col.nodeName === "TH")?{fill: "DBE5F1", val: ShadingType.SOLID, color: "DBE5F1"}:undefined;
              let cell = new TableCell({
                children: [cellContents],
                borders: borders,
                shading: shading,
                columnSpan: colspan,
                margins: {
                  top: margin,
                  left: margin,
                  right: margin,
                  bottom: margin
                }

              })
              cells.push(cell);
            })
            let tr = new TableRow({
              children: cells
            })

            tableRows.push(tr);
          })

          let table = new Table({
            rows: tableRows,
          })

          return table;


      },

      asyncImageLoader: function(src){
        return new Promise((resolve, reject) => {
          let img = new Image();
          img.onload = () => resolve(img)
          img.onerror = reject;
          img.src = src;
        })
      },

      processNode: function(node, children, images, counters){
        let nodeName = node.nodeName;

        if(nodeName === "HR") {
          let br = new Paragraph({
            text: "",
            border: {
              top: {
                color: "000000",
                space: 1,
                value: "single",
                size: 6
              }
            },

            spacing: {
              before: 0,
              after: 120
            }
          })

          children.push(br);

        } else if(nodeName === "BR") {
          let br = new Paragraph({
            text: "",
            pageBreakBefore: true,
            spacing: {
              before: 0,
              after: 0
            }
          })

          children.push(br);

        } else if(nodeName === "TABLE"){

          let isTitleBox = node.classList.contains('titlebox');

          if(isTitleBox){

            let tableRows = [];
            node.children.forEach((row) => {

              let cells = [];

              row.children.forEach((col) => {
                let cellStyle = col.dataset.cellStyle?col.dataset.cellStyle:"Basic";
                let cellContents = new Paragraph({ text: col.innerText, style: cellStyle});
                let cell = new TableCell({
                  children: [cellContents],
                  borders: this.noCellBorders,
                  margins: {
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0
                  }

                })
                cells.push(cell);
              })
              let tr = new TableRow({
                children: cells
              })

              tableRows.push(tr);
            })

            let table = new Table({
              rows: tableRows,
              width: { size: 35, type: WidthType.PERCENTAGE },
            })

            children.push(table);

          }else{

            let table = this.createDocTable(node);

            children.push(table);

          }

        }else if(nodeName === "svg"){

          let imageP = new Paragraph(images[node.dataset.imgId].image);
          children.push(imageP);


        }else if(nodeName === "IMG"){

          let imageP = new Paragraph(images[node.dataset.imgId].image);
          children.push(imageP);


        }else if(nodeName === "UL"){
          node.children.forEach((bullet) => {
            let bText = bullet.innerText;
            let bP = new Paragraph({
              text: bText,
              bullet: {
                level: 0
              }
            })
            children.push(bP);
          })

        }else{
          let text = node.innerText;
          let heading = nodeName.startsWith("H")?HeadingLevel['HEADING_'+nodeName.slice(1)]:"";
          if(heading){
            if(nodeName === "H1"){
              counters.H1++;
              counters.H2 = 0;
              counters.H3 = 0;
              text = counters.H1 + "  "+ text;
            }else if(nodeName === "H2"){
              counters.H2++;
              counters.H3 = 0;
              text = counters.H1 +"."+counters.H2+ "  "+ text;
            }else if(nodeName === "H3"){
              counters.H3++;
              text = counters.H1 +"."+counters.H2+"."+counters.H3+ "  "+ text;
            }
          }

          let spacing = {};
          let pstyle = undefined;

          if(node.classList.contains("bold-label")){
            pstyle = "BoldLabel"
          }

          if(node.classList.contains("title")){
            pstyle = "Title"
          }

          if(node.classList.contains("subtitle")){
            pstyle = "Subtitle"
          }

          if(node.classList.contains("titlebox")){
            pstyle = "Titlebox"
          }


          if(node.classList.contains("no-mb")){
            spacing.after = 0;
          }

          if(node.classList.contains("mb-0")){
            spacing.after = 0;
          }

          if(node.classList.contains("mt-0")){
            spacing.before = 0;
          }

          if(node.classList.contains("mt-2")){
            spacing.before = 120;
          }

          if(node.classList.contains("mt-4")){
            spacing.before = 240;
          }

          if(node.classList.contains("mt-8")){
            spacing.before = 480;
          }


          let p = new Paragraph({
            text: text,
            heading: heading,
            spacing: spacing,
            style: pstyle,
          })


          children.push(p);
        }

        return children;
      },

      processImages: async function(doc){
        var images = {};
        let imgs = this.$refs.report.querySelectorAll("img");


        for(var i=0; i<imgs.length; i++){


          console.log("fetching", imgs[i].src);
          let imgFile = await fetch(imgs[i].src).then(r => {
            console.log(r);
            return r
          });


          let imgRatio =  parseFloat(imgs[i].dataset.ratio);

          let imgOptions = undefined;

          if(imgs[i].dataset.float){
            imgOptions = { floating: {
                behindDocument: true,

                horizontalPosition: {
                  offset: 0
                },
                verticalPosition: {
                  offset: 0
                }
              }
            }
          }



          images["image"+i] = {
            image: Media.addImage(doc, imgFile.blob(), imgOptions?815:600, imgOptions?(815 * imgRatio):(600 * imgRatio), imgOptions)
          };
          imgs[i].dataset.imgId = "image"+i;
        }

        console.log("images processed");
        return images;
      },

      processSVGs: async function(doc){
        var images = {};
        let imgs = this.$refs.report.querySelectorAll("svg");


        for(var i=0; i<imgs.length; i++){

          let svg = imgs[i];
          console.log("fetching svg");

          let svgXML = (new XMLSerializer()).serializeToString(svg);
          let canvas = document.createElement('canvas');
          console.log(svg);

          let w = parseInt(svg.getAttribute("width"));
          let h = parseInt(svg.getAttribute("height"));
          console.log(w, h);
          canvas.width = w;
          canvas.height = h;
          let ctx = canvas.getContext('2d');

          let srcString =  "data:image/svg+xml;base64," + btoa(svgXML);

          let tmpImage = await this.asyncImageLoader(srcString);
          ctx.drawImage(tmpImage, 0, 0);

          let pngSrc = canvas.toDataURL("image/png");

          //console.log(pngSrc);



          let imgFile = await fetch(pngSrc).then(r => {
            console.log(r);
            return r
          });


          let imgRatio =  h/w;

          let imgOptions = undefined;

          if(imgs[i].dataset.float){
            imgOptions = { floating: {
                behindDocument: true,

                horizontalPosition: {
                  offset: 0
                },
                verticalPosition: {
                  offset: 0
                }
              }
            }
          }

          images["svg"+i] = {
            image: Media.addImage(doc, imgFile.blob(), imgOptions?815:600, imgOptions?(815 * imgRatio):(600 * imgRatio), imgOptions)
          };
          imgs[i].dataset.imgId = "svg"+i;
        }

        console.log("images processed");
        return images;
      },


      exportDoc: async function(){


        let doc = new Document({
          title: "Findings Report",
          description: "Production Workflow Assessment",
          styles: this.rStyles
        });

        // let imgs = this.$refs.report.querySelectorAll("img");
        // let images = {};
        //
        // for(var i=0; i<imgs.length; i++){
        //
        //   let imgFile = await fetch(imgs[i].src).then(r => {
        //     return r
        //   });
        //
        //
        //   let imgRatio =  parseFloat(imgs[i].dataset.ratio);
        //
        //   let imgOptions = undefined;
        //
        //   if(imgs[i].dataset.float){
        //     imgOptions = { floating: {
        //           behindDocument: true,
        //
        //           horizontalPosition: {
        //             offset: 0
        //           },
        //           verticalPosition: {
        //             offset: 0
        //           }
        //       }
        //     }
        //   }
        //
        //
        //
        //   images["image"+i] = {
        //     image: Media.addImage(doc, imgFile.blob(), imgOptions?815:600, imgOptions?(815 * imgRatio):(600 * imgRatio), imgOptions)
        //   };
        //   imgs[i].dataset.imgId = "image"+i;
        // }

        let imgs = await this.processImages(doc);

        let svgs = await this.processSVGs(doc);

        let images = { ...imgs, ...svgs }

        console.log("images loaded", images);

        let sections = this.$refs.report. querySelectorAll('section');

        //heading numbering counters
        let counters = {
          H1: 0,
          H2: 0,
          H3: 0
        }


        sections.forEach((section)=>{
          let children = [];
          section.children.forEach((node) => {

            if(node.dataset.container){
              node.children.forEach((child) => {
                children = this.processNode(child, children, images, counters);
              })
            }else{
              children = this.processNode(node, children, images, counters);
            }




          })
          doc.addSection({
            children: children,
            style: {

            },
            size: {
              width: 12240,
              height: 15840
            }
          })
        })

        Packer.toBlob(doc).then((blob) => {
          console.log(blob);
          saveAs(blob, "report_export.docx");
          console.log("Report exported");
        })


      }
    },
    computed: {

      questionTypes: function(){
        return this.$store.getters.questionTypes;
      },

      strengthsWeakness: function(){
        let _listing = {
          strength: [],
          weakness: []
        }

        if(this.r){
          Object.keys(workflow.survey).forEach((key) => {
            let question = workflow.survey[key];
            let answer = this.r.survey.questions[key];

            if(answer.answer.final && answer.answer.final !=="NA"){
              let intValue = parseInt(answer.answer.final);
              if(intValue > 3){
                _listing.strength.push({
                  title: question.question,
                  criteria: question['criteria'+intValue],
                  grade: (intValue === 4)?"Very Good":"Best-in-Class",
                  notes: answer.notes
                })
              }

              if(intValue < 3){
                _listing.weakness.push({
                  title: question.question,
                  criteria: question['criteria'+intValue],
                  grade: (intValue === 2)?"Sufficient":"Substandard",
                  notes: answer.notes
                })
              }
            }

          })
        }

        return _listing;


      },


      option2: function(){
        if(this.r){
          return (this.r.exportSettings.option === "2");
        }
        return false;
      },

      solutions: function(){


        if(this.r){
          let _solutions = {
            Other: this.r.exportSettings.solutions.Other.map((otherID)=>{
                return this.solutionList[otherID]
              })
          };

          if(this.r.exportSettings.solutions.MIS){
            _solutions.MIS = this.solutionList[this.r.exportSettings.solutions.MIS]
          }

          if(this.r.exportSettings.solutions.Storefront){
            _solutions.Storefront = this.solutionList[this.r.exportSettings.solutions.Storefront]
          }

          if(this.r.exportSettings.solutions.VDP){
            _solutions.VDP = this.solutionList[this.r.exportSettings.solutions.VDP]
          }

          return _solutions;

        }

        return {}

      },

      survey: function(){
        return workflow.survey;
      },

      iQ: function(){
        return workflow.questions;
      },

      q: function(){
        if(this.r){
          return this.r.interview.questions
        }

        return undefined;
      },

      currentDate: function(){
        return (new Date(Date.now())).toLocaleDateString()
      },

      scorecardGrade: function(){
        if(this.r && this.r.scorecard && this.r.scorecard.final){
          if(this.r.scorecard.final < 1.5){
            return "Sub-Standard"
          }else if(this.r.scorecard.final < 2.5){
            return "Sufficient"
          }else if(this.r.scorecard.final < 3.5){
            return "Good"
          }else if(this.r.scorecard.final < 4.5){
            return "Very Good"
          }else if(this.r.scorecard.final < 5.1){
            return "Best-in-Class"
          }


        }

        return "NA"
      },

      cellBorders: function(){
        return {
          top: {
            style: BorderStyle.SINGLE,
            size: 1,
            color: "black"
          },
          left: {
            style: BorderStyle.SINGLE,
            size: 1,
            color: "black"
          },
          right: {
            style: BorderStyle.SINGLE,
            size: 1,
            color: "black"
          },
          bottom: {
            style: BorderStyle.SINGLE,
            size: 1,
            color: "black"
          }
        }
      },

      noCellBorders: function(){
        return {
          top: {
            style: BorderStyle.NONE,
            size: 0,
            color: "black"
          },
          left: {
            style: BorderStyle.NONE,
            size: 0,
            color: "black"
          },
          right: {
            style: BorderStyle.NONE,
            size: 0,
            color: "black"
          },
          bottom: {
            style: BorderStyle.NONE,
            size: 0,
            color: "black"
          }
        }
      },

      rStyles: function(){
        return {
          default: {
            heading1: {
                run: {
                  size: 32,
                  bold: true,
                  color: "000000"
                },
                paragraph: {
                  spacing: {
                    after: 120
                  }
                }
            },
            heading2: {
              run: {
                size: 26,
                bold: true,
                color: "C00000"
              },
              paragraph: {
                spacing: {
                  before: 960,
                  after: 120
                }
              }
            },
            heading3: {
              run: {
                size: 20,
                bold: true,
                color: "C00000"
              },
              paragraph: {
                spacing: {
                  after: 0
                }
              }
            }
          },
          paragraphStyles: [
            {

              name: 'Normal',
              run: {
                size: 20,
                color: '000000',
                font: "Helvetica"
              },
              paragraph: {
                spacing: {
                  before: 0,
                  after: 240
                }
              }
            },
            {

              name: 'Basic',
              basedOn: "Normal",
              quickFormat: true,
              run: {
                size: 20,
                color: '000000',
              },
              paragraph: {
                spacing: {
                  before: 0,
                  after: 0
                }
              }
            },
            {

              name: 'Title',
              basedOn: "Normal",
              quickFormat: true,
              run: {
                size: 26,
                color: 'FFFFFF',
                bold: true
              },
              paragraph: {
                spacing: {
                  before: 0,
                  after: 0,
                }
              }
            },

            {

              name: 'Subtitle',
              basedOn: "Normal",
              quickFormat: true,
              run: {
                size: 20,
                color: 'FFFFFF',
                bold: true
              },
              paragraph: {
                spacing: {
                  before: 0,
                  after: 240
                }
              }
            },

            {

              name: 'BoldLabel',
              basedOn: "Normal",
              quickFormat: true,
              run: {
                size: 20,
                color: '000000',
                bold: true
              },
              paragraph: {
                spacing: {
                  before: 240,
                  after: 60
                }
              }
            },

            {

              name: 'Titlebox',
              basedOn: "Normal",
              quickFormat: true,
              run: {
                size: 20,
                color: 'FFFFFF',
              },
              paragraph: {
                spacing: {
                  before: 0,
                  after: 0,
                  right: 500
                }
              }
            },
            {
              name: 'TitlePageTitleCell',
              basedOn: "Normal",
              quickFormat: true,
              run: {
                size: 26,
                bold:true,
                color: 'FFFFFF',
              },
              paragraph: {
                spacing: {
                  before: 0,
                  after: 0
                }
              }
            },
            {
              name: 'TitlePageSubtitleCell',
              basedOn: "Normal",
              quickFormat: true,
              run: {
                size: 26,
                color: 'FFFFFF',
              },
              paragraph: {
                spacing: {
                  before: 180,
                  after: 240
                }
              }
            },

            {
              name: 'TitlePageCell',
              basedOn: "Normal",
              quickFormat: true,
              run: {
                size: 16,
                color: 'FFFFFF',
              },
              paragraph: {
                spacing: {
                  before: 0,
                  after: 0
                }
              }
            },

            {
              name: 'AppendixCell',
              basedOn: "Normal",
              quickFormat: true,
              run: {
                size: 11,
                color: '000000',
              },
              paragraph: {
                spacing: {
                  before: 0,
                  after: 0
                }
              }
            },

            {
              name: 'AppendixBoldCell',
              basedOn: "AppendixCell",
              quickFormat: true,
              run: {
                bold:true,
              }
            },

            {
              name: 'BlankCell',
              basedOn: "AppendixCell",
              quickFormat: true,
            },
            {

              name: "Heading 1",
              basedOn: "Normal",
              quickFormat: true,
              run: {
                size: 32,
                bold: true,
                color: "000000"
              },
              paragraph: {
                spacing: {
                  before: 240,
                  after: 120
                }
              }

            },
            {

              name: "Heading 2",
              basedOn: "Normal",
              quickFormat: true,
              run: {
                size: 26,
                bold: true,
                color: "C00000"
              },
              paragraph: {
                spacing: {
                  before: 480,
                  after: 120
                }
              }

            },
            {

              name: "Heading 3",
              basedOn: "Normal",
              quickFormat: true,
              run: {
                size: 20,
                bold: true,
                color: "000000"
              },
              paragraph: {
                spacing: {
                  before: 0,
                  after: 120
                }
              }

            },
            {

              name: "Heading 4",
              basedOn: "Normal",
              quickFormat: true,
              run: {
                size: 20,
                bold: true,
                color: "000000",
              },
              paragraph: {
                spacing: {
                  before: 480,
                  after: 0
                }
              }

            },
            {

              name: "List Paragraph",
              basedOn: "Normal",
              quickFormat: true,
              run: {

              },
              paragraph: {
                spacing: {
                  before: 0,
                  after: 120
                }
              }

            }
          ]
        }
      }
    }
}
</script>

<style>


.report-generator {

}

.report section {
  @apply mb-16 pb-16 border-b-2 border-red-600;
}

.report section h1 {
  @apply text-2xl font-bold mb-4;
}

.report section h2 {
  @apply text-lg font-bold mt-8 mb-2 text-red-700;
}

.report section h3 {
  @apply font-bold;
}

.report section h4 {
  @apply font-bold text-lg mt-4;
}

.report hr {
  @apply mb-4;
}

.report .container + .container {
  @apply border-t;
}

.report section h1 + h2 {
  @apply mt-4;
}

.report section p {
  @apply mb-4;
}

.report section p.no-mb {
  @apply mb-0;
}


.report section ul {
  @apply ml-4 mb-4;
}

.report section span[title] {
  @apply bg-yellow-100 cursor-pointer;
}

.report section span:hover {
  @apply bg-yellow-200
}


.report section th {
  @apply bg-blue-100 border border-black p-2;
}

.report section td {
  @apply border border-black p-2;
}

.report section h1:before {
  content: attr(data-num);
  @apply mr-2;
}

.report section h2:before {
  content: attr(data-num);
  @apply mr-2;
}

.report section h3:before {
  content: attr(data-num);
  @apply mr-2;
}

.report section .title {
  @apply text-2xl font-bold mb-0;
}

.report section .subtitle {
  @apply text-lg font-bold mb-4;
}

.report section table.titlebox tr {
  @apply border-none;
}

.report section .titlebox th {
  @apply bg-blue-100 border-none p-0;
}

.report section .titlebox td {
  @apply border-none p-0;
}

.report section .titlebox span {
  background-color: transparent;
}

.report section .bold-label {
  @apply font-bold mb-0;
}

.report section ul {
  @apply list-disc;
}


</style>