export default {
    requirements: {
        low: {
            id: 'low',
            name: 'Low',
            totalFootage: 30,
            staffing: 1,
            staffingCode: "as",
            equipment: [],
            software: [],
            otherItems: []
        },

        medium: {
            id: 'medium',
            name: 'Medium',
            totalFootage: 60,
            staffing:2,
            staffingCode: "as",
            equipment: [],
            software: [],
            otherItems: []
        },

        high: {
            id: 'high',
            name: 'High',
            totalFootage: 90,
            staffing: 3,
            staffingCode: "as",
            equipment: [],
            software: [],
            otherItems: []
        },
    },

    ratingDetails: [
        {
            id: 'level',
            type: 'enum',
            required: false,
            component: 'PulloutQuestionMultiSwitchList',
            default: 0,
            label: "Level of Need:",
            options: [
                {
                    id: "none",
                    label: "None",
                    value: 0
                },

                {
                    id: "low",
                    label: "Small",
                    description: "Reception for 1 site",
                    value: 1
                },
                {
                    id: "medium",
                    label: "Medium",
                    description: "Reception for 2 sites",
                    value: 2
                },
                {
                    id: "high",
                    label: "High",
                    description: "Reception for 3 sites",
                    value: 3
                }
            ]
        }

    ]
}
