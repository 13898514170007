


<template>
  <div class="w-full cursor-pointer p-4 border bg-white rounded-lg hover:border-brand-blue" :class="comment.status?'':'border-red-500'">
    <div class="text-xs relative">

      <span class="flex items-center">
        <span class="inline-block group relative">
          <span class="text-brand-blue font-bold">{{ schema.voiceOptions[respondent.department].initials }}</span><span class="absolute block text-xs text-center absolute mb-1 hidden group-hover:block py-1 px-2 rounded bg-brand-blue text-white bottom-full left-1/2 transform -translate-x-1/2" >{{ schema.voiceOptions[respondent.department].label }}</span>
        </span>
        <span class="mx-1">|</span> <span class="opacity-50">{{ respondent.name?respondent.name:schema.voiceOptions[respondent.department].label }}</span>
      </span>


    </div>
    <p class="whitespace-pre-wrap py-0.5" v-if="comment.value">{{ comment.value }}</p>
    <p class="text-xs opacity-50">{{ comment.reviewer }}  - {{ timeAgo(comment.ts) }}</p>
    <p class="whitespace-pre-wrap py-0.5 text-red-500 font-bold uppercase text-xxs" v-if="!comment.status">FLAGGED FOR FOLLOWUP</p>

  </div>
</template>

<script>
export default {
    name: "needs-comment-bubble",
    props: {
      schema: {
        type: Object,
        required: true
      },
      analysis: {
        type:Object,
        required: true
      },
      comment: {
        type:Object,
        required: true
      }
    },
    data: function(){
        return {}
        },
    methods: {
      timeAgo: function(ts){
        return this.$timeAgo.format(ts);
      }
    },
    computed: {
      respondent: function(){
        return this.analysis.respondents[this.comment.respondent];
      }
    }
}
</script>

<style scoped>


.needs-comment-bubble {

}

</style>
