<template>
  <div class="interactive-video">
      <h4>Interactive Personalized Video (IPV) </h4>
    <hr>
      <p>Interactive personalized video (IPV) drives ROI throughout the entire customer journey through engaging statements and customer care. The platform cost-effectively scales aligning cost and performance. The power of the platform includes capturing knowledge and data from the video interaction creating new and compelling data driven customer insights.</p>
    <p>Data-driven Interactive Personalized Video (IPV) ensures a seamless customer journey from initial attraction to retention. With IPV, increase conversions and decrease funnel abandonment at every stage of your customers’ journey.</p>
    <p class="bold-label">Benefits:</p>
    <ul>
      <li>Paperless adoption & Postage savings</li>
      <li>Reduced call center calls</li>
      <li>Cross-sell/upsell/retain</li>
      <li>Educated, engaged customers</li>
      <li>Explanation of transactional communication</li>
    </ul>
    <p class="no-mb">Clients using interactive personalized video achieved:</p>
    <ul>
      <li>70% Video view through rate</li>
      <li>90% Customer satisfaction rating</li>
      <li>65% Lift in cash contributions</li>
    </ul>
    <p>Create a memorable experience, simplify complex products, and ensure immediate relevancy using Interactive Personalized Video.</p>
    <img data-ratio="0.437" src="/reports/interactive-video.png" />
    <p></p>
    <p>Videos have not only become an inseparable part of our personal lives but also a valuable tool for our business promotion. This is what video marketing statistics show. (Source: Brendan Cournoyer Brainshark)</p>
    <p class="bold-label">Marketing statistics:</p>
    <ul>
      <li>A video is 50 times more likely to get organic page ranks in Google than plain text.</li>
      <li>Half of the consumers who watch online product videos say it helps them make more confident purchasing decisions.</li>
      <li>People who watch videos stay on a site two minutes longer on average and are 64 percent more likely to make a purchase.</li>
      <li>85% of all internet users in the U.S. watch online video content monthly on any of their devices (Statista, 2018)</li>
    <li>54% of consumers want to see more video content from a brand or business they support (HubSpot, 2018)</li>
  <li>Studies show that web pages with videos have significantly higher average time on site than those without videos.</li>
  <li>Next to Google, YouTube is the second largest search engine out there.</li>
  <li>Videos allow you to drive branding and build trust. They’re like a TV commercial - for a fraction of the price.</li>
      <li>The best marketing videos are under 60-seconds - in fact, they should be under 10 seconds for maximum impact. Recommend videos for your marketing plan:
      -	How-to videos of your products and services
      -	Customer testimonials
      -	Company culture videos
        -	Videos that highlight the benefits of using your products or services</li>
    </ul>

  </div>
</template>

<script>
export default {
  name: "interactive-video",
  props: {},
  data: function () {
    return {}
  },
  methods: {},
  computed: {}
}
</script>

<style scoped>


.interactive-video {

}

</style>