<template>
  <div class="side-panel absolute top-0 right-0 w-full h-full bg-white border-l bg-gray-50">
    <div class="flex justify-between px-8 py-4 border-b bg-white">
      <h2 class="text-xl leading-none">Create Organization</h2>
      <img @click="close" class="h-4 inline-block mt-1 cursor-pointer opacity-50 hover:opacity-100" src="../assets/icons/close-icon.svg" />
    </div>
    <div class="p-8">
      <div class="">

        <div class="mt-4">
          <label class="text-sm" :data-invalid="!validName">Organization Name</label>
          <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="customerProfile.name"  />
        </div>

        <div class="mt-4">
          <label class="text-sm" :data-invalid="!validCode" >Oracle Continuous Feed Customer #</label>
          <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="customerProfile.orgCode" />
        </div>

        <div class="mt-4">
          <label class="text-sm">Oracle Cut Sheet Customer #</label>
          <input type="text" class="block w-full border p-2 mt-1 rounded" v-model="customerProfile.orgCode2" />
        </div>


        <p v-if="error" class="my-4 p-2 bg-red-500 text-white">{{ error }}</p>

        <p v-if="warning" class="my-4 p-2 bg-yellow-100 text-black border">{{ warning }}</p>

        <div class="mt-4">
          <p>Organization profiles are used to help organize documents and create reports. It is important that each organization has a unique name and code setup.</p>
        </div>


        <div class="pt-6 mt-4 border-t">
          <div class="pb-4 flex justify-between">
            <div class="mr-8">
              <button class="button border-gray-400" @click="close">Cancel</button>
            </div>
            <div class="">
              <button class="button button-blue" @click="create">Create</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "side-panel-new-customer",
  components: {},
  props: {},
  data: function () {
    return {
      customerProfile: {
        name: "",
        orgCode: "",
        orgCode2: ""
      },
      error: "",
    }
  },
  methods: {
    close: function(){
      this.$emit('close');
    },

    create: async function() {

      if(!this.isValid){
        alert("Please fill out of the required fields");
        return;
      }

      let admin = this.$store.getters.getUserProfile;
      let profile = this.customerProfile;
      profile.creator = admin.email;
      profile.created = Date.now();

      let q = this.$db.query;
      let client = new this.$db.Client({ secret: this.$store.getters.getToken });

      let newCustomer = await client.query(
          q.Create(
              q.Collection('customers'),
              { data: profile }
            )
          )
          .then((response) => {
            return response;
          })
      .catch((error) => {
        console.error(error);
        alert("Something went wrong with this operation.");
      })

      if(newCustomer.ref){
        console.log(newCustomer);
        alert("Customer has been added.");
        this.$emit("created");
      }

    }
  },
  computed: {


    validName: function() {
      return this.customerProfile.name.length > 0;
    },

    validCode: function() {
      return this.customerProfile.orgCode.length > 0;
    },

    isValid: function(){
      return (this.validName && this.validCode);
    },

    warning: function(){
      if(!this.isValid){

        return "Please enter a unique name and at least one organization code."
      }

      return ""

    }
  }
}
</script>

<style scoped>


.side-panel-new-user {

}

label[data-invalid="true"]{
  @apply bg-yellow-200
}

</style>