export default {
    requirements: {
        low: {
            id: 'low',
            name: 'Low',
            totalFootage: 64,
            staffing: 0.25,
            staffingCode: "ddps",
            equipment: ["Design Workstation"],
            software: ["Fusion Pro"],
            otherItems: []
        },

        medium: {
            id: 'medium',
            name: 'Medium',
            totalFootage: 64,
            staffing:0.5,
            staffingCode: "ddps",
            equipment: ["Design Workstation"],
            software: ["Fusion Pro"],
            otherItems: []
        },

        high: {
            id: 'high',
            name: 'High',
            totalFootage: 64,
            staffing: 1,
            staffingCode: "ddps",
            equipment: ["Design Workstation"],
            software: ["Fusion Pro"],
            otherItems: []
        },
    },

    ratingDetails: [
        {
            id: 'level',
            type: 'enum',
            required: false,
            component: 'PulloutQuestionMultiSwitchList',
            default: 0,
            label: "Level of Need:",
            options: [
                {
                    id: "none",
                    label: "None",
                    value: 0
                },

                {
                    id: "low",
                    label: "Low",
                    description: "up to 63  templates per month",
                    value: 1
                },
                {
                    id: "medium",
                    label: "Medium",
                    description: "up to 140 templates per month",
                    value: 2
                },
                {
                    id: "high",
                    label: "High",
                    description: "up to 283 templates per month",
                    value: 3
                }
            ]
        }

    ]
}
