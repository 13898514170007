<template>
  <div class="enfocus-switch">
      <h4>Enfocus Switch</h4>
    <hr>
    <p>Switch is an application that allows you to automate tasks and processes. The road to automation starts by connecting business systems and integrating those systems into a single automated workflow. Create your own automation with Switch to connect MIS, web to print, imposition, online proofing, and preflight.</p>
    <p>Switch is built in a modular format around a core engine and contains a number of modules to extend its functionality:</p>
    <ul>
      <li>Database</li>
      <li>Metadata</li>
      <li>Scripting</li>
      <li>Performance</li>
      <li>SwitchClient</li>
      <li>WebServices</li>
      <li>SwitchProxy</li>
      <li>Configurator</li>
    </ul>

  </div>
</template>

<script>
export default {
  name: "enfocus-switch",
  props: {},
  data: function () {
    return {}
  },
  methods: {},
  computed: {}
}
</script>

<style scoped>


.eficross-media {

}

</style>