


<template>
<div class="needs-question border-t">
  <div class="flex w-full relative border-l-4 border-transparent pl-4" :class="(!hideIrrelevant && relevant)?'border-brand-blue':''">
    <div class="w-48 md:w-64 lg:w-96 flex-shrink-0 sticky top-0 self-start py-4">
      <div class="text-sm">{{ question.id }}</div>

      <div class="font-bold relative" :class="relevant?'':'text-gray-500'">{{ question.label }}</div>
      <div class="text-sm">{{ question.description }}</div>
      <div class="text-sm italic text-red-500" v-if="!relevant">Not relevant for current respondent <span class="underline cursor-pointer hover:text-black not-italic ml-2" @click="$emit('settings')">Edit settings</span></div>
      <div class="mt-1">
        <div v-for="voiceId in relevantVoices" :key="voiceId" class="relative group inline-flex w-6 h-6 text-xxs rounded-full text-center items-center mr-1 border border-gray-500" :class="answeredVoices[voiceId]?'text-white bg-brand-blue border-brand-blue':'text-gray-500'">
          <span class="w-full select-none">{{ api.schema.voiceOptions[voiceId].initials }}</span>
          <span class="absolute hidden group-hover:block bottom-full bg-brand-blue text-white p-2 w-16 text-center mb-1 rounded ml-2"><span class="block">{{ answeredVoices[voiceId]?answeredVoices[voiceId]:0 }}</span> {{ api.schema.voiceOptions[voiceId].label }} Responses</span>
        </div>
        <div class="relative group inline-flex w-6 h-6 text-xxs rounded-full text-center items-center mr-1 border border-red-500" :class="answeredVoices.notes?'text-white bg-red-500 border-red-500':'text-red-500'">
          <span class="w-full select-none">R</span>
          <span class="absolute hidden group-hover:block bottom-full bg-red-500 text-white p-2 w-16 text-center mb-1 rounded ml-2"><span class="block">{{ answeredVoices.notes?answeredVoices.notes:0 }}</span> Team Notes</span>
        </div>
      </div>
    </div>
    <div class="flex-grow pl-16 py-4">
<!--      <div class="max-w-2xl mb-4" v-for="voice in answeredVoices" :key="voice">-->

<!--        <div class="w-full cursor-pointer p-4 border bg-white rounded-lg hover:border-brand-blue" @click="editAnswer(voice)">-->
<!--          <div class="text-xs opacity-50">-->
<!--            <span class="">{{ api.schema.voiceOptions[voice].label }}</span> –-->
<!--            <span class="" v-if="answer[voice].ts">{{ timeAgo(answer[voice].ts) }}</span>-->
<!--          </div>-->
<!--          <p class="whitespace-pre-wrap" v-if="answer[voice].value">{{ answer[voice].value }}</p>-->
<!--        </div>-->
<!--      </div>-->
      <div class="max-w-2xl mb-4" v-for="response in responseList" :key="response.id">

        <needs-comment-bubble :schema="api.schema" :comment="response" :analysis="analysis" @click.native="editResponse(response)"></needs-comment-bubble>
      </div>

      <div class="">




        <div class="max-w-2xl inline-flex items-center group relative cursor-pointer"  @click="newResponse">
          <plus-icon size="8"></plus-icon>
          <span class="text-xs text-center hidden group-hover:inline-block p-1 px-2 bg-brand-blue text-white rounded ml-2" >Add Response</span>
        </div>


      </div>
    </div>
  </div>
</div>
</template>

<script>
import NeedsCommentBubble from "@/components/NeedsAnalysis/NeedsCommentBubble";
import PlusIcon from "@/components/PlusIcon";
export default {
    name: "needs-question",
  components: {PlusIcon, NeedsCommentBubble},
  props: {
      question: {
        type: Object,
        required: true
      },

      analysis: {
        type: Object,
        required: true
      },

    responses: {
      type: Object,
      required: false,
      default: function(){
        return {}
      }
    },

      voice: {
        type: String,
        required: false,
        default: "all"
      },

      hideIrrelevant: {
        type: Boolean,
        default: true
      },

      api: {
        type: Object,
        required: true
      }

    },
    data: function(){
        return {}
        },
    methods: {
      newResponse: function(){
        this.$emit("edit", {
          questionId: this.question.id
        })
      },

      editResponse: function(response){
        // this.$emit("edit", {
        //   questionId: this.question.id,
        //   voice: voice
        // })
        this.$emit("edit",{
          questionId: this.question.id,
          responseId: response.id
        })
      },

      timeAgo: function(ts){
        return this.$timeAgo.format(ts);
      }
    },


    computed: {

      relevant: function(){
        if(this.voice === "all" || this.voice === "notes"){
          return true;
        }else{
          return this.relevantVoices.includes(this.voice);
        }
      },

      responseList: function(){
        return Object.keys(this.responses).map((id) => {
          return this.responses[id];
        })
      },

      relevantVoices: function(){
        return this.question.voices;
      },

      answeredVoices: function(){

        let voices = {};
        if(this.responseList && this.responseList.length){
          Object.keys(this.responses).forEach((responseId) => {

            let department = this.analysis.respondents[this.responses[responseId].respondent].department;
            if(voices[department]){
              voices[department]++
            }else{
              voices[department] = 1
            }
          })
        }
        return voices;


      }
    }
}
</script>

<style scoped>


.needs-question {

}

</style>
