


<template>
<div class="">
  <h4>Security Conformance Services</h4>
  <hr>
  <p>Information security threats are becoming more advanced and stealthier every day. Ricoh is committed to offering products that protect your assets and harmonize with your environment and policies. Ensuring security requires understanding what to look for within the settings of network devices and making the necessary adjustments to maintain security. Security conformance ultimately means more confidence that the network devices’ operating systems are secure and the data security policies and standards are in compliance.</p>
  <p>Assessing vulnerabilities, establishing security objectives and taking appropriate countermeasures will help minimize the risk of potentially serious breaches, and at the same time enable you to document security compliance initiatives.</p>
  <p>Ricoh offers this protection by conducting on-site IT security assessments that help organizations understand the business as well as requirements for backup, restoration, authentication and compliance. We do this by implementing a customized security configuration aimed at reducing overall risk and increasing security of the print devices.</p>

  <p class="bold-label">Security conformance services include:</p>
  <ul>
    <li>Network port security / port filtering</li>
    <li>IP address range filtering - limiting access to devices</li>
    <li>Password complexity requirements - changing default usernames and passwords</li>
    <li>Review of firewall rules</li>
    <li>Incident response upon vulnerability detection</li>
    <li>EFI® security white paper review</li>
    <li>Fiery® server configuration - server configured based upon security requirements</li>
    <li>Data encryption</li>
    <li>Security awareness training</li>
    <li>Patch installation / Windows®</li>
  </ul>

</div>
</template>

<script>
export default {
    name: "security-conformance",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


</style>
