export default {
    requirements: {
        low: {
            id: 'low',
            name: 'Low',
            totalFootage: 100,
            staffing: 0.125,
            staffingCode: "da",
            equipment: [],
            software: [],
            otherItems: []
        },

        medium: {
            id: 'medium',
            name: 'Medium',
            totalFootage: 150,
            staffing:0.375,
            staffingCode: "da",
            equipment: [],
            software: [],
            otherItems: []
        },

        high: {
            id: 'high',
            name: 'High',
            totalFootage: 200,
            staffing: 0.5,
            staffingCode: "da",
            equipment: [],
            software: [],
            otherItems: []
        },
    },

    ratingDetails: [
        {
            id: 'level',
            type: 'enum',
            required: false,
            component: 'PulloutQuestionMultiSwitchList',
            default: 0,
            label: "Level of Need:",
            options: [
                {
                    id: "none",
                    label: "None",
                    value: 0
                },

                {
                    id: "low",
                    label: "Low",
                    description: "up to 60 orders per month",
                    value: 1
                },
                {
                    id: "medium",
                    label: "Medium",
                    description: "up to 180 orders per month",
                    value: 2
                },
                {
                    id: "high",
                    label: "High",
                    description: "up to 240 orders per month",
                    value: 3
                }
            ]
        }

    ]
}
