


<template>
<div class="">
  <h4>Lorton Data A-Qua Mailer</h4>
  <hr>
  <p>Lorton Data’s A-Qua Mailer software offering provides cost-effective, scalable and flexible mailing list processing that maximizes mail recipient address quality in a print-and-mail production environment.</p>
  <p class="bold-label">BCC Mail Manager</p>
  <p>Lorton Data is a trusted provider of mailing software solutions, with A-Qua Mailer as the company's flagship product.  A-Qua Mailer is an ideal solution for companies looking to streamline their direct mail processing and improve the impact of their campaigns and customer communications.</p>
  <p>Key advantages include:</p>
  <ul>
    <li>Address quality services such as ZIP + 4, NCOALink processing, duplicate elimination, postal presort, and printer file output</li>
    <li>Cost effective SaaS / cloud-hosted solution</li>
    <li>Available metrics and dashboard to track trends in on-time delivery</li>
    <li>Optional professional services to help optimize communications</li>
    <li>
      Integrates with different Ricoh cross-media mailing solutions, e.g.:
      <ul>
        <li>FusionPro or PrintShop Mail, EFI cross media or Mail merge</li>
        <li>FusionPro Server, Objectif Lune PlanetPress / PReS Connect</li>
      </ul>

    </li>

  </ul>

</div>
</template>

<script>
export default {
    name: "lorton",
    props: {},
    data: function(){
        return {}
        },
    methods: {},
    computed: {}
}
</script>

<style scoped>


</style>
