<template>
  <div class="fixed w-full h-full top-0 right-0 shadow-lg z-30 bg-black bg-opacity-50" >
    <div v-if="answer" class="side-panel absolute w-full max-w-2xl top-0 right-0 w-full h-screen bg-white border-l bg-gray-50 overflow-y-scroll">
      <div class="flex justify-between items-center px-8 py-4 border-b bg-white">
        <p class="text-xl leading-none">{{ question.id }}</p>
        <div class="w-8 h-8 border-gray-300 border-2 rounded-full flex justify-center items-center ml-3 opacity-50 hover:opacity-100 cursor-pointer" @click="onClose">
          <img  class="h-3 inline-block " src="../assets/icons/close-icon.svg" />
        </div>
      </div>
      <div class="py-8">

        <component :is="questionForm"  :question="question" :answer="answer" class="question flex items-center pb-4 border-b bg-gray-50 mx-8"  @change="onChange">
        </component>

        <p class="px-8 py-4 text-red-600">Warning:<br>Any edits you make here changes this value everywhere in this document. Any calculations relying on this value will also be recalculated.</p>
        <p class="px-8 py-4 text-red-600">This document will need to be saved before you close this preview if you wish to keep these changes outside of preview mode.</p>

      </div>
    </div>
  </div>

</template>

<script>

import AssessmentInterviewQuestionBoolean from "@/components/AssessmentInterviewQuestionBoolean";
import AssessmentInterviewQuestionMultiChoice from "@/components/AssessmentInterviewQuestionMultiChoice";
import AssessmentInterviewQuestionMultiCol from "@/components/AssessmentInterviewQuestionMultiCol";
import AssessmentInterviewQuestionSelect from "@/components/AssessmentInterviewQuestionSelect";
import AssessmentInterviewQuestionSingleCol from "@/components/AssessmentInterviewQuestionSingleCol";
import AssessmentInterviewQuestionTextField from "@/components/AssessmentInterviewQuestionTextField";
import ConfigQuestionSelect from "@/components/PrintConfig/ConfigQuestionSelect";
import ConfigQuestionText from "@/components/PrintConfig/ConfigQuestionText";


export default {
name: "FormPullout",
  components: {
    ConfigQuestionText,
    ConfigQuestionSelect,
    AssessmentInterviewQuestionTextField,
    AssessmentInterviewQuestionSingleCol,
    AssessmentInterviewQuestionSelect,
    AssessmentInterviewQuestionMultiCol,
    AssessmentInterviewQuestionMultiChoice,
    AssessmentInterviewQuestionBoolean,

  },
  mounted: function(){
    this.editAnswer = { ...this.answer }
  },

  props: {
    question: {
      type: Object,
      required: true
    },
    answer: {
      required: true
    }


  },
  data: function() {
    return {
      editAnswer: undefined
    }
  },
  methods: {
    onClose: function(){
      this.$emit('close');
    },
    onChange: function(){
      this.$emit('change');
    },

  },
  computed: {
    questionForm: function(){
      if(this.question.component){
        return this.question.component
      }else{
        return this.questionTypes[this.question.type];
      }
    },
    questionTypes: function(){
      return {
        1: "SurveyQuestion",
        2: "AssessmentInterviewQuestionSingleCol",
        3: "AssessmentInterviewQuestionTextField",
        4: "AssessmentInterviewQuestionMultiCol",
        5: "AssessmentInterviewQuestionSelect",
        6: "AssessmentInterviewQuestionBoolean",
        7: "AssessmentInterviewQuestionStatement",
        8: "AssessmentInterviewQuestionMultiChoice",
      }
    }
  }

}
</script>

<style scoped>

</style>